export const debounce = (debouncedFn: () => void, delay: number) => {
  let timer: NodeJS.Timeout | null = null
  return () => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      debouncedFn.apply(this)
    }, delay)
  }
}
