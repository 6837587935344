import styled from 'styled-components'

import { fromMd, timingSlower, zIndexForeground } from '@syconium/little-miss-figgy'

const desktopMenuColumnWidth = '136px'
export const contentHeight = '32px'
export const padHeight = '14px' // (60 - 32) / 2 [60px container, 32px content]

export const SectionLayout = styled.div<{
  location?: 'left' | 'center' | 'right'
}>`
  align-items: center;
  display: flex;
  flex: ${o => o.location === 'center' && '0 0 48px'};
  height: ${contentHeight};
  justify-content: ${o => o.location === 'right' && 'flex-end'};
  margin: auto;
  text-align: ${o => o.location};
  width: 100%;
`

export const SubMenuLayout = styled.ul`
  text-indent: 48px;
  margin-bottom: 12px;

  ${fromMd} {
    text-indent: initial;
    margin-bottom: 0;
  }
`

export const SubMenuSegment = styled.div`
  margin-bottom: 12px;
`

export const MenuItemLayout = styled.li``

export const MenuTitleLayout = styled.div`
  line-height: ${contentHeight};
`

export const MenuContentLayout = styled.div`
  ${fromMd} {
    background-color: white;
    border-radius: 0 0 5px 5px;
    box-shadow: rgba(213, 213, 213, 0.5) 0 4px 4px -2px;
    display: none;
    padding: ${contentHeight} 28px ${padHeight};
    position: absolute;
    /* Be careful adjusting the vertical translation.
      Beyond 16px there will be enough whitespace
      between the content and the title that you will
      break the hover state shared by MenuLayout */
    transform: translate3d(-20px, 0, 0);
  }
`

export const MenuLayout = styled.div`
  ${fromMd} {
    &:hover {
      ${MenuContentLayout} {
        display: flex;
      }

      ${SubMenuLayout} {
        display: block;

        ${MenuItemLayout} {
          position: relative;
          width: ${desktopMenuColumnWidth};
        }
      }
    }
  }
`

export const NavButtonLayout = styled.button`
  align-items: center;
  display: flex;
  height: ${contentHeight};
  justify-content: center;
  width: ${contentHeight};

  ${fromMd} {
    display: none;
  }
`

export const NavCloseButtonLayout = styled(NavButtonLayout)`
  left: calc(${o => o.theme.header.slideOutOffset} - 48px);
  position: relative;
  top: 12px;

  svg {
    height: 12px;
    width: 12px;
  }
`

export const NavLayout = styled.nav.attrs(() => ({
  'role': 'navigation',
  'aria-label': 'Main Site Navigation',
}))`
  ${({ theme: { header } }) => `
  background: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  width: ${header.slideOutOffset};

  transform: translate3d(-${header.slideOutOffset}, 0, 0);

  svg {
    path {
      stroke-width: 0.5;
    }
  }

  ${fromMd} {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    overflow-y: initial;
    position: inherit;

    height: auto;
    width: auto;
    background-color: inherit;
    transform: initial;
    transition: initial;

    & ${MenuLayout} {
      padding: 0 16px;
    }
  }`}
`
export const NavSlideOutOverlay = styled.div`
  ${({
    theme: {
      color: {
        deprecated: { black },
      },
      header,
    },
  }) => `
    background-color: ${black.primary};
    position: fixed;
    top: 0;
    left: ${header.slideOutOffset};
    height: 100vh;
    width: 100vw;
    z-index: ${zIndexForeground};

    transform: translate3d(-${header.slideOutOffset}, 0, 0);
    animation: fadeIn ${timingSlower} ease 1;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 0.3;
      }
    }

    &[aria-expanded="false"] {
      opacity: 0;
      display: none;
    }

    &[aria-expanded="true"] {
      opacity: 0.3;
    }

    ${fromMd} {
      display: none;
    }
  `}
`

export const NavMobileBottomLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 24px 24px 48px;

  ${fromMd} {
    display: none;
  }
`
