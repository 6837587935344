import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ViewportSizes, mdStartPixels } from '@syconium/little-miss-figgy'
import { VIMEO_PLAYER_BASE_URL } from '@syconium/magnolia/src/brunswick/constants'
import { InlineVideoPlatform, InlineVideoPlatforms } from '@syconium/magnolia/src/types/video'

export interface IProps {
  inlineVideoDesktop?: string | null
  inlineVideoMobile?: string | null
  inlineVimeoIdDesktop?: number | null
  inlineVimeoIdMobile?: number | null
  preferredVideoPlatform?: InlineVideoPlatform
}

export function useResponsiveVideoSrc({
  inlineVideoDesktop,
  inlineVideoMobile,
  inlineVimeoIdDesktop,
  inlineVimeoIdMobile,
  preferredVideoPlatform,
}: IProps) {
  const windowWidthRef = useRef<number | null>(null)

  const getVideoInfo = useCallback(() => {
    if (!windowWidthRef.current) return null

    const isLargeViewport = windowWidthRef.current >= mdStartPixels
    const viewport: ViewportSizes = isLargeViewport ? ViewportSizes.large : ViewportSizes.small

    const vimeoId = isLargeViewport ? inlineVimeoIdDesktop : inlineVimeoIdMobile
    const vimeoSrc = vimeoId ? `${VIMEO_PLAYER_BASE_URL}/${vimeoId}` : null
    const vimeoResult = vimeoSrc
      ? { viewport, platform: InlineVideoPlatforms.VIMEO, src: vimeoSrc }
      : null

    const defaultPlayerSrc = isLargeViewport ? inlineVideoDesktop : inlineVideoMobile
    const defaultPlayerObject = defaultPlayerSrc
      ? { viewport, platform: InlineVideoPlatforms.DEFAULT_PLAYER, src: defaultPlayerSrc }
      : null

    if (preferredVideoPlatform === InlineVideoPlatforms.VIMEO) {
      return vimeoResult ?? defaultPlayerObject
    } else {
      return defaultPlayerObject ?? vimeoResult
    }
  }, [
    inlineVideoDesktop,
    inlineVideoMobile,
    inlineVimeoIdDesktop,
    inlineVimeoIdMobile,
    preferredVideoPlatform,
  ])

  const [videoSrc, setVideoSrc] = useState(() => {
    return getVideoInfo()?.src
  })

  const [videoPlatform, setVideoPlatform] = useState(() => {
    return getVideoInfo()?.platform
  })

  useEffect(() => {
    let mostRecentUpdate = true
    if (!window) return

    const update = () => {
      windowWidthRef.current = window.innerWidth
      if (mostRecentUpdate) {
        const newVideoInfo = getVideoInfo()
        setVideoSrc(newVideoInfo?.src)
        setVideoPlatform(newVideoInfo?.platform)
      }
    }
    update()

    const events = ['orientationchange', 'resize']
    events.forEach(e =>
      window.addEventListener(e, update, {
        passive: true,
      })
    )

    return () => {
      mostRecentUpdate = false
      events.forEach(e => window.removeEventListener(e, update))
    }
  }, [getVideoInfo])

  const result = useMemo(() => {
    return videoSrc
      ? {
          src: videoSrc,
          platform: videoPlatform,
        }
      : undefined
  }, [videoPlatform, videoSrc])

  return result
}
