import React from 'react'
import { createContainer } from 'unstated-next'

export interface HeaderContextProps {
  isAuthorized: boolean
  isToggled: boolean
}

function useHeaderContext(
  initialState: HeaderContextProps = { isAuthorized: false, isToggled: false }
) {
  const [isToggled, setToggled] = React.useState<boolean>(initialState && initialState.isToggled)
  const isAuthorized = (initialState && initialState.isAuthorized) || false

  return {
    isAuthorized,
    isToggled,
    setToggled,
  }
}

const HeaderStateContainer = createContainer(useHeaderContext)

export default HeaderStateContainer
