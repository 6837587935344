import unfetch from 'isomorphic-unfetch';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

const localStorageNamespace = "@figs:";

const getJwtAuthTokenStatus = (token, refreshToken) => {
  if (!token) {
    return {
      status: "absent",
      metadata: null
    };
  }
  if (!refreshToken) {
    return {
      status: "present",
      metadata: null
    };
  }
  const tokenInfo = jwtAuthTokenFromString(token);
  const refreshTokenInfo = jwtAuthTokenFromString(refreshToken);
  if (!tokenInfo || !refreshTokenInfo) {
    return {
      status: "expired",
      metadata: null
    };
  }
  const secondsUntilRefresh = secondsUntilJwtAuthTokenExpires(tokenInfo);
  const secondsUntilExpiration = secondsUntilJwtAuthTokenExpires(refreshTokenInfo);
  const twelveHoursInSeconds = 43200;
  return {
    status: secondsUntilExpiration <= 0 ? "expired" : secondsUntilRefresh <= twelveHoursInSeconds ? "expiring" : "active",
    metadata: {
      secondsUntilRefresh,
      secondsUntilExpiration
    }
  };
};
const jwtAuthTokenFromString = (token) => {
  let decodedToken = null;
  try {
    decodedToken = jwtDecode(token.replace("Bearer ", ""));
  } catch (_error) {
    return void 0;
  }
  return decodedToken;
};
const secondsUntilJwtAuthTokenExpires = (token) => {
  const nowInSeconds = Date.now() / 1e3;
  const secondsUntilExpiration = token.exp - nowInSeconds;
  return secondsUntilExpiration;
};

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const cookieManager = new Cookies();
const _TokenAuthStorageSingleton = class _TokenAuthStorageSingleton {
  constructor() {
    __publicField(this, "token", null);
    __publicField(this, "refreshToken", null);
  }
  static getInstance() {
    if (this.instance === null) {
      this.instance = new _TokenAuthStorageSingleton();
    }
    return this.instance;
  }
  getToken() {
    if (this._isLocalStorageDefined) {
      const cookieAuthToken = cookieManager.get(
        _TokenAuthStorageSingleton.AUTH_TOKEN_COOKIE_KEY
      );
      if (typeof cookieAuthToken === "string" && cookieAuthToken.length > 0) {
        return cookieAuthToken;
      } else {
        return window.localStorage.getItem(_TokenAuthStorageSingleton.AUTH_TOKEN_LOCAL_STORAGE_KEY);
      }
    }
    return this.token;
  }
  getRefreshToken() {
    if (this._isLocalStorageDefined) {
      const cookieRefreshToken = cookieManager.get(
        _TokenAuthStorageSingleton.REFRESH_TOKEN_COOKIE_KEY
      );
      if (typeof cookieRefreshToken === "string" && cookieRefreshToken.length > 0) {
        return cookieRefreshToken;
      } else {
        return window.localStorage.getItem(
          _TokenAuthStorageSingleton.REFRESH_TOKEN_LOCAL_STORAGE_KEY
        );
      }
    }
    return this.refreshToken;
  }
  getTokenStatus() {
    const token = this.getToken() ?? void 0;
    const refreshToken = this.getRefreshToken() ?? void 0;
    return getJwtAuthTokenStatus(token, refreshToken);
  }
  setToken(token, asBearer = false) {
    this.token = asBearer ? `Bearer ${token}` : token;
    if (this._isLocalStorageDefined && Boolean(token)) {
      window.localStorage.setItem(
        _TokenAuthStorageSingleton.AUTH_TOKEN_LOCAL_STORAGE_KEY,
        this.token
      );
      cookieManager.set(_TokenAuthStorageSingleton.AUTH_TOKEN_COOKIE_KEY, this.token, {
        secure: true,
        sameSite: "strict",
        path: "/",
        maxAge: 156e5
      });
    }
  }
  setRefreshToken(token) {
    this.refreshToken = token;
    if (this._isLocalStorageDefined && Boolean(token)) {
      window.localStorage.setItem(
        _TokenAuthStorageSingleton.REFRESH_TOKEN_LOCAL_STORAGE_KEY,
        this.refreshToken
      );
      cookieManager.set(_TokenAuthStorageSingleton.REFRESH_TOKEN_COOKIE_KEY, this.refreshToken, {
        secure: true,
        sameSite: "strict",
        path: "/",
        maxAge: 156e5
      });
    }
  }
  appendToOrSetToken(token) {
    const currentToken = this.getToken();
    return currentToken ? this.setToken(currentToken.concat(` ${token}`)) : this.setToken(token);
  }
  clearToken() {
    this.token = null;
    if (this._isLocalStorageDefined) {
      window.localStorage.removeItem(_TokenAuthStorageSingleton.AUTH_TOKEN_LOCAL_STORAGE_KEY);
      cookieManager.remove(_TokenAuthStorageSingleton.AUTH_TOKEN_COOKIE_KEY, {
        path: "/"
      });
    }
  }
  clearRefreshToken() {
    this.refreshToken = null;
    if (this._isLocalStorageDefined) {
      window.localStorage.removeItem(_TokenAuthStorageSingleton.REFRESH_TOKEN_LOCAL_STORAGE_KEY);
      cookieManager.remove(_TokenAuthStorageSingleton.REFRESH_TOKEN_COOKIE_KEY, {
        path: "/"
      });
    }
  }
  clearTokens() {
    this.clearToken();
    this.clearRefreshToken();
  }
  hasAppendedToken() {
    const currentToken = this.getToken();
    if (this._isLocalStorageDefined && currentToken) {
      return currentToken.split(" ")?.length > 1;
    }
    return false;
  }
  get _isLocalStorageDefined() {
    return Boolean(
      typeof window !== "undefined" && typeof document !== "undefined" && window.localStorage
    );
  }
};
__publicField(_TokenAuthStorageSingleton, "instance", null);
__publicField(_TokenAuthStorageSingleton, "AUTH_TOKEN_LOCAL_STORAGE_KEY", `${localStorageNamespace}auth-token`);
__publicField(_TokenAuthStorageSingleton, "REFRESH_TOKEN_LOCAL_STORAGE_KEY", `${localStorageNamespace}refresh-token`);
__publicField(_TokenAuthStorageSingleton, "AUTH_TOKEN_COOKIE_KEY", `figs-auth-token`);
__publicField(_TokenAuthStorageSingleton, "REFRESH_TOKEN_COOKIE_KEY", `figs-refresh-token`);
let TokenAuthStorageSingleton = _TokenAuthStorageSingleton;

const authenticateCarica = async (config, force) => {
  const tokenSingleton = TokenAuthStorageSingleton.getInstance();
  let authToken = tokenSingleton.getToken();
  if (!authToken || force) {
    const fetch = typeof window === "undefined" ? unfetch : window.fetch;
    const response = await fetch(`${config.host}/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: config.email,
        password: config.password
      })
    });
    const data = await response.json();
    authToken = data.auth_token;
    tokenSingleton.setToken(authToken);
  }
  return authToken;
};

export { TokenAuthStorageSingleton, authenticateCarica, getJwtAuthTokenStatus, jwtAuthTokenFromString, localStorageNamespace, secondsUntilJwtAuthTokenExpires };
