import { useEffect, useState } from 'react'

import { isBrowser } from '../utils'

export const useIsBrowser = (): boolean | null => {
  const [isClient, setIsClient] = useState(() => {
    return isBrowser
  })

  useEffect(() => {
    setIsClient(isBrowser)
  }, [])

  return isClient
}
