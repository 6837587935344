import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

/**
 * No recursive fragments allowed.
 * NavAccordions nest 2 levels deep.
 */
export const FETCH_SITE_FIXTURES = gql(`
  query GetSiteFixtures($handle: String!, $preview: Boolean = false) {
    siteFixtures(handle: $handle, preview: $preview) {
      disableOneTrust
      disableOptimizely
      embroideryIcon
      enablePDPAccentColor
      id
      handle
      miniCartMessage
      embroideryWarningMessage
      logoColorSelectionTooltip
      logoDigitizationDisclaimer
      logoDigitizationWarning
      logoLearnMorelink
      navigation {
        name
        desktopTabs {
          ...navTab
        }
        mobileTabs {
          ...navTab
        }
        helpLinkText
        helpLinkHref
      }
      siteWidePromo
      enableSiteWidePromo
      tooltips {
        id
        contentType
        enumValue {
          name
        }
        title
        description
      }
      footer {
        copyrightLine
        accessibilityButtonText
        desktopSections {
          __typename
          ... on NavListSection {
            ...navListSection
          }
          ... on NavTileSection {
            ...navTileSection
          }
          ... on RawHtmlSection {
            ...rawHtmlSection
          }
          ... on NavAccordion {
            ...navAccordionSection
          }
        }
        mobileSections {
          __typename
          ... on NavListSection {
            ...navListSection
          }
          ... on NavTileSection {
            ...navTileSection
          }
          ... on RawHtmlSection {
            ...rawHtmlSection
          }
          ... on NavAccordion {
            ...navAccordionSection
          }
        }
        socialLinks {
          ...navListItem
        }
        legalLinks {
          ...navListItem
        }
      }
      promoBar {
        backgroundColor
        textColor
        pages {
          id
          content
        }
      }
      upsellHandles
      nostoUpsell
      nostoUpsellId
      regionData {
        regions {
          id
          name
          flagSrc
          shippingText
          taxAndDutyText
          welcomeShippingText
          welcomeTaxAndDutyText
          promoBarRegion {
            backgroundColor
            textColor
            pages {
              id
              content
            }
          }
        }
      }
      localeData {
        locales {
          language
          locale
        }
        name
      }
    }
  }
  fragment navTab on NavTab {
    href
    id
    text
    textColor
    sections {
      __typename
      ... on NavListSection {
        ...navListSection
      }
      ... on NavListGroupSection {
        ...navListGroupSection
      }
      ... on NavTileSection {
        ...navTileSection
      }
      ... on RawHtmlSection {
        ...rawHtmlSection
      }
      ... on NavAccordion {
        ...navAccordionSection
      }
    }
    alternateModelVariant {
      validContentfulModel {
        ... on NavTab {
          href
          id
          text
          textColor
          sections {
            __typename
            ... on NavListSection {
              ...navListSection
            }
            ... on NavListGroupSection {
              ...navListGroupSection
            }
            ... on NavTileSection {
              ...navTileSection
            }
            ... on RawHtmlSection {
              ...rawHtmlSection
            }
            ... on NavAccordion {
              ...navAccordionSection
            }
          }
        }
      }
      name
      action
      targetCriteria {
        name
        targetLogic
        targetCriteria
        inRegion
      }
    }
  }
  fragment navAccordionSection on NavAccordion {
    id
    variant
    sections {
      id
      header {
        ...navListItem
      }
      body {
        __typename
        ... on NavListSection {
          ...navListSection
        }
        ... on NavTileSection {
          ...navTileSection
        }
        ... on RawHtmlSection {
          ...rawHtmlSection
        }
        ... on NavAccordion {
          id
          sections {
            id
            header {
              ...navListItem
            }
            body {
              __typename
              ... on NavListSection {
                ...navListSection
              }
              ... on NavTileSection {
                ...navTileSection
              }
              ... on RawHtmlSection {
                ...rawHtmlSection
              }
            }
          }
        }
      }
      alternateModelVariant {
        action
        name
        targetCriteria {
          name
          targetLogic
          targetCriteria
          inRegion
        }
      }
    }
  }
  fragment navListSection on NavListSection {
    id
    variant
    items {
      ...navListItem
    }
  }
  fragment navListGroupSection on NavListGroupSection {
    id
    navSections {
      ...navListSection
    }
  }
  fragment navListItem on NavListItem {
    badge
    badgeColor
    badgeTextColor
    href
    icon
    id
    isBold
    isHeading
    text
    textColor
    htmlId
    htmlClass
    isBtn
    alternateModelVariant {
      validContentfulModel {
        ... on NavListItem {
          badge
          badgeColor
          badgeTextColor
          href
          icon
          id
          isBold
          isHeading
          text
          textColor
          htmlId
          htmlClass
          isBtn
        }
      }
      name
      action
      targetCriteria {
        name
        targetLogic
        targetCriteria
        inRegion
      }
    }
  }
  fragment navTileSection on NavTileSection {
    id
    tiles {
      ctaText
      href
      image
    }
  }
  fragment rawHtmlSection on RawHtmlSection {
    id
    html
  }
`)
