import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const House: React.FC<IProps> = ({
  height = '24px',
  fill = '#282828',
  width = '24px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 5L5 11V19H8V14H11V19H19V11L12 5Z'
      />
    </BaseIcon>
  )
}
