export const headerHeightPixels = 60
export const headerHeight = `${headerHeightPixels}px`

export const searchBarPixels = 60
export const searchBarHeight = `${searchBarPixels}px`

export const promoBarHeightMobilePixels = 30
export const promoBarHeightMobile = `${promoBarHeightMobilePixels}px`
export const promoBarHeightDesktopPixels = 40
export const promoBarHeightDesktop = `${promoBarHeightDesktopPixels}px`
export const promoBarHeightClosedPixels = 0

export const miniCartWidthMobile = '100%'
export const miniCartWidthDesktop = '600px'

export const draggableProductDetailsCardPreviewHeight = '204px'
