import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Hamburger: React.FC<IProps> = ({
  className = '',
  height = '20px',
  stroke = 'currentColor',
  width = '20px',
}) => {
  return (
    <BaseIcon {...{ className, height, width }} viewBox='0 0 24 24'>
      <path fillRule='evenodd' clipRule='evenodd' d='M24 8.75H0V7.25H24V8.75Z' fill={stroke} />
      <path fillRule='evenodd' clipRule='evenodd' d='M24 16.75H0V15.25H24V16.75Z' fill={stroke} />
    </BaseIcon>
  )
}
