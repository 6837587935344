import styled from 'styled-components'

import { TypeStyle, mdStartPixels } from '@syconium/little-miss-figgy'
import { Container } from '@syconium/magnolia/src/components/pages/products/ProductPage/DetailsSection/MultiColorSection/styles'

import { ColorSection } from '../../VariantAttributeSelectors/styles'

const sidePadding: string = '14px'

export const Body = styled.div`
  overflow-y: scroll;
  padding-bottom: 24px;
  max-width: ${mdStartPixels}px;
  margin: 0 auto;
  ${Container} {
    margin-top: 20px;
  }
`

export const Header = styled(TypeStyle.HeadlineExtraSmall)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 24px;
`

export const ProductPreview = styled.div`
  display: flex;
  padding: 0 ${sidePadding};
`

export const ProductSummary = styled.div`
  align-items: start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-inline-start: 16px;
`

export const Style = styled.p`
  ${TypeStyle.css.headlineExtraExtraSmall};
`

export const ProductImageWrapper = styled.div`
  height: 80px;
  width: 80px;
`

export const AddToBagButtonSection = styled.section`
  margin-top: 24px;
  padding: 0 ${sidePadding};
`

export const VariantAttributeSelectorsWrapper = styled.div`
  padding: 0 ${sidePadding};

  ${ColorSection} {
    margin-top: 24px;
  }

  [data-testid='base-attribute-selector'] {
    margin: 0 -${sidePadding};
    padding: 0 ${sidePadding};
  }
`

export const ColorNameWrap = styled.p`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  line-height: 21px;
  font-size: 14px;
  font-weight: 700;
`
