import { cookieKeys } from '../../app/_config/Cookies.config'
import { useCookies } from '../../app/_providers/CookiesProvider.client'

export type InitialSession = {
  isInitialSession: boolean
  hasPriorSession: boolean
}

/**
 * Set initial session and return customer as a cookie on a users first visit
 * We use cookies here insteak of session/local storage in order to support SSR for
 * alternate contentful models
 */
export function useInitialSession(): InitialSession {
  const [cookies, setCookie] = useCookies([
    cookieKeys.initialSessionIndicator.key,
    cookieKeys.priorPageViewIndicator.key,
  ])

  if (!cookies[cookieKeys.priorPageViewIndicator.key]) {
    setCookie(
      cookieKeys.priorPageViewIndicator.key,
      'true',
      cookieKeys.priorPageViewIndicator.options
    )
    setCookie(
      cookieKeys.initialSessionIndicator.key,
      'true',
      cookieKeys.initialSessionIndicator.options
    )
  }

  return {
    isInitialSession: cookies[cookieKeys.initialSessionIndicator.key] === 'true',
    hasPriorSession: !cookies[cookieKeys.initialSessionIndicator.key], // we cant use figs-prior-session here because it will be true during first session/visit
  }
}
