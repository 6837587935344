import Link from 'next/link'
import { FC } from 'react'

import { Logo } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { DEFAULT_NAV_ELEMENT } from '../../../constants'
import { GenderPreferenceContainer } from '../../../containers/GenderPreferenceContainer'

import { LogoBody } from './styles'

export const NavLogo: FC = () => {
  const {
    magnolia: { navigation: strings },
  } = useFixturesContext()
  const { genderPreference } = GenderPreferenceContainer.useContainer()

  const href = genderPreference ? `/pages/${genderPreference}-home` : '/'

  return (
    <LogoBody data-testid='nav-header-logo-body'>
      <Link
        id={DEFAULT_NAV_ELEMENT}
        href={href}
        title={strings.logoTitle}
        {...trackEvent({ category: 'navigation', action: 'click logo' })}
      >
        <Logo fill={'currentColor'} />
      </Link>
    </LogoBody>
  )
}
