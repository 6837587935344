import styled from 'styled-components'

import { TypeStyle, withoutHoverSupport } from '@syconium/little-miss-figgy'

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(2)};
`

export const TooltipTitle = styled(TypeStyle.BodyMicro)`
  text-transform: none;
  ${withoutHoverSupport} {
    font-weight: 700;
    font-size: 16px;
  }
`

export const TooltipDescription = styled(TypeStyle.BodySmall)`
  text-transform: none;
  font-weight: 400;
  ${withoutHoverSupport} {
    font-size: 16px;
  }
`

export { SoldOut } from '../styles'
