import LazyHydrate from 'react-lazy-hydration'

import { MiniCartAndOverlay } from '@syconium/magnolia/src/brunswick/components/MiniCartAndOverlay'
import { siteFixturesQueryOpts } from '@syconium/magnolia/src/brunswick/containers/fixtures/hooks/useCmsProvidedSiteFixtures'
import { IntlWelcomeModal } from '@syconium/magnolia/src/components/modals/IntlWelcomeModal'
import { singleQueryParamValue } from '@syconium/magnolia/src/lib/routing'
import { ApolloClientProfile } from '@syconium/weeping-figs'

import { CartRecoveryContainer } from '../../../containers/CartRecoveryContainer'
import { UnsupportedBanner } from '../../chrome/UnsupportedBanner'
import { NavFooter } from '../../nav/NavFooter'
import { NavHeader } from '../../nav/NavHeader'
import { PromoBarFromContext } from '../../nav/PromoBar'
import { SkipToMainContentLink } from '../../nav/SkipToMainContentLink'
import { SearchOverlay } from '../../pages/search/Search/SearchOverlay'
import { PageLayoutConfig } from '../types'

interface PrimaryLayoutProps {
  children?: React.ReactNode
}

function PrimaryLayout({ children }: PrimaryLayoutProps) {
  return (
    <CartRecoveryContainer.Provider>
      <SkipToMainContentLink />

      <UnsupportedBanner />

      <LazyHydrate whenIdle>
        <SearchOverlay />
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <PromoBarFromContext />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <NavHeader />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <>{children}</>
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <NavFooter />
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <IntlWelcomeModal />
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <MiniCartAndOverlay />
      </LazyHydrate>
    </CartRecoveryContainer.Provider>
  )
}

export const primaryLayoutConfig: PageLayoutConfig = {
  Layout: ({ content, options: _options }) => <PrimaryLayout>{content}</PrimaryLayout>,
  preloadDataForLayout: pageContext => {
    const content: string = singleQueryParamValue(pageContext.query.content)
    const contentClient = pageContext.apolloClientFactory.for(
      ApolloClientProfile.FigsPublicSupergraph
    )

    // @TODO: remove `any` generics for full type-safety
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return contentClient.query<any, any>(siteFixturesQueryOpts('default', content === 'preview'))
  },
}
