import { CarouselProductSummary } from '@syconium/magnolia/src/types/figs'

let id = 1

function uuid(): string {
  return (++id).toString()
}

export const productSummaries: readonly CarouselProductSummary[] = [
  {
    category: 'Scrub Tops',
    colorDisplayName: 'Ceil Blue',
    colorRawName: 'Ceil Blue',
    colorCount: 6,
    currency: 'USD',
    discountPrice: null,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Catarina-Top_ceilblue-1.jpg?v=1617814604',
    price: 3800,
    priceRange: undefined,
    productGroupHandle: 'catarina-scrub-top',
    title: 'Catarina™ One-Pocket Scrub Top',
    handle: 'catarina-scrube-top-ceil-blue',
    shopifyId: '',
  },
  {
    category: 'Scrub Pants',
    colorDisplayName: 'Navy',
    colorRawName: 'Navy',
    colorCount: 9,
    currency: 'USD',
    discountPrice: null,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Catarina-Top_CRB-1.jpg?v=1629078685',
    price: 3800,
    priceRange: {
      max: 4200,
      min: 3600,
    },
    productGroupHandle: 'zamora-scrub-pants',
    title: 'Zamora™ Jogger Scrub Pants',
    handle: 'zamora-scrub-pants-navy',
    shopifyId: '',
  },
  {
    category: 'Lab Coats',
    colorDisplayName: 'White',
    colorRawName: 'White',
    colorCount: 4,
    currency: 'USD',
    discountPrice: 3200,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Bellevue-Long-Lab-Coat_1.jpg?v=1620240599',
    price: 3800,
    priceRange: undefined,
    productGroupHandle: 'bellevue-lab-coat',
    title: 'Bellevue Long Lab Coat',
    handle: 'bellevue-lab-coat-white',
    shopifyId: '',
  },
  {
    category: 'Outerwear',
    colorDisplayName: 'Black',
    colorRawName: 'Black',
    colorCount: 2,
    currency: 'USD',
    discountPrice: null,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Bellery-Scrub-JKT_black-1.jpg?v=1611713722',
    price: 3800,
    priceRange: undefined,
    productGroupHandle: 'bellery-scrub-jacket',
    title: 'Bellery Scrub Jacket',
    handle: 'bellery-scrub-jacket-black',
    shopifyId: '',
  },
  {
    category: 'Scrub Pants',
    colorDisplayName: 'Burgundy',
    colorRawName: 'Burgundy',
    colorCount: 1,
    currency: 'USD',
    discountPrice: null,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Yowah_Classic_Jogger-burgundy-1_673a4128-46a2-457d-961d-047dae89cc94.jpg?v=1622581235',
    price: 3800,
    priceRange: undefined,
    productGroupHandle: 'yowah-scrub-pants',
    title: 'Yowah Classic Jogger Scrub Pants',
    handle: 'yowah-scrub-pants-burgundy',
    shopifyId: '',
  },
  {
    category: 'Scrub Tops',
    colorDisplayName: 'Teal',
    colorRawName: 'Teal',
    colorCount: 3,
    currency: 'USD',
    discountPrice: null,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Women-Casma-Two-Pocket-Scrub-Top_teal-ABTest-1.jpg?v=1617214472',
    price: 3800,
    priceRange: undefined,
    productGroupHandle: 'casma-scrub-top',
    title: 'Casma™ Three-Pocket Scrub Top',
    handle: 'casma-scrub-top-teal',
    shopifyId: '',
  },
  {
    category: 'Scrub Tops',
    colorDisplayName: 'Royal Blue',
    colorRawName: 'Royal Blue',
    colorCount: 5,
    currency: 'USD',
    discountPrice: null,
    id: uuid(),
    image:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Chisec-Pocket-Scrub-Top_royal-blue-1.jpg?v=1606792523',
    price: 3800,
    priceRange: undefined,
    productGroupHandle: 'chisec-scrub-top',
    title: 'Chisec™ Three-Pocket Scrub Top',
    handle: 'chisec-scrub-top-royal-blue',
    shopifyId: '',
  },
]
