import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Info: React.FC<IProps> = ({
  height = '15px',
  fill = '#A3A3A3',
  width = '15px',
  ...rest
}) => {
  return (
    <BaseIcon
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <circle cx='7.5' cy='7.5' r='7' stroke={fill} />
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.875 11.25L6.875 6.25L8.125 6.25L8.125 11.25L6.875 11.25Z'
      />
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.875 5L6.875 3.75L8.125 3.75L8.125 5L6.875 5Z'
      />
    </BaseIcon>
  )
}
