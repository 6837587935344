import styled from 'styled-components'

import { pageLayoutMaxWidth } from '@syconium/little-miss-figgy'

export const Body = styled.div<{ hasVerticalMargin?: boolean }>`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0px auto;
  margin-bottom: ${o => (o.hasVerticalMargin ? '40px' : '0')};
  @media (max-width: 811px) {
    margin-bottom: 0px;
  }
  max-width: ${pageLayoutMaxWidth};
  position: relative;
`
