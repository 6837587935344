import { CSSProperties } from 'react'
import styled from 'styled-components'

import { Button as LMFButton, fromMd } from '@syconium/little-miss-figgy'

/**
 * Logic:
 *   - if isHighlighted (formerly isSoldOut), show black on white with border.
 *     on hover, no change.
 *   - else if isProcessing, show grey background and dark text.
 *     on hover, no change.
 *   - else if accentColor, use that background and white text.
 *     on hover, change background and border color to accentColor with 85% opacity.
 *   - else use selected buttonVariant color scheme (undefined here, to inherit)
 *     on hover, use selected buttonVariant hover color scheme.
 */
export const Button = styled(LMFButton)<{
  accentColor?: CSSProperties['color']
  isHighlighted?: boolean
  isProcessing?: boolean
}>(({
  accentColor,
  disabled,
  isHighlighted,
  isProcessing,
  theme: {
    color: {
      deprecated: { stoneLight, obsidianLighter },
    },
  },
}) => {
  const disableHover = disabled || isHighlighted || isProcessing

  return `
    background-color: ${isHighlighted ? 'white' : isProcessing ? stoneLight : accentColor};
    border-color: ${isHighlighted ? 'black' : isProcessing ? stoneLight : accentColor};
    color: ${
      isHighlighted ? 'black' : isProcessing ? obsidianLighter : accentColor ? 'white' : undefined
    };

    &:focus {
      border: solid;
      outline: 2px solid black;
    }

    ${fromMd} {
      &:hover {
        background-color: ${
          disableHover ? 'initial' : accentColor ? getHoverColor(accentColor) : undefined
        };
        border-color: ${
          disableHover ? 'initial' : accentColor ? getHoverColor(accentColor) : undefined
        };
        color: ${disableHover ? 'initial' : accentColor ? 'white' : undefined};
      }
    }
  `
})

export const LoadingSpinnerWrapper = styled.div`
  margin-right: 15px;
`

// Convert CSS color from 6-digit to 8-digit hex with 85% opacity
const getHoverColor = (x: CSSProperties['color']) =>
  /^#.{6}$/.test(x?.toString() ?? '') ? `${x}d9` : x
