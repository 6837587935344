import styled from 'styled-components'

import { ResponsiveImage as LMFResponsiveImage } from '@syconium/little-miss-figgy'

export const navTilePadding = '8px'
export const navTileWidth = '256px'

const width = 256
const height = 125

export const navTileAspectRatio = width / height

export const Body = styled.figure`
  display: inline-block;
  padding: ${navTilePadding};
  width: ${width}px;
`

export const ResponsiveImage = styled(LMFResponsiveImage)`
  height: ${height}px;
  object-fit: cover;
  font-family: 'object-fit: cover;';
`

export const Figcaption = styled.figcaption<{
  isUppercase: boolean
}>`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: ${o => (o.isUppercase ? '0.15em' : '0.05em')};
  line-height: 1.5;
  margin-top: 8px;

  a {
    margin-right: 12px;
  }

  svg {
    flex-shrink: 0;
  }
`
