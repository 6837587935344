import styled, { CSSProperties } from 'styled-components'

import { fromMd, timingSlower } from '@syconium/little-miss-figgy'
import { headerHeight, headerHeightPixels } from '@syconium/magnolia/src/brunswick/constants'

export const ExpansionPanelWrapper = styled.div<{
  promoBar: boolean
  promoBarHeightDesktopPixels: number
  promoBarHeightMobilePixels: number
  scrollDirection: string
}>`
  background-color: white;
  left: 0;
  position: fixed;
  right: 0;
  top: ${o =>
    headerHeightPixels +
    (o.promoBar && !o.scrollDirection ? o.promoBarHeightMobilePixels : 0) -
    1}px;
  z-index: ${o => o.theme.zIndex.modals + 10};

  ${fromMd} {
    top: ${o =>
      headerHeightPixels +
      (o.promoBar && !o.scrollDirection ? o.promoBarHeightDesktopPixels : 0) -
      1}px;
  }

  #brunswick-root & {
    top: calc(${headerHeight} - 1px);
  }
`

export const StyledExpansionPanelDetails = styled.div`
  box-sizing: border-box;

  ${fromMd} {
    padding-top: 52px;
    padding-bottom: 100px;
  }

  > * {
    margin: auto;
  }
`

export const Overlay = styled.div<{
  promoBarHeightDesktop: CSSProperties['height']
  promoBarHeightMobile: CSSProperties['height']
  shouldDisplaySearchOverlay: boolean
}>`
  background: black;
  bottom: 0;
  left: 0;
  opacity: ${o => (o.shouldDisplaySearchOverlay ? 0.6 : 0)};
  pointer-events: ${o => !o.shouldDisplaySearchOverlay && 'none'};
  position: fixed;
  right: 0;
  top: calc(${headerHeight} + ${o => o.promoBarHeightMobile});
  transition: opacity ${timingSlower} ease;
  z-index: ${o => o.theme.zIndex.modals};

  ${fromMd} {
    top: calc(${headerHeight} + ${o => o.promoBarHeightDesktop});
  }

  #brunswick-root & {
    top: ${headerHeight};
  }
`
