import { QuickBuyContainer } from '../../containers/QuickBuyContainer'

import { QuickBuyModalWithData } from './QuickBuyModalWithData'

export const QuickBuyModal: React.FC = () => {
  const { closeModal, state } = QuickBuyContainer.useContainer()

  if (state.modalState === 'Unopened') return null

  return <QuickBuyModalWithData {...{ closeModal, quickBuyState: state }} />
}
