/* eslint-disable rulesdir/require-next-modal-react-modal-wrapper */
import { useRouter } from 'next/router'
import { ComponentProps, useEffect, useReducer, useRef } from 'react'
import ReactModal, { Styles } from 'react-modal'

ReactModal.setAppElement('#__next')

type UseNextModalOptions = Omit<
  ComponentProps<typeof ReactModal>,
  'onRequestClose' | 'appElement'
> & {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent | undefined) => void
}

type UseNextModalResults = UseNextModalOptions

function useNextModal(options: UseNextModalOptions): UseNextModalResults {
  // https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [_ignored, forceUpdate] = useReducer(x => x + 1, 0)
  const shouldForceUpdateRef = useRef(false)

  const router = useRouter()
  const onRequestClose = options.onRequestClose

  useEffect(() => {
    const routeChangeStart = (url: string) => {
      // Do not consider query param changes good enough reason to request a modal close.
      const destinationPathname = typeof url === 'string' ? url.split('?')[0] : undefined
      if (destinationPathname === window.location.pathname) return

      // If we are starting a close during a route change, we may get stuck in the before-close state
      // so we need to force a rerender once the route change completes.
      shouldForceUpdateRef.current = true
      onRequestClose?.(undefined)
    }

    const routeChangeComplete = () => {
      if (shouldForceUpdateRef.current) {
        shouldForceUpdateRef.current = false
        forceUpdate()
      }
    }

    // If a route change starts while we are open, close ourselves.
    if (options.isOpen) {
      router.events.on('routeChangeStart', routeChangeStart)
    }

    // Always on completion run a possible rerender
    router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [onRequestClose, options.isOpen, router])

  return options
}

export type { Styles as NextModalStyles }

export const NextModal = (props: UseNextModalOptions) => {
  const nextSafeProps = useNextModal(props)
  return <ReactModal {...nextSafeProps} />
}
