import { CSSProperties } from 'react'
import styled, { keyframes } from 'styled-components'

import { zIndexAlert } from '@syconium/little-miss-figgy'

const xyAnimation = (x: number, y: number) => keyframes`
  100% {
    height: 18px;
    transform: translateX(${x}px) translateY(${y}px);
    width: 18px;
  }
`

const buttonClickAnimation = keyframes`
  90% {
    opacity: 1;
  }
  100% {
    border-radius: 50px;
    opacity: 0;
    width: 50px;
  }
`
export const ButtonComponentWrapper = styled.div<{ isHidden?: boolean }>`
  opacity: ${o => (o.isHidden ? 0 : 1)};
  transition-duration: 0.7s;
  width: 100%;
`

export const ButtonAnimation = styled.div<{ animationColor?: CSSProperties['color'] | null }>`
  animation: ${buttonClickAnimation} 0.5s;
  background: ${o => o.animationColor ?? 'black'};
  border-radius: 4px;
  height: 50px;
  position: absolute;
  width: 100%;
`

export const AddToBagButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`

export const MobileAddToBagAnimation = styled.div<{
  animationColor?: CSSProperties['color'] | null
  translationX: number
  translationY: number
}>`
  animation: ${o => xyAnimation(o.translationX, o.translationY)} 0.5s ease;
  background: ${o => o.animationColor ?? 'black'};
  border-radius: 50%;
  height: 50px;
  position: absolute;
  top: 0;
  width: 50px;
  z-index: ${zIndexAlert};
`

export const BagIconWrapper = styled.span<{ isAnimating?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  transform: ${o => (o.isAnimating ? 'scale(1.1)' : 'scale(1)')};
  transition-duration: 0.3s;
`
