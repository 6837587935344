/* istanbul ignore file */
import { ReactElement } from 'react'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

import type { Props } from './types'

export const QuickBuyModalWithData = (props: Props): ReactElement => {
  return <Template {...useViewModel(props)} />
}
