import React from 'react'
import styled from 'styled-components'

/**
 * Center text, accounting for letter-spacing.
 */

export const CenterText: React.FC<{
  className?: string
  letterSpacing: string
  children?: React.ReactNode
}> = ({ children, className, letterSpacing }) => {
  return <Body {...{ className, letterSpacing }}>{children}</Body>
}

/**
 * Negative right margin handles inherent imbalance in letter-spacing.
 * `justify-content: center` handles both narrow content and overflow.
 * `text-align: center` aligns multiple lines of text when text wraps.
 */
export const Body = styled.div<{
  letterSpacing: string
}>`
  display: flex;
  justify-content: center;
  letter-spacing: ${o => o.letterSpacing};
  margin-right: -${o => o.letterSpacing};
  text-align: center;
`
