import { IDeserializable } from '@syconium/magnolia/src/brunswick/interfaces/ISerializer'

import { CartItem } from '../../../../types/figs'
import { ShopifyCartItem } from '../../../../types/shopify'

export class ShopifyCartItemSerializer implements IDeserializable<CartItem, ShopifyCartItem> {
  public deserialize(shopifyCartItem: ShopifyCartItem): CartItem {
    return {
      image: shopifyCartItem.featured_image,
      key: shopifyCartItem.key,
      fit: '',
      fullPrice: shopifyCartItem.original_price,
      effectivePrice: shopifyCartItem.final_price,
      handle: shopifyCartItem.handle,
      productGroupHandle: shopifyCartItem.handle,
      productId: shopifyCartItem.product_id.toString(),
      productType: shopifyCartItem.product_type,
      properties: shopifyCartItem.properties ?? {},
      quantity: shopifyCartItem.quantity,
      sku: shopifyCartItem.sku,
      title: '',
      description: '',
      variantId: shopifyCartItem.variant_id.toString(),
      finalSale: shopifyCartItem.final_sale,
    }
  }
}
