import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export interface BagProps extends IProps {
  viewBox?: string
}

export const Bag: React.FC<BagProps> = ({
  height = '26px',
  stroke = 'currentColor',
  width = '26px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <g
        {...{ stroke }}
        fill='none'
        fillRule='evenodd'
        strokeWidth='1.5'
        transform='translate(6 6)'
      >
        <path d='M3.72 4.83V1.4a.73.73 0 0 1 .71-.75h4a.73.73 0 0 1 .71.75v3.43' />
        <rect width='12.4' height='9.2' x='.13' y='4.52' rx='.46' />
      </g>
    </BaseIcon>
  )
}
