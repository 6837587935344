export * from './utils'

import { useRouter } from 'next/router'
import * as React from 'react'
import { createContainer } from 'unstated-next'

import { sessionStorageKeys } from '../../../app/_config/Session.config'

import { mapFrom } from './utils'

function useFeatureFlags() {
  const router = useRouter()

  const getSessionStoredFeature: () => string = (): string => {
    return globalThis.sessionStorage?.getItem(sessionStorageKeys.deprecatedFeatureFlags) ?? ''
  }

  const [sessionStoredFlag] = React.useState<string>(getSessionStoredFeature())

  const featureMap: Record<string, boolean> = mapFrom(router.query.features)

  const isFeatureEnabled = (key: string) => !!featureMap[key] || sessionStoredFlag === key

  return {
    isFeatureEnabled,
  }
}

export const FeatureFlagService = createContainer(useFeatureFlags)
