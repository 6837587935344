'use client'

import { createGlobalStyle } from 'styled-components'

import { mediaStyles } from '@syconium/little-miss-figgy'

import { preventBodyScrollClassName } from './constants'
import { imageZoomStyles } from './image-zoom-styles'
import { navModalStyles } from './nav-modal-styles'
import { pureReactCarouselStyles } from './pure-react-carousel-styles'
import { reactModalVideoStyles } from './react-modal-video-styles'
import { solidModalStyles } from './solid-modal-styles'
import { standardModalStyles } from './standard-modal-styles'
import { stickyAddToBagStyles } from './stickyAddToBagStyles'
import { videoModalStyles } from './video-modal-styles'

/**
 * DO NOT ADD MORE THINGS TO THIS FILE
 *
 * Styled components can provide scoped styles to different components that they wrap.
 * Adding global styles here defeats the purpose of styled-components in most cases.
 *
 * Many of these should likely be converted to just styling components directly.
 * Please be certain you need to add things globally before doing so. As these styles
 * go stale they often get forgotten and leave bloat behind on the page.
 *
 * They also cause issues where we are loading these on pages that don't even need them.
 */
export const GlobalStyles = createGlobalStyle`
  ${pureReactCarouselStyles}
  ${imageZoomStyles}
  ${navModalStyles}
  ${reactModalVideoStyles}
  ${solidModalStyles}
  ${standardModalStyles}
  ${stickyAddToBagStyles}
  ${videoModalStyles}

  ${mediaStyles}

  .${preventBodyScrollClassName} {
    overflow: hidden;
  }
`
