import styled from 'styled-components'

import { navTilePadding } from '../NavTile/styles'

export const Body = styled.div`
  overflow: hidden;
`

// ScrollWrap allows Body to be overflow: hidden to trim negative margin bit
export const ScrollWrap = styled.div`
  margin: -${navTilePadding};
  overflow-x: auto;
  white-space: nowrap;

  figure {
    white-space: initial;
  }
`
