import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_FEATURED_PRODUCT = gql(`
  query GetFeaturedProduct(
    $productGroupHandle: String!
    $color: String
    $fit: String
    $selectedColor: String
    $selectedFit: String
  ) {
    productGroup(
      handle: $productGroupHandle
      selectedColor: $selectedColor
      selectedFit: $selectedFit
    ) {
      id
      featuredProduct(color: $color, fit: $fit) {
        ...FeaturedProduct
      }
    }
  }
`)
