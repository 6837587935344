import { keyframes } from 'styled-components'

import { zIndexModal } from '@syconium/little-miss-figgy'

import { NextModalStyles } from '../../components/modals/NextModal'

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const fadeUpAndIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const spin = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const videoModalStyles: NextModalStyles = {
  content: {
    background: 'transparent',
    borderRadius: 0,
    borderWidth: 0,
    bottom: 0,
    left: 0,
    padding: 0,
    right: 0,
    top: 0,
  },
  overlay: {
    backgroundColor: 'intentionally invalid',
  },
}

export const solidModalStyles: NextModalStyles = {
  content: {
    borderRadius: '8px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    overflow: 'visible',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: zIndexModal,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}

export const navModalStyles: NextModalStyles = videoModalStyles
