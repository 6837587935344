import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { LocalizationContainer } from '../../brunswick/containers/localization/LocalizationContainer'
import { AuthenticationContainer } from '../../containers/AuthenticationContainer'
import { TargetCriteria, TargetCriteriaTypes } from '../../types/graphql'

import { useInitialSession } from './useInitialSession'

type TargetCriteriaOptions = {
  inRegion?: SupportedRegionIdentifier[]
}

export const useTargetCriteria = () => {
  const { isInitialSession, hasPriorSession } = useInitialSession()
  const { isLoggedIn } = AuthenticationContainer.useContainer()
  const { region } = LocalizationContainer.useContainer()

  const checkCriteria = (targetCriteria?: TargetCriteria): boolean => {
    const options: TargetCriteriaOptions = {
      inRegion: targetCriteria?.inRegion,
    }
    if (!targetCriteria || !targetCriteria?.targetLogic || !targetCriteria?.targetCriteria)
      return false
    switch (targetCriteria.targetLogic) {
      case 'Match Any':
        return targetCriteria.targetCriteria.some(c => checkTargetCriteria(c, options))
      case 'Match All':
        return targetCriteria.targetCriteria.every(c => checkTargetCriteria(c, options))
      default:
        return false
    }
  }

  const checkTargetCriteria = (
    criteria: TargetCriteriaTypes,
    options: TargetCriteriaOptions
  ): boolean => {
    switch (criteria) {
      case 'Initial Session':
        return isInitialSession
      case 'Has Prior Session':
        return hasPriorSession
      case 'Logged In':
        return isLoggedIn
      case 'In Region':
        return inRegion(options.inRegion)
      default:
        return false
    }
  }

  const inRegion = (regions?: SupportedRegionIdentifier[]) => {
    if (!regions) return false
    if (regions.includes(region.id)) return true
    return false
  }

  return {
    checkCriteria,
    isInitialSession,
    hasPriorSession,
    isLoggedIn,
  }
}
