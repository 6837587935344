import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 28px;
  border: 1px solid ${o => o.theme.color.deprecated.obsidianLighter};
  border-radius: 4px;
`

export const Button = styled.button`
  border: none;
  cursor: ${o => (o.disabled ? 'not-allowed' : 'pointer')};
  font-size: 13px;
  width: 28px;
`

export const Input = styled.input`
  border: none;
  color: inherit;
  font-weight: 600;
  text-align: center;
  width: 40px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const SpinnerWrapper = styled.div`
  align-items: center;
  background: ${o => o.theme.color.deprecated.stoneLighter};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 2px;
  top: 0;
  width: 40px;
`
