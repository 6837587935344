import { ProductColor } from '../../types/graphql'

export function makeCanonicalProductUrl(
  baseUrl: string,
  colors: ReadonlyArray<ProductColor>,
  handle: string,
  colorKey: string
) {
  // Sort colors by length, descending, for, e.g. "royal-blue" and "deep-royal-blue"
  // where a color name is part of another color name. We search for longest first.
  const sortedColors = colors.slice().sort((a, b) => b.handle.length - a.handle.length)
  const handleColor = sortedColors.find(color => handle.includes(color.handle))

  // e.g. womens-casma-scrub-top-teal -> womens-casma-scrub-top
  const canonicalHandle = handleColor?.handle
    ? handle.replace(`-${handleColor.handle}`, '')
    : handle

  const query = `?color=${encodeURIComponent(colorKey)}`

  return `${baseUrl}${canonicalHandle}${query}`
}
