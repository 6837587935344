import { createContainer } from 'unstated-next'

import { asyncGlobalVariable, isBrowser } from '@syconium/magnolia/src/lib/utils'

interface HeapEvent {
  eventName: string
  props: Record<string, string>
}

const isHeapPresent = () => {
  return typeof globalThis.heap !== 'undefined'
}

const heap$ = asyncGlobalVariable(() => globalThis.heap)

const sendHeapEvent = ({ eventName, props }: HeapEvent) => {
  if (!isBrowser()) return
  if (isHeapPresent()) {
    globalThis.heap.track(eventName, props)
  } else {
    heap$.subscribe(() => {
      if (isHeapPresent()) {
        globalThis.heap.track(eventName, props)
      }
    })
  }
}
const useHeapTrack = () => {
  return { sendHeapEvent }
}

export const HeapTrackContainer = createContainer(useHeapTrack)
