import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;
  justify-content: center;
`

export const UserInitials = styled.div`
  color: ${o => o.theme.color.text.primary.on.subtle};
  align-items: center;
  background: #e6e6e6;
  border-radius: 50%;
  display: flex;
  font-size: 9px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  text-transform: uppercase;
  width: 24px;
`

export const LoggedInTextWrapper = styled.span`
  white-space: nowrap;
`
