import { FC, useRef } from 'react'
import { useObservable } from 'react-use'
import { of } from 'rxjs'
import { delay, switchMap } from 'rxjs/operators'

import { timingSlowerMs } from '@syconium/little-miss-figgy'

import { MiniCartSlideOutContainer } from '../../containers/mini-cart-slideout'
import { MiniCart } from '../MiniCart'

import { Overlay } from './styles'

export const MiniCartAndOverlay: FC = () => {
  const { isRevealed$, toggleIsRevealed } = MiniCartSlideOutContainer.useContainer()

  /**
   * hidden is not equivalent to !revealed.
   * "revealed" is used to animate the minicart in and out.
   * "hidden" is for aria/accessibility.
   * When animating out, we need to wait to apply hidden/aria-hidden until
   * after the animation completes, otherwise the element will just disappear
   * immediately.
   */
  const isHidden$ = useRef(
    isRevealed$.pipe(
      switchMap(revealed => {
        const newObs$ = of(!revealed)
        if (revealed) return newObs$
        return newObs$.pipe(delay(timingSlowerMs))
      })
    )
  ).current

  /**
   * When revealing the MiniCart, the hidden/aria-hidden attributes must be removed
   * from the container BEFORE transition animations begin. Accordingly, we wait until
   * the next iteration of the event loop:
   */
  const isRevealedNextTick$ = useRef(isRevealed$.pipe(delay(0))).current

  const isHidden = useObservable(isHidden$, true)
  const isRevealedNextTick = useObservable(isRevealedNextTick$, false)

  return (
    <>
      <Overlay
        aria-hidden={isHidden}
        hidden={isHidden}
        isMiniCartRevealed={isRevealedNextTick}
        onClick={toggleIsRevealed}
      />

      <MiniCart
        isHidden={isHidden}
        isRevealed={isRevealedNextTick}
        toggleIsRevealed={toggleIsRevealed}
      />
    </>
  )
}
