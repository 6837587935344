import { figsAuthedClient, figsPublicClient } from '../../../lib/graphql/apollo-clients'

/**
 * Legacy clients for Brunswick. Not used by Magnolia.
 *
 * - Brunswick uses this map for graph aliasing.
 */
export const initiallyStatelessClientMap = {
  figsPublicClient,
  figsAuthedClient: figsAuthedClient,
  shopifyStorefront: figsAuthedClient,
}
