import React, { FC } from 'react'

import { ResponsiveImage, ResponsiveImageProps } from '@syconium/little-miss-figgy'

import { StyledImage as IStyledImage } from '../../../__generated__/graphql/catalog/graphql'

import { StyledImageWrapper } from './styles'

export const ContentfulStyledImage: FC<
  IStyledImage & {
    widths: ResponsiveImageProps['widths']
    transparentBackground?: ResponsiveImageProps['transparentBackground']
    className?: string
  }
> = ({
  imageMobile,
  imageDesktop,
  maxWidthMobile,
  maxWidthDesktop,
  aspectRatioMobile,
  aspectRatioDesktop,
  altText,
  bottomMargin,
  widths,
  transparentBackground,
  className,
}) => {
  const imgSrc = imageDesktop ?? imageMobile

  if (!imgSrc) return null

  return (
    <StyledImageWrapper
      marginBottom={bottomMargin}
      maxWidthDesktop={maxWidthDesktop}
      maxWidthMobile={maxWidthMobile}
      className={className}
    >
      <ResponsiveImage
        src={imgSrc}
        aspectRatios={{
          sm: aspectRatioMobile,
          md: aspectRatioDesktop,
        }}
        loading='lazy'
        alt={altText}
        srcs={{
          sm: imageMobile ?? imgSrc,
          md: imageDesktop ?? imgSrc,
        }}
        widths={widths}
        transparentBackground={transparentBackground}
      />
    </StyledImageWrapper>
  )
}
