import React, { useMemo, useTransition } from 'react'

import { CenterText } from '@syconium/magnolia/src/brunswick/components/atoms/CenterText'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'

import { VisualFilterTile } from '../../../../../../../../../types/graphql'
import { useCollectionFilterQueryParamsContext } from '../../../../../../../../containers/CollectionFilterQueryParamsContext'
import { useCollectionFiltersContext } from '../../../containers/CollectionFiltersContext'

import { Body, Figcaption, ImageWrapper, Label, Image as StyledImage } from './styles'

interface Props extends VisualFilterTile {
  rollingFilters: string[]
  toggleRollingFilter: (filter: string) => void
  visibleTilesDesktop: number
  visibleTilesMobile: number
}

export const VisualFilterSectionTile: React.FC<Props> = ({
  ctaText,
  image,
  id,
  value,
  filter: filterFromField,
  filterSpec,
  toggleRollingFilter,
  rollingFilters,
  visibleTilesDesktop,
  visibleTilesMobile,
}) => {
  const tileVariantDesktop: 'circle' | 'rectangle' = 'circle'
  const tileVariantMobile: 'circle' | 'rectangle' = 'circle'

  const { updateFilterSelection, selectedFilters } = useCollectionFilterQueryParamsContext()
  const { filters } = useCollectionFiltersContext()
  const [isUpdateFiltersTransitionPending, startUpdateFiltersTransition] = useTransition()
  const isChecked = useMemo(() => {
    const includedInFilters = Object.values(selectedFilters).flat().includes(value)
    return isUpdateFiltersTransitionPending ? !includedInFilters : includedInFilters
  }, [isUpdateFiltersTransitionPending, selectedFilters, value])

  const filter = filterSpec?.name || filterFromField

  const getVisualFilterType = useMemo(
    () => filters?.find(f => f.displayName.toLocaleLowerCase() === filter),
    [filters, filter]
  )

  const letterSpacing = useMemo(() => (isUppercase(ctaText) ? '0.15em' : '0.05em'), [ctaText])

  const isDisabled = useMemo(
    () => getVisualFilterType?.filterValues?.some(o => o.value === value && o.state === 'DISABLED'),
    [getVisualFilterType, value]
  )

  const toggleChecked = () => {
    if (isUpdateFiltersTransitionPending) return
    startUpdateFiltersTransition(() => {
      updateFilterSelection(filter, value, !isChecked)
      toggleRollingFilter(value)
    })
  }

  return (
    <Body
      tileVariantDesktop={tileVariantDesktop}
      tileVariantMobile={tileVariantMobile}
      isDisabled={!!isDisabled}
      isChecked={isChecked}
    >
      <Label
        htmlFor={`checkbox-${id}`}
        {...trackEvent({
          customAttributes: {
            'data-heap-rolling-filters': rollingFilters.join(','),
            'data-heap-visual-filter-value': value,
            'data-heap-filter-type': 'visual',
          },
          category: 'page-tile-section-tile',
          action: 'click',
          label: value,
        })}
      >
        <input
          disabled={!!isDisabled}
          checked={isChecked}
          type='checkbox'
          id={`checkbox-${id}`}
          style={{ display: 'none' }}
          onChange={toggleChecked}
        />
        <ImageWrapper>
          <StyledImage
            alt={ctaText}
            src={image}
            loading='lazy'
            aspectRatios={{
              sm: 1,
              md: 1,
            }}
            widths={{
              unit: 'vw',
              sm: 100 / visibleTilesMobile,
              md: 100 / visibleTilesDesktop,
            }}
          />
        </ImageWrapper>
      </Label>

      {ctaText?.length > 0 ? (
        <Figcaption
          isUppercase={isUppercase(ctaText)}
          isDisabled={isDisabled || false}
          isChecked={isChecked}
          onClick={() => {
            if (!isDisabled) {
              toggleChecked()
            }
          }}
        >
          <CenterText {...{ letterSpacing }}>{ctaText}</CenterText>
        </Figcaption>
      ) : null}
    </Body>
  )
}
