import { FC, HTMLAttributes } from 'react'

import { LoadingSpinner } from '@syconium/little-miss-figgy'

import { TotalAmount, TotalLabel, TotalLine as TotalLineStyling } from '../Cart/styles'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  loading?: boolean
  label?: string
  price: string
}

/**
 * Display a total line in the Mini Cart.
 *
 * @param opts
 * @param [opts.loading] - If the total balance is pending
 * @param [opts.label] - The label to display for the total line.
 * @param [opts.price] - The price to display for the total line. This value is expected to already be formatted/localized.
 **/
export const TotalLine: FC<Props> = ({ loading, label, price, ...rest }) => {
  return (
    <TotalLineStyling>
      {label && <TotalLabel>{label}:</TotalLabel>}
      {loading ? (
        <LoadingSpinner label='loading' />
      ) : (
        <TotalAmount className='money' {...rest}>
          {price}
        </TotalAmount>
      )}
    </TotalLineStyling>
  )
}
