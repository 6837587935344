import styled from 'styled-components'

import { timingDefault } from '@syconium/little-miss-figgy'

export const FreeShippingContainer = styled.div`
  background: white;
  height: 43px;
  padding: 0 16.5px 0 16.5px;
  position: relative;

  ${o => o.theme.device.fromMd} {
    padding: 0 40px 0 40px;
  }
`

export const FreeShippingGoal = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  font-weight: 600;
`

export const FreeShippingTranslate = styled.div<{
  runAnimation?: boolean
}>`
  display: ${o => !o.runAnimation && 'flex'};
  margin-bottom: auto;
  margin-top: auto;
  transition: all 1s ease-out;
  padding-top: 10px;

  ${o =>
    o.runAnimation &&
    `
      & > div {
        display: inline-flex;
        transform: translateY(23%);
      }
  `}
`

export const Bar = styled.div`
  background: ${o => o.theme.color.deprecated.stone};
  border-radius: 4px;
  box-sizing: content-box;
  height: 6px;
  margin-top: 12px;
  max-width: 100%;
  position: relative;
`

export const Progress = styled.div<{
  progressAsPercent: number
}>`
  background: ${o => o.theme.color.deprecated.black.primary};
  border-radius: 100px;
  height: 100%;
  max-width: 100%;
  transition: width ${timingDefault} ease-in-out;
  width: ${o => o.progressAsPercent}%;
`

export const ProgressBar = {
  Bar,
  Progress,
}

export const CheckMarkWrap = styled.div`
  background-color: black;
  border-radius: 100px;
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;

  & > div > svg {
    color: white;
    display: block;
    margin-top: 6px;
  }
`

export const Fade = styled.div<{
  fadeDirection: 'in' | 'out'
  // Used to give unique names to each animation and for testing
  id: string
  runAnimation?: boolean
}>`
  ${o =>
    o.runAnimation &&
    `

    animation-direction: normal;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: ${o.id};
    animation-play-state: running;
    animation-timing-function: ease-${o.fadeDirection};

    @keyframes ${o.id}Fade${o.fadeDirection} {
      0% {
        opacity: ${o.fadeDirection === 'in' ? 0 : 1};
      }

      100% {
        opacity: ${o.fadeDirection === 'in' ? 1 : 0};
      }
    }
`}
`

export const FreeShippingFadeInAndDown = styled.div<{
  runAnimation?: boolean
}>`
  ${o =>
    o.runAnimation &&
    `
    animation-delay: 0.4s;
    animation-direction: normal;
    animation-duration: ${timingDefault};
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: FreeShippingFade;
    animation-play-state: running;
    animation-timing-function: ease-in;
    transition: transform 0.1s ease-in;
    @keyframes FreeShippingFade {
      0% {
        opacity: 0;
        transform: translateY(-35%);
      }

      100% {
        opacity: 1;
        transform: translateY(-12%);
      }
    }
  `}
`

export const CheckMarkCircleFade = styled(Fade)<{
  runAnimation?: boolean
}>`
  ${o =>
    o.runAnimation &&
    `
    animation-delay: 0.5s;
    animation-direction: normal;
    animation-duration: 0.8s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: FreeShippingCheckMarkCircleFade;
    animation-play-state: running;
    animation-timing-function: ease-in;
    transition: transform 0.1s ease-in;
    @keyframes FreeShippingCheckMarkCircleFade {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  `}
`

export const CheckMarkFade = styled(Fade)<{
  runAnimation?: boolean
}>`
  ${o =>
    o.runAnimation &&
    `
    animation-delay: 1.8s;
    animation-duration: 0.8s;
  `}
`

export const FreeShippingAchievedContainer = styled(Fade)<{
  runAnimation?: boolean
  hide: boolean
}>`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  opacity: ${o => o.hide && 0};
  display: ${o => (o.hide ? 'none' : 'block')};
  padding-bottom: 3px;
  text-align: center;

  ${o =>
    o.runAnimation &&
    `
    animation-delay: 0.3s;
    animation-duration: 1s;
  `}
`

export const SpendXMoreContainer = styled(Fade)<{
  fadeDirection: 'in' | 'out'
  runAnimation?: boolean
  hide: boolean
}>`
  display: ${o => (o.hide ? 'none' : 'block')};
  transition: opacity ${timingDefault} ease-${o => o.fadeDirection};

  ${o =>
    o.runAnimation &&
    `
    animation-delay: ${timingDefault};
    animation-duration: 0.8s;
  `}
`
