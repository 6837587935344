import { timingSlowerMs } from '@syconium/little-miss-figgy'

export const modalAnimationDuration = timingSlowerMs
export const standardModalMaxWidth = '1110px'
export const preventBodyScrollClassName = 'body--prevent-scroll'

// BodyOpenClassName: react-modal will add this class to the body element when the modal is open.
// We use it to prevent page scroll
export const navModalBodyOpenClassName = 'FIGS__NavModal-Body--open'
export const standardModalBodyOpenClassName = 'FIGS__StandardModal-Body--open'
export const solidModalBodyOpenClassName = 'FIGS__SolidModal-Body--open'
export const videoModalBodyOpenClassName = 'FIGS__VideoModal-Body--open'

// PortalClassName: react-modal adds this class to the portal element into which the modal is mounted
export const imageZoomPortalClassName = 'FIGS__ImageZoom-Portal'
export const navModalPortalClassName = 'FIGS__NavModal-Portal'
export const solidModalPortalClassName = 'FIGS__SolidModal-Portal'
export const standardModalPortalClassName = 'FIGS__StandardModal-Portal'
export const stickyAddToBagPortalClassName = 'FIGS_StickyAddToBag-Portal'
export const videoModalPortalClassName = 'FIGS__VideoModal-Portal'
