import { Hamburger, Media } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { useModalState } from '../../modals/useModalState'
import { NavModal } from '../NavModal'

import { NavLogo } from './NavLogo'
import { HamburgerButton, Left, LogoMedia, NavGhostProxy } from './styles'

/**
 * The left container for the NavHeader.
 *
 * On mobile viewports, renders the hamburger button and the NavModal.
 * On desktop viewports, renders the brand logo.
 *
 * @param props
 * @param [props.isFadedOut] - If the contents in the container should be opaque.
 * @param [props.isNavModalOpen] - If the NavModal should be open.
 * @param props.onNavModalOpen - Handler for when the nav modal is prompted to be open.
 * @param props.onNavModalClose- Handler for when the nav modal is prompted to be closed.
 *
 */
export function NavHeaderLeft({ isFadedOut = false }: { isFadedOut?: boolean }) {
  const {
    magnolia: { navigation: strings },
  } = useFixturesContext()

  const { isOpen, openModal, closeModal } = useModalState()

  return (
    <NavGhostProxy>
      <Left isFadedOut={isFadedOut}>
        <LogoMedia greaterThanOrEqual='md'>
          <NavLogo />
        </LogoMedia>
        <Media lessThan='md'>
          <HamburgerButton
            aria-expanded={isOpen}
            aria-label={strings.hamburgerAriaLabel}
            data-testid='nav-header-hamburger-button'
            id='nav-header-hamburger-button'
            onClick={openModal}
            variant={'black-on-transparent'}
            {...trackEvent({ category: 'navigation', action: 'click hamburger' })}
          >
            <Hamburger />
          </HamburgerButton>
          <NavModal isOpen={isOpen} onClose={closeModal} />
        </Media>
      </Left>
    </NavGhostProxy>
  )
}
