import { ProductAsset } from '../../types/graphql'

export function resizeShopifyImageSource({
  height,
  source,
  width,
}: {
  height: number
  source: string
  width: number
}): string {
  const matches = source?.match(/^(.*shopify.*)(\.(?:jpg|png|gif).*)$/)
  return matches ? `${matches[1]}_${width}x${height}${matches[2]}` : source
}

export function resizeShopifyImage({
  height,
  image,
  width,
}: {
  image: ProductAsset
  height: number
  width: number
}): ProductAsset {
  return {
    ...image,
    source: resizeShopifyImageSource({ height, source: image.source, width }),
  }
}

export function makeShopifyImageSrcSet(imageSource: string, sizes: number[]): string {
  return sizes
    .map(size => {
      const resizedSource = resizeShopifyImageSource({
        height: size,
        source: imageSource,
        width: size,
      })
      return `${resizedSource} ${size}w`
    })
    .join(', ')
}
