import styled from 'styled-components'

import { ProgressiveAsset, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  line-height: 18px;
  padding: 24px 0;

  &:first-of-type {
    border-top-width: 0;
  }
`

const imageSize = '100px'

export const Top = styled.div`
  display: flex;

  ${ProgressiveAsset.Styles.Container} {
    height: ${imageSize};
    width: ${imageSize};
  }

  ${ProgressiveAsset.Styles.Image} {
    background: ${o => o.theme.color.deprecated.stoneLighter};
  }
`

export const ImagePlaceholder = styled.div`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  height: ${imageSize};
  width: ${imageSize};
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100px;
  margin-inline-start: 12px;
`

export const DetailsRow = styled.div<{
  hasOffsetBelow?: boolean
  hasMarginTop?: boolean
}>`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: ${o => (o.hasOffsetBelow ? 'auto' : '0px')};
  margin-top: ${o => (o.hasMarginTop ? '20px' : 0)};

  &:last-child {
    padding-bottom: 0;
  }
`

export const DetailsColumn = styled.div<{
  hasOffsetLeft?: boolean
}>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-inline-start: auto;
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)``

export const OptionsText = styled.p`
  ${TypeStyle.css.bodyMicro};
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  font-weight: bold;

  ${fromMd} {
    ${TypeStyle.css.bodySmall};
    font-weight: bold;
  }
`

export const FitDescription = styled.span`
  text-transform: capitalize;
`

export const Bottom = styled.div`
  padding-left: 112px;
`
export const SiteWidePromoWrap = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.deprecated.red.error};
  padding-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
`
