import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Search: React.FC<IProps> = ({ height = '26px', width = '26px' }) => {
  return (
    <BaseIcon height={height} width={width}>
      <g
        fill='none'
        fillRule='evenodd'
        stroke='currentColor'
        strokeWidth='1.5'
        transform='translate(5 5)'
      >
        <circle cx='6.39' cy='6.15' r='5.88'></circle>
        <path d='M10.79 10.64l3.36 3.46'></path>
      </g>
    </BaseIcon>
  )
}
