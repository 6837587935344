import * as React from 'react'

import HeaderStateContainer, { HeaderContextProps } from '../context'

import { ContainerLayout } from './styles'

type ContainerBaseProps = {
  children: React.ReactNode
  className?: string
  dataTestId?: string
}

type ContainerProps = ContainerBaseProps & Partial<HeaderContextProps>

const WithContext: React.FC<ContainerBaseProps & { dataTestId: string | undefined }> = ({
  children,
  className,
  dataTestId,
}) => {
  const { isToggled } = HeaderStateContainer.useContainer()
  return (
    <ContainerLayout className={className} aria-hidden={isToggled} data-testid={dataTestId}>
      {children}
    </ContainerLayout>
  )
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
  dataTestId,
  isAuthorized = false,
  isToggled = false,
}) => {
  return (
    <HeaderStateContainer.Provider initialState={{ isAuthorized, isToggled }}>
      <WithContext className={className} dataTestId={dataTestId}>
        {children}
      </WithContext>
    </HeaderStateContainer.Provider>
  )
}
