import { css } from 'styled-components'

import {
  modalAnimationDuration,
  videoModalBodyOpenClassName,
  videoModalPortalClassName,
} from './constants'

export const videoModalStyles = css`
  .${videoModalPortalClassName} {
    .ReactModal {
      &__Overlay {
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        transition: background-color ${modalAnimationDuration}ms ease;
        z-index: ${o => o.theme.zIndex.modals};

        &--after-open {
          background-color: rgba(0, 0, 0, 1);
        }

        &--before-close {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      &__Content {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate3d(0, 100vh, 0);
        transition: transform ${modalAnimationDuration}ms ease;

        &--after-open {
          transform: translate3d(0, 0, 0);
        }

        &--before-close {
          transform: translate3d(0, 100vh, 0);
        }
      }
    }
  }

  .${videoModalBodyOpenClassName} {
    overflow: hidden;
  }
`
