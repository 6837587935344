import React from 'react'
import styled from 'styled-components'

const Body = styled.div`
  display: contents;
`

const GhostChild = styled.div`
  * + & {
    display: none;
  }
`

export const GhostProxy: React.FC<{
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}> = ({ children, className, style }) => {
  return (
    <Body>
      {children}
      <GhostChild {...{ className, style }} />
    </Body>
  )
}
