import * as React from 'react'

import { Trash } from '@syconium/little-miss-figgy'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { intlPrettyCurrency } from '@syconium/magnolia/src/lib/utils'

import {
  DeleteIconButton,
  DeleteIconColumn,
  FullPrice,
  Price,
  PriceColumn,
  ValuePrice,
} from './styles'

export const Template: React.FC<{
  displayDiscountAsValue?: boolean
  effectivePrice: number | undefined
  eventCategory?: 'minicart' | 'cart'
  fullPrice: number | undefined
  removeItem?: () => void
  title?: string
  valueSignalLabel: string
}> = ({
  displayDiscountAsValue = false,
  effectivePrice,
  eventCategory = '',
  fullPrice,
  removeItem,
  title = '',
  valueSignalLabel,
}) => {
  const { locale, region } = LocalizationContainer.useContainer()
  const isOnSale = fullPrice !== effectivePrice

  return (
    <>
      <PriceColumn displayDiscountAsValue={displayDiscountAsValue}>
        <Price
          displayDiscountAsValue={displayDiscountAsValue}
          isSalePrice={isOnSale}
          data-testid='price-and-delete-button-price'
        >
          {intlPrettyCurrency({ cents: effectivePrice, region: region, locale: locale })}
        </Price>

        {isOnSale &&
          (displayDiscountAsValue ? (
            <ValuePrice>
              {intlPrettyCurrency({ cents: fullPrice, region: region, locale: locale })}{' '}
              {valueSignalLabel}
            </ValuePrice>
          ) : (
            <FullPrice>
              {intlPrettyCurrency({ cents: fullPrice, region: region, locale: locale })}
            </FullPrice>
          ))}
      </PriceColumn>

      {title && (
        <DeleteIconColumn>
          <DeleteIconButton
            {...trackEvent({
              category: eventCategory,
              action: 'remove item from cart',
              label: title,
            })}
            onClick={removeItem}
            aria-label={`Remove item: ${title}`}
          >
            <Trash stroke='currentColor' />
          </DeleteIconButton>
        </DeleteIconColumn>
      )}
    </>
  )
}
