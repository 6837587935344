import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Exit: React.FC<IProps> = ({
  className = '',
  height = '24px',
  rotateClockwise,
  width = '24px',
  transition,
}) => {
  return (
    <BaseIcon
      className={className}
      height={height}
      width={width}
      rotateClockwise={rotateClockwise}
      transition={transition}
      viewBox='0 0 24 24'
    >
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.1692 12.384L6.44432 12.384L9.40606 15.3458L8.3454 16.4064L3.573 11.634L8.3454 6.86164L9.40606 7.9223L6.44432 10.884H13.1692L13.1692 12.384Z'
          fill='#282828'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.3662 17.8838L18.3662 17.8838C18.5043 17.8838 18.6162 17.7719 18.6162 17.6338L18.6162 5.63379C18.6162 5.49572 18.5043 5.38379 18.3662 5.38379L11.3662 5.38379L11.3662 3.88379L18.3662 3.88379C19.3327 3.88379 20.1162 4.66729 20.1162 5.63379L20.1162 17.6338C20.1162 18.6003 19.3327 19.3838 18.3662 19.3838L11.3662 19.3838L11.3662 17.8838Z'
          fill='#282828'
        />
      </svg>
    </BaseIcon>
  )
}
