import unfetch from 'isomorphic-unfetch'

import { isBrowser } from '@syconium/magnolia/src/lib/utils'

import { ShopifyCartClient } from './ShopifyCartClient'

export class ShopifyCartClientSingleton {
  private static instance: ShopifyCartClient | null = null

  public static getInstance(): ShopifyCartClient {
    if (this.instance === null) {
      this.instance = new ShopifyCartClient({
        fetch: isBrowser() ? window.fetch.bind(window) : unfetch,
      })
    }
    return this.instance
  }

  private constructor() {}
}
