import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_RECO_PRODUCT_DETAILS_OPTIMIZED = gql(`
  query FetchRecoProductDetailsOptimized($handles: [String!]) {
    optimizedProducts(handles: $handles) {
      nodes {
        color
        colorInfo {
          name
          rawName
        }
        handle
        id
        priceRange
        category
        images(first: 1) {
          nodes {
            source
          }
        }
        defaultVariant {
          priceDetails {
            discountPrice {
              amount
            }
            price {
              amount
              currency
            }
          }
        }
        productGroup {
          colors {
            handle
          }
          handle
          title
        }
      }
    }
  }
`)
