import { BaseIcon, IProps } from '../BaseIcon'

export const LogoBadge: React.FC<IProps> = ({
  height = '21px',
  fill = '#282828',
  width = '14px',
  className,
  children,
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 14 21' className={className} {...rest}>
      {children}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.77945 11.2503L7.76586 14.0026L5.42411 13.9733L5.41732 11.2425C2.73493 11.1869 2.70194 8.87076 2.70194 8.87076L5.42994 8.87173L5.44255 6.13604L7.79595 6.14385L7.78236 8.89125C10.2697 9.07271 10.5075 11.264 10.5075 11.264L7.77945 11.2503ZM9.4845 0.517355C8.68581 0.220761 7.759 0.0431952 6.71769 0.0324632C5.67637 0.0100235 4.57294 0.170028 3.74318 0.522234C1.4781 1.36811 0.00298124 3.36622 6.98199e-05 6.51753V13.5968C-0.0115759 16.7695 1.43443 18.7784 3.7296 19.6213C4.5283 19.9179 5.46092 20.1013 6.49157 20.0916C7.53871 20.1179 8.64602 19.9365 9.45054 19.6272C11.7418 18.7696 13.2169 16.7715 13.2305 13.626V6.55265C13.2402 3.38378 11.7962 1.36519 9.4845 0.517355Z'
        fill={fill}
      />
    </BaseIcon>
  )
}
