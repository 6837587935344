import styled from 'styled-components'

import { timingDefault } from '../../constants'

export const StyledContainer = styled.div``

export const StyledSummary = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  outline-offset: -1px;

  cursor: pointer;
`

export const Details = styled.div<{
  animate: boolean
}>`
  box-sizing: border-box;
  overflow: hidden;
  transition: ${o => o.animate && `max-height ${timingDefault} ease`};
`
