import * as React from 'react'
import { useTheme } from 'styled-components'

import { BaseIcon, IProps } from '../BaseIcon'

export const Trash: React.FC<IProps> = ({ height = '20px', stroke, width = '20px', ...rest }) => {
  const { color } = useTheme()

  return (
    <BaseIcon height={height} width={width} viewBox='0 0 20 20' {...rest}>
      <g fillRule='evenodd' fill={stroke || color.deprecated.black.primary}>
        <path d='M12.527 4.01c0-.244.007-.458 0-.671-.015-.382-.228-.587-.6-.59a148.54 148.54 0 00-3.04.003c-.35.004-.554.206-.572.562-.011.226-.002.453-.002.696h4.214zM5.645 5.265c-.014.112-.026.16-.025.21.102 3.16.196 6.32.314 9.48.034.91.765 1.615 1.703 1.628 1.847.026 3.695.026 5.542.002.973-.013 1.697-.742 1.732-1.713.112-3.077.207-6.154.306-9.23.004-.126-.02-.253-.03-.377H5.645zm8.08-1.224h2.132c.566 0 1.132.004 1.697-.001.41-.004.74.096.765.58.02.403-.246.61-.785.619-.347.006-.695.001-1.191.001 0 .865.02 1.691-.004 2.517-.064 2.329-.15 4.656-.22 6.985-.052 1.791-1.275 3.029-3.073 3.05-1.763.019-3.527.02-5.29-.002-1.781-.022-2.985-1.226-3.047-3.016-.102-2.976-.198-5.953-.297-8.93-.006-.179-.02-.358-.035-.604-.36 0-.688.002-1.015 0-.593-.005-.854-.186-.862-.593-.008-.419.241-.603.837-.605 1.065-.003 2.13-.001 3.195-.001h.582c0-.315-.01-.561.002-.807.043-.897.715-1.635 1.608-1.668 1.13-.04 2.264-.04 3.393 0 .892.033 1.563.773 1.606 1.671.012.245.002.492.002.804z' />
        <path d='M8.622 10.868c0 .93.002 1.861-.002 2.792-.002.517-.233.82-.619.783-.457-.045-.583-.353-.582-.774.008-1.878.002-3.757.005-5.635.001-.494.214-.755.593-.758.381-.003.6.26.603.75.005.947.001 1.894.002 2.842m2.398-.023c0 .948.003 1.895-.002 2.843-.002.486-.224.758-.597.76-.374 0-.598-.271-.599-.757-.003-1.895-.004-3.79 0-5.685.002-.477.241-.759.62-.726.434.037.581.319.58.723-.006.948-.002 1.895-.002 2.842m2.398.017c0 .931.002 1.862-.001 2.793-.002.52-.228.822-.614.788-.457-.04-.59-.346-.587-.769.007-1.878.006-3.756 0-5.635 0-.42.126-.728.592-.76.381-.024.605.253.608.74.006.948.002 1.896.002 2.843' />
      </g>
    </BaseIcon>
  )
}
