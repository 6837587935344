import styled, { css } from 'styled-components'

export const Container = styled.div<{ leftOffset?: boolean; topOffset?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-inline-start: ${o => (o.leftOffset ? 0 : '-8px')};
  margin-top: ${o => (o.topOffset ? '8px' : 0)};
`

export const BadgeWrapper = styled.div<{ isFinalSale?: boolean }>`
  margin: 8px 0 0 8px;
  ${({ isFinalSale }) =>
    isFinalSale &&
    css`
      margin: 0;
    `}
`
