import styled from 'styled-components'

export const SearchContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid ${o => o.theme.color.deprecated.stone};
  box-sizing: border-box;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 32px;
  margin-right: 14px;
  margin-left: 10px;
  padding: 10px;
  width: 122px;
`

export const SearchText = styled.span`
  padding-inline-start: 4px;
  color: ${o => o.theme.color.deprecated.obsidianLighter};
`
