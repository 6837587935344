import { FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { IProps } from './IProps'
import { VisibilityTarget as DefaultVisibilityTarget } from './styles'

export const RenderOnceInViewport: FC<IProps> = ({
  children,
  forceRender = false,
  offset,
  partialVisibility = false,
  VisibilityTarget,
}) => {
  const offsetString = [
    offset?.top || 0,
    offset?.right || 0,
    offset?.bottom || 0,
    offset?.left || 0,
  ]
    .map(value => `${value}px`)
    .join(' ')

  const { ref, inView } = useInView({
    threshold: partialVisibility ? 0 : 1,
    rootMargin: offsetString,
    triggerOnce: true,
    fallbackInView: true,
  })

  const render = forceRender || inView

  return (
    <>
      <div ref={ref}>{render ? children : null}</div>
      {VisibilityTarget ? <VisibilityTarget /> : <DefaultVisibilityTarget />}
    </>
  )
}
