import * as React from 'react'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const PriceAndDeleteButton: React.FC<{
  currency: string
  displayDiscountAsValue?: boolean
  fullPrice: number
  effectivePrice: number
  eventCategory?: 'minicart' | 'cart'
  removeItem?: () => void
  title?: string
}> = passedProps => {
  const viewModel = useViewModel()
  return <Template {...passedProps} {...viewModel} />
}
