import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_COLLECTION_ITEMS_WITH_IMAGES = gql(`
  query GetCollectionItemsWithImages(
    $handle: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $includeUnavailable: Boolean
  ) {
    collection: optimizedCollection(handle: $handle, includeUnavailable: $includeUnavailable) {
      items(first: $first, after: $after, last: $last, before: $before, grouping: PRODUCT) {
        numItems
        numPages
        nodes {
          category
          colorInfo {
            availability
            hexCodes
            name
          }
          detailImages
          fitModel {
            images
          }
          id
          images {
            nodes {
              source
            }
          }
          productGroup {
            description
            handle
            id
            style
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`)
