import { User } from '@syconium/little-miss-figgy'

import {
  NavAccordion,
  NavListItem,
  NavListSection,
  NavTab,
  NavTile,
  NavTileSection,
  PromoBar,
  SiteFooter,
  SiteNavigation,
} from '../../types/graphql'

import { rawHtmlSection } from './contentful-page-view'

export const promoBar: PromoBar = {
  backgroundColor: '#393941',
  textColor: '#FFF',
  pages: [
    {
      id: 'A',
      content:
        '<p>WE GIVE A FIT – <a href="https://thephysical.wearfigs.com/we-give-a-fit-sizing-updates-for-l-xxl/" title="Sizing Updates for L-XL">SIZING UPDATES FOR L-2XL</a></p>\n',
    },
    {
      id: 'B',
      content:
        '<p>SAVING LIVES MEANS PROTECTING BLACK LIVES - <a href="https://hello.wearfigs.com/blacklivesmatter/"><b>#BLACKLIVESMATTER</b></a><p>\n',
    },
    { id: 'C', content: '<p>FREE SHIPPING ON ORDERS OF $50 OR MORE</p>\n' },
    {
      id: 'D',
      content: '<p>Can be lowercase, <b>bold</b>, <i>italic</i>, or <u>underlined</u>.</p>\n',
    },
    {
      id: 'E',
      content:
        '<p>If Contentful gives us <em>em</em> or <strong>strong</strong>, that’s cool.</p>\n',
    },
    {
      id: 'F',
      content:
        '<p>Multiple links: <a href="/collections/core-scrubs-womens">Shop Women</a> and <a href="/collections/core-scrubs-men">Shop Men</a></p>\n',
    },
    {
      id: 'G',
      content:
        '<p>Long content wraps: <a href="/collections/core-scrubs-womens">Shop Women</a> and <a href="/collections/core-scrubs-men">Shop Men</a> and <a href="/collections/core-scrubs-womens">Shop Women</a> and <a href="/collections/core-scrubs-men">Shop Men</a> and <a href="/collections/core-scrubs-womens">Shop Women</a> and <a href="/collections/core-scrubs-men">Shop Men</a> and <a href="/collections/core-scrubs-womens">Shop Women</a> and <a href="/collections/core-scrubs-men">Shop Men</a> and <a href="/collections/core-scrubs-womens">Shop Women</a> and <a href="/collections/core-scrubs-men">Shop Men</a></p>\n',
    },
  ],
}

export const navListItem: NavListItem = {
  badge: null,
  badgeColor: null,
  badgeTextColor: null,
  href: 'https://giphy.com/search/eye-bleach',
  icon: null,
  id: 'A',
  isBold: false,
  isHeading: false,
  text: 'Eye bleach',
  textColor: null,
}

const icon = 'https://cdn.shopify.com/s/files/1/0139/8942/files/icon_gift_black.png?v=1546891519'

const longNavListItem = {
  ...navListItem,
  text: 'This is an unusually long text for a NavListItem. And now it’s just crazy long. Like ridiculously long.',
  badge:
    'This is unreasonably long text for a Badge! Ridiculously long. We would never actually do this. But we can.',
  icon,
}

export const navListItems: NavListItem[] = [
  { ...navListItem, id: 'A', text: 'HEADING, NO HREF', isHeading: true, href: null },
  { ...navListItem, id: 'B', text: 'SPA LINK, UPPERCASE DATA', href: '/pages/accessibility' },
  { ...navListItem, id: 'C', badge: 'fun', badgeColor: '#0F00B0', badgeTextColor: '#FF8' },
  { ...navListItem, id: 'D', text: 'Link heading with icon', isHeading: true, icon, badge: 'new' },
  { ...navListItem, id: 'E', icon, text: 'Bold affects valign', href: null, badge: 'no href' },
  { ...navListItem, id: 'F', icon, text: 'Bold affects valign', isBold: true, badge: 'slightly' },
  { ...longNavListItem, id: 'G' },
  { ...longNavListItem, id: 'H', badge: 'short badge' },
  { ...longNavListItem, id: 'I', badge: '🔥', badgeColor: '#FFF' },
]

export const navListSection: NavListSection = {
  __typename: 'NavListSection',
  id: 'A',
  items: navListItems,
  variant: 'default',
}

export const navTile: NavTile = {
  id: 'A',
  ctaText: 'UPPERCASE DATA',
  href: 'https://giphy.com/search/mind-blown',
  image:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/3_-_Jumbo_Tile_-_M_2x_acf3017c-28e4-43fc-8d4a-1650724fcd78.jpg?v=1598658678',
}

export const navTileSection: NavTileSection = {
  __typename: 'NavTileSection',
  id: 'A',
  tiles: [
    { ...navTile, id: 'A' },
    { ...navTile, id: 'B', ctaText: 'NavTile ctaText can be lowercase' },
  ],
}

export const nestedNavAccordion: NavAccordion = {
  __typename: 'NavAccordion',
  id: 'A',
  sections: [
    { id: 'A', header: { ...navListItem, text: 'List' }, body: navListSection },
    { id: 'B', header: { ...navListItem, text: 'Tiles' }, body: navTileSection },
    { id: 'C', header: { ...navListItem, text: 'Empty' }, body: null },
    { id: 'E', header: { ...navListItem, text: 'Raw HTML' }, body: rawHtmlSection },
  ],
}

export const navAccordion: NavAccordion = {
  __typename: 'NavAccordion',
  id: 'A',
  sections: [
    { id: 'A', header: { ...navListItem, text: 'List' }, body: navListSection },
    { id: 'B', header: { ...navListItem, text: 'Tiles' }, body: navTileSection },
    { id: 'C', header: { ...navListItem, text: 'Empty' }, body: null },
    { id: 'D', header: { ...navListItem, text: 'Nested Accordion' }, body: nestedNavAccordion },
    { id: 'E', header: { ...navListItem, text: 'Raw HTML' }, body: rawHtmlSection },
    ...navListItems.map((navListItem, i) => ({
      __typename: 'NavAccordionSection',
      id: `${i}`,
      header: navListItem,
      body: null,
    })),
  ],
}

export const navTab: NavTab = {
  id: 'A',
  text: 'NavTab',
  textColor: null,
  href: null,
  sections: [
    { ...navListSection, id: 'A', variant: 'featured' },
    { ...navListSection, id: 'B' },
    { ...navAccordion, id: 'C' },
    { ...navTileSection, id: 'D' },
    { ...rawHtmlSection, id: 'E' },
  ],
}

export const siteNavigation: SiteNavigation = {
  navigationJSON: '{"menus":[{"href":"/","title":"Home","submenus":[]}]}', // deprecated, Nav 1.0
  mobileTabs: [
    { ...navTab, id: 'A' },
    { id: 'B', text: 'Dropdown', textColor: null, href: null, sections: [navListSection] },
  ],
  desktopTabs: [
    { ...navTab, id: 'A' },
    { ...navTab, id: 'B' },
    { ...navTab, id: 'C' },
    {
      id: 'D',
      text: 'Link',
      textColor: null,
      href: 'https://giphy.com/search/mind-blown',
      sections: [],
    },
    { id: 'E', text: 'Dropdown', textColor: null, href: null, sections: [navListSection] },
  ],
  helpLinkText: 'Help Center',
  helpLinkHref: 'https://www.google.com/',
}

export const siteFooter: SiteFooter = {
  desktopSections: [
    { ...navListSection, id: 'A' },
    { ...navListSection, id: 'B' },
    { ...navListSection, id: 'C' },
  ],
  mobileSections: [navAccordion],
  socialLinks: [
    { ...navListItem, id: 'A' },
    { ...navListItem, id: 'B' },
  ],
  legalLinks: [
    { ...navListItem, id: 'A' },
    { ...navListItem, id: 'B' },
  ],
  copyrightLine: '© 2021 FIGS, INC. ALL RIGHTS RESERVED',
}

export const scrubsNavListSection: NavListSection = {
  __typename: 'NavListSection',
  id: 'A',
  variant: 'default',
  items: [
    { ...navListItem, id: 'A', text: 'Scrub Tops' },
    { ...navListItem, id: 'B', text: 'Scrub Pants' },
    { ...navListItem, id: 'C', text: 'All Scrubs' },
    { ...navListItem, id: 'D', text: 'Shop By Fit', isHeading: true, href: null },
    { ...navListItem, id: 'E', text: 'Tall' },
    { ...navListItem, id: 'F', text: 'Petite/Short' },
    { ...navListItem, id: 'G', text: 'Maternity' },
  ],
}

export const shortNavListSection: NavListSection = {
  __typename: 'NavListSection',
  id: 'A',
  variant: 'default',
  items: [
    { ...navListItem, id: 'A' },
    { ...navListItem, id: 'B', text: 'Surprise' },
    { ...navListItem, id: 'C', text: 'Supplies' },
  ],
}

export const iconNavListSection: NavListSection = {
  __typename: 'NavListSection',
  id: 'A',
  variant: 'default',
  items: [
    { ...navListItem, id: 'A', icon },
    { ...navListItem, id: 'B', icon, text: 'Surprise' },
    { ...navListItem, id: 'C', icon, text: 'Supplies' },
  ],
}

export const realisticNavTab: NavTab = {
  id: 'A',
  text: 'Women',
  textColor: null,
  href: 'https://giphy.com/search/patrick',
  sections: [
    {
      __typename: 'NavListSection',
      id: 'A',
      variant: 'featured',
      items: [
        { ...navListItem, id: 'A', text: 'NEW ARRIVALS', isBold: true },
        { ...navListItem, id: 'B', text: 'BEST SELLERS', isBold: true },
        { ...navListItem, id: 'C', text: 'FEATURED COLLECTIONS', isBold: true, badge: 'new' },
      ],
    },
    {
      __typename: 'NavAccordion',
      id: 'B',
      sections: [
        { id: 'A', header: { ...navListItem, text: 'Scrubs' }, body: scrubsNavListSection },
        { id: 'E', header: { ...navListItem, text: 'Shop By Color' }, body: iconNavListSection },
        { id: 'G', header: { ...navListItem, text: 'Team Orders' }, body: null },
        { id: 'H', header: { ...navListItem, text: 'My Account' }, body: shortNavListSection },
      ],
    },
    { ...navTileSection, id: 'C' },
  ],
}

export const realisticSiteNavigation: SiteNavigation = {
  ...siteNavigation,
  mobileTabs: [realisticNavTab, { ...realisticNavTab, id: 'B', text: 'Men' }],
}

export const emptyNavListItem: NavListItem = {
  badge: null,
  badgeColor: null,
  badgeTextColor: null,
  hasDivider: false,
  href: null,
  icon: null,
  id: '',
  isBold: null,
  isHeading: null,
  text: '',
  textColor: null,
}

export const iconTab: NavTab = {
  href: null,
  id: 'icon',
  render: () => <User />,
  sections: [],
  text: '',
  textColor: null,
}
