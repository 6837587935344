import styled from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'

export const Button = styled.button`
  align-items: center;
  display: flex;
  flex-direction: row;

  ${fromMd} {
    &:hover {
      color: ${o => o.theme.color.deprecated.obsidianLighter};

      svg > g {
        stroke: ${o => o.theme.color.deprecated.obsidianLighter};
      }
    }
  }
`
