import * as React from 'react'

/**
 * Manages `inputQuantity` state that also updates when a new `originalQuantity` is passed as a prop. The default `React.useState` behavior only uses the initial state on FIRST render.
 */
export function usePropReactiveInputQuantity(originalQuantity: string) {
  const [inputQuantity, setInputQuantity] = React.useState<string | undefined>(originalQuantity)

  React.useEffect(() => {
    setInputQuantity(originalQuantity)
  }, [originalQuantity])

  return {
    inputQuantity,
    setInputQuantity,
  }
}
