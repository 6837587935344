import {
  DetailedHTMLProps,
  FC,
  SyntheticEvent,
  VideoHTMLAttributes,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react'

import { MediaLazyLoad } from '../MediaLazyLoad'

import { ResponsiveImage } from './styles'

const VideoContainer: FC<{ isLazy?: boolean; children?: React.ReactNode }> = ({
  children,
  isLazy = false,
}) => (isLazy ? <MediaLazyLoad>{children}</MediaLazyLoad> : <>{children}</>)

export const MaxIterationsVideo = forwardRef<
  HTMLVideoElement,
  {
    imageWhenComplete?: string
    isLazy?: boolean
    maxIterations: number
  } & DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
>(({ imageWhenComplete, isLazy = false, maxIterations = 5, ...rest }, ref) => {
  const numberOfPlays = useRef<number>(1)
  const [showImage, setShowImage] = useState<boolean>(false)

  const replayVideo = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      const videoElement = event.target as HTMLVideoElement
      if (numberOfPlays.current < maxIterations) {
        videoElement.play()
        numberOfPlays.current++
      } else {
        setShowImage(true)
      }
    },
    [maxIterations, numberOfPlays]
  )

  return (
    <>
      {showImage && imageWhenComplete ? (
        <ResponsiveImage
          className={rest.className}
          src={imageWhenComplete}
          alt=''
          aspectRatios={{
            sm: null,
            md: null,
          }}
          loading='default'
          widths={{ unit: 'vw', sm: 100, md: 100 }}
        />
      ) : (
        <VideoContainer {...{ isLazy }}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video {...{ ref }} onEnded={replayVideo} {...rest} />
        </VideoContainer>
      )}
    </>
  )
})
