import * as React from 'react'

import { SelectMark } from '@syconium/little-miss-figgy'

import { IProps } from './IProps'
import { Container } from './styles'

export const ModalCloseButton: React.FC<IProps> = props => {
  return (
    <Container aria-label='Close' {...props}>
      <SelectMark />
    </Container>
  )
}
