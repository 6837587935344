import { ServerResponse } from 'http'

import { CacheControl, format } from '@tusbar/cache-control'
import { NextPageContext } from 'next'
import { AppContext, AppInitialProps } from 'next/app'

// 15s is our timeout in the CDN, so seems appropriate to just
// allow client cache during that time if refreshed before we
// would even have timedout.
const clientSideCacheBuffer = 15

// Allow stale content for 5x the time we allow for content to cache normally.
const staleWhileRevalidateMultiplier = 5
// Allow stale content rather than an error for 10x the time we allow for content to cache normally.
const staleIfErrorMultiplier = 10

// Default TTL for static assets if not set in env (1 month)
const defaultStaticAssetsTTL = 2592000

export interface WithResponseCacheControl {
  responseCacheControl: CacheControl
}

type CachePolicyKeys =
  | 'noCache'
  | 'defaultContentPolicy'
  | 'defaultStorefrontPolicy'
  | 'defaultPrivatePolicy'
  | 'productPolicy'
  | 'productCollectionPolicy'
  | 'productSearchPolicy'
  | 'staticAssetsPolicy'

/**
 * All cache policies related to the storefront and its products currently
 * have low maxAge values but instead rely on sharedMaxAge. This means they
 * will never cache long on a user's device. The user device caching is just to
 * avoid rapid refresh network requests.
 *
 * The sharedMaxAge instead lets them cache in the CDN longer. We do this so we
 * can run mass CDN cache invalidations on the things related to our storefront.
 * We do this at flash sale moments to ensure everyone quickly starts seeing the
 * new sale content. If it was cached via maxAge on their browser clients we would
 * not be able to invalidate it.
 *
 * If you are caching something not related to our sales, you can use the defaultContentPolicy
 * which allows caching on clients for full durations.
 */
export const cachePolicies: Record<CachePolicyKeys, CacheControl> = {
  noCache: { noStore: true, public: false, maxAge: 0, sharedMaxAge: 0 },
  defaultContentPolicy: {
    public: true,
    maxAge: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0'),
    sharedMaxAge: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0'),
    staleWhileRevalidate:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0') * staleWhileRevalidateMultiplier,
    staleIfError: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0') * staleIfErrorMultiplier,
  },
  defaultStorefrontPolicy: {
    public: true,
    maxAge: Math.min(parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0'), clientSideCacheBuffer),
    sharedMaxAge: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0'),
    staleWhileRevalidate:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0') * staleWhileRevalidateMultiplier,
    staleIfError: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL || '0') * staleIfErrorMultiplier,
  },
  defaultPrivatePolicy: {
    public: false,
    maxAge: clientSideCacheBuffer,
    staleIfError: clientSideCacheBuffer * staleIfErrorMultiplier,
  },
  productPolicy: {
    public: true,
    maxAge: Math.min(
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_DETAILS || '0'),
      clientSideCacheBuffer
    ),
    sharedMaxAge: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_DETAILS || '0'),
    staleWhileRevalidate:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_DETAILS || '0') *
      staleWhileRevalidateMultiplier,
    staleIfError:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_DETAILS || '0') *
      staleIfErrorMultiplier,
  },
  productCollectionPolicy: {
    public: true,
    maxAge: Math.min(
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_COLLECTION || '0'),
      clientSideCacheBuffer
    ),
    sharedMaxAge: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_COLLECTION || '0'),
    staleWhileRevalidate:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_COLLECTION || '0') *
      staleWhileRevalidateMultiplier,
    staleIfError:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_COLLECTION || '0') *
      staleIfErrorMultiplier,
  },
  productSearchPolicy: {
    public: true,
    maxAge: Math.min(
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_SEARCH || '0'),
      clientSideCacheBuffer
    ),
    sharedMaxAge: parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_SEARCH || '0'),
    staleWhileRevalidate:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_SEARCH || '0') *
      staleWhileRevalidateMultiplier,
    staleIfError:
      parseInt(process.env.PUBLIC_CACHE_DEFAULT_TTL_PRODUCT_SEARCH || '0') * staleIfErrorMultiplier,
  },
  staticAssetsPolicy: {
    public: true,
    maxAge: parseInt(
      process.env.PUBLIC_CACHE_DEFAULT_TTL_STATIC_ASSETS || String(defaultStaticAssetsTTL)
    ),
    sharedMaxAge: parseInt(
      process.env.PUBLIC_CACHE_DEFAULT_TTL_STATIC_ASSETS || String(defaultStaticAssetsTTL)
    ),
    staleWhileRevalidate:
      parseInt(
        process.env.PUBLIC_CACHE_DEFAULT_TTL_STATIC_ASSETS || String(defaultStaticAssetsTTL)
      ) * staleWhileRevalidateMultiplier,
    staleIfError:
      parseInt(
        process.env.PUBLIC_CACHE_DEFAULT_TTL_STATIC_ASSETS || String(defaultStaticAssetsTTL)
      ) * staleIfErrorMultiplier,
  },
}

export function conditionallySetCacheControl(appContext: AppContext, appProps: AppInitialProps) {
  if (!viableCacheContext(appContext.ctx)) {
    return
  }

  const { pageProps } = appProps

  if (typeof pageProps.responseCacheControl !== 'undefined') {
    setCacheControl(appContext.ctx.res, pageProps.responseCacheControl)
  }
}

function viableCacheContext(ctx: NextPageContext) {
  return ctx.res && !ctx.err
}

function setCacheControl(res: ServerResponse | undefined, cc: CacheControl) {
  if (res?.writableEnded) return
  res?.setHeader('Cache-Control', format(cc))
}
