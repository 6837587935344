import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_KIT_SHOP_THE_LOOK = gql(`
  query fetchKitShopTheLook($handle: String!) {
    kit(handle: $handle) {
      shopTheLook {
        color
        colorInfo {
          availability
          rawName
          name
          rawName
        }
        finalSale
        fit
        rawFit
        handle
        images {
          nodes {
            kind
            source
            color
          }
        }
        priceRange
        waitlistable
        productGroup {
          id
          colorGroups {
            ...ColorGroup
          }
          colors {
            availability
            hexCodes
            name
            rawName
            swatchUrl
          }
          defaultVariant {
            priceDetails {
              discountPrice {
                amount
              }
              price {
                amount
              }
            }
          }
          description
          fits
          handle
          productsForAvailability: products {
            availableSizes
            colorInfo {
              rawName
            }
            rawFit
            swPromoEligible
          }
          rawFits
          sizes
          style
          sizeChart: sizeChartCG {
            columnNames
            columnValues
            imageUri
            name
            navLabel
            tables {
              cells
              measureImage
              measureTips {
                kind
                tip
              }
              sizeTips {
                kind
                tip
              }
              subtitle
              tabLabel
              title
            }
            tips {
              kind
              tip
            }
            title
            video
          }
        }
        variants {
          category
          color
          fit
          priceDetails {
            discountPrice {
              amount
            }
            price {
              amount
              currency
            }
          }
          rawColor
          rawFit
          shopifyId
          size
          sku
          soldOut
        }
      }
    }
  }
`)
