import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const QuickBuy: React.FC<IProps> = ({ fill, height = '32px', width = '32px' }) => {
  const pathFill = fill ?? 'currentColor'
  return (
    <BaseIcon
      {...{ height, width }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.2841 5.77783H13.3334C12.3516 5.77783 11.5557 6.57377 11.5557 7.55561V10.6667H7.99995C7.01811 10.6667 6.22217 11.4627 6.22217 12.4445V24.0001C6.22217 24.9819 7.01811 25.7779 7.99995 25.7779H23.9999C24.9818 25.7779 25.7777 24.9819 25.7777 24.0001V15.6211C25.2142 15.8149 24.6182 15.9388 23.9999 15.9824V24.0001H7.99995V12.4445H17.4047C17.093 11.894 16.8536 11.2971 16.6997 10.6667H13.3334V7.55561H16.6492C16.7808 6.92799 16.9966 6.33129 17.2841 5.77783Z'
        fill={pathFill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5 14C26.2614 14 28.5 11.7614 28.5 9C28.5 6.23858 26.2614 4 23.5 4C20.7386 4 18.5 6.23858 18.5 9C18.5 11.7614 20.7386 14 23.5 14ZM24.1934 8.41397H25.7474V9.64597H24.1934V11.34H22.8074V9.64597H21.2534V8.41397H22.8074V6.71997H24.1934V8.41397Z'
        fill={pathFill}
      />
    </BaseIcon>
  )
}
