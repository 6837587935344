import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

// Get collection view + static portal configuration
export const FETCH_COLLECTION_LAYOUT = gql(`
  query GetCollectionView(
    $handle: String!
    $preview: Boolean = false
    $withPortal: Boolean = false
  ) {
    collectionView(handle: $handle, preview: $preview) {
      ...CollectionLayoutFragment
    }
    portal(collectionHandle: $handle, preview: $preview) @include(if: $withPortal) {
      ...PortalStatic
    }
  }
`)
