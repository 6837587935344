import { NavSection } from '@syconium/magnolia/src/types/graphql'

interface NavModalSectionOptions {
  openFirstNavAccordionSection: boolean
}

const defaultOptions = {
  openFirstNavAccordionSection: false,
}

export function augmentNavSections(
  navSections: NavSection[] | undefined,
  { openFirstNavAccordionSection }: NavModalSectionOptions = defaultOptions
): NavSection[] {
  if (!navSections) return []
  let typeCounts: Partial<Record<NavSection['__typename'], number>> = {}

  return navSections.map((section: NavSection) => {
    const { __typename } = section
    typeCounts[__typename] = (typeCounts[__typename] ?? 0) + 1

    // add `startOpenSet: [0]` to the first NavAccordion
    if (
      openFirstNavAccordionSection &&
      __typename === 'NavAccordion' &&
      typeCounts['NavAccordion'] === 1
    ) {
      return { ...section, startOpenSet: [0] }
    }

    return section
  })
}
