import { HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import Cookies from 'universal-cookie'

import { ServiceFilterPairs } from '@syconium/weeping-figs'

import { cookieKeys } from './Cookies.config'
import { headerKeys } from './Headers.config'
import { LocalizationAttributes } from './Localization.config'
import { PreviewDirectives } from './PreviewDirectives.config'

const supergraphBaseUrl = process.env.NEXT_PUBLIC_BRUNSWICK_API_BASE_URL
const shopName = process.env.NEXT_PUBLIC_SHOPIFY_SHOP_NAME

const getAuthLink = (authToken?: string) => {
  return setContext((_operation, { headers }) => {
    const clientSideCookies = new Cookies()
    const authorization =
      clientSideCookies.get(cookieKeys.authToken.key) ?? authToken ?? headers?.authorization
    return {
      headers: {
        ...(headers ?? {}),
        ...(authorization ? { authorization } : {}),
      },
    }
  })
}

const getLocalizationLink = (localization: LocalizationAttributes) => {
  return setContext((_operation, { headers }) => {
    const clientSideCookies = new Cookies()
    return {
      headers: {
        ...(headers ?? {}),
        [headerKeys.region]: clientSideCookies.get(cookieKeys.region.key) ?? localization.region.id,
        [headerKeys.locale]: clientSideCookies.get(cookieKeys.locale.key) ?? localization.locale,
        [headerKeys.currency]:
          clientSideCookies.get(cookieKeys.currency.key) ?? localization.currency,
        ...(shopName && {
          [headerKeys.shopName]: shopName,
        }),
      },
    }
  })
}

const getCustomerTierLink = () => {
  return setContext((_operation, _previousContext) => {
    return { customerTier: 'web' }
  })
}

const getPreviewDirectivesLink = (previewDirectives: PreviewDirectives) => {
  return setContext((_operation, { headers }) => {
    const includeUnavailable = previewDirectives.includeUnavailableProducts
    const isPreview = previewDirectives.includeUnpublishedCmsContent
    const launchTags = previewDirectives.includeUnpublishedProductsForLaunchTags
    const serviceFilters = ServiceFilterPairs.buildServiceFilterHeader({
      includeUnavailable,
      isPreview,
      launchTags,
      customerTier: 'web',
    })
    return {
      includeUnavailable,
      isPreview,
      launchTags,
      headers: {
        ...(headers ?? {}),
        ...(serviceFilters ? { [headerKeys.serviceFilters]: serviceFilters } : {}),
      },
    }
  })
}

const getPersistedQueryLink = () => {
  return createPersistedQueryLink({ sha256 })
}

const getHttpLink = (graphAlias: string) => {
  return new HttpLink({
    uri: `${supergraphBaseUrl}/${graphAlias}/graphql`,
  })
}

export const getGraphqlClientLinks = ({
  includeAuth,
  authToken,
  graphAlias,
  localization,
  previewDirectives,
}: {
  includeAuth: boolean
  authToken: string | undefined
  graphAlias: string
  localization: LocalizationAttributes
  previewDirectives: PreviewDirectives
}) => {
  const links = includeAuth
    ? getAuthLink(authToken).concat(getLocalizationLink(localization))
    : getLocalizationLink(localization)
  return links
    .concat(getCustomerTierLink())
    .concat(getPreviewDirectivesLink(previewDirectives))
    .concat(getPersistedQueryLink())
    .concat(getHttpLink(graphAlias))
}

export const graphqlClientsDefaultOptions = {
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  },
} as const

export type GraphQlClients = 'cache-optimized' | 'auth-supported'
