import * as React from 'react'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

import { DetailLine, Icon, IconEmbroideryVisual, Initials, Label } from './styles'
import { Template } from './Template'

export interface Props {
  backgroundColor?: string
  iconImageSource?: string
  iconInitials?: string
  iconInitialsColor?: string
  iconDesignName?: string
  iconPlacement?: string
}

export const IconSneakPeek: React.FC<Props> = ({
  backgroundColor,
  iconImageSource,
  iconInitials,
  iconInitialsColor,
  iconDesignName,
  iconPlacement,
}) => {
  const {
    embroidery: {
      summary: {
        iconInitialsColorLabel,
        iconInitialsLabel,
        iconNameLabel,
        iconPlacementLabel,
        iconSneakPeekLabel,
      },
    },
  } = useFixturesContext()

  const label = iconSneakPeekLabel

  const displayIconNameTitle = iconInitials || iconPlacement

  if (!iconPlacement && !iconImageSource) return null

  return (
    <Template.Container>
      <Template.Header backgroundColor={backgroundColor}>
        {iconImageSource && (
          <IconEmbroideryVisual>
            <Icon
              alt={iconDesignName ?? 'Embroidery Icon Sneak Peak'}
              src={iconImageSource}
              widths={{ unit: 'px', sm: 36, md: 36 }}
              aspectRatios={{ sm: null, md: null }}
              loading='default'
              transparentBackground={true}
            />

            {iconInitials && (
              <Initials initialsColor={iconInitialsColor?.toLowerCase()}>{iconInitials}</Initials>
            )}
          </IconEmbroideryVisual>
        )}
      </Template.Header>

      <Template.Body>
        {label && <Label>{label}</Label>}

        {iconDesignName && (
          <DetailLine general>
            {displayIconNameTitle ? `${iconNameLabel}: ` : ''}
            {iconDesignName}
          </DetailLine>
        )}

        {iconInitials && (
          <DetailLine general>
            {iconInitialsLabel}: {iconInitials}
          </DetailLine>
        )}

        {iconInitials && iconInitialsColor && (
          <DetailLine>
            {iconInitialsColorLabel}: {iconInitialsColor}
          </DetailLine>
        )}

        {iconPlacement && (
          <DetailLine>
            {iconPlacementLabel}: {iconPlacement}
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}
