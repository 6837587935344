import { CarouselContext } from 'pure-react-carousel'
import { ReactElement, useContext, useEffect, useState } from 'react'

import { Carat, RotateClockwise } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { Back, Next } from './styles'

export const CarouselControls = (): ReactElement => {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)
  const [totalSlides] = useState(carouselContext.state.totalSlides)
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const isOnLastSlide: boolean = currentSlide === totalSlides - 1
  const isOnFirstSlide: boolean = currentSlide === 0

  return (
    <>
      {!isOnFirstSlide && (
        <Back
          {...trackEvent({
            category: 'minicart upsell',
            action: 'click arrow',
            value: 'back',
          })}
        >
          <Carat rotateClockwise={RotateClockwise.Half} />
        </Back>
      )}
      {!isOnLastSlide && (
        <Next
          {...trackEvent({
            category: 'minicart upsell',
            action: 'click arrow',
            value: 'next',
          })}
        >
          <Carat />
        </Next>
      )}
    </>
  )
}
