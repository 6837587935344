import styled from 'styled-components'

import { Button as LmfButton, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const Container = styled.div<{
  type?: string
}>`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 75vw;
  padding: ${o => o.theme.spacing(6)};

  ${fromMd} {
    padding: ${o => (o.type === 'dropdown' ? o.theme.spacing(4) : o.theme.spacing(8))};
    width: ${o => (o.type === 'dropdown' ? '280px' : '360px')};
  }
`

export const CloseButton = styled.div`
  margin-left: auto;
  position: relative;
  cursor: pointer;
  svg {
    height: ${o => o.theme.spacing(4)};
    width: ${o => o.theme.spacing(4)};
    padding: ${o => o.theme.spacing(1)};
  }

  g {
    stroke-width: 1;
    stroke: ${o => o.theme.color.deprecated.obsidian};
  }
`

export const FooterLink = styled.div`
  font-size: 13px;
  padding: 0 0 ${o => o.theme.spacing(1)} 0;
  text-decoration: underline;
`

export const DropdownWrap = styled.div`
  text-align: left;
  margin: ${o => o.theme.spacing(1)} 0 ${o => o.theme.spacing(3)} 0};
`

export const Label = styled.label`
  ${TypeStyle.css.bodyMicro}
  font-weight: 700;
  margin-bottom: ${o => o.theme.spacing(1)};
  text-transform: uppercase;
`

export const CtaButton = styled(LmfButton)`
  width: 100%;
  ${fromMd} {
    min-height: 50px;
  }
`

export const LinkStyleButton = styled(TypeStyle.BodySmall)`
  margin-top: ${o => o.theme.spacing(3)};
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`

export const CtaWrapper = styled.div`
  margin-top: ${o => o.theme.spacing(2)};
`

export const BodyTextSection = styled(TypeStyle.BodySmall)`
  margin: ${o => o.theme.spacing(2)} 0;
`

export const TitleSpan = styled.span`
  display: block;
`
