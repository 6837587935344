import { css } from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'

import {
  modalAnimationDuration,
  standardModalBodyOpenClassName,
  standardModalMaxWidth,
  standardModalPortalClassName,
} from './constants'

export const standardModalStyles = css`
  .${standardModalPortalClassName} {
    .ReactModal {
      &__Overlay {
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        transition: background-color ${modalAnimationDuration}ms ease;
        z-index: ${o => o.theme.zIndex.modals};

        &--after-open {
          background-color: rgba(0, 0, 0, 0.5);
        }

        &--before-close {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      &__Content {
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate3d(0, 100vh, 0);
        transition: transform ${modalAnimationDuration}ms ease;
        width: 100%;

        ${fromMd} {
          height: unset;
          margin: auto;
          position: static;
          max-width: ${standardModalMaxWidth};
          width: 100%;
        }

        &--after-open {
          transform: translate3d(0, 0, 0);
        }

        &--before-close {
          transform: translate3d(0, 100vh, 0);
        }
      }
    }
  }

  .${standardModalBodyOpenClassName} {
    overflow: hidden;
  }
`
