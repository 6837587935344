import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import {
  addOrRemoveItemFromList,
  isRecordEmpty,
  toggleItemInList,
} from '@syconium/magnolia/src/lib/utils'
import { extractSelectedCollectionFilters } from '@syconium/magnolia/src/lib/utils/collection-filters'

import { useUpdateQueryParams } from '../../../lib/adapters/next-routing-service/useUpdateQueryParam'
import { FILTERS_QUERY_PARAM, LegacyFilterQueryParam } from '../../constants'

import { ICollectionFilterQueryParamsContext } from './types'

export function useCollectionFilterQueryParams(): ICollectionFilterQueryParamsContext {
  const { query } = useRouter()

  const collectionFiltersQueryParamAsString = `${query[FILTERS_QUERY_PARAM]}`
  const legacyCategoryQueryParamAsString = `${query[LegacyFilterQueryParam.Category]}`
  const legacyColorQueryParamAsString = `${query[LegacyFilterQueryParam.Color]}`
  const legacyFitQueryParamAsString = `${query[LegacyFilterQueryParam.Fit]}`

  const selectedFilters: Record<string, string[]> = useMemo(() => {
    // Force a dependency on these strings since they are safe things to trigger a recalc of the selected filters.
    // @ts-ignore
    const _forcedDependency =
      collectionFiltersQueryParamAsString +
      legacyCategoryQueryParamAsString +
      legacyColorQueryParamAsString +
      legacyFitQueryParamAsString

    const collectionFiltersQueryParam = query[FILTERS_QUERY_PARAM]
    const legacyCategoryQueryParam = query[LegacyFilterQueryParam.Category]
    const legacyColorQueryParam = query[LegacyFilterQueryParam.Color]
    const legacyFitQueryParam = query[LegacyFilterQueryParam.Fit]

    return extractSelectedCollectionFilters({
      collectionFiltersQueryParam,
      legacyCategoryQueryParam,
      legacyColorQueryParam,
      legacyFitQueryParam,
    })
  }, [
    query,
    collectionFiltersQueryParamAsString,
    legacyCategoryQueryParamAsString,
    legacyColorQueryParamAsString,
    legacyFitQueryParamAsString,
  ])

  const updateQueryParams = useUpdateQueryParams()

  const updateFilterSelection = useCallback(
    (key: string, value: string, selected: boolean) => {
      const updatedSelectedFilterValues: string[] = addOrRemoveItemFromList({
        item: value,
        list: selectedFilters[key] ?? [],
        operation: selected ? 'ADD' : 'REMOVE',
      })
      const updatedSelectedFilters = {
        ...selectedFilters,
      }
      if (updatedSelectedFilterValues.length > 0) {
        updatedSelectedFilters[key] = updatedSelectedFilterValues
      } else {
        delete updatedSelectedFilters[key]
      }
      updateQueryParams({
        [FILTERS_QUERY_PARAM]: isRecordEmpty(updatedSelectedFilters)
          ? undefined
          : JSON.stringify(updatedSelectedFilters),
        [LegacyFilterQueryParam.Category]: undefined,
        [LegacyFilterQueryParam.Color]: undefined,
        [LegacyFilterQueryParam.Fit]: undefined,
      })
    },
    [selectedFilters, updateQueryParams]
  )

  const toggleSelectedFilter = useCallback(
    (key: string, value: string) => {
      const updatedSelectedFilterValues: string[] = toggleItemInList(
        value,
        selectedFilters[key] ?? []
      )
      const updatedSelectedFilters = {
        ...selectedFilters,
      }
      if (updatedSelectedFilterValues.length > 0) {
        updatedSelectedFilters[key] = updatedSelectedFilterValues
      } else {
        delete updatedSelectedFilters[key]
      }
      updateQueryParams({
        [FILTERS_QUERY_PARAM]: isRecordEmpty(updatedSelectedFilters)
          ? undefined
          : JSON.stringify(updatedSelectedFilters),
        [LegacyFilterQueryParam.Category]: undefined,
        [LegacyFilterQueryParam.Color]: undefined,
        [LegacyFilterQueryParam.Fit]: undefined,
      })
    },
    [selectedFilters, updateQueryParams]
  )

  const clearAllFilters = useCallback(() => {
    updateQueryParams({
      [FILTERS_QUERY_PARAM]: undefined,
      [LegacyFilterQueryParam.Category]: undefined,
      [LegacyFilterQueryParam.Color]: undefined,
      [LegacyFilterQueryParam.Fit]: undefined,
    })
  }, [updateQueryParams])

  const totalNumberOfSelectedFilterValues: number = useMemo(
    () =>
      Object.values(selectedFilters).reduce(
        (count, filterValues) => count + filterValues.length,
        0
      ),
    [selectedFilters]
  )

  const value: ICollectionFilterQueryParamsContext = useMemo(
    () => ({
      clearAllFilters,
      selectedFilters,
      toggleSelectedFilter,
      updateFilterSelection,
      totalNumberOfSelectedFilterValues,
    }),
    [
      clearAllFilters,
      selectedFilters,
      toggleSelectedFilter,
      updateFilterSelection,
      totalNumberOfSelectedFilterValues,
    ]
  )

  return value
}
