import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const ORDER_HISTORY = gql(`
  query shopOrders($after: String, $email: String!, $first: Int, $reverse: Boolean) {
    shopOrders(email: $email, first: $first, after: $after, reverse: $reverse) {
      shopOrders {
        createdAt
        displayFinancialStatus
        id
        name
        total
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)
