import React, { CSSProperties } from 'react'

import { SelectMark, TypeStyle } from '@syconium/little-miss-figgy'

import { NextModal } from '../../../components/modals/NextModal'
import { useFixturesContext } from '../../containers/fixtures'
import {
  modalAnimationDuration,
  solidModalBodyOpenClassName,
  solidModalPortalClassName,
} from '../../styles/constants'

import { CloseButton, TitleBar, TitleBarCenter, TitleBarLeft, TitleBarRight } from './styles'

/**
 * Content-height overlay drawer, slides up from the bottom.
 */
export const TrayModal: React.FC<{
  isModalOpen: boolean
  onRequestClose(): void
  title: string
  overflow?: CSSProperties['overflow']
  children?: React.ReactNode
}> = ({ children, isModalOpen, onRequestClose, title, overflow = 'auto' }) => {
  const { trayModal: trayModalStrings } = useFixturesContext()

  return (
    <NextModal
      onRequestClose={onRequestClose}
      bodyOpenClassName={solidModalBodyOpenClassName}
      closeTimeoutMS={modalAnimationDuration}
      isOpen={isModalOpen}
      portalClassName={solidModalPortalClassName}
      style={{
        content: {
          background: 'white',
          borderRadius: 0,
          borderWidth: 0,
          bottom: 0,
          left: 0,
          maxHeight: '100%',
          overflow,
          padding: 0,
          right: 0,
          top: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      {title && (
        <TitleBar>
          <TitleBarLeft />
          <TitleBarCenter>
            <TypeStyle.HeadlineExtraSmall>{title}</TypeStyle.HeadlineExtraSmall>
          </TitleBarCenter>
          <TitleBarRight>
            <CloseButton aria-label={trayModalStrings.close} onClick={onRequestClose}>
              <SelectMark height='16px' stroke='currentColor' width='16px' />
            </CloseButton>
          </TitleBarRight>
        </TitleBar>
      )}
      {children}
    </NextModal>
  )
}
