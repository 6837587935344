import {
  modalAnimationDuration,
  solidModalBodyOpenClassName,
  solidModalPortalClassName,
} from '@syconium/magnolia/src/brunswick/styles/constants'
import { solidModalStyles } from '@syconium/magnolia/src/brunswick/styles/shared'
import * as RegionLocale from '@syconium/magnolia/src/components/region-locale'

import { NextModal } from '../NextModal'

import { RegionAndLocalePickerContent } from './RegionAndLocalePickerContent'

export interface RegionAndLocalePickerModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const RegionAndLocalePickerModal = ({
  isOpen,
  closeModal,
}: RegionAndLocalePickerModalProps) => {
  return (
    <NextModal
      bodyOpenClassName={solidModalBodyOpenClassName}
      closeTimeoutMS={modalAnimationDuration}
      isOpen={isOpen}
      onRequestClose={closeModal}
      portalClassName={solidModalPortalClassName}
      shouldCloseOnOverlayClick={true}
      style={solidModalStyles}
    >
      <RegionAndLocalePickerContent onReqeustClose={closeModal} />
      <RegionLocale.IntlFooter onRequestClose={closeModal} />
    </NextModal>
  )
}
