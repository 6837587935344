import { useRouter } from 'next/router'

import { getLinkProps, isSpaHref } from './utils'

export const useMaybeSpaLink = (href: string) => {
  const router = useRouter()
  const isLinkInIframe = router?.pathname === '/pages/plain/[handle]'
  const isSpaLink = isSpaHref(href)
  const spaLinkProps = getLinkProps(href)
  const spaLinkRoute = spaLinkProps.href
  const spaLinkDisplayHref = spaLinkProps.as

  const spaLinkHandler = () => {
    if (isLinkInIframe && window && window.top) {
      window.top.location.href = href
    }

    if (!isLinkInIframe) {
      router.push(spaLinkRoute, spaLinkDisplayHref)
    }
  }

  return {
    isSpaLink,
    isLinkInIframe,
    spaLinkHandler,
    spaLinkHref: spaLinkDisplayHref,
  }
}
