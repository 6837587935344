import { stringifyUrl } from 'query-string'

import { ImageLoader, ImageLoaderProps } from './imageLoader'

/**
 * GIFs are ignored for now because:
 * - they cannot be converted while keeping the animation
 * - we are aiming to deprecate them in Contentful
 */
const shouldIgnore = (src: string) => /\.gif($|\?)/.test(src)

const withQuality = (quality: ImageLoaderProps['quality']) => (quality ? { q: quality } : {})
const withWidth = (width: ImageLoaderProps['width']) => ({ w: width })
const withWebp = () => ({ fm: 'webp' })

const withProgressiveJpg = (src: string) => {
  // PNGs are not converted to PJPG because they would loose transparency
  if (/\.png($|\?)/.test(src)) return undefined

  return { fm: 'jpg', fl: 'progressive' }
}

export const createContentfulImageLoader = ({
  isWebpSupported,
  devicePixelRatio,
}: {
  isWebpSupported: boolean
  devicePixelRatio: number
}): ImageLoader => {
  const withFormat = isWebpSupported ? withWebp : withProgressiveJpg

  return ({ src, quality, width }) => {
    if (shouldIgnore(src)) return src

    return stringifyUrl({
      url: src,
      query: {
        ...withQuality(quality),
        ...(width && withWidth(Math.ceil(width * devicePixelRatio))),
        ...withFormat(src),
      },
    })
  }
}
