import React from 'react'

import { ExpansionPanel } from '@syconium/little-miss-figgy'

import { SearchOverlayForm } from '../SearchOverlayForm'

import { ExpansionPanelWrapper, Overlay, StyledExpansionPanelDetails } from './styles'

export const Template = React.memo<{
  closeSearchOverlay(): void
  onFormSubmit: (searchText: string) => void
  promoBar: boolean
  promoBarHeightDesktop?: string | number
  promoBarHeightDesktopPixels: number
  promoBarHeightMobile?: string | number
  promoBarHeightMobilePixels: number
  scrollDirection: string
  shouldDisplaySearchOverlay: boolean
}>(
  ({
    closeSearchOverlay,
    onFormSubmit,
    promoBar,
    promoBarHeightDesktop,
    promoBarHeightDesktopPixels,
    promoBarHeightMobile,
    promoBarHeightMobilePixels,
    scrollDirection,
    shouldDisplaySearchOverlay,
  }) => {
    return (
      <>
        <ExpansionPanelWrapper
          promoBar={promoBar}
          promoBarHeightDesktopPixels={promoBarHeightDesktopPixels}
          promoBarHeightMobilePixels={promoBarHeightMobilePixels}
          scrollDirection={scrollDirection}
        >
          <ExpansionPanel.Container isExpanded={shouldDisplaySearchOverlay}>
            <ExpansionPanel.Details>
              <StyledExpansionPanelDetails>
                <SearchOverlayForm
                  onFormSubmit={onFormSubmit}
                  shouldDisplaySearchOverlay={shouldDisplaySearchOverlay}
                  focusOnMount
                  onClearFormClick={closeSearchOverlay}
                  onClickSearchOverlayProduct={closeSearchOverlay}
                />
              </StyledExpansionPanelDetails>
            </ExpansionPanel.Details>
          </ExpansionPanel.Container>
        </ExpansionPanelWrapper>

        <Overlay
          promoBarHeightDesktop={promoBarHeightDesktop}
          promoBarHeightMobile={promoBarHeightMobile}
          shouldDisplaySearchOverlay={shouldDisplaySearchOverlay}
          onClick={closeSearchOverlay}
        />
      </>
    )
  }
)
