import { v4 as generateUuid } from 'uuid'

import { hashPrefixed, intlPrettyCurrency } from '@syconium/magnolia/src/lib/utils'
import { ShopRegion, SupportedLocaleIdentifier } from '@syconium/weeping-figs'

import { Embroidery, EmbroideryProductKind } from '../../../../types/graphql'
import {
  DTCAddLogoFormValues,
  DTCLogoSetupFormValues,
  EmbroideryFormOptions,
  EmbroideryFormValues,
  EmbroideryItemCustomProperties,
  IconFormValues,
  LogoAndTextFormValues,
  LogoFormValues,
  TextFormValues,
} from '../types'

export interface EmbroideryPriceRange {
  lowestPrice: string
  highestPrice: string
}

export function derivePriceRange(
  embroideryOptions: ReadonlyArray<Embroidery>,
  region: ShopRegion,
  locale: SupportedLocaleIdentifier
): EmbroideryPriceRange {
  const allEmbroideryOptionPrices: number[] = embroideryOptions.flatMap(embroideryOption =>
    embroideryOption.variants.flatMap(variant => variant?.priceDetails?.price?.amount)
  )

  if (allEmbroideryOptionPrices.length === 0) return { highestPrice: '', lowestPrice: '' }

  if (allEmbroideryOptionPrices.length === 1) {
    const onlyPrice: string = intlPrettyCurrency({
      cents: allEmbroideryOptionPrices[0],
      region: region,
      locale: locale,
      truncateTrailingZeros: true,
    })
    return {
      lowestPrice: onlyPrice,
      highestPrice: onlyPrice,
    }
  }
  const lowestPrice: number = allEmbroideryOptionPrices.reduce((lowest, current) =>
    Math.min(lowest, current)
  )
  const highestPrice: number = allEmbroideryOptionPrices.reduce((lowest, current) =>
    Math.max(lowest, current)
  )

  return {
    lowestPrice: intlPrettyCurrency({
      cents: lowestPrice,
      region: region,
      locale: locale,
      truncateTrailingZeros: true,
    }),
    highestPrice: intlPrettyCurrency({
      cents: highestPrice,
      region: region,
      locale: locale,
      truncateTrailingZeros: true,
    }),
  }
}

export function deriveFontFamilyFromStyle(fontStyle: string): string {
  switch (fontStyle) {
    case 'Script':
      return 'VarsityScriptJF'
    case 'Block':
    default:
      return 'Helvetica'
  }
}

export function deriveColorValueFromColorName(name: string): string {
  switch (name) {
    case 'Black':
      return 'rgb(0, 0, 0)'
    case 'White':
    default:
      return 'rgb(255, 255, 255)'
  }
}

export function generateLineItemProperties(
  options: EmbroideryFormOptions | undefined,
  productColorHex: string | undefined,
  shopifyId: number | undefined,
  logoSetupId?: number | undefined
): EmbroideryItemCustomProperties {
  if (!options) return {}
  const {
    iconDesignFile,
    iconDesignName,
    iconInitials,
    iconInitialsColor,
    iconImageSource,
    iconPlacement,
    logoName,
    logoDesignFile,
    logoPlacement,
    logoImageSource,
    textColor,
    textFirstLine,
    textFont,
    textPlacement,
    textSecondLine,
    logoId,
    logoPreview,
    logoColor,
    logoNote,
  } = options

  const allProperties: EmbroideryItemCustomProperties = {
    _guid: generateUuid(),
    text_first_line: textFirstLine,
    text_second_line: textSecondLine,
    text_color: textColor,
    text_placement: textPlacement,
    text_font: textFont,
    icon_placement: iconPlacement,
    icon_design: iconDesignName,
    icon_initials: iconInitials,
    icon_initials_color: iconInitials && iconInitialsColor,
    _icon_file_name: iconDesignFile,
    _text_font_family_value: textFont ? deriveFontFamilyFromStyle(textFont) : undefined,
    _icon_font_family_value: iconInitials ? 'Helvetica' : undefined,
    _icon_image_source: iconImageSource,
    _logo_file_name: logoDesignFile,
    _logo_image_source: logoImageSource,
    _logo_id: logoId,
    logo_placement: logoPlacement,
    logo_preview: logoPreview,
    logo_name: logoName,
    logo_color_option: logoColor,
    logo_note: logoNote,
    _LPROP: 'final sale',
    _embroideryId: shopifyId?.toString(),
    _scrub_color: hashPrefixed(productColorHex),
    _text_thread_color_value: textColor ? deriveColorValueFromColorName(textColor) : undefined,
    _icon_thread_color_value: iconInitialsColor
      ? deriveColorValueFromColorName(iconInitialsColor)
      : undefined,
    _embroidery_item: '',
    _embroidery_version: '1.5',
    _logo_setup_embroideryId: logoSetupId?.toString(),
    _logo_setup_guid: logoSetupId ? generateUuid() : undefined,
  }

  return removeUnusedProperties(allProperties)
}

function removeUnusedProperties(o: EmbroideryItemCustomProperties): EmbroideryItemCustomProperties {
  return Object.entries(o).reduce((accumulator, [key, val]) => {
    // Shopify Checkout page reads the _embroidery_item empty string value to style embroidery items in the cart. (Kyle Corbelli 07/21/20)
    return val || key === '_embroidery_item' ? { ...accumulator, [key]: val } : accumulator
  }, {})
}

export const emptyIconFormValues: IconFormValues = {
  __type: 'IconFormValues',
  iconPlacement: undefined,
  initials: '',
  initialsColor: '',
  iconImage: undefined,
}

export const emptyTextFormValues: TextFormValues = {
  __type: 'TextFormValues',
  textColor: '',
  font: undefined,
  firstLine: '',
  textPlacement: undefined,
  secondLine: '',
}

export const emptyLogoFormValues: LogoFormValues = {
  __type: 'LogoFormValues',
  logoName: '',
  logoImageSource: '',
}

export const emptyLogoAndTextFormValues: LogoAndTextFormValues = {
  ...emptyTextFormValues,
  ...emptyLogoFormValues,
  __type: 'LogoAndTextFormValues',
}

export function textDefaultValues(embroideryOption: Embroidery): TextFormValues {
  return {
    __type: 'TextFormValues',
    textColor: embroideryOption?.textColors?.[0] ?? '',
    font: embroideryOption?.textFonts?.[0] ?? undefined,
    firstLine: '',
    textPlacement: embroideryOption?.textPlacements?.[0] ?? undefined,
    secondLine: '',
  }
}

export function iconDefaultValues(embroideryOption: Embroidery): IconFormValues {
  const iconPlacement = embroideryOption?.iconPlacements?.[0] ?? undefined

  return {
    __type: 'IconFormValues',
    // We always want to display the "flatlay-chest" overlay image for icon embroidery
    iconPlacement: iconPlacement ? { ...iconPlacement, flatlayKind: 'flatlay-chest' } : undefined,
    initials: '',
    initialsColor: embroideryOption?.initialsColors?.[0] ?? '',
    iconImage: undefined,
  }
}

export function logoAndTextDefaultValues(embroideryOption: Embroidery): LogoAndTextFormValues {
  return {
    ...textDefaultValues(embroideryOption),
    ...emptyLogoFormValues,
    __type: 'LogoAndTextFormValues',
  }
}

export const logoSetupDefaultValues: DTCLogoSetupFormValues = {
  __type: 'DTCLogoEmbroideryFormValues',
  name: undefined,
  notes: undefined,
  tos: undefined,
  file: undefined,
  digitization_setting: undefined,
}

export const addLogoDefaultValues: DTCAddLogoFormValues = {
  __type: 'AddLogoFormValues',
  logoFileId: undefined,
  logoFile: undefined,
  logoPlacement: {
    flatlayKind: 'flatlay-chest',
    name: 'Left Chest',
    offsetKey: 'left-chest',
  },
}

export function mapEmbroideryOptionToInitialFormValues<T extends EmbroideryProductKind>(
  kind: T,
  embroideryOption: Embroidery
): EmbroideryFormValues[T] {
  const map = {
    ICON_ONLY: iconDefaultValues(embroideryOption),
    TEXT_ONLY: textDefaultValues(embroideryOption),
    LOGO_ONLY: emptyLogoFormValues,
    LOGO_TEXT: emptyLogoAndTextFormValues,
    LOGO_SETUP: logoSetupDefaultValues,
    LOGO_BLACK_WHITE: addLogoDefaultValues,
    LOGO_COLOR: addLogoDefaultValues,
  } as const

  return map[kind]
}

export const allowedTextInputCharactersPattern = /^[!@#$%&*()+=\-:“;’<>.?,\/a-zA-Z0-9\s]+$/
export const allowedIconTextInputCharactersPattern = /^[A-Z]+$/
export const allowedLogoNameInputCharactersPattern = /^[a-zA-Z0-9\s'-.]+$/
