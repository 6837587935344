import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_PRODUCT_SIZE_CHART = gql(`
  query getProductGroups(
    $handle: String
    $isPreview: Boolean = false
    $includeUnavailable: Boolean = false
  ) {
    productGroups(handle: $handle, isPreview: $isPreview, includeUnavailable: $includeUnavailable) {
      nodes {
        title
        seoTitle
        sizeChart: sizeChartCG {
          columnNames
          columnValues
          imageUri
          name
          navLabel
          tables {
            cells
            measureImage
            measureTips {
              kind
              tip
            }
            sizeTips {
              kind
              tip
            }
            subtitle
            tabLabel
            title
          }
          tips {
            kind
            tip
          }
          title
          video
        }
      }
    }
  }
`)
