import * as React from 'react'

import {
  createContentfulImageLoader,
  createImageLoader,
  createShopifyImageLoader,
} from './image-loaders'

export const useImageLoader = () => {
  /**
   * This should not be used... I have a story that will likely happen in the next
   * month to replace all the concepts like this with actually just using the responsive
   * image features browsers provide. Pixel ratios and webp support is not something
   * we can check during a SSR. Doing so causes huge issues by replacing the src in hydration
   * which causes the user to load two images instead of one. It also causes them to get a flash
   * of white content when the src changes which then causes incorrect LCP measurement times and
   * potentially hurts our SEO rating.
   *
   * Device pixel ratio will not always be 2. I realize that, but we were returning 2 anyways for
   * every server-side render. Hence needing to just burn all this to the ground and replace it with
   * utilities that generate size and srcset attributes... not what this is doing.
   */
  return React.useMemo(() => {
    const isWebpSupported = false
    const devicePixelRatio = 2
    const contentfulImageLoader = createContentfulImageLoader({ devicePixelRatio, isWebpSupported })
    const shopifyImageLoader = createShopifyImageLoader({ devicePixelRatio })
    const imageLoader = createImageLoader({ contentfulImageLoader, shopifyImageLoader })

    return {
      contentfulImageLoader,
      shopifyImageLoader,
      imageLoader,
    }
  }, [])
}
