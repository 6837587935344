import { useRouter } from 'next/router'
import React from 'react'

import { moveFocus, timingSlowerMs } from '@syconium/little-miss-figgy'
import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { GenderPreferenceContainer } from '../../../containers/GenderPreferenceContainer'
import { getTabGender } from '../NavModalContent/useViewModel'

export function useViewModel({
  freezeTabId,
  tabs,
  wrapItems = false,
  exitedTab,
}: {
  freezeTabId?: string
  tabs: NavTab[]
  wrapItems?: boolean
  exitedTab: React.MutableRefObject<boolean | undefined>
}) {
  const [activeTab, setActiveTab] = React.useState<NavTab | null>(null)
  const bodyLeaveTimer = React.useRef<ReturnType<typeof setTimeout> | undefined>()
  const router = useRouter()

  const { setPotentialGenderPreference, setGenderPreference } =
    GenderPreferenceContainer.useContainer()
  const isFrozen = !!freezeTabId

  const onMouseLeaveBody = (_event: React.MouseEvent<HTMLElement>) => {
    if (isFrozen) return
    if (bodyLeaveTimer.current) clearTimeout(bodyLeaveTimer.current)
    const delay = activeTab?.sections.length ? timingSlowerMs : 0
    bodyLeaveTimer.current = setTimeout(() => setActiveTab(null), delay)
  }

  const onMouseEnterBody = (_event: React.MouseEvent<HTMLElement>) => {
    if (isFrozen) return
    if (bodyLeaveTimer.current) clearTimeout(bodyLeaveTimer.current)
    bodyLeaveTimer.current = undefined
  }

  const onKeyDownBody = (event: React.KeyboardEvent<HTMLElement>) => {
    if (isFrozen) return
    if (event.key === 'Escape' || event.key === 'ArrowUp') setActiveTab(null)
  }

  /*
    if a hover event is coming from a tab with that contains a menu delay the firing of setting
    a new active tab if the cursor has not left the hovered tab after 250ms otherwise change
    the active tab to the currently hovered tab
  */
  const tabsWithMenu = ['Men', 'Women', 'Impact']
  const tabHoverDelay = 200

  const getOnMouseEnterTab =
    (tab: NavTab) => (_event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
      const relatedTarget = _event?.relatedTarget as HTMLElement
      const target = _event?.target as HTMLElement
      if (
        tabsWithMenu.includes(relatedTarget?.innerText) &&
        tabsWithMenu.includes(target?.innerText)
      ) {
        setTimeout(() => {
          if (!exitedTab.current) {
            setActiveTab(tab)
            setPotentialGenderPreference(getTabGender(tab))
            exitedTab.current = undefined
          }
        }, tabHoverDelay)
      } else {
        if (isFrozen) return
        setActiveTab(tab)
        setPotentialGenderPreference(getTabGender(tab))
      }
    }

  const getOnMouseLeaveTab =
    () => (_event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
      const target = _event?.relatedTarget as HTMLElement
      if (tabsWithMenu.includes(target.innerText)) {
        exitedTab.current = undefined
      } else {
        exitedTab.current = true
      }
    }

  const moveToNextTab = (event: React.KeyboardEvent<HTMLElement>) => {
    const currentTabIndex = tabs.findIndex(tab => tab.id === activeTab?.id)

    if (currentTabIndex !== -1) {
      const newIndex = event.shiftKey ? currentTabIndex - 1 : currentTabIndex + 1
      const isNewIndexValid = newIndex >= 0 && newIndex <= tabs.length - 1

      event.preventDefault()

      if (isNewIndexValid) {
        // @ts-ignore
        setActiveTab(tabs[newIndex])
        // @ts-ignore
        const tabElement = document.getElementById(`nav-tab-${tabs[newIndex].id}`)

        if (event.shiftKey) {
          moveFocus(tabElement, true)
        } else {
          moveFocus(tabElement)
        }
      } else {
        setActiveTab(null)
      }
    }
  }

  const getOnKeyDownTab = (tab: NavTab) => (event: React.KeyboardEvent<HTMLElement>) => {
    if (isFrozen && tab.id !== freezeTabId) return

    if (event.key === 'Tab') {
      moveToNextTab(event)
      setActiveTab(null)
    } else if (event.key === 'Enter' && tab.href) {
      event.preventDefault()
      router.push(tab.href)
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      setActiveTab(tab)
      moveFocus(document.activeElement?.nextElementSibling)
      event.preventDefault()
    }
  }

  const onSubMenuKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const shouldMoveToNext = event.key === 'ArrowDown' || event.key === 'ArrowRight'
    const shouldMoveToPrevious = event.key === 'ArrowUp' || event.key === 'ArrowLeft'
    const activeTabId = activeTab?.id
    if (!activeTabId) return

    const navigationElement = document.getElementById(`nav-tab-${activeTabId}-content`)
    if (!navigationElement) return

    if (shouldMoveToNext) {
      moveFocus(navigationElement)
      event.preventDefault()
    } else if (shouldMoveToPrevious) {
      moveFocus(navigationElement, true)
      event.preventDefault()
    } else if (event.key === 'Tab') {
      moveToNextTab(event)
    }
  }

  const getOnClickTab = (tab: NavTab) => (event: React.MouseEvent<HTMLElement>) => {
    setGenderPreference(getTabGender(tab))
    if (isFrozen && tab.id !== freezeTabId) return
    if (tab.onClickish) tab.onClickish(event)
    setActiveTab(tab)
    if (tab.href) {
      router.push(tab.href)
    }
  }

  return {
    activeTab,
    deselectCurrentTab: () => setActiveTab(null),
    freezeTabId,
    getOnClickTab,
    getOnKeyDownTab,
    getOnMouseEnterTab,
    getOnMouseLeaveTab,
    onKeyDownBody,
    onMouseEnterBody,
    onMouseLeaveBody,
    onSubMenuKeyDown,
    tabs,
    wrapItems,
  }
}
