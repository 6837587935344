export interface IProps {
  className?: string
  fill?: string
  height?: string
  rotateClockwise?: RotateClockwise
  stroke?: string
  width?: string
  transition?: string
  children?: React.ReactNode
  backgroundFill?: string
}

export enum RotateClockwise {
  Quarter = '90deg',
  Half = '180deg',
  ThreeQuarters = '270deg',
  QuarterReverse = '-90deg',
}
