import Cookies from 'universal-cookie'

import { getUserDataFromLS } from '../utils'

type RequestContext = {
  __TypeInfo: 'request'
  pageUrl: string
  pageTitle: string
  timezone: string
  userAgent: string
}

type UserContext = {
  __TypeInfo: 'user'
  shopifyCustomerId: string
  emailAddress: string
}

type ThirdPartyContext = {
  __TypeInfo: 'thirdparty'
  heapSessionId: string
}

export class SignalClient {
  track(event: string, attributes: Record<string, unknown>) {
    if (process.env.NEXT_PUBLIC_USER_SIGNALS_ENABLED !== 'true') return

    const data = {
      sessionId: this.retrieveSessionId(),
      event: event,
      attributes: attributes,
      context: this.createContext(),
      createdAt: new Date().toISOString(),
    }

    const url = process.env.NEXT_PUBLIC_REST_API_BASE_URL + '/user-signal/v1/signals'

    fetch(url, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(data),
    }).catch(error => console.error(error))
  }

  private retrieveSessionId() {
    //Temporarily store the sessionId in sessionStorage till we relax caching logic on the CDN

    let sessionId = sessionStorage.getItem('figsSessionId')

    if (sessionId == null) {
      sessionId = crypto.randomUUID()
      sessionStorage.setItem('figsSessionId', sessionId)
    }

    return sessionId
  }

  private createContext() {
    const context = []

    const requestContext = this.buildRequestContext()
    if (requestContext) {
      context.push(requestContext)
    }

    const userContext = this.buildUserContext()
    if (userContext) {
      context.push(userContext)
    }

    const thirdpartyContext = this.buildThirdPartyContext()
    if (thirdpartyContext) {
      context.push(thirdpartyContext)
    }

    return context
  }

  private buildRequestContext() {
    if (document && window) {
      const requestContext: RequestContext = {
        __TypeInfo: 'request',
        pageTitle: document.title,
        pageUrl: document.URL,
        timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
        userAgent: window.navigator.userAgent,
      }
      return requestContext
    }
    return null
  }

  private buildUserContext() {
    const customer = getUserDataFromLS()
    if (customer) {
      const userContext: UserContext = {
        __TypeInfo: 'user',
        shopifyCustomerId: customer.shopifyId || '',
        emailAddress: customer.email,
      }
      return userContext
    }
    return null
  }

  private buildThirdPartyContext() {
    if (heap && document) {
      const heapCookie = this.lookupHeapCookie()

      if (heapCookie) {
        const thirdpartyContext: ThirdPartyContext = {
          __TypeInfo: 'thirdparty',
          heapSessionId: heapCookie.sessionId,
        }
        return thirdpartyContext
      }
    }
    return null
  }

  private lookupHeapCookie() {
    const heapCookieId = '_hp2_id.' + process.env.NEXT_PUBLIC_HEAP_APP_ID

    return new Cookies().get(heapCookieId)
  }
}
