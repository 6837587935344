import * as React from 'react'

import { createContextHook } from '../../../lib/utils/createContextHook'

import { useCmsProvidedSiteFixtures } from './hooks/useCmsProvidedSiteFixtures'
import { InitialSiteFixtures, SiteFixtures } from './IFixtures'

export const FixturesContext = React.createContext<SiteFixtures | undefined>(undefined)

export const FixturesContextProvider: React.FC<{
  initialFixtures: InitialSiteFixtures
  children?: React.ReactNode
}> = ({ initialFixtures, children }) => {
  const cmsSiteFixtures = useCmsProvidedSiteFixtures()

  const fixtureData: SiteFixtures = React.useMemo(
    () => ({
      ...initialFixtures,
      ...cmsSiteFixtures,
    }),
    [initialFixtures, cmsSiteFixtures]
  )

  return <FixturesContext.Provider value={fixtureData}>{children}</FixturesContext.Provider>
}

export const useFixturesContext = createContextHook(FixturesContext, 'FixturesContext')
