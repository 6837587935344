import { useCallback, useRef, useState } from 'react'
import { useMount } from 'react-use'
interface Args<
  State extends Record<string, unknown> | Array<unknown> | string | number | undefined | boolean,
> {
  storageKey: string
  initialState: State
  storage?: Storage
  ssr?: boolean
}

/**
 * Manage state that is stored in browser storage.
 *
 * @template State
 * @param {Object} opts
 * @param {string} opts.storageKey - The key you want to use to create/update the value in browser storage.
 * @param {State} opts.initialState - The initial state value. This is returned if you are in an environment that doesn't support your storage method, or if your storage key doesn't exist.
 * @param {Storage} [opts.storage] - The storage interface to use. Must be compatible with the Storage API (https://developer.mozilla.org/en-US/docs/Web/API/Storage). Defaults to `localStorage`.
 * @param {boolean} [opts.ssr] - Defer syncing the state until after the initial render on the client. This is useful if you are rendering markup based on a storage value & want to avoid hydration mismatches. Defaults to false.
 */
export function useBrowserStoredState<
  State extends Record<string, unknown> | Array<unknown> | string | number | undefined | boolean,
>({
  storageKey,
  initialState,
  storage = globalThis.localStorage ?? globalThis.sessionStorage,
  ssr = false,
}: Args<State>) {
  const [state, _setState] = useState<State>(() => {
    // If the callee specified they want to leverage the same initial state
    // value as a prior ssr execution, use the static initial state they provided
    // since it is the only thing guaranteed to be stable here.

    if (ssr) return initialState

    // Otherwise pull browser-stored data into in-memory state on initial mount.
    // If we are on the server, this will just result in undefined storedState
    // anyways and return `initialState` all the same.
    const storedState = storage?.getItem(storageKey)
    return storedState ? JSON.parse(storedState) : initialState
  })

  // Track if external code has called `setState` or not.
  const setStateCalledRef = useRef(false)

  const setState = useCallback(
    (newState: React.SetStateAction<State>) => {
      setStateCalledRef.current = true

      // Write to browser stored data when in-memory state is changed
      _setState(prevState => {
        const nextState = typeof newState === 'function' ? newState(prevState) : newState
        storage?.setItem(storageKey, JSON.stringify(nextState))
        return nextState
      })
    },
    [storage, storageKey]
  )

  // If ssr was enabled, we want to sync storage into our in-memory state now after initial render.
  // Since we ignored local storage contents in the initial render based on the `ssr` flag being true.
  // Above we ensured that the client initially rendered with the same value that was used
  // during the server render, guarding against hydration mismatches. Now we can pull what
  // was on the client finally since `useMount` leverages a `useEffect` which occurs as a second render.
  useMount(() => {
    // If we didn't ask for SSR we would already have used storage for initial state.
    // If external code has altered the state since creation, we are too late and storage
    // is altered as well anyways. So just return.
    if (!ssr || setStateCalledRef.current === true) return
    const storedState = storage?.getItem(storageKey)
    if (storedState) {
      setState(JSON.parse(storedState))
    }
  })

  return {
    state,
    setState,
  }
}
