import parse, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from 'html-react-parser'
import React, { useEffect, useState } from 'react'

import { SelectMark } from '@syconium/little-miss-figgy'
import { NavContainer } from '@syconium/magnolia/src/brunswick/containers/NavContainer'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { Body, CloseButton, CloseContainer, Content, Page, Pages } from './styles'
import { PromoBarDisplayPages, ViewModel } from './types'

const getParseOptions = (tabIndex?: number) => {
  const results: HTMLReactParserOptions = {
    replace: domNode => {
      if (domNode instanceof Element && domNode.name === 'a') {
        const { attribs, children } = domNode
        // @ts-ignore TODO: Handle the fact that data is not a known property
        const label: string = (children && children[0]?.data) ?? ''
        const parseOptions = getParseOptions(tabIndex)

        return (
          <>
            {/* TODO: Handle this auto disabled ESLint rule. */}
            {/* eslint-disable-next-line rulesdir/require-routing-service */}
            <a
              tabIndex={tabIndex}
              {...attributesToProps(attribs)}
              {...trackEvent({ category: 'promo bar', action: 'click link', label })}
            >
              {/** @ts-ignore TODO: Narrow the children type of ChildNode[] to the more narrow type of DomNode[] */}
              {children && domToReact(children, parseOptions)}
            </a>
          </>
        )
      }
      return
    },
  }

  return results
}

const isDuplicatePage = (pages: PromoBarDisplayPages, index: number) => {
  if (index === 0) {
    return pages[0]?.id === pages[pages.length - 1]?.id
  }
  return pages[index]?.id === pages[index - 1]?.id
}

export const Template: React.FC<ViewModel> = ({
  autoTransition,
  backgroundColor,
  textColor,
  displayPages,
  onTransition,
  handleKeyDown,
  transition,
  isFadedOut,
}) => {
  const {
    promoBarHeightDesktop,
    promoBarHeightDesktopPixels,
    promoBarHeightMobile,
    promoBarHeightMobilePixels,
    promoBarIsOpen: isOpen,
    setPromoBarIsOpen,
  } = NavContainer.useContainer()
  const [isHover, setIsHover] = useState<boolean>(false)
  const displayPagesLength: number = displayPages.length
  const onCloseButtonClick = () => {
    setPromoBarIsOpen(!isOpen)
  }

  useEffect(() => {
    if (!autoTransition) return
    const interval = setInterval(() => !isHover && onTransition(), 4000)
    return () => {
      clearInterval(interval)
    }
  }, [isHover, onTransition, autoTransition])

  if (!isOpen) return null

  return (
    <Body
      {...{
        backgroundColor,
        isFadedOut,
        promoBarHeightDesktop,
        promoBarHeightMobile,
        textColor,
      }}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      onFocus={() => setIsHover(true)}
      onBlur={() => setIsHover(false)}
      onKeyDown={event => handleKeyDown(event)}
      id={'geFreeShippingBannerContainer'}
    >
      <Content>
        <Pages
          {...{
            displayPagesLength,
            promoBarHeightDesktopPixels,
            promoBarHeightMobilePixels,
            transition,
          }}
          tabIndex={0}
        >
          {displayPages.map((page, index) => {
            const isDuplicate = isDuplicatePage(displayPages, index)
            const tabIndex = isDuplicate ? -1 : undefined
            const parseOptions = getParseOptions(tabIndex)

            return (
              <Page
                {...{ promoBarHeightDesktopPixels, promoBarHeightMobilePixels }}
                key={`${page.id}.${index}`}
              >
                {parse(page.content, parseOptions)}
              </Page>
            )
          })}
        </Pages>

        <CloseContainer>
          <CloseButton onClick={onCloseButtonClick} value='close'>
            <SelectMark aria-label='close' height='10px' stroke='currentColor' width='10px' />
          </CloseButton>
        </CloseContainer>
      </Content>
    </Body>
  )
}
