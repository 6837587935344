/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  Cents: { input: any; output: any }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: any; output: any }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any }
  /** Represents untyped JSON */
  JSON: { input: any; output: any }
  /** An Int scalar that must be a positive value */
  PositiveInt: { input: any; output: any }
}

export type AccordionView = {
  __typename?: 'AccordionView'
  id: Scalars['ID']['output']
  maxWidth: Maybe<Scalars['Float']['output']>
  sections: Array<AccordionViewSection>
}

export type AccordionViewSection = {
  __typename?: 'AccordionViewSection'
  content: Maybe<AccordionViewSectionContent>
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type AccordionViewSectionContent = RawHtmlSection | RichTextSection

/** Autogenerated return type of ActivateShopUser. */
export type ActivateShopUserPayload = {
  __typename?: 'ActivateShopUserPayload'
  authToken: Maybe<Scalars['String']['output']>
  errors: Maybe<Array<UserError>>
  shopToken: Maybe<Scalars['String']['output']>
  shopUser: Maybe<ShopUser>
}

/** Autogenerated return type of AddUserProperties. */
export type AddUserPropertiesPayload = {
  __typename?: 'AddUserPropertiesPayload'
  errors: Maybe<Array<UserError>>
  user: Maybe<User>
}

export type Address = {
  __typename?: 'Address'
  address1: Scalars['String']['output']
  address2: Scalars['String']['output']
  city: Scalars['String']['output']
  company: Scalars['String']['output']
  country: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  province: Scalars['String']['output']
  shopifyId: Scalars['String']['output']
  zip: Scalars['String']['output']
}

export type AddressConnection = {
  __typename?: 'AddressConnection'
  edges: Array<AddressEdge>
  nodes: Array<Address>
  pageInfo: PageInfo
}

export type AddressEdge = {
  __typename?: 'AddressEdge'
  cursor: Scalars['String']['output']
  node: Address
}

export type AddressInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province: Scalars['String']['input']
  zip: Scalars['String']['input']
}

export type AlternateModelVariant = {
  __typename?: 'AlternateModelVariant'
  action: Scalars['String']['output']
  name: Scalars['String']['output']
  targetCriteria: Maybe<TargetCriteria>
  validContentfulModel: Maybe<ValidContentfulModel>
}

export type AppAccountNavSection = AppNavListItem | NavAccordion | NavListSection

export type AppNavListItem = {
  __typename?: 'AppNavListItem'
  backgroundImageApp: Maybe<Scalars['String']['output']>
  badge: Maybe<Scalars['String']['output']>
  badgeColor: Maybe<Scalars['String']['output']>
  badgeTextColor: Maybe<Scalars['String']['output']>
  href: Maybe<Scalars['String']['output']>
  icon: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isBold: Maybe<Scalars['Boolean']['output']>
  isCopiable: Maybe<Scalars['Boolean']['output']>
  isHeading: Maybe<Scalars['Boolean']['output']>
  isHidden: Maybe<Scalars['Boolean']['output']>
  text: Scalars['String']['output']
  textColor: Maybe<Scalars['String']['output']>
}

export type AppPageHeroSection = {
  __typename?: 'AppPageHeroSection'
  backgroundColor: Maybe<Scalars['String']['output']>
  buttonBackgroundColor: Maybe<Scalars['String']['output']>
  buttonPlacement: Maybe<Scalars['String']['output']>
  buttonTextColor: Maybe<Scalars['String']['output']>
  buttonVariant: Maybe<Scalars['String']['output']>
  countDownTimerPrecision: Maybe<Scalars['String']['output']>
  countDownTimerTargetTime: Maybe<Scalars['String']['output']>
  countDownTimerTheme: Scalars['String']['output']
  enableCountdownTimer: Maybe<Scalars['Boolean']['output']>
  enableFullBleedBackground: Maybe<Scalars['Boolean']['output']>
  enableTextBelowImage: Maybe<Scalars['Boolean']['output']>
  eyebrow: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  imageShopifyAsset: Maybe<Scalars['String']['output']>
  inlineVideo: Maybe<Scalars['String']['output']>
  inlineVimeoID: Maybe<Scalars['Int']['output']>
  layoutStyle: Scalars['String']['output']
  linkHref: Maybe<Scalars['String']['output']>
  linkText: Maybe<Scalars['String']['output']>
  personalizedMessaging: Maybe<PersonalizedMessagingTextBlock>
  subtitle: Maybe<Scalars['String']['output']>
  subtitleAlignment: Maybe<Scalars['String']['output']>
  subtitlePlacement: Maybe<Scalars['String']['output']>
  textColor: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
  titleAlignment: Maybe<PageSectionTitleAlignment>
  titleImage: Maybe<Scalars['String']['output']>
  titleImageMaxWidthMobile: Maybe<Scalars['Int']['output']>
  titleImageMobile: Maybe<Scalars['String']['output']>
  titlePlacement: Maybe<Scalars['String']['output']>
}

export type AppVersionUpdatePolicy = {
  __typename?: 'AppVersionUpdatePolicy'
  deprecatedVersion: Scalars['String']['output']
  deprecatedVersionPrompt: Maybe<AppPageHeroSection>
  id: Scalars['ID']['output']
  obsoleteVersion: Scalars['String']['output']
  obsoleteVersionPrompt: Maybe<AppPageHeroSection>
  outdatedVersion: Scalars['String']['output']
  outdatedVersionPrompt: Maybe<AppPageHeroSection>
}

/** Autogenerated return type of AssociateCheckout. */
export type AssociateCheckoutPayload = {
  __typename?: 'AssociateCheckoutPayload'
  checkoutId: Maybe<Scalars['ID']['output']>
  customerId: Maybe<Scalars['ID']['output']>
  errors: Maybe<Array<UserError>>
}

export type Attribute = {
  __typename?: 'Attribute'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type AuthInfo = {
  __typename?: 'AuthInfo'
  customerAccessToken: Scalars['String']['output']
  refreshToken: Scalars['String']['output']
  token: Scalars['String']['output']
}

export type BillingAddress = {
  __typename?: 'BillingAddress'
  addressLine1: Scalars['String']['output']
  addressLine2: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  country: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  postalCode: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type Cart = {
  __typename?: 'Cart'
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  billingAddress: Maybe<BillingAddress>
  cartItems: Maybe<Array<CartItem>>
  createdAt: Scalars['ISO8601DateTime']['output']
  csvExport: Maybe<Scalars['String']['output']>
  /** The maximum discount percentage of all cart items */
  currentDiscount: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  locked: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  numItems: Scalars['Int']['output']
  order: Maybe<TeamOrder>
  shippingAddress: Maybe<ShippingAddress>
  status: Scalars['String']['output']
  total: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  user: User
}

export type CartCategoryConstraint = {
  __typename?: 'CartCategoryConstraint'
  actualQuantity: Scalars['Int']['output']
  cart: Cart
  category: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  maxQuantity: Scalars['Int']['output']
  minQuantity: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for CartCategoryConstraint. */
export type CartCategoryConstraintConnection = {
  __typename?: 'CartCategoryConstraintConnection'
  /** A list of edges. */
  edges: Array<CartCategoryConstraintEdge>
  /** A list of nodes. */
  nodes: Array<CartCategoryConstraint>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type CartCategoryConstraintEdge = {
  __typename?: 'CartCategoryConstraintEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<CartCategoryConstraint>
}

export type CartInvitation = {
  __typename?: 'CartInvitation'
  acceptedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  cart: GroupCart
  createdAt: Scalars['ISO8601DateTime']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitee: Maybe<User>
  inviteeStatus: Scalars['String']['output']
  sentAt: Maybe<Scalars['ISO8601DateTime']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for CartInvitation. */
export type CartInvitationConnection = {
  __typename?: 'CartInvitationConnection'
  /** A list of edges. */
  edges: Array<CartInvitationEdge>
  /** A list of nodes. */
  nodes: Array<CartInvitation>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type CartInvitationEdge = {
  __typename?: 'CartInvitationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<CartInvitation>
}

export type CartItem = CartItemInterface & {
  __typename?: 'CartItem'
  cart: Cart
  createdAt: Scalars['ISO8601DateTime']['output']
  discountAmount: Maybe<Scalars['Cents']['output']>
  discountPercentage: Maybe<Scalars['Int']['output']>
  embroideryOption: Maybe<EmbroideryOption>
  id: Scalars['ID']['output']
  isReturnable: Maybe<Scalars['Boolean']['output']>
  owner: User
  quantity: Scalars['Int']['output']
  /** The associated item or embroidery item */
  relatedItem: Maybe<CartItem>
  subtotal: Maybe<Scalars['Cents']['output']>
  total: Maybe<Scalars['Cents']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  variant: Variant
}

/** The connection type for CartItem. */
export type CartItemConnection = {
  __typename?: 'CartItemConnection'
  /** A list of edges. */
  edges: Array<CartItemEdge>
  /** A list of nodes. */
  nodes: Array<CartItem>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type CartItemEdge = {
  __typename?: 'CartItemEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<CartItem>
}

export type CartItemInterface = {
  cart: Cart
  createdAt: Scalars['ISO8601DateTime']['output']
  discountAmount: Maybe<Scalars['Cents']['output']>
  discountPercentage: Maybe<Scalars['Int']['output']>
  embroideryOption: Maybe<EmbroideryOption>
  id: Scalars['ID']['output']
  isReturnable: Maybe<Scalars['Boolean']['output']>
  owner: User
  quantity: Scalars['Int']['output']
  /** The associated item or embroidery item */
  relatedItem: Maybe<CartItem>
  subtotal: Maybe<Scalars['Cents']['output']>
  total: Maybe<Scalars['Cents']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  variant: Variant
}

export type CartStatus = 'active' | 'archived' | 'complete'

export type Collection = FilteredProducts & {
  __typename?: 'Collection'
  availableForSale: Scalars['Boolean']['output']
  colors: Maybe<Array<ProductColor>>
  createdAt: Scalars['ISO8601DateTime']['output']
  filters: Maybe<Array<ProductFilter>>
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  items: Maybe<ProductConnection>
  name: Scalars['String']['output']
  productGroup: Maybe<ProductGroup>
  /** @deprecated Replaced by items */
  productGroupHandles: Maybe<Array<Scalars['String']['output']>>
  /** @deprecated Replaced by items */
  productGroups: Maybe<Array<ProductGroup>>
  productHandles: Maybe<Array<Scalars['String']['output']>>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type CollectionFiltersArgs = {
  filterKeys?: InputMaybe<Array<Scalars['String']['input']>>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput>>
}

export type CollectionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<CollectionItemFilterInput>>
  first?: InputMaybe<Scalars['Int']['input']>
  grouping?: InputMaybe<CollectionItemGrouping>
  last?: InputMaybe<Scalars['Int']['input']>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput>>
}

export type CollectionProductGroupArgs = {
  handle: Scalars['String']['input']
}

export type CollectionColorScope = 'ALL' | 'COLLECTION'

/** The connection type for Collection. */
export type CollectionConnection = {
  __typename?: 'CollectionConnection'
  /** A list of edges. */
  edges: Array<CollectionEdge>
  /** A list of nodes. */
  nodes: Array<Collection>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type CollectionEdge = {
  __typename?: 'CollectionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Collection>
}

export type CollectionFilter = {
  __typename?: 'CollectionFilter'
  crawlable: Scalars['Boolean']['output']
  displayName: Scalars['String']['output']
  displayType: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type CollectionFilterInput = {
  crawlable: Scalars['Boolean']['input']
  displayName: Scalars['String']['input']
  displayType: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type CollectionGridSection = {
  __typename?: 'CollectionGridSection'
  collection: Maybe<Collection>
  /** @deprecated use editorializedCollections field instead */
  collectionHandle: Scalars['String']['output']
  colorScope: Scalars['String']['output']
  /** @deprecated use editorializedCollections field instead */
  editorialCellPlacements: Array<EditorialCellPlacement>
  /** @deprecated use editorializedCollections field instead */
  editorialRows: Array<EditorialRow>
  editorializedCollections: Array<EditorializedCollection>
  filterConfig: Maybe<FilterConfig>
  filtersEnabled: Scalars['Boolean']['output']
  groupedProductTiles: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  itemColorScope: ProductGroupColorSelector
  itemGrouping: CollectionItemGrouping
  optimizedCollection: Maybe<Collection>
  /** @deprecated use editorializedCollections field instead */
  subtitle: Maybe<Scalars['String']['output']>
  /** @deprecated use editorializedCollections field instead */
  title: Maybe<Scalars['String']['output']>
  visualFiltersSection: Maybe<VisualFiltersSection>
  visualFiltersSectionV2: Maybe<VisualFiltersSection>
}

export type CollectionItemFilterInput = {
  key: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

export type CollectionItemGrouping =
  /** Item per product */
  | 'PRODUCT'
  /** Item per product group */
  | 'PRODUCT_GROUP'
  /** Derive from any present parent scope such as collection */
  | 'SCOPE'

export type CollectionSection = {
  __typename?: 'CollectionSection'
  collectionHandle: Scalars['String']['output']
  colorsForFilters: Maybe<Array<Color>>
  featuredImage: Maybe<StyledImage>
  /** @deprecated use featuredImage field instead */
  featuredImageDesktop: Maybe<Scalars['String']['output']>
  /** @deprecated use featuredImage field instead */
  featuredImageMobile: Maybe<Scalars['String']['output']>
  featuredImages: Maybe<Array<StyledImage>>
  featuredInlineVideoDesktop: Maybe<Scalars['String']['output']>
  featuredInlineVideoMobile: Maybe<Scalars['String']['output']>
  hideColors: Maybe<Scalars['Boolean']['output']>
  hidePrice: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  /** @deprecated use links field instead */
  linkHref: Maybe<Scalars['String']['output']>
  /** @deprecated use links field instead */
  linkText: Maybe<Scalars['String']['output']>
  links: Array<StyledLink>
  productCountLimit: Maybe<Scalars['Int']['output']>
  /** @deprecated use subtitleText field instead */
  subtitle: Maybe<Scalars['String']['output']>
  subtitleText: Maybe<StyledText>
  /** @deprecated use titleText field instead */
  title: Maybe<Scalars['String']['output']>
  titleAlignmentDesktop: Maybe<Scalars['String']['output']>
  titleAlignmentMobile: Maybe<Scalars['String']['output']>
  titleLockupImage: Maybe<StyledImage>
  titleText: Maybe<StyledText>
}

export type CollectionView = {
  __typename?: 'CollectionView'
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  noindex: Maybe<Scalars['Boolean']['output']>
  section: Maybe<Section>
  sections: Array<Section>
  seoContent: Maybe<Scalars['JSON']['output']>
  socialDescription: Maybe<Scalars['String']['output']>
  socialImage: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type CollectionViewSectionArgs = {
  id: Scalars['String']['input']
}

export type CollectionViewHeroSection = {
  __typename?: 'CollectionViewHeroSection'
  backgroundColor: Scalars['String']['output']
  ctaLink: Maybe<Scalars['String']['output']>
  ctaText: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  imagePlacement: Maybe<Scalars['String']['output']>
  inlineVideoDesktop: Maybe<Scalars['String']['output']>
  inlineVideoMobile: Maybe<Scalars['String']['output']>
  inlineVimeoIdDesktop: Maybe<Scalars['Int']['output']>
  inlineVimeoIdMobile: Maybe<Scalars['Int']['output']>
  name: Maybe<Scalars['String']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  textColor: Scalars['String']['output']
  title: Maybe<Scalars['String']['output']>
  videoId: Maybe<Scalars['String']['output']>
  videoPlatform: Maybe<Scalars['String']['output']>
}

export type Color = {
  __typename?: 'Color'
  availability: Scalars['String']['output']
  handle: Scalars['String']['output']
  hexCodes: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ColorGroup = {
  __typename?: 'ColorGroup'
  colors: Array<ProductColor>
  createdAt: Scalars['ISO8601DateTime']['output']
  label: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ColorLab = {
  __typename?: 'ColorLab'
  displayTitle: Scalars['String']['output']
  gender: Scalars['String']['output']
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  polls: Array<ColorLabPoll>
  ranges: Array<ColorLabRange>
}

export type ColorLabPollsArgs = {
  items: Scalars['Float']['input']
}

export type ColorLabCapsule = {
  __typename?: 'ColorLabCapsule'
  color: ColorLabColor
  displayTitle: Scalars['String']['output']
  drawCord: Scalars['String']['output']
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  pantsImageMobile: Scalars['String']['output']
  shoppable: Scalars['Boolean']['output']
  shoppableLink: Maybe<Scalars['String']['output']>
  timePeriod: Scalars['String']['output']
  topImageMobile: Scalars['String']['output']
}

export type ColorLabColor = {
  __typename?: 'ColorLabColor'
  accentColor: Maybe<Scalars['String']['output']>
  availability: Scalars['String']['output']
  handle: Scalars['String']['output']
  hexCodes: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  sortOrder: Maybe<Scalars['Float']['output']>
}

export type ColorLabPoll = {
  __typename?: 'ColorLabPoll'
  id: Scalars['ID']['output']
  options: Array<ColorLabCapsule>
  results: Array<ColorLabResult>
}

export type ColorLabPollResponseInput = {
  colorLabHandle: Scalars['String']['input']
  displayedOptionHandles: Array<Scalars['String']['input']>
  pollId: Scalars['String']['input']
  selectedOptionHandle: Scalars['String']['input']
  selectedOptionPosition: Scalars['Int']['input']
}

export type ColorLabRange = {
  __typename?: 'ColorLabRange'
  capsules: Array<ColorLabCapsule>
  displayTitle: Scalars['String']['output']
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  imageMobile: Scalars['String']['output']
  swatchImageMobile: Scalars['String']['output']
}

export type ColorLabResult = {
  __typename?: 'ColorLabResult'
  distribution: Scalars['Float']['output']
  optionHandle: Scalars['String']['output']
}

export type CreateColorLabPollResponseResult = {
  __typename?: 'CreateColorLabPollResponseResult'
  id: Scalars['String']['output']
}

export type CreateCustomerFavoriteListInput = {
  title: Scalars['String']['input']
}

export type CreateCustomerFavoriteListProductInput = {
  listHandle?: InputMaybe<Scalars['String']['input']>
  preference?: InputMaybe<CustomerFavoriteProductPreferenceInput>
  productHandle: Scalars['String']['input']
}

/** Autogenerated return type of CreateProductReview. */
export type CreateProductReviewPayload = {
  __typename?: 'CreateProductReviewPayload'
  code: Maybe<Scalars['Int']['output']>
  errors: Maybe<Array<UserError>>
  message: Maybe<Scalars['String']['output']>
}

/** Autogenerated return type of CreateShopUser. */
export type CreateShopUserPayload = {
  __typename?: 'CreateShopUserPayload'
  authToken: Maybe<Scalars['String']['output']>
  errors: Maybe<Array<UserError>>
  shopToken: Maybe<Scalars['String']['output']>
  shopUser: Maybe<ShopUser>
}

/** Autogenerated return type of CreateWaitlistEntry */
export type CreateWaitlistEntryPayload = {
  __typename?: 'CreateWaitlistEntryPayload'
  added: Scalars['Boolean']['output']
  previouslyAdded: Scalars['Boolean']['output']
  result: Scalars['Boolean']['output']
}

export type CropRegion = 'BOTTOM' | 'CENTER' | 'LEFT' | 'RIGHT' | 'TOP'

export type CurrencyLocale = {
  __typename?: 'CurrencyLocale'
  country: Maybe<Scalars['String']['output']>
  currency: Scalars['String']['output']
  locale: Scalars['String']['output']
}

export type CurrencyLocaleInput = {
  country?: InputMaybe<Scalars['String']['input']>
  currency: Scalars['String']['input']
  locale: Scalars['String']['input']
}

export type CustomComponentSection = {
  __typename?: 'CustomComponentSection'
  componentName: CustomComponentSectionName
  id: Scalars['ID']['output']
}

export type CustomComponentSectionName = 'STUDENT_DISCOUNT'

export type CustomField = {
  __typename?: 'CustomField'
  fieldType: Maybe<Scalars['String']['output']>
  formId: Maybe<Scalars['Int']['output']>
  key: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
  value: Maybe<Scalars['String']['output']>
}

export type Customer = {
  __typename?: 'Customer'
  addresses: AddressConnection
  defaultAddress: Maybe<Address>
  email: Scalars['String']['output']
  embroideryLogos: Maybe<Array<Maybe<EmbroideryLogoDtc>>>
  favorites: CustomerFavoriteList
  groups: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['ID']['output']
  order: Order
  orders: OrderConnection
  ordersStorefront: OrderStorefrontConnection
  portal: Maybe<PortalCg>
  profile: CustomerProfile
  shopifyId: Maybe<Scalars['String']['output']>
  sizeProfiles: Maybe<CustomerSizeProfiles>
}

export type CustomerOrderArgs = {
  id: Scalars['String']['input']
  paymentIconTransform?: InputMaybe<ImageTransformInput>
}

export type CustomerOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  paymentIconTransform?: InputMaybe<ImageTransformInput>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
}

export type CustomerOrdersStorefrontArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
}

export type CustomerPortalArgs = {
  preview?: Scalars['Boolean']['input']
}

export type CustomerAssociateCheckoutPayload = {
  __typename?: 'CustomerAssociateCheckoutPayload'
  checkoutId: Scalars['String']['output']
  customerId: Scalars['String']['output']
}

export type CustomerFavoriteContent = CustomerListItemContent & {
  __typename?: 'CustomerFavoriteContent'
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type CustomerFavoriteList = CustomerList & {
  __typename?: 'CustomerFavoriteList'
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  items: Array<CustomerFavoriteListItemType>
  title: Scalars['String']['output']
}

export type CustomerFavoriteListItemType = CustomerFavoriteContent | CustomerFavoriteProduct

export type CustomerFavoriteProduct = CustomerListItemProduct & {
  __typename?: 'CustomerFavoriteProduct'
  id: Scalars['ID']['output']
  preferences: Maybe<CustomerFavoriteProductPreferences>
  product: Maybe<Product>
  productHandle: Scalars['String']['output']
}

export type CustomerFavoriteProductPreferenceInput = {
  color?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['String']['input']>
}

export type CustomerFavoriteProductPreferences = {
  __typename?: 'CustomerFavoriteProductPreferences'
  color: Maybe<Scalars['String']['output']>
  fit: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  size: Maybe<Scalars['String']['output']>
}

export type CustomerInput = {
  email: Scalars['String']['input']
  emailOptIn: Scalars['Boolean']['input']
  externalId?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type CustomerList = {
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type CustomerListItemContent = {
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type CustomerListItemProduct = {
  id: Scalars['ID']['output']
  productHandle: Scalars['String']['output']
}

export type CustomerProfile = {
  __typename?: 'CustomerProfile'
  avatarUri: Maybe<Scalars['String']['output']>
  birthday: Maybe<Scalars['String']['output']>
  emailOptIn: Scalars['Boolean']['output']
  embroideryPreferences: CustomerProfileEmbroideryPreferences
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Maybe<Scalars['String']['output']>
  notificationPreferences: CustomerProfileNotificationPreferences
  occupation: Maybe<Scalars['String']['output']>
  ordersCount: Scalars['Int']['output']
  orgId: Maybe<Scalars['String']['output']>
  phone: Maybe<Scalars['String']['output']>
  /** @deprecated Use `sizePreferences` field instead */
  productPreferences: CustomerProfileProductPreferences
  sizePreferences: CustomerProfileSizePreferences
  stipend: Maybe<Scalars['Float']['output']>
  stylePreference: Maybe<CustomerProfileStylePreference>
  tags: Array<Scalars['String']['output']>
  uploadAvatarUrl: Scalars['String']['output']
}

export type CustomerProfileEmbroideryPreferences = {
  __typename?: 'CustomerProfileEmbroideryPreferences'
  text: CustomerProfileTextEmbroideryConfig
}

export type CustomerProfileNotificationPreference = {
  __typename?: 'CustomerProfileNotificationPreference'
  category: CustomerProfileNotificationPreferenceCategory
  displayText: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
}

export type CustomerProfileNotificationPreferenceCategory = 'ALL' | 'MARKETING' | 'ORDER' | 'STOCK'

export type CustomerProfileNotificationPreferenceSegment = 'PUSH'

export type CustomerProfileNotificationPreferences = {
  __typename?: 'CustomerProfileNotificationPreferences'
  push: Array<CustomerProfileNotificationPreference>
}

export type CustomerProfileProductPreferences = {
  __typename?: 'CustomerProfileProductPreferences'
  men: Maybe<CustomerProfileProductPreferencesConfig>
  women: Maybe<CustomerProfileProductPreferencesConfig>
}

export type CustomerProfileProductPreferencesConfig = {
  __typename?: 'CustomerProfileProductPreferencesConfig'
  id: Scalars['ID']['output']
  pantFit: Maybe<Scalars['String']['output']>
  pantLength: Maybe<Scalars['String']['output']>
  pantSize: Maybe<Scalars['String']['output']>
  segment: CustomerProfileProductPreferencesSegment
  shoeSize: Maybe<Scalars['String']['output']>
  topFit: Maybe<Scalars['String']['output']>
  topSize: Maybe<Scalars['String']['output']>
}

export type CustomerProfileProductPreferencesSegment = 'MEN' | 'WOMEN'

export type CustomerProfileSizePreferences = {
  __typename?: 'CustomerProfileSizePreferences'
  men: Maybe<CustomerProfileSizePreferencesConfig>
  women: Maybe<CustomerProfileSizePreferencesConfig>
}

export type CustomerProfileSizePreferencesConfig = {
  __typename?: 'CustomerProfileSizePreferencesConfig'
  gender: CustomerProfileProductPreferencesSegment
  id: Scalars['ID']['output']
  settings: Maybe<Array<SizePreferenceSetting>>
}

export type CustomerProfileStylePreference = 'BOTH' | 'MEN' | 'WOMEN'

export type CustomerProfileTextEmbroideryConfig = {
  __typename?: 'CustomerProfileTextEmbroideryConfig'
  color: Maybe<Scalars['String']['output']>
  firstLine: Maybe<Scalars['String']['output']>
  fontStyle: Maybe<Scalars['String']['output']>
  placement: Maybe<Scalars['String']['output']>
  secondLine: Maybe<Scalars['String']['output']>
}

export type CustomerSizeProfile = {
  __typename?: 'CustomerSizeProfile'
  age: Maybe<Scalars['Int']['output']>
  gender: Maybe<CustomerSizeProfileGender>
  height: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  recommendations: Maybe<Array<Maybe<CustomerSizeRecommendation>>>
  weight: Maybe<Scalars['Float']['output']>
}

export type CustomerSizeProfileGender = 'MEN' | 'WOMEN'

export type CustomerSizeProfiles = {
  __typename?: 'CustomerSizeProfiles'
  men: Maybe<CustomerSizeProfile>
  women: Maybe<CustomerSizeProfile>
}

export type CustomerSizeRecommendation = {
  __typename?: 'CustomerSizeRecommendation'
  category: Maybe<Scalars['String']['output']>
  fit: Maybe<Scalars['String']['output']>
  size: Maybe<Scalars['String']['output']>
}

export type DefaultSearchFilter = {
  __typename?: 'DefaultSearchFilter'
  key: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type DeleteCustomerAddressResult = {
  __typename?: 'DeleteCustomerAddressResult'
  deletedCustomerAddressId: Scalars['String']['output']
}

export type Discount = {
  __typename?: 'Discount'
  categories: Maybe<Array<Scalars['String']['output']>>
  colors: Maybe<Array<Scalars['String']['output']>>
  createdAt: Scalars['ISO8601DateTime']['output']
  effectiveAt: Maybe<Scalars['ISO8601DateTime']['output']>
  expiresAt: Maybe<Scalars['ISO8601DateTime']['output']>
  global: Scalars['Boolean']['output']
  globalTeam: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
  organizations: Maybe<Array<Organization>>
  percentage: Maybe<Scalars['Int']['output']>
  price: Maybe<Scalars['Cents']['output']>
  skus: Maybe<Array<Scalars['String']['output']>>
  styles: Maybe<Array<Scalars['String']['output']>>
  teams: Maybe<Array<Team>>
  updatedAt: Scalars['ISO8601DateTime']['output']
  users: Maybe<Array<User>>
}

/** The connection type for Discount. */
export type DiscountConnection = {
  __typename?: 'DiscountConnection'
  /** A list of edges. */
  edges: Array<DiscountEdge>
  /** A list of nodes. */
  nodes: Array<Discount>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type DiscountEdge = {
  __typename?: 'DiscountEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Discount>
}

export type DiscountTier = {
  __typename?: 'DiscountTier'
  createdAt: Scalars['ISO8601DateTime']['output']
  minUnits: Scalars['Int']['output']
  percentage: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type DynamicCollection = FilteredProducts & {
  __typename?: 'DynamicCollection'
  colors: Maybe<Array<ProductColor>>
  filters: Maybe<Array<ProductFilter>>
  handles: Array<Maybe<Scalars['String']['output']>>
  items: Maybe<ProductConnection>
  skus: Array<Maybe<Scalars['String']['output']>>
}

export type DynamicCollectionFiltersArgs = {
  filterKeys?: InputMaybe<Array<Scalars['String']['input']>>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput>>
}

export type DynamicCollectionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<CollectionItemFilterInput>>
  first?: InputMaybe<Scalars['Int']['input']>
  grouping?: InputMaybe<CollectionItemGrouping>
  last?: InputMaybe<Scalars['Int']['input']>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput>>
}

export type EditorialCell = {
  __typename?: 'EditorialCell'
  backgroundColor: Maybe<Scalars['String']['output']>
  buttonVariantDesktop: Maybe<Scalars['String']['output']>
  buttonVariantMobile: Maybe<Scalars['String']['output']>
  ctaAlignment: Maybe<EditorialCellCtaAlignment>
  ctaDisplay: Maybe<EditorialCellCtaDisplay>
  ctaText: Maybe<Scalars['String']['output']>
  description: Maybe<Scalars['String']['output']>
  descriptionAlignment: Maybe<EditorialCellDescriptionAlignment>
  descriptionByLine: Maybe<Scalars['String']['output']>
  descriptionColor: Maybe<Scalars['String']['output']>
  descriptionPadding: Maybe<Scalars['Float']['output']>
  descriptionPlacement: Maybe<Scalars['String']['output']>
  descriptionSize: Maybe<Scalars['String']['output']>
  enableFullBleedBackground: Scalars['Boolean']['output']
  enableGradientBackground: Scalars['Boolean']['output']
  eyebrow: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  imageAspectRatio: Maybe<Scalars['Float']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  imageMobileAspectRatio: Maybe<Scalars['Float']['output']>
  inlineVideo: Maybe<Scalars['String']['output']>
  inlineVimeoId: Maybe<Scalars['Int']['output']>
  inlineVimeoIdMobile: Maybe<Scalars['Int']['output']>
  linkHref: Maybe<Scalars['String']['output']>
  modalVideoId: Maybe<Scalars['String']['output']>
  modalVideoIdMobile: Maybe<Scalars['String']['output']>
  modalVideoPlatform: Maybe<PageSectionModalVideoPlatform>
  platformAvailability: EditorialCellPlatformAvailability
  reviewStars: Maybe<Scalars['Boolean']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  textAlignment: Maybe<EditorialCellTextAlignment>
  textColor: Scalars['String']['output']
  title: Maybe<Scalars['String']['output']>
  titleAlignment: Maybe<PageSectionTitleAlignment>
  titleSize: Maybe<Scalars['String']['output']>
}

export type EditorialCellCtaAlignment = 'center' | 'left'

export type EditorialCellCtaDisplay = 'button' | 'textAndUnderline'

export type EditorialCellDescriptionAlignment = 'center' | 'left' | 'right'

export type EditorialCellPlacement = {
  __typename?: 'EditorialCellPlacement'
  alignmentDesktop: Maybe<Scalars['String']['output']>
  cellWidth: Maybe<Scalars['Int']['output']>
  editorialCell: Maybe<EditorialCell>
  id: Scalars['ID']['output']
  pinToBottom: Maybe<Scalars['String']['output']>
  rowsToSkipDesktop: Maybe<Scalars['Int']['output']>
  rowsToSkipMobile: Maybe<Scalars['Int']['output']>
}

export type EditorialCellPlatformAvailability = 'app' | 'both' | 'web'

export type EditorialCellTextAlignment = 'center' | 'left' | 'right'

export type EditorialRow = VideoSection

export type EditorializedCollection = {
  __typename?: 'EditorializedCollection'
  editorialCellPlacements: Array<EditorialCellPlacement>
  editorialRows: Array<EditorialRow>
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  subtitle: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
}

export type Embroidery = {
  __typename?: 'Embroidery'
  iconPlacements: Maybe<Array<EmbroideryPlacementDetails>>
  initialsColors: Maybe<Array<Scalars['String']['output']>>
  initialsFonts: Maybe<Array<EmbroideryFontInfo>>
  initialsMaxChars: Maybe<Scalars['Int']['output']>
  kind: EmbroideryTypeEnum
  logoPlacements: Maybe<Array<EmbroideryPlacementDetails>>
  product: Maybe<Product>
  textColors: Maybe<Array<Scalars['String']['output']>>
  textFonts: Maybe<Array<EmbroideryFontInfo>>
  textLines: Maybe<Scalars['Int']['output']>
  textMaxChars: Maybe<Scalars['Int']['output']>
  textPlacements: Maybe<Array<EmbroideryPlacementDetails>>
  /** @deprecated Variants should be accessed via products */
  variants: Maybe<Array<Variant>>
}

export type EmbroideryConstraint = {
  __typename?: 'EmbroideryConstraint'
  createdAt: Scalars['ISO8601DateTime']['output']
  embroideryOption: EmbroideryOption
  fontLocked: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  isOptional: Maybe<Scalars['Boolean']['output']>
  line1Locked: Maybe<Scalars['Boolean']['output']>
  line2Locked: Maybe<Scalars['Boolean']['output']>
  logoPlacementLocked: Maybe<Scalars['Boolean']['output']>
  productGroup: ProductGroup
  textPlacementLocked: Maybe<Scalars['Boolean']['output']>
  threadColorLocked: Maybe<Scalars['Boolean']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for EmbroideryConstraint. */
export type EmbroideryConstraintConnection = {
  __typename?: 'EmbroideryConstraintConnection'
  /** A list of edges. */
  edges: Array<EmbroideryConstraintEdge>
  /** A list of nodes. */
  nodes: Array<EmbroideryConstraint>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EmbroideryConstraintEdge = {
  __typename?: 'EmbroideryConstraintEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<EmbroideryConstraint>
}

export type EmbroideryFontInfo = {
  __typename?: 'EmbroideryFontInfo'
  name: Scalars['String']['output']
  scale: Maybe<Scalars['Float']['output']>
}

export type EmbroideryIconGroup = {
  __typename?: 'EmbroideryIconGroup'
  disabledInitialsText: Maybe<Scalars['String']['output']>
  displayName: Scalars['String']['output']
  id: Scalars['ID']['output']
  maxIconsPerRowMobile: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  sortOrder: Maybe<Scalars['Float']['output']>
}

export type EmbroideryLogo = {
  __typename?: 'EmbroideryLogo'
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  approvedBy: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  documentUrl: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  notes: Maybe<Scalars['String']['output']>
  previewUrl: Maybe<Scalars['String']['output']>
  setupFeePaid: Maybe<Scalars['Boolean']['output']>
  stitchUrl: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for EmbroideryLogo. */
export type EmbroideryLogoConnection = {
  __typename?: 'EmbroideryLogoConnection'
  /** A list of edges. */
  edges: Array<EmbroideryLogoEdge>
  /** A list of nodes. */
  nodes: Array<EmbroideryLogo>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/**  DTC custom logo embroidery */
export type EmbroideryLogoDtc = {
  __typename?: 'EmbroideryLogoDTC'
  digitizationSetting: LogoDigitizationSetting
  id: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
  notes: Maybe<Scalars['String']['output']>
  preview: Scalars['String']['output']
  shopifyId: Scalars['String']['output']
  status: LogoStatus
}

/** An edge in a connection. */
export type EmbroideryLogoEdge = {
  __typename?: 'EmbroideryLogoEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<EmbroideryLogo>
}

export type EmbroideryOption = {
  __typename?: 'EmbroideryOption'
  createdAt: Scalars['ISO8601DateTime']['output']
  font: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  line1: Maybe<Scalars['String']['output']>
  line2: Maybe<Scalars['String']['output']>
  logo: Maybe<EmbroideryLogo>
  logoPlacement: Maybe<Scalars['String']['output']>
  textPlacement: Maybe<Scalars['String']['output']>
  threadColor: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type EmbroideryOptions = {
  __typename?: 'EmbroideryOptions'
  createdAt: Scalars['ISO8601DateTime']['output']
  font: Array<Scalars['String']['output']>
  logoPlacement: Maybe<Array<Scalars['String']['output']>>
  textPlacement: Array<Scalars['String']['output']>
  threadColor: Array<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type EmbroideryPlacementDetails = {
  __typename?: 'EmbroideryPlacementDetails'
  flatlayKind: Scalars['String']['output']
  name: Scalars['String']['output']
  offsetKey: Maybe<Scalars['String']['output']>
}

export type EmbroideryTypeEnum =
  | 'ICON_ONLY'
  | 'ICON_TEXT'
  | 'LOGO_BLACK_WHITE'
  | 'LOGO_BLACK_WHITE_TEXT'
  | 'LOGO_COLOR'
  | 'LOGO_COLOR_TEXT'
  | 'LOGO_ONLY'
  | 'LOGO_SETUP'
  | 'LOGO_TEXT'
  | 'TEXT_ONLY'

export type EnumeratedValue = {
  __typename?: 'EnumeratedValue'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Enumeration = {
  __typename?: 'Enumeration'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type ErrorDetail =
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  | 'DEADLINE_EXCEEDED'
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  | 'ENHANCE_YOUR_CALM'
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  | 'FIELD_NOT_FOUND'
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  | 'INVALID_ARGUMENT'
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  | 'INVALID_CURSOR'
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  | 'MISSING_RESOURCE'
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  | 'SERVICE_ERROR'
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  | 'TCP_FAILURE'
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  | 'THROTTLED_CONCURRENCY'
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  | 'THROTTLED_CPU'
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  | 'UNIMPLEMENTED'
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  | 'UNKNOWN'

export type ErrorType =
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  | 'BAD_REQUEST'
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  | 'FAILED_PRECONDITION'
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  | 'INTERNAL'
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  | 'NOT_FOUND'
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  | 'PERMISSION_DENIED'
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  | 'UNAUTHENTICATED'
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  | 'UNAVAILABLE'
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  | 'UNKNOWN'

export type FigsRepAdmin = {
  __typename?: 'FigsRepAdmin'
  concierge: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  eligibleForDiscount: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  emailOptIn: Scalars['Boolean']['output']
  firstName: Scalars['String']['output']
  hasOrders: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  initials: Maybe<Scalars['String']['output']>
  jobCategory: Maybe<Scalars['String']['output']>
  lastLoginAt: Maybe<Scalars['ISO8601DateTime']['output']>
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  phone: Maybe<Scalars['String']['output']>
  properties: Maybe<Array<Property>>
  resetPasswordSentAt: Maybe<Scalars['ISO8601DateTime']['output']>
  teams: Maybe<Array<Team>>
  type: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for FigsRepAdmin. */
export type FigsRepAdminConnection = {
  __typename?: 'FigsRepAdminConnection'
  /** A list of edges. */
  edges: Array<FigsRepAdminEdge>
  /** A list of nodes. */
  nodes: Array<FigsRepAdmin>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type FigsRepAdminEdge = {
  __typename?: 'FigsRepAdminEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<FigsRepAdmin>
}

export type FilterConfig = {
  __typename?: 'FilterConfig'
  filters: Array<CollectionFilter>
}

export type FilterConfigInput = {
  filters: Array<CollectionFilterInput>
}

export type FilterCustomField = {
  answers: Array<Scalars['String']['input']>
  questionId: Scalars['Int']['input']
}

export type FilterInput = {
  crfs?: InputMaybe<Array<InputMaybe<FilterCustomField>>>
  freeTextSearch?: InputMaybe<Scalars['String']['input']>
  pictured?: InputMaybe<Scalars['Boolean']['input']>
  productId: Scalars['String']['input']
  scores?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type FilterValue = {
  __typename?: 'FilterValue'
  state: FilterValueState
  value: Scalars['String']['output']
}

export type FilterValueState = 'DISABLED' | 'ENABLED' | 'SELECTED'

export type FilteredProducts = {
  colors: Maybe<Array<ProductColor>>
  filters: Maybe<Array<ProductFilter>>
  items: Maybe<ProductConnection>
}

export type FilteredProductsFiltersArgs = {
  filterKeys?: InputMaybe<Array<Scalars['String']['input']>>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput>>
}

export type FilteredProductsItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<CollectionItemFilterInput>>
  first?: InputMaybe<Scalars['Int']['input']>
  grouping?: InputMaybe<CollectionItemGrouping>
  last?: InputMaybe<Scalars['Int']['input']>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput>>
}

export type FilteredReviewsResponse = {
  __typename?: 'FilteredReviewsResponse'
  pagination: Maybe<ProductReviewsPageInfo>
  reviews: Maybe<Array<Maybe<Review>>>
  tag: Maybe<Scalars['String']['output']>
}

export type FitModel = {
  __typename?: 'FitModel'
  description: Maybe<Scalars['String']['output']>
  height: Maybe<Scalars['String']['output']>
  imageSets: Maybe<Scalars['JSON']['output']>
  images: Maybe<Array<Scalars['String']['output']>>
  name: Maybe<Scalars['String']['output']>
  pantsSize: Maybe<Scalars['String']['output']>
  topSize: Maybe<Scalars['String']['output']>
}

/** The connection type for FitModel. */
export type FitModelConnection = {
  __typename?: 'FitModelConnection'
  /** A list of edges. */
  edges: Array<FitModelEdge>
  /** A list of nodes. */
  nodes: Array<FitModel>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type FitModelEdge = {
  __typename?: 'FitModelEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<FitModel>
}

export type FitQuiz = {
  __typename?: 'FitQuiz'
  categories: Array<FitQuizCategoryEnum>
  exitLink: Maybe<StyledLink>
  handle: Scalars['String']['output']
  id: Maybe<Scalars['ID']['output']>
  introText: Maybe<StyledText>
  recommendedFitText: Maybe<StyledText>
}

export type FitQuizCategoryEnum =
  | 'Accessories'
  | 'Embroidery'
  | 'Gift'
  | 'GiftCard'
  | 'Hat'
  | 'Jumpsuits'
  | 'LabCoats'
  | 'Leggings'
  | 'Loungewear'
  | 'Mask'
  | 'Outerwear'
  | 'Sample'
  | 'ScrubCap'
  | 'ScrubJacket'
  | 'ScrubPants'
  | 'ScrubTop'
  | 'Shoes'
  | 'Socks'
  | 'SportsBra'
  | 'StuffWeLike'
  | 'Underscrub'

/** Autogenerated return type of ForgotPassword. */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload'
  authToken: Maybe<Scalars['String']['output']>
  errors: Maybe<Array<UserError>>
  shopToken: Maybe<Scalars['String']['output']>
  shopUser: Maybe<ShopUser>
  user: Maybe<User>
}

export type FormattedPrice = {
  __typename?: 'FormattedPrice'
  explicitFmt: Scalars['String']['output']
  shortFmt: Scalars['String']['output']
}

export type FormattedPrices = {
  __typename?: 'FormattedPrices'
  currencyLocale: CurrencyLocale
  prices: Array<FormattedPrice>
}

export type Fulfillment = {
  __typename?: 'Fulfillment'
  createdAt: Scalars['ISO8601DateTime']['output']
  deliveredAt: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  shopifyId: Scalars['String']['output']
  status: Maybe<Scalars['String']['output']>
  trackingIds: Maybe<Array<Scalars['String']['output']>>
  trackingUrls: Maybe<Array<Scalars['String']['output']>>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type GenderNavSection = {
  __typename?: 'GenderNavSection'
  alternateGenderHandle: Scalars['String']['output']
  id: Scalars['ID']['output']
  selectedGender: Scalars['String']['output']
}

export type GiftItemCollection = {
  __typename?: 'GiftItemCollection'
  addToCartCTA: Maybe<Scalars['String']['output']>
  callToAction: Scalars['String']['output']
  changeSelectionCTA: Maybe<Scalars['String']['output']>
  collectionHandleMens: Scalars['String']['output']
  collectionHandleWomens: Scalars['String']['output']
  collectionMens: Collection
  collectionWomens: Collection
  embroiderySku: Maybe<Scalars['String']['output']>
  header: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logoFileName: Maybe<Scalars['String']['output']>
  logoImage: Maybe<Scalars['String']['output']>
  logoName: Maybe<Scalars['String']['output']>
  logoPlacement: Maybe<Scalars['String']['output']>
  thumbnail: Maybe<Scalars['String']['output']>
}

export type GiftPackage = {
  __typename?: 'GiftPackage'
  codePrefix: Scalars['String']['output']
  companyLogo: Maybe<Scalars['String']['output']>
  companyName: Scalars['String']['output']
  errorMessage: Maybe<Scalars['String']['output']>
  giftPackageOptions: Array<GiftPackageOption>
  id: Scalars['ID']['output']
  slug: Scalars['String']['output']
  styleImageMens: Maybe<Scalars['String']['output']>
  styleImageWomens: Maybe<Scalars['String']['output']>
  verificationCopy: Maybe<Scalars['JSON']['output']>
}

export type GiftPackageOption = {
  __typename?: 'GiftPackageOption'
  giftItemCollections: Array<GiftItemCollection>
  id: Scalars['ID']['output']
  promoImageMens: Maybe<Scalars['String']['output']>
  promoImageWomens: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type GiftingVerifyDiscountCodeResponse = {
  __typename?: 'GiftingVerifyDiscountCodeResponse'
  valid: Scalars['Boolean']['output']
}

export type GroupCart = {
  __typename?: 'GroupCart'
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  billingAddress: Maybe<BillingAddress>
  cartItems: Maybe<Array<CartItem>>
  categoryConstraints: Maybe<Array<CartCategoryConstraint>>
  closesAt: Maybe<Scalars['ISO8601DateTime']['output']>
  collection: Collection
  createdAt: Scalars['ISO8601DateTime']['output']
  csvExport: Maybe<Scalars['String']['output']>
  /** The maximum discount percentage of all cart items */
  currentDiscount: Maybe<Scalars['Int']['output']>
  embroideryStatus: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitations: Maybe<CartInvitationConnection>
  inviteToken: Scalars['String']['output']
  locked: Scalars['Boolean']['output']
  memberSpendLimit: Maybe<Scalars['Int']['output']>
  missingResponders: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  numInvitations: Scalars['Int']['output']
  numItems: Scalars['Int']['output']
  numParticipants: Scalars['Int']['output']
  order: Maybe<TeamOrder>
  participants: Maybe<Array<User>>
  responseSummary: Maybe<Scalars['String']['output']>
  shippingAddress: Maybe<ShippingAddress>
  status: Scalars['String']['output']
  total: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  user: User
}

export type GroupCartInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type GuestNavigationPageHeroSection = {
  __typename?: 'GuestNavigationPageHeroSection'
  id: Scalars['ID']['output']
  leftImage: Maybe<Scalars['String']['output']>
  leftLinkHref: Maybe<Scalars['String']['output']>
  leftLinkText: Maybe<Scalars['String']['output']>
  leftTextColor: Maybe<Scalars['String']['output']>
  rightImage: Maybe<Scalars['String']['output']>
  rightLinkHref: Maybe<Scalars['String']['output']>
  rightLinkText: Maybe<Scalars['String']['output']>
  rightTextColor: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
}

export type HeroFullBleedSection = {
  __typename?: 'HeroFullBleedSection'
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  inlineVideoDesktop: Maybe<Scalars['String']['output']>
  inlineVideoMobile: Maybe<Scalars['String']['output']>
  inlineVimeoIdDesktop: Maybe<Scalars['Int']['output']>
  inlineVimeoIdMobile: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  textColor: Scalars['String']['output']
  title: Maybe<Scalars['String']['output']>
  titleImage: Maybe<Scalars['String']['output']>
  titleImageMobile: Maybe<Scalars['String']['output']>
}

export type Hotspot = {
  __typename?: 'Hotspot'
  linkHref1: Scalars['String']['output']
}

export type ImageContentType = 'JPG' | 'PNG' | 'WEBP'

export type ImageTransformInput = {
  crop?: InputMaybe<CropRegion>
  maxHeight?: InputMaybe<Scalars['Int']['input']>
  maxWidth?: InputMaybe<Scalars['Int']['input']>
  preferredContentType?: InputMaybe<ImageContentType>
  scale?: InputMaybe<Scalars['Int']['input']>
}

export type InteractiveSlide = {
  __typename?: 'InteractiveSlide'
  altText: Maybe<Scalars['String']['output']>
  aspectRatioDesktop: Scalars['Float']['output']
  aspectRatioMobile: Scalars['Float']['output']
  contentColorDesktop: Scalars['String']['output']
  contentColorMobile: Scalars['String']['output']
  ctaLink: Scalars['String']['output']
  ctaText: Scalars['String']['output']
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  overlayOpacity: Scalars['Float']['output']
  title: Maybe<Scalars['String']['output']>
}

export type Kit = {
  __typename?: 'Kit'
  accordion: Maybe<AccordionView>
  collection: Collection
  defaultColors: Maybe<Scalars['String']['output']>
  groupItemsByColor: Scalars['Boolean']['output']
  handle: Scalars['String']['output']
  hideProductDetails: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  sections: Array<KitSection>
  shopTheLook: Array<Product>
  testimonials: Array<Testimonial>
  testimonialsSubtitle: Maybe<Scalars['String']['output']>
  testimonialsTitle: Maybe<Scalars['String']['output']>
}

export type KitSection = PageBannerSection | PageHeroSection | PageTileSection | RawHtmlSection

export type Link = {
  __typename?: 'Link'
  href: Scalars['String']['output']
  text: Scalars['String']['output']
}

/** The connection type for Link. */
export type LinkConnection = {
  __typename?: 'LinkConnection'
  /** A list of edges. */
  edges: Array<LinkEdge>
  /** A list of nodes. */
  nodes: Array<Link>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type LinkEdge = {
  __typename?: 'LinkEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Link>
}

export type Locale = {
  __typename?: 'Locale'
  language: Scalars['String']['output']
  locale: Scalars['String']['output']
}

export type Locales = {
  __typename?: 'Locales'
  locales: Array<Locale>
  name: Scalars['String']['output']
}

/** Autogenerated return type of LoginShopUser. */
export type LoginShopUserPayload = {
  __typename?: 'LoginShopUserPayload'
  authToken: Maybe<Scalars['String']['output']>
  errors: Maybe<Array<UserError>>
  shopToken: Maybe<Scalars['String']['output']>
  shopUser: Maybe<ShopUser>
}

export type LogoDigitizationSetting = 'BLACK_WHITE' | 'COLOR'

export type LogoScopeEnum = 'DTC' | 'PORTALS'

export type LogoStatus = 'APPROVED' | 'PENDING'

export type MeasurementUnitSystem = 'IMPERIAL' | 'METRIC'

/** Autogenerated return type of ModifyOrganization. */
export type ModifyOrganizationPayload = {
  __typename?: 'ModifyOrganizationPayload'
  errors: Maybe<Array<UserError>>
  organization: Maybe<Organization>
}

export type Money = {
  __typename?: 'Money'
  amount: Maybe<Scalars['Cents']['output']>
  /** The 3 character ISO-4217 currency code */
  currency: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  activateCustomer: AuthInfo
  /** Activates a Shopify user account */
  activateShopUser: Maybe<ActivateShopUserPayload>
  addUserProperties: Maybe<AddUserPropertiesPayload>
  /** Associates a shop user with their checkout */
  associateCheckout: Maybe<AssociateCheckoutPayload>
  createColorLabPollResponse: CreateColorLabPollResponseResult
  createCustomer: AuthInfo
  createCustomerAddress: Address
  createCustomerFavoriteList: CustomerFavoriteList
  createCustomerFavoriteListProduct: CustomerFavoriteProduct
  createIdmeShopifyDiscountCode: Scalars['String']['output']
  /** Post a product review to Yotpo */
  createProductReview: Maybe<CreateProductReviewPayload>
  createReview: Maybe<Scalars['Boolean']['output']>
  /** Creates a Shopify user account */
  createShopUser: Maybe<CreateShopUserPayload>
  createWaitlistEntry: CreateWaitlistEntryPayload
  customerAssociateCheckout: CustomerAssociateCheckoutPayload
  customerChangePassword: Scalars['Boolean']['output']
  customerForgotPassword: Scalars['Boolean']['output']
  customerResetPassword: Scalars['Boolean']['output']
  customerSendMagicLink: Scalars['Boolean']['output']
  customerTokenRefresh: AuthInfo
  deleteCustomerAddress: DeleteCustomerAddressResult
  /** Send an email to the user with a link to reset their password */
  forgotPassword: Maybe<ForgotPasswordPayload>
  initateGuestOrderCancellation: Scalars['String']['output']
  initateOrderCancellation: Scalars['String']['output']
  loginCustomer: AuthInfo
  loginCustomerMagicLink: AuthInfo
  /** Authenticates a Shopify user account */
  loginShopUser: Maybe<LoginShopUserPayload>
  /** Modify organization info */
  modifyOrganization: Maybe<ModifyOrganizationPayload>
  removeCustomerFavoriteListProduct: CustomerFavoriteList
  resetPassword: Maybe<ResetPasswordPayload>
  setDefaultCustomerAddress: Address
  submitCustomerSizeProfile: Maybe<CustomerSizeProfile>
  /** @deprecated use subscribeCustomer instead */
  subscribeUser: Maybe<SubscribeUserPayload>
  updateCustomerAddress: Address
  updateCustomerProfile: CustomerProfile
  updateCustomerProfileEmbroideryPreferences: CustomerProfile
  updateCustomerProfileNotificationPreference: CustomerProfile
  updateCustomerProfileProductPreferences: CustomerProfile
  updateCustomerProfileSizePreferences: CustomerProfile
  verifyDiscountCode: GiftingVerifyDiscountCodeResponse
}

export type MutationActivateCustomerArgs = {
  activationUrl: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationActivateShopUserArgs = {
  activationUrl: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationAddUserPropertiesArgs = {
  props: Array<PropertyInput>
  userId: Scalars['ID']['input']
}

export type MutationAssociateCheckoutArgs = {
  checkoutId: Scalars['ID']['input']
}

export type MutationCreateColorLabPollResponseArgs = {
  response: ColorLabPollResponseInput
}

export type MutationCreateCustomerArgs = {
  input: CustomerInput
}

export type MutationCreateCustomerAddressArgs = {
  addressData: AddressInput
}

export type MutationCreateCustomerFavoriteListArgs = {
  list: CreateCustomerFavoriteListInput
}

export type MutationCreateCustomerFavoriteListProductArgs = {
  item: CreateCustomerFavoriteListProductInput
}

export type MutationCreateIdmeShopifyDiscountCodeArgs = {
  authCode: Scalars['String']['input']
  source: Scalars['String']['input']
}

export type MutationCreateProductReviewArgs = {
  content: Scalars['String']['input']
  domain: Scalars['String']['input']
  email: Scalars['String']['input']
  handle: Scalars['String']['input']
  name: Scalars['String']['input']
  path: Scalars['String']['input']
  rating: Scalars['Int']['input']
  title: Scalars['String']['input']
}

export type MutationCreateReviewArgs = {
  review: ReviewInput
}

export type MutationCreateShopUserArgs = {
  input: ShopUserInput
  properties?: InputMaybe<Array<PropertyInput>>
}

export type MutationCreateWaitlistEntryArgs = {
  email: Scalars['String']['input']
  variantId: Scalars['String']['input']
}

export type MutationCustomerAssociateCheckoutArgs = {
  checkoutId: Scalars['String']['input']
}

export type MutationCustomerChangePasswordArgs = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type MutationCustomerForgotPasswordArgs = {
  email: Scalars['String']['input']
}

export type MutationCustomerResetPasswordArgs = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationCustomerSendMagicLinkArgs = {
  email: Scalars['String']['input']
  platform?: InputMaybe<Scalars['String']['input']>
}

export type MutationCustomerTokenRefreshArgs = {
  refreshToken: Scalars['String']['input']
}

export type MutationDeleteCustomerAddressArgs = {
  shopifyId: Scalars['String']['input']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input']
  resetUrl: Scalars['String']['input']
}

export type MutationInitateGuestOrderCancellationArgs = {
  email: Scalars['String']['input']
  id: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationInitateOrderCancellationArgs = {
  id: Scalars['String']['input']
}

export type MutationLoginCustomerArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationLoginCustomerMagicLinkArgs = {
  token: Scalars['String']['input']
}

export type MutationLoginShopUserArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationModifyOrganizationArgs = {
  hubspotId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type MutationRemoveCustomerFavoriteListProductArgs = {
  item: RemoveCustomerFavoriteListProductInput
}

export type MutationResetPasswordArgs = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationSetDefaultCustomerAddressArgs = {
  shopifyId: Scalars['String']['input']
}

export type MutationSubmitCustomerSizeProfileArgs = {
  input: SubmitCustomerSizeProfileInput
}

export type MutationSubscribeUserArgs = {
  email: Scalars['String']['input']
  gender?: InputMaybe<Scalars['String']['input']>
  registrationSource?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateCustomerAddressArgs = {
  addressData: AddressInput
  shopifyId: Scalars['String']['input']
}

export type MutationUpdateCustomerProfileArgs = {
  updateCustomerProfileData: UpdateCustomerProfileInput
}

export type MutationUpdateCustomerProfileEmbroideryPreferencesArgs = {
  updateEmbroideryPreferencesData: UpdateCustomerProfileEmbroideryPreferencesInput
}

export type MutationUpdateCustomerProfileNotificationPreferenceArgs = {
  category: CustomerProfileNotificationPreferenceCategory
  enabled: Scalars['Boolean']['input']
  segment: CustomerProfileNotificationPreferenceSegment
}

export type MutationUpdateCustomerProfileProductPreferencesArgs = {
  segment: CustomerProfileProductPreferencesSegment
  updateProductPreferencesData: UpdateCustomerProfileProductPreferencesInput
}

export type MutationUpdateCustomerProfileSizePreferencesArgs = {
  gender: CustomerProfileProductPreferencesSegment
  settings: Array<UpdateSizePreferenceSettingInput>
}

export type MutationVerifyDiscountCodeArgs = {
  code: Scalars['String']['input']
}

export type NavAccordion = {
  __typename?: 'NavAccordion'
  id: Scalars['ID']['output']
  sections: Array<NavAccordionSection>
  variant: Scalars['String']['output']
}

export type NavAccordionSection = {
  __typename?: 'NavAccordionSection'
  alternateModelVariant: Maybe<AlternateModelVariant>
  backgroundImageApp: Maybe<Scalars['String']['output']>
  body: Maybe<NavSection>
  header: NavListItem
  id: Scalars['ID']['output']
}

export type NavListGroupSection = {
  __typename?: 'NavListGroupSection'
  id: Scalars['ID']['output']
  navSections: Array<NavListSection>
}

export type NavListItem = {
  __typename?: 'NavListItem'
  alternateModelVariant: Maybe<AlternateModelVariant>
  backgroundImage: Maybe<Scalars['String']['output']>
  backgroundImageApp: Maybe<Scalars['String']['output']>
  badge: Maybe<Scalars['String']['output']>
  badgeColor: Maybe<Scalars['String']['output']>
  badgeTextColor: Maybe<Scalars['String']['output']>
  href: Maybe<Scalars['String']['output']>
  htmlClass: Maybe<Scalars['String']['output']>
  htmlId: Maybe<Scalars['String']['output']>
  icon: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isBold: Maybe<Scalars['Boolean']['output']>
  isBtn: Maybe<Scalars['Boolean']['output']>
  isHeading: Maybe<Scalars['Boolean']['output']>
  text: Scalars['String']['output']
  textColor: Maybe<Scalars['String']['output']>
}

export type NavListSection = {
  __typename?: 'NavListSection'
  id: Scalars['ID']['output']
  items: Array<NavListSectionItem>
  variant: Scalars['String']['output']
}

export type NavListSectionItem = AppNavListItem | NavListItem

export type NavSection =
  | NavAccordion
  | NavListGroupSection
  | NavListSection
  | NavTileSection
  | RawHtmlSection

export type NavTab = {
  __typename?: 'NavTab'
  alternateModelVariant: Maybe<AlternateModelVariant>
  href: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  sections: Array<NavSection>
  text: Scalars['String']['output']
  textColor: Maybe<Scalars['String']['output']>
}

export type NavTile = {
  __typename?: 'NavTile'
  ctaText: Scalars['String']['output']
  href: Scalars['String']['output']
  id: Scalars['ID']['output']
  image: Scalars['String']['output']
}

export type NavTileSection = {
  __typename?: 'NavTileSection'
  id: Scalars['ID']['output']
  tiles: Array<NavTile>
}

export type Order = {
  __typename?: 'Order'
  billingAddress: Maybe<Address>
  cancelReason: Maybe<OrderCancelReason>
  cancellable: Scalars['Boolean']['output']
  cancelledAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  customAttributes: Array<Attribute>
  discountCode: Scalars['String']['output']
  displayFinancialStatus: Scalars['String']['output']
  displayFulfillmentStatus: Scalars['String']['output']
  email: Scalars['String']['output']
  fulfillmentOrderStatus: Scalars['String']['output']
  fulfillments: Array<OrderFulfillment>
  id: Scalars['String']['output']
  lineItems: OrderLineItemConnection
  name: Scalars['String']['output']
  netPayment: Scalars['Float']['output']
  originalTotal: Scalars['Float']['output']
  paymentGatewayNames: Array<Scalars['String']['output']>
  paymentMethod: Maybe<PaymentMethod>
  refunds: Array<OrderRefund>
  shippingAddress: Maybe<Address>
  status: OrderStatus
  subtotal: Scalars['Float']['output']
  total: Scalars['Float']['output']
  totalDiscount: Scalars['Float']['output']
  totalRefunded: Scalars['Float']['output']
  totalRefundedShipping: Scalars['Float']['output']
  totalShipping: Scalars['Float']['output']
  totalTax: Scalars['Float']['output']
  transactions: Array<OrderTransaction>
  updatedAt: Scalars['DateTime']['output']
}

export type OrderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  imageTransform?: InputMaybe<ImageTransformInput>
}

export type OrderRefundsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  imageTransform?: InputMaybe<ImageTransformInput>
}

export type OrderCancelReason = 'CUSTOMER' | 'DECLINED' | 'FRAUD' | 'INVENTORY' | 'OTHER'

export type OrderConnection = {
  __typename?: 'OrderConnection'
  edges: Array<OrderEdge>
  nodes: Array<Order>
  pageInfo: PageInfo
}

export type OrderEdge = {
  __typename?: 'OrderEdge'
  cursor: Scalars['String']['output']
  node: Order
}

export type OrderFulfillment = {
  __typename?: 'OrderFulfillment'
  deliveredAt: Scalars['String']['output']
  displayStatus: Scalars['String']['output']
  trackingInfo: Array<OrderTrackingInfo>
}

export type OrderLineItem = {
  __typename?: 'OrderLineItem'
  customAttributes: Array<Attribute>
  fulfillmentStatus: Scalars['String']['output']
  id: Scalars['String']['output']
  image: ShopifyImage
  name: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
  total: Scalars['Float']['output']
  variant: Maybe<Variant>
}

export type OrderLineItemConnection = {
  __typename?: 'OrderLineItemConnection'
  edges: Array<OrderLineItemEdge>
  nodes: Array<OrderLineItem>
  pageInfo: PageInfo
}

export type OrderLineItemEdge = {
  __typename?: 'OrderLineItemEdge'
  cursor: Scalars['String']['output']
  node: OrderLineItem
}

export type OrderLineItemStorefront = {
  __typename?: 'OrderLineItemStorefront'
  image: Maybe<ShopifyImage>
  title: Scalars['String']['output']
}

export type OrderLineItemStorefrontConnection = {
  __typename?: 'OrderLineItemStorefrontConnection'
  edges: Array<OrderLineItemStorefrontEdge>
  nodes: Array<OrderLineItemStorefront>
  pageInfo: PageInfo
}

export type OrderLineItemStorefrontEdge = {
  __typename?: 'OrderLineItemStorefrontEdge'
  cursor: Scalars['String']['output']
  node: OrderLineItemStorefront
}

export type OrderRefund = {
  __typename?: 'OrderRefund'
  createdAt: Scalars['DateTime']['output']
  note: Maybe<Scalars['String']['output']>
  refundLineItems: RefundLineItemConnection
  total: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OrderStatus =
  | 'CANCELED'
  | 'DELIVERED'
  | 'ORDER_PLACED'
  | 'PARTIALLY_DELIVERED'
  | 'SHIPPED'

export type OrderStorefront = {
  __typename?: 'OrderStorefront'
  createdAt: Scalars['DateTime']['output']
  fulfillments: Array<OrderFulfillment>
  lineItems: OrderLineItemStorefrontConnection
  name: Scalars['String']['output']
  total: Scalars['Float']['output']
}

export type OrderStorefrontConnection = {
  __typename?: 'OrderStorefrontConnection'
  edges: Array<OrderStorefrontEdge>
  nodes: Array<OrderStorefront>
  pageInfo: PageInfo
}

export type OrderStorefrontEdge = {
  __typename?: 'OrderStorefrontEdge'
  cursor: Scalars['String']['output']
  node: OrderStorefront
}

export type OrderTrackingInfo = {
  __typename?: 'OrderTrackingInfo'
  company: Scalars['String']['output']
  number: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type OrderTransaction = {
  __typename?: 'OrderTransaction'
  accountNumber: Scalars['String']['output']
  amount: Scalars['Float']['output']
  authorizationCode: Scalars['String']['output']
  errorCode: Scalars['String']['output']
  formattedGateway: Scalars['String']['output']
  gateway: Scalars['String']['output']
  id: Scalars['String']['output']
  kind: Scalars['String']['output']
  paymentIcon: Maybe<ShopifyImage>
  processedAt: Scalars['DateTime']['output']
  status: Scalars['String']['output']
}

export type Organization = {
  __typename?: 'Organization'
  children: Array<Organization>
  createdAt: Scalars['ISO8601DateTime']['output']
  hubspotId: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parent: Maybe<Organization>
  size: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for Organization. */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection'
  /** A list of edges. */
  edges: Array<OrganizationEdge>
  /** A list of nodes. */
  nodes: Array<Organization>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Organization>
}

export type PageBannerSection = {
  __typename?: 'PageBannerSection'
  /** @deprecated Replaced by backgroundColorDesktop */
  backgroundColor: Maybe<Scalars['String']['output']>
  backgroundColorDesktop: Maybe<Scalars['String']['output']>
  backgroundColorMobile: Maybe<Scalars['String']['output']>
  ctaStyle: Scalars['String']['output']
  /** @deprecated Replaced by StyledText */
  eyebrow: Maybe<Scalars['String']['output']>
  hasVerticalMargin: Scalars['Boolean']['output']
  hotspot: Maybe<Hotspot>
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  inlineVideoDesktop: Maybe<Scalars['String']['output']>
  inlineVideoMobile: Maybe<Scalars['String']['output']>
  inlineVimeoIdDesktop: Maybe<Scalars['Int']['output']>
  inlineVimeoIdMobile: Maybe<Scalars['Int']['output']>
  isFullBleedDesktop: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Replaced by StyledText */
  linkHref1: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  linkHref2: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  linkText1: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  linkText2: Maybe<Scalars['String']['output']>
  modalVideoId: Maybe<Scalars['String']['output']>
  modalVideoPlatform: Maybe<PageSectionModalVideoPlatform>
  name: Maybe<Scalars['String']['output']>
  openVideoModalText: Maybe<Scalars['String']['output']>
  overlayColorDesktop: Maybe<Scalars['String']['output']>
  overlayColorMobile: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  paragraph: Maybe<Scalars['String']['output']>
  styledContentItems: Array<StyledContentItem>
  /** @deprecated Replaced by StyledText */
  textAlignDesktop: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  textAlignMobile: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  textColorDesktop: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  textColorMobile: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledText */
  textContentMaxWidthDesktop: Maybe<Scalars['Int']['output']>
  /** @deprecated Replaced by StyledText */
  textContentMaxWidthMobile: Maybe<Scalars['Int']['output']>
  textPlacementDesktop: Scalars['String']['output']
  textPlacementMobile: Scalars['String']['output']
  /** @deprecated Replaced by StyledText */
  title: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledImage */
  titleImage: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by StyledImage */
  titleImageMaxWidthDesktop: Maybe<Scalars['Int']['output']>
  /** @deprecated Replaced by StyledImage */
  titleImageMaxWidthMobile: Maybe<Scalars['Int']['output']>
  titleTextSize: TitleTextSize
}

export type PageBreathingExerciseLauncherSection = {
  __typename?: 'PageBreathingExerciseLauncherSection'
  breathIntervalSeconds: Scalars['Int']['output']
  id: Scalars['ID']['output']
}

export type PageBuyItAgainSection = {
  __typename?: 'PageBuyItAgainSection'
  hideColors: Scalars['Boolean']['output']
  hidePrice: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  productCountLimit: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
  titleAlignmentDesktop: Maybe<Scalars['String']['output']>
  titleAlignmentMobile: Maybe<Scalars['String']['output']>
}

export type PageColorVoteSection = {
  __typename?: 'PageColorVoteSection'
  id: Scalars['ID']['output']
  pollHandle: Scalars['String']['output']
}

export type PageEditorialCarouselSection = {
  __typename?: 'PageEditorialCarouselSection'
  backgroundColor: Maybe<Scalars['String']['output']>
  backgroundImage: Maybe<StyledImage>
  bodies: Array<StyledText>
  body: Maybe<StyledText>
  cta1: Maybe<StyledText>
  cta2: Maybe<StyledText>
  displayVideoLink: Scalars['Boolean']['output']
  hasVerticalMargin: Scalars['Boolean']['output']
  headline: Maybe<StyledText>
  id: Scalars['ID']['output']
  modalVimeoIdDesktop: Maybe<Scalars['Float']['output']>
  modalVimeoIdMobile: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  overlayOpacity: Maybe<Scalars['Float']['output']>
  tileCarousel: Maybe<PageTileSection>
}

export type PageHeroSection = {
  __typename?: 'PageHeroSection'
  backgroundColor: Maybe<Scalars['String']['output']>
  buttonVariantDesktop: Maybe<Scalars['String']['output']>
  buttonVariantMobile: Maybe<Scalars['String']['output']>
  description: Maybe<Scalars['String']['output']>
  desktopTitleColor: Scalars['String']['output']
  eyebrow: Maybe<Scalars['String']['output']>
  hasVerticalMargin: Scalars['Boolean']['output']
  hideDownArrow: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  inlineVideoDesktop: Maybe<Scalars['String']['output']>
  inlineVideoMobile: Maybe<Scalars['String']['output']>
  inlineVimeoIdDesktop: Maybe<Scalars['Int']['output']>
  inlineVimeoIdMobile: Maybe<Scalars['Int']['output']>
  isFullscreenHeight: Scalars['Boolean']['output']
  isTitleItalic: Maybe<Scalars['Boolean']['output']>
  isTitleSerif: Maybe<Scalars['Boolean']['output']>
  linkHref1: Maybe<Scalars['String']['output']>
  linkHref2: Maybe<Scalars['String']['output']>
  linkText1: Maybe<Scalars['String']['output']>
  linkText2: Maybe<Scalars['String']['output']>
  mobileTitleColor: Scalars['String']['output']
  modalVideoId: Maybe<Scalars['String']['output']>
  modalVideoIdMobile: Maybe<Scalars['String']['output']>
  modalVideoPlatform: Maybe<PageSectionModalVideoPlatform>
  modalVideoPlatformMobile: Maybe<PageSectionModalVideoPlatform>
  name: Maybe<Scalars['String']['output']>
  openVideoModalText: Maybe<Scalars['String']['output']>
  overlayColor: Maybe<Scalars['String']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** @deprecated not in use */
  textColor: Scalars['String']['output']
  textContentMaxWidthDesktop: Maybe<Scalars['Int']['output']>
  textContentMaxWidthMobile: Maybe<Scalars['Int']['output']>
  textPlacementDesktop: Scalars['String']['output']
  textPlacementMobile: Scalars['String']['output']
  title: Maybe<Scalars['String']['output']>
  titleImage: Maybe<Scalars['String']['output']>
  titleImageAspectRatio: Scalars['Float']['output']
  titleImageMaxWidthDesktop: Maybe<Scalars['Int']['output']>
  titleImageMaxWidthMobile: Maybe<Scalars['Int']['output']>
  titleImageMobile: Maybe<Scalars['String']['output']>
  titleImageMobileAspectRatio: Scalars['Float']['output']
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>
}

export type PageInteractiveSlideSection = {
  __typename?: 'PageInteractiveSlideSection'
  hasVerticalMargin: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interactiveSlides: Array<InteractiveSlide>
  isFullBleedDesktop: Maybe<Scalars['Boolean']['output']>
  title: Maybe<Scalars['String']['output']>
}

export type PageOrderTrackingSection = {
  __typename?: 'PageOrderTrackingSection'
  id: Scalars['ID']['output']
  includeItems: Scalars['Boolean']['output']
  itemDisplayVariantMobile: Scalars['String']['output']
  maxOrderAgeInDays: Scalars['Int']['output']
  minOrderAgeInDays: Scalars['Int']['output']
  orderCountLimit: Scalars['Int']['output']
  sectionDisplayVariantMobile: Scalars['String']['output']
}

export type PageSection =
  | AccordionView
  | AppPageHeroSection
  | CollectionSection
  | CollectionViewHeroSection
  | CustomComponentSection
  | EditorialCell
  | GuestNavigationPageHeroSection
  | PageBannerSection
  | PageBreathingExerciseLauncherSection
  | PageBuyItAgainSection
  | PageColorVoteSection
  | PageEditorialCarouselSection
  | PageHeroSection
  | PageInteractiveSlideSection
  | PageOrderTrackingSection
  | PageSectionStackSection
  | PageTabSection
  | PageTestimonialSection
  | PageTileSection
  | PageTitleSection
  | ProductRecommendationsSection
  | RawHtmlSection
  | RichTextSection
  | VideoSection

export type PageSectionModalVideoPlatform = 'vimeo' | 'youtube'

export type PageSectionStackSection = {
  __typename?: 'PageSectionStackSection'
  id: Scalars['ID']['output']
  orientation: PageSectionStackSectionOrientation
  personalizedMessaging: Maybe<PersonalizedMessagingTextBlock>
  personalizedMessagingBehavior: PageSectionStackSectionPersonalizedMessagingBehavior
  sections: Array<PageSection>
  showPositionIndicators: Scalars['Boolean']['output']
}

export type PageSectionStackSectionOrientation = 'horizontal' | 'vertical'

export type PageSectionStackSectionPersonalizedMessagingBehavior = 'adopt' | 'fallback' | 'override'

export type PageSectionTitleAlignment = 'center' | 'left' | 'right'

export type PageTab = {
  __typename?: 'PageTab'
  id: Scalars['ID']['output']
  tabSections: Array<PageTabTabSection>
  text: Scalars['String']['output']
  textColor: Maybe<Scalars['String']['output']>
}

export type PageTabSection = {
  __typename?: 'PageTabSection'
  hideOnDesktop: Scalars['Boolean']['output']
  hideOnMobile: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
  tabs: Array<PageTab>
  title: Maybe<Scalars['String']['output']>
}

export type PageTabTabSection = PageTabTileSection | RawHtmlSection

export type PageTabTileSection = {
  __typename?: 'PageTabTileSection'
  id: Scalars['ID']['output']
  layoutVariantDesktop: Scalars['String']['output']
  layoutVariantMobile: Scalars['String']['output']
  tileVariantDesktop: Scalars['String']['output']
  tileVariantMobile: Scalars['String']['output']
  tiles: Array<PageTabTileSectionTile>
}

export type PageTabTileSectionTile = {
  __typename?: 'PageTabTileSectionTile'
  ctaText: Scalars['String']['output']
  href: Scalars['String']['output']
  id: Scalars['ID']['output']
  image: Scalars['String']['output']
}

export type PageTestimonialSection = {
  __typename?: 'PageTestimonialSection'
  id: Scalars['ID']['output']
  subtitle: Maybe<Scalars['String']['output']>
  testimonials: Array<Testimonial>
  title: Maybe<Scalars['String']['output']>
}

export type PageTileSection = {
  __typename?: 'PageTileSection'
  hasHorizontalMargin: Scalars['Boolean']['output']
  hasTileGaps: Scalars['Boolean']['output']
  hasVerticalMargin: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
  numColsDesktop: Scalars['Int']['output']
  numColsMobile: Scalars['Int']['output']
  textAlign: Scalars['String']['output']
  textPlacement: Scalars['String']['output']
  tileAspectRatio: Maybe<Scalars['Float']['output']>
  tiles: Array<PageTileSectionTile>
  title: Maybe<Scalars['String']['output']>
  titleAlignmentDesktop: Maybe<Scalars['String']['output']>
  titleAlignmentMobile: Maybe<Scalars['String']['output']>
  titleTextSize: TitleTextSize
  variantDesktop: Scalars['String']['output']
  variantMobile: Scalars['String']['output']
}

export type PageTileSectionTile = {
  __typename?: 'PageTileSectionTile'
  ctaLinkHref: Maybe<Scalars['String']['output']>
  ctaText: Maybe<Scalars['String']['output']>
  description: Maybe<Scalars['String']['output']>
  hoverImage: Maybe<Scalars['String']['output']>
  hoverVideo: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  inlineVideo: Maybe<Scalars['String']['output']>
  inlineVimeoId: Maybe<Scalars['Int']['output']>
  jumboTitle: Maybe<Scalars['String']['output']>
  linkHref: Scalars['String']['output']
  name: Maybe<Scalars['String']['output']>
  titleImage: Maybe<Scalars['String']['output']>
}

export type PageTitleSection = {
  __typename?: 'PageTitleSection'
  id: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
}

export type PageView = {
  __typename?: 'PageView'
  handle: Scalars['String']['output']
  hideEmailCaptureForm: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  noindex: Scalars['Boolean']['output']
  sections: Array<PageSection>
  socialDescription: Maybe<Scalars['String']['output']>
  socialImage: Maybe<Scalars['String']['output']>
  themeOverride: Maybe<ThemeOverrideString>
  title: Scalars['String']['output']
}

export type ParagraphTextSize = 'bodyDefault' | 'bodyLarge' | 'bodyMedium' | 'bodySmall'

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  description: Scalars['String']['output']
  expiration: Scalars['String']['output']
  icon: Maybe<ShopifyImage>
}

export type PersonalizedMessagingTextBlock = {
  __typename?: 'PersonalizedMessagingTextBlock'
  defaultSubtitle: Scalars['String']['output']
  defaultTitle: Scalars['String']['output']
  id: Scalars['ID']['output']
  loggedOutSubtitle: Scalars['String']['output']
  subtitle: Scalars['String']['output']
  textColor: Scalars['String']['output']
  title: Scalars['String']['output']
  titlePlacement: Scalars['String']['output']
}

export type PortalCg = {
  __typename?: 'PortalCG'
  accountHeroSectionCG: Maybe<CollectionViewHeroSection>
  collectionHandle: Maybe<Scalars['String']['output']>
  collectionHeroSectionCG: Maybe<CollectionViewHeroSection>
  collectionUrl: Maybe<Scalars['String']['output']>
  discountPercentage: Maybe<Scalars['Float']['output']>
  embroidery: Maybe<PortalEmbroiderySettings>
  enableSSO: Scalars['Boolean']['output']
  groupEmail: Maybe<Scalars['String']['output']>
  groupTag: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
  pdpCtaText: Maybe<Scalars['String']['output']>
  promoBarPages: Array<PromoBarPage>
  quickBuyText: Scalars['String']['output']
}

export type PortalCgAccountHeroSectionCgArgs = {
  preview?: Scalars['Boolean']['input']
}

export type PortalCgCollectionHeroSectionCgArgs = {
  preview?: Scalars['Boolean']['input']
}

export type PortalDefaultTextEmbroideryColor = 'Black' | 'White'

export type PortalDefaultTextEmbroideryFont = 'Block' | 'Script'

export type PortalDefaultTextEmbroideryValues = {
  __typename?: 'PortalDefaultTextEmbroideryValues'
  font: Maybe<PortalDefaultTextEmbroideryFont>
  textColor: Maybe<PortalDefaultTextEmbroideryColor>
  textPlacement: Maybe<Scalars['String']['output']>
}

export type PortalEmbroiderySettings = {
  __typename?: 'PortalEmbroiderySettings'
  addText: Scalars['String']['output']
  defaultTextEmbroideryValues: PortalDefaultTextEmbroideryValues
  eligibleProductCategories: Array<Scalars['String']['output']>
  embroideryDiscountPercentage: Maybe<Scalars['Float']['output']>
  hiddenTextEmbroideryFields: Array<PortalHiddenTextEmbroideryFields>
  image: Scalars['String']['output']
  logoName: Scalars['String']['output']
  mandatoryLogoEmbroideryCategories: Array<Scalars['String']['output']>
  mandatoryTextEmbroideryCategories: Array<Scalars['String']['output']>
  noText: Maybe<Scalars['String']['output']>
  yesText: Maybe<Scalars['String']['output']>
}

export type PortalHiddenTextEmbroideryFields = 'font' | 'secondLine' | 'textColor' | 'textPlacement'

export type Price = {
  __typename?: 'Price'
  amount: Maybe<Scalars['Cents']['output']>
  base: Maybe<Money>
  /** The 3 character ISO-4217 currency code */
  currency: Scalars['String']['output']
}

export type PriceDetails = {
  __typename?: 'PriceDetails'
  discountPct: Maybe<Scalars['Int']['output']>
  discountPrice: Maybe<Price>
  price: Maybe<Price>
}

export type PriceRange = {
  __typename?: 'PriceRange'
  max: Scalars['Cents']['output']
  min: Scalars['Cents']['output']
}

export type Product = {
  __typename?: 'Product'
  availableFits: Array<Scalars['String']['output']>
  availableForSale: Scalars['Boolean']['output']
  availableSizes: Array<Scalars['String']['output']>
  /** @deprecated Use badges instead */
  badgeList: Maybe<Array<Scalars['String']['output']>>
  badges: Maybe<Array<Scalars['String']['output']>>
  /** @deprecated no longer needed */
  baseSku: Scalars['String']['output']
  bulkOrderable: Scalars['Boolean']['output']
  careInstructions: Maybe<Scalars['String']['output']>
  category: Scalars['String']['output']
  collectionBadgeColor: Maybe<Scalars['String']['output']>
  collectionBadgeText: Maybe<Scalars['String']['output']>
  collectionLinks: Maybe<LinkConnection>
  collections: Maybe<CollectionConnection>
  color: Maybe<Scalars['String']['output']>
  colorInfo: Maybe<ProductColor>
  /** @deprecated no longer needed */
  commonGroupId: Scalars['String']['output']
  completeTheSet: Maybe<Product>
  contentfulId: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  defaultVariant: Variant
  description: Maybe<Scalars['String']['output']>
  detailImageSets: Maybe<Scalars['JSON']['output']>
  detailImages: Maybe<Array<Scalars['String']['output']>>
  embroidery: Maybe<Array<Embroidery>>
  filters: Maybe<Array<ProductFilter>>
  finalSale: Scalars['Boolean']['output']
  fit: Maybe<Scalars['String']['output']>
  fitModel: Maybe<FitModel>
  fitModelCount: Scalars['Int']['output']
  /** @deprecated replaced by fitModelInfo */
  fitModelsDescriptions: Maybe<Array<Scalars['String']['output']>>
  genderCategory: Scalars['String']['output']
  handle: Maybe<Scalars['String']['output']>
  iconBadge: Maybe<Scalars['JSON']['output']>
  iconBadgePlacement: Maybe<Scalars['String']['output']>
  /** @deprecated Use icons instead */
  iconList: Maybe<Array<ProductIcon>>
  icons: Maybe<Array<ProductIcon>>
  id: Scalars['ID']['output']
  images: Maybe<ProductAssetConnection>
  isKit: Scalars['Boolean']['output']
  kitColors: Array<Scalars['String']['output']>
  noindex: Scalars['Boolean']['output']
  priceRange: Maybe<Scalars['JSON']['output']>
  productGroup: ProductGroup
  productImageUrl: Maybe<Scalars['String']['output']>
  rawAvailableFits: Array<Scalars['String']['output']>
  rawAvailableSizes: Array<Scalars['String']['output']>
  rawCategory: Scalars['String']['output']
  rawFit: Maybe<Scalars['String']['output']>
  rawMaterials: Maybe<Array<Scalars['String']['output']>>
  rawSizes: Array<Scalars['String']['output']>
  reviews: Maybe<Array<Maybe<Review>>>
  rtfFeatures: Maybe<Scalars['JSON']['output']>
  seoDescription: Maybe<Scalars['String']['output']>
  seoTitle: Maybe<Scalars['String']['output']>
  shopTheLook: Maybe<ProductConnection>
  shopifyId: Scalars['String']['output']
  sizes: Array<Scalars['String']['output']>
  soldOut: Scalars['Boolean']['output']
  swPromoEligible: Scalars['Boolean']['output']
  tags: Array<Scalars['String']['output']>
  tiers: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  variants: Maybe<Array<Variant>>
  videos: Maybe<ProductAssetConnection>
  waitlistable: Scalars['Boolean']['output']
  whyWeLove: Maybe<Scalars['String']['output']>
}

export type ProductCollectionLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type ProductCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type ProductEmbroideryArgs = {
  logoScope?: InputMaybe<LogoScopeEnum>
}

export type ProductImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type ProductProductGroupArgs = {
  selector?: InputMaybe<ProductGroupColorSelector>
}

export type ProductShopTheLookArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  handles?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type ProductVariantsArgs = {
  soldOut?: InputMaybe<Scalars['Boolean']['input']>
}

export type ProductVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type ProductAsset = {
  __typename?: 'ProductAsset'
  caption: Maybe<Scalars['String']['output']>
  /** @deprecated Backward compatibility, use parent attribute instead */
  color: Maybe<Scalars['String']['output']>
  designFile: Maybe<Scalars['String']['output']>
  embroideryIconGroup: Maybe<Scalars['String']['output']>
  embroideryOffsets: Maybe<Scalars['JSON']['output']>
  /** @deprecated Backward compatibility, use parent attribute instead */
  fit: Maybe<Scalars['String']['output']>
  /** @deprecated useless */
  id: Scalars['ID']['output']
  index: Scalars['Int']['output']
  kind: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
  source: Scalars['String']['output']
  /** @deprecated images and videos are already separate, this should not be needed */
  type: Maybe<Scalars['String']['output']>
}

/** The connection type for ProductAsset. */
export type ProductAssetConnection = {
  __typename?: 'ProductAssetConnection'
  /** A list of edges. */
  edges: Array<ProductAssetEdge>
  /** A list of nodes. */
  nodes: Array<ProductAsset>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ProductAssetEdge = {
  __typename?: 'ProductAssetEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<ProductAsset>
}

export type ProductColor = {
  __typename?: 'ProductColor'
  accentColor: Maybe<Scalars['String']['output']>
  availability: Scalars['String']['output']
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  handle: Scalars['String']['output']
  hexCodes: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  rawName: Scalars['String']['output']
  sortOrder: Maybe<Scalars['Int']['output']>
  swatchUrl: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection'
  /** A list of edges. */
  edges: Array<ProductEdge>
  /** A list of nodes. */
  nodes: Array<Product>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Product>
}

export type ProductFilter = {
  __typename?: 'ProductFilter'
  crawlable: Scalars['Boolean']['output']
  displayName: Scalars['String']['output']
  displayType: Scalars['String']['output']
  filterValues: Array<FilterValue>
  key: Scalars['String']['output']
  values: Maybe<Array<Scalars['String']['output']>>
}

export type ProductFilterSort = {
  __typename?: 'ProductFilterSort'
  name: Scalars['String']['output']
  values: Maybe<Array<Scalars['String']['output']>>
}

export type ProductFilterSpec = {
  __typename?: 'ProductFilterSpec'
  allowMultiple: Scalars['Boolean']['output']
  displayName: Scalars['String']['output']
  displayTypes: Array<ProductFilterSpecDisplayType>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  values: Scalars['String']['output']
}

export type ProductFilterSpecDisplayType = 'basicButton' | 'basicCheckbox' | 'basicColor'

export type ProductGroup = {
  __typename?: 'ProductGroup'
  /** @deprecated No longer needed */
  baseSku: Scalars['String']['output']
  briefProductDescription: Maybe<Scalars['String']['output']>
  careInstructions: Maybe<Scalars['String']['output']>
  category: Scalars['String']['output']
  collectionHandle: Maybe<Scalars['String']['output']>
  colorGroups: Array<ColorGroup>
  colors: Array<ProductColor>
  /** @deprecated Use colors instead */
  colorsDetail: Maybe<Array<ProductColor>>
  contentfulId: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  defaultProduct: Maybe<Product>
  defaultProductHandle: Maybe<Scalars['String']['output']>
  defaultVariant: Maybe<Variant>
  description: Maybe<Scalars['String']['output']>
  embroiderable: Scalars['Boolean']['output']
  /** @deprecated Should be accessed at the product level */
  embroidery: Maybe<Array<Embroidery>>
  embroideryImages: Maybe<Array<ProductAsset>>
  featuredProduct: Maybe<Product>
  filters: Array<ProductFilter>
  fitQuiz: Maybe<FitQuiz>
  fits: Array<Scalars['String']['output']>
  genderCategory: Scalars['String']['output']
  handle: Scalars['String']['output']
  iconBadge: Maybe<Scalars['JSON']['output']>
  iconBadgePlacement: Maybe<Scalars['String']['output']>
  icons: Maybe<Array<ProductIcon>>
  id: Scalars['ID']['output']
  isKit: Scalars['Boolean']['output']
  numColors: Scalars['Int']['output']
  /** @deprecated Should be accessed at the product level */
  priceRange: Maybe<PriceRange>
  /** @deprecated Should be replaced by looking at the product */
  priceRangeByColors: Maybe<Scalars['JSON']['output']>
  productGroupView: Maybe<ProductGroupView>
  products: Array<Product>
  rawCategory: Scalars['String']['output']
  rawFits: Array<Scalars['String']['output']>
  rawMaterials: Maybe<Array<Scalars['String']['output']>>
  rawSizes: Array<Scalars['String']['output']>
  rtfFeatures: Maybe<Scalars['JSON']['output']>
  seoDescription: Maybe<Scalars['String']['output']>
  seoTitle: Maybe<Scalars['String']['output']>
  sizeChartCG: SizeChartCg
  sizeChartName: Maybe<Scalars['String']['output']>
  sizes: Array<Scalars['String']['output']>
  style: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** @deprecated Variants should be accessed via products */
  variants: Maybe<Array<Variant>>
  whyWeLove: Maybe<Scalars['String']['output']>
}

export type ProductGroupColorGroupsArgs = {
  collectionHandle?: InputMaybe<Scalars['String']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  onlyScoped?: InputMaybe<Scalars['Boolean']['input']>
  selector?: InputMaybe<ProductGroupColorSelector>
}

export type ProductGroupColorsArgs = {
  selector?: InputMaybe<ProductGroupColorSelector>
}

export type ProductGroupEmbroideryArgs = {
  includeLogos?: InputMaybe<Scalars['Boolean']['input']>
}

export type ProductGroupFeaturedProductArgs = {
  color?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<Scalars['String']['input']>
}

export type ProductGroupNumColorsArgs = {
  selector?: InputMaybe<ProductGroupColorSelector>
}

export type ProductGroupProductGroupViewArgs = {
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type ProductGroupColorSelector =
  /** Ignore any present parent scope and include all */
  | 'ALL'
  /** Derive from any present parent scope such as collection */
  | 'SCOPE'

/** The connection type for ProductGroup. */
export type ProductGroupConnection = {
  __typename?: 'ProductGroupConnection'
  /** A list of edges. */
  edges: Array<ProductGroupEdge>
  /** A list of nodes. */
  nodes: Array<ProductGroup>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ProductGroupEdge = {
  __typename?: 'ProductGroupEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<ProductGroup>
}

export type ProductGroupFilter = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>
  genderCategories?: InputMaybe<Array<Scalars['String']['input']>>
  styles?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ProductGroupView = {
  __typename?: 'ProductGroupView'
  id: Scalars['ID']['output']
  sections: Array<ProductGroupViewSection>
}

export type ProductGroupViewSection = PageBannerSection | PageHeroSection | PageTileSection

export type ProductIcon = {
  __typename?: 'ProductIcon'
  /** @deprecated useless */
  id: Scalars['ID']['output']
  image: Scalars['String']['output']
  label: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ProductKit = {
  __typename?: 'ProductKit'
  collection: Collection
  productGroup: ProductGroup
}

export type ProductRecommendationsSection = {
  __typename?: 'ProductRecommendationsSection'
  hideColors: Maybe<Scalars['Boolean']['output']>
  hidePrice: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
  nostoPlacementId: Scalars['String']['output']
  productCountLimit: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
  titleAlignmentDesktop: Maybe<Scalars['String']['output']>
  titleAlignmentMobile: Maybe<Scalars['String']['output']>
  useNostoPlacementTitle: Maybe<Scalars['Boolean']['output']>
}

/** A customer review */
export type ProductReview = {
  __typename?: 'ProductReview'
  /** The content of the review */
  body: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  /** The custom fields from Yotpo (pass-through) */
  customFields: Maybe<Scalars['JSON']['output']>
  /** An integer from 1 to 5 */
  rating: Scalars['Int']['output']
  /** The name of the reviewer */
  reviewer: Scalars['String']['output']
  /** The title of the review */
  title: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Pagination info for a set of reviews */
export type ProductReviewPageInfo = {
  __typename?: 'ProductReviewPageInfo'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Total number of pages */
  numPages: Scalars['Int']['output']
  /** Number of reviews per page */
  numPerPage: Maybe<Scalars['Int']['output']>
  /** Current page number */
  pageNum: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Product reviews */
export type ProductReviews = {
  __typename?: 'ProductReviews'
  /** The average score across all reviews */
  averageScore: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  /** The number of reviews */
  numReviews: Maybe<Scalars['Int']['output']>
  /** Pagination info for a set of reviews */
  pageInfo: Maybe<ProductReviewPageInfo>
  /** Distribution of review ratings */
  ratings: Maybe<ProductReviewsRatings>
  /** An array of product reviews */
  reviews: Array<ProductReview>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ProductReviewsPageInfo = {
  __typename?: 'ProductReviewsPageInfo'
  numPages: Maybe<Scalars['Int']['output']>
  numPerPage: Maybe<Scalars['Int']['output']>
  pageNum: Maybe<Scalars['Int']['output']>
}

/** Distribution of review ratings */
export type ProductReviewsRatings = {
  __typename?: 'ProductReviewsRatings'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Five star reviews */
  five: Maybe<Scalars['Int']['output']>
  /** Four star reviews */
  four: Maybe<Scalars['Int']['output']>
  /** One star reviews */
  one: Maybe<Scalars['Int']['output']>
  /** Three star reviews */
  three: Maybe<Scalars['Int']['output']>
  /** Two star reviews */
  two: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type PromoBar = {
  __typename?: 'PromoBar'
  backgroundColor: Scalars['String']['output']
  id: Scalars['ID']['output']
  pages: Array<PromoBarPage>
  textColor: Scalars['String']['output']
}

export type PromoBarPage = {
  __typename?: 'PromoBarPage'
  content: Scalars['String']['output']
  id: Scalars['ID']['output']
}

/** A property key - value pair. The value should be a valid JSON string */
export type Property = {
  __typename?: 'Property'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** The name of the property */
  name: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** The value of the property */
  value: Scalars['String']['output']
}

/** A property key - value pair. The value should be a valid JSON string */
export type PropertyInput = {
  /** The name of the property */
  name: Scalars['String']['input']
  /** The value of the property */
  value: Scalars['String']['input']
}

export type Query = {
  __typename?: 'Query'
  /** Returns a list of category constraints for a cart */
  cartCategoryConstraints: Maybe<CartCategoryConstraintConnection>
  /** Results a list of cart items */
  cartItems: Maybe<CartItemConnection>
  collection: Maybe<Collection>
  collectionGridSection: Maybe<CollectionGridSection>
  collectionView: Maybe<CollectionView>
  collectionViewIndex: Array<CollectionView>
  collections: Maybe<CollectionConnection>
  colorLab: Maybe<ColorLab>
  customerShopifyUrl: ShopifyUrlPayload
  /** Return a list of discounts */
  discounts: Maybe<DiscountConnection>
  dynamicCollection: Maybe<DynamicCollection>
  /** Return a list of embroidery constraints for the current user */
  embroideryConstraints: Maybe<EmbroideryConstraintConnection>
  embroideryIconGroups: Array<EmbroideryIconGroup>
  /** Return a list of team logos */
  embroideryLogos: Maybe<EmbroideryLogoConnection>
  embroideryOptions: EmbroideryOptions
  enumeration: Maybe<Enumeration>
  favorites: Maybe<CustomerFavoriteList>
  /** Returns a list of FIGS representatives */
  figsReps: Maybe<FigsRepAdminConnection>
  filteredReviews: Maybe<FilteredReviewsResponse>
  fitModels: Maybe<FitModelConnection>
  fitQuiz: Maybe<FitQuiz>
  formatPrices: Maybe<Array<FormattedPrices>>
  getCustomerSizeProfile: Maybe<CustomerSizeProfile>
  getReviews: ReviewsResponse
  /** @deprecated Replaced by `my { profile { uploadAvatarUrl } }` */
  getUploadAvatarUrl: Scalars['String']['output']
  giftPackage: Maybe<GiftPackage>
  guestOrderDetails: Maybe<Order>
  jobCategories: Array<Scalars['String']['output']>
  kit: Maybe<Kit>
  my: Customer
  optimizedCollection: Maybe<Collection>
  optimizedProducts: Maybe<ProductConnection>
  /** Returns a list of Team orders */
  orders: Maybe<TeamOrderConnection>
  /** Returns the matching organization */
  organization: Maybe<Organization>
  organizationCategories: Array<Scalars['String']['output']>
  organizationNames: Array<Scalars['String']['output']>
  organizationSizes: Array<Scalars['String']['output']>
  /** Returns a list of organizations */
  organizations: Maybe<OrganizationConnection>
  pageView: Maybe<PageView>
  pageViewIndex: Array<PageView>
  portal: Maybe<PortalCg>
  product: Maybe<Product>
  productFilterSorts: Array<ProductFilterSort>
  productGroup: Maybe<ProductGroup>
  productGroups: Maybe<ProductGroupConnection>
  productKit: Maybe<ProductKit>
  /** Returns a list of product reviews from Yotpo */
  productReviewsById: ProductReviews
  products: Maybe<ProductConnection>
  recommendationList: Maybe<RecommendationList>
  /** Return a list of user registrations */
  registrations: Maybe<RegistrationConnection>
  replacementVariants: Maybe<Array<Replacement>>
  search: SearchContext
  searchView: Maybe<CollectionView>
  serverFixtures: ServerFixtures
  /**
   * Returns the line items from a specific order given an order id
   * @deprecated Moved to account-services
   */
  shopOrderDetails: Maybe<ShopOrder>
  /**
   * Returns the order history for a Shopify user
   * @deprecated Moved to account-services
   */
  shopOrders: Maybe<ShopOrderHistory>
  /**
   * Retrieves a user who has previously registered with Shopify in the wearfigs.com environment
   * @deprecated Moved to account-services
   */
  shopUser: Maybe<ShopUser>
  /**
   * Returns a multipass enabled url for an authenticated user
   * @deprecated Moved to account-services
   */
  shopifyUrl: Maybe<ShopifyUrl>
  siteFixtures: SiteFixtures
  /** Returns a list of carts for a team */
  teamCarts: Maybe<TeamCartConnection>
  /** Returns a summary of active/complete cart counts */
  teamCartsSummary: Maybe<TeamCartSummary>
  teamCategories: Array<Scalars['String']['output']>
  teamCollection: Maybe<TeamCollection>
  /** Returns a list collections for a team */
  teamCollections: Maybe<TeamCollectionConnection>
  /** Returns a list of members of a team */
  teamMembers: Maybe<TeamMemberConnection>
  /** Returns a list of owners for a team */
  teamOwners: Maybe<TeamOwnerConnection>
  /** Returns a list of teams */
  teams: Maybe<TeamConnection>
  teamsDiscountTiers: Array<DiscountTier>
  /** Looks up and returns the specified user */
  user: Maybe<User>
  variants: Maybe<VariantConnection>
  whoami: Maybe<User>
}

export type QueryCartCategoryConstraintsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  cartId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCartItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  cartId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  owner?: InputMaybe<TeamMemberFilter>
  sortOrder?: InputMaybe<SortFilter>
}

export type QueryCollectionArgs = {
  handle: Scalars['String']['input']
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryCollectionGridSectionArgs = {
  id: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCollectionViewArgs = {
  handle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryCollectionViewIndexArgs = {
  includeNoIndex?: Scalars['Boolean']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  handle?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type QueryColorLabArgs = {
  handle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryCustomerShopifyUrlArgs = {
  redirectUri: Scalars['String']['input']
}

export type QueryDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  organizationId?: InputMaybe<Scalars['ID']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryDynamicCollectionArgs = {
  handles?: InputMaybe<Array<Scalars['String']['input']>>
  skus?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryEmbroideryConstraintsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  teamCollectionId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryEmbroideryIconGroupsArgs = {
  preview?: Scalars['Boolean']['input']
}

export type QueryEmbroideryLogosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  logoId?: InputMaybe<Scalars['ID']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  teamCollectionId?: InputMaybe<Scalars['ID']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryEnumerationArgs = {
  name: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryFavoritesArgs = {
  id: Scalars['String']['input']
}

export type QueryFigsRepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type QueryFilteredReviewsArgs = {
  filterInput: FilterInput
  numPerPage: Scalars['Int']['input']
  pageNum: Scalars['Int']['input']
}

export type QueryFitModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  handle: Scalars['String']['input']
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryFitQuizArgs = {
  handle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryFormatPricesArgs = {
  currencyLocales: Array<CurrencyLocaleInput>
  rawPrices: Array<Scalars['Int']['input']>
}

export type QueryGetCustomerSizeProfileArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetReviewsArgs = {
  numPerPage: Scalars['Int']['input']
  pageNum: Scalars['Int']['input']
  productId: Scalars['String']['input']
  sort?: InputMaybe<Scalars['String']['input']>
  sortDir?: InputMaybe<Scalars['String']['input']>
  star?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGiftPackageArgs = {
  preview?: Scalars['Boolean']['input']
  slug: Scalars['String']['input']
}

export type QueryGuestOrderDetailsArgs = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type QueryKitArgs = {
  handle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryOptimizedCollectionArgs = {
  colorScope?: InputMaybe<CollectionColorScope>
  filterConfig?: InputMaybe<FilterConfigInput>
  handle: Scalars['String']['input']
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryOptimizedProductsArgs = {
  handles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  draftId?: InputMaybe<Scalars['String']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  last?: InputMaybe<Scalars['Int']['input']>
  owner?: InputMaybe<TeamOwnerFilter>
  sampleKit?: InputMaybe<Scalars['Boolean']['input']>
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type QueryOrganizationArgs = {
  hubspotId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  hubspotId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<SortFilter>
}

export type QueryPageViewArgs = {
  handle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryPageViewIndexArgs = {
  preview?: Scalars['Boolean']['input']
}

export type QueryPortalArgs = {
  collectionHandle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryProductArgs = {
  handle: Scalars['String']['input']
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductFilterSortsArgs = {
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductGroupArgs = {
  collectionHandle?: InputMaybe<Scalars['String']['input']>
  handle: Scalars['String']['input']
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
  selectedColor?: InputMaybe<Scalars['String']['input']>
  selectedFit?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  collectionHandle?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  handle?: InputMaybe<Scalars['String']['input']>
  handles?: InputMaybe<Array<Scalars['String']['input']>>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
  skus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QueryProductKitArgs = {
  handle: Scalars['String']['input']
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductReviewsByIdArgs = {
  id: Scalars['ID']['input']
  numPerPage?: InputMaybe<Scalars['Int']['input']>
  pageNum?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  sortDir?: InputMaybe<Scalars['String']['input']>
  star?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  handles?: InputMaybe<Array<Scalars['String']['input']>>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  launchTags?: InputMaybe<Scalars['String']['input']>
}

export type QueryRecommendationListArgs = {
  nostoSectionId: Scalars['String']['input']
  sessionId?: InputMaybe<Scalars['String']['input']>
}

export type QueryRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  completed?: InputMaybe<Scalars['Boolean']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryReplacementVariantsArgs = {
  replacementInput: Array<ReplacementInput>
}

export type QuerySearchArgs = {
  query?: InputMaybe<Scalars['String']['input']>
  selectedFilters?: InputMaybe<Array<SearchFilterInput>>
}

export type QuerySearchViewArgs = {
  handle: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
  skus: Array<Scalars['String']['input']>
}

export type QueryServerFixturesArgs = {
  handle?: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryShopOrderDetailsArgs = {
  id: Scalars['String']['input']
}

export type QueryShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryShopUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
}

export type QueryShopifyUrlArgs = {
  redirectUri: Scalars['String']['input']
}

export type QuerySiteFixturesArgs = {
  handle?: Scalars['String']['input']
  preview?: Scalars['Boolean']['input']
}

export type QueryTeamCartsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  cartId?: InputMaybe<Scalars['ID']['input']>
  cartStatus?: InputMaybe<CartStatus>
  excludeArchived?: InputMaybe<Scalars['Boolean']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  owner?: InputMaybe<TeamOwnerFilter>
  sortOrder?: InputMaybe<SortFilter>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryTeamCartsSummaryArgs = {
  ownerIdOrEmail: Scalars['String']['input']
}

export type QueryTeamCollectionArgs = {
  handle?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryTeamCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  owner?: InputMaybe<TeamOwnerFilter>
  productGroupFilter?: InputMaybe<ProductGroupFilter>
  sortOrder?: InputMaybe<SortFilter>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryTeamMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryTeamOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  owner?: InputMaybe<TeamOwnerFilter>
  sortOrder?: InputMaybe<SortFilter>
  teamId?: InputMaybe<Scalars['ID']['input']>
  teamStatus?: InputMaybe<TeamStatus>
}

export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

export type QueryVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type Ratings = {
  __typename?: 'Ratings'
  fiveStarRatings: Maybe<Scalars['Int']['output']>
  fourStarRatings: Maybe<Scalars['Int']['output']>
  oneStarRatings: Maybe<Scalars['Int']['output']>
  threeStarRatings: Maybe<Scalars['Int']['output']>
  twoStarRatings: Maybe<Scalars['Int']['output']>
}

export type RawHtmlSection = {
  __typename?: 'RawHtmlSection'
  html: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type RecommendationList = {
  __typename?: 'RecommendationList'
  optimizedProducts: Maybe<ProductConnection>
  productHandles: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type RedirectSet = {
  __typename?: 'RedirectSet'
  code: Scalars['Int']['output']
  id: Scalars['ID']['output']
  redirects: Scalars['String']['output']
}

export type RefundLineItem = {
  __typename?: 'RefundLineItem'
  lineItem: OrderLineItem
  price: Scalars['Float']['output']
  quantity: Scalars['Int']['output']
  restocked: Scalars['Boolean']['output']
  subtotal: Scalars['Float']['output']
  totalTax: Scalars['Float']['output']
}

export type RefundLineItemConnection = {
  __typename?: 'RefundLineItemConnection'
  edges: Array<RefundLineItemEdge>
  nodes: Array<RefundLineItem>
  pageInfo: PageInfo
}

export type RefundLineItemEdge = {
  __typename?: 'RefundLineItemEdge'
  cursor: Scalars['String']['output']
  node: RefundLineItem
}

export type Region = {
  __typename?: 'Region'
  flagSrc: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  promoBarRegion: Maybe<PromoBar>
  shippingText: Maybe<Scalars['String']['output']>
  taxAndDutyText: Maybe<Scalars['String']['output']>
  welcomeShippingText: Maybe<Scalars['String']['output']>
  welcomeTaxAndDutyText: Maybe<Scalars['String']['output']>
}

export type Regions = {
  __typename?: 'Regions'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regions: Array<Region>
}

/** An email registration */
export type Registration = {
  __typename?: 'Registration'
  /** When the user created their account, if ever */
  completedAt: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  /** A json blob of arbitrary data to associate with this email */
  data: Scalars['JSON']['output']
  /** The email to register */
  email: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** The type of user the email belongs to */
  userType: Scalars['String']['output']
}

/** The connection type for Registration. */
export type RegistrationConnection = {
  __typename?: 'RegistrationConnection'
  /** A list of edges. */
  edges: Array<RegistrationEdge>
  /** A list of nodes. */
  nodes: Array<Registration>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type RegistrationEdge = {
  __typename?: 'RegistrationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Registration>
}

export type RemoveCustomerFavoriteListProductInput = {
  id: Scalars['String']['input']
  listHandle: Scalars['String']['input']
}

export type Replacement = {
  __typename?: 'Replacement'
  replacementShopifyId: Scalars['String']['output']
  replacementSku: Scalars['String']['output']
  requestedKey: Scalars['String']['output']
}

export type ReplacementInput = {
  key: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload'
  errors: Maybe<Array<UserError>>
  user: Maybe<User>
}

export type Review = {
  __typename?: 'Review'
  content: Scalars['String']['output']
  createdAt: Maybe<Scalars['String']['output']>
  customFields: Maybe<Array<Maybe<CustomField>>>
  imageUrls: Maybe<Array<Maybe<Scalars['String']['output']>>>
  reviewer: Scalars['String']['output']
  score: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type ReviewInput = {
  content: Scalars['String']['input']
  domain: Scalars['String']['input']
  email: Scalars['String']['input']
  handle: Scalars['String']['input']
  imageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  name: Scalars['String']['input']
  path: Scalars['String']['input']
  productDescription: Scalars['String']['input']
  productImageUrl: Scalars['String']['input']
  productTitle: Scalars['String']['input']
  region: Scalars['String']['input']
  score: Scalars['String']['input']
  shopifyId: Scalars['String']['input']
  sku: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type ReviewsResponse = {
  __typename?: 'ReviewsResponse'
  averageFit: Maybe<Scalars['Int']['output']>
  averageScore: Maybe<Scalars['Float']['output']>
  pagination: Maybe<ProductReviewsPageInfo>
  reviews: Maybe<Array<Maybe<Review>>>
  starDistribution: Maybe<Ratings>
  tag: Maybe<Scalars['String']['output']>
  totalReviews: Maybe<Scalars['Int']['output']>
}

export type RichTextSection = {
  __typename?: 'RichTextSection'
  content: Scalars['JSON']['output']
  id: Scalars['ID']['output']
}

export type SearchContext = {
  __typename?: 'SearchContext'
  defaultFilters: Array<DefaultSearchFilter>
  items: SearchResultConnection
  suggestions: Array<SearchSuggestion>
}

export type SearchContextDefaultFiltersArgs = {
  input: SearchPreferencesInput
}

export type SearchContextItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type SearchFilterInput = {
  key: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

export type SearchGridSection = {
  __typename?: 'SearchGridSection'
  collection: DynamicCollection
  collectionHandle: Scalars['String']['output']
  colorScope: Scalars['String']['output']
  filterConfig: Maybe<FilterConfig>
  filtersEnabled: Scalars['Boolean']['output']
  groupedProductTiles: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  skus: Array<Scalars['String']['output']>
}

export type SearchPreferencesInput = {
  styles?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SearchResultColor = {
  __typename?: 'SearchResultColor'
  hexCodes: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type SearchResultConnection = {
  __typename?: 'SearchResultConnection'
  analyticsId: Maybe<Scalars['String']['output']>
  colors: Array<SearchResultColor>
  filters: Array<SearchResultFilter>
  id: Scalars['String']['output']
  nodes: Array<SearchResultItem>
  numItems: Scalars['Float']['output']
  pageInfo: PageInfo
}

export type SearchResultFilter = {
  __typename?: 'SearchResultFilter'
  displayName: Scalars['String']['output']
  displayType: Scalars['String']['output']
  filterValues: Array<SearchResultFilterValue>
  key: Scalars['String']['output']
}

export type SearchResultFilterState = 'DISABLED' | 'ENABLED' | 'SELECTED'

export type SearchResultFilterValue = {
  __typename?: 'SearchResultFilterValue'
  state: SearchResultFilterState
  value: Scalars['String']['output']
}

export type SearchResultItem = {
  __typename?: 'SearchResultItem'
  id: Scalars['ID']['output']
  product: Product
  summary: SearchResultProductSummary
}

export type SearchResultProductAsset = {
  __typename?: 'SearchResultProductAsset'
  source: Scalars['String']['output']
}

export type SearchResultProductAssetConnection = {
  __typename?: 'SearchResultProductAssetConnection'
  nodes: Array<SearchResultProductAsset>
  numItems: Scalars['Float']['output']
  numPages: Scalars['Float']['output']
  pageInfo: PageInfo
}

export type SearchResultProductColorInfo = {
  __typename?: 'SearchResultProductColorInfo'
  availability: Scalars['String']['output']
  code: Scalars['String']['output']
  hexCodes: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
}

export type SearchResultProductGroup = {
  __typename?: 'SearchResultProductGroup'
  collectionHandle: Maybe<Scalars['String']['output']>
  description: Maybe<Scalars['String']['output']>
  handle: Scalars['String']['output']
  style: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type SearchResultProductSummary = {
  __typename?: 'SearchResultProductSummary'
  category: Scalars['String']['output']
  colorInfo: Maybe<SearchResultProductColorInfo>
  id: Scalars['String']['output']
  images: SearchResultProductAssetConnection
  productGroup: Maybe<SearchResultProductGroup>
}

export type SearchResultProductSummaryImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type SearchSuggestion = {
  __typename?: 'SearchSuggestion'
  query: Scalars['String']['output']
}

export type Section =
  | CollectionGridSection
  | CollectionViewHeroSection
  | GenderNavSection
  | HeroFullBleedSection
  | PageTabSection
  | RichTextSection
  | SearchGridSection
  | VideoModalBannerSection
  | VideoSection

export type ServerFixtures = {
  __typename?: 'ServerFixtures'
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  redirectSets: Array<RedirectSet>
}

export type ShippingAddress = {
  __typename?: 'ShippingAddress'
  addressLine1: Scalars['String']['output']
  addressLine2: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  country: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  postalCode: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type ShopOrder = {
  __typename?: 'ShopOrder'
  billingAddress: Maybe<ShopifyAddress>
  createdAt: Maybe<Scalars['String']['output']>
  displayFinancialStatus: Maybe<Scalars['String']['output']>
  displayFulfillmentStatus: Maybe<Scalars['String']['output']>
  fulfillments: Maybe<Array<ShopifyFulfillment>>
  id: Scalars['String']['output']
  lineItems: Maybe<Array<ShopOrderLineItem>>
  name: Scalars['String']['output']
  shippingAddress: Maybe<ShopifyAddress>
  total: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ShopOrderHistory = {
  __typename?: 'ShopOrderHistory'
  createdAt: Scalars['ISO8601DateTime']['output']
  pageInfo: PageInfo
  shopOrders: Maybe<Array<ShopOrder>>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ShopOrderLineItem = {
  __typename?: 'ShopOrderLineItem'
  altText: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  fulfillmentStatus: Maybe<Scalars['String']['output']>
  imgSrc: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
  total: Scalars['Float']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ShopUser = {
  __typename?: 'ShopUser'
  concierge: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  eligibleForDiscount: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  emailOptIn: Scalars['Boolean']['output']
  firstName: Scalars['String']['output']
  hasOrders: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  initials: Maybe<Scalars['String']['output']>
  jobCategory: Maybe<Scalars['String']['output']>
  lastLoginAt: Maybe<Scalars['ISO8601DateTime']['output']>
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  ordersCount: Scalars['Int']['output']
  phone: Maybe<Scalars['String']['output']>
  properties: Maybe<Array<Property>>
  resetPasswordSentAt: Maybe<Scalars['ISO8601DateTime']['output']>
  shopifyId: Scalars['String']['output']
  tags: Maybe<Array<Scalars['String']['output']>>
  type: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ShopUserInput = {
  concierge?: InputMaybe<Scalars['Boolean']['input']>
  eligibleForDiscount?: InputMaybe<Scalars['Boolean']['input']>
  email: Scalars['String']['input']
  emailOptIn?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  initials?: InputMaybe<Scalars['String']['input']>
  jobCategory?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ShopifyAddress = {
  __typename?: 'ShopifyAddress'
  address1: Scalars['String']['output']
  address2: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  country: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  name: Maybe<Scalars['String']['output']>
  phone: Maybe<Scalars['String']['output']>
  province: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  zip: Scalars['String']['output']
}

export type ShopifyFulfillment = {
  __typename?: 'ShopifyFulfillment'
  createdAt: Scalars['ISO8601DateTime']['output']
  deliveredAt: Maybe<Scalars['String']['output']>
  displayStatus: Maybe<Scalars['String']['output']>
  trackingInfo: Maybe<Array<TrackingInfo>>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ShopifyImage = {
  __typename?: 'ShopifyImage'
  altText: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ShopifyUrl = {
  __typename?: 'ShopifyUrl'
  createdAt: Scalars['ISO8601DateTime']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  url: Maybe<Scalars['String']['output']>
}

export type ShopifyUrlPayload = {
  __typename?: 'ShopifyUrlPayload'
  url: Scalars['String']['output']
}

export type SiteFixtures = {
  __typename?: 'SiteFixtures'
  appAccountNavigationSections: Array<AppAccountNavSection>
  appVersionUpdatePolicy: Maybe<AppVersionUpdatePolicy>
  disableApplePay: Maybe<Scalars['Boolean']['output']>
  disableOneTrust: Maybe<Scalars['Boolean']['output']>
  disableOptimizely: Maybe<Scalars['Boolean']['output']>
  embroideryIcon: Maybe<Scalars['String']['output']>
  embroideryWarningMessage: Maybe<Scalars['String']['output']>
  enablePDPAccentColor: Maybe<Scalars['Boolean']['output']>
  enableSiteWidePromo: Maybe<Scalars['Boolean']['output']>
  footer: SiteFooter
  handle: Scalars['String']['output']
  id: Scalars['ID']['output']
  localeData: Maybe<Locales>
  logoColorSelectionTooltip: Maybe<Scalars['String']['output']>
  logoDigitizationDisclaimer: Maybe<Scalars['String']['output']>
  logoDigitizationWarning: Maybe<Scalars['String']['output']>
  logoLearnMorelink: Maybe<Scalars['String']['output']>
  mensSizeCharts: Maybe<Array<SizeChartCg>>
  miniCartMessage: Maybe<Scalars['String']['output']>
  navigation: SiteNavigation
  nostoUpsell: Maybe<Scalars['Boolean']['output']>
  nostoUpsellId: Maybe<Scalars['String']['output']>
  promoBar: Maybe<PromoBar>
  regionData: Maybe<Regions>
  searchRedirects: Maybe<RedirectSet>
  siteWidePromo: Maybe<Scalars['String']['output']>
  tooltips: Maybe<Array<Tooltip>>
  upsellHandles: Maybe<Array<Scalars['String']['output']>>
  womensSizeCharts: Maybe<Array<SizeChartCg>>
}

export type SiteFooter = {
  __typename?: 'SiteFooter'
  accessibilityButtonText: Maybe<Scalars['String']['output']>
  copyrightLine: Maybe<Scalars['String']['output']>
  desktopSections: Array<NavSection>
  legalLinks: Array<NavListItem>
  mobileSections: Array<NavSection>
  socialLinks: Array<NavListItem>
}

export type SiteNavigation = {
  __typename?: 'SiteNavigation'
  desktopTabs: Array<NavTab>
  helpLinkHref: Maybe<Scalars['String']['output']>
  helpLinkText: Maybe<Scalars['String']['output']>
  mobileTabs: Array<NavTab>
  name: Scalars['String']['output']
}

export type SizeChartCg = {
  __typename?: 'SizeChartCG'
  columnNames: Maybe<Array<Scalars['String']['output']>>
  columnValues: Maybe<Array<Array<Scalars['String']['output']>>>
  imageUri: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  navLabel: Maybe<Scalars['String']['output']>
  tables: Maybe<Array<SizeChartTable>>
  tips: Maybe<Array<SizeChartTipCg>>
  title: Scalars['String']['output']
  video: Maybe<Scalars['String']['output']>
}

export type SizeChartTable = {
  __typename?: 'SizeChartTable'
  cells: Scalars['String']['output']
  measureImage: Maybe<Scalars['String']['output']>
  measureTips: Maybe<Array<SizeChartTipCg>>
  sizeTips: Maybe<Array<SizeChartTipCg>>
  subtitle: Maybe<Scalars['String']['output']>
  tabLabel: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
}

export type SizeChartTipCg = {
  __typename?: 'SizeChartTipCG'
  kind: Maybe<Scalars['String']['output']>
  tip: Maybe<Scalars['String']['output']>
}

export type SizePreferenceSetting = {
  __typename?: 'SizePreferenceSetting'
  category: Scalars['String']['output']
  fit: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  size: Scalars['String']['output']
}

export type SortFilter = {
  attr: Scalars['String']['input']
  dir: Scalars['String']['input']
}

export type StyledContentItem = StyledImage | StyledText

export type StyledImage = {
  __typename?: 'StyledImage'
  altText: Scalars['String']['output']
  /** @deprecated Replaced by altText */
  altTextDesktop: Maybe<Scalars['String']['output']>
  /** @deprecated Replaced by altText */
  altTextMobile: Maybe<Scalars['String']['output']>
  aspectRatioDesktop: Maybe<Scalars['Float']['output']>
  aspectRatioMobile: Maybe<Scalars['Float']['output']>
  bottomMargin: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  maxWidthDesktop: Maybe<Scalars['Float']['output']>
  maxWidthMobile: Maybe<Scalars['Float']['output']>
}

export type StyledLink = {
  __typename?: 'StyledLink'
  id: Scalars['ID']['output']
  text: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type StyledText = {
  __typename?: 'StyledText'
  bottomMargin: Maybe<Scalars['Float']['output']>
  eyebrow: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  links: Array<StyledLink>
  paragraph: Maybe<Scalars['String']['output']>
  paragraphTextSize: ParagraphTextSize
  textAlignDesktop: TextAlignDesktop
  textAlignMobile: TextAlignMobile
  textColorDesktop: Scalars['String']['output']
  textColorMobile: Scalars['String']['output']
  textContentMaxWidthDesktop: Maybe<Scalars['Float']['output']>
  textContentMaxWidthMobile: Maybe<Scalars['Float']['output']>
  title: Maybe<Scalars['String']['output']>
  titleTextSize: TitleTextSize
}

export type SubmitCustomerSizeProfileInput = {
  age: Scalars['Int']['input']
  gender: CustomerSizeProfileGender
  height: Scalars['Float']['input']
  measurementUnitSystem: MeasurementUnitSystem
  weight: Scalars['Float']['input']
}

/** Autogenerated return type of SubscribeUser. */
export type SubscribeUserPayload = {
  __typename?: 'SubscribeUserPayload'
  errors: Maybe<Scalars['JSON']['output']>
  result: Maybe<Scalars['JSON']['output']>
}

export type TargetCriteria = {
  __typename?: 'TargetCriteria'
  inRegion: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  targetCriteria: Array<Scalars['String']['output']>
  targetLogic: Scalars['String']['output']
}

export type Team = {
  __typename?: 'Team'
  activeCartCount: Scalars['Int']['output']
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  baseCollection: Maybe<Collection>
  cartSummary: Maybe<TeamCartSummary>
  category: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  currentDiscount: Scalars['Int']['output']
  embroideryLogos: Maybe<Array<EmbroideryLogo>>
  figsRep: Maybe<User>
  figsRepEmail: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  memberCount: Scalars['Int']['output']
  name: Scalars['String']['output']
  organization: Organization
  teamCarts: Maybe<Array<TeamCart>>
  teamCollections: Maybe<Array<TeamCollection>>
  teamMembers: Array<TeamMember>
  teamOwners: Array<TeamOwner>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type TeamTeamCartsArgs = {
  excludeArchived?: InputMaybe<Scalars['Boolean']['input']>
}

export type TeamCart = {
  __typename?: 'TeamCart'
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  billingAddress: Maybe<BillingAddress>
  cartItems: Maybe<Array<CartItem>>
  categoryConstraints: Maybe<Array<CartCategoryConstraint>>
  closesAt: Maybe<Scalars['ISO8601DateTime']['output']>
  collection: TeamCollection
  createdAt: Scalars['ISO8601DateTime']['output']
  csvExport: Maybe<Scalars['String']['output']>
  /** The maximum discount percentage of all cart items */
  currentDiscount: Maybe<Scalars['Int']['output']>
  embroideryStatus: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitations: Maybe<CartInvitationConnection>
  inviteToken: Scalars['String']['output']
  locked: Scalars['Boolean']['output']
  memberSpendLimit: Maybe<Scalars['Int']['output']>
  missingResponders: Maybe<Array<Scalars['String']['output']>>
  name: Maybe<Scalars['String']['output']>
  numInvitations: Scalars['Int']['output']
  numItems: Scalars['Int']['output']
  numParticipants: Scalars['Int']['output']
  numUnits: Scalars['Int']['output']
  order: Maybe<TeamOrder>
  owner: TeamOwner
  participants: Maybe<Array<User>>
  responseSummary: Maybe<Scalars['String']['output']>
  shippingAddress: Maybe<ShippingAddress>
  status: Scalars['String']['output']
  team: Team
  total: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  user: User
}

export type TeamCartInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for TeamCart. */
export type TeamCartConnection = {
  __typename?: 'TeamCartConnection'
  /** A list of edges. */
  edges: Array<TeamCartEdge>
  /** A list of nodes. */
  nodes: Array<TeamCart>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type TeamCartEdge = {
  __typename?: 'TeamCartEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<TeamCart>
}

export type TeamCartSummary = {
  __typename?: 'TeamCartSummary'
  createdAt: Scalars['ISO8601DateTime']['output']
  numActive: Scalars['Int']['output']
  numArchived: Scalars['Int']['output']
  numCarts: Scalars['Int']['output']
  numComplete: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type TeamCollection = {
  __typename?: 'TeamCollection'
  baseCollection: Collection
  collection: DynamicCollection
  createdAt: Scalars['ISO8601DateTime']['output']
  embroideryConstraints: Maybe<Array<EmbroideryConstraint>>
  team: Team
  teamName: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for TeamCollection. */
export type TeamCollectionConnection = {
  __typename?: 'TeamCollectionConnection'
  /** A list of edges. */
  edges: Array<TeamCollectionEdge>
  /** A list of nodes. */
  nodes: Array<TeamCollection>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type TeamCollectionEdge = {
  __typename?: 'TeamCollectionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<TeamCollection>
}

/** The connection type for Team. */
export type TeamConnection = {
  __typename?: 'TeamConnection'
  /** A list of edges. */
  edges: Array<TeamEdge>
  /** A list of nodes. */
  nodes: Array<Team>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type TeamEdge = {
  __typename?: 'TeamEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Team>
}

export type TeamMember = {
  __typename?: 'TeamMember'
  concierge: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  eligibleForDiscount: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  emailOptIn: Scalars['Boolean']['output']
  firstName: Scalars['String']['output']
  hasOrders: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  initials: Maybe<Scalars['String']['output']>
  jobCategory: Maybe<Scalars['String']['output']>
  lastLoginAt: Maybe<Scalars['ISO8601DateTime']['output']>
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  phone: Maybe<Scalars['String']['output']>
  properties: Maybe<Array<Property>>
  resetPasswordSentAt: Maybe<Scalars['ISO8601DateTime']['output']>
  teams: Array<Team>
  type: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for TeamMember. */
export type TeamMemberConnection = {
  __typename?: 'TeamMemberConnection'
  /** A list of edges. */
  edges: Array<TeamMemberEdge>
  /** A list of nodes. */
  nodes: Array<TeamMember>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type TeamMemberEdge = {
  __typename?: 'TeamMemberEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<TeamMember>
}

export type TeamMemberFilter = {
  OR?: InputMaybe<Array<UserFilter>>
  email?: InputMaybe<Scalars['String']['input']>
  emailContains?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  firstNameContains?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  lastNameContains?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneContains?: InputMaybe<Scalars['String']['input']>
}

export type TeamOrder = {
  __typename?: 'TeamOrder'
  cart: Cart
  createdAt: Scalars['ISO8601DateTime']['output']
  discount: Maybe<Scalars['Int']['output']>
  draftId: Maybe<Scalars['String']['output']>
  draftUrl: Maybe<Scalars['String']['output']>
  fulfillmentStatus: Maybe<Scalars['String']['output']>
  fulfillments: Maybe<Array<Fulfillment>>
  id: Scalars['ID']['output']
  invoiceUrl: Maybe<Scalars['String']['output']>
  isReturnable: Scalars['Boolean']['output']
  owner: Maybe<User>
  paymentStatus: Maybe<Scalars['String']['output']>
  shipping: Maybe<Scalars['Int']['output']>
  shippingPostalCode: Maybe<Scalars['String']['output']>
  shopifyId: Maybe<Scalars['String']['output']>
  shopifyName: Maybe<Scalars['String']['output']>
  shopifyUrl: Maybe<Scalars['String']['output']>
  subtotal: Maybe<Scalars['Int']['output']>
  tax: Maybe<Scalars['Int']['output']>
  total: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for TeamOrder. */
export type TeamOrderConnection = {
  __typename?: 'TeamOrderConnection'
  /** A list of edges. */
  edges: Array<TeamOrderEdge>
  /** A list of nodes. */
  nodes: Array<TeamOrder>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type TeamOrderEdge = {
  __typename?: 'TeamOrderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<TeamOrder>
}

export type TeamOwner = {
  __typename?: 'TeamOwner'
  concierge: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  currentDiscount: Scalars['Int']['output']
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  eligibleForDiscount: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  emailOptIn: Scalars['Boolean']['output']
  firstName: Scalars['String']['output']
  hasOrders: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  initials: Maybe<Scalars['String']['output']>
  jobCategory: Scalars['String']['output']
  lastLoginAt: Maybe<Scalars['ISO8601DateTime']['output']>
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  phone: Maybe<Scalars['String']['output']>
  properties: Maybe<Array<Property>>
  resetPasswordSentAt: Maybe<Scalars['ISO8601DateTime']['output']>
  teams: Array<Team>
  type: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for TeamOwner. */
export type TeamOwnerConnection = {
  __typename?: 'TeamOwnerConnection'
  /** A list of edges. */
  edges: Array<TeamOwnerEdge>
  /** A list of nodes. */
  nodes: Array<TeamOwner>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type TeamOwnerEdge = {
  __typename?: 'TeamOwnerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<TeamOwner>
}

export type TeamOwnerFilter = {
  OR?: InputMaybe<Array<UserFilter>>
  email?: InputMaybe<Scalars['String']['input']>
  emailContains?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  firstNameContains?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  lastNameContains?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneContains?: InputMaybe<Scalars['String']['input']>
}

export type TeamStatus = 'active' | 'archived'

export type Testimonial = {
  __typename?: 'Testimonial'
  id: Scalars['ID']['output']
  image: Scalars['String']['output']
  instagram: Maybe<Scalars['String']['output']>
  occupation: Maybe<Scalars['String']['output']>
  personName: Scalars['String']['output']
  quote: Scalars['String']['output']
}

export type TextAlignDesktop = 'center' | 'left' | 'right'

export type TextAlignMobile = 'center' | 'left' | 'right'

export type ThemeOverrideString = 'dark' | 'light' | 'olympicDark' | 'olympicLight'

export type TitleTextSize = 'h1' | 'h2' | 'h3' | 'h4'

export type Tooltip = {
  __typename?: 'Tooltip'
  contentType: Scalars['String']['output']
  description: Scalars['String']['output']
  enumValue: EnumeratedValue
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type TrackingInfo = {
  __typename?: 'TrackingInfo'
  company: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  number: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  url: Maybe<Scalars['String']['output']>
}

export type UpdateCustomerProfileEmbroideryPreferencesInput = {
  textColor?: InputMaybe<Scalars['String']['input']>
  textFirstLine?: InputMaybe<Scalars['String']['input']>
  textFontStyle?: InputMaybe<Scalars['String']['input']>
  textPlacement?: InputMaybe<Scalars['String']['input']>
  textSecondLine?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCustomerProfileInput = {
  avatarUri?: InputMaybe<Scalars['String']['input']>
  birthday?: InputMaybe<Scalars['String']['input']>
  emailOptIn?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  occupation?: InputMaybe<Scalars['String']['input']>
  ordersCount?: InputMaybe<Scalars['Int']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  stylePreference?: InputMaybe<CustomerProfileStylePreference>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpdateCustomerProfileProductPreferencesInput = {
  pantFit?: InputMaybe<Scalars['String']['input']>
  pantLength?: InputMaybe<Scalars['String']['input']>
  pantSize?: InputMaybe<Scalars['String']['input']>
  shoeSize?: InputMaybe<Scalars['String']['input']>
  topFit?: InputMaybe<Scalars['String']['input']>
  topSize?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSizePreferenceSettingInput = {
  category: Scalars['String']['input']
  fit?: InputMaybe<Scalars['String']['input']>
  size: Scalars['String']['input']
}

export type User = {
  __typename?: 'User'
  concierge: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>
  eligibleForDiscount: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  emailOptIn: Scalars['Boolean']['output']
  firstName: Scalars['String']['output']
  hasOrders: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  initials: Maybe<Scalars['String']['output']>
  jobCategory: Maybe<Scalars['String']['output']>
  lastLoginAt: Maybe<Scalars['ISO8601DateTime']['output']>
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  phone: Maybe<Scalars['String']['output']>
  properties: Maybe<Array<Property>>
  resetPasswordSentAt: Maybe<Scalars['ISO8601DateTime']['output']>
  type: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** A user-readable error */
export type UserError = {
  __typename?: 'UserError'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** A description of the error */
  message: Scalars['String']['output']
  /** Where the error came from */
  path: Maybe<Array<Scalars['String']['output']>>
  rawMessage: Maybe<Scalars['JSON']['output']>
  /** An optional status code */
  status: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type UserFilter = {
  OR?: InputMaybe<Array<UserFilter>>
  email?: InputMaybe<Scalars['String']['input']>
  emailContains?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  firstNameContains?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  lastNameContains?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneContains?: InputMaybe<Scalars['String']['input']>
}

export type ValidContentfulModel = NavAccordionSection | NavListItem | NavTab

export type Variant = {
  __typename?: 'Variant'
  availableForSale: Scalars['Boolean']['output']
  bulkOrderQuantityLimit: Maybe<Scalars['Int']['output']>
  category: Scalars['String']['output']
  color: Maybe<Scalars['String']['output']>
  colorInfo: Maybe<ProductColor>
  currencyType: Scalars['String']['output']
  discountPrice: Maybe<Scalars['Cents']['output']>
  discountPriceExplicitFmt: Scalars['String']['output']
  discountPriceShortFmt: Maybe<Scalars['String']['output']>
  /** @deprecated Use product.shopifyId instead */
  externalParentId: Scalars['String']['output']
  fit: Maybe<Scalars['String']['output']>
  /** @deprecated This should be at the productGroup level */
  genderCategory: Scalars['String']['output']
  handle: Scalars['String']['output']
  /** @deprecated This should be at the product level */
  image: Maybe<ProductAsset>
  /** @deprecated This should be at the product level */
  images: Maybe<Array<ProductAsset>>
  price: Scalars['Cents']['output']
  priceDetails: Maybe<PriceDetails>
  priceExplicitFmt: Scalars['String']['output']
  /** @deprecated Should use priceDetails */
  priceInfo: Maybe<Scalars['JSON']['output']>
  priceShortFmt: Maybe<Scalars['String']['output']>
  /** @deprecated Variants should be accessed via products, not the other way around */
  product: Product
  /** @deprecated This should start by querying the product_group. Seems like we might need the ability to query product_groups by skus */
  productGroup: ProductGroup
  rawCategory: Scalars['String']['output']
  rawColor: Maybe<Scalars['String']['output']>
  rawFit: Maybe<Scalars['String']['output']>
  rawSize: Maybe<Scalars['String']['output']>
  shopifyId: Scalars['String']['output']
  size: Maybe<Scalars['String']['output']>
  sku: Maybe<Scalars['String']['output']>
  soldOut: Scalars['Boolean']['output']
  /** @deprecated This should be at either the product_group or product level */
  style: Maybe<Scalars['String']['output']>
  upc: Maybe<Scalars['String']['output']>
}

/** The connection type for Variant. */
export type VariantConnection = {
  __typename?: 'VariantConnection'
  /** A list of edges. */
  edges: Array<VariantEdge>
  /** A list of nodes. */
  nodes: Array<Variant>
  /** Total number of items returnable */
  numItems: Scalars['Int']['output']
  /** Total number of pages based on total number of items and page size */
  numPages: Scalars['Int']['output']
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type VariantEdge = {
  __typename?: 'VariantEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Maybe<Variant>
}

export type VideoModalBannerSection = {
  __typename?: 'VideoModalBannerSection'
  body: Scalars['String']['output']
  ctaText: Scalars['String']['output']
  heading: Scalars['String']['output']
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  inlineVideo: Maybe<Scalars['String']['output']>
  inlineVimeoId: Maybe<Scalars['Int']['output']>
  linkHref: Maybe<Scalars['String']['output']>
  videoId: Maybe<Scalars['String']['output']>
  videoPlatform: Maybe<Scalars['String']['output']>
}

export type VideoSection = {
  __typename?: 'VideoSection'
  buttonVariant: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageDesktop: Maybe<Scalars['String']['output']>
  imageMobile: Maybe<Scalars['String']['output']>
  inlineVideoDesktop: Maybe<Scalars['String']['output']>
  inlineVideoMobile: Maybe<Scalars['String']['output']>
  inlineVimeoIdDesktop: Maybe<Scalars['Int']['output']>
  inlineVimeoIdMobile: Maybe<Scalars['Int']['output']>
  linkHref1: Maybe<Scalars['String']['output']>
  linkHref2: Maybe<Scalars['String']['output']>
  linkText1: Maybe<Scalars['String']['output']>
  linkText2: Maybe<Scalars['String']['output']>
  modalVideoId: Maybe<Scalars['String']['output']>
  modalVideoPlatform: Maybe<PageSectionModalVideoPlatform>
  name: Maybe<Scalars['String']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  textColor: Scalars['String']['output']
  textContentMaxWidthDesktop: Maybe<Scalars['Int']['output']>
  textContentMaxWidthMobile: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
  titleImageDesktop: Maybe<Scalars['String']['output']>
  titleImageMobile: Maybe<Scalars['String']['output']>
}

export type VisualFilterTile = {
  __typename?: 'VisualFilterTile'
  ctaText: Scalars['String']['output']
  description: Maybe<Scalars['String']['output']>
  filter: Scalars['String']['output']
  filterSpec: Maybe<ProductFilterSpec>
  id: Scalars['ID']['output']
  image: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type VisualFiltersSection = {
  __typename?: 'VisualFiltersSection'
  entryCardDescription: Maybe<Scalars['String']['output']>
  entryCardHeader: Maybe<Scalars['String']['output']>
  hideOnDesktop: Scalars['Boolean']['output']
  hideOnMobile: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isCoreStylesVariant: Scalars['Boolean']['output']
  layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
  layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
  tiles: Array<VisualFilterTile>
}

export type VisualFiltersSectionLayoutVariantDesktop = 'carousel' | 'grid'

export type VisualFiltersSectionLayoutVariantMobile = 'carousel' | 'grid' | 'slider'

export type GetCollectionViewForSitemapQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCollectionViewForSitemapQuery = {
  __typename?: 'Query'
  collectionViewIndex: Array<{
    __typename?: 'CollectionView'
    handle: string
    noindex: boolean | null
    sections: Array<
      | {
          __typename?: 'CollectionGridSection'
          collection: { __typename?: 'Collection'; availableForSale: boolean } | null
        }
      | { __typename?: 'CollectionViewHeroSection' }
      | { __typename?: 'GenderNavSection' }
      | { __typename?: 'HeroFullBleedSection' }
      | { __typename?: 'PageTabSection' }
      | { __typename?: 'RichTextSection' }
      | { __typename?: 'SearchGridSection' }
      | { __typename?: 'VideoModalBannerSection' }
      | { __typename?: 'VideoSection' }
    >
  }>
}

export type GetPageViewForSitemapQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetPageViewForSitemapQuery = {
  __typename?: 'Query'
  pageViewIndex: Array<{ __typename?: 'PageView'; handle: string }>
}

export type FetchProductIndexPageQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
}>

export type FetchProductIndexPageQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'ProductConnection'
    nodes: Array<{
      __typename?: 'Product'
      noindex: boolean
      handle: string | null
      color: string | null
      title: string
      updatedAt: any
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{ __typename?: 'ProductAsset'; source: string; color: string | null }>
      } | null
      productGroup: {
        __typename?: 'ProductGroup'
        category: string
        handle: string
        genderCategory: string
        defaultVariant: { __typename?: 'Variant'; availableForSale: boolean } | null
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
  } | null
}

export type GetMainMenuNavQueryVariables = Exact<{ [key: string]: never }>

export type GetMainMenuNavQuery = {
  __typename?: 'Query'
  siteFixtures: {
    __typename?: 'SiteFixtures'
    navigation: {
      __typename?: 'SiteNavigation'
      desktopTabs: Array<{
        __typename?: 'NavTab'
        href: string | null
        id: string
        text: string
        textColor: string | null
      }>
    }
  }
}

export type ReviewsHeaderQueryVariables = Exact<{
  productHandle: Scalars['String']['input']
}>

export type ReviewsHeaderQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    rawFit: string | null
    colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
    productGroup: { __typename?: 'ProductGroup'; handle: string }
  } | null
}

export type ReviewsOverviewProductQueryVariables = Exact<{
  productHandle: Scalars['String']['input']
}>

export type ReviewsOverviewProductQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    title: string
    seoTitle: string | null
    shopifyId: string
    images: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
    } | null
  } | null
}

export type GetShopifyIdFromProductForReviewsQueryQueryVariables = Exact<{
  productHandle: Scalars['String']['input']
}>

export type GetShopifyIdFromProductForReviewsQueryQuery = {
  __typename?: 'Query'
  product: { __typename?: 'Product'; shopifyId: string } | null
}

export type CreateProductReviewMutationVariables = Exact<{
  content: Scalars['String']['input']
  domain: Scalars['String']['input']
  email: Scalars['String']['input']
  handle: Scalars['String']['input']
  name: Scalars['String']['input']
  path: Scalars['String']['input']
  rating: Scalars['Int']['input']
  title: Scalars['String']['input']
}>

export type CreateProductReviewMutation = {
  __typename?: 'Mutation'
  createProductReview: {
    __typename?: 'CreateProductReviewPayload'
    message: string | null
    errors: Array<{ __typename?: 'UserError'; message: string; status: number | null }> | null
  } | null
}

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginUserMutation = {
  __typename?: 'Mutation'
  loginCustomer: {
    __typename?: 'AuthInfo'
    customerAccessToken: string
    refreshToken: string
    token: string
  }
}

export type GetUserAfterSuccessfulLoginQueryVariables = Exact<{ [key: string]: never }>

export type GetUserAfterSuccessfulLoginQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    id: string
    email: string
    shopifyId: string | null
    profile: {
      __typename?: 'CustomerProfile'
      firstName: string | null
      lastName: string | null
      phone: string | null
      ordersCount: number
    }
    portal: { __typename?: 'PortalCG'; name: string | null } | null
  }
}

export type GetUserForRequestQueryVariables = Exact<{ [key: string]: never }>

export type GetUserForRequestQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    id: string
    email: string
    shopifyId: string | null
    profile: {
      __typename?: 'CustomerProfile'
      firstName: string | null
      lastName: string | null
      phone: string | null
      ordersCount: number
    }
  }
}

export type GetAppDirClientDemoProductGroupQueryVariables = Exact<{
  productGroupHandle: Scalars['String']['input']
}>

export type GetAppDirClientDemoProductGroupQuery = {
  __typename?: 'Query'
  productGroup: { __typename?: 'ProductGroup'; category: string } | null
}

export type GetAppDirClientDemoOrdersQueryVariables = Exact<{ [key: string]: never }>

export type GetAppDirClientDemoOrdersQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    orders: {
      __typename?: 'OrderConnection'
      nodes: Array<{ __typename?: 'Order'; status: OrderStatus }>
    }
  }
}

export type GetAppDirServerDemoProductGroupQueryVariables = Exact<{
  productGroupHandle: Scalars['String']['input']
}>

export type GetAppDirServerDemoProductGroupQuery = {
  __typename?: 'Query'
  productGroup: { __typename?: 'ProductGroup'; category: string } | null
}

export type GetAppDirServerDemoOrdersQueryVariables = Exact<{ [key: string]: never }>

export type GetAppDirServerDemoOrdersQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    orders: {
      __typename?: 'OrderConnection'
      nodes: Array<{ __typename?: 'Order'; status: OrderStatus }>
    }
  }
}

export type FetchUpsellProductsQueryVariables = Exact<{
  handles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type FetchUpsellProductsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'ProductConnection'
    nodes: Array<{
      __typename?: 'Product'
      color: string | null
      finalSale: boolean
      rawFit: string | null
      handle: string | null
      swPromoEligible: boolean
      colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
      } | null
      productGroup: {
        __typename?: 'ProductGroup'
        category: string
        description: string | null
        handle: string
        id: string
        sizes: Array<string>
        style: string | null
        title: string
        defaultVariant: {
          __typename?: 'Variant'
          size: string | null
          shopifyId: string
          sku: string | null
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: { __typename?: 'Price'; amount: any | null; currency: string } | null
          } | null
        } | null
      }
      variants: Array<{
        __typename?: 'Variant'
        shopifyId: string
        size: string | null
        sku: string | null
        soldOut: boolean
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      }> | null
    }>
  } | null
}

export type FetchFitModelsQueryVariables = Exact<{
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchFitModelsQuery = {
  __typename?: 'Query'
  fitModels: {
    __typename?: 'FitModelConnection'
    nodes: Array<{
      __typename?: 'FitModel'
      description: string | null
      name: string | null
      height: string | null
      images: Array<string> | null
      pantsSize: string | null
      topSize: string | null
    }>
  } | null
}

export type FetchVariantsBySkuQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >
}>

export type FetchVariantsBySkuQuery = {
  __typename?: 'Query'
  variants: {
    __typename?: 'VariantConnection'
    nodes: Array<{
      __typename?: 'Variant'
      category: string
      color: string | null
      currencyType: string
      discountPrice: any | null
      fit: string | null
      handle: string
      price: any
      shopifyId: string
      size: string | null
      sku: string | null
      soldOut: boolean
      priceDetails: {
        __typename?: 'PriceDetails'
        discountPrice: { __typename?: 'Price'; amount: any | null } | null
        price: {
          __typename?: 'Price'
          currency: string
          amount: any | null
          base: { __typename?: 'Money'; amount: any | null; currency: string } | null
        } | null
      } | null
    }>
  } | null
}

export type SizeChartFieldsFragment = {
  __typename?: 'SizeChartCG'
  columnNames: Array<string> | null
  columnValues: Array<Array<string>> | null
  imageUri: string | null
  name: string
  navLabel: string | null
  title: string
  video: string | null
  tables: Array<{
    __typename?: 'SizeChartTable'
    cells: string
    measureImage: string | null
    subtitle: string | null
    tabLabel: string | null
    title: string | null
    measureTips: Array<{
      __typename?: 'SizeChartTipCG'
      kind: string | null
      tip: string | null
    }> | null
    sizeTips: Array<{
      __typename?: 'SizeChartTipCG'
      kind: string | null
      tip: string | null
    }> | null
  }> | null
  tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
}

export type Fetch_Size_ChartsQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type Fetch_Size_ChartsQuery = {
  __typename?: 'Query'
  siteFixtures: {
    __typename?: 'SiteFixtures'
    mensSizeCharts: Array<{
      __typename?: 'SizeChartCG'
      columnNames: Array<string> | null
      columnValues: Array<Array<string>> | null
      imageUri: string | null
      name: string
      navLabel: string | null
      title: string
      video: string | null
      tables: Array<{
        __typename?: 'SizeChartTable'
        cells: string
        measureImage: string | null
        subtitle: string | null
        tabLabel: string | null
        title: string | null
        measureTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
        sizeTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }> | null
      tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
    }> | null
    womensSizeCharts: Array<{
      __typename?: 'SizeChartCG'
      columnNames: Array<string> | null
      columnValues: Array<Array<string>> | null
      imageUri: string | null
      name: string
      navLabel: string | null
      title: string
      video: string | null
      tables: Array<{
        __typename?: 'SizeChartTable'
        cells: string
        measureImage: string | null
        subtitle: string | null
        tabLabel: string | null
        title: string | null
        measureTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
        sizeTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }> | null
      tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
    }> | null
  }
}

export type ActivateShopUserMutationVariables = Exact<{
  activationUrl: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type ActivateShopUserMutation = {
  __typename?: 'Mutation'
  activateShopUser: {
    __typename?: 'ActivateShopUserPayload'
    authToken: string | null
    shopUser: {
      __typename?: 'ShopUser'
      email: string
      firstName: string
      id: string
      lastName: string
      ordersCount: number
      phone: string | null
      shopifyId: string
    } | null
    errors: Array<{ __typename?: 'UserError'; path: Array<string> | null; message: string }> | null
  } | null
}

export type ActivateCustomerMutationVariables = Exact<{
  activationUrl: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type ActivateCustomerMutation = {
  __typename?: 'Mutation'
  activateCustomer: {
    __typename?: 'AuthInfo'
    customerAccessToken: string
    refreshToken: string
    token: string
  }
}

export type FetchReviewProductGroupQueryVariables = Exact<{
  handle: Scalars['String']['input']
}>

export type FetchReviewProductGroupQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    color: string | null
    handle: string | null
    seoTitle: string | null
    shopifyId: string
    title: string
    images: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
    } | null
    productGroup: { __typename?: 'ProductGroup'; handle: string }
  } | null
}

export type LoginShopUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginShopUserMutation = {
  __typename?: 'Mutation'
  loginShopUser: {
    __typename?: 'LoginShopUserPayload'
    authToken: string | null
    shopToken: string | null
    errors: Array<{ __typename?: 'UserError'; status: number | null; message: string }> | null
    shopUser: {
      __typename?: 'ShopUser'
      shopifyId: string
      firstName: string
      lastName: string
      phone: string | null
      ordersCount: number
    } | null
  } | null
}

export type CreateShopUserMutationVariables = Exact<{
  input: ShopUserInput
}>

export type CreateShopUserMutation = {
  __typename?: 'Mutation'
  createShopUser: {
    __typename?: 'CreateShopUserPayload'
    authToken: string | null
    shopToken: string | null
    errors: Array<{ __typename?: 'UserError'; message: string; status: number | null }> | null
    shopUser: { __typename?: 'ShopUser'; firstName: string; lastName: string; email: string } | null
  } | null
}

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type ForgotPasswordMutation = {
  __typename?: 'Mutation'
  forgotPassword: {
    __typename?: 'ForgotPasswordPayload'
    errors: Array<{ __typename?: 'UserError'; message: string; status: number | null }> | null
    user: { __typename?: 'User'; email: string; firstName: string } | null
  } | null
}

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword: {
    __typename?: 'ResetPasswordPayload'
    user: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
    } | null
    errors: Array<{
      __typename?: 'UserError'
      message: string
      path: Array<string> | null
      status: number | null
    }> | null
  } | null
}

export type CustomerForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type CustomerForgotPasswordMutation = {
  __typename?: 'Mutation'
  customerForgotPassword: boolean
}

export type CustomerResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type CustomerResetPasswordMutation = {
  __typename?: 'Mutation'
  customerResetPassword: boolean
}

export type ChangeCustomerPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}>

export type ChangeCustomerPasswordMutation = {
  __typename?: 'Mutation'
  customerChangePassword: boolean
}

export type LoginCustomerMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginCustomerMutation = {
  __typename?: 'Mutation'
  loginCustomer: {
    __typename?: 'AuthInfo'
    customerAccessToken: string
    refreshToken: string
    token: string
  }
}

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput
}>

export type CreateCustomerMutation = {
  __typename?: 'Mutation'
  createCustomer: {
    __typename?: 'AuthInfo'
    customerAccessToken: string
    refreshToken: string
    token: string
  }
}

export type GetNewTokensMutationVariables = Exact<{
  refreshToken: Scalars['String']['input']
}>

export type GetNewTokensMutation = {
  __typename?: 'Mutation'
  customerTokenRefresh: { __typename?: 'AuthInfo'; token: string; refreshToken: string }
}

export type UpdateCustomerProfileMutationVariables = Exact<{
  customerProfileData: UpdateCustomerProfileInput
}>

export type UpdateCustomerProfileMutation = {
  __typename?: 'Mutation'
  updateCustomerProfile: { __typename?: 'CustomerProfile'; id: string }
}

export type CreateCustomerAddressMutationVariables = Exact<{
  addressData: AddressInput
}>

export type CreateCustomerAddressMutation = {
  __typename?: 'Mutation'
  createCustomerAddress: { __typename?: 'Address'; shopifyId: string }
}

export type DeleteCustomerAddressMutationVariables = Exact<{
  shopifyId: Scalars['String']['input']
}>

export type DeleteCustomerAddressMutation = {
  __typename?: 'Mutation'
  deleteCustomerAddress: {
    __typename?: 'DeleteCustomerAddressResult'
    deletedCustomerAddressId: string
  }
}

export type UpdateCustomerAddressMutationVariables = Exact<{
  shopifyId: Scalars['String']['input']
  addressData: AddressInput
}>

export type UpdateCustomerAddressMutation = {
  __typename?: 'Mutation'
  updateCustomerAddress: { __typename?: 'Address'; shopifyId: string }
}

export type SetDefaultCustomerAddressMutationVariables = Exact<{
  shopifyId: Scalars['String']['input']
}>

export type SetDefaultCustomerAddressMutation = {
  __typename?: 'Mutation'
  setDefaultCustomerAddress: { __typename?: 'Address'; shopifyId: string }
}

export type CreateIdmeShopifyDiscountCodeMutationVariables = Exact<{
  authCode: Scalars['String']['input']
  source: Scalars['String']['input']
}>

export type CreateIdmeShopifyDiscountCodeMutation = {
  __typename?: 'Mutation'
  createIdmeShopifyDiscountCode: string
}

export type VerifyDiscountCodeMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type VerifyDiscountCodeMutation = {
  __typename?: 'Mutation'
  verifyDiscountCode: { __typename?: 'GiftingVerifyDiscountCodeResponse'; valid: boolean }
}

export type InitateOrderCancellationMutationVariables = Exact<{
  id: Scalars['String']['input']
  email: Scalars['String']['input']
  name: Scalars['String']['input']
}>

export type InitateOrderCancellationMutation = {
  __typename?: 'Mutation'
  initateGuestOrderCancellation: string
}

export type SubscribeUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  gender?: InputMaybe<Scalars['String']['input']>
  registrationSource?: InputMaybe<Scalars['String']['input']>
}>

export type SubscribeUserMutation = {
  __typename?: 'Mutation'
  subscribeUser: {
    __typename?: 'SubscribeUserPayload'
    result: any | null
    errors: any | null
  } | null
}

export type CollectionGridSectionFragmentFragment = {
  __typename: 'CollectionGridSection'
  id: string
  itemColorScope: ProductGroupColorSelector
  groupedProductTiles: boolean
  filtersEnabled: boolean
  filterConfig: {
    __typename: 'FilterConfig'
    filters: Array<{
      __typename: 'CollectionFilter'
      name: string
      displayName: string
      crawlable: boolean
      displayType: string
    }>
  } | null
  editorializedCollections: Array<{
    __typename?: 'EditorializedCollection'
    id: string
    handle: string
    title: string | null
    subtitle: string | null
    editorialCellPlacements: Array<{
      __typename: 'EditorialCellPlacement'
      id: string
      alignmentDesktop: string | null
      rowsToSkipDesktop: number | null
      rowsToSkipMobile: number | null
      cellWidth: number | null
      pinToBottom: string | null
      editorialCell: {
        __typename: 'EditorialCell'
        id: string
        title: string | null
        subtitle: string | null
        textColor: string
        textAlignment: EditorialCellTextAlignment | null
        titleAlignment: PageSectionTitleAlignment | null
        titleSize: string | null
        description: string | null
        descriptionSize: string | null
        descriptionByLine: string | null
        reviewStars: boolean | null
        descriptionPadding: number | null
        descriptionPlacement: string | null
        descriptionAlignment: EditorialCellDescriptionAlignment | null
        descriptionColor: string | null
        ctaText: string | null
        ctaAlignment: EditorialCellCtaAlignment | null
        linkHref: string | null
        modalVideoPlatform: PageSectionModalVideoPlatform | null
        modalVideoId: string | null
        modalVideoIdMobile: string | null
        image: string | null
        imageAspectRatio: number | null
        imageMobile: string | null
        imageMobileAspectRatio: number | null
        inlineVideo: string | null
        inlineVimeoId: number | null
        inlineVimeoIdMobile: number | null
        backgroundColor: string | null
        eyebrow: string | null
      } | null
    }>
    editorialRows: Array<{
      __typename: 'VideoSection'
      buttonVariant: string | null
      id: string
      imageDesktop: string | null
      imageMobile: string | null
      inlineVideoDesktop: string | null
      inlineVideoMobile: string | null
      inlineVimeoIdDesktop: number | null
      inlineVimeoIdMobile: number | null
      linkHref1: string | null
      linkHref2: string | null
      linkText1: string | null
      linkText2: string | null
      modalVideoId: string | null
      modalVideoPlatform: PageSectionModalVideoPlatform | null
      subtitle: string | null
      textColor: string
      textContentMaxWidthDesktop: number | null
      textContentMaxWidthMobile: number | null
      title: string | null
      titleImageDesktop: string | null
      titleImageMobile: string | null
    }>
  }>
  visualFiltersSection: {
    __typename: 'VisualFiltersSection'
    id: string
    layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
    layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
    hideOnMobile: boolean
    hideOnDesktop: boolean
    isCoreStylesVariant: boolean
    entryCardHeader: string | null
    entryCardDescription: string | null
    tiles: Array<{
      __typename: 'VisualFilterTile'
      id: string
      ctaText: string
      value: string
      filter: string
      image: string
      description: string | null
      filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
    }>
  } | null
  visualFiltersSectionV2: {
    __typename: 'VisualFiltersSection'
    id: string
    layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
    layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
    hideOnMobile: boolean
    hideOnDesktop: boolean
    isCoreStylesVariant: boolean
    entryCardHeader: string | null
    entryCardDescription: string | null
    tiles: Array<{
      __typename: 'VisualFilterTile'
      id: string
      ctaText: string
      value: string
      filter: string
      image: string
      description: string | null
      filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
    }>
  } | null
}

export type CollectionItemsConnectionFragment = {
  __typename?: 'ProductConnection'
  numItems: number
  pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
}

export type CollectionItemProductFragment = {
  __typename?: 'Product'
  id: string
  category: string
  rawCategory: string
  collectionBadgeColor: string | null
  collectionBadgeText: string | null
  iconBadge: any | null
  iconBadgePlacement: string | null
  isKit: boolean
  handle: string | null
  shopifyId: string
  availableFits: Array<string>
  rawAvailableFits: Array<string>
  waitlistable: boolean
  swPromoEligible: boolean
  priceRange: any | null
  finalSale: boolean
  colorInfo: {
    __typename?: 'ProductColor'
    availability: string
    hexCodes: Array<string> | null
    name: string
    rawName: string
    swatchUrl: string | null
  } | null
  defaultVariant: {
    __typename?: 'Variant'
    discountPriceExplicitFmt: string
    priceExplicitFmt: string
    shopifyId: string
    sku: string | null
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  }
  images: {
    __typename?: 'ProductAssetConnection'
    nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
  } | null
  productGroup: {
    __typename?: 'ProductGroup'
    collectionHandle: string | null
    description: string | null
    fits: Array<string>
    handle: string
    iconBadge: any | null
    iconBadgePlacement: string | null
    numColors: number
    rawFits: Array<string>
    style: string | null
    title: string
    priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
  }
}

export type CollectionLayoutFragmentFragment = {
  __typename?: 'CollectionView'
  id: string
  handle: string
  name: string
  noindex: boolean | null
  seoContent: any | null
  socialDescription: string | null
  socialImage: string | null
  sections: Array<
    | {
        __typename: 'CollectionGridSection'
        id: string
        itemColorScope: ProductGroupColorSelector
        groupedProductTiles: boolean
        filtersEnabled: boolean
        filterConfig: {
          __typename: 'FilterConfig'
          filters: Array<{
            __typename: 'CollectionFilter'
            name: string
            displayName: string
            crawlable: boolean
            displayType: string
          }>
        } | null
        editorializedCollections: Array<{
          __typename?: 'EditorializedCollection'
          id: string
          handle: string
          title: string | null
          subtitle: string | null
          editorialCellPlacements: Array<{
            __typename: 'EditorialCellPlacement'
            id: string
            alignmentDesktop: string | null
            rowsToSkipDesktop: number | null
            rowsToSkipMobile: number | null
            cellWidth: number | null
            pinToBottom: string | null
            editorialCell: {
              __typename: 'EditorialCell'
              id: string
              title: string | null
              subtitle: string | null
              textColor: string
              textAlignment: EditorialCellTextAlignment | null
              titleAlignment: PageSectionTitleAlignment | null
              titleSize: string | null
              description: string | null
              descriptionSize: string | null
              descriptionByLine: string | null
              reviewStars: boolean | null
              descriptionPadding: number | null
              descriptionPlacement: string | null
              descriptionAlignment: EditorialCellDescriptionAlignment | null
              descriptionColor: string | null
              ctaText: string | null
              ctaAlignment: EditorialCellCtaAlignment | null
              linkHref: string | null
              modalVideoPlatform: PageSectionModalVideoPlatform | null
              modalVideoId: string | null
              modalVideoIdMobile: string | null
              image: string | null
              imageAspectRatio: number | null
              imageMobile: string | null
              imageMobileAspectRatio: number | null
              inlineVideo: string | null
              inlineVimeoId: number | null
              inlineVimeoIdMobile: number | null
              backgroundColor: string | null
              eyebrow: string | null
            } | null
          }>
          editorialRows: Array<{
            __typename: 'VideoSection'
            buttonVariant: string | null
            id: string
            imageDesktop: string | null
            imageMobile: string | null
            inlineVideoDesktop: string | null
            inlineVideoMobile: string | null
            inlineVimeoIdDesktop: number | null
            inlineVimeoIdMobile: number | null
            linkHref1: string | null
            linkHref2: string | null
            linkText1: string | null
            linkText2: string | null
            modalVideoId: string | null
            modalVideoPlatform: PageSectionModalVideoPlatform | null
            subtitle: string | null
            textColor: string
            textContentMaxWidthDesktop: number | null
            textContentMaxWidthMobile: number | null
            title: string | null
            titleImageDesktop: string | null
            titleImageMobile: string | null
          }>
        }>
        visualFiltersSection: {
          __typename: 'VisualFiltersSection'
          id: string
          layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
          layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
          hideOnMobile: boolean
          hideOnDesktop: boolean
          isCoreStylesVariant: boolean
          entryCardHeader: string | null
          entryCardDescription: string | null
          tiles: Array<{
            __typename: 'VisualFilterTile'
            id: string
            ctaText: string
            value: string
            filter: string
            image: string
            description: string | null
            filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
          }>
        } | null
        visualFiltersSectionV2: {
          __typename: 'VisualFiltersSection'
          id: string
          layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
          layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
          hideOnMobile: boolean
          hideOnDesktop: boolean
          isCoreStylesVariant: boolean
          entryCardHeader: string | null
          entryCardDescription: string | null
          tiles: Array<{
            __typename: 'VisualFilterTile'
            id: string
            ctaText: string
            value: string
            filter: string
            image: string
            description: string | null
            filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
          }>
        } | null
      }
    | {
        __typename: 'CollectionViewHeroSection'
        id: string
        backgroundColor: string
        imageDesktop: string | null
        imageMobile: string | null
        imagePlacement: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        subtitle: string | null
        textColor: string
        title: string | null
        videoId: string | null
        videoPlatform: string | null
      }
    | {
        __typename: 'GenderNavSection'
        id: string
        alternateGenderHandle: string
        selectedGender: string
      }
    | {
        __typename: 'HeroFullBleedSection'
        id: string
        description: string | null
        imageDesktop: string | null
        imageMobile: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        subtitle: string | null
        textColor: string
        title: string | null
        titleImage: string | null
        titleImageMobile: string | null
      }
    | {
        __typename: 'PageTabSection'
        id: string
        hideOnMobile: boolean
        hideOnDesktop: boolean
        title: string | null
        tabs: Array<{
          __typename?: 'PageTab'
          id: string
          text: string
          textColor: string | null
          tabSections: Array<
            | {
                __typename: 'PageTabTileSection'
                id: string
                layoutVariantMobile: string
                layoutVariantDesktop: string
                tileVariantMobile: string
                tileVariantDesktop: string
                tiles: Array<{
                  __typename?: 'PageTabTileSectionTile'
                  id: string
                  ctaText: string
                  href: string
                  image: string
                }>
              }
            | { __typename: 'RawHtmlSection'; id: string; html: string }
          >
        }>
      }
    | { __typename: 'RichTextSection' }
    | { __typename: 'SearchGridSection' }
    | {
        __typename: 'VideoModalBannerSection'
        id: string
        body: string
        ctaText: string
        heading: string
        image: string | null
        inlineVideo: string | null
        inlineVimeoId: number | null
        linkHref: string | null
        videoId: string | null
        videoPlatform: string | null
      }
    | {
        __typename: 'VideoSection'
        buttonVariant: string | null
        id: string
        imageDesktop: string | null
        imageMobile: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        linkHref1: string | null
        linkHref2: string | null
        linkText1: string | null
        linkText2: string | null
        modalVideoId: string | null
        modalVideoPlatform: PageSectionModalVideoPlatform | null
        subtitle: string | null
        textColor: string
        textContentMaxWidthDesktop: number | null
        textContentMaxWidthMobile: number | null
        title: string | null
        titleImageDesktop: string | null
        titleImageMobile: string | null
      }
  >
}

export type CollectionPageQueryOptimizedQueryVariables = Exact<{
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
  withPortal?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CollectionPageQueryOptimizedQuery = {
  __typename?: 'Query'
  collectionView: {
    __typename?: 'CollectionView'
    id: string
    handle: string
    name: string
    noindex: boolean | null
    seoContent: any | null
    socialDescription: string | null
    socialImage: string | null
    sections: Array<
      | {
          __typename: 'CollectionGridSection'
          id: string
          itemColorScope: ProductGroupColorSelector
          groupedProductTiles: boolean
          filtersEnabled: boolean
          filterConfig: {
            __typename: 'FilterConfig'
            filters: Array<{
              __typename: 'CollectionFilter'
              name: string
              displayName: string
              crawlable: boolean
              displayType: string
            }>
          } | null
          editorializedCollections: Array<{
            __typename?: 'EditorializedCollection'
            id: string
            handle: string
            title: string | null
            subtitle: string | null
            editorialCellPlacements: Array<{
              __typename: 'EditorialCellPlacement'
              id: string
              alignmentDesktop: string | null
              rowsToSkipDesktop: number | null
              rowsToSkipMobile: number | null
              cellWidth: number | null
              pinToBottom: string | null
              editorialCell: {
                __typename: 'EditorialCell'
                id: string
                title: string | null
                subtitle: string | null
                textColor: string
                textAlignment: EditorialCellTextAlignment | null
                titleAlignment: PageSectionTitleAlignment | null
                titleSize: string | null
                description: string | null
                descriptionSize: string | null
                descriptionByLine: string | null
                reviewStars: boolean | null
                descriptionPadding: number | null
                descriptionPlacement: string | null
                descriptionAlignment: EditorialCellDescriptionAlignment | null
                descriptionColor: string | null
                ctaText: string | null
                ctaAlignment: EditorialCellCtaAlignment | null
                linkHref: string | null
                modalVideoPlatform: PageSectionModalVideoPlatform | null
                modalVideoId: string | null
                modalVideoIdMobile: string | null
                image: string | null
                imageAspectRatio: number | null
                imageMobile: string | null
                imageMobileAspectRatio: number | null
                inlineVideo: string | null
                inlineVimeoId: number | null
                inlineVimeoIdMobile: number | null
                backgroundColor: string | null
                eyebrow: string | null
              } | null
            }>
            editorialRows: Array<{
              __typename: 'VideoSection'
              buttonVariant: string | null
              id: string
              imageDesktop: string | null
              imageMobile: string | null
              inlineVideoDesktop: string | null
              inlineVideoMobile: string | null
              inlineVimeoIdDesktop: number | null
              inlineVimeoIdMobile: number | null
              linkHref1: string | null
              linkHref2: string | null
              linkText1: string | null
              linkText2: string | null
              modalVideoId: string | null
              modalVideoPlatform: PageSectionModalVideoPlatform | null
              subtitle: string | null
              textColor: string
              textContentMaxWidthDesktop: number | null
              textContentMaxWidthMobile: number | null
              title: string | null
              titleImageDesktop: string | null
              titleImageMobile: string | null
            }>
          }>
          visualFiltersSection: {
            __typename: 'VisualFiltersSection'
            id: string
            layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
            layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
            hideOnMobile: boolean
            hideOnDesktop: boolean
            isCoreStylesVariant: boolean
            entryCardHeader: string | null
            entryCardDescription: string | null
            tiles: Array<{
              __typename: 'VisualFilterTile'
              id: string
              ctaText: string
              value: string
              filter: string
              image: string
              description: string | null
              filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
            }>
          } | null
          visualFiltersSectionV2: {
            __typename: 'VisualFiltersSection'
            id: string
            layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
            layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
            hideOnMobile: boolean
            hideOnDesktop: boolean
            isCoreStylesVariant: boolean
            entryCardHeader: string | null
            entryCardDescription: string | null
            tiles: Array<{
              __typename: 'VisualFilterTile'
              id: string
              ctaText: string
              value: string
              filter: string
              image: string
              description: string | null
              filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
            }>
          } | null
        }
      | {
          __typename: 'CollectionViewHeroSection'
          id: string
          backgroundColor: string
          imageDesktop: string | null
          imageMobile: string | null
          imagePlacement: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          subtitle: string | null
          textColor: string
          title: string | null
          videoId: string | null
          videoPlatform: string | null
        }
      | {
          __typename: 'GenderNavSection'
          id: string
          alternateGenderHandle: string
          selectedGender: string
        }
      | {
          __typename: 'HeroFullBleedSection'
          id: string
          description: string | null
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          subtitle: string | null
          textColor: string
          title: string | null
          titleImage: string | null
          titleImageMobile: string | null
        }
      | {
          __typename: 'PageTabSection'
          id: string
          hideOnMobile: boolean
          hideOnDesktop: boolean
          title: string | null
          tabs: Array<{
            __typename?: 'PageTab'
            id: string
            text: string
            textColor: string | null
            tabSections: Array<
              | {
                  __typename: 'PageTabTileSection'
                  id: string
                  layoutVariantMobile: string
                  layoutVariantDesktop: string
                  tileVariantMobile: string
                  tileVariantDesktop: string
                  tiles: Array<{
                    __typename?: 'PageTabTileSectionTile'
                    id: string
                    ctaText: string
                    href: string
                    image: string
                  }>
                }
              | { __typename: 'RawHtmlSection'; id: string; html: string }
            >
          }>
        }
      | { __typename: 'RichTextSection' }
      | { __typename: 'SearchGridSection' }
      | {
          __typename: 'VideoModalBannerSection'
          id: string
          body: string
          ctaText: string
          heading: string
          image: string | null
          inlineVideo: string | null
          inlineVimeoId: number | null
          linkHref: string | null
          videoId: string | null
          videoPlatform: string | null
        }
      | {
          __typename: 'VideoSection'
          buttonVariant: string | null
          id: string
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          modalVideoId: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          subtitle: string | null
          textColor: string
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          title: string | null
          titleImageDesktop: string | null
          titleImageMobile: string | null
        }
    >
  } | null
  portal?: { __typename?: 'PortalCG'; enableSSO: boolean; groupTag: string | null } | null
}

export type CollectionProductFilterFragment = {
  __typename?: 'ProductFilter'
  key: string
  crawlable: boolean
  displayName: string
  displayType: string
  filterValues: Array<{ __typename?: 'FilterValue'; state: FilterValueState; value: string }>
}

export type CollectionProductGroupFragmentFragment = {
  __typename?: 'ProductGroup'
  handle: string
  category: string
  collectionHandle: string | null
  description: string | null
  fits: Array<string>
  sizes: Array<string>
  style: string | null
  title: string
  rawCategory: string
  colors: Array<{
    __typename?: 'ProductColor'
    availability: string
    handle: string
    hexCodes: Array<string> | null
    name: string
  }>
  defaultVariant: {
    __typename?: 'Variant'
    color: string | null
    shopifyId: string
    sku: string | null
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  } | null
  products: Array<{
    __typename?: 'Product'
    id: string
    availableFits: Array<string>
    availableSizes: Array<string>
    category: string
    color: string | null
    finalSale: boolean
    fit: string | null
    handle: string | null
    isKit: boolean
    priceRange: any | null
    rawAvailableFits: Array<string>
    rawCategory: string
    rawFit: string | null
    swPromoEligible: boolean
    waitlistable: boolean
    colorInfo: {
      __typename?: 'ProductColor'
      availability: string
      hexCodes: Array<string> | null
      name: string
    } | null
    defaultVariant: {
      __typename?: 'Variant'
      color: string | null
      shopifyId: string
      sku: string | null
      priceDetails: {
        __typename?: 'PriceDetails'
        discountPrice: { __typename?: 'Price'; amount: any | null } | null
        price: {
          __typename?: 'Price'
          currency: string
          amount: any | null
          base: { __typename?: 'Money'; amount: any | null; currency: string } | null
        } | null
      } | null
    }
    images: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{
        __typename?: 'ProductAsset'
        color: string | null
        fit: string | null
        index: number
        kind: string | null
        source: string
      }>
    } | null
  }>
}

export type CollectionViewHeroSectionFragmentFragment = {
  __typename?: 'CollectionViewHeroSection'
  id: string
  backgroundColor: string
  imageDesktop: string | null
  imageMobile: string | null
  imagePlacement: string | null
  inlineVideoDesktop: string | null
  inlineVideoMobile: string | null
  inlineVimeoIdDesktop: number | null
  inlineVimeoIdMobile: number | null
  subtitle: string | null
  textColor: string
  title: string | null
  videoId: string | null
  videoPlatform: string | null
}

export type ColorFragment = {
  __typename?: 'ProductColor'
  availability: string
  hexCodes: Array<string> | null
  name: string
  rawName: string
  swatchUrl: string | null
}

export type ColorGroupFragment = {
  __typename?: 'ColorGroup'
  label: string
  colors: Array<{
    __typename?: 'ProductColor'
    accentColor: string | null
    availability: string
    handle: string
    hexCodes: Array<string> | null
    name: string
    rawName: string
    swatchUrl: string | null
  }>
}

export type EditorialCellPlacementFragmentFragment = {
  __typename: 'EditorialCellPlacement'
  id: string
  alignmentDesktop: string | null
  rowsToSkipDesktop: number | null
  rowsToSkipMobile: number | null
  cellWidth: number | null
  pinToBottom: string | null
  editorialCell: {
    __typename: 'EditorialCell'
    id: string
    title: string | null
    subtitle: string | null
    textColor: string
    textAlignment: EditorialCellTextAlignment | null
    titleAlignment: PageSectionTitleAlignment | null
    titleSize: string | null
    description: string | null
    descriptionSize: string | null
    descriptionByLine: string | null
    reviewStars: boolean | null
    descriptionPadding: number | null
    descriptionPlacement: string | null
    descriptionAlignment: EditorialCellDescriptionAlignment | null
    descriptionColor: string | null
    ctaText: string | null
    ctaAlignment: EditorialCellCtaAlignment | null
    linkHref: string | null
    modalVideoPlatform: PageSectionModalVideoPlatform | null
    modalVideoId: string | null
    modalVideoIdMobile: string | null
    image: string | null
    imageAspectRatio: number | null
    imageMobile: string | null
    imageMobileAspectRatio: number | null
    inlineVideo: string | null
    inlineVimeoId: number | null
    inlineVimeoIdMobile: number | null
    backgroundColor: string | null
    eyebrow: string | null
  } | null
}

export type FetchEmbroideryIconGroupsQueryVariables = Exact<{
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchEmbroideryIconGroupsQuery = {
  __typename?: 'Query'
  embroideryIconGroups: Array<{
    __typename?: 'EmbroideryIconGroup'
    id: string
    name: string
    displayName: string
    sortOrder: number | null
    maxIconsPerRowMobile: number | null
    disabledInitialsText: string | null
  }>
}

export type FavoriteTileProductGroupFragment = {
  __typename?: 'ProductGroup'
  collectionHandle: string | null
  description: string | null
  fits: Array<string>
  handle: string
  iconBadge: any | null
  iconBadgePlacement: string | null
  id: string
  sizes: Array<string>
  style: string | null
  defaultVariant: {
    __typename?: 'Variant'
    color: string | null
    shopifyId: string
    sku: string | null
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  } | null
  products: Array<{
    __typename?: 'Product'
    availableSizes: Array<string>
    collectionBadgeColor: string | null
    collectionBadgeText: string | null
    color: string | null
    finalSale: boolean
    fit: string | null
    handle: string | null
    iconBadge: any | null
    iconBadgePlacement: string | null
    priceRange: any | null
    swPromoEligible: boolean
    waitlistable: boolean
    colorInfo: {
      __typename?: 'ProductColor'
      availability: string
      hexCodes: Array<string> | null
      name: string
    } | null
    images: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{
        __typename?: 'ProductAsset'
        color: string | null
        fit: string | null
        index: number
        kind: string | null
        source: string
      }>
    } | null
    variants: Array<{
      __typename?: 'Variant'
      category: string
      color: string | null
      currencyType: string
      discountPrice: any | null
      fit: string | null
      handle: string
      price: any
      priceInfo: any | null
      shopifyId: string
      size: string | null
      sku: string | null
      soldOut: boolean
      priceDetails: {
        __typename?: 'PriceDetails'
        discountPrice: { __typename?: 'Price'; amount: any | null } | null
        price: {
          __typename?: 'Price'
          currency: string
          amount: any | null
          base: { __typename?: 'Money'; amount: any | null; currency: string } | null
        } | null
      } | null
    }> | null
  }>
  sizeChart: {
    __typename?: 'SizeChartCG'
    columnNames: Array<string> | null
    columnValues: Array<Array<string>> | null
    imageUri: string | null
    name: string
    navLabel: string | null
    title: string
    video: string | null
    tables: Array<{
      __typename?: 'SizeChartTable'
      cells: string
      measureImage: string | null
      subtitle: string | null
      tabLabel: string | null
      title: string | null
      measureTips: Array<{
        __typename?: 'SizeChartTipCG'
        kind: string | null
        tip: string | null
      }> | null
      sizeTips: Array<{
        __typename?: 'SizeChartTipCG'
        kind: string | null
        tip: string | null
      }> | null
    }> | null
    tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
  }
  variants: Array<{
    __typename?: 'Variant'
    category: string
    color: string | null
    currencyType: string
    discountPrice: any | null
    fit: string | null
    handle: string
    price: any
    priceInfo: any | null
    shopifyId: string
    size: string | null
    sku: string | null
    soldOut: boolean
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  }> | null
}

export type FeaturedProductFragment = {
  __typename?: 'Product'
  availableFits: Array<string>
  rawAvailableFits: Array<string>
  availableSizes: Array<string>
  category: string
  collectionBadgeColor: string | null
  collectionBadgeText: string | null
  color: string | null
  finalSale: boolean
  fit: string | null
  rawFit: string | null
  handle: string | null
  iconBadge: any | null
  iconBadgePlacement: string | null
  priceRange: any | null
  shopifyId: string
  swPromoEligible: boolean
  waitlistable: boolean
  colorInfo: {
    __typename?: 'ProductColor'
    availability: string
    hexCodes: Array<string> | null
    name: string
    rawName: string
  } | null
  images: {
    __typename?: 'ProductAssetConnection'
    nodes: Array<{
      __typename?: 'ProductAsset'
      index: number
      kind: string | null
      source: string
    }>
  } | null
  variants: Array<{
    __typename?: 'Variant'
    currencyType: string
    discountPriceExplicitFmt: string
    priceExplicitFmt: string
    shopifyId: string
    sku: string | null
    size: string | null
    soldOut: boolean
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  }> | null
}

export type GetVariantsBySkusQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >
}>

export type GetVariantsBySkusQuery = {
  __typename?: 'Query'
  variants: {
    __typename?: 'VariantConnection'
    nodes: Array<{
      __typename?: 'Variant'
      category: string
      color: string | null
      discountPrice: any | null
      externalParentId: string
      fit: string | null
      price: any
      shopifyId: string
      sku: string | null
      size: string | null
      style: string | null
      image: { __typename?: 'ProductAsset'; source: string } | null
      priceDetails: {
        __typename?: 'PriceDetails'
        discountPrice: { __typename?: 'Price'; amount: any | null } | null
        price: {
          __typename?: 'Price'
          currency: string
          amount: any | null
          base: { __typename?: 'Money'; amount: any | null; currency: string } | null
        } | null
      } | null
      product: {
        __typename?: 'Product'
        availableForSale: boolean
        finalSale: boolean
        handle: string | null
      }
      productGroup: {
        __typename?: 'ProductGroup'
        genderCategory: string
        style: string | null
        handle: string
        title: string
        description: string | null
        embroiderable: boolean
        embroideryImages: Array<{
          __typename?: 'ProductAsset'
          color: string | null
          embroideryOffsets: any | null
          fit: string | null
          index: number
          source: string
          kind: string | null
          type: string | null
        }> | null
        colorGroups: Array<{
          __typename?: 'ColorGroup'
          label: string
          colors: Array<{
            __typename?: 'ProductColor'
            accentColor: string | null
            availability: string
            handle: string
            hexCodes: Array<string> | null
            name: string
            rawName: string
            swatchUrl: string | null
          }>
        }>
      }
    }>
  } | null
}

export type GetCollectionViewQueryVariables = Exact<{
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
  withPortal?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCollectionViewQuery = {
  __typename?: 'Query'
  collectionView: {
    __typename?: 'CollectionView'
    id: string
    handle: string
    name: string
    noindex: boolean | null
    seoContent: any | null
    socialDescription: string | null
    socialImage: string | null
    sections: Array<
      | {
          __typename: 'CollectionGridSection'
          id: string
          itemColorScope: ProductGroupColorSelector
          groupedProductTiles: boolean
          filtersEnabled: boolean
          filterConfig: {
            __typename: 'FilterConfig'
            filters: Array<{
              __typename: 'CollectionFilter'
              name: string
              displayName: string
              crawlable: boolean
              displayType: string
            }>
          } | null
          editorializedCollections: Array<{
            __typename?: 'EditorializedCollection'
            id: string
            handle: string
            title: string | null
            subtitle: string | null
            editorialCellPlacements: Array<{
              __typename: 'EditorialCellPlacement'
              id: string
              alignmentDesktop: string | null
              rowsToSkipDesktop: number | null
              rowsToSkipMobile: number | null
              cellWidth: number | null
              pinToBottom: string | null
              editorialCell: {
                __typename: 'EditorialCell'
                id: string
                title: string | null
                subtitle: string | null
                textColor: string
                textAlignment: EditorialCellTextAlignment | null
                titleAlignment: PageSectionTitleAlignment | null
                titleSize: string | null
                description: string | null
                descriptionSize: string | null
                descriptionByLine: string | null
                reviewStars: boolean | null
                descriptionPadding: number | null
                descriptionPlacement: string | null
                descriptionAlignment: EditorialCellDescriptionAlignment | null
                descriptionColor: string | null
                ctaText: string | null
                ctaAlignment: EditorialCellCtaAlignment | null
                linkHref: string | null
                modalVideoPlatform: PageSectionModalVideoPlatform | null
                modalVideoId: string | null
                modalVideoIdMobile: string | null
                image: string | null
                imageAspectRatio: number | null
                imageMobile: string | null
                imageMobileAspectRatio: number | null
                inlineVideo: string | null
                inlineVimeoId: number | null
                inlineVimeoIdMobile: number | null
                backgroundColor: string | null
                eyebrow: string | null
              } | null
            }>
            editorialRows: Array<{
              __typename: 'VideoSection'
              buttonVariant: string | null
              id: string
              imageDesktop: string | null
              imageMobile: string | null
              inlineVideoDesktop: string | null
              inlineVideoMobile: string | null
              inlineVimeoIdDesktop: number | null
              inlineVimeoIdMobile: number | null
              linkHref1: string | null
              linkHref2: string | null
              linkText1: string | null
              linkText2: string | null
              modalVideoId: string | null
              modalVideoPlatform: PageSectionModalVideoPlatform | null
              subtitle: string | null
              textColor: string
              textContentMaxWidthDesktop: number | null
              textContentMaxWidthMobile: number | null
              title: string | null
              titleImageDesktop: string | null
              titleImageMobile: string | null
            }>
          }>
          visualFiltersSection: {
            __typename: 'VisualFiltersSection'
            id: string
            layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
            layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
            hideOnMobile: boolean
            hideOnDesktop: boolean
            isCoreStylesVariant: boolean
            entryCardHeader: string | null
            entryCardDescription: string | null
            tiles: Array<{
              __typename: 'VisualFilterTile'
              id: string
              ctaText: string
              value: string
              filter: string
              image: string
              description: string | null
              filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
            }>
          } | null
          visualFiltersSectionV2: {
            __typename: 'VisualFiltersSection'
            id: string
            layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
            layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
            hideOnMobile: boolean
            hideOnDesktop: boolean
            isCoreStylesVariant: boolean
            entryCardHeader: string | null
            entryCardDescription: string | null
            tiles: Array<{
              __typename: 'VisualFilterTile'
              id: string
              ctaText: string
              value: string
              filter: string
              image: string
              description: string | null
              filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
            }>
          } | null
        }
      | {
          __typename: 'CollectionViewHeroSection'
          id: string
          backgroundColor: string
          imageDesktop: string | null
          imageMobile: string | null
          imagePlacement: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          subtitle: string | null
          textColor: string
          title: string | null
          videoId: string | null
          videoPlatform: string | null
        }
      | {
          __typename: 'GenderNavSection'
          id: string
          alternateGenderHandle: string
          selectedGender: string
        }
      | {
          __typename: 'HeroFullBleedSection'
          id: string
          description: string | null
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          subtitle: string | null
          textColor: string
          title: string | null
          titleImage: string | null
          titleImageMobile: string | null
        }
      | {
          __typename: 'PageTabSection'
          id: string
          hideOnMobile: boolean
          hideOnDesktop: boolean
          title: string | null
          tabs: Array<{
            __typename?: 'PageTab'
            id: string
            text: string
            textColor: string | null
            tabSections: Array<
              | {
                  __typename: 'PageTabTileSection'
                  id: string
                  layoutVariantMobile: string
                  layoutVariantDesktop: string
                  tileVariantMobile: string
                  tileVariantDesktop: string
                  tiles: Array<{
                    __typename?: 'PageTabTileSectionTile'
                    id: string
                    ctaText: string
                    href: string
                    image: string
                  }>
                }
              | { __typename: 'RawHtmlSection'; id: string; html: string }
            >
          }>
        }
      | { __typename: 'RichTextSection' }
      | { __typename: 'SearchGridSection' }
      | {
          __typename: 'VideoModalBannerSection'
          id: string
          body: string
          ctaText: string
          heading: string
          image: string | null
          inlineVideo: string | null
          inlineVimeoId: number | null
          linkHref: string | null
          videoId: string | null
          videoPlatform: string | null
        }
      | {
          __typename: 'VideoSection'
          buttonVariant: string | null
          id: string
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          modalVideoId: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          subtitle: string | null
          textColor: string
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          title: string | null
          titleImageDesktop: string | null
          titleImageMobile: string | null
        }
    >
  } | null
  portal?: { __typename?: 'PortalCG'; enableSSO: boolean; groupTag: string | null } | null
}

export type FetchFavoritesQueryVariables = Exact<{
  id: Scalars['String']['input']
  selector: ProductGroupColorSelector
}>

export type FetchFavoritesQuery = {
  __typename?: 'Query'
  favorites: {
    __typename?: 'CustomerFavoriteList'
    id: string
    title: string
    items: Array<
      | { __typename: 'CustomerFavoriteContent' }
      | {
          __typename: 'CustomerFavoriteProduct'
          productHandle: string
          preferences: {
            __typename?: 'CustomerFavoriteProductPreferences'
            size: string | null
            fit: string | null
            color: string | null
          } | null
          product: {
            __typename?: 'Product'
            handle: string | null
            soldOut: boolean
            category: string
            title: string
            tiers: Array<string>
            priceRange: any | null
            waitlistable: boolean
            availableSizes: Array<string>
            availableForSale: boolean
            finalSale: boolean
            swPromoEligible: boolean
            images: {
              __typename?: 'ProductAssetConnection'
              nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
            } | null
            colorInfo: {
              __typename?: 'ProductColor'
              availability: string
              hexCodes: Array<string> | null
              name: string
              rawName: string
            } | null
            defaultVariant: {
              __typename?: 'Variant'
              color: string | null
              rawColor: string | null
              shopifyId: string
              sku: string | null
              fit: string | null
              rawFit: string | null
              priceDetails: {
                __typename?: 'PriceDetails'
                discountPct: number | null
                price: {
                  __typename?: 'Price'
                  amount: any | null
                  currency: string
                  base: { __typename?: 'Money'; amount: any | null; currency: string } | null
                } | null
                discountPrice: { __typename?: 'Price'; amount: any | null } | null
              } | null
            }
            productGroup: {
              __typename?: 'ProductGroup'
              collectionHandle: string | null
              description: string | null
              fits: Array<string>
              handle: string
              iconBadge: any | null
              iconBadgePlacement: string | null
              id: string
              sizes: Array<string>
              style: string | null
              colors: Array<{
                __typename?: 'ProductColor'
                availability: string
                hexCodes: Array<string> | null
                name: string
                swatchUrl: string | null
              }>
              productsForAvailability: Array<{
                __typename?: 'Product'
                availableSizes: Array<string>
                rawFit: string | null
                swPromoEligible: boolean
                colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
              }>
              defaultVariant: {
                __typename?: 'Variant'
                color: string | null
                shopifyId: string
                sku: string | null
                priceDetails: {
                  __typename?: 'PriceDetails'
                  discountPrice: { __typename?: 'Price'; amount: any | null } | null
                  price: {
                    __typename?: 'Price'
                    currency: string
                    amount: any | null
                    base: { __typename?: 'Money'; amount: any | null; currency: string } | null
                  } | null
                } | null
              } | null
              products: Array<{
                __typename?: 'Product'
                availableSizes: Array<string>
                collectionBadgeColor: string | null
                collectionBadgeText: string | null
                color: string | null
                finalSale: boolean
                fit: string | null
                handle: string | null
                iconBadge: any | null
                iconBadgePlacement: string | null
                priceRange: any | null
                swPromoEligible: boolean
                waitlistable: boolean
                colorInfo: {
                  __typename?: 'ProductColor'
                  availability: string
                  hexCodes: Array<string> | null
                  name: string
                } | null
                images: {
                  __typename?: 'ProductAssetConnection'
                  nodes: Array<{
                    __typename?: 'ProductAsset'
                    color: string | null
                    fit: string | null
                    index: number
                    kind: string | null
                    source: string
                  }>
                } | null
                variants: Array<{
                  __typename?: 'Variant'
                  category: string
                  color: string | null
                  currencyType: string
                  discountPrice: any | null
                  fit: string | null
                  handle: string
                  price: any
                  priceInfo: any | null
                  shopifyId: string
                  size: string | null
                  sku: string | null
                  soldOut: boolean
                  priceDetails: {
                    __typename?: 'PriceDetails'
                    discountPrice: { __typename?: 'Price'; amount: any | null } | null
                    price: {
                      __typename?: 'Price'
                      currency: string
                      amount: any | null
                      base: { __typename?: 'Money'; amount: any | null; currency: string } | null
                    } | null
                  } | null
                }> | null
              }>
              sizeChart: {
                __typename?: 'SizeChartCG'
                columnNames: Array<string> | null
                columnValues: Array<Array<string>> | null
                imageUri: string | null
                name: string
                navLabel: string | null
                title: string
                video: string | null
                tables: Array<{
                  __typename?: 'SizeChartTable'
                  cells: string
                  measureImage: string | null
                  subtitle: string | null
                  tabLabel: string | null
                  title: string | null
                  measureTips: Array<{
                    __typename?: 'SizeChartTipCG'
                    kind: string | null
                    tip: string | null
                  }> | null
                  sizeTips: Array<{
                    __typename?: 'SizeChartTipCG'
                    kind: string | null
                    tip: string | null
                  }> | null
                }> | null
                tips: Array<{
                  __typename?: 'SizeChartTipCG'
                  kind: string | null
                  tip: string | null
                }> | null
              }
              variants: Array<{
                __typename?: 'Variant'
                category: string
                color: string | null
                currencyType: string
                discountPrice: any | null
                fit: string | null
                handle: string
                price: any
                priceInfo: any | null
                shopifyId: string
                size: string | null
                sku: string | null
                soldOut: boolean
                priceDetails: {
                  __typename?: 'PriceDetails'
                  discountPrice: { __typename?: 'Price'; amount: any | null } | null
                  price: {
                    __typename?: 'Price'
                    currency: string
                    amount: any | null
                    base: { __typename?: 'Money'; amount: any | null; currency: string } | null
                  } | null
                } | null
              }> | null
            }
          } | null
        }
    >
  } | null
}

export type GetFilteredReviewsQueryVariables = Exact<{
  filterInput: FilterInput
  pageNum: Scalars['Int']['input']
  numPerPage: Scalars['Int']['input']
}>

export type GetFilteredReviewsQuery = {
  __typename?: 'Query'
  filteredReviews: {
    __typename?: 'FilteredReviewsResponse'
    tag: string | null
    pagination: {
      __typename?: 'ProductReviewsPageInfo'
      numPages: number | null
      pageNum: number | null
      numPerPage: number | null
    } | null
    reviews: Array<{
      __typename?: 'Review'
      reviewer: string
      title: string
      content: string
      createdAt: string | null
      score: number
      imageUrls: Array<string | null> | null
      customFields: Array<{
        __typename?: 'CustomField'
        key: number | null
        title: string | null
        value: string | null
        formId: number | null
      } | null> | null
    } | null> | null
  } | null
}

export type GiftCollectionItemsFragmentFragment = {
  __typename?: 'Collection'
  items: {
    __typename?: 'ProductConnection'
    numItems: number
    nodes: Array<{
      __typename?: 'Product'
      id: string
      category: string
      rawCategory: string
      collectionBadgeColor: string | null
      collectionBadgeText: string | null
      iconBadge: any | null
      iconBadgePlacement: string | null
      isKit: boolean
      handle: string | null
      shopifyId: string
      availableFits: Array<string>
      rawAvailableFits: Array<string>
      waitlistable: boolean
      swPromoEligible: boolean
      priceRange: any | null
      finalSale: boolean
      colorInfo: {
        __typename?: 'ProductColor'
        availability: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
        swatchUrl: string | null
      } | null
      defaultVariant: {
        __typename?: 'Variant'
        discountPriceExplicitFmt: string
        priceExplicitFmt: string
        shopifyId: string
        sku: string | null
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      }
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
      } | null
      productGroup: {
        __typename?: 'ProductGroup'
        collectionHandle: string | null
        description: string | null
        fits: Array<string>
        handle: string
        iconBadge: any | null
        iconBadgePlacement: string | null
        numColors: number
        rawFits: Array<string>
        style: string | null
        title: string
        priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
  } | null
}

export type FetchGiftCollectionItemsQueryVariables = Exact<{
  handle: Scalars['String']['input']
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput> | CollectionItemFilterInput>
}>

export type FetchGiftCollectionItemsQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    id: string
    colors: Array<{
      __typename?: 'ProductColor'
      name: string
      hexCodes: Array<string> | null
      swatchUrl: string | null
    }> | null
    items: {
      __typename?: 'ProductConnection'
      numItems: number
      nodes: Array<{
        __typename?: 'Product'
        id: string
        category: string
        rawCategory: string
        collectionBadgeColor: string | null
        collectionBadgeText: string | null
        iconBadge: any | null
        iconBadgePlacement: string | null
        isKit: boolean
        handle: string | null
        shopifyId: string
        availableFits: Array<string>
        rawAvailableFits: Array<string>
        waitlistable: boolean
        swPromoEligible: boolean
        priceRange: any | null
        finalSale: boolean
        colorInfo: {
          __typename?: 'ProductColor'
          availability: string
          hexCodes: Array<string> | null
          name: string
          rawName: string
          swatchUrl: string | null
        } | null
        defaultVariant: {
          __typename?: 'Variant'
          discountPriceExplicitFmt: string
          priceExplicitFmt: string
          shopifyId: string
          sku: string | null
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: {
              __typename?: 'Price'
              currency: string
              amount: any | null
              base: { __typename?: 'Money'; amount: any | null; currency: string } | null
            } | null
          } | null
        }
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
        } | null
        productGroup: {
          __typename?: 'ProductGroup'
          collectionHandle: string | null
          description: string | null
          fits: Array<string>
          handle: string
          iconBadge: any | null
          iconBadgePlacement: string | null
          numColors: number
          rawFits: Array<string>
          style: string | null
          title: string
          priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
        }
      }>
      pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
    } | null
  } | null
}

export type FetchGiftPackageQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type FetchGiftPackageQuery = {
  __typename?: 'Query'
  giftPackage: {
    __typename?: 'GiftPackage'
    id: string
    slug: string
    companyName: string
    companyLogo: string | null
    codePrefix: string
    errorMessage: string | null
    verificationCopy: any | null
    styleImageMens: string | null
    styleImageWomens: string | null
    giftPackageOptions: Array<{
      __typename?: 'GiftPackageOption'
      id: string
      title: string
      promoImageMens: string | null
      promoImageWomens: string | null
      giftItemCollections: Array<{
        __typename?: 'GiftItemCollection'
        id: string
        header: string | null
        collectionHandleMens: string
        collectionHandleWomens: string
        embroiderySku: string | null
        logoName: string | null
        logoFileName: string | null
        logoPlacement: string | null
        logoImage: string | null
        callToAction: string
        addToCartCTA: string | null
        changeSelectionCTA: string | null
        thumbnail: string | null
      }>
    }>
  } | null
}

export type FetchKitQueryVariables = Exact<{
  handle: Scalars['String']['input']
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchKitQuery = {
  __typename?: 'Query'
  kit: {
    __typename?: 'Kit'
    handle: string
    testimonialsTitle: string | null
    testimonialsSubtitle: string | null
    groupItemsByColor: boolean
    defaultColors: string | null
    hideProductDetails: boolean
    testimonials: Array<{
      __typename?: 'Testimonial'
      personName: string
      quote: string
      occupation: string | null
      instagram: string | null
      image: string
    }>
    sections: Array<
      | {
          __typename: 'PageBannerSection'
          id: string
          backgroundColorDesktop: string | null
          backgroundColorMobile: string | null
          eyebrow: string | null
          hasVerticalMargin: boolean
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          isFullBleedDesktop: boolean | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          ctaStyle: string
          modalVideoId: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          openVideoModalText: string | null
          overlayColorDesktop: string | null
          overlayColorMobile: string | null
          paragraph: string | null
          textAlignDesktop: string | null
          textAlignMobile: string | null
          textColorDesktop: string | null
          textColorMobile: string | null
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          textPlacementDesktop: string
          textPlacementMobile: string
          title: string | null
          titleTextSize: TitleTextSize
          titleImage: string | null
          titleImageMaxWidthDesktop: number | null
          titleImageMaxWidthMobile: number | null
          hotspot: { __typename?: 'Hotspot'; linkHref1: string } | null
          styledContentItems: Array<
            | {
                __typename: 'StyledImage'
                id: string
                imageMobile: string | null
                imageDesktop: string | null
                maxWidthMobile: number | null
                maxWidthDesktop: number | null
                altText: string
                aspectRatioMobile: number | null
                aspectRatioDesktop: number | null
                bottomMargin: number | null
              }
            | {
                __typename: 'StyledText'
                id: string
                title: string | null
                titleTextSize: TitleTextSize
                paragraph: string | null
                eyebrow: string | null
                textColorMobile: string
                textColorDesktop: string
                textContentMaxWidthMobile: number | null
                textContentMaxWidthDesktop: number | null
                textAlignMobile: TextAlignMobile
                textAlignDesktop: TextAlignDesktop
                bottomMargin: number | null
                links: Array<{ __typename?: 'StyledLink'; id: string; text: string; url: string }>
              }
          >
        }
      | {
          __typename: 'PageHeroSection'
          id: string
          backgroundColor: string | null
          buttonVariantDesktop: string | null
          buttonVariantMobile: string | null
          description: string | null
          eyebrow: string | null
          hideDownArrow: boolean | null
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          isTitleSerif: boolean | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          modalVideoId: string | null
          modalVideoIdMobile: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          modalVideoPlatformMobile: PageSectionModalVideoPlatform | null
          openVideoModalText: string | null
          subtitle: string | null
          mobileTitleColor: string
          desktopTitleColor: string
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          textPlacementDesktop: string
          textPlacementMobile: string
          title: string | null
          titleImage: string | null
          titleImageAspectRatio: number
          titleImageMaxWidthDesktop: number | null
          titleImageMaxWidthMobile: number | null
          titleImageMobile: string | null
          titleImageMobileAspectRatio: number
          isFullscreenHeight: boolean
          hasVerticalMargin: boolean
        }
      | {
          __typename: 'PageTileSection'
          id: string
          numColsDesktop: number
          numColsMobile: number
          tileAspectRatio: number | null
          textAlign: string
          textPlacement: string
          title: string | null
          titleTextSize: TitleTextSize
          variantDesktop: string
          variantMobile: string
          titleAlignmentDesktop: string | null
          titleAlignmentMobile: string | null
          hasHorizontalMargin: boolean
          hasVerticalMargin: boolean
          hasTileGaps: boolean
          tiles: Array<{
            __typename: 'PageTileSectionTile'
            ctaText: string | null
            ctaLinkHref: string | null
            description: string | null
            hoverImage: string | null
            hoverVideo: string | null
            id: string
            image: string | null
            inlineVideo: string | null
            inlineVimeoId: number | null
            jumboTitle: string | null
            linkHref: string
            name: string | null
            titleImage: string | null
          }>
        }
      | { __typename: 'RawHtmlSection'; id: string; html: string }
    >
    accordion: {
      __typename?: 'AccordionView'
      maxWidth: number | null
      sections: Array<{
        __typename?: 'AccordionViewSection'
        id: string
        title: string
        content:
          | { __typename: 'RawHtmlSection'; id: string; html: string }
          | { __typename: 'RichTextSection'; id: string; content: any }
          | null
      }>
    } | null
  } | null
}

export type FetchProductKitPricesQueryVariables = Exact<{
  handle: Scalars['String']['input']
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchProductKitPricesQuery = {
  __typename?: 'Query'
  productKit: {
    __typename?: 'ProductKit'
    collection: {
      __typename?: 'Collection'
      productGroups: Array<{
        __typename?: 'ProductGroup'
        defaultVariant: { __typename?: 'Variant'; price: any } | null
      }> | null
    }
  } | null
}

export type FetchProductKitQueryVariables = Exact<{
  handle: Scalars['String']['input']
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchProductKitQuery = {
  __typename?: 'Query'
  productKit: {
    __typename?: 'ProductKit'
    productGroup: {
      __typename?: 'ProductGroup'
      description: string | null
      handle: string
      seoTitle: string | null
      seoDescription: string | null
      title: string
      defaultVariant: {
        __typename?: 'Variant'
        sku: string | null
        currencyType: string
        discountPrice: any | null
        discountPriceExplicitFmt: string
        price: any
        priceExplicitFmt: string
      } | null
      products: Array<{
        __typename?: 'Product'
        badgeList: Array<string> | null
        collectionBadgeColor: string | null
        collectionBadgeText: string | null
        finalSale: boolean
        fitModelsDescriptions: Array<string> | null
        fit: string | null
        noindex: boolean
        rawFit: string | null
        rawMaterials: Array<string> | null
        shopifyId: string
        swPromoEligible: boolean
        whyWeLove: string | null
        iconList: Array<{
          __typename?: 'ProductIcon'
          label: string
          title: string
          image: string
        }> | null
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{
            __typename?: 'ProductAsset'
            color: string | null
            fit: string | null
            index: number
            kind: string | null
            source: string
            type: string | null
          }>
        } | null
        videos: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{
            __typename?: 'ProductAsset'
            kind: string | null
            source: string
            type: string | null
          }>
        } | null
      }>
    }
    collection: {
      __typename?: 'Collection'
      handle: string
      name: string
      productGroups: Array<{
        __typename?: 'ProductGroup'
        category: string
        description: string | null
        fits: Array<string>
        rawFits: Array<string>
        handle: string
        id: string
        priceRangeByColors: any | null
        embroiderable: boolean
        rtfFeatures: any | null
        sizes: Array<string>
        style: string | null
        colors: Array<{
          __typename?: 'ProductColor'
          availability: string
          handle: string
          hexCodes: Array<string> | null
          name: string
          rawName: string
          swatchUrl: string | null
        }>
        defaultVariant: {
          __typename?: 'Variant'
          category: string
          color: string | null
          currencyType: string
          discountPrice: any | null
          fit: string | null
          handle: string
          price: any
          rawColor: string | null
          rawFit: string | null
          shopifyId: string
          sku: string | null
        } | null
        embroideryImages: Array<{
          __typename?: 'ProductAsset'
          color: string | null
          embroideryOffsets: any | null
          fit: string | null
          index: number
          source: string
          kind: string | null
          type: string | null
        }> | null
        priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
        productsForAvailability: Array<{
          __typename?: 'Product'
          availableSizes: Array<string>
          rawFit: string | null
          swPromoEligible: boolean
          colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
        }>
        products: Array<{
          __typename?: 'Product'
          shopifyId: string
          careInstructions: string | null
          color: string | null
          finalSale: boolean
          fitModelsDescriptions: Array<string> | null
          fit: string | null
          handle: string | null
          rawFit: string | null
          rawMaterials: Array<string> | null
          rtfFeatures: any | null
          title: string
          collectionLinks: {
            __typename?: 'LinkConnection'
            nodes: Array<{ __typename?: 'Link'; text: string; href: string }>
          } | null
          colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
          images: {
            __typename?: 'ProductAssetConnection'
            nodes: Array<{
              __typename?: 'ProductAsset'
              color: string | null
              embroideryOffsets: any | null
              fit: string | null
              index: number
              source: string
              kind: string | null
              type: string | null
            }>
          } | null
        }>
        sizeChart: {
          __typename?: 'SizeChartCG'
          columnNames: Array<string> | null
          columnValues: Array<Array<string>> | null
          imageUri: string | null
          name: string
          navLabel: string | null
          title: string
          video: string | null
          tables: Array<{
            __typename?: 'SizeChartTable'
            cells: string
            measureImage: string | null
            subtitle: string | null
            tabLabel: string | null
            title: string | null
            measureTips: Array<{
              __typename?: 'SizeChartTipCG'
              kind: string | null
              tip: string | null
            }> | null
            sizeTips: Array<{
              __typename?: 'SizeChartTipCG'
              kind: string | null
              tip: string | null
            }> | null
          }> | null
          tips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
        }
        variants: Array<{
          __typename?: 'Variant'
          color: string | null
          rawColor: string | null
          fit: string | null
          rawFit: string | null
          handle: string
        }> | null
      }> | null
    }
  } | null
}

export type ProductGroupKitRequirementsFragment = {
  __typename?: 'ProductGroup'
  category: string
  description: string | null
  fits: Array<string>
  rawFits: Array<string>
  handle: string
  id: string
  priceRangeByColors: any | null
  embroiderable: boolean
  rtfFeatures: any | null
  sizes: Array<string>
  style: string | null
  colors: Array<{
    __typename?: 'ProductColor'
    availability: string
    handle: string
    hexCodes: Array<string> | null
    name: string
    rawName: string
    swatchUrl: string | null
  }>
  defaultVariant: {
    __typename?: 'Variant'
    category: string
    color: string | null
    currencyType: string
    discountPrice: any | null
    fit: string | null
    handle: string
    price: any
    rawColor: string | null
    rawFit: string | null
    shopifyId: string
    sku: string | null
  } | null
  embroideryImages: Array<{
    __typename?: 'ProductAsset'
    color: string | null
    embroideryOffsets: any | null
    fit: string | null
    index: number
    source: string
    kind: string | null
    type: string | null
  }> | null
  priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
  productsForAvailability: Array<{
    __typename?: 'Product'
    availableSizes: Array<string>
    rawFit: string | null
    swPromoEligible: boolean
    colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
  }>
  products: Array<{
    __typename?: 'Product'
    shopifyId: string
    careInstructions: string | null
    color: string | null
    finalSale: boolean
    fitModelsDescriptions: Array<string> | null
    fit: string | null
    handle: string | null
    rawFit: string | null
    rawMaterials: Array<string> | null
    rtfFeatures: any | null
    title: string
    collectionLinks: {
      __typename?: 'LinkConnection'
      nodes: Array<{ __typename?: 'Link'; text: string; href: string }>
    } | null
    colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
    images: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{
        __typename?: 'ProductAsset'
        color: string | null
        embroideryOffsets: any | null
        fit: string | null
        index: number
        source: string
        kind: string | null
        type: string | null
      }>
    } | null
  }>
  sizeChart: {
    __typename?: 'SizeChartCG'
    columnNames: Array<string> | null
    columnValues: Array<Array<string>> | null
    imageUri: string | null
    name: string
    navLabel: string | null
    title: string
    video: string | null
    tables: Array<{
      __typename?: 'SizeChartTable'
      cells: string
      measureImage: string | null
      subtitle: string | null
      tabLabel: string | null
      title: string | null
      measureTips: Array<{
        __typename?: 'SizeChartTipCG'
        kind: string | null
        tip: string | null
      }> | null
      sizeTips: Array<{
        __typename?: 'SizeChartTipCG'
        kind: string | null
        tip: string | null
      }> | null
    }> | null
    tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
  }
  variants: Array<{
    __typename?: 'Variant'
    color: string | null
    rawColor: string | null
    fit: string | null
    rawFit: string | null
    handle: string
  }> | null
}

export type FetchKitShopTheLookQueryVariables = Exact<{
  handle: Scalars['String']['input']
}>

export type FetchKitShopTheLookQuery = {
  __typename?: 'Query'
  kit: {
    __typename?: 'Kit'
    shopTheLook: Array<{
      __typename?: 'Product'
      color: string | null
      finalSale: boolean
      fit: string | null
      rawFit: string | null
      handle: string | null
      priceRange: any | null
      waitlistable: boolean
      colorInfo: {
        __typename?: 'ProductColor'
        availability: string
        rawName: string
        name: string
      } | null
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{
          __typename?: 'ProductAsset'
          kind: string | null
          source: string
          color: string | null
        }>
      } | null
      productGroup: {
        __typename?: 'ProductGroup'
        id: string
        description: string | null
        fits: Array<string>
        handle: string
        rawFits: Array<string>
        sizes: Array<string>
        style: string | null
        colorGroups: Array<{
          __typename?: 'ColorGroup'
          label: string
          colors: Array<{
            __typename?: 'ProductColor'
            accentColor: string | null
            availability: string
            handle: string
            hexCodes: Array<string> | null
            name: string
            rawName: string
            swatchUrl: string | null
          }>
        }>
        colors: Array<{
          __typename?: 'ProductColor'
          availability: string
          hexCodes: Array<string> | null
          name: string
          rawName: string
          swatchUrl: string | null
        }>
        defaultVariant: {
          __typename?: 'Variant'
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: { __typename?: 'Price'; amount: any | null } | null
          } | null
        } | null
        productsForAvailability: Array<{
          __typename?: 'Product'
          availableSizes: Array<string>
          rawFit: string | null
          swPromoEligible: boolean
          colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
        }>
        sizeChart: {
          __typename?: 'SizeChartCG'
          columnNames: Array<string> | null
          columnValues: Array<Array<string>> | null
          imageUri: string | null
          name: string
          navLabel: string | null
          title: string
          video: string | null
          tables: Array<{
            __typename?: 'SizeChartTable'
            cells: string
            measureImage: string | null
            subtitle: string | null
            tabLabel: string | null
            title: string | null
            measureTips: Array<{
              __typename?: 'SizeChartTipCG'
              kind: string | null
              tip: string | null
            }> | null
            sizeTips: Array<{
              __typename?: 'SizeChartTipCG'
              kind: string | null
              tip: string | null
            }> | null
          }> | null
          tips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
        }
      }
      variants: Array<{
        __typename?: 'Variant'
        category: string
        color: string | null
        fit: string | null
        rawColor: string | null
        rawFit: string | null
        shopifyId: string
        size: string | null
        sku: string | null
        soldOut: boolean
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: { __typename?: 'Price'; amount: any | null; currency: string } | null
        } | null
      }> | null
    }>
  } | null
}

export type FetchKitVariantsQueryVariables = Exact<{
  handles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type FetchKitVariantsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'ProductConnection'
    nodes: Array<{
      __typename?: 'Product'
      variants: Array<{
        __typename?: 'Variant'
        sku: string | null
        category: string
        color: string | null
        currencyType: string
        discountPrice: any | null
        fit: string | null
        rawFit: string | null
        shopifyId: string
        size: string | null
        upc: string | null
        handle: string
        price: any
        soldOut: boolean
        product: { __typename?: 'Product'; finalSale: boolean }
      }> | null
    }>
  } | null
}

export type FetchPageViewQueryVariables = Exact<{
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchPageViewQuery = {
  __typename?: 'Query'
  pageView: {
    __typename?: 'PageView'
    handle: string
    hideEmailCaptureForm: boolean | null
    noindex: boolean
    socialDescription: string | null
    socialImage: string | null
    title: string
    themeOverride: ThemeOverrideString | null
    sections: Array<
      | {
          __typename: 'AccordionView'
          maxWidth: number | null
          sections: Array<{
            __typename?: 'AccordionViewSection'
            id: string
            title: string
            content:
              | { __typename: 'RawHtmlSection'; id: string; html: string }
              | { __typename: 'RichTextSection'; id: string; content: any }
              | null
          }>
        }
      | { __typename: 'AppPageHeroSection' }
      | {
          __typename: 'CollectionSection'
          id: string
          collectionHandle: string
          featuredInlineVideoDesktop: string | null
          featuredInlineVideoMobile: string | null
          hideColors: boolean | null
          hidePrice: boolean | null
          linkHref: string | null
          linkText: string | null
          productCountLimit: number | null
          featuredImage: {
            __typename: 'StyledImage'
            id: string
            imageMobile: string | null
            imageDesktop: string | null
            altText: string
            maxWidthMobile: number | null
            maxWidthDesktop: number | null
            aspectRatioMobile: number | null
            aspectRatioDesktop: number | null
            bottomMargin: number | null
            altTextMobile: string | null
            altTextDesktop: string | null
          } | null
          links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
          titleLockupImage: {
            __typename: 'StyledImage'
            id: string
            imageMobile: string | null
            imageDesktop: string | null
            altText: string
            maxWidthMobile: number | null
            maxWidthDesktop: number | null
            aspectRatioMobile: number | null
            aspectRatioDesktop: number | null
            bottomMargin: number | null
            altTextMobile: string | null
            altTextDesktop: string | null
          } | null
          subtitleText: {
            __typename: 'StyledText'
            id: string
            title: string | null
            titleTextSize: TitleTextSize
            paragraph: string | null
            paragraphTextSize: ParagraphTextSize
            eyebrow: string | null
            textColorMobile: string
            textColorDesktop: string
            textContentMaxWidthMobile: number | null
            textContentMaxWidthDesktop: number | null
            textAlignMobile: TextAlignMobile
            textAlignDesktop: TextAlignDesktop
            bottomMargin: number | null
            links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
          } | null
          titleText: {
            __typename: 'StyledText'
            id: string
            title: string | null
            titleTextSize: TitleTextSize
            paragraph: string | null
            paragraphTextSize: ParagraphTextSize
            eyebrow: string | null
            textColorMobile: string
            textColorDesktop: string
            textContentMaxWidthMobile: number | null
            textContentMaxWidthDesktop: number | null
            textAlignMobile: TextAlignMobile
            textAlignDesktop: TextAlignDesktop
            bottomMargin: number | null
            links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
          } | null
          colorsForFilters: Array<{
            __typename?: 'Color'
            id: string
            handle: string
            name: string
            hexCodes: Array<string>
          }> | null
          featuredImages: Array<{
            __typename: 'StyledImage'
            id: string
            imageMobile: string | null
            imageDesktop: string | null
            altText: string
            maxWidthMobile: number | null
            maxWidthDesktop: number | null
            aspectRatioMobile: number | null
            aspectRatioDesktop: number | null
            bottomMargin: number | null
            altTextMobile: string | null
            altTextDesktop: string | null
          }> | null
        }
      | {
          __typename: 'CollectionViewHeroSection'
          ctaText: string | null
          id: string
          imageDesktop: string | null
          imageMobile: string | null
          imagePlacement: string | null
          subtitle: string | null
          textColor: string
          title: string | null
          collectionViewHeroBackgroundColor: string
        }
      | {
          __typename: 'CustomComponentSection'
          id: string
          componentName: CustomComponentSectionName
        }
      | { __typename: 'EditorialCell' }
      | { __typename: 'GuestNavigationPageHeroSection' }
      | {
          __typename: 'PageBannerSection'
          id: string
          backgroundColorDesktop: string | null
          backgroundColorMobile: string | null
          eyebrow: string | null
          hasVerticalMargin: boolean
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          isFullBleedDesktop: boolean | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          ctaStyle: string
          modalVideoId: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          openVideoModalText: string | null
          overlayColorDesktop: string | null
          overlayColorMobile: string | null
          paragraph: string | null
          textAlignDesktop: string | null
          textAlignMobile: string | null
          textColorDesktop: string | null
          textColorMobile: string | null
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          textPlacementDesktop: string
          textPlacementMobile: string
          title: string | null
          titleTextSize: TitleTextSize
          titleImage: string | null
          titleImageMaxWidthDesktop: number | null
          titleImageMaxWidthMobile: number | null
          hotspot: { __typename?: 'Hotspot'; linkHref1: string } | null
          styledContentItems: Array<
            | {
                __typename: 'StyledImage'
                id: string
                imageMobile: string | null
                imageDesktop: string | null
                maxWidthMobile: number | null
                maxWidthDesktop: number | null
                altText: string
                aspectRatioMobile: number | null
                aspectRatioDesktop: number | null
                bottomMargin: number | null
              }
            | {
                __typename: 'StyledText'
                id: string
                title: string | null
                titleTextSize: TitleTextSize
                paragraph: string | null
                eyebrow: string | null
                textColorMobile: string
                textColorDesktop: string
                textContentMaxWidthMobile: number | null
                textContentMaxWidthDesktop: number | null
                textAlignMobile: TextAlignMobile
                textAlignDesktop: TextAlignDesktop
                bottomMargin: number | null
                links: Array<{ __typename?: 'StyledLink'; id: string; text: string; url: string }>
              }
          >
        }
      | { __typename: 'PageBreathingExerciseLauncherSection' }
      | { __typename: 'PageBuyItAgainSection' }
      | { __typename: 'PageColorVoteSection' }
      | {
          __typename: 'PageEditorialCarouselSection'
          id: string
          backgroundColor: string | null
          hasVerticalMargin: boolean
          overlayOpacity: number | null
          displayVideoLink: boolean
          modalVimeoIdDesktop: number | null
          modalVimeoIdMobile: number | null
          headline: {
            __typename: 'StyledText'
            id: string
            title: string | null
            titleTextSize: TitleTextSize
            paragraph: string | null
            paragraphTextSize: ParagraphTextSize
            eyebrow: string | null
            textColorMobile: string
            textColorDesktop: string
            textContentMaxWidthMobile: number | null
            textContentMaxWidthDesktop: number | null
            textAlignMobile: TextAlignMobile
            textAlignDesktop: TextAlignDesktop
            bottomMargin: number | null
            links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
          } | null
          backgroundImage: {
            __typename: 'StyledImage'
            id: string
            imageMobile: string | null
            imageDesktop: string | null
            altText: string
            maxWidthMobile: number | null
            maxWidthDesktop: number | null
            aspectRatioMobile: number | null
            aspectRatioDesktop: number | null
            bottomMargin: number | null
            altTextMobile: string | null
            altTextDesktop: string | null
          } | null
          bodies: Array<{
            __typename: 'StyledText'
            id: string
            title: string | null
            titleTextSize: TitleTextSize
            paragraph: string | null
            paragraphTextSize: ParagraphTextSize
            eyebrow: string | null
            textColorMobile: string
            textColorDesktop: string
            textContentMaxWidthMobile: number | null
            textContentMaxWidthDesktop: number | null
            textAlignMobile: TextAlignMobile
            textAlignDesktop: TextAlignDesktop
            bottomMargin: number | null
            links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
          }>
          cta1: {
            __typename?: 'StyledText'
            title: string | null
            titleTextSize: TitleTextSize
            paragraph: string | null
            eyebrow: string | null
            textColorMobile: string
            textColorDesktop: string
            textContentMaxWidthMobile: number | null
            textContentMaxWidthDesktop: number | null
            textAlignMobile: TextAlignMobile
            textAlignDesktop: TextAlignDesktop
            bottomMargin: number | null
            links: Array<{ __typename?: 'StyledLink'; text: string; url: string }>
          } | null
          cta2: {
            __typename?: 'StyledText'
            title: string | null
            titleTextSize: TitleTextSize
            paragraph: string | null
            eyebrow: string | null
            textColorMobile: string
            textColorDesktop: string
            textContentMaxWidthMobile: number | null
            textContentMaxWidthDesktop: number | null
            textAlignMobile: TextAlignMobile
            textAlignDesktop: TextAlignDesktop
            bottomMargin: number | null
            links: Array<{ __typename?: 'StyledLink'; text: string; url: string }>
          } | null
          tileCarousel: {
            __typename?: 'PageTileSection'
            numColsDesktop: number
            numColsMobile: number
            tileAspectRatio: number | null
            textAlign: string
            textPlacement: string
            tiles: Array<{
              __typename?: 'PageTileSectionTile'
              titleImage: string | null
              jumboTitle: string | null
              description: string | null
              linkHref: string
              image: string | null
              inlineVideo: string | null
              inlineVimeoId: number | null
              hoverImage: string | null
              hoverVideo: string | null
              ctaText: string | null
              ctaLinkHref: string | null
            }>
          } | null
        }
      | {
          __typename: 'PageHeroSection'
          id: string
          backgroundColor: string | null
          buttonVariantDesktop: string | null
          buttonVariantMobile: string | null
          description: string | null
          eyebrow: string | null
          hideDownArrow: boolean | null
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          isTitleSerif: boolean | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          modalVideoId: string | null
          modalVideoIdMobile: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          modalVideoPlatformMobile: PageSectionModalVideoPlatform | null
          openVideoModalText: string | null
          subtitle: string | null
          mobileTitleColor: string
          desktopTitleColor: string
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          textPlacementDesktop: string
          textPlacementMobile: string
          title: string | null
          titleImage: string | null
          titleImageAspectRatio: number
          titleImageMaxWidthDesktop: number | null
          titleImageMaxWidthMobile: number | null
          titleImageMobile: string | null
          titleImageMobileAspectRatio: number
          isFullscreenHeight: boolean
          hasVerticalMargin: boolean
        }
      | {
          __typename: 'PageInteractiveSlideSection'
          id: string
          title: string | null
          isFullBleedDesktop: boolean | null
          hasVerticalMargin: boolean
          interactiveSlides: Array<{
            __typename?: 'InteractiveSlide'
            id: string
            title: string | null
            imageDesktop: string | null
            imageMobile: string | null
            altText: string | null
            aspectRatioMobile: number
            aspectRatioDesktop: number
            overlayOpacity: number
            contentColorDesktop: string
            contentColorMobile: string
            ctaText: string
            ctaLink: string
          }>
        }
      | { __typename: 'PageOrderTrackingSection' }
      | { __typename: 'PageSectionStackSection' }
      | {
          __typename: 'PageTabSection'
          id: string
          hideOnMobile: boolean
          hideOnDesktop: boolean
          name: string | null
          title: string | null
          tabs: Array<{
            __typename?: 'PageTab'
            id: string
            text: string
            textColor: string | null
            tabSections: Array<
              | {
                  __typename: 'PageTabTileSection'
                  id: string
                  layoutVariantMobile: string
                  layoutVariantDesktop: string
                  tileVariantMobile: string
                  tileVariantDesktop: string
                  tiles: Array<{
                    __typename?: 'PageTabTileSectionTile'
                    id: string
                    ctaText: string
                    href: string
                    image: string
                  }>
                }
              | { __typename: 'RawHtmlSection'; id: string; html: string }
            >
          }>
        }
      | {
          __typename: 'PageTestimonialSection'
          id: string
          title: string | null
          subtitle: string | null
          testimonials: Array<{
            __typename?: 'Testimonial'
            personName: string
            quote: string
            occupation: string | null
            instagram: string | null
            image: string
          }>
        }
      | {
          __typename: 'PageTileSection'
          id: string
          numColsDesktop: number
          numColsMobile: number
          tileAspectRatio: number | null
          textAlign: string
          textPlacement: string
          title: string | null
          titleTextSize: TitleTextSize
          variantDesktop: string
          variantMobile: string
          titleAlignmentDesktop: string | null
          titleAlignmentMobile: string | null
          hasHorizontalMargin: boolean
          hasVerticalMargin: boolean
          hasTileGaps: boolean
          tiles: Array<{
            __typename: 'PageTileSectionTile'
            ctaText: string | null
            ctaLinkHref: string | null
            description: string | null
            hoverImage: string | null
            hoverVideo: string | null
            id: string
            image: string | null
            inlineVideo: string | null
            inlineVimeoId: number | null
            jumboTitle: string | null
            linkHref: string
            name: string | null
            titleImage: string | null
          }>
        }
      | { __typename: 'PageTitleSection'; id: string; title: string | null }
      | {
          __typename: 'ProductRecommendationsSection'
          hideColors: boolean | null
          hidePrice: boolean | null
          id: string
          name: string | null
          nostoPlacementId: string
          productCountLimit: number | null
          title: string | null
          titleAlignmentDesktop: string | null
          titleAlignmentMobile: string | null
          useNostoPlacementTitle: boolean | null
        }
      | { __typename: 'RawHtmlSection'; id: string; html: string }
      | { __typename: 'RichTextSection'; id: string; content: any }
      | {
          __typename: 'VideoSection'
          buttonVariant: string | null
          id: string
          imageDesktop: string | null
          imageMobile: string | null
          inlineVideoDesktop: string | null
          inlineVideoMobile: string | null
          inlineVimeoIdDesktop: number | null
          inlineVimeoIdMobile: number | null
          linkHref1: string | null
          linkHref2: string | null
          linkText1: string | null
          linkText2: string | null
          modalVideoId: string | null
          modalVideoPlatform: PageSectionModalVideoPlatform | null
          subtitle: string | null
          textColor: string
          textContentMaxWidthDesktop: number | null
          textContentMaxWidthMobile: number | null
          title: string | null
          titleImageDesktop: string | null
          titleImageMobile: string | null
        }
    >
  } | null
}

export type FetchProductGroupDetailsQueryVariables = Exact<{
  handle?: InputMaybe<Scalars['String']['input']>
}>

export type FetchProductGroupDetailsQuery = {
  __typename?: 'Query'
  productGroups: {
    __typename?: 'ProductGroupConnection'
    nodes: Array<{
      __typename?: 'ProductGroup'
      fits: Array<string>
      iconBadge: any | null
      iconBadgePlacement: string | null
      products: Array<{
        __typename?: 'Product'
        color: string | null
        collectionBadgeColor: string | null
        collectionBadgeText: string | null
        finalSale: boolean
        iconBadge: any | null
        iconBadgePlacement: string | null
        waitlistable: boolean
      }>
      sizeChart: {
        __typename?: 'SizeChartCG'
        columnNames: Array<string> | null
        columnValues: Array<Array<string>> | null
        imageUri: string | null
        name: string
        navLabel: string | null
        title: string
        video: string | null
        tables: Array<{
          __typename?: 'SizeChartTable'
          cells: string
          measureImage: string | null
          subtitle: string | null
          tabLabel: string | null
          title: string | null
          measureTips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
          sizeTips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
        }> | null
        tips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }
      variants: Array<{
        __typename?: 'Variant'
        category: string
        color: string | null
        currencyType: string
        discountPrice: any | null
        fit: string | null
        handle: string
        price: any
        priceInfo: any | null
        shopifyId: string
        size: string | null
        sku: string | null
        soldOut: boolean
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      }> | null
    }>
  } | null
}

export type GetProductReviewsQueryVariables = Exact<{
  shopifyProductId: Scalars['String']['input']
  pageNum: Scalars['Int']['input']
  numPerPage: Scalars['Int']['input']
  star?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  sortDir?: InputMaybe<Scalars['String']['input']>
}>

export type GetProductReviewsQuery = {
  __typename?: 'Query'
  getReviews: {
    __typename?: 'ReviewsResponse'
    averageScore: number | null
    totalReviews: number | null
    averageFit: number | null
    tag: string | null
    starDistribution: {
      __typename?: 'Ratings'
      oneStarRatings: number | null
      twoStarRatings: number | null
      threeStarRatings: number | null
      fourStarRatings: number | null
      fiveStarRatings: number | null
    } | null
    pagination: {
      __typename?: 'ProductReviewsPageInfo'
      pageNum: number | null
      numPages: number | null
      numPerPage: number | null
    } | null
    reviews: Array<{
      __typename?: 'Review'
      reviewer: string
      title: string
      content: string
      createdAt: string | null
      score: number
      imageUrls: Array<string | null> | null
      customFields: Array<{
        __typename?: 'CustomField'
        key: number | null
        title: string | null
        value: string | null
      } | null> | null
    } | null> | null
  }
}

export type FetchRecoProductDetailsOptimizedQueryVariables = Exact<{
  handles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type FetchRecoProductDetailsOptimizedQuery = {
  __typename?: 'Query'
  optimizedProducts: {
    __typename?: 'ProductConnection'
    nodes: Array<{
      __typename?: 'Product'
      color: string | null
      handle: string | null
      id: string
      priceRange: any | null
      category: string
      colorInfo: { __typename?: 'ProductColor'; name: string; rawName: string } | null
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
      } | null
      defaultVariant: {
        __typename?: 'Variant'
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: { __typename?: 'Price'; amount: any | null; currency: string } | null
        } | null
      }
      productGroup: {
        __typename?: 'ProductGroup'
        handle: string
        title: string
        colors: Array<{ __typename?: 'ProductColor'; handle: string }>
      }
    }>
  } | null
}

export type SiteFixturesRedirectsQueryVariables = Exact<{
  handle?: InputMaybe<Scalars['String']['input']>
}>

export type SiteFixturesRedirectsQuery = {
  __typename?: 'Query'
  siteFixtures: {
    __typename?: 'SiteFixtures'
    searchRedirects: {
      __typename?: 'RedirectSet'
      id: string
      code: number
      redirects: string
    } | null
  }
}

export type FetchShopTheLookQueryVariables = Exact<{
  handle: Scalars['String']['input']
}>

export type FetchShopTheLookQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    shopTheLook: {
      __typename?: 'ProductConnection'
      nodes: Array<{
        __typename?: 'Product'
        color: string | null
        fit: string | null
        swPromoEligible: boolean
        waitlistable: boolean
        finalSale: boolean
        handle: string | null
        priceRange: any | null
        colorInfo: {
          __typename?: 'ProductColor'
          availability: string
          name: string
          rawName: string
        } | null
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{
            __typename?: 'ProductAsset'
            kind: string | null
            source: string
            color: string | null
          }>
        } | null
        productGroup: {
          __typename?: 'ProductGroup'
          id: string
          description: string | null
          fits: Array<string>
          handle: string
          rawFits: Array<string>
          sizes: Array<string>
          style: string | null
          colorGroups: Array<{
            __typename?: 'ColorGroup'
            label: string
            colors: Array<{
              __typename?: 'ProductColor'
              accentColor: string | null
              availability: string
              handle: string
              hexCodes: Array<string> | null
              name: string
              rawName: string
              swatchUrl: string | null
            }>
          }>
          colors: Array<{
            __typename?: 'ProductColor'
            availability: string
            hexCodes: Array<string> | null
            name: string
            rawName: string
            swatchUrl: string | null
          }>
          defaultVariant: {
            __typename?: 'Variant'
            priceDetails: {
              __typename?: 'PriceDetails'
              discountPrice: { __typename?: 'Price'; amount: any | null } | null
              price: { __typename?: 'Price'; amount: any | null } | null
            } | null
          } | null
          productsForAvailability: Array<{
            __typename?: 'Product'
            availableSizes: Array<string>
            rawFit: string | null
            swPromoEligible: boolean
            colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
          }>
          sizeChart: {
            __typename?: 'SizeChartCG'
            columnNames: Array<string> | null
            columnValues: Array<Array<string>> | null
            imageUri: string | null
            name: string
            navLabel: string | null
            title: string
            video: string | null
            tables: Array<{
              __typename?: 'SizeChartTable'
              cells: string
              measureImage: string | null
              subtitle: string | null
              tabLabel: string | null
              title: string | null
              measureTips: Array<{
                __typename?: 'SizeChartTipCG'
                kind: string | null
                tip: string | null
              }> | null
              sizeTips: Array<{
                __typename?: 'SizeChartTipCG'
                kind: string | null
                tip: string | null
              }> | null
            }> | null
            tips: Array<{
              __typename?: 'SizeChartTipCG'
              kind: string | null
              tip: string | null
            }> | null
          }
        }
        variants: Array<{
          __typename?: 'Variant'
          category: string
          color: string | null
          fit: string | null
          rawColor: string | null
          shopifyId: string
          size: string | null
          sku: string | null
          soldOut: boolean
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: { __typename?: 'Price'; amount: any | null; currency: string } | null
          } | null
        }> | null
      }>
    } | null
  } | null
}

export type GetCollectionsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>
  handle: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCollectionsQuery = {
  __typename?: 'Query'
  collections: {
    __typename?: 'CollectionConnection'
    nodes: Array<{
      __typename?: 'Collection'
      handle: string
      id: string
      name: string
      productHandles: Array<string> | null
      filters: Array<{
        __typename?: 'ProductFilter'
        key: string
        values: Array<string> | null
      }> | null
      productGroups: Array<{
        __typename?: 'ProductGroup'
        handle: string
        category: string
        collectionHandle: string | null
        description: string | null
        fits: Array<string>
        sizes: Array<string>
        style: string | null
        title: string
        rawCategory: string
        colors: Array<{
          __typename?: 'ProductColor'
          availability: string
          handle: string
          hexCodes: Array<string> | null
          name: string
        }>
        defaultVariant: {
          __typename?: 'Variant'
          color: string | null
          shopifyId: string
          sku: string | null
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: {
              __typename?: 'Price'
              currency: string
              amount: any | null
              base: { __typename?: 'Money'; amount: any | null; currency: string } | null
            } | null
          } | null
        } | null
        products: Array<{
          __typename?: 'Product'
          id: string
          availableFits: Array<string>
          availableSizes: Array<string>
          category: string
          color: string | null
          finalSale: boolean
          fit: string | null
          handle: string | null
          isKit: boolean
          priceRange: any | null
          rawAvailableFits: Array<string>
          rawCategory: string
          rawFit: string | null
          swPromoEligible: boolean
          waitlistable: boolean
          colorInfo: {
            __typename?: 'ProductColor'
            availability: string
            hexCodes: Array<string> | null
            name: string
          } | null
          defaultVariant: {
            __typename?: 'Variant'
            color: string | null
            shopifyId: string
            sku: string | null
            priceDetails: {
              __typename?: 'PriceDetails'
              discountPrice: { __typename?: 'Price'; amount: any | null } | null
              price: {
                __typename?: 'Price'
                currency: string
                amount: any | null
                base: { __typename?: 'Money'; amount: any | null; currency: string } | null
              } | null
            } | null
          }
          images: {
            __typename?: 'ProductAssetConnection'
            nodes: Array<{
              __typename?: 'ProductAsset'
              color: string | null
              fit: string | null
              index: number
              kind: string | null
              source: string
            }>
          } | null
        }>
      }> | null
    }>
  } | null
}

export type GetSiteFixturesQueryVariables = Exact<{
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetSiteFixturesQuery = {
  __typename?: 'Query'
  siteFixtures: {
    __typename?: 'SiteFixtures'
    disableOneTrust: boolean | null
    disableOptimizely: boolean | null
    embroideryIcon: string | null
    enablePDPAccentColor: boolean | null
    id: string
    handle: string
    miniCartMessage: string | null
    embroideryWarningMessage: string | null
    logoColorSelectionTooltip: string | null
    logoDigitizationDisclaimer: string | null
    logoDigitizationWarning: string | null
    logoLearnMorelink: string | null
    siteWidePromo: string | null
    enableSiteWidePromo: boolean | null
    upsellHandles: Array<string> | null
    nostoUpsell: boolean | null
    nostoUpsellId: string | null
    navigation: {
      __typename?: 'SiteNavigation'
      name: string
      helpLinkText: string | null
      helpLinkHref: string | null
      desktopTabs: Array<{
        __typename?: 'NavTab'
        href: string | null
        id: string
        text: string
        textColor: string | null
        sections: Array<
          | {
              __typename: 'NavAccordion'
              id: string
              variant: string
              sections: Array<{
                __typename?: 'NavAccordionSection'
                id: string
                header: {
                  __typename?: 'NavListItem'
                  badge: string | null
                  badgeColor: string | null
                  badgeTextColor: string | null
                  href: string | null
                  icon: string | null
                  id: string
                  isBold: boolean | null
                  isHeading: boolean | null
                  text: string
                  textColor: string | null
                  htmlId: string | null
                  htmlClass: string | null
                  isBtn: boolean | null
                  alternateModelVariant: {
                    __typename?: 'AlternateModelVariant'
                    name: string
                    action: string
                    validContentfulModel:
                      | { __typename?: 'NavAccordionSection' }
                      | {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                        }
                      | { __typename?: 'NavTab' }
                      | null
                    targetCriteria: {
                      __typename?: 'TargetCriteria'
                      name: string
                      targetLogic: string
                      targetCriteria: Array<string>
                      inRegion: Array<string> | null
                    } | null
                  } | null
                }
                body:
                  | {
                      __typename: 'NavAccordion'
                      id: string
                      sections: Array<{
                        __typename?: 'NavAccordionSection'
                        id: string
                        header: {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                          alternateModelVariant: {
                            __typename?: 'AlternateModelVariant'
                            name: string
                            action: string
                            validContentfulModel:
                              | { __typename?: 'NavAccordionSection' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                }
                              | { __typename?: 'NavTab' }
                              | null
                            targetCriteria: {
                              __typename?: 'TargetCriteria'
                              name: string
                              targetLogic: string
                              targetCriteria: Array<string>
                              inRegion: Array<string> | null
                            } | null
                          } | null
                        }
                        body:
                          | { __typename: 'NavAccordion' }
                          | { __typename: 'NavListGroupSection' }
                          | {
                              __typename: 'NavListSection'
                              id: string
                              variant: string
                              items: Array<
                                | { __typename?: 'AppNavListItem' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                    alternateModelVariant: {
                                      __typename?: 'AlternateModelVariant'
                                      name: string
                                      action: string
                                      validContentfulModel:
                                        | { __typename?: 'NavAccordionSection' }
                                        | {
                                            __typename?: 'NavListItem'
                                            badge: string | null
                                            badgeColor: string | null
                                            badgeTextColor: string | null
                                            href: string | null
                                            icon: string | null
                                            id: string
                                            isBold: boolean | null
                                            isHeading: boolean | null
                                            text: string
                                            textColor: string | null
                                            htmlId: string | null
                                            htmlClass: string | null
                                            isBtn: boolean | null
                                          }
                                        | { __typename?: 'NavTab' }
                                        | null
                                      targetCriteria: {
                                        __typename?: 'TargetCriteria'
                                        name: string
                                        targetLogic: string
                                        targetCriteria: Array<string>
                                        inRegion: Array<string> | null
                                      } | null
                                    } | null
                                  }
                              >
                            }
                          | {
                              __typename: 'NavTileSection'
                              id: string
                              tiles: Array<{
                                __typename?: 'NavTile'
                                ctaText: string
                                href: string
                                image: string
                              }>
                            }
                          | { __typename: 'RawHtmlSection'; id: string; html: string }
                          | null
                      }>
                    }
                  | { __typename: 'NavListGroupSection' }
                  | {
                      __typename: 'NavListSection'
                      id: string
                      variant: string
                      items: Array<
                        | { __typename?: 'AppNavListItem' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                            alternateModelVariant: {
                              __typename?: 'AlternateModelVariant'
                              name: string
                              action: string
                              validContentfulModel:
                                | { __typename?: 'NavAccordionSection' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                  }
                                | { __typename?: 'NavTab' }
                                | null
                              targetCriteria: {
                                __typename?: 'TargetCriteria'
                                name: string
                                targetLogic: string
                                targetCriteria: Array<string>
                                inRegion: Array<string> | null
                              } | null
                            } | null
                          }
                      >
                    }
                  | {
                      __typename: 'NavTileSection'
                      id: string
                      tiles: Array<{
                        __typename?: 'NavTile'
                        ctaText: string
                        href: string
                        image: string
                      }>
                    }
                  | { __typename: 'RawHtmlSection'; id: string; html: string }
                  | null
                alternateModelVariant: {
                  __typename?: 'AlternateModelVariant'
                  action: string
                  name: string
                  targetCriteria: {
                    __typename?: 'TargetCriteria'
                    name: string
                    targetLogic: string
                    targetCriteria: Array<string>
                    inRegion: Array<string> | null
                  } | null
                } | null
              }>
            }
          | {
              __typename: 'NavListGroupSection'
              id: string
              navSections: Array<{
                __typename?: 'NavListSection'
                id: string
                variant: string
                items: Array<
                  | { __typename?: 'AppNavListItem' }
                  | {
                      __typename?: 'NavListItem'
                      badge: string | null
                      badgeColor: string | null
                      badgeTextColor: string | null
                      href: string | null
                      icon: string | null
                      id: string
                      isBold: boolean | null
                      isHeading: boolean | null
                      text: string
                      textColor: string | null
                      htmlId: string | null
                      htmlClass: string | null
                      isBtn: boolean | null
                      alternateModelVariant: {
                        __typename?: 'AlternateModelVariant'
                        name: string
                        action: string
                        validContentfulModel:
                          | { __typename?: 'NavAccordionSection' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                            }
                          | { __typename?: 'NavTab' }
                          | null
                        targetCriteria: {
                          __typename?: 'TargetCriteria'
                          name: string
                          targetLogic: string
                          targetCriteria: Array<string>
                          inRegion: Array<string> | null
                        } | null
                      } | null
                    }
                >
              }>
            }
          | {
              __typename: 'NavListSection'
              id: string
              variant: string
              items: Array<
                | { __typename?: 'AppNavListItem' }
                | {
                    __typename?: 'NavListItem'
                    badge: string | null
                    badgeColor: string | null
                    badgeTextColor: string | null
                    href: string | null
                    icon: string | null
                    id: string
                    isBold: boolean | null
                    isHeading: boolean | null
                    text: string
                    textColor: string | null
                    htmlId: string | null
                    htmlClass: string | null
                    isBtn: boolean | null
                    alternateModelVariant: {
                      __typename?: 'AlternateModelVariant'
                      name: string
                      action: string
                      validContentfulModel:
                        | { __typename?: 'NavAccordionSection' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                          }
                        | { __typename?: 'NavTab' }
                        | null
                      targetCriteria: {
                        __typename?: 'TargetCriteria'
                        name: string
                        targetLogic: string
                        targetCriteria: Array<string>
                        inRegion: Array<string> | null
                      } | null
                    } | null
                  }
              >
            }
          | {
              __typename: 'NavTileSection'
              id: string
              tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
            }
          | { __typename: 'RawHtmlSection'; id: string; html: string }
        >
        alternateModelVariant: {
          __typename?: 'AlternateModelVariant'
          name: string
          action: string
          validContentfulModel:
            | { __typename?: 'NavAccordionSection' }
            | { __typename?: 'NavListItem' }
            | {
                __typename?: 'NavTab'
                href: string | null
                id: string
                text: string
                textColor: string | null
                sections: Array<
                  | {
                      __typename: 'NavAccordion'
                      id: string
                      variant: string
                      sections: Array<{
                        __typename?: 'NavAccordionSection'
                        id: string
                        header: {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                          alternateModelVariant: {
                            __typename?: 'AlternateModelVariant'
                            name: string
                            action: string
                            validContentfulModel:
                              | { __typename?: 'NavAccordionSection' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                }
                              | { __typename?: 'NavTab' }
                              | null
                            targetCriteria: {
                              __typename?: 'TargetCriteria'
                              name: string
                              targetLogic: string
                              targetCriteria: Array<string>
                              inRegion: Array<string> | null
                            } | null
                          } | null
                        }
                        body:
                          | {
                              __typename: 'NavAccordion'
                              id: string
                              sections: Array<{
                                __typename?: 'NavAccordionSection'
                                id: string
                                header: {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                  alternateModelVariant: {
                                    __typename?: 'AlternateModelVariant'
                                    name: string
                                    action: string
                                    validContentfulModel:
                                      | { __typename?: 'NavAccordionSection' }
                                      | {
                                          __typename?: 'NavListItem'
                                          badge: string | null
                                          badgeColor: string | null
                                          badgeTextColor: string | null
                                          href: string | null
                                          icon: string | null
                                          id: string
                                          isBold: boolean | null
                                          isHeading: boolean | null
                                          text: string
                                          textColor: string | null
                                          htmlId: string | null
                                          htmlClass: string | null
                                          isBtn: boolean | null
                                        }
                                      | { __typename?: 'NavTab' }
                                      | null
                                    targetCriteria: {
                                      __typename?: 'TargetCriteria'
                                      name: string
                                      targetLogic: string
                                      targetCriteria: Array<string>
                                      inRegion: Array<string> | null
                                    } | null
                                  } | null
                                }
                                body:
                                  | { __typename: 'NavAccordion' }
                                  | { __typename: 'NavListGroupSection' }
                                  | {
                                      __typename: 'NavListSection'
                                      id: string
                                      variant: string
                                      items: Array<
                                        | { __typename?: 'AppNavListItem' }
                                        | {
                                            __typename?: 'NavListItem'
                                            badge: string | null
                                            badgeColor: string | null
                                            badgeTextColor: string | null
                                            href: string | null
                                            icon: string | null
                                            id: string
                                            isBold: boolean | null
                                            isHeading: boolean | null
                                            text: string
                                            textColor: string | null
                                            htmlId: string | null
                                            htmlClass: string | null
                                            isBtn: boolean | null
                                            alternateModelVariant: {
                                              __typename?: 'AlternateModelVariant'
                                              name: string
                                              action: string
                                              validContentfulModel:
                                                | { __typename?: 'NavAccordionSection' }
                                                | {
                                                    __typename?: 'NavListItem'
                                                    badge: string | null
                                                    badgeColor: string | null
                                                    badgeTextColor: string | null
                                                    href: string | null
                                                    icon: string | null
                                                    id: string
                                                    isBold: boolean | null
                                                    isHeading: boolean | null
                                                    text: string
                                                    textColor: string | null
                                                    htmlId: string | null
                                                    htmlClass: string | null
                                                    isBtn: boolean | null
                                                  }
                                                | { __typename?: 'NavTab' }
                                                | null
                                              targetCriteria: {
                                                __typename?: 'TargetCriteria'
                                                name: string
                                                targetLogic: string
                                                targetCriteria: Array<string>
                                                inRegion: Array<string> | null
                                              } | null
                                            } | null
                                          }
                                      >
                                    }
                                  | {
                                      __typename: 'NavTileSection'
                                      id: string
                                      tiles: Array<{
                                        __typename?: 'NavTile'
                                        ctaText: string
                                        href: string
                                        image: string
                                      }>
                                    }
                                  | { __typename: 'RawHtmlSection'; id: string; html: string }
                                  | null
                              }>
                            }
                          | { __typename: 'NavListGroupSection' }
                          | {
                              __typename: 'NavListSection'
                              id: string
                              variant: string
                              items: Array<
                                | { __typename?: 'AppNavListItem' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                    alternateModelVariant: {
                                      __typename?: 'AlternateModelVariant'
                                      name: string
                                      action: string
                                      validContentfulModel:
                                        | { __typename?: 'NavAccordionSection' }
                                        | {
                                            __typename?: 'NavListItem'
                                            badge: string | null
                                            badgeColor: string | null
                                            badgeTextColor: string | null
                                            href: string | null
                                            icon: string | null
                                            id: string
                                            isBold: boolean | null
                                            isHeading: boolean | null
                                            text: string
                                            textColor: string | null
                                            htmlId: string | null
                                            htmlClass: string | null
                                            isBtn: boolean | null
                                          }
                                        | { __typename?: 'NavTab' }
                                        | null
                                      targetCriteria: {
                                        __typename?: 'TargetCriteria'
                                        name: string
                                        targetLogic: string
                                        targetCriteria: Array<string>
                                        inRegion: Array<string> | null
                                      } | null
                                    } | null
                                  }
                              >
                            }
                          | {
                              __typename: 'NavTileSection'
                              id: string
                              tiles: Array<{
                                __typename?: 'NavTile'
                                ctaText: string
                                href: string
                                image: string
                              }>
                            }
                          | { __typename: 'RawHtmlSection'; id: string; html: string }
                          | null
                        alternateModelVariant: {
                          __typename?: 'AlternateModelVariant'
                          action: string
                          name: string
                          targetCriteria: {
                            __typename?: 'TargetCriteria'
                            name: string
                            targetLogic: string
                            targetCriteria: Array<string>
                            inRegion: Array<string> | null
                          } | null
                        } | null
                      }>
                    }
                  | {
                      __typename: 'NavListGroupSection'
                      id: string
                      navSections: Array<{
                        __typename?: 'NavListSection'
                        id: string
                        variant: string
                        items: Array<
                          | { __typename?: 'AppNavListItem' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                              alternateModelVariant: {
                                __typename?: 'AlternateModelVariant'
                                name: string
                                action: string
                                validContentfulModel:
                                  | { __typename?: 'NavAccordionSection' }
                                  | {
                                      __typename?: 'NavListItem'
                                      badge: string | null
                                      badgeColor: string | null
                                      badgeTextColor: string | null
                                      href: string | null
                                      icon: string | null
                                      id: string
                                      isBold: boolean | null
                                      isHeading: boolean | null
                                      text: string
                                      textColor: string | null
                                      htmlId: string | null
                                      htmlClass: string | null
                                      isBtn: boolean | null
                                    }
                                  | { __typename?: 'NavTab' }
                                  | null
                                targetCriteria: {
                                  __typename?: 'TargetCriteria'
                                  name: string
                                  targetLogic: string
                                  targetCriteria: Array<string>
                                  inRegion: Array<string> | null
                                } | null
                              } | null
                            }
                        >
                      }>
                    }
                  | {
                      __typename: 'NavListSection'
                      id: string
                      variant: string
                      items: Array<
                        | { __typename?: 'AppNavListItem' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                            alternateModelVariant: {
                              __typename?: 'AlternateModelVariant'
                              name: string
                              action: string
                              validContentfulModel:
                                | { __typename?: 'NavAccordionSection' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                  }
                                | { __typename?: 'NavTab' }
                                | null
                              targetCriteria: {
                                __typename?: 'TargetCriteria'
                                name: string
                                targetLogic: string
                                targetCriteria: Array<string>
                                inRegion: Array<string> | null
                              } | null
                            } | null
                          }
                      >
                    }
                  | {
                      __typename: 'NavTileSection'
                      id: string
                      tiles: Array<{
                        __typename?: 'NavTile'
                        ctaText: string
                        href: string
                        image: string
                      }>
                    }
                  | { __typename: 'RawHtmlSection'; id: string; html: string }
                >
              }
            | null
          targetCriteria: {
            __typename?: 'TargetCriteria'
            name: string
            targetLogic: string
            targetCriteria: Array<string>
            inRegion: Array<string> | null
          } | null
        } | null
      }>
      mobileTabs: Array<{
        __typename?: 'NavTab'
        href: string | null
        id: string
        text: string
        textColor: string | null
        sections: Array<
          | {
              __typename: 'NavAccordion'
              id: string
              variant: string
              sections: Array<{
                __typename?: 'NavAccordionSection'
                id: string
                header: {
                  __typename?: 'NavListItem'
                  badge: string | null
                  badgeColor: string | null
                  badgeTextColor: string | null
                  href: string | null
                  icon: string | null
                  id: string
                  isBold: boolean | null
                  isHeading: boolean | null
                  text: string
                  textColor: string | null
                  htmlId: string | null
                  htmlClass: string | null
                  isBtn: boolean | null
                  alternateModelVariant: {
                    __typename?: 'AlternateModelVariant'
                    name: string
                    action: string
                    validContentfulModel:
                      | { __typename?: 'NavAccordionSection' }
                      | {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                        }
                      | { __typename?: 'NavTab' }
                      | null
                    targetCriteria: {
                      __typename?: 'TargetCriteria'
                      name: string
                      targetLogic: string
                      targetCriteria: Array<string>
                      inRegion: Array<string> | null
                    } | null
                  } | null
                }
                body:
                  | {
                      __typename: 'NavAccordion'
                      id: string
                      sections: Array<{
                        __typename?: 'NavAccordionSection'
                        id: string
                        header: {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                          alternateModelVariant: {
                            __typename?: 'AlternateModelVariant'
                            name: string
                            action: string
                            validContentfulModel:
                              | { __typename?: 'NavAccordionSection' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                }
                              | { __typename?: 'NavTab' }
                              | null
                            targetCriteria: {
                              __typename?: 'TargetCriteria'
                              name: string
                              targetLogic: string
                              targetCriteria: Array<string>
                              inRegion: Array<string> | null
                            } | null
                          } | null
                        }
                        body:
                          | { __typename: 'NavAccordion' }
                          | { __typename: 'NavListGroupSection' }
                          | {
                              __typename: 'NavListSection'
                              id: string
                              variant: string
                              items: Array<
                                | { __typename?: 'AppNavListItem' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                    alternateModelVariant: {
                                      __typename?: 'AlternateModelVariant'
                                      name: string
                                      action: string
                                      validContentfulModel:
                                        | { __typename?: 'NavAccordionSection' }
                                        | {
                                            __typename?: 'NavListItem'
                                            badge: string | null
                                            badgeColor: string | null
                                            badgeTextColor: string | null
                                            href: string | null
                                            icon: string | null
                                            id: string
                                            isBold: boolean | null
                                            isHeading: boolean | null
                                            text: string
                                            textColor: string | null
                                            htmlId: string | null
                                            htmlClass: string | null
                                            isBtn: boolean | null
                                          }
                                        | { __typename?: 'NavTab' }
                                        | null
                                      targetCriteria: {
                                        __typename?: 'TargetCriteria'
                                        name: string
                                        targetLogic: string
                                        targetCriteria: Array<string>
                                        inRegion: Array<string> | null
                                      } | null
                                    } | null
                                  }
                              >
                            }
                          | {
                              __typename: 'NavTileSection'
                              id: string
                              tiles: Array<{
                                __typename?: 'NavTile'
                                ctaText: string
                                href: string
                                image: string
                              }>
                            }
                          | { __typename: 'RawHtmlSection'; id: string; html: string }
                          | null
                      }>
                    }
                  | { __typename: 'NavListGroupSection' }
                  | {
                      __typename: 'NavListSection'
                      id: string
                      variant: string
                      items: Array<
                        | { __typename?: 'AppNavListItem' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                            alternateModelVariant: {
                              __typename?: 'AlternateModelVariant'
                              name: string
                              action: string
                              validContentfulModel:
                                | { __typename?: 'NavAccordionSection' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                  }
                                | { __typename?: 'NavTab' }
                                | null
                              targetCriteria: {
                                __typename?: 'TargetCriteria'
                                name: string
                                targetLogic: string
                                targetCriteria: Array<string>
                                inRegion: Array<string> | null
                              } | null
                            } | null
                          }
                      >
                    }
                  | {
                      __typename: 'NavTileSection'
                      id: string
                      tiles: Array<{
                        __typename?: 'NavTile'
                        ctaText: string
                        href: string
                        image: string
                      }>
                    }
                  | { __typename: 'RawHtmlSection'; id: string; html: string }
                  | null
                alternateModelVariant: {
                  __typename?: 'AlternateModelVariant'
                  action: string
                  name: string
                  targetCriteria: {
                    __typename?: 'TargetCriteria'
                    name: string
                    targetLogic: string
                    targetCriteria: Array<string>
                    inRegion: Array<string> | null
                  } | null
                } | null
              }>
            }
          | {
              __typename: 'NavListGroupSection'
              id: string
              navSections: Array<{
                __typename?: 'NavListSection'
                id: string
                variant: string
                items: Array<
                  | { __typename?: 'AppNavListItem' }
                  | {
                      __typename?: 'NavListItem'
                      badge: string | null
                      badgeColor: string | null
                      badgeTextColor: string | null
                      href: string | null
                      icon: string | null
                      id: string
                      isBold: boolean | null
                      isHeading: boolean | null
                      text: string
                      textColor: string | null
                      htmlId: string | null
                      htmlClass: string | null
                      isBtn: boolean | null
                      alternateModelVariant: {
                        __typename?: 'AlternateModelVariant'
                        name: string
                        action: string
                        validContentfulModel:
                          | { __typename?: 'NavAccordionSection' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                            }
                          | { __typename?: 'NavTab' }
                          | null
                        targetCriteria: {
                          __typename?: 'TargetCriteria'
                          name: string
                          targetLogic: string
                          targetCriteria: Array<string>
                          inRegion: Array<string> | null
                        } | null
                      } | null
                    }
                >
              }>
            }
          | {
              __typename: 'NavListSection'
              id: string
              variant: string
              items: Array<
                | { __typename?: 'AppNavListItem' }
                | {
                    __typename?: 'NavListItem'
                    badge: string | null
                    badgeColor: string | null
                    badgeTextColor: string | null
                    href: string | null
                    icon: string | null
                    id: string
                    isBold: boolean | null
                    isHeading: boolean | null
                    text: string
                    textColor: string | null
                    htmlId: string | null
                    htmlClass: string | null
                    isBtn: boolean | null
                    alternateModelVariant: {
                      __typename?: 'AlternateModelVariant'
                      name: string
                      action: string
                      validContentfulModel:
                        | { __typename?: 'NavAccordionSection' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                          }
                        | { __typename?: 'NavTab' }
                        | null
                      targetCriteria: {
                        __typename?: 'TargetCriteria'
                        name: string
                        targetLogic: string
                        targetCriteria: Array<string>
                        inRegion: Array<string> | null
                      } | null
                    } | null
                  }
              >
            }
          | {
              __typename: 'NavTileSection'
              id: string
              tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
            }
          | { __typename: 'RawHtmlSection'; id: string; html: string }
        >
        alternateModelVariant: {
          __typename?: 'AlternateModelVariant'
          name: string
          action: string
          validContentfulModel:
            | { __typename?: 'NavAccordionSection' }
            | { __typename?: 'NavListItem' }
            | {
                __typename?: 'NavTab'
                href: string | null
                id: string
                text: string
                textColor: string | null
                sections: Array<
                  | {
                      __typename: 'NavAccordion'
                      id: string
                      variant: string
                      sections: Array<{
                        __typename?: 'NavAccordionSection'
                        id: string
                        header: {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                          alternateModelVariant: {
                            __typename?: 'AlternateModelVariant'
                            name: string
                            action: string
                            validContentfulModel:
                              | { __typename?: 'NavAccordionSection' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                }
                              | { __typename?: 'NavTab' }
                              | null
                            targetCriteria: {
                              __typename?: 'TargetCriteria'
                              name: string
                              targetLogic: string
                              targetCriteria: Array<string>
                              inRegion: Array<string> | null
                            } | null
                          } | null
                        }
                        body:
                          | {
                              __typename: 'NavAccordion'
                              id: string
                              sections: Array<{
                                __typename?: 'NavAccordionSection'
                                id: string
                                header: {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                  alternateModelVariant: {
                                    __typename?: 'AlternateModelVariant'
                                    name: string
                                    action: string
                                    validContentfulModel:
                                      | { __typename?: 'NavAccordionSection' }
                                      | {
                                          __typename?: 'NavListItem'
                                          badge: string | null
                                          badgeColor: string | null
                                          badgeTextColor: string | null
                                          href: string | null
                                          icon: string | null
                                          id: string
                                          isBold: boolean | null
                                          isHeading: boolean | null
                                          text: string
                                          textColor: string | null
                                          htmlId: string | null
                                          htmlClass: string | null
                                          isBtn: boolean | null
                                        }
                                      | { __typename?: 'NavTab' }
                                      | null
                                    targetCriteria: {
                                      __typename?: 'TargetCriteria'
                                      name: string
                                      targetLogic: string
                                      targetCriteria: Array<string>
                                      inRegion: Array<string> | null
                                    } | null
                                  } | null
                                }
                                body:
                                  | { __typename: 'NavAccordion' }
                                  | { __typename: 'NavListGroupSection' }
                                  | {
                                      __typename: 'NavListSection'
                                      id: string
                                      variant: string
                                      items: Array<
                                        | { __typename?: 'AppNavListItem' }
                                        | {
                                            __typename?: 'NavListItem'
                                            badge: string | null
                                            badgeColor: string | null
                                            badgeTextColor: string | null
                                            href: string | null
                                            icon: string | null
                                            id: string
                                            isBold: boolean | null
                                            isHeading: boolean | null
                                            text: string
                                            textColor: string | null
                                            htmlId: string | null
                                            htmlClass: string | null
                                            isBtn: boolean | null
                                            alternateModelVariant: {
                                              __typename?: 'AlternateModelVariant'
                                              name: string
                                              action: string
                                              validContentfulModel:
                                                | { __typename?: 'NavAccordionSection' }
                                                | {
                                                    __typename?: 'NavListItem'
                                                    badge: string | null
                                                    badgeColor: string | null
                                                    badgeTextColor: string | null
                                                    href: string | null
                                                    icon: string | null
                                                    id: string
                                                    isBold: boolean | null
                                                    isHeading: boolean | null
                                                    text: string
                                                    textColor: string | null
                                                    htmlId: string | null
                                                    htmlClass: string | null
                                                    isBtn: boolean | null
                                                  }
                                                | { __typename?: 'NavTab' }
                                                | null
                                              targetCriteria: {
                                                __typename?: 'TargetCriteria'
                                                name: string
                                                targetLogic: string
                                                targetCriteria: Array<string>
                                                inRegion: Array<string> | null
                                              } | null
                                            } | null
                                          }
                                      >
                                    }
                                  | {
                                      __typename: 'NavTileSection'
                                      id: string
                                      tiles: Array<{
                                        __typename?: 'NavTile'
                                        ctaText: string
                                        href: string
                                        image: string
                                      }>
                                    }
                                  | { __typename: 'RawHtmlSection'; id: string; html: string }
                                  | null
                              }>
                            }
                          | { __typename: 'NavListGroupSection' }
                          | {
                              __typename: 'NavListSection'
                              id: string
                              variant: string
                              items: Array<
                                | { __typename?: 'AppNavListItem' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                    alternateModelVariant: {
                                      __typename?: 'AlternateModelVariant'
                                      name: string
                                      action: string
                                      validContentfulModel:
                                        | { __typename?: 'NavAccordionSection' }
                                        | {
                                            __typename?: 'NavListItem'
                                            badge: string | null
                                            badgeColor: string | null
                                            badgeTextColor: string | null
                                            href: string | null
                                            icon: string | null
                                            id: string
                                            isBold: boolean | null
                                            isHeading: boolean | null
                                            text: string
                                            textColor: string | null
                                            htmlId: string | null
                                            htmlClass: string | null
                                            isBtn: boolean | null
                                          }
                                        | { __typename?: 'NavTab' }
                                        | null
                                      targetCriteria: {
                                        __typename?: 'TargetCriteria'
                                        name: string
                                        targetLogic: string
                                        targetCriteria: Array<string>
                                        inRegion: Array<string> | null
                                      } | null
                                    } | null
                                  }
                              >
                            }
                          | {
                              __typename: 'NavTileSection'
                              id: string
                              tiles: Array<{
                                __typename?: 'NavTile'
                                ctaText: string
                                href: string
                                image: string
                              }>
                            }
                          | { __typename: 'RawHtmlSection'; id: string; html: string }
                          | null
                        alternateModelVariant: {
                          __typename?: 'AlternateModelVariant'
                          action: string
                          name: string
                          targetCriteria: {
                            __typename?: 'TargetCriteria'
                            name: string
                            targetLogic: string
                            targetCriteria: Array<string>
                            inRegion: Array<string> | null
                          } | null
                        } | null
                      }>
                    }
                  | {
                      __typename: 'NavListGroupSection'
                      id: string
                      navSections: Array<{
                        __typename?: 'NavListSection'
                        id: string
                        variant: string
                        items: Array<
                          | { __typename?: 'AppNavListItem' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                              alternateModelVariant: {
                                __typename?: 'AlternateModelVariant'
                                name: string
                                action: string
                                validContentfulModel:
                                  | { __typename?: 'NavAccordionSection' }
                                  | {
                                      __typename?: 'NavListItem'
                                      badge: string | null
                                      badgeColor: string | null
                                      badgeTextColor: string | null
                                      href: string | null
                                      icon: string | null
                                      id: string
                                      isBold: boolean | null
                                      isHeading: boolean | null
                                      text: string
                                      textColor: string | null
                                      htmlId: string | null
                                      htmlClass: string | null
                                      isBtn: boolean | null
                                    }
                                  | { __typename?: 'NavTab' }
                                  | null
                                targetCriteria: {
                                  __typename?: 'TargetCriteria'
                                  name: string
                                  targetLogic: string
                                  targetCriteria: Array<string>
                                  inRegion: Array<string> | null
                                } | null
                              } | null
                            }
                        >
                      }>
                    }
                  | {
                      __typename: 'NavListSection'
                      id: string
                      variant: string
                      items: Array<
                        | { __typename?: 'AppNavListItem' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                            alternateModelVariant: {
                              __typename?: 'AlternateModelVariant'
                              name: string
                              action: string
                              validContentfulModel:
                                | { __typename?: 'NavAccordionSection' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                  }
                                | { __typename?: 'NavTab' }
                                | null
                              targetCriteria: {
                                __typename?: 'TargetCriteria'
                                name: string
                                targetLogic: string
                                targetCriteria: Array<string>
                                inRegion: Array<string> | null
                              } | null
                            } | null
                          }
                      >
                    }
                  | {
                      __typename: 'NavTileSection'
                      id: string
                      tiles: Array<{
                        __typename?: 'NavTile'
                        ctaText: string
                        href: string
                        image: string
                      }>
                    }
                  | { __typename: 'RawHtmlSection'; id: string; html: string }
                >
              }
            | null
          targetCriteria: {
            __typename?: 'TargetCriteria'
            name: string
            targetLogic: string
            targetCriteria: Array<string>
            inRegion: Array<string> | null
          } | null
        } | null
      }>
    }
    tooltips: Array<{
      __typename?: 'Tooltip'
      id: string
      contentType: string
      title: string
      description: string
      enumValue: { __typename?: 'EnumeratedValue'; name: string }
    }> | null
    footer: {
      __typename?: 'SiteFooter'
      copyrightLine: string | null
      accessibilityButtonText: string | null
      desktopSections: Array<
        | {
            __typename: 'NavAccordion'
            id: string
            variant: string
            sections: Array<{
              __typename?: 'NavAccordionSection'
              id: string
              header: {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
                alternateModelVariant: {
                  __typename?: 'AlternateModelVariant'
                  name: string
                  action: string
                  validContentfulModel:
                    | { __typename?: 'NavAccordionSection' }
                    | {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                      }
                    | { __typename?: 'NavTab' }
                    | null
                  targetCriteria: {
                    __typename?: 'TargetCriteria'
                    name: string
                    targetLogic: string
                    targetCriteria: Array<string>
                    inRegion: Array<string> | null
                  } | null
                } | null
              }
              body:
                | {
                    __typename: 'NavAccordion'
                    id: string
                    sections: Array<{
                      __typename?: 'NavAccordionSection'
                      id: string
                      header: {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                        alternateModelVariant: {
                          __typename?: 'AlternateModelVariant'
                          name: string
                          action: string
                          validContentfulModel:
                            | { __typename?: 'NavAccordionSection' }
                            | {
                                __typename?: 'NavListItem'
                                badge: string | null
                                badgeColor: string | null
                                badgeTextColor: string | null
                                href: string | null
                                icon: string | null
                                id: string
                                isBold: boolean | null
                                isHeading: boolean | null
                                text: string
                                textColor: string | null
                                htmlId: string | null
                                htmlClass: string | null
                                isBtn: boolean | null
                              }
                            | { __typename?: 'NavTab' }
                            | null
                          targetCriteria: {
                            __typename?: 'TargetCriteria'
                            name: string
                            targetLogic: string
                            targetCriteria: Array<string>
                            inRegion: Array<string> | null
                          } | null
                        } | null
                      }
                      body:
                        | { __typename: 'NavAccordion' }
                        | { __typename: 'NavListGroupSection' }
                        | {
                            __typename: 'NavListSection'
                            id: string
                            variant: string
                            items: Array<
                              | { __typename?: 'AppNavListItem' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                  alternateModelVariant: {
                                    __typename?: 'AlternateModelVariant'
                                    name: string
                                    action: string
                                    validContentfulModel:
                                      | { __typename?: 'NavAccordionSection' }
                                      | {
                                          __typename?: 'NavListItem'
                                          badge: string | null
                                          badgeColor: string | null
                                          badgeTextColor: string | null
                                          href: string | null
                                          icon: string | null
                                          id: string
                                          isBold: boolean | null
                                          isHeading: boolean | null
                                          text: string
                                          textColor: string | null
                                          htmlId: string | null
                                          htmlClass: string | null
                                          isBtn: boolean | null
                                        }
                                      | { __typename?: 'NavTab' }
                                      | null
                                    targetCriteria: {
                                      __typename?: 'TargetCriteria'
                                      name: string
                                      targetLogic: string
                                      targetCriteria: Array<string>
                                      inRegion: Array<string> | null
                                    } | null
                                  } | null
                                }
                            >
                          }
                        | {
                            __typename: 'NavTileSection'
                            id: string
                            tiles: Array<{
                              __typename?: 'NavTile'
                              ctaText: string
                              href: string
                              image: string
                            }>
                          }
                        | { __typename: 'RawHtmlSection'; id: string; html: string }
                        | null
                    }>
                  }
                | { __typename: 'NavListGroupSection' }
                | {
                    __typename: 'NavListSection'
                    id: string
                    variant: string
                    items: Array<
                      | { __typename?: 'AppNavListItem' }
                      | {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                          alternateModelVariant: {
                            __typename?: 'AlternateModelVariant'
                            name: string
                            action: string
                            validContentfulModel:
                              | { __typename?: 'NavAccordionSection' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                }
                              | { __typename?: 'NavTab' }
                              | null
                            targetCriteria: {
                              __typename?: 'TargetCriteria'
                              name: string
                              targetLogic: string
                              targetCriteria: Array<string>
                              inRegion: Array<string> | null
                            } | null
                          } | null
                        }
                    >
                  }
                | {
                    __typename: 'NavTileSection'
                    id: string
                    tiles: Array<{
                      __typename?: 'NavTile'
                      ctaText: string
                      href: string
                      image: string
                    }>
                  }
                | { __typename: 'RawHtmlSection'; id: string; html: string }
                | null
              alternateModelVariant: {
                __typename?: 'AlternateModelVariant'
                action: string
                name: string
                targetCriteria: {
                  __typename?: 'TargetCriteria'
                  name: string
                  targetLogic: string
                  targetCriteria: Array<string>
                  inRegion: Array<string> | null
                } | null
              } | null
            }>
          }
        | { __typename: 'NavListGroupSection' }
        | {
            __typename: 'NavListSection'
            id: string
            variant: string
            items: Array<
              | { __typename?: 'AppNavListItem' }
              | {
                  __typename?: 'NavListItem'
                  badge: string | null
                  badgeColor: string | null
                  badgeTextColor: string | null
                  href: string | null
                  icon: string | null
                  id: string
                  isBold: boolean | null
                  isHeading: boolean | null
                  text: string
                  textColor: string | null
                  htmlId: string | null
                  htmlClass: string | null
                  isBtn: boolean | null
                  alternateModelVariant: {
                    __typename?: 'AlternateModelVariant'
                    name: string
                    action: string
                    validContentfulModel:
                      | { __typename?: 'NavAccordionSection' }
                      | {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                        }
                      | { __typename?: 'NavTab' }
                      | null
                    targetCriteria: {
                      __typename?: 'TargetCriteria'
                      name: string
                      targetLogic: string
                      targetCriteria: Array<string>
                      inRegion: Array<string> | null
                    } | null
                  } | null
                }
            >
          }
        | {
            __typename: 'NavTileSection'
            id: string
            tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
          }
        | { __typename: 'RawHtmlSection'; id: string; html: string }
      >
      mobileSections: Array<
        | {
            __typename: 'NavAccordion'
            id: string
            variant: string
            sections: Array<{
              __typename?: 'NavAccordionSection'
              id: string
              header: {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
                alternateModelVariant: {
                  __typename?: 'AlternateModelVariant'
                  name: string
                  action: string
                  validContentfulModel:
                    | { __typename?: 'NavAccordionSection' }
                    | {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                      }
                    | { __typename?: 'NavTab' }
                    | null
                  targetCriteria: {
                    __typename?: 'TargetCriteria'
                    name: string
                    targetLogic: string
                    targetCriteria: Array<string>
                    inRegion: Array<string> | null
                  } | null
                } | null
              }
              body:
                | {
                    __typename: 'NavAccordion'
                    id: string
                    sections: Array<{
                      __typename?: 'NavAccordionSection'
                      id: string
                      header: {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                        alternateModelVariant: {
                          __typename?: 'AlternateModelVariant'
                          name: string
                          action: string
                          validContentfulModel:
                            | { __typename?: 'NavAccordionSection' }
                            | {
                                __typename?: 'NavListItem'
                                badge: string | null
                                badgeColor: string | null
                                badgeTextColor: string | null
                                href: string | null
                                icon: string | null
                                id: string
                                isBold: boolean | null
                                isHeading: boolean | null
                                text: string
                                textColor: string | null
                                htmlId: string | null
                                htmlClass: string | null
                                isBtn: boolean | null
                              }
                            | { __typename?: 'NavTab' }
                            | null
                          targetCriteria: {
                            __typename?: 'TargetCriteria'
                            name: string
                            targetLogic: string
                            targetCriteria: Array<string>
                            inRegion: Array<string> | null
                          } | null
                        } | null
                      }
                      body:
                        | { __typename: 'NavAccordion' }
                        | { __typename: 'NavListGroupSection' }
                        | {
                            __typename: 'NavListSection'
                            id: string
                            variant: string
                            items: Array<
                              | { __typename?: 'AppNavListItem' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                  alternateModelVariant: {
                                    __typename?: 'AlternateModelVariant'
                                    name: string
                                    action: string
                                    validContentfulModel:
                                      | { __typename?: 'NavAccordionSection' }
                                      | {
                                          __typename?: 'NavListItem'
                                          badge: string | null
                                          badgeColor: string | null
                                          badgeTextColor: string | null
                                          href: string | null
                                          icon: string | null
                                          id: string
                                          isBold: boolean | null
                                          isHeading: boolean | null
                                          text: string
                                          textColor: string | null
                                          htmlId: string | null
                                          htmlClass: string | null
                                          isBtn: boolean | null
                                        }
                                      | { __typename?: 'NavTab' }
                                      | null
                                    targetCriteria: {
                                      __typename?: 'TargetCriteria'
                                      name: string
                                      targetLogic: string
                                      targetCriteria: Array<string>
                                      inRegion: Array<string> | null
                                    } | null
                                  } | null
                                }
                            >
                          }
                        | {
                            __typename: 'NavTileSection'
                            id: string
                            tiles: Array<{
                              __typename?: 'NavTile'
                              ctaText: string
                              href: string
                              image: string
                            }>
                          }
                        | { __typename: 'RawHtmlSection'; id: string; html: string }
                        | null
                    }>
                  }
                | { __typename: 'NavListGroupSection' }
                | {
                    __typename: 'NavListSection'
                    id: string
                    variant: string
                    items: Array<
                      | { __typename?: 'AppNavListItem' }
                      | {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                          alternateModelVariant: {
                            __typename?: 'AlternateModelVariant'
                            name: string
                            action: string
                            validContentfulModel:
                              | { __typename?: 'NavAccordionSection' }
                              | {
                                  __typename?: 'NavListItem'
                                  badge: string | null
                                  badgeColor: string | null
                                  badgeTextColor: string | null
                                  href: string | null
                                  icon: string | null
                                  id: string
                                  isBold: boolean | null
                                  isHeading: boolean | null
                                  text: string
                                  textColor: string | null
                                  htmlId: string | null
                                  htmlClass: string | null
                                  isBtn: boolean | null
                                }
                              | { __typename?: 'NavTab' }
                              | null
                            targetCriteria: {
                              __typename?: 'TargetCriteria'
                              name: string
                              targetLogic: string
                              targetCriteria: Array<string>
                              inRegion: Array<string> | null
                            } | null
                          } | null
                        }
                    >
                  }
                | {
                    __typename: 'NavTileSection'
                    id: string
                    tiles: Array<{
                      __typename?: 'NavTile'
                      ctaText: string
                      href: string
                      image: string
                    }>
                  }
                | { __typename: 'RawHtmlSection'; id: string; html: string }
                | null
              alternateModelVariant: {
                __typename?: 'AlternateModelVariant'
                action: string
                name: string
                targetCriteria: {
                  __typename?: 'TargetCriteria'
                  name: string
                  targetLogic: string
                  targetCriteria: Array<string>
                  inRegion: Array<string> | null
                } | null
              } | null
            }>
          }
        | { __typename: 'NavListGroupSection' }
        | {
            __typename: 'NavListSection'
            id: string
            variant: string
            items: Array<
              | { __typename?: 'AppNavListItem' }
              | {
                  __typename?: 'NavListItem'
                  badge: string | null
                  badgeColor: string | null
                  badgeTextColor: string | null
                  href: string | null
                  icon: string | null
                  id: string
                  isBold: boolean | null
                  isHeading: boolean | null
                  text: string
                  textColor: string | null
                  htmlId: string | null
                  htmlClass: string | null
                  isBtn: boolean | null
                  alternateModelVariant: {
                    __typename?: 'AlternateModelVariant'
                    name: string
                    action: string
                    validContentfulModel:
                      | { __typename?: 'NavAccordionSection' }
                      | {
                          __typename?: 'NavListItem'
                          badge: string | null
                          badgeColor: string | null
                          badgeTextColor: string | null
                          href: string | null
                          icon: string | null
                          id: string
                          isBold: boolean | null
                          isHeading: boolean | null
                          text: string
                          textColor: string | null
                          htmlId: string | null
                          htmlClass: string | null
                          isBtn: boolean | null
                        }
                      | { __typename?: 'NavTab' }
                      | null
                    targetCriteria: {
                      __typename?: 'TargetCriteria'
                      name: string
                      targetLogic: string
                      targetCriteria: Array<string>
                      inRegion: Array<string> | null
                    } | null
                  } | null
                }
            >
          }
        | {
            __typename: 'NavTileSection'
            id: string
            tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
          }
        | { __typename: 'RawHtmlSection'; id: string; html: string }
      >
      socialLinks: Array<{
        __typename?: 'NavListItem'
        badge: string | null
        badgeColor: string | null
        badgeTextColor: string | null
        href: string | null
        icon: string | null
        id: string
        isBold: boolean | null
        isHeading: boolean | null
        text: string
        textColor: string | null
        htmlId: string | null
        htmlClass: string | null
        isBtn: boolean | null
        alternateModelVariant: {
          __typename?: 'AlternateModelVariant'
          name: string
          action: string
          validContentfulModel:
            | { __typename?: 'NavAccordionSection' }
            | {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
              }
            | { __typename?: 'NavTab' }
            | null
          targetCriteria: {
            __typename?: 'TargetCriteria'
            name: string
            targetLogic: string
            targetCriteria: Array<string>
            inRegion: Array<string> | null
          } | null
        } | null
      }>
      legalLinks: Array<{
        __typename?: 'NavListItem'
        badge: string | null
        badgeColor: string | null
        badgeTextColor: string | null
        href: string | null
        icon: string | null
        id: string
        isBold: boolean | null
        isHeading: boolean | null
        text: string
        textColor: string | null
        htmlId: string | null
        htmlClass: string | null
        isBtn: boolean | null
        alternateModelVariant: {
          __typename?: 'AlternateModelVariant'
          name: string
          action: string
          validContentfulModel:
            | { __typename?: 'NavAccordionSection' }
            | {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
              }
            | { __typename?: 'NavTab' }
            | null
          targetCriteria: {
            __typename?: 'TargetCriteria'
            name: string
            targetLogic: string
            targetCriteria: Array<string>
            inRegion: Array<string> | null
          } | null
        } | null
      }>
    }
    promoBar: {
      __typename?: 'PromoBar'
      backgroundColor: string
      textColor: string
      pages: Array<{ __typename?: 'PromoBarPage'; id: string; content: string }>
    } | null
    regionData: {
      __typename?: 'Regions'
      regions: Array<{
        __typename?: 'Region'
        id: string
        name: string
        flagSrc: string | null
        shippingText: string | null
        taxAndDutyText: string | null
        welcomeShippingText: string | null
        welcomeTaxAndDutyText: string | null
        promoBarRegion: {
          __typename?: 'PromoBar'
          backgroundColor: string
          textColor: string
          pages: Array<{ __typename?: 'PromoBarPage'; id: string; content: string }>
        } | null
      }>
    } | null
    localeData: {
      __typename?: 'Locales'
      name: string
      locales: Array<{ __typename?: 'Locale'; language: string; locale: string }>
    } | null
  }
}

export type NavTabFragment = {
  __typename?: 'NavTab'
  href: string | null
  id: string
  text: string
  textColor: string | null
  sections: Array<
    | {
        __typename: 'NavAccordion'
        id: string
        variant: string
        sections: Array<{
          __typename?: 'NavAccordionSection'
          id: string
          header: {
            __typename?: 'NavListItem'
            badge: string | null
            badgeColor: string | null
            badgeTextColor: string | null
            href: string | null
            icon: string | null
            id: string
            isBold: boolean | null
            isHeading: boolean | null
            text: string
            textColor: string | null
            htmlId: string | null
            htmlClass: string | null
            isBtn: boolean | null
            alternateModelVariant: {
              __typename?: 'AlternateModelVariant'
              name: string
              action: string
              validContentfulModel:
                | { __typename?: 'NavAccordionSection' }
                | {
                    __typename?: 'NavListItem'
                    badge: string | null
                    badgeColor: string | null
                    badgeTextColor: string | null
                    href: string | null
                    icon: string | null
                    id: string
                    isBold: boolean | null
                    isHeading: boolean | null
                    text: string
                    textColor: string | null
                    htmlId: string | null
                    htmlClass: string | null
                    isBtn: boolean | null
                  }
                | { __typename?: 'NavTab' }
                | null
              targetCriteria: {
                __typename?: 'TargetCriteria'
                name: string
                targetLogic: string
                targetCriteria: Array<string>
                inRegion: Array<string> | null
              } | null
            } | null
          }
          body:
            | {
                __typename: 'NavAccordion'
                id: string
                sections: Array<{
                  __typename?: 'NavAccordionSection'
                  id: string
                  header: {
                    __typename?: 'NavListItem'
                    badge: string | null
                    badgeColor: string | null
                    badgeTextColor: string | null
                    href: string | null
                    icon: string | null
                    id: string
                    isBold: boolean | null
                    isHeading: boolean | null
                    text: string
                    textColor: string | null
                    htmlId: string | null
                    htmlClass: string | null
                    isBtn: boolean | null
                    alternateModelVariant: {
                      __typename?: 'AlternateModelVariant'
                      name: string
                      action: string
                      validContentfulModel:
                        | { __typename?: 'NavAccordionSection' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                          }
                        | { __typename?: 'NavTab' }
                        | null
                      targetCriteria: {
                        __typename?: 'TargetCriteria'
                        name: string
                        targetLogic: string
                        targetCriteria: Array<string>
                        inRegion: Array<string> | null
                      } | null
                    } | null
                  }
                  body:
                    | { __typename: 'NavAccordion' }
                    | { __typename: 'NavListGroupSection' }
                    | {
                        __typename: 'NavListSection'
                        id: string
                        variant: string
                        items: Array<
                          | { __typename?: 'AppNavListItem' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                              alternateModelVariant: {
                                __typename?: 'AlternateModelVariant'
                                name: string
                                action: string
                                validContentfulModel:
                                  | { __typename?: 'NavAccordionSection' }
                                  | {
                                      __typename?: 'NavListItem'
                                      badge: string | null
                                      badgeColor: string | null
                                      badgeTextColor: string | null
                                      href: string | null
                                      icon: string | null
                                      id: string
                                      isBold: boolean | null
                                      isHeading: boolean | null
                                      text: string
                                      textColor: string | null
                                      htmlId: string | null
                                      htmlClass: string | null
                                      isBtn: boolean | null
                                    }
                                  | { __typename?: 'NavTab' }
                                  | null
                                targetCriteria: {
                                  __typename?: 'TargetCriteria'
                                  name: string
                                  targetLogic: string
                                  targetCriteria: Array<string>
                                  inRegion: Array<string> | null
                                } | null
                              } | null
                            }
                        >
                      }
                    | {
                        __typename: 'NavTileSection'
                        id: string
                        tiles: Array<{
                          __typename?: 'NavTile'
                          ctaText: string
                          href: string
                          image: string
                        }>
                      }
                    | { __typename: 'RawHtmlSection'; id: string; html: string }
                    | null
                }>
              }
            | { __typename: 'NavListGroupSection' }
            | {
                __typename: 'NavListSection'
                id: string
                variant: string
                items: Array<
                  | { __typename?: 'AppNavListItem' }
                  | {
                      __typename?: 'NavListItem'
                      badge: string | null
                      badgeColor: string | null
                      badgeTextColor: string | null
                      href: string | null
                      icon: string | null
                      id: string
                      isBold: boolean | null
                      isHeading: boolean | null
                      text: string
                      textColor: string | null
                      htmlId: string | null
                      htmlClass: string | null
                      isBtn: boolean | null
                      alternateModelVariant: {
                        __typename?: 'AlternateModelVariant'
                        name: string
                        action: string
                        validContentfulModel:
                          | { __typename?: 'NavAccordionSection' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                            }
                          | { __typename?: 'NavTab' }
                          | null
                        targetCriteria: {
                          __typename?: 'TargetCriteria'
                          name: string
                          targetLogic: string
                          targetCriteria: Array<string>
                          inRegion: Array<string> | null
                        } | null
                      } | null
                    }
                >
              }
            | {
                __typename: 'NavTileSection'
                id: string
                tiles: Array<{
                  __typename?: 'NavTile'
                  ctaText: string
                  href: string
                  image: string
                }>
              }
            | { __typename: 'RawHtmlSection'; id: string; html: string }
            | null
          alternateModelVariant: {
            __typename?: 'AlternateModelVariant'
            action: string
            name: string
            targetCriteria: {
              __typename?: 'TargetCriteria'
              name: string
              targetLogic: string
              targetCriteria: Array<string>
              inRegion: Array<string> | null
            } | null
          } | null
        }>
      }
    | {
        __typename: 'NavListGroupSection'
        id: string
        navSections: Array<{
          __typename?: 'NavListSection'
          id: string
          variant: string
          items: Array<
            | { __typename?: 'AppNavListItem' }
            | {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
                alternateModelVariant: {
                  __typename?: 'AlternateModelVariant'
                  name: string
                  action: string
                  validContentfulModel:
                    | { __typename?: 'NavAccordionSection' }
                    | {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                      }
                    | { __typename?: 'NavTab' }
                    | null
                  targetCriteria: {
                    __typename?: 'TargetCriteria'
                    name: string
                    targetLogic: string
                    targetCriteria: Array<string>
                    inRegion: Array<string> | null
                  } | null
                } | null
              }
          >
        }>
      }
    | {
        __typename: 'NavListSection'
        id: string
        variant: string
        items: Array<
          | { __typename?: 'AppNavListItem' }
          | {
              __typename?: 'NavListItem'
              badge: string | null
              badgeColor: string | null
              badgeTextColor: string | null
              href: string | null
              icon: string | null
              id: string
              isBold: boolean | null
              isHeading: boolean | null
              text: string
              textColor: string | null
              htmlId: string | null
              htmlClass: string | null
              isBtn: boolean | null
              alternateModelVariant: {
                __typename?: 'AlternateModelVariant'
                name: string
                action: string
                validContentfulModel:
                  | { __typename?: 'NavAccordionSection' }
                  | {
                      __typename?: 'NavListItem'
                      badge: string | null
                      badgeColor: string | null
                      badgeTextColor: string | null
                      href: string | null
                      icon: string | null
                      id: string
                      isBold: boolean | null
                      isHeading: boolean | null
                      text: string
                      textColor: string | null
                      htmlId: string | null
                      htmlClass: string | null
                      isBtn: boolean | null
                    }
                  | { __typename?: 'NavTab' }
                  | null
                targetCriteria: {
                  __typename?: 'TargetCriteria'
                  name: string
                  targetLogic: string
                  targetCriteria: Array<string>
                  inRegion: Array<string> | null
                } | null
              } | null
            }
        >
      }
    | {
        __typename: 'NavTileSection'
        id: string
        tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
      }
    | { __typename: 'RawHtmlSection'; id: string; html: string }
  >
  alternateModelVariant: {
    __typename?: 'AlternateModelVariant'
    name: string
    action: string
    validContentfulModel:
      | { __typename?: 'NavAccordionSection' }
      | { __typename?: 'NavListItem' }
      | {
          __typename?: 'NavTab'
          href: string | null
          id: string
          text: string
          textColor: string | null
          sections: Array<
            | {
                __typename: 'NavAccordion'
                id: string
                variant: string
                sections: Array<{
                  __typename?: 'NavAccordionSection'
                  id: string
                  header: {
                    __typename?: 'NavListItem'
                    badge: string | null
                    badgeColor: string | null
                    badgeTextColor: string | null
                    href: string | null
                    icon: string | null
                    id: string
                    isBold: boolean | null
                    isHeading: boolean | null
                    text: string
                    textColor: string | null
                    htmlId: string | null
                    htmlClass: string | null
                    isBtn: boolean | null
                    alternateModelVariant: {
                      __typename?: 'AlternateModelVariant'
                      name: string
                      action: string
                      validContentfulModel:
                        | { __typename?: 'NavAccordionSection' }
                        | {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                          }
                        | { __typename?: 'NavTab' }
                        | null
                      targetCriteria: {
                        __typename?: 'TargetCriteria'
                        name: string
                        targetLogic: string
                        targetCriteria: Array<string>
                        inRegion: Array<string> | null
                      } | null
                    } | null
                  }
                  body:
                    | {
                        __typename: 'NavAccordion'
                        id: string
                        sections: Array<{
                          __typename?: 'NavAccordionSection'
                          id: string
                          header: {
                            __typename?: 'NavListItem'
                            badge: string | null
                            badgeColor: string | null
                            badgeTextColor: string | null
                            href: string | null
                            icon: string | null
                            id: string
                            isBold: boolean | null
                            isHeading: boolean | null
                            text: string
                            textColor: string | null
                            htmlId: string | null
                            htmlClass: string | null
                            isBtn: boolean | null
                            alternateModelVariant: {
                              __typename?: 'AlternateModelVariant'
                              name: string
                              action: string
                              validContentfulModel:
                                | { __typename?: 'NavAccordionSection' }
                                | {
                                    __typename?: 'NavListItem'
                                    badge: string | null
                                    badgeColor: string | null
                                    badgeTextColor: string | null
                                    href: string | null
                                    icon: string | null
                                    id: string
                                    isBold: boolean | null
                                    isHeading: boolean | null
                                    text: string
                                    textColor: string | null
                                    htmlId: string | null
                                    htmlClass: string | null
                                    isBtn: boolean | null
                                  }
                                | { __typename?: 'NavTab' }
                                | null
                              targetCriteria: {
                                __typename?: 'TargetCriteria'
                                name: string
                                targetLogic: string
                                targetCriteria: Array<string>
                                inRegion: Array<string> | null
                              } | null
                            } | null
                          }
                          body:
                            | { __typename: 'NavAccordion' }
                            | { __typename: 'NavListGroupSection' }
                            | {
                                __typename: 'NavListSection'
                                id: string
                                variant: string
                                items: Array<
                                  | { __typename?: 'AppNavListItem' }
                                  | {
                                      __typename?: 'NavListItem'
                                      badge: string | null
                                      badgeColor: string | null
                                      badgeTextColor: string | null
                                      href: string | null
                                      icon: string | null
                                      id: string
                                      isBold: boolean | null
                                      isHeading: boolean | null
                                      text: string
                                      textColor: string | null
                                      htmlId: string | null
                                      htmlClass: string | null
                                      isBtn: boolean | null
                                      alternateModelVariant: {
                                        __typename?: 'AlternateModelVariant'
                                        name: string
                                        action: string
                                        validContentfulModel:
                                          | { __typename?: 'NavAccordionSection' }
                                          | {
                                              __typename?: 'NavListItem'
                                              badge: string | null
                                              badgeColor: string | null
                                              badgeTextColor: string | null
                                              href: string | null
                                              icon: string | null
                                              id: string
                                              isBold: boolean | null
                                              isHeading: boolean | null
                                              text: string
                                              textColor: string | null
                                              htmlId: string | null
                                              htmlClass: string | null
                                              isBtn: boolean | null
                                            }
                                          | { __typename?: 'NavTab' }
                                          | null
                                        targetCriteria: {
                                          __typename?: 'TargetCriteria'
                                          name: string
                                          targetLogic: string
                                          targetCriteria: Array<string>
                                          inRegion: Array<string> | null
                                        } | null
                                      } | null
                                    }
                                >
                              }
                            | {
                                __typename: 'NavTileSection'
                                id: string
                                tiles: Array<{
                                  __typename?: 'NavTile'
                                  ctaText: string
                                  href: string
                                  image: string
                                }>
                              }
                            | { __typename: 'RawHtmlSection'; id: string; html: string }
                            | null
                        }>
                      }
                    | { __typename: 'NavListGroupSection' }
                    | {
                        __typename: 'NavListSection'
                        id: string
                        variant: string
                        items: Array<
                          | { __typename?: 'AppNavListItem' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                              alternateModelVariant: {
                                __typename?: 'AlternateModelVariant'
                                name: string
                                action: string
                                validContentfulModel:
                                  | { __typename?: 'NavAccordionSection' }
                                  | {
                                      __typename?: 'NavListItem'
                                      badge: string | null
                                      badgeColor: string | null
                                      badgeTextColor: string | null
                                      href: string | null
                                      icon: string | null
                                      id: string
                                      isBold: boolean | null
                                      isHeading: boolean | null
                                      text: string
                                      textColor: string | null
                                      htmlId: string | null
                                      htmlClass: string | null
                                      isBtn: boolean | null
                                    }
                                  | { __typename?: 'NavTab' }
                                  | null
                                targetCriteria: {
                                  __typename?: 'TargetCriteria'
                                  name: string
                                  targetLogic: string
                                  targetCriteria: Array<string>
                                  inRegion: Array<string> | null
                                } | null
                              } | null
                            }
                        >
                      }
                    | {
                        __typename: 'NavTileSection'
                        id: string
                        tiles: Array<{
                          __typename?: 'NavTile'
                          ctaText: string
                          href: string
                          image: string
                        }>
                      }
                    | { __typename: 'RawHtmlSection'; id: string; html: string }
                    | null
                  alternateModelVariant: {
                    __typename?: 'AlternateModelVariant'
                    action: string
                    name: string
                    targetCriteria: {
                      __typename?: 'TargetCriteria'
                      name: string
                      targetLogic: string
                      targetCriteria: Array<string>
                      inRegion: Array<string> | null
                    } | null
                  } | null
                }>
              }
            | {
                __typename: 'NavListGroupSection'
                id: string
                navSections: Array<{
                  __typename?: 'NavListSection'
                  id: string
                  variant: string
                  items: Array<
                    | { __typename?: 'AppNavListItem' }
                    | {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                        alternateModelVariant: {
                          __typename?: 'AlternateModelVariant'
                          name: string
                          action: string
                          validContentfulModel:
                            | { __typename?: 'NavAccordionSection' }
                            | {
                                __typename?: 'NavListItem'
                                badge: string | null
                                badgeColor: string | null
                                badgeTextColor: string | null
                                href: string | null
                                icon: string | null
                                id: string
                                isBold: boolean | null
                                isHeading: boolean | null
                                text: string
                                textColor: string | null
                                htmlId: string | null
                                htmlClass: string | null
                                isBtn: boolean | null
                              }
                            | { __typename?: 'NavTab' }
                            | null
                          targetCriteria: {
                            __typename?: 'TargetCriteria'
                            name: string
                            targetLogic: string
                            targetCriteria: Array<string>
                            inRegion: Array<string> | null
                          } | null
                        } | null
                      }
                  >
                }>
              }
            | {
                __typename: 'NavListSection'
                id: string
                variant: string
                items: Array<
                  | { __typename?: 'AppNavListItem' }
                  | {
                      __typename?: 'NavListItem'
                      badge: string | null
                      badgeColor: string | null
                      badgeTextColor: string | null
                      href: string | null
                      icon: string | null
                      id: string
                      isBold: boolean | null
                      isHeading: boolean | null
                      text: string
                      textColor: string | null
                      htmlId: string | null
                      htmlClass: string | null
                      isBtn: boolean | null
                      alternateModelVariant: {
                        __typename?: 'AlternateModelVariant'
                        name: string
                        action: string
                        validContentfulModel:
                          | { __typename?: 'NavAccordionSection' }
                          | {
                              __typename?: 'NavListItem'
                              badge: string | null
                              badgeColor: string | null
                              badgeTextColor: string | null
                              href: string | null
                              icon: string | null
                              id: string
                              isBold: boolean | null
                              isHeading: boolean | null
                              text: string
                              textColor: string | null
                              htmlId: string | null
                              htmlClass: string | null
                              isBtn: boolean | null
                            }
                          | { __typename?: 'NavTab' }
                          | null
                        targetCriteria: {
                          __typename?: 'TargetCriteria'
                          name: string
                          targetLogic: string
                          targetCriteria: Array<string>
                          inRegion: Array<string> | null
                        } | null
                      } | null
                    }
                >
              }
            | {
                __typename: 'NavTileSection'
                id: string
                tiles: Array<{
                  __typename?: 'NavTile'
                  ctaText: string
                  href: string
                  image: string
                }>
              }
            | { __typename: 'RawHtmlSection'; id: string; html: string }
          >
        }
      | null
    targetCriteria: {
      __typename?: 'TargetCriteria'
      name: string
      targetLogic: string
      targetCriteria: Array<string>
      inRegion: Array<string> | null
    } | null
  } | null
}

export type NavAccordionSectionFragment = {
  __typename?: 'NavAccordion'
  id: string
  variant: string
  sections: Array<{
    __typename?: 'NavAccordionSection'
    id: string
    header: {
      __typename?: 'NavListItem'
      badge: string | null
      badgeColor: string | null
      badgeTextColor: string | null
      href: string | null
      icon: string | null
      id: string
      isBold: boolean | null
      isHeading: boolean | null
      text: string
      textColor: string | null
      htmlId: string | null
      htmlClass: string | null
      isBtn: boolean | null
      alternateModelVariant: {
        __typename?: 'AlternateModelVariant'
        name: string
        action: string
        validContentfulModel:
          | { __typename?: 'NavAccordionSection' }
          | {
              __typename?: 'NavListItem'
              badge: string | null
              badgeColor: string | null
              badgeTextColor: string | null
              href: string | null
              icon: string | null
              id: string
              isBold: boolean | null
              isHeading: boolean | null
              text: string
              textColor: string | null
              htmlId: string | null
              htmlClass: string | null
              isBtn: boolean | null
            }
          | { __typename?: 'NavTab' }
          | null
        targetCriteria: {
          __typename?: 'TargetCriteria'
          name: string
          targetLogic: string
          targetCriteria: Array<string>
          inRegion: Array<string> | null
        } | null
      } | null
    }
    body:
      | {
          __typename: 'NavAccordion'
          id: string
          sections: Array<{
            __typename?: 'NavAccordionSection'
            id: string
            header: {
              __typename?: 'NavListItem'
              badge: string | null
              badgeColor: string | null
              badgeTextColor: string | null
              href: string | null
              icon: string | null
              id: string
              isBold: boolean | null
              isHeading: boolean | null
              text: string
              textColor: string | null
              htmlId: string | null
              htmlClass: string | null
              isBtn: boolean | null
              alternateModelVariant: {
                __typename?: 'AlternateModelVariant'
                name: string
                action: string
                validContentfulModel:
                  | { __typename?: 'NavAccordionSection' }
                  | {
                      __typename?: 'NavListItem'
                      badge: string | null
                      badgeColor: string | null
                      badgeTextColor: string | null
                      href: string | null
                      icon: string | null
                      id: string
                      isBold: boolean | null
                      isHeading: boolean | null
                      text: string
                      textColor: string | null
                      htmlId: string | null
                      htmlClass: string | null
                      isBtn: boolean | null
                    }
                  | { __typename?: 'NavTab' }
                  | null
                targetCriteria: {
                  __typename?: 'TargetCriteria'
                  name: string
                  targetLogic: string
                  targetCriteria: Array<string>
                  inRegion: Array<string> | null
                } | null
              } | null
            }
            body:
              | { __typename: 'NavAccordion' }
              | { __typename: 'NavListGroupSection' }
              | {
                  __typename: 'NavListSection'
                  id: string
                  variant: string
                  items: Array<
                    | { __typename?: 'AppNavListItem' }
                    | {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                        alternateModelVariant: {
                          __typename?: 'AlternateModelVariant'
                          name: string
                          action: string
                          validContentfulModel:
                            | { __typename?: 'NavAccordionSection' }
                            | {
                                __typename?: 'NavListItem'
                                badge: string | null
                                badgeColor: string | null
                                badgeTextColor: string | null
                                href: string | null
                                icon: string | null
                                id: string
                                isBold: boolean | null
                                isHeading: boolean | null
                                text: string
                                textColor: string | null
                                htmlId: string | null
                                htmlClass: string | null
                                isBtn: boolean | null
                              }
                            | { __typename?: 'NavTab' }
                            | null
                          targetCriteria: {
                            __typename?: 'TargetCriteria'
                            name: string
                            targetLogic: string
                            targetCriteria: Array<string>
                            inRegion: Array<string> | null
                          } | null
                        } | null
                      }
                  >
                }
              | {
                  __typename: 'NavTileSection'
                  id: string
                  tiles: Array<{
                    __typename?: 'NavTile'
                    ctaText: string
                    href: string
                    image: string
                  }>
                }
              | { __typename: 'RawHtmlSection'; id: string; html: string }
              | null
          }>
        }
      | { __typename: 'NavListGroupSection' }
      | {
          __typename: 'NavListSection'
          id: string
          variant: string
          items: Array<
            | { __typename?: 'AppNavListItem' }
            | {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
                alternateModelVariant: {
                  __typename?: 'AlternateModelVariant'
                  name: string
                  action: string
                  validContentfulModel:
                    | { __typename?: 'NavAccordionSection' }
                    | {
                        __typename?: 'NavListItem'
                        badge: string | null
                        badgeColor: string | null
                        badgeTextColor: string | null
                        href: string | null
                        icon: string | null
                        id: string
                        isBold: boolean | null
                        isHeading: boolean | null
                        text: string
                        textColor: string | null
                        htmlId: string | null
                        htmlClass: string | null
                        isBtn: boolean | null
                      }
                    | { __typename?: 'NavTab' }
                    | null
                  targetCriteria: {
                    __typename?: 'TargetCriteria'
                    name: string
                    targetLogic: string
                    targetCriteria: Array<string>
                    inRegion: Array<string> | null
                  } | null
                } | null
              }
          >
        }
      | {
          __typename: 'NavTileSection'
          id: string
          tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
        }
      | { __typename: 'RawHtmlSection'; id: string; html: string }
      | null
    alternateModelVariant: {
      __typename?: 'AlternateModelVariant'
      action: string
      name: string
      targetCriteria: {
        __typename?: 'TargetCriteria'
        name: string
        targetLogic: string
        targetCriteria: Array<string>
        inRegion: Array<string> | null
      } | null
    } | null
  }>
}

export type NavListSectionFragment = {
  __typename?: 'NavListSection'
  id: string
  variant: string
  items: Array<
    | { __typename?: 'AppNavListItem' }
    | {
        __typename?: 'NavListItem'
        badge: string | null
        badgeColor: string | null
        badgeTextColor: string | null
        href: string | null
        icon: string | null
        id: string
        isBold: boolean | null
        isHeading: boolean | null
        text: string
        textColor: string | null
        htmlId: string | null
        htmlClass: string | null
        isBtn: boolean | null
        alternateModelVariant: {
          __typename?: 'AlternateModelVariant'
          name: string
          action: string
          validContentfulModel:
            | { __typename?: 'NavAccordionSection' }
            | {
                __typename?: 'NavListItem'
                badge: string | null
                badgeColor: string | null
                badgeTextColor: string | null
                href: string | null
                icon: string | null
                id: string
                isBold: boolean | null
                isHeading: boolean | null
                text: string
                textColor: string | null
                htmlId: string | null
                htmlClass: string | null
                isBtn: boolean | null
              }
            | { __typename?: 'NavTab' }
            | null
          targetCriteria: {
            __typename?: 'TargetCriteria'
            name: string
            targetLogic: string
            targetCriteria: Array<string>
            inRegion: Array<string> | null
          } | null
        } | null
      }
  >
}

export type NavListGroupSectionFragment = {
  __typename?: 'NavListGroupSection'
  id: string
  navSections: Array<{
    __typename?: 'NavListSection'
    id: string
    variant: string
    items: Array<
      | { __typename?: 'AppNavListItem' }
      | {
          __typename?: 'NavListItem'
          badge: string | null
          badgeColor: string | null
          badgeTextColor: string | null
          href: string | null
          icon: string | null
          id: string
          isBold: boolean | null
          isHeading: boolean | null
          text: string
          textColor: string | null
          htmlId: string | null
          htmlClass: string | null
          isBtn: boolean | null
          alternateModelVariant: {
            __typename?: 'AlternateModelVariant'
            name: string
            action: string
            validContentfulModel:
              | { __typename?: 'NavAccordionSection' }
              | {
                  __typename?: 'NavListItem'
                  badge: string | null
                  badgeColor: string | null
                  badgeTextColor: string | null
                  href: string | null
                  icon: string | null
                  id: string
                  isBold: boolean | null
                  isHeading: boolean | null
                  text: string
                  textColor: string | null
                  htmlId: string | null
                  htmlClass: string | null
                  isBtn: boolean | null
                }
              | { __typename?: 'NavTab' }
              | null
            targetCriteria: {
              __typename?: 'TargetCriteria'
              name: string
              targetLogic: string
              targetCriteria: Array<string>
              inRegion: Array<string> | null
            } | null
          } | null
        }
    >
  }>
}

export type NavListItemFragment = {
  __typename?: 'NavListItem'
  badge: string | null
  badgeColor: string | null
  badgeTextColor: string | null
  href: string | null
  icon: string | null
  id: string
  isBold: boolean | null
  isHeading: boolean | null
  text: string
  textColor: string | null
  htmlId: string | null
  htmlClass: string | null
  isBtn: boolean | null
  alternateModelVariant: {
    __typename?: 'AlternateModelVariant'
    name: string
    action: string
    validContentfulModel:
      | { __typename?: 'NavAccordionSection' }
      | {
          __typename?: 'NavListItem'
          badge: string | null
          badgeColor: string | null
          badgeTextColor: string | null
          href: string | null
          icon: string | null
          id: string
          isBold: boolean | null
          isHeading: boolean | null
          text: string
          textColor: string | null
          htmlId: string | null
          htmlClass: string | null
          isBtn: boolean | null
        }
      | { __typename?: 'NavTab' }
      | null
    targetCriteria: {
      __typename?: 'TargetCriteria'
      name: string
      targetLogic: string
      targetCriteria: Array<string>
      inRegion: Array<string> | null
    } | null
  } | null
}

export type NavTileSectionFragment = {
  __typename?: 'NavTileSection'
  id: string
  tiles: Array<{ __typename?: 'NavTile'; ctaText: string; href: string; image: string }>
}

export type RawHtmlSectionFragment = { __typename?: 'RawHtmlSection'; id: string; html: string }

export type GenderNavSectionFragmentFragment = {
  __typename?: 'GenderNavSection'
  id: string
  alternateGenderHandle: string
  selectedGender: string
}

export type GetCollectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  nextPageDataOnly: Scalars['Boolean']['input']
  collectionColorScope: CollectionColorScope
  filterConfig?: InputMaybe<FilterConfigInput>
  filterKeys?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  selectedFilters?: InputMaybe<Array<CollectionItemFilterInput> | CollectionItemFilterInput>
  collectionHandle: Scalars['String']['input']
  grouping?: InputMaybe<CollectionItemGrouping>
}>

export type GetCollectionQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    id: string
    handle: string
    colors?: Array<{
      __typename?: 'ProductColor'
      name: string
      hexCodes: Array<string> | null
      swatchUrl: string | null
    }> | null
    filters?: Array<{
      __typename?: 'ProductFilter'
      key: string
      crawlable: boolean
      displayName: string
      displayType: string
      filterValues: Array<{ __typename?: 'FilterValue'; state: FilterValueState; value: string }>
    }> | null
    items: {
      __typename?: 'ProductConnection'
      numItems: number
      nodes: Array<{
        __typename?: 'Product'
        id: string
        category: string
        rawCategory: string
        collectionBadgeColor: string | null
        collectionBadgeText: string | null
        iconBadge: any | null
        iconBadgePlacement: string | null
        isKit: boolean
        handle: string | null
        shopifyId: string
        availableFits: Array<string>
        rawAvailableFits: Array<string>
        waitlistable: boolean
        swPromoEligible: boolean
        priceRange: any | null
        finalSale: boolean
        colorInfo: {
          __typename?: 'ProductColor'
          availability: string
          hexCodes: Array<string> | null
          name: string
          rawName: string
          swatchUrl: string | null
        } | null
        defaultVariant: {
          __typename?: 'Variant'
          discountPriceExplicitFmt: string
          priceExplicitFmt: string
          shopifyId: string
          sku: string | null
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: {
              __typename?: 'Price'
              currency: string
              amount: any | null
              base: { __typename?: 'Money'; amount: any | null; currency: string } | null
            } | null
          } | null
        }
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
        } | null
        productGroup: {
          __typename?: 'ProductGroup'
          collectionHandle: string | null
          description: string | null
          fits: Array<string>
          handle: string
          iconBadge: any | null
          iconBadgePlacement: string | null
          numColors: number
          rawFits: Array<string>
          style: string | null
          title: string
          priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
        }
      }>
      pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
    } | null
  } | null
}

export type GetCollectionItemsWithImagesQueryVariables = Exact<{
  handle: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCollectionItemsWithImagesQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    items: {
      __typename?: 'ProductConnection'
      numItems: number
      numPages: number
      nodes: Array<{
        __typename?: 'Product'
        category: string
        detailImages: Array<string> | null
        id: string
        colorInfo: {
          __typename?: 'ProductColor'
          availability: string
          hexCodes: Array<string> | null
          name: string
        } | null
        fitModel: { __typename?: 'FitModel'; images: Array<string> | null } | null
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
        } | null
        productGroup: {
          __typename?: 'ProductGroup'
          description: string | null
          handle: string
          id: string
          style: string | null
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
    } | null
  } | null
}

export type GetCollectionSectionProductsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  handle: Scalars['String']['input']
}>

export type GetCollectionSectionProductsQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    items: {
      __typename?: 'ProductConnection'
      nodes: Array<{
        __typename?: 'Product'
        id: string
        shopifyId: string
        handle: string | null
        category: string
        colorInfo: {
          __typename?: 'ProductColor'
          handle: string
          name: string
          rawName: string
        } | null
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
        } | null
        productGroup: {
          __typename?: 'ProductGroup'
          handle: string
          title: string
          numColors: number
          priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
        }
        defaultVariant: {
          __typename?: 'Variant'
          priceDetails: {
            __typename?: 'PriceDetails'
            price: { __typename?: 'Price'; amount: any | null; currency: string } | null
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
          } | null
        }
      }>
    } | null
  } | null
}

export type GetFeaturedProductQueryVariables = Exact<{
  productGroupHandle: Scalars['String']['input']
  color?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<Scalars['String']['input']>
  selectedColor?: InputMaybe<Scalars['String']['input']>
  selectedFit?: InputMaybe<Scalars['String']['input']>
}>

export type GetFeaturedProductQuery = {
  __typename?: 'Query'
  productGroup: {
    __typename?: 'ProductGroup'
    id: string
    featuredProduct: {
      __typename?: 'Product'
      availableFits: Array<string>
      rawAvailableFits: Array<string>
      availableSizes: Array<string>
      category: string
      collectionBadgeColor: string | null
      collectionBadgeText: string | null
      color: string | null
      finalSale: boolean
      fit: string | null
      rawFit: string | null
      handle: string | null
      iconBadge: any | null
      iconBadgePlacement: string | null
      priceRange: any | null
      shopifyId: string
      swPromoEligible: boolean
      waitlistable: boolean
      colorInfo: {
        __typename?: 'ProductColor'
        availability: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
      } | null
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{
          __typename?: 'ProductAsset'
          index: number
          kind: string | null
          source: string
        }>
      } | null
      variants: Array<{
        __typename?: 'Variant'
        currencyType: string
        discountPriceExplicitFmt: string
        priceExplicitFmt: string
        shopifyId: string
        sku: string | null
        size: string | null
        soldOut: boolean
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      }> | null
    } | null
  } | null
}

export type GetPdpFeaturedProductQueryVariables = Exact<{
  productGroupHandle: Scalars['String']['input']
  collectionHandle?: InputMaybe<Scalars['String']['input']>
  color?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<Scalars['String']['input']>
  logoScope?: InputMaybe<LogoScopeEnum>
}>

export type GetPdpFeaturedProductQuery = {
  __typename?: 'Query'
  productGroup: {
    __typename?: 'ProductGroup'
    id: string
    featuredProduct: {
      __typename?: 'Product'
      id: string
      availableForSale: boolean
      color: string | null
      detailImages: Array<string> | null
      finalSale: boolean
      fit: string | null
      fitModelCount: number
      fitModelsDescriptions: Array<string> | null
      badgeList: Array<string> | null
      iconBadge: any | null
      iconBadgePlacement: string | null
      noindex: boolean
      handle: string | null
      rtfFeatures: any | null
      rawFit: string | null
      rawMaterials: Array<string> | null
      seoTitle: string | null
      seoDescription: string | null
      shopifyId: string
      soldOut: boolean
      swPromoEligible: boolean
      whyWeLove: string | null
      careInstructions: string | null
      waitlistable: boolean
      collectionLinks: {
        __typename?: 'LinkConnection'
        nodes: Array<{ __typename?: 'Link'; href: string; text: string }>
      } | null
      colorInfo: { __typename?: 'ProductColor'; rawName: string; accentColor: string | null } | null
      embroidery: Array<{
        __typename?: 'Embroidery'
        kind: EmbroideryTypeEnum
        initialsColors: Array<string> | null
        initialsMaxChars: number | null
        textColors: Array<string> | null
        textLines: number | null
        textMaxChars: number | null
        iconPlacements: Array<{
          __typename?: 'EmbroideryPlacementDetails'
          name: string
          flatlayKind: string
          offsetKey: string | null
        }> | null
        textPlacements: Array<{
          __typename?: 'EmbroideryPlacementDetails'
          name: string
          flatlayKind: string
          offsetKey: string | null
        }> | null
        logoPlacements: Array<{
          __typename?: 'EmbroideryPlacementDetails'
          flatlayKind: string
          name: string
          offsetKey: string | null
        }> | null
        textFonts: Array<{
          __typename?: 'EmbroideryFontInfo'
          name: string
          scale: number | null
        }> | null
        variants: Array<{
          __typename?: 'Variant'
          category: string
          price: any
          shopifyId: string
          sku: string | null
          images: Array<{
            __typename?: 'ProductAsset'
            caption: string | null
            designFile: string | null
            embroideryIconGroup: string | null
            embroideryOffsets: any | null
            source: string
          }> | null
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: {
              __typename?: 'Price'
              currency: string
              amount: any | null
              base: { __typename?: 'Money'; amount: any | null; currency: string } | null
            } | null
          } | null
        }> | null
      }> | null
      fitModel: {
        __typename?: 'FitModel'
        description: string | null
        height: string | null
        images: Array<string> | null
        name: string | null
        pantsSize: string | null
        topSize: string | null
      } | null
      iconList: Array<{
        __typename?: 'ProductIcon'
        image: string
        label: string
        title: string
      }> | null
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{
          __typename?: 'ProductAsset'
          color: string | null
          embroideryOffsets: any | null
          fit: string | null
          index: number
          source: string
          kind: string | null
          type: string | null
        }>
      } | null
      variants: Array<{
        __typename?: 'Variant'
        sku: string | null
        category: string
        color: string | null
        currencyType: string
        discountPrice: any | null
        discountPriceExplicitFmt: string
        size: string | null
        fit: string | null
        handle: string
        price: any
        priceExplicitFmt: string
        soldOut: boolean
        upc: string | null
        rawColor: string | null
        rawFit: string | null
        shopifyId: string
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
        product: {
          __typename?: 'Product'
          id: string
          finalSale: boolean
          waitlistable: boolean
          shopifyId: string
        }
      }> | null
      videos: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{
          __typename?: 'ProductAsset'
          kind: string | null
          source: string
          type: string | null
        }>
      } | null
    } | null
  } | null
}

export type GetProductImpressionVariantsQueryVariables = Exact<{
  collectionViewHandle: Scalars['String']['input']
  sectionId: Scalars['String']['input']
}>

export type GetProductImpressionVariantsQuery = {
  __typename?: 'Query'
  collectionView: {
    __typename?: 'CollectionView'
    section:
      | {
          __typename?: 'CollectionGridSection'
          collection: {
            __typename?: 'Collection'
            id: string
            handle: string
            items: {
              __typename?: 'ProductConnection'
              nodes: Array<{
                __typename?: 'Product'
                id: string
                defaultVariant: {
                  __typename?: 'Variant'
                  externalParentId: string
                  category: string
                  discountPrice: any | null
                  handle: string
                  price: any
                  shopifyId: string
                  sku: string | null
                }
              }>
            } | null
          } | null
        }
      | { __typename?: 'CollectionViewHeroSection' }
      | { __typename?: 'GenderNavSection' }
      | { __typename?: 'HeroFullBleedSection' }
      | { __typename?: 'PageTabSection' }
      | { __typename?: 'RichTextSection' }
      | { __typename?: 'SearchGridSection' }
      | { __typename?: 'VideoModalBannerSection' }
      | { __typename?: 'VideoSection' }
      | null
  } | null
}

export type GetProductGroupsQueryVariables = Exact<{
  handle?: InputMaybe<Scalars['String']['input']>
  isPreview?: InputMaybe<Scalars['Boolean']['input']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetProductGroupsQuery = {
  __typename?: 'Query'
  productGroups: {
    __typename?: 'ProductGroupConnection'
    nodes: Array<{
      __typename?: 'ProductGroup'
      title: string
      seoTitle: string | null
      sizeChart: {
        __typename?: 'SizeChartCG'
        columnNames: Array<string> | null
        columnValues: Array<Array<string>> | null
        imageUri: string | null
        name: string
        navLabel: string | null
        title: string
        video: string | null
        tables: Array<{
          __typename?: 'SizeChartTable'
          cells: string
          measureImage: string | null
          subtitle: string | null
          tabLabel: string | null
          title: string | null
          measureTips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
          sizeTips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
        }> | null
        tips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }
    }>
  } | null
}

export type GetReplacementVariantsQueryVariables = Exact<{
  replacementInput: Array<ReplacementInput> | ReplacementInput
}>

export type GetReplacementVariantsQuery = {
  __typename?: 'Query'
  replacementVariants: Array<{
    __typename?: 'Replacement'
    replacementSku: string
    requestedKey: string
    replacementShopifyId: string
  }> | null
}

export type GetTileProductGroupForCollectionQueryVariables = Exact<{
  productGroupHandle: Scalars['String']['input']
  color?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<Scalars['String']['input']>
  collectionHandle: Scalars['String']['input']
  collectionColorScope: CollectionColorScope
  withEmbroideryOptions?: InputMaybe<Scalars['Boolean']['input']>
  withEmbroideryScope?: InputMaybe<LogoScopeEnum>
}>

export type GetTileProductGroupForCollectionQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    id: string
    productGroup: {
      __typename?: 'ProductGroup'
      collectionHandle: string | null
      description: string | null
      fits: Array<string>
      handle: string
      iconBadge: any | null
      iconBadgePlacement: string | null
      id: string
      rawFits: Array<string>
      sizes: Array<string>
      style: string | null
      colorGroups: Array<{
        __typename?: 'ColorGroup'
        label: string
        colors: Array<{
          __typename?: 'ProductColor'
          accentColor: string | null
          availability: string
          handle: string
          hexCodes: Array<string> | null
          name: string
          rawName: string
          swatchUrl: string | null
        }>
      }>
      colors: Array<{
        __typename?: 'ProductColor'
        availability: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
        swatchUrl: string | null
      }>
      featuredProduct: {
        __typename?: 'Product'
        availableFits: Array<string>
        rawAvailableFits: Array<string>
        availableSizes: Array<string>
        category: string
        collectionBadgeColor: string | null
        collectionBadgeText: string | null
        color: string | null
        finalSale: boolean
        fit: string | null
        rawFit: string | null
        handle: string | null
        iconBadge: any | null
        iconBadgePlacement: string | null
        priceRange: any | null
        shopifyId: string
        swPromoEligible: boolean
        waitlistable: boolean
        embroidery?: Array<{
          __typename?: 'Embroidery'
          kind: EmbroideryTypeEnum
          initialsColors: Array<string> | null
          initialsMaxChars: number | null
          textColors: Array<string> | null
          textLines: number | null
          textMaxChars: number | null
          iconPlacements: Array<{
            __typename?: 'EmbroideryPlacementDetails'
            name: string
            flatlayKind: string
            offsetKey: string | null
          }> | null
          textPlacements: Array<{
            __typename?: 'EmbroideryPlacementDetails'
            name: string
            flatlayKind: string
            offsetKey: string | null
          }> | null
          logoPlacements: Array<{
            __typename?: 'EmbroideryPlacementDetails'
            flatlayKind: string
            name: string
            offsetKey: string | null
          }> | null
          textFonts: Array<{
            __typename?: 'EmbroideryFontInfo'
            name: string
            scale: number | null
          }> | null
          variants: Array<{
            __typename?: 'Variant'
            category: string
            price: any
            shopifyId: string
            sku: string | null
            images: Array<{
              __typename?: 'ProductAsset'
              caption: string | null
              designFile: string | null
              embroideryIconGroup: string | null
              embroideryOffsets: any | null
              source: string
            }> | null
            priceDetails: {
              __typename?: 'PriceDetails'
              discountPrice: { __typename?: 'Price'; amount: any | null } | null
              price: {
                __typename?: 'Price'
                currency: string
                amount: any | null
                base: { __typename?: 'Money'; amount: any | null; currency: string } | null
              } | null
            } | null
          }> | null
        }> | null
        colorInfo: {
          __typename?: 'ProductColor'
          availability: string
          hexCodes: Array<string> | null
          name: string
          rawName: string
        } | null
        images: {
          __typename?: 'ProductAssetConnection'
          nodes: Array<{
            __typename?: 'ProductAsset'
            index: number
            kind: string | null
            source: string
          }>
        } | null
        variants: Array<{
          __typename?: 'Variant'
          currencyType: string
          discountPriceExplicitFmt: string
          priceExplicitFmt: string
          shopifyId: string
          sku: string | null
          size: string | null
          soldOut: boolean
          priceDetails: {
            __typename?: 'PriceDetails'
            discountPrice: { __typename?: 'Price'; amount: any | null } | null
            price: {
              __typename?: 'Price'
              currency: string
              amount: any | null
              base: { __typename?: 'Money'; amount: any | null; currency: string } | null
            } | null
          } | null
        }> | null
      } | null
      defaultVariant: {
        __typename?: 'Variant'
        color: string | null
        shopifyId: string
        sku: string | null
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      } | null
      productsForAvailability: Array<{
        __typename?: 'Product'
        availableSizes: Array<string>
        rawFit: string | null
        swPromoEligible: boolean
        colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
      }>
      sizeChart: {
        __typename?: 'SizeChartCG'
        columnNames: Array<string> | null
        columnValues: Array<Array<string>> | null
        imageUri: string | null
        name: string
        navLabel: string | null
        title: string
        video: string | null
        tables: Array<{
          __typename?: 'SizeChartTable'
          cells: string
          measureImage: string | null
          subtitle: string | null
          tabLabel: string | null
          title: string | null
          measureTips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
          sizeTips: Array<{
            __typename?: 'SizeChartTipCG'
            kind: string | null
            tip: string | null
          }> | null
        }> | null
        tips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }
    } | null
  } | null
}

export type GetTileProductGroupForSearchQueryVariables = Exact<{
  handle: Scalars['String']['input']
  color?: InputMaybe<Scalars['String']['input']>
  fit?: InputMaybe<Scalars['String']['input']>
}>

export type GetTileProductGroupForSearchQuery = {
  __typename?: 'Query'
  productGroup: {
    __typename?: 'ProductGroup'
    collectionHandle: string | null
    description: string | null
    fits: Array<string>
    handle: string
    iconBadge: any | null
    iconBadgePlacement: string | null
    id: string
    rawFits: Array<string>
    sizes: Array<string>
    style: string | null
    colorGroups: Array<{
      __typename?: 'ColorGroup'
      label: string
      colors: Array<{
        __typename?: 'ProductColor'
        accentColor: string | null
        availability: string
        handle: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
        swatchUrl: string | null
      }>
    }>
    colors: Array<{
      __typename?: 'ProductColor'
      availability: string
      hexCodes: Array<string> | null
      name: string
      rawName: string
      swatchUrl: string | null
    }>
    featuredProduct: {
      __typename?: 'Product'
      availableFits: Array<string>
      rawAvailableFits: Array<string>
      availableSizes: Array<string>
      category: string
      collectionBadgeColor: string | null
      collectionBadgeText: string | null
      color: string | null
      finalSale: boolean
      fit: string | null
      rawFit: string | null
      handle: string | null
      iconBadge: any | null
      iconBadgePlacement: string | null
      priceRange: any | null
      shopifyId: string
      swPromoEligible: boolean
      waitlistable: boolean
      colorInfo: {
        __typename?: 'ProductColor'
        availability: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
      } | null
      images: {
        __typename?: 'ProductAssetConnection'
        nodes: Array<{
          __typename?: 'ProductAsset'
          index: number
          kind: string | null
          source: string
        }>
      } | null
      variants: Array<{
        __typename?: 'Variant'
        currencyType: string
        discountPriceExplicitFmt: string
        priceExplicitFmt: string
        shopifyId: string
        sku: string | null
        size: string | null
        soldOut: boolean
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      }> | null
    } | null
    defaultVariant: {
      __typename?: 'Variant'
      color: string | null
      shopifyId: string
      sku: string | null
      priceDetails: {
        __typename?: 'PriceDetails'
        discountPrice: { __typename?: 'Price'; amount: any | null } | null
        price: {
          __typename?: 'Price'
          currency: string
          amount: any | null
          base: { __typename?: 'Money'; amount: any | null; currency: string } | null
        } | null
      } | null
    } | null
    productsForAvailability: Array<{
      __typename?: 'Product'
      availableSizes: Array<string>
      rawFit: string | null
      swPromoEligible: boolean
      colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
    }>
    sizeChart: {
      __typename?: 'SizeChartCG'
      columnNames: Array<string> | null
      columnValues: Array<Array<string>> | null
      imageUri: string | null
      name: string
      navLabel: string | null
      title: string
      video: string | null
      tables: Array<{
        __typename?: 'SizeChartTable'
        cells: string
        measureImage: string | null
        subtitle: string | null
        tabLabel: string | null
        title: string | null
        measureTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
        sizeTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }> | null
      tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
    }
  } | null
}

export type HeroFullBleedSectionFragmentFragment = {
  __typename?: 'HeroFullBleedSection'
  id: string
  description: string | null
  imageDesktop: string | null
  imageMobile: string | null
  inlineVideoDesktop: string | null
  inlineVideoMobile: string | null
  inlineVimeoIdDesktop: number | null
  inlineVimeoIdMobile: number | null
  subtitle: string | null
  textColor: string
  title: string | null
  titleImage: string | null
  titleImageMobile: string | null
}

export type MultipassAuthUrlQueryVariables = Exact<{
  redirectUri: Scalars['String']['input']
}>

export type MultipassAuthUrlQuery = {
  __typename?: 'Query'
  customerShopifyUrl: { __typename?: 'ShopifyUrlPayload'; url: string }
}

export type ShopOrdersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ShopOrdersQuery = {
  __typename?: 'Query'
  shopOrders: {
    __typename?: 'ShopOrderHistory'
    shopOrders: Array<{
      __typename?: 'ShopOrder'
      createdAt: string | null
      displayFinancialStatus: string | null
      id: string
      name: string
      total: number | null
    }> | null
    pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
  } | null
}

export type PageTabSectionFragmentFragment = {
  __typename?: 'PageTabSection'
  id: string
  hideOnMobile: boolean
  hideOnDesktop: boolean
  title: string | null
  tabs: Array<{
    __typename?: 'PageTab'
    id: string
    text: string
    textColor: string | null
    tabSections: Array<
      | {
          __typename: 'PageTabTileSection'
          id: string
          layoutVariantMobile: string
          layoutVariantDesktop: string
          tileVariantMobile: string
          tileVariantDesktop: string
          tiles: Array<{
            __typename?: 'PageTabTileSectionTile'
            id: string
            ctaText: string
            href: string
            image: string
          }>
        }
      | { __typename: 'RawHtmlSection'; id: string; html: string }
    >
  }>
}

export type PdpFeaturedProductFragmentFragment = {
  __typename?: 'ProductGroup'
  featuredProduct: {
    __typename?: 'Product'
    id: string
    availableForSale: boolean
    color: string | null
    detailImages: Array<string> | null
    finalSale: boolean
    fit: string | null
    fitModelCount: number
    fitModelsDescriptions: Array<string> | null
    badgeList: Array<string> | null
    iconBadge: any | null
    iconBadgePlacement: string | null
    noindex: boolean
    handle: string | null
    rtfFeatures: any | null
    rawFit: string | null
    rawMaterials: Array<string> | null
    seoTitle: string | null
    seoDescription: string | null
    shopifyId: string
    soldOut: boolean
    swPromoEligible: boolean
    whyWeLove: string | null
    careInstructions: string | null
    waitlistable: boolean
    collectionLinks: {
      __typename?: 'LinkConnection'
      nodes: Array<{ __typename?: 'Link'; href: string; text: string }>
    } | null
    colorInfo: { __typename?: 'ProductColor'; rawName: string; accentColor: string | null } | null
    embroidery: Array<{
      __typename?: 'Embroidery'
      kind: EmbroideryTypeEnum
      initialsColors: Array<string> | null
      initialsMaxChars: number | null
      textColors: Array<string> | null
      textLines: number | null
      textMaxChars: number | null
      iconPlacements: Array<{
        __typename?: 'EmbroideryPlacementDetails'
        name: string
        flatlayKind: string
        offsetKey: string | null
      }> | null
      textPlacements: Array<{
        __typename?: 'EmbroideryPlacementDetails'
        name: string
        flatlayKind: string
        offsetKey: string | null
      }> | null
      logoPlacements: Array<{
        __typename?: 'EmbroideryPlacementDetails'
        flatlayKind: string
        name: string
        offsetKey: string | null
      }> | null
      textFonts: Array<{
        __typename?: 'EmbroideryFontInfo'
        name: string
        scale: number | null
      }> | null
      variants: Array<{
        __typename?: 'Variant'
        category: string
        price: any
        shopifyId: string
        sku: string | null
        images: Array<{
          __typename?: 'ProductAsset'
          caption: string | null
          designFile: string | null
          embroideryIconGroup: string | null
          embroideryOffsets: any | null
          source: string
        }> | null
        priceDetails: {
          __typename?: 'PriceDetails'
          discountPrice: { __typename?: 'Price'; amount: any | null } | null
          price: {
            __typename?: 'Price'
            currency: string
            amount: any | null
            base: { __typename?: 'Money'; amount: any | null; currency: string } | null
          } | null
        } | null
      }> | null
    }> | null
    fitModel: {
      __typename?: 'FitModel'
      description: string | null
      height: string | null
      images: Array<string> | null
      name: string | null
      pantsSize: string | null
      topSize: string | null
    } | null
    iconList: Array<{
      __typename?: 'ProductIcon'
      image: string
      label: string
      title: string
    }> | null
    images: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{
        __typename?: 'ProductAsset'
        color: string | null
        embroideryOffsets: any | null
        fit: string | null
        index: number
        source: string
        kind: string | null
        type: string | null
      }>
    } | null
    variants: Array<{
      __typename?: 'Variant'
      sku: string | null
      category: string
      color: string | null
      currencyType: string
      discountPrice: any | null
      discountPriceExplicitFmt: string
      size: string | null
      fit: string | null
      handle: string
      price: any
      priceExplicitFmt: string
      soldOut: boolean
      upc: string | null
      rawColor: string | null
      rawFit: string | null
      shopifyId: string
      priceDetails: {
        __typename?: 'PriceDetails'
        discountPrice: { __typename?: 'Price'; amount: any | null } | null
        price: {
          __typename?: 'Price'
          currency: string
          amount: any | null
          base: { __typename?: 'Money'; amount: any | null; currency: string } | null
        } | null
      } | null
      product: {
        __typename?: 'Product'
        id: string
        finalSale: boolean
        waitlistable: boolean
        shopifyId: string
      }
    }> | null
    videos: {
      __typename?: 'ProductAssetConnection'
      nodes: Array<{
        __typename?: 'ProductAsset'
        kind: string | null
        source: string
        type: string | null
      }>
    } | null
  } | null
}

export type PortalProfileFragment = {
  __typename?: 'PortalCG'
  collectionHandle: string | null
  name: string | null
  discountPercentage: number | null
  pdpCtaText: string | null
  quickBuyText: string
  accountHeroSectionCG: {
    __typename?: 'CollectionViewHeroSection'
    backgroundColor: string
    ctaText: string | null
    ctaLink: string | null
    id: string
    imageDesktop: string | null
    imageMobile: string | null
    imagePlacement: string | null
    inlineVideoDesktop: string | null
    inlineVideoMobile: string | null
    inlineVimeoIdDesktop: number | null
    inlineVimeoIdMobile: number | null
    subtitle: string | null
    textColor: string
    title: string | null
    videoId: string | null
    videoPlatform: string | null
  } | null
  collectionHeroSectionCG: {
    __typename?: 'CollectionViewHeroSection'
    backgroundColor: string
    ctaText: string | null
    id: string
    imageDesktop: string | null
    imageMobile: string | null
    imagePlacement: string | null
    inlineVideoDesktop: string | null
    inlineVideoMobile: string | null
    inlineVimeoIdDesktop: number | null
    inlineVimeoIdMobile: number | null
    subtitle: string | null
    textColor: string
    title: string | null
    videoId: string | null
    videoPlatform: string | null
  } | null
  promoBarPages: Array<{ __typename?: 'PromoBarPage'; id: string; content: string }>
  embroidery: {
    __typename?: 'PortalEmbroiderySettings'
    image: string
    addText: string
    yesText: string | null
    noText: string | null
    logoName: string
    eligibleProductCategories: Array<string>
    mandatoryTextEmbroideryCategories: Array<string>
    mandatoryLogoEmbroideryCategories: Array<string>
    embroideryDiscountPercentage: number | null
    hiddenTextEmbroideryFields: Array<PortalHiddenTextEmbroideryFields>
    defaultTextEmbroideryValues: {
      __typename?: 'PortalDefaultTextEmbroideryValues'
      textColor: PortalDefaultTextEmbroideryColor | null
      font: PortalDefaultTextEmbroideryFont | null
      textPlacement: string | null
    }
  } | null
}

export type PortalStaticFragment = {
  __typename?: 'PortalCG'
  enableSSO: boolean
  groupTag: string | null
}

export type PriceDetailFragment = {
  __typename?: 'PriceDetails'
  discountPrice: { __typename?: 'Price'; amount: any | null } | null
  price: {
    __typename?: 'Price'
    currency: string
    amount: any | null
    base: { __typename?: 'Money'; amount: any | null; currency: string } | null
  } | null
}

export type ProductAvailabilityFragmentFragment = {
  __typename: 'Product'
  id: string
  availableSizes: Array<string>
  fit: string | null
  rawFit: string | null
  swPromoEligible: boolean
  priceRange: any | null
  colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
  defaultVariant: { __typename?: 'Variant'; price: any; discountPrice: any | null }
}

export type ProductEmbroideryFragment = {
  __typename?: 'Embroidery'
  kind: EmbroideryTypeEnum
  initialsColors: Array<string> | null
  initialsMaxChars: number | null
  textColors: Array<string> | null
  textLines: number | null
  textMaxChars: number | null
  iconPlacements: Array<{
    __typename?: 'EmbroideryPlacementDetails'
    name: string
    flatlayKind: string
    offsetKey: string | null
  }> | null
  textPlacements: Array<{
    __typename?: 'EmbroideryPlacementDetails'
    name: string
    flatlayKind: string
    offsetKey: string | null
  }> | null
  logoPlacements: Array<{
    __typename?: 'EmbroideryPlacementDetails'
    flatlayKind: string
    name: string
    offsetKey: string | null
  }> | null
  textFonts: Array<{ __typename?: 'EmbroideryFontInfo'; name: string; scale: number | null }> | null
  variants: Array<{
    __typename?: 'Variant'
    category: string
    price: any
    shopifyId: string
    sku: string | null
    images: Array<{
      __typename?: 'ProductAsset'
      caption: string | null
      designFile: string | null
      embroideryIconGroup: string | null
      embroideryOffsets: any | null
      source: string
    }> | null
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  }> | null
}

export type ProductPageQueryQueryVariables = Exact<{
  handle: Scalars['String']['input']
  collectionHandle?: InputMaybe<Scalars['String']['input']>
  collectionScope?: InputMaybe<ProductGroupColorSelector>
  portalCollectionHandle?: InputMaybe<Scalars['String']['input']>
  withPortalColors: Scalars['Boolean']['input']
}>

export type ProductPageQueryQuery = {
  __typename?: 'Query'
  productGroup: {
    __typename?: 'ProductGroup'
    id: string
    category: string
    genderCategory: string
    description: string | null
    embroiderable: boolean
    iconBadge: any | null
    iconBadgePlacement: string | null
    handle: string
    rtfFeatures: any | null
    title: string
    sizes: Array<string>
    style: string | null
    fits: Array<string>
    rawFits: Array<string>
    portalColorGroups?: Array<{
      __typename?: 'ColorGroup'
      label: string
      colors: Array<{
        __typename?: 'ProductColor'
        accentColor: string | null
        availability: string
        handle: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
        swatchUrl: string | null
      }>
    }>
    colorGroups: Array<{
      __typename?: 'ColorGroup'
      label: string
      colors: Array<{
        __typename?: 'ProductColor'
        accentColor: string | null
        availability: string
        handle: string
        hexCodes: Array<string> | null
        name: string
        rawName: string
        swatchUrl: string | null
      }>
    }>
    embroideryImages: Array<{
      __typename?: 'ProductAsset'
      color: string | null
      embroideryOffsets: any | null
      fit: string | null
      index: number
      source: string
      kind: string | null
      type: string | null
    }> | null
    priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
    productsForAvailability: Array<{
      __typename: 'Product'
      id: string
      availableSizes: Array<string>
      fit: string | null
      rawFit: string | null
      swPromoEligible: boolean
      priceRange: any | null
      colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
      defaultVariant: { __typename?: 'Variant'; price: any; discountPrice: any | null }
    }>
    productGroupView: {
      __typename?: 'ProductGroupView'
      sections: Array<
        | {
            __typename: 'PageBannerSection'
            id: string
            backgroundColorDesktop: string | null
            backgroundColorMobile: string | null
            eyebrow: string | null
            hasVerticalMargin: boolean
            imageDesktop: string | null
            imageMobile: string | null
            inlineVideoDesktop: string | null
            inlineVideoMobile: string | null
            inlineVimeoIdDesktop: number | null
            inlineVimeoIdMobile: number | null
            isFullBleedDesktop: boolean | null
            linkHref1: string | null
            linkHref2: string | null
            linkText1: string | null
            linkText2: string | null
            ctaStyle: string
            modalVideoId: string | null
            modalVideoPlatform: PageSectionModalVideoPlatform | null
            openVideoModalText: string | null
            overlayColorDesktop: string | null
            overlayColorMobile: string | null
            paragraph: string | null
            textAlignDesktop: string | null
            textAlignMobile: string | null
            textColorDesktop: string | null
            textColorMobile: string | null
            textContentMaxWidthDesktop: number | null
            textContentMaxWidthMobile: number | null
            textPlacementDesktop: string
            textPlacementMobile: string
            title: string | null
            titleTextSize: TitleTextSize
            titleImage: string | null
            titleImageMaxWidthDesktop: number | null
            titleImageMaxWidthMobile: number | null
            hotspot: { __typename?: 'Hotspot'; linkHref1: string } | null
            styledContentItems: Array<
              | {
                  __typename: 'StyledImage'
                  id: string
                  imageMobile: string | null
                  imageDesktop: string | null
                  maxWidthMobile: number | null
                  maxWidthDesktop: number | null
                  altText: string
                  aspectRatioMobile: number | null
                  aspectRatioDesktop: number | null
                  bottomMargin: number | null
                }
              | {
                  __typename: 'StyledText'
                  id: string
                  title: string | null
                  titleTextSize: TitleTextSize
                  paragraph: string | null
                  eyebrow: string | null
                  textColorMobile: string
                  textColorDesktop: string
                  textContentMaxWidthMobile: number | null
                  textContentMaxWidthDesktop: number | null
                  textAlignMobile: TextAlignMobile
                  textAlignDesktop: TextAlignDesktop
                  bottomMargin: number | null
                  links: Array<{ __typename?: 'StyledLink'; id: string; text: string; url: string }>
                }
            >
          }
        | {
            __typename: 'PageHeroSection'
            id: string
            backgroundColor: string | null
            buttonVariantDesktop: string | null
            buttonVariantMobile: string | null
            description: string | null
            eyebrow: string | null
            hideDownArrow: boolean | null
            imageDesktop: string | null
            imageMobile: string | null
            inlineVideoDesktop: string | null
            inlineVideoMobile: string | null
            inlineVimeoIdDesktop: number | null
            inlineVimeoIdMobile: number | null
            isTitleSerif: boolean | null
            linkHref1: string | null
            linkHref2: string | null
            linkText1: string | null
            linkText2: string | null
            modalVideoId: string | null
            modalVideoIdMobile: string | null
            modalVideoPlatform: PageSectionModalVideoPlatform | null
            modalVideoPlatformMobile: PageSectionModalVideoPlatform | null
            openVideoModalText: string | null
            subtitle: string | null
            mobileTitleColor: string
            desktopTitleColor: string
            textContentMaxWidthDesktop: number | null
            textContentMaxWidthMobile: number | null
            textPlacementDesktop: string
            textPlacementMobile: string
            title: string | null
            titleImage: string | null
            titleImageAspectRatio: number
            titleImageMaxWidthDesktop: number | null
            titleImageMaxWidthMobile: number | null
            titleImageMobile: string | null
            titleImageMobileAspectRatio: number
            isFullscreenHeight: boolean
            hasVerticalMargin: boolean
          }
        | {
            __typename: 'PageTileSection'
            id: string
            numColsDesktop: number
            numColsMobile: number
            tileAspectRatio: number | null
            textAlign: string
            textPlacement: string
            title: string | null
            titleTextSize: TitleTextSize
            variantDesktop: string
            variantMobile: string
            titleAlignmentDesktop: string | null
            titleAlignmentMobile: string | null
            hasHorizontalMargin: boolean
            hasVerticalMargin: boolean
            hasTileGaps: boolean
            tiles: Array<{
              __typename: 'PageTileSectionTile'
              ctaText: string | null
              ctaLinkHref: string | null
              description: string | null
              hoverImage: string | null
              hoverVideo: string | null
              id: string
              image: string | null
              inlineVideo: string | null
              inlineVimeoId: number | null
              jumboTitle: string | null
              linkHref: string
              name: string | null
              titleImage: string | null
            }>
          }
      >
    } | null
    sizeChart: {
      __typename?: 'SizeChartCG'
      columnNames: Array<string> | null
      columnValues: Array<Array<string>> | null
      imageUri: string | null
      name: string
      navLabel: string | null
      title: string
      video: string | null
      tables: Array<{
        __typename?: 'SizeChartTable'
        cells: string
        measureImage: string | null
        subtitle: string | null
        tabLabel: string | null
        title: string | null
        measureTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
        sizeTips: Array<{
          __typename?: 'SizeChartTipCG'
          kind: string | null
          tip: string | null
        }> | null
      }> | null
      tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
    }
  } | null
}

export type ProductVariantFragment = {
  __typename?: 'Variant'
  category: string
  color: string | null
  currencyType: string
  discountPrice: any | null
  fit: string | null
  handle: string
  price: any
  shopifyId: string
  size: string | null
  sku: string | null
  soldOut: boolean
  priceDetails: {
    __typename?: 'PriceDetails'
    discountPrice: { __typename?: 'Price'; amount: any | null } | null
    price: {
      __typename?: 'Price'
      currency: string
      amount: any | null
      base: { __typename?: 'Money'; amount: any | null; currency: string } | null
    } | null
  } | null
}

export type RichTextSectionFragmentFragment = {
  __typename: 'RichTextSection'
  id: string
  content: any
}

export type SearchQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
  selectedFilters?: InputMaybe<Array<SearchFilterInput> | SearchFilterInput>
}>

export type SearchQuery = {
  __typename?: 'Query'
  search: {
    __typename?: 'SearchContext'
    suggestions: Array<{ __typename?: 'SearchSuggestion'; query: string }>
    items: {
      __typename?: 'SearchResultConnection'
      analyticsId: string | null
      numItems: number
      nodes: Array<{
        __typename?: 'SearchResultItem'
        product: {
          __typename?: 'Product'
          id: string
          category: string
          rawCategory: string
          collectionBadgeColor: string | null
          collectionBadgeText: string | null
          iconBadge: any | null
          iconBadgePlacement: string | null
          isKit: boolean
          handle: string | null
          shopifyId: string
          availableFits: Array<string>
          rawAvailableFits: Array<string>
          waitlistable: boolean
          swPromoEligible: boolean
          priceRange: any | null
          finalSale: boolean
          colorInfo: {
            __typename?: 'ProductColor'
            availability: string
            hexCodes: Array<string> | null
            name: string
            rawName: string
            swatchUrl: string | null
          } | null
          defaultVariant: {
            __typename?: 'Variant'
            discountPriceExplicitFmt: string
            priceExplicitFmt: string
            shopifyId: string
            sku: string | null
            priceDetails: {
              __typename?: 'PriceDetails'
              discountPrice: { __typename?: 'Price'; amount: any | null } | null
              price: {
                __typename?: 'Price'
                currency: string
                amount: any | null
                base: { __typename?: 'Money'; amount: any | null; currency: string } | null
              } | null
            } | null
          }
          images: {
            __typename?: 'ProductAssetConnection'
            nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
          } | null
          productGroup: {
            __typename?: 'ProductGroup'
            collectionHandle: string | null
            description: string | null
            fits: Array<string>
            handle: string
            iconBadge: any | null
            iconBadgePlacement: string | null
            numColors: number
            rawFits: Array<string>
            style: string | null
            title: string
            priceRange: { __typename?: 'PriceRange'; min: any; max: any } | null
          }
        }
      }>
      pageInfo: { __typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
      colors: Array<{ __typename?: 'SearchResultColor'; name: string; hexCodes: Array<string> }>
      filters: Array<{
        __typename?: 'SearchResultFilter'
        displayType: string
        displayName: string
        key: string
        filterValues: Array<{
          __typename?: 'SearchResultFilterValue'
          value: string
          state: SearchResultFilterState
        }>
      }>
    }
  }
}

export type SearchAutocompleteQueryVariables = Exact<{
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchAutocompleteQuery = {
  __typename?: 'Query'
  search: {
    __typename?: 'SearchContext'
    suggestions: Array<{ __typename?: 'SearchSuggestion'; query: string }>
    items: {
      __typename?: 'SearchResultConnection'
      nodes: Array<{
        __typename?: 'SearchResultItem'
        product: {
          __typename?: 'Product'
          id: string
          handle: string | null
          title: string
          category: string
          images: {
            __typename?: 'ProductAssetConnection'
            nodes: Array<{ __typename?: 'ProductAsset'; source: string }>
          } | null
          defaultVariant: {
            __typename?: 'Variant'
            shopifyId: string
            sku: string | null
            priceDetails: {
              __typename?: 'PriceDetails'
              discountPrice: { __typename?: 'Price'; amount: any | null } | null
              price: { __typename?: 'Price'; amount: any | null; currency: string } | null
            } | null
          }
        }
      }>
    }
  }
}

export type ShopOrderDetailsQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ShopOrderDetailsQuery = {
  __typename?: 'Query'
  shopOrderDetails: {
    __typename?: 'ShopOrder'
    createdAt: string | null
    name: string
    total: number | null
    displayFulfillmentStatus: string | null
    displayFinancialStatus: string | null
    fulfillments: Array<{
      __typename?: 'ShopifyFulfillment'
      deliveredAt: string | null
      displayStatus: string | null
      trackingInfo: Array<{
        __typename?: 'TrackingInfo'
        url: string | null
        number: string | null
        company: string | null
      }> | null
    }> | null
    lineItems: Array<{
      __typename?: 'ShopOrderLineItem'
      altText: string | null
      fulfillmentStatus: string | null
      imgSrc: string | null
      name: string
      quantity: number
      sku: string
      title: string
      total: number
    }> | null
  } | null
}

export type ShopOrderFragment = {
  __typename?: 'ShopOrder'
  createdAt: string | null
  name: string
  total: number | null
  displayFulfillmentStatus: string | null
  displayFinancialStatus: string | null
  fulfillments: Array<{
    __typename?: 'ShopifyFulfillment'
    deliveredAt: string | null
    displayStatus: string | null
    trackingInfo: Array<{
      __typename?: 'TrackingInfo'
      url: string | null
      number: string | null
      company: string | null
    }> | null
  }> | null
  lineItems: Array<{
    __typename?: 'ShopOrderLineItem'
    altText: string | null
    fulfillmentStatus: string | null
    imgSrc: string | null
    name: string
    quantity: number
    sku: string
    title: string
    total: number
  }> | null
}

export type TileProductGroupFragment = {
  __typename?: 'ProductGroup'
  collectionHandle: string | null
  description: string | null
  fits: Array<string>
  handle: string
  iconBadge: any | null
  iconBadgePlacement: string | null
  id: string
  rawFits: Array<string>
  sizes: Array<string>
  style: string | null
  defaultVariant: {
    __typename?: 'Variant'
    color: string | null
    shopifyId: string
    sku: string | null
    priceDetails: {
      __typename?: 'PriceDetails'
      discountPrice: { __typename?: 'Price'; amount: any | null } | null
      price: {
        __typename?: 'Price'
        currency: string
        amount: any | null
        base: { __typename?: 'Money'; amount: any | null; currency: string } | null
      } | null
    } | null
  } | null
  productsForAvailability: Array<{
    __typename?: 'Product'
    availableSizes: Array<string>
    rawFit: string | null
    swPromoEligible: boolean
    colorInfo: { __typename?: 'ProductColor'; rawName: string } | null
  }>
  sizeChart: {
    __typename?: 'SizeChartCG'
    columnNames: Array<string> | null
    columnValues: Array<Array<string>> | null
    imageUri: string | null
    name: string
    navLabel: string | null
    title: string
    video: string | null
    tables: Array<{
      __typename?: 'SizeChartTable'
      cells: string
      measureImage: string | null
      subtitle: string | null
      tabLabel: string | null
      title: string | null
      measureTips: Array<{
        __typename?: 'SizeChartTipCG'
        kind: string | null
        tip: string | null
      }> | null
      sizeTips: Array<{
        __typename?: 'SizeChartTipCG'
        kind: string | null
        tip: string | null
      }> | null
    }> | null
    tips: Array<{ __typename?: 'SizeChartTipCG'; kind: string | null; tip: string | null }> | null
  }
}

export type VideoModalBannerSectionFragmentFragment = {
  __typename?: 'VideoModalBannerSection'
  id: string
  body: string
  ctaText: string
  heading: string
  image: string | null
  inlineVideo: string | null
  inlineVimeoId: number | null
  linkHref: string | null
  videoId: string | null
  videoPlatform: string | null
}

export type VideoSectionFragmentFragment = {
  __typename: 'VideoSection'
  buttonVariant: string | null
  id: string
  imageDesktop: string | null
  imageMobile: string | null
  inlineVideoDesktop: string | null
  inlineVideoMobile: string | null
  inlineVimeoIdDesktop: number | null
  inlineVimeoIdMobile: number | null
  linkHref1: string | null
  linkHref2: string | null
  linkText1: string | null
  linkText2: string | null
  modalVideoId: string | null
  modalVideoPlatform: PageSectionModalVideoPlatform | null
  subtitle: string | null
  textColor: string
  textContentMaxWidthDesktop: number | null
  textContentMaxWidthMobile: number | null
  title: string | null
  titleImageDesktop: string | null
  titleImageMobile: string | null
}

export type VisualFiltersSectionFragmentFragment = {
  __typename: 'VisualFiltersSection'
  id: string
  layoutVariantMobile: VisualFiltersSectionLayoutVariantMobile
  layoutVariantDesktop: VisualFiltersSectionLayoutVariantDesktop
  hideOnMobile: boolean
  hideOnDesktop: boolean
  isCoreStylesVariant: boolean
  entryCardHeader: string | null
  entryCardDescription: string | null
  tiles: Array<{
    __typename: 'VisualFilterTile'
    id: string
    ctaText: string
    value: string
    filter: string
    image: string
    description: string | null
    filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
  }>
}

export type VisualFiltersTilesFragmentFragment = {
  __typename: 'VisualFilterTile'
  id: string
  ctaText: string
  value: string
  filter: string
  image: string
  description: string | null
  filterSpec: { __typename: 'ProductFilterSpec'; id: string; name: string } | null
}

export type WhoamiQueryVariables = Exact<{ [key: string]: never }>

export type WhoamiQuery = {
  __typename?: 'Query'
  whoami: { __typename?: 'User'; email: string } | null
}

export type GetGuestOrderDetailsQueryVariables = Exact<{
  name: Scalars['String']['input']
  email: Scalars['String']['input']
}>

export type GetGuestOrderDetailsQuery = {
  __typename?: 'Query'
  guestOrderDetails: {
    __typename?: 'Order'
    discountCode: string
    netPayment: number
    status: OrderStatus
    subtotal: number
    total: number
    totalRefunded: number
    totalShipping: number
    totalTax: number
    createdAt: any
    id: string
    displayFinancialStatus: string
    displayFulfillmentStatus: string
    cancellable: boolean
    cancelledAt: any | null
    fulfillmentOrderStatus: string
    name: string
    transactions: Array<{
      __typename?: 'OrderTransaction'
      accountNumber: string
      amount: number
      authorizationCode: string
      errorCode: string
      formattedGateway: string
      gateway: string
      id: string
      kind: string
      processedAt: any
      status: string
      paymentIcon: { __typename?: 'ShopifyImage'; altText: string; url: string } | null
    }>
    paymentMethod: {
      __typename?: 'PaymentMethod'
      description: string
      expiration: string
      icon: { __typename?: 'ShopifyImage'; url: string } | null
    } | null
    shippingAddress: {
      __typename?: 'Address'
      phone: string
      address1: string
      address2: string
      city: string
      zip: string
      province: string
      country: string
    } | null
    fulfillments: Array<{
      __typename?: 'OrderFulfillment'
      displayStatus: string
      deliveredAt: string
      trackingInfo: Array<{
        __typename?: 'OrderTrackingInfo'
        url: string
        number: string
        company: string
      }>
    }>
    lineItems: {
      __typename?: 'OrderLineItemConnection'
      nodes: Array<{
        __typename?: 'OrderLineItem'
        id: string
        title: string
        quantity: number
        sku: string
        total: number
        name: string
        fulfillmentStatus: string
        image: { __typename?: 'ShopifyImage'; url: string }
        customAttributes: Array<{ __typename?: 'Attribute'; key: string; value: string }>
        variant: {
          __typename?: 'Variant'
          size: string | null
          fit: string | null
          colorInfo: { __typename?: 'ProductColor'; name: string } | null
        } | null
      }>
    }
    refunds: Array<{
      __typename?: 'OrderRefund'
      refundLineItems: {
        __typename?: 'RefundLineItemConnection'
        nodes: Array<{
          __typename?: 'RefundLineItem'
          lineItem: { __typename?: 'OrderLineItem'; id: string }
        }>
      }
    }>
  } | null
}

export type CustomerFragmentFragment = {
  __typename?: 'Customer'
  id: string
  email: string
  groups: Array<string> | null
  shopifyId: string | null
  addresses: {
    __typename?: 'AddressConnection'
    nodes: Array<{
      __typename?: 'Address'
      address1: string
      address2: string
      city: string
      company: string
      country: string
      firstName: string
      lastName: string
      phone: string
      province: string
      shopifyId: string
      zip: string
    }>
  }
  defaultAddress: {
    __typename?: 'Address'
    address1: string
    address2: string
    city: string
    country: string
    firstName: string
    lastName: string
    phone: string
    province: string
    shopifyId: string
    zip: string
  } | null
  profile: {
    __typename?: 'CustomerProfile'
    avatarUri: string | null
    emailOptIn: boolean
    firstName: string | null
    id: string
    lastName: string | null
    occupation: string | null
    ordersCount: number
    phone: string | null
    stipend: number | null
    stylePreference: CustomerProfileStylePreference | null
    birthday: string | null
  }
  portal: {
    __typename?: 'PortalCG'
    collectionHandle: string | null
    accountHeroSectionCG: {
      __typename?: 'CollectionViewHeroSection'
      backgroundColor: string
      ctaText: string | null
      ctaLink: string | null
      id: string
      imageDesktop: string | null
      imageMobile: string | null
      imagePlacement: string | null
      inlineVideoDesktop: string | null
      inlineVideoMobile: string | null
      inlineVimeoIdDesktop: number | null
      inlineVimeoIdMobile: number | null
      subtitle: string | null
      textColor: string
      title: string | null
      videoId: string | null
      videoPlatform: string | null
    } | null
  } | null
}

export type MyQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
}>

export type MyQuery = {
  __typename?: 'Query'
  getUploadAvatarUrl: string
  my: {
    __typename?: 'Customer'
    id: string
    email: string
    groups: Array<string> | null
    shopifyId: string | null
    orders: {
      __typename?: 'OrderConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
      nodes: Array<{
        __typename?: 'Order'
        createdAt: any
        displayFinancialStatus: string
        displayFulfillmentStatus: string
        id: string
        name: string
        status: OrderStatus
        total: number
        fulfillments: Array<{
          __typename?: 'OrderFulfillment'
          deliveredAt: string
          displayStatus: string
          trackingInfo: Array<{
            __typename?: 'OrderTrackingInfo'
            company: string
            number: string
            url: string
          }>
        }>
        lineItems: {
          __typename?: 'OrderLineItemConnection'
          nodes: Array<{
            __typename?: 'OrderLineItem'
            id: string
            fulfillmentStatus: string
            image: { __typename?: 'ShopifyImage'; url: string }
          }>
        }
      }>
    }
    addresses: {
      __typename?: 'AddressConnection'
      nodes: Array<{
        __typename?: 'Address'
        address1: string
        address2: string
        city: string
        company: string
        country: string
        firstName: string
        lastName: string
        phone: string
        province: string
        shopifyId: string
        zip: string
      }>
    }
    defaultAddress: {
      __typename?: 'Address'
      address1: string
      address2: string
      city: string
      country: string
      firstName: string
      lastName: string
      phone: string
      province: string
      shopifyId: string
      zip: string
    } | null
    profile: {
      __typename?: 'CustomerProfile'
      avatarUri: string | null
      emailOptIn: boolean
      firstName: string | null
      id: string
      lastName: string | null
      occupation: string | null
      ordersCount: number
      phone: string | null
      stipend: number | null
      stylePreference: CustomerProfileStylePreference | null
      birthday: string | null
    }
    portal: {
      __typename?: 'PortalCG'
      collectionHandle: string | null
      accountHeroSectionCG: {
        __typename?: 'CollectionViewHeroSection'
        backgroundColor: string
        ctaText: string | null
        ctaLink: string | null
        id: string
        imageDesktop: string | null
        imageMobile: string | null
        imagePlacement: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        subtitle: string | null
        textColor: string
        title: string | null
        videoId: string | null
        videoPlatform: string | null
      } | null
    } | null
  }
}

export type MyLimitedOrderQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
}>

export type MyLimitedOrderQuery = {
  __typename?: 'Query'
  getUploadAvatarUrl: string
  my: {
    __typename?: 'Customer'
    id: string
    email: string
    groups: Array<string> | null
    shopifyId: string | null
    orders: {
      __typename?: 'OrderStorefrontConnection'
      nodes: Array<{
        __typename?: 'OrderStorefront'
        name: string
        createdAt: any
        total: number
        fulfillments: Array<{
          __typename?: 'OrderFulfillment'
          trackingInfo: Array<{ __typename?: 'OrderTrackingInfo'; url: string }>
        }>
        lineItems: {
          __typename?: 'OrderLineItemStorefrontConnection'
          nodes: Array<{
            __typename?: 'OrderLineItemStorefront'
            title: string
            image: { __typename?: 'ShopifyImage'; altText: string; url: string } | null
          }>
        }
      }>
    }
    addresses: {
      __typename?: 'AddressConnection'
      nodes: Array<{
        __typename?: 'Address'
        address1: string
        address2: string
        city: string
        company: string
        country: string
        firstName: string
        lastName: string
        phone: string
        province: string
        shopifyId: string
        zip: string
      }>
    }
    defaultAddress: {
      __typename?: 'Address'
      address1: string
      address2: string
      city: string
      country: string
      firstName: string
      lastName: string
      phone: string
      province: string
      shopifyId: string
      zip: string
    } | null
    profile: {
      __typename?: 'CustomerProfile'
      avatarUri: string | null
      emailOptIn: boolean
      firstName: string | null
      id: string
      lastName: string | null
      occupation: string | null
      ordersCount: number
      phone: string | null
      stipend: number | null
      stylePreference: CustomerProfileStylePreference | null
      birthday: string | null
    }
    portal: {
      __typename?: 'PortalCG'
      collectionHandle: string | null
      accountHeroSectionCG: {
        __typename?: 'CollectionViewHeroSection'
        backgroundColor: string
        ctaText: string | null
        ctaLink: string | null
        id: string
        imageDesktop: string | null
        imageMobile: string | null
        imagePlacement: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        subtitle: string | null
        textColor: string
        title: string | null
        videoId: string | null
        videoPlatform: string | null
      } | null
    } | null
  }
}

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>

export type MyProfileQuery = {
  __typename?: 'Query'
  getUploadAvatarUrl: string
  my: {
    __typename?: 'Customer'
    id: string
    email: string
    groups: Array<string> | null
    shopifyId: string | null
    profile: {
      __typename?: 'CustomerProfile'
      avatarUri: string | null
      emailOptIn: boolean
      firstName: string | null
      id: string
      lastName: string | null
      occupation: string | null
      ordersCount: number
      phone: string | null
      stipend: number | null
      stylePreference: CustomerProfileStylePreference | null
      birthday: string | null
    }
    portal: {
      __typename?: 'PortalCG'
      collectionHandle: string | null
      name: string | null
      discountPercentage: number | null
      pdpCtaText: string | null
      quickBuyText: string
      accountHeroSectionCG: {
        __typename?: 'CollectionViewHeroSection'
        backgroundColor: string
        ctaText: string | null
        ctaLink: string | null
        id: string
        imageDesktop: string | null
        imageMobile: string | null
        imagePlacement: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        subtitle: string | null
        textColor: string
        title: string | null
        videoId: string | null
        videoPlatform: string | null
      } | null
      collectionHeroSectionCG: {
        __typename?: 'CollectionViewHeroSection'
        backgroundColor: string
        ctaText: string | null
        id: string
        imageDesktop: string | null
        imageMobile: string | null
        imagePlacement: string | null
        inlineVideoDesktop: string | null
        inlineVideoMobile: string | null
        inlineVimeoIdDesktop: number | null
        inlineVimeoIdMobile: number | null
        subtitle: string | null
        textColor: string
        title: string | null
        videoId: string | null
        videoPlatform: string | null
      } | null
      promoBarPages: Array<{ __typename?: 'PromoBarPage'; id: string; content: string }>
      embroidery: {
        __typename?: 'PortalEmbroiderySettings'
        image: string
        addText: string
        yesText: string | null
        noText: string | null
        logoName: string
        eligibleProductCategories: Array<string>
        mandatoryTextEmbroideryCategories: Array<string>
        mandatoryLogoEmbroideryCategories: Array<string>
        embroideryDiscountPercentage: number | null
        hiddenTextEmbroideryFields: Array<PortalHiddenTextEmbroideryFields>
        defaultTextEmbroideryValues: {
          __typename?: 'PortalDefaultTextEmbroideryValues'
          textColor: PortalDefaultTextEmbroideryColor | null
          font: PortalDefaultTextEmbroideryFont | null
          textPlacement: string | null
        }
      } | null
    } | null
  }
}

export type MyAddressesQueryVariables = Exact<{ [key: string]: never }>

export type MyAddressesQuery = {
  __typename?: 'Query'
  getUploadAvatarUrl: string
  my: {
    __typename?: 'Customer'
    id: string
    email: string
    groups: Array<string> | null
    shopifyId: string | null
    addresses: {
      __typename?: 'AddressConnection'
      nodes: Array<{
        __typename?: 'Address'
        address1: string
        address2: string
        city: string
        company: string
        country: string
        firstName: string
        lastName: string
        phone: string
        province: string
        shopifyId: string
        zip: string
      }>
    }
    defaultAddress: {
      __typename?: 'Address'
      address1: string
      address2: string
      city: string
      country: string
      firstName: string
      lastName: string
      phone: string
      province: string
      shopifyId: string
      zip: string
    } | null
  }
}

export type MyOrderDetailsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
}>

export type MyOrderDetailsQuery = {
  __typename?: 'Query'
  getUploadAvatarUrl: string
  my: {
    __typename?: 'Customer'
    id: string
    profile: { __typename?: 'CustomerProfile'; ordersCount: number }
    orders: {
      __typename?: 'OrderConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
      nodes: Array<{
        __typename?: 'Order'
        createdAt: any
        displayFinancialStatus: string
        displayFulfillmentStatus: string
        status: OrderStatus
        id: string
        name: string
        total: number
        fulfillments: Array<{
          __typename?: 'OrderFulfillment'
          deliveredAt: string
          displayStatus: string
          trackingInfo: Array<{
            __typename?: 'OrderTrackingInfo'
            company: string
            number: string
            url: string
          }>
        }>
      }>
    }
  }
}

export type MyOrdersPageInfoQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  reverse?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
}>

export type MyOrdersPageInfoQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    orders: {
      __typename?: 'OrderConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
    }
  }
}

export type MyEmbroideryLogosQueryVariables = Exact<{ [key: string]: never }>

export type MyEmbroideryLogosQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    embroideryLogos: Array<{
      __typename?: 'EmbroideryLogoDTC'
      id: string
      status: LogoStatus
      name: string | null
      notes: string | null
      preview: string
      digitizationSetting: LogoDigitizationSetting
    } | null> | null
  }
}

export type GetOrderDetailsQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetOrderDetailsQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    order: {
      __typename?: 'Order'
      discountCode: string
      netPayment: number
      status: OrderStatus
      subtotal: number
      total: number
      totalRefunded: number
      totalShipping: number
      totalTax: number
      createdAt: any
      id: string
      displayFinancialStatus: string
      displayFulfillmentStatus: string
      cancellable: boolean
      cancelledAt: any | null
      fulfillmentOrderStatus: string
      name: string
      transactions: Array<{
        __typename?: 'OrderTransaction'
        accountNumber: string
        amount: number
        authorizationCode: string
        errorCode: string
        formattedGateway: string
        gateway: string
        id: string
        kind: string
        processedAt: any
        status: string
        paymentIcon: { __typename?: 'ShopifyImage'; altText: string; url: string } | null
      }>
      paymentMethod: {
        __typename?: 'PaymentMethod'
        description: string
        expiration: string
        icon: { __typename?: 'ShopifyImage'; url: string } | null
      } | null
      shippingAddress: {
        __typename?: 'Address'
        phone: string
        address1: string
        address2: string
        city: string
        zip: string
        province: string
        country: string
      } | null
      fulfillments: Array<{
        __typename?: 'OrderFulfillment'
        displayStatus: string
        deliveredAt: string
        trackingInfo: Array<{
          __typename?: 'OrderTrackingInfo'
          url: string
          number: string
          company: string
        }>
      }>
      lineItems: {
        __typename?: 'OrderLineItemConnection'
        nodes: Array<{
          __typename?: 'OrderLineItem'
          id: string
          title: string
          quantity: number
          sku: string
          total: number
          name: string
          fulfillmentStatus: string
          image: { __typename?: 'ShopifyImage'; url: string }
          customAttributes: Array<{ __typename?: 'Attribute'; key: string; value: string }>
          variant: {
            __typename?: 'Variant'
            size: string | null
            fit: string | null
            colorInfo: { __typename?: 'ProductColor'; name: string } | null
          } | null
        }>
      }
      refunds: Array<{
        __typename?: 'OrderRefund'
        refundLineItems: {
          __typename?: 'RefundLineItemConnection'
          nodes: Array<{
            __typename?: 'RefundLineItem'
            lineItem: { __typename?: 'OrderLineItem'; id: string }
          }>
        }
      }>
    }
  }
}

export type GetOrderLineItemsQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetOrderLineItemsQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'Customer'
    order: {
      __typename?: 'Order'
      lineItems: {
        __typename?: 'OrderLineItemConnection'
        nodes: Array<{
          __typename?: 'OrderLineItem'
          id: string
          fulfillmentStatus: string
          image: { __typename?: 'ShopifyImage'; url: string }
        }>
      }
    }
  }
}

export type AccordionViewFragmentFragment = {
  __typename?: 'AccordionView'
  maxWidth: number | null
  sections: Array<{
    __typename?: 'AccordionViewSection'
    id: string
    title: string
    content:
      | { __typename: 'RawHtmlSection'; id: string; html: string }
      | { __typename: 'RichTextSection'; id: string; content: any }
      | null
  }>
}

export type CollectionSectionFragmentFragment = {
  __typename: 'CollectionSection'
  id: string
  collectionHandle: string
  featuredInlineVideoDesktop: string | null
  featuredInlineVideoMobile: string | null
  hideColors: boolean | null
  hidePrice: boolean | null
  linkHref: string | null
  linkText: string | null
  productCountLimit: number | null
  featuredImage: {
    __typename: 'StyledImage'
    id: string
    imageMobile: string | null
    imageDesktop: string | null
    altText: string
    maxWidthMobile: number | null
    maxWidthDesktop: number | null
    aspectRatioMobile: number | null
    aspectRatioDesktop: number | null
    bottomMargin: number | null
    altTextMobile: string | null
    altTextDesktop: string | null
  } | null
  links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
  titleLockupImage: {
    __typename: 'StyledImage'
    id: string
    imageMobile: string | null
    imageDesktop: string | null
    altText: string
    maxWidthMobile: number | null
    maxWidthDesktop: number | null
    aspectRatioMobile: number | null
    aspectRatioDesktop: number | null
    bottomMargin: number | null
    altTextMobile: string | null
    altTextDesktop: string | null
  } | null
  subtitleText: {
    __typename: 'StyledText'
    id: string
    title: string | null
    titleTextSize: TitleTextSize
    paragraph: string | null
    paragraphTextSize: ParagraphTextSize
    eyebrow: string | null
    textColorMobile: string
    textColorDesktop: string
    textContentMaxWidthMobile: number | null
    textContentMaxWidthDesktop: number | null
    textAlignMobile: TextAlignMobile
    textAlignDesktop: TextAlignDesktop
    bottomMargin: number | null
    links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
  } | null
  titleText: {
    __typename: 'StyledText'
    id: string
    title: string | null
    titleTextSize: TitleTextSize
    paragraph: string | null
    paragraphTextSize: ParagraphTextSize
    eyebrow: string | null
    textColorMobile: string
    textColorDesktop: string
    textContentMaxWidthMobile: number | null
    textContentMaxWidthDesktop: number | null
    textAlignMobile: TextAlignMobile
    textAlignDesktop: TextAlignDesktop
    bottomMargin: number | null
    links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
  } | null
  colorsForFilters: Array<{
    __typename?: 'Color'
    id: string
    handle: string
    name: string
    hexCodes: Array<string>
  }> | null
  featuredImages: Array<{
    __typename: 'StyledImage'
    id: string
    imageMobile: string | null
    imageDesktop: string | null
    altText: string
    maxWidthMobile: number | null
    maxWidthDesktop: number | null
    aspectRatioMobile: number | null
    aspectRatioDesktop: number | null
    bottomMargin: number | null
    altTextMobile: string | null
    altTextDesktop: string | null
  }> | null
}

export type PageBannerSectionFragmentFragment = {
  __typename: 'PageBannerSection'
  id: string
  backgroundColorDesktop: string | null
  backgroundColorMobile: string | null
  eyebrow: string | null
  hasVerticalMargin: boolean
  imageDesktop: string | null
  imageMobile: string | null
  inlineVideoDesktop: string | null
  inlineVideoMobile: string | null
  inlineVimeoIdDesktop: number | null
  inlineVimeoIdMobile: number | null
  isFullBleedDesktop: boolean | null
  linkHref1: string | null
  linkHref2: string | null
  linkText1: string | null
  linkText2: string | null
  ctaStyle: string
  modalVideoId: string | null
  modalVideoPlatform: PageSectionModalVideoPlatform | null
  openVideoModalText: string | null
  overlayColorDesktop: string | null
  overlayColorMobile: string | null
  paragraph: string | null
  textAlignDesktop: string | null
  textAlignMobile: string | null
  textColorDesktop: string | null
  textColorMobile: string | null
  textContentMaxWidthDesktop: number | null
  textContentMaxWidthMobile: number | null
  textPlacementDesktop: string
  textPlacementMobile: string
  title: string | null
  titleTextSize: TitleTextSize
  titleImage: string | null
  titleImageMaxWidthDesktop: number | null
  titleImageMaxWidthMobile: number | null
  hotspot: { __typename?: 'Hotspot'; linkHref1: string } | null
  styledContentItems: Array<
    | {
        __typename: 'StyledImage'
        id: string
        imageMobile: string | null
        imageDesktop: string | null
        maxWidthMobile: number | null
        maxWidthDesktop: number | null
        altText: string
        aspectRatioMobile: number | null
        aspectRatioDesktop: number | null
        bottomMargin: number | null
      }
    | {
        __typename: 'StyledText'
        id: string
        title: string | null
        titleTextSize: TitleTextSize
        paragraph: string | null
        eyebrow: string | null
        textColorMobile: string
        textColorDesktop: string
        textContentMaxWidthMobile: number | null
        textContentMaxWidthDesktop: number | null
        textAlignMobile: TextAlignMobile
        textAlignDesktop: TextAlignDesktop
        bottomMargin: number | null
        links: Array<{ __typename?: 'StyledLink'; id: string; text: string; url: string }>
      }
  >
}

export type PageEditorialCarouselFragmentFragment = {
  __typename: 'PageEditorialCarouselSection'
  id: string
  backgroundColor: string | null
  hasVerticalMargin: boolean
  overlayOpacity: number | null
  displayVideoLink: boolean
  modalVimeoIdDesktop: number | null
  modalVimeoIdMobile: number | null
  headline: {
    __typename: 'StyledText'
    id: string
    title: string | null
    titleTextSize: TitleTextSize
    paragraph: string | null
    paragraphTextSize: ParagraphTextSize
    eyebrow: string | null
    textColorMobile: string
    textColorDesktop: string
    textContentMaxWidthMobile: number | null
    textContentMaxWidthDesktop: number | null
    textAlignMobile: TextAlignMobile
    textAlignDesktop: TextAlignDesktop
    bottomMargin: number | null
    links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
  } | null
  backgroundImage: {
    __typename: 'StyledImage'
    id: string
    imageMobile: string | null
    imageDesktop: string | null
    altText: string
    maxWidthMobile: number | null
    maxWidthDesktop: number | null
    aspectRatioMobile: number | null
    aspectRatioDesktop: number | null
    bottomMargin: number | null
    altTextMobile: string | null
    altTextDesktop: string | null
  } | null
  bodies: Array<{
    __typename: 'StyledText'
    id: string
    title: string | null
    titleTextSize: TitleTextSize
    paragraph: string | null
    paragraphTextSize: ParagraphTextSize
    eyebrow: string | null
    textColorMobile: string
    textColorDesktop: string
    textContentMaxWidthMobile: number | null
    textContentMaxWidthDesktop: number | null
    textAlignMobile: TextAlignMobile
    textAlignDesktop: TextAlignDesktop
    bottomMargin: number | null
    links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
  }>
  cta1: {
    __typename?: 'StyledText'
    title: string | null
    titleTextSize: TitleTextSize
    paragraph: string | null
    eyebrow: string | null
    textColorMobile: string
    textColorDesktop: string
    textContentMaxWidthMobile: number | null
    textContentMaxWidthDesktop: number | null
    textAlignMobile: TextAlignMobile
    textAlignDesktop: TextAlignDesktop
    bottomMargin: number | null
    links: Array<{ __typename?: 'StyledLink'; text: string; url: string }>
  } | null
  cta2: {
    __typename?: 'StyledText'
    title: string | null
    titleTextSize: TitleTextSize
    paragraph: string | null
    eyebrow: string | null
    textColorMobile: string
    textColorDesktop: string
    textContentMaxWidthMobile: number | null
    textContentMaxWidthDesktop: number | null
    textAlignMobile: TextAlignMobile
    textAlignDesktop: TextAlignDesktop
    bottomMargin: number | null
    links: Array<{ __typename?: 'StyledLink'; text: string; url: string }>
  } | null
  tileCarousel: {
    __typename?: 'PageTileSection'
    numColsDesktop: number
    numColsMobile: number
    tileAspectRatio: number | null
    textAlign: string
    textPlacement: string
    tiles: Array<{
      __typename?: 'PageTileSectionTile'
      titleImage: string | null
      jumboTitle: string | null
      description: string | null
      linkHref: string
      image: string | null
      inlineVideo: string | null
      inlineVimeoId: number | null
      hoverImage: string | null
      hoverVideo: string | null
      ctaText: string | null
      ctaLinkHref: string | null
    }>
  } | null
}

export type PageHeroSectionFragmentFragment = {
  __typename: 'PageHeroSection'
  id: string
  backgroundColor: string | null
  buttonVariantDesktop: string | null
  buttonVariantMobile: string | null
  description: string | null
  eyebrow: string | null
  hideDownArrow: boolean | null
  imageDesktop: string | null
  imageMobile: string | null
  inlineVideoDesktop: string | null
  inlineVideoMobile: string | null
  inlineVimeoIdDesktop: number | null
  inlineVimeoIdMobile: number | null
  isTitleSerif: boolean | null
  linkHref1: string | null
  linkHref2: string | null
  linkText1: string | null
  linkText2: string | null
  modalVideoId: string | null
  modalVideoIdMobile: string | null
  modalVideoPlatform: PageSectionModalVideoPlatform | null
  modalVideoPlatformMobile: PageSectionModalVideoPlatform | null
  openVideoModalText: string | null
  subtitle: string | null
  mobileTitleColor: string
  desktopTitleColor: string
  textContentMaxWidthDesktop: number | null
  textContentMaxWidthMobile: number | null
  textPlacementDesktop: string
  textPlacementMobile: string
  title: string | null
  titleImage: string | null
  titleImageAspectRatio: number
  titleImageMaxWidthDesktop: number | null
  titleImageMaxWidthMobile: number | null
  titleImageMobile: string | null
  titleImageMobileAspectRatio: number
  isFullscreenHeight: boolean
  hasVerticalMargin: boolean
}

export type PageInteractiveSlideSectionFragmentFragment = {
  __typename: 'PageInteractiveSlideSection'
  id: string
  title: string | null
  isFullBleedDesktop: boolean | null
  hasVerticalMargin: boolean
  interactiveSlides: Array<{
    __typename?: 'InteractiveSlide'
    id: string
    title: string | null
    imageDesktop: string | null
    imageMobile: string | null
    altText: string | null
    aspectRatioMobile: number
    aspectRatioDesktop: number
    overlayOpacity: number
    contentColorDesktop: string
    contentColorMobile: string
    ctaText: string
    ctaLink: string
  }>
}

export type PageTileSectionFragmentFragment = {
  __typename: 'PageTileSection'
  id: string
  numColsDesktop: number
  numColsMobile: number
  tileAspectRatio: number | null
  textAlign: string
  textPlacement: string
  title: string | null
  titleTextSize: TitleTextSize
  variantDesktop: string
  variantMobile: string
  titleAlignmentDesktop: string | null
  titleAlignmentMobile: string | null
  hasHorizontalMargin: boolean
  hasVerticalMargin: boolean
  hasTileGaps: boolean
  tiles: Array<{
    __typename: 'PageTileSectionTile'
    ctaText: string | null
    ctaLinkHref: string | null
    description: string | null
    hoverImage: string | null
    hoverVideo: string | null
    id: string
    image: string | null
    inlineVideo: string | null
    inlineVimeoId: number | null
    jumboTitle: string | null
    linkHref: string
    name: string | null
    titleImage: string | null
  }>
}

export type StyledImageFragmentFragment = {
  __typename: 'StyledImage'
  id: string
  imageMobile: string | null
  imageDesktop: string | null
  altText: string
  maxWidthMobile: number | null
  maxWidthDesktop: number | null
  aspectRatioMobile: number | null
  aspectRatioDesktop: number | null
  bottomMargin: number | null
  altTextMobile: string | null
  altTextDesktop: string | null
}

export type StyledLinkFragmentFragment = {
  __typename: 'StyledLink'
  id: string
  text: string
  url: string
}

export type StyledTextFragmentFragment = {
  __typename: 'StyledText'
  id: string
  title: string | null
  titleTextSize: TitleTextSize
  paragraph: string | null
  paragraphTextSize: ParagraphTextSize
  eyebrow: string | null
  textColorMobile: string
  textColorDesktop: string
  textContentMaxWidthMobile: number | null
  textContentMaxWidthDesktop: number | null
  textAlignMobile: TextAlignMobile
  textAlignDesktop: TextAlignDesktop
  bottomMargin: number | null
  links: Array<{ __typename: 'StyledLink'; id: string; text: string; url: string }>
}

export type FetchServerFixturesQueryVariables = Exact<{
  handle: Scalars['String']['input']
  preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FetchServerFixturesQuery = {
  __typename?: 'Query'
  serverFixtures: {
    __typename?: 'ServerFixtures'
    id: string
    handle: string
    redirectSets: Array<{ __typename?: 'RedirectSet'; code: number; redirects: string }>
  }
}

export const SizeChartFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'sizeChartFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChartCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'measureTips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sizeTips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tips' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'video' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SizeChartFieldsFragment, unknown>
export const CollectionItemsConnectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemsConnection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductConnection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'numItems' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionItemsConnectionFragment, unknown>
export const EditorialCellPlacementFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorialCellPlacement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinToBottom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorialCell' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionByLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPadding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditorialCellPlacementFragmentFragment, unknown>
export const VideoSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoSectionFragmentFragment, unknown>
export const VisualFiltersTilesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFilterTile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterSpec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VisualFiltersTilesFragmentFragment, unknown>
export const VisualFiltersSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFiltersSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreStylesVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardHeader' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFilterTile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterSpec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VisualFiltersSectionFragmentFragment, unknown>
export const CollectionGridSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionGridSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemColorScope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupedProductTiles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtersEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crawlable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorializedCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialCellPlacements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialRows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'VideoSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VideoSectionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSectionV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFilterTile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterSpec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorialCellPlacement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinToBottom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorialCell' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionByLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPadding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFiltersSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreStylesVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardHeader' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionGridSectionFragmentFragment, unknown>
export const CollectionViewHeroSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollectionViewHeroSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionViewHeroSectionFragmentFragment, unknown>
export const GenderNavSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GenderNavSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderNavSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alternateGenderHandle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedGender' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenderNavSectionFragmentFragment, unknown>
export const HeroFullBleedSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroFullBleedSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HeroFullBleedSectionFragmentFragment, unknown>
export const VideoModalBannerSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoModalBannerSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoModalBannerSectionFragmentFragment, unknown>
export const PageTabSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTabSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTabSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabSections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTabTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'layoutVariantDesktop' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantMobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantDesktop' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageTabSectionFragmentFragment, unknown>
export const CollectionLayoutFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionLayoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seoContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionGridSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionViewHeroSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GenderNavSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GenderNavSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'HeroFullBleedSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VideoModalBannerSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VideoSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PageTabSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageTabSectionFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorialCellPlacement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinToBottom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorialCell' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionByLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPadding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFilterTile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterSpec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFiltersSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreStylesVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardHeader' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionGridSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemColorScope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupedProductTiles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtersEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crawlable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorializedCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialCellPlacements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialRows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'VideoSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VideoSectionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSectionV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollectionViewHeroSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GenderNavSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderNavSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alternateGenderHandle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedGender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroFullBleedSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoModalBannerSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTabSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTabSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabSections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTabTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'layoutVariantDesktop' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantMobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantDesktop' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionLayoutFragmentFragment, unknown>
export const CollectionProductFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionProductFilter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductFilter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crawlable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterValues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionProductFilterFragment, unknown>
export const PriceDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceDetailFragment, unknown>
export const CollectionProductGroupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionProductGroupFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selector' },
                value: { kind: 'EnumValue', value: 'ALL' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PriceDetail' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionProductGroupFragmentFragment, unknown>
export const ColorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductColor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorFragment, unknown>
export const ColorGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorGroupFragment, unknown>
export const FavoriteTileProductGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteTileProductGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PriceDetail' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteTileProductGroupFragment, unknown>
export const FeaturedProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeaturedProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeaturedProductFragment, unknown>
export const CollectionItemProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionItemProductFragment, unknown>
export const GiftCollectionItemsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftCollectionItemsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'grouping' },
                value: { kind: 'EnumValue', value: 'PRODUCT_GROUP' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionItemProduct' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'numItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GiftCollectionItemsFragmentFragment, unknown>
export const ProductGroupKitRequirementsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductGroupKitRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeByColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'embroiderable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'embroideryImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productsForAvailability' },
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careInstructions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fitModelsDescriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawMaterials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductGroupKitRequirementsFragment, unknown>
export const NavListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavListItemFragment, unknown>
export const NavListSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavListSectionFragment, unknown>
export const NavListGroupSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListGroupSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListGroupSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'navSections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListSection' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavListGroupSectionFragment, unknown>
export const NavTileSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navTileSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavTileSectionFragment, unknown>
export const RawHtmlSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rawHtmlSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RawHtmlSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'html' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RawHtmlSectionFragment, unknown>
export const NavAccordionSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navAccordionSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavAccordion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'header' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'body' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navListSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navTileSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'rawHtmlSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavAccordion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'header' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListItem' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'NavListSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'navListSection' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'NavTileSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'navTileSection' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'RawHtmlSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'rawHtmlSection' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alternateModelVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetCriteria' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navTileSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rawHtmlSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RawHtmlSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'html' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavAccordionSectionFragment, unknown>
export const NavTabFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navTab' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavTab' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavListSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListSection' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavListGroupSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'navListGroupSection' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavTileSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navTileSection' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RawHtmlSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'rawHtmlSection' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavAccordion' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'navAccordionSection' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavTab' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavListSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavListGroupSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListGroupSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavTileSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navTileSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'RawHtmlSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'rawHtmlSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavAccordion' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navAccordionSection' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navTileSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rawHtmlSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RawHtmlSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'html' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListGroupSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListGroupSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'navSections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListSection' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navAccordionSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavAccordion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'header' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'body' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navListSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navTileSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'rawHtmlSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavAccordion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'header' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListItem' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'NavListSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'navListSection' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'NavTileSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'navTileSection' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'RawHtmlSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'rawHtmlSection' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alternateModelVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetCriteria' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavTabFragment, unknown>
export const ProductEmbroideryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductEmbroidery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Embroidery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iconPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsMaxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textLines' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'textMaxChars' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'designFile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryIconGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductEmbroideryFragment, unknown>
export const PdpFeaturedProductFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PdpFeaturedProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'color' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'embroidery' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'logoScope' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'logoScope' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductEmbroidery' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'detailImages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fitModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pantsSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'topSize' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fitModelCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fitModelsDescriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'badgeList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iconList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawMaterials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'whyWeLove' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careInstructions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upc' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PriceDetail' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductEmbroidery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Embroidery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iconPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsMaxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textLines' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'textMaxChars' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'designFile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryIconGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PdpFeaturedProductFragmentFragment, unknown>
export const PortalProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortalProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortalCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountHeroSectionCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionHeroSectionCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promoBarPages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'embroidery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yesText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibleProductCategories' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryTextEmbroideryCategories' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryLogoEmbroideryCategories' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'embroideryDiscountPercentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultTextEmbroideryValues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'font' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hiddenTextEmbroideryFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pdpCtaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quickBuyText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortalProfileFragment, unknown>
export const PortalStaticFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortalStatic' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortalCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enableSSO' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupTag' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortalStaticFragment, unknown>
export const ProductAvailabilityFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductAvailabilityFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductAvailabilityFragmentFragment, unknown>
export const ProductVariantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductVariant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Variant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductVariantFragment, unknown>
export const RichTextSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RichTextSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RichTextSectionFragmentFragment, unknown>
export const ShopOrderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopOrder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayFulfillmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayFinancialStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fulfillments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopOrderFragment, unknown>
export const TileProductGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TileProductGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productsForAvailability' },
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TileProductGroupFragment, unknown>
export const CustomerFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailOptIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stipend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stylePreference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountHeroSectionCG' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerFragmentFragment, unknown>
export const AccordionViewFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccordionViewFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccordionView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RichTextSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccordionViewFragmentFragment, unknown>
export const StyledImageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextDesktop' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StyledImageFragmentFragment, unknown>
export const StyledLinkFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StyledLinkFragmentFragment, unknown>
export const StyledTextFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledTextFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraphTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledLinkFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StyledTextFragmentFragment, unknown>
export const CollectionSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'featuredInlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featuredInlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledLinkFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productCountLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleLockupImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtitleText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorsForFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextDesktop' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledTextFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraphTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledLinkFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionSectionFragmentFragment, unknown>
export const PageBannerSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageBannerSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageBannerSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotspot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleedDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styledContentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textContentMaxWidthDesktop' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StyledImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageBannerSectionFragmentFragment, unknown>
export const PageEditorialCarouselFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageEditorialCarouselFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageEditorialCarouselSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bodies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayOpacity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cta1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cta2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayVideoLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVimeoIdMobile' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileCarousel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numColsDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColsMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlign' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jumboTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hoverImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hoverVideo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkHref' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledTextFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraphTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledLinkFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextDesktop' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageEditorialCarouselFragmentFragment, unknown>
export const PageHeroSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageHeroSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageHeroSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideDownArrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatformMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobileTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desktopTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullscreenHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageHeroSectionFragmentFragment, unknown>
export const PageInteractiveSlideSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageInteractiveSlideSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageInteractiveSlideSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interactiveSlides' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overlayOpacity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentColorDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentColorMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleedDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageInteractiveSlideSectionFragmentFragment, unknown>
export const PageTileSectionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTileSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jumboTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasHorizontalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTileGaps' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageTileSectionFragmentFragment, unknown>
export const GetCollectionViewForSitemapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCollectionViewForSitemap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionViewIndex' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CollectionGridSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableForSale' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCollectionViewForSitemapQuery,
  GetCollectionViewForSitemapQueryVariables
>
export const GetPageViewForSitemapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageViewForSitemap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageViewIndex' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'handle' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPageViewForSitemapQuery, GetPageViewForSitemapQueryVariables>
export const FetchProductIndexPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchProductIndexPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'genderCategory' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultVariant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableForSale' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchProductIndexPageQuery, FetchProductIndexPageQueryVariables>
export const GetMainMenuNavDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMainMenuNav' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'siteFixtures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'StringValue', value: 'default', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'navigation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'desktopTabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMainMenuNavQuery, GetMainMenuNavQueryVariables>
export const ReviewsHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReviewsHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'handle' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewsHeaderQuery, ReviewsHeaderQueryVariables>
export const ReviewsOverviewProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReviewsOverviewProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewsOverviewProductQuery, ReviewsOverviewProductQueryVariables>
export const GetShopifyIdFromProductForReviewsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShopifyIdFromProductForReviewsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetShopifyIdFromProductForReviewsQueryQuery,
  GetShopifyIdFromProductForReviewsQueryQueryVariables
>
export const CreateProductReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProductReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rating' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProductReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'content' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rating' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rating' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProductReviewMutation, CreateProductReviewMutationVariables>
export const LoginUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginCustomer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginUserMutation, LoginUserMutationVariables>
export const GetUserAfterSuccessfulLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserAfterSuccessfulLogin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserAfterSuccessfulLoginQuery,
  GetUserAfterSuccessfulLoginQueryVariables
>
export const GetUserForRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserForRequest' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserForRequestQuery, GetUserForRequestQueryVariables>
export const GetAppDirClientDemoProductGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppDirClientDemoProductGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'category' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppDirClientDemoProductGroupQuery,
  GetAppDirClientDemoProductGroupQueryVariables
>
export const GetAppDirClientDemoOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppDirClientDemoOrders' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppDirClientDemoOrdersQuery,
  GetAppDirClientDemoOrdersQueryVariables
>
export const GetAppDirServerDemoProductGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppDirServerDemoProductGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'category' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppDirServerDemoProductGroupQuery,
  GetAppDirServerDemoProductGroupQueryVariables
>
export const GetAppDirServerDemoOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppDirServerDemoOrders' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppDirServerDemoOrdersQuery,
  GetAppDirServerDemoOrdersQueryVariables
>
export const FetchUpsellProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchUpsellProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handles' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handles' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'colorInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultVariant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'discountPrice' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'price' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priceDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PriceDetail' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchUpsellProductsQuery, FetchUpsellProductsQueryVariables>
export const FetchFitModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchFitModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fitModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPreview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pantsSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'topSize' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchFitModelsQuery, FetchFitModelsQueryVariables>
export const FetchVariantsBySkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchVariantsBySKU' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductVariant' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductVariant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Variant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchVariantsBySkuQuery, FetchVariantsBySkuQueryVariables>
export const Fetch_Size_ChartsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FETCH_SIZE_CHARTS' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'siteFixtures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mensSizeCharts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'sizeChartFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'womensSizeCharts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'sizeChartFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'sizeChartFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChartCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'measureTips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sizeTips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tips' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'video' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Fetch_Size_ChartsQuery, Fetch_Size_ChartsQueryVariables>
export const ActivateShopUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateShopUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activationUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateShopUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activationUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activationUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivateShopUserMutation, ActivateShopUserMutationVariables>
export const ActivateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activationUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateCustomer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activationUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activationUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivateCustomerMutation, ActivateCustomerMutationVariables>
export const FetchReviewProductGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchReviewProductGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'handle' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchReviewProductGroupQuery, FetchReviewProductGroupQueryVariables>
export const LoginShopUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginShopUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginShopUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginShopUserMutation, LoginShopUserMutationVariables>
export const CreateShopUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createShopUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShopUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateShopUserMutation, CreateShopUserMutationVariables>
export const ForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resetUrl' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>
export const CustomerForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'customerForgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerForgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerForgotPasswordMutation,
  CustomerForgotPasswordMutationVariables
>
export const CustomerResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'customerResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerResetPasswordMutation, CustomerResetPasswordMutationVariables>
export const ChangeCustomerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeCustomerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerChangePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeCustomerPasswordMutation,
  ChangeCustomerPasswordMutationVariables
>
export const LoginCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginCustomer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginCustomerMutation, LoginCustomerMutationVariables>
export const CreateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCustomerMutation, CreateCustomerMutationVariables>
export const GetNewTokensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getNewTokens' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerTokenRefresh' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNewTokensMutation, GetNewTokensMutationVariables>
export const UpdateCustomerProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomerProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerProfileData' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomerProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateCustomerProfileData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerProfileData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomerProfileMutation, UpdateCustomerProfileMutationVariables>
export const CreateCustomerAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'addressData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>
export const DeleteCustomerAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shopifyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deletedCustomerAddressId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCustomerAddressMutation, DeleteCustomerAddressMutationVariables>
export const UpdateCustomerAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shopifyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'addressData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>
export const SetDefaultCustomerAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setDefaultCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shopifyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetDefaultCustomerAddressMutation,
  SetDefaultCustomerAddressMutationVariables
>
export const CreateIdmeShopifyDiscountCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createIdmeShopifyDiscountCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIdmeShopifyDiscountCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateIdmeShopifyDiscountCodeMutation,
  CreateIdmeShopifyDiscountCodeMutationVariables
>
export const VerifyDiscountCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyDiscountCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyDiscountCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'valid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyDiscountCodeMutation, VerifyDiscountCodeMutationVariables>
export const InitateOrderCancellationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'initateOrderCancellation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initateGuestOrderCancellation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitateOrderCancellationMutation,
  InitateOrderCancellationMutationVariables
>
export const SubscribeUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'subscribeUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gender' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationSource' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'gender' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registrationSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationSource' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeUserMutation, SubscribeUserMutationVariables>
export const CollectionPageQueryOptimizedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollectionPageQueryOptimized' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withPortal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollectionLayoutFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collectionHandle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withPortal' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PortalStatic' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorialCellPlacement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinToBottom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorialCell' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionByLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPadding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFilterTile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterSpec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFiltersSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreStylesVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardHeader' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionGridSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemColorScope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupedProductTiles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtersEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crawlable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorializedCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialCellPlacements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialRows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'VideoSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VideoSectionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSectionV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollectionViewHeroSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GenderNavSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderNavSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alternateGenderHandle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedGender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroFullBleedSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoModalBannerSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTabSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTabSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabSections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTabTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'layoutVariantDesktop' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantMobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantDesktop' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionLayoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seoContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionGridSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionViewHeroSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GenderNavSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GenderNavSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'HeroFullBleedSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VideoModalBannerSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VideoSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PageTabSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageTabSectionFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortalStatic' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortalCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enableSSO' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupTag' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollectionPageQueryOptimizedQuery,
  CollectionPageQueryOptimizedQueryVariables
>
export const FetchEmbroideryIconGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchEmbroideryIconGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'embroideryIconGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxIconsPerRowMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabledInitialsText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchEmbroideryIconGroupsQuery,
  FetchEmbroideryIconGroupsQueryVariables
>
export const GetVariantsBySkusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVariantsBySkus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalParentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountPrice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'base' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'genderCategory' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'embroiderable' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'embroideryImages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'embroideryOffsets' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ColorGroup' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetVariantsBySkusQuery, GetVariantsBySkusQueryVariables>
export const GetCollectionViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCollectionView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withPortal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CollectionLayoutFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collectionHandle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withPortal' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PortalStatic' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorialCellPlacement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowsToSkipMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinToBottom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorialCell' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionByLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPadding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionPlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaAlignment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFilterTile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterSpec' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VisualFiltersSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCoreStylesVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardHeader' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCardDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersTilesFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionGridSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemColorScope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupedProductTiles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtersEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crawlable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorializedCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialCellPlacements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditorialCellPlacementFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editorialRows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'VideoSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VideoSectionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visualFiltersSectionV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VisualFiltersSectionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollectionViewHeroSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GenderNavSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderNavSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alternateGenderHandle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedGender' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroFullBleedSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoModalBannerSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTabSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTabSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabSections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTabTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layoutVariantMobile' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'layoutVariantDesktop' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantMobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tileVariantDesktop' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionLayoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seoContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionGridSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionGridSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionViewHeroSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionViewHeroSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GenderNavSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GenderNavSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'HeroFullBleedSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HeroFullBleedSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VideoModalBannerSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoModalBannerSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VideoSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PageTabSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageTabSectionFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortalStatic' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortalCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enableSSO' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupTag' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCollectionViewQuery, GetCollectionViewQueryVariables>
export const FetchFavoritesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchFavorites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selector' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroupColorSelector' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favorites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CustomerFavoriteProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'productHandle' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preferences' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'images' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '2' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'nodes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'source' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'availability' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hexCodes' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tiers' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableSizes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableForSale' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'defaultVariant' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'priceDetails' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'price' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'amount' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'base' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'amount' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'currency',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'currency' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'discountPrice' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'amount' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'discountPct' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'rawColor' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'shopifyId' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'swPromoEligible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productGroup' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'selector' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'selector' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'FavoriteTileProductGroup' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'colors' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'availability' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hexCodes' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'swatchUrl' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: { kind: 'Name', value: 'productsForAvailability' },
                                          name: { kind: 'Name', value: 'products' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'availableSizes' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'colorInfo' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'rawName' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rawFit' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'swPromoEligible' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteTileProductGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PriceDetail' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchFavoritesQuery, FetchFavoritesQueryVariables>
export const GetFilteredReviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFilteredReviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageNum' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'numPerPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filteredReviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNum' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageNum' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numPerPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'numPerPage' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numPages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageNum' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numPerPage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFilteredReviewsQuery, GetFilteredReviewsQueryVariables>
export const FetchGiftCollectionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchGiftCollectionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollectionItemFilterInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'collection' },
            name: { kind: 'Name', value: 'optimizedCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUnavailable' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftCollectionItemsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftCollectionItemsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'grouping' },
                value: { kind: 'EnumValue', value: 'PRODUCT_GROUP' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionItemProduct' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'numItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGiftCollectionItemsQuery, FetchGiftCollectionItemsQueryVariables>
export const FetchGiftPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchGiftPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyLogo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codePrefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verificationCopy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'styleImageMens' } },
                { kind: 'Field', name: { kind: 'Name', value: 'styleImageWomens' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftPackageOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'promoImageMens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'promoImageWomens' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftItemCollections' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collectionHandleMens' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collectionHandleWomens' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'embroiderySku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoFileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoPlacement' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoImage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'callToAction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addToCartCTA' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'changeSelectionCTA' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGiftPackageQuery, FetchGiftPackageQueryVariables>
export const FetchKitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchKit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'testimonialsTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'testimonialsSubtitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonials' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageBannerSectionFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageHeroSectionFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageTileSectionFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accordion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccordionViewFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'groupItemsByColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultColors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideProductDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageBannerSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageBannerSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotspot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleedDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styledContentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textContentMaxWidthDesktop' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StyledImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageHeroSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageHeroSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideDownArrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatformMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobileTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desktopTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullscreenHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTileSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jumboTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasHorizontalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTileGaps' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccordionViewFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccordionView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RichTextSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchKitQuery, FetchKitQueryVariables>
export const FetchProductKitPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchProductKitPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productKit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPreview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUnavailable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultVariant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchProductKitPricesQuery, FetchProductKitPricesQueryVariables>
export const FetchProductKitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchProductKit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productKit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPreview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUnavailable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultVariant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountPriceExplicitFmt' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeList' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collectionBadgeColor' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fitModelsDescriptions' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'iconList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawMaterials' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'whyWeLove' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seoDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProductGroupKitRequirements' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductGroupKitRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeByColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'embroiderable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'embroideryImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productsForAvailability' },
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careInstructions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fitModelsDescriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawMaterials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchProductKitQuery, FetchProductKitQueryVariables>
export const FetchKitShopTheLookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchKitShopTheLook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopTheLook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'colorInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ColorGroup' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultVariant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'discountPrice' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'price' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'productsForAvailability' },
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableSizes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'swPromoEligible' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'sizeChart' },
                              name: { kind: 'Name', value: 'sizeChartCG' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tables' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'measureImage' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'measureTips' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kind' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tip' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'sizeTips' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kind' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tip' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subtitle' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tabLabel' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tips' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'video' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priceDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discountPrice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchKitShopTheLookQuery, FetchKitShopTheLookQueryVariables>
export const FetchKitVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchKitVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handles' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handles' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upc' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchKitVariantsQuery, FetchKitVariantsQueryVariables>
export const FetchPageViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchPageView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideEmailCaptureForm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'themeOverride' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AccordionView' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'RawHtmlSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'RichTextSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'content' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CollectionViewHeroSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'collectionViewHeroBackgroundColor' },
                              name: { kind: 'Name', value: 'backgroundColor' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionSectionFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageBannerSectionFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageHeroSectionFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageInteractiveSlideSectionFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageEditorialCarouselFragment' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTabSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hideOnMobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hideOnDesktop' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tabs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tabSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'PageTabTileSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'layoutVariantMobile',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'layoutVariantDesktop',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tiles' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'ctaText' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'href' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'image' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tileVariantMobile' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tileVariantDesktop' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'RawHtmlSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTestimonialSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'testimonials' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PageTileSectionFragment' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PageTitleSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ProductRecommendationsSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hideColors' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidePrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nostoPlacementId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productCountLimit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'titleAlignmentDesktop' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'titleAlignmentMobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'useNostoPlacementTitle' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RichTextSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RichTextSectionFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'VideoSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VideoSectionFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CustomComponentSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altTextDesktop' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StyledTextFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraphTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledLinkFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'featuredInlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featuredInlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledLinkFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productCountLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleLockupImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtitleText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorsForFilters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageBannerSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageBannerSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotspot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleedDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styledContentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textContentMaxWidthDesktop' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StyledImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageHeroSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageHeroSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideDownArrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatformMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobileTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desktopTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullscreenHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageInteractiveSlideSectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageInteractiveSlideSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interactiveSlides' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overlayOpacity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentColorDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentColorMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleedDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageEditorialCarouselFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PageEditorialCarouselSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledImageFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bodies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StyledTextFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayOpacity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cta1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cta2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayVideoLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVimeoIdMobile' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileCarousel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numColsDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColsMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tileAspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textAlign' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jumboTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hoverImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hoverVideo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkHref' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTileSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jumboTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasHorizontalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTileGaps' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RichTextSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchPageViewQuery, FetchPageViewQueryVariables>
export const FetchProductGroupDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchProductGroupDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collectionBadgeColor' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'sizeChart' },
                        name: { kind: 'Name', value: 'sizeChartCG' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tables' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'measureTips' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sizeTips' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tips' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'video' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priceDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PriceDetail' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchProductGroupDetailsQuery, FetchProductGroupDetailsQueryVariables>
export const GetProductReviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductReviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyProductId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageNum' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'numPerPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'star' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDir' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNum' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageNum' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numPerPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'numPerPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'star' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'star' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDir' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDir' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalReviews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averageFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'starDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'oneStarRatings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'twoStarRatings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'threeStarRatings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fourStarRatings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fiveStarRatings' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pageNum' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numPages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numPerPage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductReviewsQuery, GetProductReviewsQueryVariables>
export const FetchRecoProductDetailsOptimizedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchRecoProductDetailsOptimized' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handles' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optimizedProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handles' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'colorInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultVariant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priceDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discountPrice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchRecoProductDetailsOptimizedQuery,
  FetchRecoProductDetailsOptimizedQueryVariables
>
export const SiteFixturesRedirectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SiteFixturesRedirects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'siteFixtures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchRedirects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'redirects' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteFixturesRedirectsQuery, SiteFixturesRedirectsQueryVariables>
export const FetchShopTheLookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchShopTheLook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopTheLook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorGroups' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'ColorGroup' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colors' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'availability' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hexCodes' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'swatchUrl' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'defaultVariant' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'priceDetails' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'discountPrice' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'amount' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'price' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'amount' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'productsForAvailability' },
                                    name: { kind: 'Name', value: 'products' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'availableSizes' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'colorInfo' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rawName' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'swPromoEligible' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'sizeChart' },
                                    name: { kind: 'Name', value: 'sizeChartCG' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'columnNames' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'columnValues' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageUri' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'navLabel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tables' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cells' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'measureImage' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'measureTips' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'kind' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'tip' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sizeTips' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'kind' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'tip' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtitle' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tabLabel' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tips' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'kind' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tip' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'video' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'discountPrice' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'price' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchShopTheLookQuery, FetchShopTheLookQueryVariables>
export const GetCollectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCollections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPreview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUnavailable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CollectionProductGroupFragment' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'productHandles' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionProductGroupFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selector' },
                value: { kind: 'EnumValue', value: 'ALL' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PriceDetail' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCollectionsQuery, GetCollectionsQueryVariables>
export const GetSiteFixturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSiteFixtures' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'siteFixtures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'disableOneTrust' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disableOptimizely' } },
                { kind: 'Field', name: { kind: 'Name', value: 'embroideryIcon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enablePDPAccentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'miniCartMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'embroideryWarningMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoColorSelectionTooltip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoDigitizationDisclaimer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoDigitizationWarning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoLearnMorelink' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'navigation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'desktopTabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navTab' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobileTabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navTab' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'helpLinkText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'helpLinkHref' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'siteWidePromo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enableSiteWidePromo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tooltips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enumValue' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'footer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'copyrightLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accessibilityButtonText' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'desktopSections' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'NavListSection' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'navListSection' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'NavTileSection' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'navTileSection' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'RawHtmlSection' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'rawHtmlSection' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'NavAccordion' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'navAccordionSection' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobileSections' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'NavListSection' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'navListSection' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'NavTileSection' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'navTileSection' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'RawHtmlSection' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'rawHtmlSection' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'NavAccordion' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'navAccordionSection' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'socialLinks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navListItem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'legalLinks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navListItem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promoBar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'upsellHandles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nostoUpsell' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nostoUpsellId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regionData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'regions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'flagSrc' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shippingText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxAndDutyText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'welcomeShippingText' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'welcomeTaxAndDutyText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'promoBarRegion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'backgroundColor' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pages' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localeData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locales' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBold' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHeading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'htmlClass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBtn' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navListGroupSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavListGroupSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'navSections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListSection' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navTileSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rawHtmlSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RawHtmlSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'html' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navAccordionSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavAccordion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'header' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListItem' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'body' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavListSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navListSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavTileSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'navTileSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'RawHtmlSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'rawHtmlSection' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavAccordion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'header' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListItem' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'NavListSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'navListSection' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'NavTileSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'navTileSection' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'RawHtmlSection' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'rawHtmlSection' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alternateModelVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetCriteria' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'navTab' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavTab' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'href' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavListSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navListSection' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavListGroupSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'navListGroupSection' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavTileSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'navTileSection' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RawHtmlSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'rawHtmlSection' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NavAccordion' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'navAccordionSection' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alternateModelVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validContentfulModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'NavTab' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavListSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavListGroupSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navListGroupSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavTileSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navTileSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'RawHtmlSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'rawHtmlSection' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'NavAccordion' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'navAccordionSection' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetCriteria' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetLogic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetCriteria' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inRegion' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSiteFixturesQuery, GetSiteFixturesQueryVariables>
export const GetCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextPageDataOnly' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionColorScope' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionColorScope' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterConfig' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterConfigInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterKeys' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CollectionItemFilterInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'grouping' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItemGrouping' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'collection' },
            name: { kind: 'Name', value: 'optimizedCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'colorScope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionColorScope' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterConfig' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filterConfig' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colors' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'nextPageDataOnly' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterKeys' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterKeys' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'nextPageDataOnly' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionProductFilter' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedFilters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grouping' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'grouping' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollectionItemsConnection' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CollectionItemProduct' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionProductFilter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductFilter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crawlable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterValues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemsConnection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductConnection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'numItems' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCollectionQuery, GetCollectionQueryVariables>
export const GetCollectionItemsWithImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCollectionItemsWithImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'collection' },
            name: { kind: 'Name', value: 'optimizedCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUnavailable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'last' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'before' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grouping' },
                      value: { kind: 'EnumValue', value: 'PRODUCT' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numPages' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'detailImages' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fitModel' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCollectionItemsWithImagesQuery,
  GetCollectionItemsWithImagesQueryVariables
>
export const GetCollectionSectionProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCollectionSectionProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'collection' },
            name: { kind: 'Name', value: 'optimizedCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'numColors' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceRange' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultVariant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'price' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'discountPrice' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amount' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCollectionSectionProductsQuery,
  GetCollectionSectionProductsQueryVariables
>
export const GetFeaturedProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeaturedProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedColor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedColor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedColor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedFit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featuredProduct' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'color' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FeaturedProduct' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeaturedProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeaturedProductQuery, GetFeaturedProductQueryVariables>
export const GetPdpFeaturedProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPdpFeaturedProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logoScope' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LogoScopeEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collectionHandle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PdpFeaturedProductFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductEmbroidery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Embroidery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iconPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsMaxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textLines' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'textMaxChars' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'designFile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryIconGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PdpFeaturedProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'color' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'embroidery' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'logoScope' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'logoScope' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductEmbroidery' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'detailImages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fitModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pantsSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'topSize' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fitModelCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fitModelsDescriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'badgeList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iconList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawMaterials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'whyWeLove' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careInstructions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upc' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PriceDetail' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPdpFeaturedProductQuery, GetPdpFeaturedProductQueryVariables>
export const GetProductImpressionVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductImpressionVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionViewHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sectionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionViewHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sectionId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CollectionGridSection' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'nodes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'defaultVariant' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'externalParentId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'category' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'discountPrice',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'handle' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'price' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'shopifyId' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'sku' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductImpressionVariantsQuery,
  GetProductImpressionVariantsQueryVariables
>
export const GetProductGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPreview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPreview' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUnavailable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUnavailable' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'sizeChart' },
                        name: { kind: 'Name', value: 'sizeChartCG' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tables' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'measureTips' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sizeTips' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tips' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'video' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductGroupsQuery, GetProductGroupsQueryVariables>
export const GetReplacementVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReplacementVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'replacementInput' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReplacementInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacementVariants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'replacementInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'replacementInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'replacementSku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requestedKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replacementShopifyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReplacementVariantsQuery, GetReplacementVariantsQueryVariables>
export const GetTileProductGroupForCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTileProductGroupForCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productGroupHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionColorScope' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionColorScope' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withEmbroideryOptions' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withEmbroideryScope' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LogoScopeEnum' } },
          defaultValue: { kind: 'EnumValue', value: 'DTC' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'collection' },
            name: { kind: 'Name', value: 'optimizedCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'colorScope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionColorScope' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productGroup' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'handle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productGroupHandle' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TileProductGroup' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'colorGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorGroup' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'colors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Color' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredProduct' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'color' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fit' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FeaturedProduct' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'embroidery' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'logoScope' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'withEmbroideryScope' },
                                  },
                                },
                              ],
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: { kind: 'Name', value: 'include' },
                                  arguments: [
                                    {
                                      kind: 'Argument',
                                      name: { kind: 'Name', value: 'if' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'withEmbroideryOptions' },
                                      },
                                    },
                                  ],
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ProductEmbroidery' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TileProductGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productsForAvailability' },
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductColor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeaturedProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductEmbroidery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Embroidery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iconPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialsMaxChars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textLines' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoPlacements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'flatlayKind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offsetKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'textMaxChars' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'designFile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryIconGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTileProductGroupForCollectionQuery,
  GetTileProductGroupForCollectionQueryVariables
>
export const GetTileProductGroupForSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTileProductGroupForSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TileProductGroup' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorGroup' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colors' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selector' },
                      value: { kind: 'EnumValue', value: 'ALL' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Color' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featuredProduct' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'color' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'color' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FeaturedProduct' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TileProductGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'productsForAvailability' },
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sizeChart' },
            name: { kind: 'Name', value: 'sizeChartCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'measureTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeTips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'video' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'style' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductColor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeaturedProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceDetail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTileProductGroupForSearchQuery,
  GetTileProductGroupForSearchQueryVariables
>
export const MultipassAuthUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MultipassAuthURL' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerShopifyUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUri' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultipassAuthUrlQuery, MultipassAuthUrlQueryVariables>
export const ShopOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shopOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reverse' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopOrders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayFinancialStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopOrdersQuery, ShopOrdersQueryVariables>
export const ProductPageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductPageQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionScope' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductGroupColorSelector' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portalCollectionHandle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withPortalColors' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collectionHandle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionHandle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'genderCategory' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'portalColorGroups' },
                  name: { kind: 'Name', value: 'colorGroups' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'collectionHandle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'portalCollectionHandle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'onlyScoped' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'withPortalColors' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorGroup' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorGroups' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selector' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionScope' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorGroup' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'embroiderable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'embroideryImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'embroideryOffsets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'productsForAvailability' },
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductAvailabilityFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productGroupView' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'handle' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sections' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PageBannerSectionFragment' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PageHeroSectionFragment' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PageTileSectionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rtfFeatures' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizes' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'sizeChart' },
                  name: { kind: 'Name', value: 'sizeChartCG' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'columnNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'columnValues' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'navLabel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tables' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cells' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'measureImage' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'measureTips' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sizeTips' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tabLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'video' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductAvailabilityFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableSizes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rawName' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawFit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageBannerSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageBannerSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotspot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleedDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styledContentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StyledText' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColorDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textContentMaxWidthDesktop' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textAlignDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'StyledImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWidthDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatioDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bottomMargin' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageHeroSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageHeroSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonVariantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyebrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideDownArrow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTitleSerif' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkHref2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoIdMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modalVideoPlatformMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openVideoModalText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobileTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desktopTitleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textContentMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacementMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMaxWidthMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleImageMobileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullscreenHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTileSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTileSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numColsMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tileAspectRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textAlign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLinkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hoverVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jumboTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkHref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleImage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleTextSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentDesktop' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleAlignmentMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasHorizontalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVerticalMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTileGaps' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductPageQueryQuery, ProductPageQueryQueryVariables>
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchFilterInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedFilters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'query' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CollectionItemProduct' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'analyticsId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'numItems' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'colors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValues' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionBadgeText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hexCodes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swatchUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountPriceExplicitFmt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceExplicitFmt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isKit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconBadgePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numColors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawFits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rawAvailableFits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'waitlistable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swPromoEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSale' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>
export const SearchAutocompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'query' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'images' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '1' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'nodes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'source' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'defaultVariant' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'shopifyId' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'priceDetails' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'discountPrice' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'amount' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'price' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'amount' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'currency' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAutocompleteQuery, SearchAutocompleteQueryVariables>
export const ShopOrderDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shopOrderDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopOrderDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopOrder' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopOrder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayFulfillmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayFinancialStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fulfillments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopOrderDetailsQuery, ShopOrderDetailsQueryVariables>
export const WhoamiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'whoami' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoami' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WhoamiQuery, WhoamiQueryVariables>
export const GetGuestOrderDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGuestOrderDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'guestOrderDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netPayment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRefunded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalShipping' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTax' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorizationCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedGateway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gateway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentIcon' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'processedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayFinancialStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayFulfillmentStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cancellable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentOrderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lineItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customAttributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refunds' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refundLineItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lineItem' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGuestOrderDetailsQuery, GetGuestOrderDetailsQueryVariables>
export const MyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'my' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'getUploadAvatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerFragment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reverse' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayFinancialStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayFulfillmentStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fulfillments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trackingInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lineItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fulfillmentStatus' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fulfillments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailOptIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stipend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stylePreference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountHeroSectionCG' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyQuery, MyQueryVariables>
export const MyLimitedOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myLimitedOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'getUploadAvatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerFragment' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'orders' },
                  name: { kind: 'Name', value: 'ordersStorefront' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reverse' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fulfillments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trackingInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lineItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'altText' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailOptIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stipend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stylePreference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountHeroSectionCG' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyLimitedOrderQuery, MyLimitedOrderQueryVariables>
export const MyProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'getUploadAvatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailOptIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stipend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stylePreference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PortalProfile' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortalProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortalCG' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountHeroSectionCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionHandle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionHeroSectionCG' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVideoMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdDesktop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inlineVimeoIdMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoPlatform' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promoBarPages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'embroidery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yesText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibleProductCategories' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryTextEmbroideryCategories' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryLogoEmbroideryCategories' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'embroideryDiscountPercentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultTextEmbroideryValues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'font' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textPlacement' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hiddenTextEmbroideryFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pdpCtaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quickBuyText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyProfileQuery, MyProfileQueryVariables>
export const MyAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myAddresses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'getUploadAvatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopifyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyAddressesQuery, MyAddressesQueryVariables>
export const MyOrderDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myOrderDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'getUploadAvatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ordersCount' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reverse' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayFinancialStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayFulfillmentStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fulfillments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trackingInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fulfillments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyOrderDetailsQuery, MyOrderDetailsQueryVariables>
export const MyOrdersPageInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myOrdersPageInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reverse' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reverse' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyOrdersPageInfoQuery, MyOrdersPageInfoQueryVariables>
export const MyEmbroideryLogosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myEmbroideryLogos' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'embroideryLogos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preview' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'digitizationSetting' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyEmbroideryLogosQuery, MyEmbroideryLogosQueryVariables>
export const GetOrderDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'netPayment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRefunded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalShipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTax' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authorizationCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'errorCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formattedGateway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gateway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'processedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'icon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shippingAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'province' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayFinancialStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayFulfillmentStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trackingInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentOrderStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lineItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fulfillmentStatus' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customAttributes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'variant' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'colorInfo' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refunds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'refundLineItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lineItem' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>
export const GetOrderLineItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderLineItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'my' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lineItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fulfillmentStatus' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderLineItemsQuery, GetOrderLineItemsQueryVariables>
export const FetchServerFixturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchServerFixtures' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serverFixtures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'redirectSets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'redirects' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchServerFixturesQuery, FetchServerFixturesQueryVariables>
