import styled from 'styled-components'

import { navDropdownShadow } from '@syconium/little-miss-figgy/dist/utility-classes'
import { Body as RawHtmlSection } from '@syconium/magnolia/src/brunswick/components/page-sections/RawHtmlSection/styles'

import { Body as NavAccordion } from '../NavAccordion/styles'
import { Body as NavListSection } from '../NavListSection/styles'
import { Wrapper as NavRegionLocaleSection } from '../NavRegionLocaleSection'
import { Body as NavTile, navTileWidth } from '../NavTile/styles'
import { ScrollWrap as NavTileSection } from '../NavTileSection/styles'

export const sidePad = '33px'
const topPad = '40px'

export const Body = styled.div`
  background: white;
  ${navDropdownShadow}
  display: flex;
  flex-wrap: wrap;
  clip-path: inset(0px -35px -35px -35px);

  > ${NavAccordion},
    ${NavListSection},
    ${NavTileSection},
    ${RawHtmlSection},
    ${NavRegionLocaleSection} {
    max-width: calc(${navTileWidth} + (2 * ${sidePad}));
  }

  ${NavListSection} {
    padding: ${topPad} ${sidePad};
  }

  > * > ${NavTileSection} {
    padding: ${topPad} ${sidePad};

    ${NavTile} {
      display: block;
    }
  }

  > ${NavAccordion} {
    padding: ${topPad} ${sidePad};
  }
`
