import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

/**
 * IMPORTANT: DO NOT add anything into this query that is on the
 * productGroup level. ONLY use the PdpFeaturedProductFragment.
 * Altering fields on the productGroup as of now can alter
 * the scope of the `featuredProduct` and `products` fields
 * that also exist on `productGroup`. It can cause us to only
 * get data for products that are within the provided `collectionHandle`.
 *
 * The default selection scope for `products` and `featuredProduct` is `SCOPE_PLUS_ALL`,
 * which will include basically anything in the collection plus anything else.
 *
 * Until we can specify that, we have to be careful not to break that wonderful
 * default with other fields.
 */
export const GET_PDP_FEATURED_PRODUCT = gql(`
  query GetPdpFeaturedProduct(
    $productGroupHandle: String!
    $collectionHandle: String
    $color: String
    $fit: String
    $logoScope: LogoScopeEnum
  ) {
    productGroup(handle: $productGroupHandle, collectionHandle: $collectionHandle) {
      id
      ...PdpFeaturedProductFragment
    }
  }
`)
