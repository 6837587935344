export type SessionAttributes = {
  stylePreference: 'women' | 'men' | undefined
  customerType: 'firstTime' | 'repeat'
  id: string
}

export const sessionStorageKeys = {
  landingPage: '@figs:landing-page',
  deprecatedFeatureFlags: '@figs:features',
  usedLocales: '@figs:used-locales',
  discount: '@figs:discount',
} as const

// Once we migrate off of the pages directory, we won't need web client generated ids.
// All ids for app directory are assigned in middleware on the server.
let sessionCounter = 0
export const createNewSessionId = () => {
  sessionCounter = sessionCounter + 1
  if (typeof window === 'undefined') {
    return `web-server-${sessionCounter}-${crypto.randomUUID()}`
  } else {
    return `web-client-${crypto.randomUUID()}`
  }
}

export const getSessionAttributesFromCookies = ({
  stylePreferenceCookie,
  initialSessionCookie,
  priorPageViewCookie,
  sessionIdCookie,
}: {
  stylePreferenceCookie: string | undefined
  initialSessionCookie: string | undefined
  priorPageViewCookie: string | undefined
  sessionIdCookie: string
}): SessionAttributes => {
  const attributes: SessionAttributes = {
    stylePreference: stylePreferenceCookie as 'men' | 'women' | undefined,
    customerType:
      initialSessionCookie === 'true' || priorPageViewCookie === undefined ? 'firstTime' : 'repeat',
    id: sessionIdCookie,
  }
  return attributes
}
