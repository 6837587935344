'use client'

import { createContainer } from 'unstated-next'

import { PreviewDirectives } from '../_config/PreviewDirectives.config'

const usePreviewDirectivesImpl = (previewDirectives?: PreviewDirectives): PreviewDirectives => {
  return previewDirectives!
}

const PreviewDirectivesContainer = createContainer(usePreviewDirectivesImpl)
export const PreviewDirectivesProvider = ({
  initialState,
  children,
}: {
  initialState: PreviewDirectives
  children: React.ReactNode
}) => {
  return (
    <PreviewDirectivesContainer.Provider initialState={initialState}>
      {children}
    </PreviewDirectivesContainer.Provider>
  )
}
export const usePreviewDirectives = PreviewDirectivesContainer.useContainer
