import styled, { css } from 'styled-components'

import { DeprecatedText } from '@syconium/little-miss-figgy'

export const Container = styled.div<{ isPlaceholder?: boolean; isFinalSale?: boolean }>`
  align-items: center;
  border-radius: 3px;
  border: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  box-sizing: border-box;
  display: flex;
  height: 24px;
  justify-content: center;
  opacity: ${o => o.isPlaceholder && '0'};
  padding: 4px 8px;

  ${({ isFinalSale }) =>
    isFinalSale &&
    css`
      border: none;
      color: ${o => o.theme.color.deprecated.red.dark};
    `}
`

export const BadgeText = styled(DeprecatedText)`
  letter-spacing: 0.15em;
  line-height: 150%;
`
