import styled from 'styled-components'

import { TypeStyle } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  line-height: 18px;
  padding: 20px 0 12px 0;

  &:first-of-type {
    border-top-width: 0;
  }
`

export const Details = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)``

export const PriceSection = styled.div`
  align-items: flex-start;
  display: flex;
`

export const Items = styled.div`
  border-left: 1px solid ${o => o.theme.color.deprecated.stoneLight};
`

export const QuantityWrapper = styled.div`
  display: inline-block;
  padding: 0 0 10px 0;
`

export const SiteWidePromoWrap = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.deprecated.red.error};
  padding-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
`
