import { Size } from '@syconium/little-miss-figgy'
import { useInputObservingState } from '@syconium/magnolia/src/lib/hooks/useInputObservingState'

import { ProductColor } from '../../../types/graphql'

export function useVariantAttributeSelections({
  initialSelectedColor,
  initialSelectedFitKey,
  initialSelectedSize,
}: {
  initialSelectedColor: ProductColor | undefined
  initialSelectedFitKey?: string
  initialSelectedSize?: Size
}) {
  const { state: selectedColor, setState: setSelectedColor } = useInputObservingState<
    ProductColor | undefined
  >(initialSelectedColor)

  const { state: selectedFitKey, setState: setSelectedFitKey } = useInputObservingState<
    string | undefined
  >(initialSelectedFitKey)

  const { state: selectedSize, setState: setSelectedSize } = useInputObservingState<
    Size | undefined
  >(initialSelectedSize)

  return {
    selectedColor,
    selectedFitKey,
    selectedSize,
    setSelectedColor,
    setSelectedFitKey,
    setSelectedSize,
  }
}
