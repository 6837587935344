import { useCallback, useRef } from 'react'
import { createContainer } from 'unstated-next'

import { signalClient } from '../../lib/signals'
import {
  CartModifiedSignal,
  PageViewSignal,
  ProductGroupImpressionSignal,
  ProductImpressionSignal,
  ProductInteractionSignal,
} from '../../types/signals'

const figsCartHashId = '@figs:SignalCartHash'

function useSignalTrackingImpl() {
  const cartHashRef = useRef(() => {
    return localStorage.getItem(figsCartHashId)
  })

  const trackPageView = useCallback(({ handle, category }: PageViewSignal) => {
    const eventAttributes = {
      pageHandle: handle,
      pageCategory: category,

      //Comment out for now as document?.referrer wont work with React Router
      //pageReferrer: document?.referrer || '',
    }

    if (handle) {
      signalClient.track('page_view', eventAttributes)
    }
  }, [])

  const trackProductGroupImpression = useCallback(
    (productGroupSignal: ProductGroupImpressionSignal) => {
      signalClient.track('product_group_impression', productGroupSignal)
    },
    []
  )

  const trackProductImpression = useCallback((productSignal: ProductImpressionSignal) => {
    signalClient.track('product_impression', productSignal)
  }, [])

  const trackProductInteraction = useCallback((productSignal: ProductInteractionSignal) => {
    signalClient.track('product_interaction', productSignal)
  }, [])

  const trackCartModified = useCallback(
    (cartSignal: CartModifiedSignal) => {
      const cartHash =
        cartSignal.id +
        '||' +
        cartSignal.items.map(item => item.variantSku + '|' + item.quantity).join('||')

      if (cartHash !== cartHashRef.current()) {
        localStorage.setItem(figsCartHashId, cartHash)
        signalClient.track('cart_modified', cartSignal)
      }
    },
    [cartHashRef]
  )

  return {
    trackCartModified,
    trackPageView,
    trackProductImpression,
    trackProductInteraction,
    trackProductGroupImpression,
  }
}

const SignalTrackingContainer = createContainer(useSignalTrackingImpl)
export const SignalTrackingProvider = SignalTrackingContainer.Provider
export const useSignalTracking = SignalTrackingContainer.useContainer
