import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

// TODO: for some unknown reason adding a fragment to
// this query breaks 2/3 of the src/containers/cart/__tests__/hook.test.tsx
// tests. It probably has something to do with the MockedProvider/MockedResponse
// from @apollo/react-testing lib, but I dont have enough time to mess
// with it (Matt 2.6.20)
export const FETCH_CART_VARIANTS = gql(`
  query getVariantsBySkus($skus: [String]) {
    variants(skus: $skus) {
      nodes {
        category
        color
        discountPrice
        externalParentId
        fit
        image {
          source
        }
        priceDetails {
          discountPrice {
            amount
          }
          price {
            currency
            amount
            base {
              amount
              currency
            }
          }
        }
        price
        product {
          availableForSale
          finalSale
          handle
        }
        productGroup {
          genderCategory
          style
          handle
          title
          description
          embroiderable
          embroideryImages {
            color
            embroideryOffsets
            fit
            index
            source
            kind
            type
          }
          colorGroups {
            ...ColorGroup
          }
        }
        shopifyId
        sku
        size
        style
      }
    }
  }
`)
