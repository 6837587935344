import * as React from 'react'
import { useTheme } from 'styled-components'

import { BaseIcon, IProps } from '../BaseIcon'

export const Minus: React.FC<IProps> = ({ height = '12px', stroke, width = '12px' }) => {
  const { color } = useTheme()

  return (
    <BaseIcon height={height} width={width} viewBox='0 0 12 2'>
      <path
        d='M10.335 1h-8.92'
        stroke={stroke || color.deprecated.black.primary}
        strokeWidth='1.5'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='square'
      />
    </BaseIcon>
  )
}
