import { FC } from 'react'

import { CenterText } from '@syconium/magnolia/src/brunswick/components/atoms/CenterText'
import type { IconBadge as Props } from '@syconium/magnolia/src/types/graphql/product'

import { Body, Caption, Icon } from './styles'

export type IconSize = 'small' | 'large' | 'extraLarge'
export const iconSizes: IconSize[] = ['small', 'large', 'extraLarge']

export const IconBadge: FC<Props & { className?: string; size: IconSize }> = ({
  caption,
  captionColor,
  className,
  icon,
  iconText,
  size,
}) => {
  if (!caption && !icon && !iconText) return null

  const iconSrc = icon?.url

  return (
    <Body className={className} size={size}>
      {(iconSrc || iconText) && (
        <Icon iconSrc={iconSrc} size={size}>
          <CenterText letterSpacing='0.05em'>{iconText}</CenterText>
        </Icon>
      )}
      {caption && (
        <Caption size={size} captionColor={captionColor}>
          <CenterText letterSpacing='0.05em'>{caption}</CenterText>
        </Caption>
      )}
    </Body>
  )
}
