import styled, { CSSProperties } from 'styled-components'

import {
  Button,
  Media,
  fromMd,
  pageLayoutMaxWidth,
  timingSlower,
  zIndexForeground,
} from '@syconium/little-miss-figgy'
import { GhostProxy } from '@syconium/magnolia/src/brunswick/components/atoms/GhostProxy'
import { CartButton } from '@syconium/magnolia/src/brunswick/components/Cart'
import { fadableSegment } from '@syconium/magnolia/src/brunswick/components/Header/styles'

import { headerHeight } from '../../../constants'
import { TabLink } from '../NavTabBar/styles'

export const zIndexNavHeader = zIndexForeground * 2 + 1

const logoPad = 24
const sideSectionWidth = 350
const searchBarWidth = 150
const desktopTabWidth = 60

export const HamburgerButton = styled(Button)`
  border: none;
  height: 100%;
  padding: 18px 16px;
  color: currentColor;
  ${fromMd} {
    display: none;
  }
`

export const LogoBody = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  a {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 ${logoPad}px;
  }
`
export const Intersection = styled.div`
  width: 100%;
  height: 0px;
  visibility: hidden;
  position: absolute;
  top: 0;
`
export const Wrapper = styled.header<{
  pushedUp: boolean
  isCollectionsPage: boolean
  promoBarIsOpen: boolean
}>`
  background-color: ${o => o.theme.color.fill.background};
  &:hover {
    background-color: ${o => o.theme.color.fill.background};
  }

  position: sticky;
  top: ${o => (o.pushedUp ? `-${headerHeight}` : 0)};
  transition:
    top ${timingSlower},
    background-color ${timingSlower};
  width: 100%;
  z-index: ${zIndexNavHeader};
  &:not(:hover) {
    color: inherit;
  }

  ${fromMd} {
    top: ${o => (o.pushedUp && o.isCollectionsPage ? `-${headerHeight}` : 0)};
  }
`

// N.B.: This must be the position parent of the NavTray.
export const Body = styled.div`
  display: flex;
  height: ${headerHeight};
  justify-content: space-between;
  margin: auto;
  max-width: ${pageLayoutMaxWidth};
  width: 100%;
  position: relative;
`

const BodySection = styled.div<{ isFadedOut?: boolean }>`
  ${fadableSegment}
  align-items: center;
  display: flex;
  width: ${sideSectionWidth}px;
`

export const Left = styled(BodySection)``

export const Center = styled(BodySection)<{
  isFadedOut?: boolean
  desktopTabCount: number
}>`
  justify-content: center;
  width: calc(100vw - ${o => (o.desktopTabCount * desktopTabWidth + searchBarWidth) * 2}px);
  /* Ensure that center container does not get covered by right/left siblings */
  z-index: 1;
  ${TabLink} {
    padding: 0 8px;
  }
  /* After adding z-index the search on desktop became not fully clickable */
  ${fromMd} {
    z-index: 0;
  }
`

export const Right = styled(BodySection)`
  justify-content: flex-end;
`

// Layout to match NavModal CloseButton for illusory substitution

export const StyledCartButton = styled(CartButton).attrs({ tabIndex: -1 })<{
  disabled?: boolean
}>`
  &:hover {
    color: ${o => o.theme.color.deprecated.obsidian}; /* temp override [nav-v2-cleanup] */
  }

  &:disabled {
    color: ${o => o.theme.color.deprecated.obsidianLighter};
    cursor: default;
  }
`

export const NavGhostProxy = styled(GhostProxy)`
  width: 100px;
`

export const LogoMedia = styled(Media)`
  height: 100%;
`

export const MobileSearchWrap = styled.header<{
  pushedUp?: boolean
  searchResults?: boolean
}>`
  background: white;
  position: sticky;
  top: ${o => (o.pushedUp ? `-${headerHeight}` : headerHeight)};
  transition: top ${timingSlower};
  width: 100%;
  z-index: ${o => (o.searchResults ? o.theme.zIndex.modals + 10 : o.theme.zIndex.modals)};
`

export const Overlay = styled.div<{
  promoBarHeightDesktop: CSSProperties['height']
  promoBarHeightMobile: CSSProperties['height']
}>`
  background: black;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: calc(${headerHeight} + ${o => o.promoBarHeightMobile});
  transition: opacity ${timingSlower} ease;
  z-index: ${o => o.theme.zIndex.modals};

  ${fromMd} {
    top: calc(${headerHeight} + ${o => o.promoBarHeightDesktop});
  }

  #brunswick-root & {
    top: ${headerHeight};
  }
`

export const ScreenReaderOnlyLink = styled.a`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`
