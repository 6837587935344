import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_FEATURED_PRODUCT } from '../../../lib/graphql/queries/GET_FEATURED_PRODUCT'
import { Product } from '../../../types/graphql'

type UseFetchFeaturedProductDataOpts = {
  color: string | undefined
  fit: string
  productGroupHandle: string
  skip: boolean
}

export const useFetchFeaturedProductData = ({
  color,
  fit,
  productGroupHandle,
  skip,
}: UseFetchFeaturedProductDataOpts) => {
  const { data, loading, previousData } = useQuery<{ productGroup: { featuredProduct: Product } }>(
    GET_FEATURED_PRODUCT,
    {
      variables: { color, fit, productGroupHandle },
      skip,
    }
  )

  const featuredProduct = useMemo(() => {
    return loading
      ? previousData?.productGroup?.featuredProduct
      : data?.productGroup?.featuredProduct
  }, [data, loading, previousData])

  return {
    featuredProduct,
  }
}
