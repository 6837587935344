/**
 * Note:
 *
 * When modifying please see the following article for explanation on the format:
 * https://css-tricks.com/snippets/css/using-font-face/
 * https://www.digitalocean.com/community/tutorials/css-font-face
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display
 * */

/**
 * Normal style
 *        X <= 500 | renders as regular
 * 501 <= X <= 600 | renders as semi-bold
 * 601 <= X <= 800 | renders as bold
 * 801 <= X        | renders as black
 *
 * Italic
 * All render as semi-bold
 */

// Update to current time stamp to force users to get new font files. Used when font files are replaced.
const CACHE_BREAK_V = '202405241048' as const

export const createFigsFontFaces = (host: string = '') => `
  @font-face {
    font-family: Figs;
    src: url('${host}/static/fonts/FIGS-regular.woff2?v=${CACHE_BREAK_V}') format('woff2'), url('${host}/static/fonts/FIGS-regular.woff?v=${CACHE_BREAK_V}') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Figs;
    src: url('${host}/static/fonts/FIGS-semi-bold.woff2?v=${CACHE_BREAK_V}') format('woff2'), url('${host}/static/fonts/FIGS-semi-bold.woff?v=${CACHE_BREAK_V}') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Figs;
    src: url('${host}/static/fonts/FIGS-semi-bold-italic.woff2?v=${CACHE_BREAK_V}') format('woff2'), url('${host}/static/fonts/FIGS-semi-bold-italic.woff?v=${CACHE_BREAK_V}') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: Figs;
    src: url('${host}/static/fonts/FIGS-bold.woff2?v=${CACHE_BREAK_V}') format('woff2'), url('${host}/static/fonts/FIGS-bold.woff?v=${CACHE_BREAK_V}') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Figs;
    src: url('${host}/static/fonts/FIGS-black.woff2?v=${CACHE_BREAK_V}') format('woff2'), url('${host}/static/fonts/FIGS-black.woff?v=${CACHE_BREAK_V}') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Figs;
    src: url('${host}/static/fonts/FIGS-black-italic.woff2?v=${CACHE_BREAK_V}') format('woff2'), url('${host}/static/fonts/FIGS-black-italic.woff?v=${CACHE_BREAK_V}') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
`
