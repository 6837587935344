import { FC } from 'react'

import { RenderOnceInViewport } from '../RenderOnceInViewport'

import { VisibilityTarget } from './styles'

export const MediaLazyLoad: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <RenderOnceInViewport offset={{ bottom: 300, top: 300 }} {...{ VisibilityTarget }}>
      {children}
    </RenderOnceInViewport>
  )
}
