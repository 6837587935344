import { FlagIconImage, ModalContent } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import * as RegionLocale from '@syconium/magnolia/src/components/region-locale'
import { SupportedLocaleIdentifier } from '@syconium/weeping-figs'

import { useRegionLocalePicker } from '../../../lib/hooks/useRegionLocalePicker'

export interface RegionAndLocalePickerContentProps {
  onReqeustClose: () => void
}

export function RegionAndLocalePickerContent(props: RegionAndLocalePickerContentProps) {
  const { regionAndLocalePicker } = useFixturesContext()
  const { region } = LocalizationContainer.useContainer()
  const {
    getLanguageName,
    handleCountryChange,
    enabledLocales,
    enabledRegionNames,
    setSelectedLocale,
    handleSubmit,
    selectedLocale,
    selectedContentfulRegionData,
  } = useRegionLocalePicker()

  return (
    <RegionLocale.Container>
      <RegionLocale.CloseButton onClick={props.onReqeustClose} />
      <ModalContent.Header>
        <FlagIconImage src={selectedContentfulRegionData?.flagSrc ?? region.flagSrc} />
        <ModalContent.Title style={{ textTransform: 'uppercase' }}>
          {regionAndLocalePicker.modal.title}
          <RegionLocale.TitleSpan>
            {regionAndLocalePicker.modal.titleLanguage}
          </RegionLocale.TitleSpan>
        </ModalContent.Title>
      </ModalContent.Header>
      <RegionLocale.DropdownWithLabel
        label={regionAndLocalePicker.modal.labelCountry}
        isScrollable={true}
        onChange={handleCountryChange}
        options={enabledRegionNames}
        placeholderText={regionAndLocalePicker.modal.placeholderCountry}
        selection={selectedContentfulRegionData?.name ?? region.name}
      />
      <RegionLocale.DropdownWithLabel
        label={regionAndLocalePicker.modal.labelLanguage}
        isScrollable={true}
        onChange={(language: SupportedLocaleIdentifier) => setSelectedLocale(language)}
        options={enabledLocales}
        placeholderText={regionAndLocalePicker.modal.placeholderLanguage}
        renderOptionText={getLanguageName}
        renderSelectionText={getLanguageName}
        selection={selectedLocale}
      />
      <RegionLocale.CtaWrapper>
        <RegionLocale.CtaButton onClick={() => handleSubmit()}>
          {regionAndLocalePicker.modal.ctaText}
        </RegionLocale.CtaButton>
      </RegionLocale.CtaWrapper>
    </RegionLocale.Container>
  )
}
