import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Delivery: React.FC<IProps> = ({
  height = '24px',
  fill = '#282828',
  width = '24px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 11.4583V16.5H19C19 17.7426 17.9926 18.75 16.75 18.75C15.5074 18.75 14.5 17.7426 14.5 16.5H12H9C9 17.7426 7.99264 18.75 6.75 18.75C5.50736 18.75 4.5 17.7426 4.5 16.5H3V6.5C3 5.94772 3.44771 5.5 4 5.5H15.1386C15.568 5.5 15.9494 5.77406 16.0864 6.18097L17.4 10.0833L21 11.4583ZM5.5 16.5C5.5 17.1904 6.05964 17.75 6.75 17.75C7.44036 17.75 8 17.1904 8 16.5C8 15.8096 7.44036 15.25 6.75 15.25C6.05964 15.25 5.5 15.8096 5.5 16.5ZM12.75 6.875H14.9274L16.125 11H12.75V6.875ZM18.0003 16.5001C18.0003 17.1906 17.4405 17.7504 16.75 17.7504C16.0595 17.7504 15.4997 17.1906 15.4997 16.5001C15.4997 15.8095 16.0595 15.2498 16.75 15.2498C17.4405 15.2498 18.0003 15.8095 18.0003 16.5001Z'
      />
    </BaseIcon>
  )
}
