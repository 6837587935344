import { ImageLoader } from './imageLoader'

interface GetImageLoaderProps {
  contentfulImageLoader: ImageLoader
  shopifyImageLoader: ImageLoader
}

export const createImageLoader = ({
  contentfulImageLoader,
  shopifyImageLoader,
}: GetImageLoaderProps): ImageLoader => {
  return props => {
    if (props.src.includes('//images.ctfassets.net')) return contentfulImageLoader(props)
    if (props.src.includes('//cdn.shopify.com')) return shopifyImageLoader(props)

    return props.src
  }
}
