import styled from 'styled-components'

import {
  ResponsiveImage,
  ResponsiveImageWithSkeletonWrapper,
  TypeStyle,
  fromMd,
} from '@syconium/little-miss-figgy'

// TODO: Fix this auto suppressed ESLint rule
// eslint-disable-next-line rulesdir/require-responsive-images
export const Image = styled(ResponsiveImageWithSkeletonWrapper)<{ isChecked: boolean }>`
  object-fit: cover;
  border: ${o => o.theme.spacing(0.5)} solid
    ${o => (!o.isChecked ? `transparent` : o.theme.color.deprecated.obsidian)};
  width: 100%;
  height: auto;
  ${fromMd} {
    aspect-ratio: 253/328;
  }
  aspect-ratio: 145/172;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
`

export const ImageDisabled = styled(ResponsiveImage)`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  -webkit-transition: none;
  &:hover {
    cursor: auto;
    border: ${o => o.theme.spacing(0.5)} solid inherit;
    transform: scale(1);
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: ${o => o.theme.spacing(2)};
  margin-left: auto;
  margin-right: auto;
`

export const Body = styled.figure`
  ${Image} {
    border-radius: ${o => o.theme.spacing(2)};
  }
`

export const FilterCtaText = styled.div<{
  isUppercase: boolean
  isChecked: boolean
  isDisabled: boolean
}>`
  ${TypeStyle.css.headlineExtraSmall}
  padding-top: ${o => o.theme.spacing(2)};
  padding-bottom: ${o => o.theme.spacing(1)};
  color: ${o =>
    o.isDisabled
      ? o.theme.color.deprecated.stoneDark
      : o.isChecked
      ? o.theme.color.deprecated.obsidian
      : o.theme.color.deprecated.obsidianLight};
  ${fromMd} {
    padding-top: ${o => o.theme.spacing(2)};
    padding-bottom: ${o => o.theme.spacing(2)};
  }
  &:hover {
    cursor: ${o => (o.isDisabled ? 'auto' : 'pointer')};
  }
`
export const FilterDescription = styled.div<{
  isUppercase: boolean
  isChecked: boolean
  isDisabled: boolean
}>`
  ${fromMd} {
    font-size: 12px;
  }
  font-size: 10px;
  font-weight: 500;
  letter-spacing: ${o => (o.isUppercase ? '0.15em' : '0.05em')};
  line-height: 20px;
  color: ${o =>
    o.isDisabled
      ? o.theme.color.deprecated.stoneDark
      : o.isChecked
      ? o.theme.color.deprecated.obsidianLighter
      : o.theme.color.deprecated.obsidianLighter};
  &:hover {
    cursor: ${o => (o.isDisabled ? 'auto' : 'pointer')};
  }
`
