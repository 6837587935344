import * as React from 'react'
import { createContainer } from 'unstated-next'

function useRefs() {
  const footerRef = React.useRef<HTMLDivElement>(null)

  // Reference to scrollable area to determine how much it has scrolled once open, and scroll-to-top on close. Kyle Corbelli 11/12/19
  const detailsCardScrollableAreaRef = React.useRef<HTMLDivElement>(null)

  // Reference to add-to-bag section to determine if we should display the sticky add-to-bag. Kyle Corbelli 01/08/20
  const detailsSectionAddToBagSectionRef = React.useRef<HTMLDivElement>(null)

  return {
    detailsSectionAddToBagSectionRef,
    detailsCardScrollableAreaRef,
    footerRef,
  }
}

export const RefsContainer = createContainer(useRefs)
