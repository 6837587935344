import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

import { ModalCloseButton } from '../../../ModalCloseButton'

export const CloseButton = styled(ModalCloseButton)`
  ${fromMd} {
    padding: 26px;
  }
`

export const Header = styled.div<{
  backgroundColor?: string
  hasBottomBorder?: boolean
}>`
  background: ${o => o.backgroundColor || o.theme.color.deprecated.stoneLighter};
  border-bottom: ${o =>
    o.hasBottomBorder ? `1px solid ${o.theme.color.deprecated.stoneLighter}` : 'none'};
  padding: 20px;
  position: sticky;

  ${fromMd} {
    min-height: 64px;
    padding: 0;
  }
`

export const HeaderTextSmall = styled(TypeStyle.HeadlineSmall)`
  ${fromMd} {
    line-height: 68px;
    padding: 0 20px;
  }
`

export const NavWrap = styled.div`
  ${fromMd} {
    padding: 0 40px;
  }
`

export const TableWrap = styled.div`
  overflow-y: scroll;
  padding: 10px 20px;

  ${fromMd} {
    padding: 30px 40px;
  }
`
