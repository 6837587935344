import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { CheckoutErrorCode } from '../../__generated__/graphql/shopify/graphql'
import {
  CheckoutLineItemInput,
  CountryCode,
  CustomAttribute,
  LanguageCode,
} from '../../types/shopify-storefront-api'

export interface CheckoutUserError {
  code: CheckoutErrorCode
  field: string[]
  message: string
}

export interface CheckoutCreate {
  data: {
    checkoutCreate: {
      checkout: {
        id: string
        webUrl: string
      }
      checkoutUserErrors: CheckoutUserError[]
    }
  }
}

export interface CheckoutCreateVariables {
  allowPartialAddresses: boolean
  lineItems: CheckoutLineItemInput[]
  shippingAddress: ShippingAddress | undefined
  customAttributes: CustomAttribute[]
  country: CountryCode
  language: LanguageCode
}

export interface CheckoutCustomerAssociateV2 {
  data: {
    checkoutCustomerAssociateV2: {
      checkout: {
        id: string
        webUrl: string
      }
      checkoutUserErrors: CheckoutUserError[]
    }
  }
}

export type RegionToShippingAddress = {
  [region in SupportedRegionIdentifier]: ShippingAddress
}

export type ShippingAddress = {
  city: 'na'
  country: string
  province: string
}

export enum IterableKeys {
  CAMPAIGN_ID = 'iterableEmailCampaignId',
  SMS_CAMPAIGN_ID = 'iterableEmailCampaignIdTemp',
  TEMPLATE_ID = 'iterableTemplateId',
  MESSAGE_ID = 'iterableMessageId',
  USER_ID = 'iterableUserId',
}
