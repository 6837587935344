import styled from 'styled-components'

import { timingSlower } from '@syconium/little-miss-figgy'
import { fadeIn } from '@syconium/magnolia/src/brunswick/styles/shared'

import { zIndexNavHeader } from '../../nav/NavHeader/styles'

export const Body = styled.div`
  align-items: center;
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndexNavHeader - 1};
`
