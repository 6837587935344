import { createContext } from 'react'

import { createContextHook } from '@syconium/magnolia/src/lib/utils'
import type { ProductColor, ProductFilter } from '@syconium/magnolia/src/types/graphql'

export const CollectionFiltersContext = createContext<
  | {
      colors: Record<string, ProductColor>
      filters: ProductFilter[] | undefined
      totalItemsCount: number
    }
  | undefined
>(undefined)

export const useCollectionFiltersContext = createContextHook(
  CollectionFiltersContext,
  'CollectionFiltersContext'
)
