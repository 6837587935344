import * as React from 'react'

export function createContextHook<Value>(
  Context: React.Context<Value | undefined>,
  contextName: string
) {
  return function (): Value {
    const hookValue: Value | undefined = React.useContext(Context)
    if (!hookValue) {
      throw new Error(`Component must be wrapped with a ${contextName} Provider`)
    }
    return hookValue
  }
}
