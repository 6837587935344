import React from 'react'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { PortalConfigContainer } from '@syconium/magnolia/src/brunswick/containers/PortalConfigContainer'
import { useRegionLocalePicker } from '@syconium/magnolia/src/lib/hooks/useRegionLocalePicker'
import { PromoBar as Props } from '@syconium/magnolia/src/types/graphql'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const PromoBar: React.FC<Props> = props => {
  const viewModel = useViewModel(props)
  return <Template {...viewModel} />
}

export const PromoBarFromContext: React.FC = () => {
  const { promoBar: defaultPromoBar } = useFixturesContext()
  const { globalContentfulRegionData } = useRegionLocalePicker()
  const { portal } = PortalConfigContainer.useContainer()

  const promoBar = globalContentfulRegionData?.promoBarRegion ?? defaultPromoBar

  if (!promoBar) return null

  return (
    <PromoBar
      {...promoBar}
      pages={
        portal?.promoBarPages && portal.promoBarPages.length > 0
          ? portal.promoBarPages
          : promoBar.pages
      }
    />
  )
}
