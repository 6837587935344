import React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const User: React.FC<IProps> = ({ height = '26px', width = '26px', ...rest }) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <g fill='none' stroke='currentColor' strokeWidth='1.5' transform='translate(6 6)'>
        <circle cx='6.32' cy='3.39' r='3.26' />
        <path d='M.13 12.24V8.37A.77.77 0 0 1 .9 7.6h10.87a.77.77 0 0 1 .77.77v3.83' />
      </g>
    </BaseIcon>
  )
}
