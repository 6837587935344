import { useRouter } from 'next/router'

import { useRegionLocalePicker } from '@syconium/magnolia/src/lib/hooks/useRegionLocalePicker'

import { useRegionLocaleTab } from './RegionLocaleTab/useRegionLocaleTab'
import { useAccountTab } from './useAccountTab'
import { useCartTab } from './useCartTab'
import { useSearchTab } from './useSearchTab'

export const useIconTabs = () => {
  const { pathname } = useRouter()
  const { enabledLocales } = useRegionLocalePicker()
  const isSearchPage: boolean = pathname === '/search'

  const searchTab = useSearchTab()
  const accountTab = useAccountTab()
  const cartTab = useCartTab()
  const localeTab = useRegionLocaleTab()
  const mobileIconTabs = [accountTab, cartTab]
  const desktopIconTabs =
    enabledLocales.length > 1
      ? [searchTab, localeTab, ...mobileIconTabs]
      : [searchTab, ...mobileIconTabs]
  if (isSearchPage) desktopIconTabs.shift()

  return {
    desktopIconTabs,
    mobileIconTabs,
  }
}
