import { BLOCKS } from '@contentful/rich-text-types'

import {
  CollectionGridSection,
  CollectionView,
  CollectionViewHeroSection,
  EditorialCell,
  EditorialCellPlacement,
  GenderNavSection,
  HeroFullBleedSection,
  VideoModalBannerSection,
  VisualFilterTile,
  VisualFiltersSection,
} from '../../types/graphql'
import { EditorialCellDescriptionSize } from '../components/pages/Collection/sections/CollectionGridSection/Tiles/types'

import { videoSection } from './sections'

export const editorialCell: EditorialCell = {
  __typename: 'EditorialCell',
  id: 'some-contentful-id',
  title: 'TEST demo sample example',
  subtitle: 'TEST demo sample example',
  textColor: '#FFF',
  textAlignment: 'left',
  descriptionPlacement: 'Below Image',
  descriptionSize: EditorialCellDescriptionSize['Large (Text Only or Two Up)'],
  titleSize: null,
  descriptionPadding: 0,
  descriptionByLine: 'demo',
  descriptionColor: null,
  descriptionAlignment: 'left',
  titleAlignment: 'left',
  reviewStars: true,
  description: 'This demo has all fields filled. For realistic examples, override to remove some.',
  ctaText: 'Learn More',
  ctaAlignment: null,
  linkHref: '/',
  modalVideoPlatform: 'youtube',
  modalVideoId: 'Jh5IHZM2yJs',
  modalVideoIdMobile: null,
  image:
    // not quite correct aspect ratio
    'https://cdn.shopify.com/s/files/1/0139/8942/files/MOBILE_HP_2x_4725c3b3-d06e-40a6-adea-82d759bcb269.jpg?v=1588639027',
  imageMobile: null,
  inlineVideo:
    // not quite correct aspect ratio
    'https://cdn.shopify.com/s/files/1/0139/8942/files/cwq2_navy_zingatop_1080x1080_200410_dt_final1.mp4?v=1588728888',
  inlineVimeoId: 586948761,
  inlineVimeoIdMobile: null,
  backgroundColor: '#365063',
  imageAspectRatio: null,
  imageMobileAspectRatio: null,
  eyebrow: null,
}

export const editorialCellPlacement: EditorialCellPlacement = {
  __typename: 'EditorialCellPlacement',
  id: 'test-id',
  alignmentDesktop: null,
  editorialCell,
  rowsToSkipDesktop: null,
  cellWidth: null,
  pinToBottom: null,
  rowsToSkipMobile: null,
}

export const visualFilterTile: VisualFilterTile = {
  id: '',
  __typename: 'VisualFilterTile',
  ctaText: '',
  value: '',
  filter: '',
  image: '',
  description: '',
  filterSpec: { __typename: 'ProductFilterSpec', id: '', name: '' },
}

export const visualFiltersSection: VisualFiltersSection = {
  __typename: 'VisualFiltersSection',
  id: '',
  layoutVariantDesktop: 'grid',
  layoutVariantMobile: 'grid',
  hideOnMobile: false,
  hideOnDesktop: false,
  isCoreStylesVariant: false,
  entryCardDescription: '',
  entryCardHeader: '',
  tiles: [visualFilterTile],
}

export const visualFiltersSectionV2: VisualFiltersSection = {
  __typename: 'VisualFiltersSection',
  id: '',
  layoutVariantDesktop: 'grid',
  layoutVariantMobile: 'grid',
  hideOnMobile: false,
  hideOnDesktop: false,
  isCoreStylesVariant: false,
  entryCardDescription: '',
  entryCardHeader: '',
  tiles: [visualFilterTile],
}

export const collectionGridSection: CollectionGridSection = {
  __typename: 'CollectionGridSection',
  id: 'some-collection-grid-id',
  filtersEnabled: true,
  filterConfig: null,
  itemColorScope: 'ALL',
  groupedProductTiles: true,
  visualFiltersSection: visualFiltersSection,
  visualFiltersSectionV2: visualFiltersSectionV2,
  editorializedCollections: [
    {
      id: 'some-collection-id',
      handle: 'some-collection-handle',
      title: null,
      subtitle: null,
      editorialCellPlacements: [editorialCellPlacement],
      editorialRows: [videoSection],
    },
  ],
}

export const genderNavSection: GenderNavSection = {
  __typename: 'GenderNavSection',
  id: 'some-gender-nav-id',
  alternateGenderHandle: 'test-womens-teal',
  selectedGender: 'Men',
}

export const heroFullBleedSection: HeroFullBleedSection = {
  __typename: 'HeroFullBleedSection',
  id: 'some-hero-id',
  title: 'Teal Collection',
  subtitle: 'Test Subtitle Here',
  description:
    'This is a test HeroFullBleedSection description. This is a test description of a reasonable length to cause some text wrapping.',
  textColor: '#FFF',
  imageDesktop:
    // 2880x1200, not normative. Aspect ratio changes, height fixed. Design 1440x400, 1024x400. height: 400px
    'https://cdn.shopify.com/s/files/1/0139/8942/files/gift-guide-collection-hero-deskop.jpg?v=1573863096',
  imageMobile:
    // 2700x960, not normative. Aspect ratio changes, height fixed. Design 375x250, 320x250. height: 250px
    'https://cdn.shopify.com/s/files/1/0139/8942/files/gift-guide-collection-hero-mobile.jpg?v=1573863096',
  inlineVideoDesktop:
    // 1280x640, not normative. Aspect ratio changes, height fixed. Design 1440x400, 1024x400. height: 400px
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_3600x1800_handbrake.mp4?v=1591307606',
  inlineVideoMobile:
    // 720x590, not normative. Aspect ratio changes, height fixed. Design 375x250, 320x250. height: 250px
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_mobile_828x1012_handbrake.mp4?v=1591307602',
  inlineVimeoIdDesktop: 586948724,
  inlineVimeoIdMobile: 586948761,
  isCriticalImage: false,
}

export const collectionViewHeroSection: CollectionViewHeroSection = {
  __typename: 'CollectionViewHeroSection',
  id: 'some-hero-id',
  backgroundColor: '#f5f5f5',
  ctaLink: '#',
  ctaText: 'shop now',
  imagePlacement: 'left',
  imageDesktop:
    // 1024x574, normative. Design 714x400, 512x287. const desktopAspectRatio = 1.784
    'https://cdn.shopify.com/s/files/1/0139/8942/files/mock-brunswick-collection-hero-desktop.jpg?v=1585170614',
  imageMobile:
    // 750x352, normative. Design 375x176, 320x150. const mobileAspectRatio = 2.132
    'https://cdn.shopify.com/s/files/1/0139/8942/files/mock-brunswick-collection-hero-mobile.jpg?v=1585170613',
  inlineVideoDesktop:
    // 1280x640, not normative. Design 714x400, 512x287. const desktopAspectRatio = 1.784
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_3600x1800_handbrake.mp4?v=1591307606',
  inlineVideoMobile:
    // 1280x640, not normative. Design 375x176, 320x150. const mobileAspectRatio = 2.132
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_3600x1800_handbrake.mp4?v=1591307606',
  inlineVimeoIdDesktop: 586948724,
  inlineVimeoIdMobile: 586948761,
  textColor: '#000000',
  title: 'All Women’s Scrubs',
  subtitle: 'Technically innovative performance-wear',
  videoId: 'a06LxCkhiN0',
  videoPlatform: 'youtube',
  isCriticalImage: false,
  handle: '',
}

export const videoModalBannerSection: VideoModalBannerSection = {
  __typename: 'VideoModalBannerSection',
  id: 'some-banner-id',
  heading: 'It’s back. It’s teal.',
  body: 'You asked for it—we got you. Our best-selling color TEAL is now available in all of your favorite Core styles.',
  ctaText: 'Button CTA here',
  image:
    // 640x498, normative. Design 888x504, 648x504, 440x342. const aspectRatio = 1.286, const maxHeight = '504px'
    'https://cdn.shopify.com/s/files/1/0139/8942/files/teal-gif-1_640x.gif?v=1585256433',
  inlineVideo:
    // 720x590, not normative. Design 888x504, 648x504, 440x342. const aspectRatio = 1.286, const maxHeight = '504px'
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_mobile_828x1012_handbrake.mp4?v=1591307602',
  inlineVimeoId: 586948761,
  videoId: 'Jh5IHZM2yJs',
  videoPlatform: 'youtube',
  linkHref: 'https://example.com/',
}

export const collectionView: CollectionView = {
  handle: 'womens-new-colors',
  name: 'Womens New Colors',
  noindex: true,
  seoContent: {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.PARAGRAPH,
        data: {},
        content: [
          {
            nodeType: 'text',
            value: 'Mock copy',
            marks: [],
            data: {},
          },
        ],
      },
    ],
  },
  socialDescription: 'Example collection of sample products from FIGS.',
  socialImage:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/FIGS_LOGO_BLACK-02-large.png?v=1574898420',
  sections: [collectionViewHeroSection, collectionGridSection, videoModalBannerSection],
}
