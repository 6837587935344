import { PageLayoutConfig } from '../types'

interface NoLayoutProps {
  children?: React.ReactNode
}

function NoLayout({ children }: NoLayoutProps) {
  return <>{children}</>
}

export const noLayoutConfig: PageLayoutConfig = {
  Layout: ({ content, options: _options }) => <NoLayout>{content}</NoLayout>,
  preloadDataForLayout: undefined,
  isPlain: true,
}
