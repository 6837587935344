import styled from 'styled-components'

import { navDropdownShadow } from '@syconium/little-miss-figgy/dist/utility-classes'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import * as RegionLocale from '@syconium/magnolia/src/components/region-locale'
import { SupportedLocaleIdentifier } from '@syconium/weeping-figs'

import { LocalizationContainer } from '../../../brunswick/containers/localization/LocalizationContainer'
import { useRegionLocalePicker } from '../../../lib/hooks/useRegionLocalePicker'

// Positions the dropdown correctly to the left and inline with the text
export const Wrapper = styled.div`
  inset-inline-end: 14px;
  position: absolute;
  background-color: ${o => o.theme.color.deprecated.white.primary};
  ${navDropdownShadow}
`

export function NavRegionLocaleSection() {
  const { regionAndLocalePicker } = useFixturesContext()
  const { region } = LocalizationContainer.useContainer()
  const {
    getLanguageName,
    handleCountryChange,
    enabledLocales,
    enabledRegionNames,
    setSelectedLocale,
    handleSubmit,
    selectedLocale,
    selectedContentfulRegionData,
  } = useRegionLocalePicker()

  return (
    <Wrapper>
      <RegionLocale.Container type='dropdown'>
        <RegionLocale.DropdownWithLabel
          label={regionAndLocalePicker.modal.labelCountry}
          isScrollable={true}
          onChange={handleCountryChange}
          options={enabledRegionNames}
          placeholderText={regionAndLocalePicker.modal.placeholderCountry}
          selection={selectedContentfulRegionData?.name ?? region.name}
        />
        <RegionLocale.DropdownWithLabel
          label={regionAndLocalePicker.modal.labelLanguage}
          isScrollable={true}
          onChange={(language: SupportedLocaleIdentifier) => setSelectedLocale(language)}
          options={enabledLocales}
          placeholderText={regionAndLocalePicker.modal.placeholderLanguage}
          renderOptionText={getLanguageName}
          renderSelectionText={getLanguageName}
          selection={selectedLocale}
        />
        <RegionLocale.CtaWrapper>
          <RegionLocale.CtaButton onClick={() => handleSubmit()}>
            {regionAndLocalePicker.modal.ctaText}
          </RegionLocale.CtaButton>
        </RegionLocale.CtaWrapper>
      </RegionLocale.Container>
    </Wrapper>
  )
}
