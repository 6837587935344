import styled from 'styled-components'

import { timingSlower } from '@syconium/little-miss-figgy'

export const Overlay = styled.div<{
  isMiniCartRevealed: boolean
}>`
  background: ${o => o.theme.color.deprecated.gray.lightest};
  bottom: 0;
  left: 0;
  opacity: ${o => (o.isMiniCartRevealed ? 0.9 : 0)};
  pointer-events: ${o => (o.isMiniCartRevealed ? 'auto' : 'none')};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity ${timingSlower} ease;
  z-index: ${o => o.theme.zIndex.modals};
`
