import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { ContentfulRegion } from '@syconium/magnolia/src/types/graphql'

export function IntlShippingAndTaxMsg() {
  const { region } = LocalizationContainer.useContainer()
  const { regionData } = useFixturesContext()
  const currentRegionData = regionData?.regions.find((r: ContentfulRegion) => r.id === region.id)
  return (
    <>
      {currentRegionData?.shippingText && <div>{currentRegionData?.shippingText}</div>}
      {currentRegionData?.taxAndDutyText && <div>{currentRegionData?.taxAndDutyText}</div>}
    </>
  )
}
