import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_TILE_PRODUCT_GROUP_FOR_SEARCH = gql(`
  query GetTileProductGroupForSearch($handle: String!, $color: String, $fit: String) {
    productGroup(handle: $handle) {
      ...TileProductGroup
      colorGroups {
        ...ColorGroup
      }
      colors(selector: ALL) {
        ...Color
      }
      featuredProduct(color: $color, fit: $fit) {
        ...FeaturedProduct
      }
    }
  }
`)
