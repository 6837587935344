import { ComponentProps, HTMLAttributes } from 'react'

import { AttributeSection, ColorSelector } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { useTooltipFixture } from '@syconium/magnolia/src/lib/hooks/useTooltipFixture'
import { ProductColor, TooltipContentTypes } from '@syconium/magnolia/src/types/graphql'

import * as Styles from './styles'

export type ColorAttributeProps = {
  title: string
  selected?: string
  soldOut?: boolean
  label: string
  onSelect: ComponentProps<typeof ColorSelector>['onSelectColor']
  colors: ProductColor[]
  withTooltip?: boolean
  children?: React.ReactNode
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'>

export type ColorTooltipProps = {
  title: string
  description?: string
}

/**
 * The tooltip content that is displayed for the color attribute selector.
 */
export function ColorGroupTooltip({ title, description }: ColorTooltipProps) {
  return (
    <Styles.TooltipContent>
      <Styles.TooltipTitle>{title}</Styles.TooltipTitle>
      {description && <Styles.TooltipDescription>{description}</Styles.TooltipDescription>}
    </Styles.TooltipContent>
  )
}

/**
 * An individual color attribute selector.
 */
export function ColorAttribute({
  title,
  selected,
  soldOut = false,
  label,
  onSelect,
  colors,
  withTooltip = false,
  children,
  ...rest
}: ColorAttributeProps) {
  const {
    detailsSection: { soldOutSectionTitleMessage },
  } = useFixturesContext()

  const tooltip = useTooltipFixture(label, TooltipContentTypes.ColorCategory)

  return (
    <AttributeSection
      title={title}
      selection={
        <>
          {selected}
          {Boolean(soldOut && selected) && (
            <Styles.SoldOut>{soldOutSectionTitleMessage}</Styles.SoldOut>
          )}
        </>
      }
      tooltip={
        withTooltip &&
        tooltip && <ColorGroupTooltip title={tooltip.title} description={tooltip?.description} />
      }
      tooltipLabel='Additional color information'
      {...rest}
    >
      <ColorSelector
        onSelectColor={onSelect}
        colors={colors}
        inputType='radio'
        selectedColorKey={selected}
        disableMobileHorizontalSwipe
      />
      {/* Optionally render additional markup within the attribute selector */}
      {children}
    </AttributeSection>
  )
}
