import { useCallback } from 'react'

import {
  GtmVariant,
  useGoogleTagManager,
} from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'

export function useTrackQuantityChange() {
  const { pushAddToCart, pushRemoveFromCart } = useGoogleTagManager()

  const trackQuantityChange = useCallback(
    (newQuantity: number, oldQuantity: number, variantToTrack: GtmVariant) => {
      if (newQuantity > oldQuantity) {
        pushAddToCart({
          variant: variantToTrack,
          quantity: newQuantity - oldQuantity,
        })
      } else {
        pushRemoveFromCart({
          variant: variantToTrack,
          quantity: oldQuantity - newQuantity,
        })
      }
    },
    [pushAddToCart, pushRemoveFromCart]
  )

  return trackQuantityChange
}
