import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const YouTube: React.FC<IProps> = ({ height = '20px', width = '20px' }) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 -1 15 12'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M14.6847 1.56452C14.5142 0.951613 14.0028 0.467742 13.3551 0.298387C12.1875 0 7.5 0 7.5 0C7.5 0 2.8125 0 1.63636 0.298387C0.988636 0.459677 0.485795 0.943548 0.306818 1.56452C0 2.67742 0 5 0 5C0 5 0 7.32258 0.315341 8.43548C0.485795 9.04839 0.997159 9.53226 1.64489 9.70161C2.8125 10 7.5 10 7.5 10C7.5 10 12.1875 10 13.3636 9.70161C14.0114 9.54032 14.5142 9.05645 14.6932 8.43548C15 7.32258 15 5 15 5C15 5 15 2.67742 14.6847 1.56452ZM5.96591 7.1129V2.8871L9.88636 5L5.96591 7.1129Z'
        fill='#A3A3A3'
      />
    </BaseIcon>
  )
}
