import { localStorageNamespace } from '@syconium/iron-figs'
import { CurrentUser } from '@syconium/magnolia/src/types/figs'
import { isServer } from '@syconium/weeping-figs'

import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'

const userKey = 'user'

export const getLSKey = (key: string) => `${localStorageNamespace}${key}`

export const setItemInLS = (key: string, value: string) => {
  if (isServer) return
  const lsKey = getLSKey(key)
  try {
    localStorage?.setItem(lsKey, value)
  } catch (error) {
    reportClientError({
      error,
      context: {
        scope: 'localStorage',
        label: 'setItemInLS',
        lsKey,
      },
    })
  }
}

export const getItemFromLS = (key: string) => {
  if (isServer) return
  const lsKey = getLSKey(key)
  return localStorage?.getItem(lsKey)
}

export const removeItemFromLS = (key: string) => {
  if (isServer) return
  localStorage?.removeItem(getLSKey(key))
}

// TODO: store user with useBrowserStoredState for session storage fallback
export const setUserDataInLS = (currentUser: CurrentUser) => {
  setItemInLS(userKey, JSON.stringify(currentUser))
}

export const getUserDataFromLS = (): CurrentUser | null => {
  if (isServer) return null
  let lsUser: CurrentUser | null = null
  try {
    const retrievedItem = getItemFromLS(userKey)
    lsUser = retrievedItem ? JSON.parse(retrievedItem) : null
  } catch (error) {
    reportClientError({
      error,
      context: {
        scope: 'localStorage',
        label: 'getUserDataFromLS',
      },
    })
  }
  return lsUser
}

export const removeUserKeyFromLS = () => removeItemFromLS(userKey)
