import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

import { cookieKeys } from '../../app/_config/Cookies.config'
import { useCookies } from '../../app/_providers/CookiesProvider.client'

/**
 * The current NavGender describes the application state. It is not product data.
 * Concept of the current site experience gender may diverge from product gender.
 * See also ProductGroupGender in contentful-gateway `generated/product_group.ts`
 */
type w = 'w' | 'W'
type m = 'm' | 'M'
type GenderPreference = null | `${m}en` | `${w}omen`

const useGenderPreferenceContainer = (): {
  genderPreference: GenderPreference | null
  potentialGenderPreference: GenderPreference | null
  setGenderPreference: (x: GenderPreference) => void
  setPotentialGenderPreference: (x: GenderPreference) => void
} => {
  const [genderPreferenceCookie, _setGenderPreferenceCookie] = useCookies([
    cookieKeys.stylePreference.key,
  ])
  const [potentialGenderPreference, setPotentialGenderPreference] = useState<GenderPreference>(null)
  const setGenderPreferenceCookie = useCallback(
    (x: GenderPreference) => {
      const gender = !!x ? x.toLowerCase() : x
      if (gender === 'women' || gender === 'men') {
        _setGenderPreferenceCookie(
          cookieKeys.stylePreference.key,
          gender,
          cookieKeys.stylePreference.options
        )
      }
    },
    [_setGenderPreferenceCookie]
  )

  const cookieValue = genderPreferenceCookie.navGender?.toLowerCase() ?? ''
  const genderPreference = cookieValue === 'men' || cookieValue === 'women' ? cookieValue : null

  return {
    genderPreference,
    potentialGenderPreference,
    setGenderPreference: setGenderPreferenceCookie,
    setPotentialGenderPreference,
  }
}

export const GenderPreferenceContainer = createContainer(useGenderPreferenceContainer)
