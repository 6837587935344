import { Footer, FooterMenuItem } from '../../../../types/figs'

function createItem(title: string = '', href: string = ''): FooterMenuItem {
  return {
    title,
    href,
  }
}

// Our Company
const ourStory = createItem('Our Story', '/pages/our-story')
const impactReport = createItem('Impact Report', 'https://hello.wearfigs.com/impact-report-2020')
const threadsForThreads = createItem(
  'Threads for Threads',
  'https://thephysical.wearfigs.com/threadsforthreads/'
)
const press = createItem('Press', '/pages/press')
const careers = createItem('Careers', '/pages/careers')
const investors = createItem('Investors', 'https://ir.wearfigs.com')

// More info
const fionTerms = createItem('FIONx Protective Mask Terms', '/pages/fionx-protective-masks-terms')
const productCare = createItem('Care Instructions', '/pages/product-care')
const students = createItem('Student Discount', '/pages/students')
const military = createItem('Military Discount', '/pages/military-first-responders')
const californiaPrivacy = createItem(
  'California Privacy Rights',
  '/pages/privacy#additional-disclosures'
)
const fionTechnology = createItem('Fabric Technology', '/pages/fabric-technology')
const mobileApp = createItem('Mobile App', 'https://hello.wearfigs.com/app/')
const accessibility = createItem('Accessibility Statement', '/pages/accessibility')
const sitemap = createItem('Site Map', '/pages/site-map')
const faceShieldTerms = createItem('Face Shield Terms', '/pages/face-shield-terms')

const defaultGetHelp = {
  helpCenter: createItem('Help Center', 'https://help.wearfigs.com/'),
  shipping: createItem('Shipping', '/pages/shipping-and-handling'),
  returnsAndExchanges: createItem('Returns & Exchanges', '/pages/returns'),
  teamsOrders: createItem('Team Orders', 'https://hello.wearfigs.com/teams/'),
  contactUs: createItem('Contact Us', 'https://help.wearfigs.com/hc/en-us/requests/new'),
  phone: {
    number: '424-500-8209',
    cta: 'Call or Text',
    timeText: '6am to 8pm PST',
    availableDaysText: 'Monday to Friday',
  },
  chat: {
    href: 'https://help.wearfigs.com/hc/en-us/requests/new',
    title: 'Chat with Us',
    timeText: '8am to 5pm PST',
    availableDaysText: 'Monday to Friday',
  },
}

const defaultMenus = [
  {
    title: 'Our Company',
    items: [ourStory, impactReport, threadsForThreads, press, careers, investors],
  },
  {
    title: 'More info',
    items: [
      fionTechnology,
      productCare,
      students,
      military,
      faceShieldTerms,
      fionTerms,
      californiaPrivacy,
      accessibility,
      mobileApp,
      sitemap,
    ],
  },
]

export const enUsFooter: Footer = {
  accessibilityButtonText: 'accessibility',
  hashtag: 'wearfigs',
  address: '108 S. Mayo Ave. City of Industry, CA 91789',
  copyright: '© 2023 FIGS, Inc. All rights reserved',
  termsLinkHref: '/pages/terms-of-use',
  termsLinkText: 'Terms of Use',
  privacyLinkHref: '/pages/privacy',
  privacyLinkText: 'Privacy Policy',
  referAFriendLinkHref: '/pages/friends',
  referAFriendLinkText: 'Refer a friend',
  getHelp: defaultGetHelp,
  menus: defaultMenus,
}

export function getFooter(locale: string): Footer {
  switch (locale) {
    case 'en-US':
      return enUsFooter
    default:
      return {
        accessibilityButtonText: 'accessibility',
        hashtag: 'wearfigs',
        address: '108 S. Mayo Ave. City of Industry, CA 91789',
        copyright: '© 2021 FIGS, Inc. All rights reserved',
        termsLinkHref: '/pages/terms-of-use',
        termsLinkText: 'Terms of Use',
        privacyLinkHref: '/pages/privacy',
        privacyLinkText: 'Privacy Policy',
        referAFriendLinkHref: '/pages/friends',
        referAFriendLinkText: 'Refer a friend',
        getHelp: defaultGetHelp,
        menus: defaultMenus,
      }
  }
}
