import styled, { css } from 'styled-components'

import { ResponsiveImage, withHoverSupport } from '@syconium/little-miss-figgy'
import { fromMd, untilMd } from '@syconium/little-miss-figgy'

const disabledImageStyles = css`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  transition: none;
  -webkit-transition: none;
`

export const Label = styled.label``

export const Image = styled(ResponsiveImage)``

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
`

export const Body = styled.figure<{
  tileVariantDesktop: 'circle' | 'rectangle'
  tileVariantMobile: 'circle' | 'rectangle'
  isChecked: boolean
  isDisabled: boolean
}>`
  ${Image} {
    ${untilMd} {
      border-radius: ${o => (o.tileVariantMobile === 'circle' ? '50%' : o.theme.spacing(2))};
    }

    ${fromMd} {
      border-radius: ${o => (o.tileVariantDesktop === 'circle' ? '50%' : o.theme.spacing(2))};
    }
  }

  ${Image} {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    transition: transform 0.5s ease;
    -webkit-transition: transform 0.5s ease;
    width: 100%;
    aspect-ratio: 1;
    height: auto;
    display: block;
    box-sizing: border-box;
    position: relative;
    transform-origin: center;

    ${o => (o.isDisabled ? disabledImageStyles : '')}
  }

  ${Label} {
    margin-top: 3px;
    box-shadow: 0 0 0 3px ${o => (o.isChecked && !o.isDisabled ? `#282828` : 'transparent')};
    border-radius: 50%;
    display: block;
  }

  ${withHoverSupport} {
    &:hover {
      ${Image} {
        cursor: ${o => (o.isDisabled ? `auto` : `pointer`)};
        transform: ${o => (o.isChecked || o.isDisabled ? `scale(1)` : `scale(1.05)`)};
      }
    }
  }
`

export const Figcaption = styled.figcaption<{
  isUppercase: boolean
  isChecked: boolean
  isDisabled: boolean
}>`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: ${o => (o.isUppercase ? '0.15em' : '0.05em')};
  line-height: 1.5;
  margin-top: ${o => o.theme.spacing(2)};
  text-align: center;
  color: ${o => (o.isDisabled ? '#A3A3A3' : o.isChecked ? '#282828' : '#525252')};
  &:hover {
    cursor: ${o => (o.isDisabled ? 'auto' : 'pointer')};
  }
`
