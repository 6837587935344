import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { RegionLocaleTab } from './RegionLocaleTab'

export function useRegionLocaleTab(): NavTab {
  const localeTab: NavTab = {
    ariaLabel: 'Region and Locale',
    href: null,
    id: 'regionlocale',
    render: () => <RegionLocaleTab />,
    sections: [
      {
        __typename: 'NavRegionLocaleSection',
        id: 'locale',
      },
    ],
    text: '',
    textColor: null,
    wrapperElement: 'button',
  }

  return localeTab
}
