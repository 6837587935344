import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_KIT_PRICES = gql(`
  query fetchProductKitPrices(
    $handle: String!
    $isPreview: Boolean = false
    $includeUnavailable: Boolean = false
  ) {
    productKit(handle: $handle, isPreview: $isPreview, includeUnavailable: $includeUnavailable) {
      collection {
        productGroups {
          defaultVariant {
            price
          }
        }
      }
    }
  }
`)
