import * as React from 'react'

import { deriveFontFamilyFromStyle } from '@syconium/magnolia/src/brunswick/containers/embroidery/utils/unorganized'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

import { DetailLine, Label, TextEmbroideryVisual } from './styles'
import { Template } from './Template'

export interface Props {
  backgroundColor?: string
  textColor?: string
  textFirstLine?: string
  textFont?: string
  textFontValue?: string
  textPlacement?: string
  textSecondLine?: string
}

export const TextSneakPeek: React.FC<Props> = ({
  backgroundColor,
  textColor,
  textFirstLine,
  textFont,
  textPlacement,
  textSecondLine,
}) => {
  const {
    embroidery: {
      summary: {
        textColorLabel,
        textFirstLineLabel,
        textFontLabel,
        textPlacementLabel,
        textSecondLineLabel,
        textSneakPeekLabel,
      },
    },
  } = useFixturesContext()

  const label = textSneakPeekLabel

  const textFontValue = textFont ? deriveFontFamilyFromStyle(textFont) : undefined

  if (!textPlacement) return null

  return (
    <Template.Container>
      <Template.Header backgroundColor={backgroundColor}>
        {textFirstLine && (
          <TextEmbroideryVisual fontFamily={textFontValue} textColor={textColor?.toLowerCase()}>
            <span>{textFirstLine}</span>
            {textSecondLine && <span>{textSecondLine}</span>}
          </TextEmbroideryVisual>
        )}
      </Template.Header>

      <Template.Body>
        {label && <Label>{label}</Label>}

        {textFirstLine && (
          <DetailLine general>
            {textFirstLineLabel}: {textFirstLine}
          </DetailLine>
        )}

        {textSecondLine && (
          <DetailLine general>
            {textSecondLineLabel}: {textSecondLine}
          </DetailLine>
        )}

        {textFont && (
          <DetailLine>
            {textFontLabel}: {textFont}
          </DetailLine>
        )}

        {textColor && (
          <DetailLine>
            {textColorLabel}: {textColor}
          </DetailLine>
        )}

        {textPlacement && (
          <DetailLine>
            {textPlacementLabel}: {textPlacement}
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}
