import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const LOGIN_SHOP_USER = gql(`
  mutation loginShopUser($email: String!, $password: String!) {
    loginShopUser(email: $email, password: $password) {
      authToken
      errors {
        status
        message
      }
      shopToken
      shopUser {
        shopifyId
        firstName
        lastName
        phone
        ordersCount
      }
    }
  }
`)

export const CREATE_SHOP_USER = gql(`
  mutation createShopUser($input: ShopUserInput!) {
    createShopUser(input: $input) {
      authToken
      errors {
        message
        status
      }
      shopToken
      shopUser {
        firstName
        lastName
        email
      }
    }
  }
`)

export const FORGOT_PASSWORD = gql(`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email, resetUrl: "") {
      errors {
        message
        status
      }
      user {
        email
        firstName
      }
    }
  }
`)

export const RESET_PASSWORD = gql(`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      user {
        id
        email
        firstName
        lastName
      }
      errors {
        message
        path
        status
      }
    }
  }
`)
export namespace AccountServices {
  export const FORGOT_PASSWORD = gql(`
    mutation customerForgotPassword($email: String!) {
      customerForgotPassword(email: $email)
    }
  `)

  export const RESET_PASSWORD = gql(`
    mutation customerResetPassword($token: String!, $password: String!) {
      customerResetPassword(token: $token, password: $password)
    }
  `)

  export const CHANGE_CUSTOMER_PASSWORD = gql(`
    mutation ChangeCustomerPassword($oldPassword: String!, $newPassword: String!) {
      customerChangePassword(oldPassword: $oldPassword, newPassword: $newPassword)
    }
  `)

  export const LOGIN_CUSTOMER = gql(`
    mutation loginCustomer($email: String!, $password: String!) {
      loginCustomer(email: $email, password: $password) {
        customerAccessToken
        refreshToken
        token
      }
    }
  `)
  export const CREATE_CUSTOMER = gql(`
    mutation createCustomer($input: CustomerInput!) {
      createCustomer(input: $input) {
        customerAccessToken
        refreshToken
        token
      }
    }
  `)
  export const GET_NEW_TOKENS = gql(`
    mutation getNewTokens($refreshToken: String!) {
      customerTokenRefresh(refreshToken: $refreshToken) {
        token
        refreshToken
      }
    }
  `)

  export const UPDATE_CUSTOMER_PROFILE = gql(`
    mutation updateCustomerProfile($customerProfileData: UpdateCustomerProfileInput!) {
      updateCustomerProfile(updateCustomerProfileData: $customerProfileData) {
        id
      }
    }
  `)

  export const CREATE_CUSTOMER_ADDRESS = gql(`
    mutation createCustomerAddress($addressData: AddressInput!) {
      createCustomerAddress(addressData: $addressData) {
        shopifyId
      }
    }
  `)

  export const DELETE_CUSTOMER_ADDRESS = gql(`
    mutation deleteCustomerAddress($shopifyId: String!) {
      deleteCustomerAddress(shopifyId: $shopifyId) {
        deletedCustomerAddressId
      }
    }
  `)

  export const UPDATE_CUSTOMER_ADDRESS = gql(`
    mutation updateCustomerAddress($shopifyId: String!, $addressData: AddressInput!) {
      updateCustomerAddress(shopifyId: $shopifyId, addressData: $addressData) {
        shopifyId
      }
    }
  `)

  export const SET_DEFAULT_CUSTOMER_ADDRESS = gql(`
    mutation setDefaultCustomerAddress($shopifyId: String!) {
      setDefaultCustomerAddress(shopifyId: $shopifyId) {
        shopifyId
      }
    }
  `)

  export const CREATE_IDME_SHOPFIY_DISCOUNT_CODE = gql(`
    mutation createIdmeShopifyDiscountCode($authCode: String!, $source: String!) {
      createIdmeShopifyDiscountCode(authCode: $authCode, source: $source)
    }
  `)
  export const VERIFY_DISCOUNT_CODE = gql(`
    mutation verifyDiscountCode($code: String!) {
      verifyDiscountCode(code: $code) {
        valid
      }
    }
  `)

  export const INITIATE_ORDER_CANCELLATION = gql(`
    mutation initateOrderCancellation($id: String!, $email: String!, $name: String!) {
      initateGuestOrderCancellation(id: $id, email: $email, name: $name)
    }
  `)
}

export const SUBSCRIBE_USER = gql(`
  mutation subscribeUser($email: String!, $gender: String, $registrationSource: String) {
    subscribeUser(email: $email, gender: $gender, registrationSource: $registrationSource) {
      result
      errors
    }
  }
`)
