import styled from 'styled-components'

import { fromMd, untilMd } from '@syconium/little-miss-figgy'

export const TitleBar = styled.div`
  align-items: top;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TitleBarCenter = styled.div`
  flex: 1 0;
  padding: 16px 16px 16px 14px;
  text-transform: uppercase;

  ${fromMd} {
    padding: 24px;
    text-align: center;
  }
`

// empty div balances justify-content:space-between on desktop
// - `8 + 16 + 16 + 16 + 8` = gutter, button padding, icon width, button padding, gutter
export const TitleBarLeft = styled.div`
  flex: 0 0 ${8 + 16 + 16 + 16 + 8}px;

  ${untilMd} {
    display: none;
  }
`

// - `8 + 16 + 16 + 16 + 8` = gutter, button padding, icon width, button padding, gutter
export const TitleBarRight = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 ${8 + 16 + 16 + 16 + 8}px;
  justify-content: center;
`

export const CloseButton = styled.button`
  line-height: 0;
  padding: 16px;

  &:hover g {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`
