import * as React from 'react'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const SearchOverlay: React.FC = () => {
  const viewModel = useViewModel()

  return <Template {...viewModel} />
}
