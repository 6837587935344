import { MutableRefObject, useEffect } from 'react'

/**
 * React to changes in the size of an element.
 *
 * @param {MutableRefObject<T | null>} ref - The target element to observe for resizing.
 * @param {ResizeObserverCallback} cb - The callback to run when the element resizes.
 */
export function useResizeObserver<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  cb: ResizeObserverCallback
) {
  useEffect(() => {
    if (!ref.current) return
    const ro = new ResizeObserver(cb)
    ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ref, cb])
}
