import { useRouter } from 'next/router'
import { StringifiableRecord, stringifyUrl } from 'query-string'

import { Product } from '@syconium/magnolia/src/types/graphql'

export function collectionHref(handle: string, query: StringifiableRecord = {}) {
  return stringifyUrl({
    url: `/collections/${handle}`,
    query,
  })
}

export const useProductOrKitHref = (item: Product) => {
  const category = item.rawCategory
  const productGroupHandle = item.productGroup.handle
  const colorName = item.colorInfo?.name

  const isKit = category === 'Product Kit'

  const query: StringifiableRecord = {
    color: colorName,
  }

  const kitHref = useKitHref(productGroupHandle, query)
  const productHref = useProductHref(productGroupHandle, query)
  const pdpHref = isKit ? kitHref : productHref

  return pdpHref
}

export function useKitHref(handle: string, query: StringifiableRecord = {}) {
  return stringifyUrl({
    url: `/kits/${handle}`,
    query,
  })
}

export function useProductHref(handle: string, query: StringifiableRecord = {}) {
  const router = useRouter()
  const url =
    router?.pathname === '/collections/[handle]'
      ? `/collections/${router?.query.handle}/products/${handle}`
      : `/products/${handle}`
  return stringifyUrl({
    url,
    query,
  })
}

export function useProductReviewsHref(handle: string, query: StringifiableRecord = {}) {
  return stringifyUrl({
    url: `/products/${handle}/reviews`,
    query,
  })
}

export function useProductWriteReviewHref(handle: string, query: StringifiableRecord = {}) {
  return stringifyUrl({
    url: `/products/${handle}/reviews`,
    query: { ...query, 'write-review': true },
  })
}

export function portalCollectionHref(handle: string, query: StringifiableRecord = {}) {
  return stringifyUrl({
    url: `/portals/${handle}`,
    query,
  })
}

export function giftProductHref(
  handle: string,
  slug: string,
  collectionHandle: string,
  query: StringifiableRecord = {}
) {
  return stringifyUrl({
    url: `/claim-gift/${slug}/collection/${collectionHandle}/product/${handle}`,
    query,
  })
}

export function giftCollectionHref(
  collectionId: string,
  slug: string,
  query: StringifiableRecord = {}
) {
  return stringifyUrl({
    url: `/claim-gift/${slug}/collection/${collectionId}`,
    query,
  })
}

/**
 * Not intended for boolean false param value, since we always return a string.
 * A false param value should return null, but that would ruin the convenience
 * of this convenience function.
 */
export function singleQueryParamValue(
  queryParamValue: string | string[] | null | undefined,
  defaultValue: string = ''
): string {
  if (typeof queryParamValue === 'boolean') return ''
  return (Array.isArray(queryParamValue) ? queryParamValue[0] : queryParamValue) || defaultValue
}
