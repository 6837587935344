import React from 'react'

import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const NavTabBar: React.FC<{
  freezeTabId?: string
  tabs: NavTab[]
  wrapItems?: boolean
}> = ({ freezeTabId, tabs, wrapItems = false }) => {
  const exitedTab = React.useRef<boolean | undefined>()
  const viewModel = useViewModel({ exitedTab, freezeTabId, tabs, wrapItems })
  return <Template {...viewModel} />
}
