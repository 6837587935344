import { LDOptions as LDClientOptions } from 'launchdarkly-js-client-sdk'

import { SessionAttributes } from './Session.config'

import type { User } from './User.config'
import type { LDContext, LDOptions as LDServerOptions } from '@launchdarkly/node-server-sdk'

export const createLaunchDarklyContext = ({
  user,
  sessionAttributes,
}: {
  user: User | undefined
  sessionAttributes: SessionAttributes
}): LDContext => {
  const context: LDContext = {
    kind: 'multi',
  }

  context.session = {
    key: sessionAttributes.id,
  }

  context.app = {
    key: 'magnolia',
    name: 'magnolia',
    medium: 'web',
    version: process.env.NEXT_PUBLIC_MAGNOLIA_VERSION ?? '0.0.0',
  }

  if (user?.id) {
    context.user = {
      'key': user.id,
      'userID': user.id,
      'email': user.email,
      'authentication': 'authenticated',
      'stylePreference': sessionAttributes.stylePreference,
      'customer-type': sessionAttributes.customerType,
    }
  } else {
    context.user = {
      'key': sessionAttributes.id,
      'authentication': 'guest',
      'stylePreference': sessionAttributes.stylePreference,
      'customer-type': sessionAttributes.customerType,
    }
  }

  return context
}

export const createLaunchDarklyClientConfig = ({
  bootstrap,
}: {
  bootstrap: object | undefined
}): LDClientOptions => {
  return {
    streaming: true,
    streamReconnectDelay: 3000,
    sendEvents: true,
    sendEventsOnlyForVariation: true,
    bootstrap,
  }
}

export const createLaunchDarklyServerConfig = (): LDServerOptions => {
  return {
    stream: true,
    streamInitialReconnectDelay: 3,
    sendEvents: true,
  }
}
