import { css } from 'styled-components'

export const reactModalVideoStyles = css`
  .modal-video {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
  }

  .modal-video-body {
    display: table;
    height: 100%;
    margin: 0 auto;
    max-width: 940px;
    width: 100%;
  }

  .modal-video-inner {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
  }

  .modal-video-movie-wrap {
    background: #333;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .modal-video-close-btn {
    background: transparent;
    border: none;
    display: inline-block;
    height: 35px;
    overflow: hidden;
    position: absolute;
    right: -35px;
    top: -35px;
    width: 35px;
    z-index: ${o => o.theme.zIndex.foregroundContent};

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before,
    &::after {
      background: #fff;
      border-radius: 5px;
      content: '';
      height: 2px;
      left: 0;
      margin-top: -6px;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }
`
