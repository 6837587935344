import { ParsedUrlQuery } from 'querystring'

import { noLayoutConfig } from './NoLayout'
import { primaryLayoutConfig } from './PrimaryLayout'
import { PageLayoutConfig } from './types'

export interface WithLayoutConfig {
  layoutConfig?: PageLayoutConfig
}

export const getLayoutConfigForPageView = ({
  query,
  pageComponent,
}: {
  query: ParsedUrlQuery
  pageComponent: WithLayoutConfig
}): PageLayoutConfig => {
  const forcePlainLayout = query?.plain
  const layoutConfig = forcePlainLayout
    ? noLayoutConfig
    : pageComponent.layoutConfig ?? primaryLayoutConfig
  return layoutConfig
}
