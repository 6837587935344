import type { ProductAsset } from '../../types/graphql'

/**
 * Removes leading “Women’s” and “Men’s” text
 */
export function degenderProductGroupTitle(title: string): string {
  return title.replace(/^(?:wo)?men(?:'|’)s\s/i, '')
}

/** Gallery images in our data are defined as those images where the "kind" matches the "color" */
export function getProductGalleryImages(images: readonly ProductAsset[]): ProductAsset[] {
  return images.filter(
    image => image.color && image.kind && image.color.toLowerCase() === image.kind.toLowerCase()
  )
}
