import * as React from 'react'

import { disableScroll, enableScroll } from '../../lib/utils'
import { createContextHook } from '../../lib/utils/createContextHook'

interface ISearchOverlay {
  closeSearchOverlay(): void
  shouldDisplaySearchOverlay: boolean
  toggleSearchOverlay(): void
}

export const SearchOverlayContext = React.createContext<ISearchOverlay | undefined>(undefined)

export const SearchOverlayContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [shouldDisplaySearchOverlay, setShouldDisplaySearchOverlay] = React.useState<boolean>(false)

  const closeSearchOverlay = React.useCallback(() => {
    setShouldDisplaySearchOverlay(false)
  }, [])

  const toggleSearchOverlay = React.useCallback(() => {
    setShouldDisplaySearchOverlay(current => !current)
  }, [])

  React.useEffect(() => {
    if (shouldDisplaySearchOverlay) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [shouldDisplaySearchOverlay])

  return (
    <SearchOverlayContext.Provider
      value={{
        closeSearchOverlay,
        shouldDisplaySearchOverlay,
        toggleSearchOverlay,
      }}
    >
      {children}
    </SearchOverlayContext.Provider>
  )
}

export const useSearchOverlayContext = createContextHook(
  SearchOverlayContext,
  'SearchOverlayContext'
)
