import React from 'react'

import { Carat } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { NavTile as Props } from '@syconium/magnolia/src/types/graphql'

import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'

import { Body, Figcaption, ResponsiveImage } from './styles'

export const NavTile: React.FC<Props> = ({ ctaText, href, image }) => {
  const [action, category, label] = ['click tile', 'navigation', ctaText]

  return (
    <Body>
      <MaybeSpaLink {...{ href }} {...trackEvent({ action, category, label, value: 'image' })}>
        <ResponsiveImage
          alt={ctaText}
          loading='lazy'
          src={image}
          widths={{
            unit: 'px',
            sm: 256,
            md: 256,
          }}
          aspectRatios={{
            sm: 2 / 1,
            md: 2 / 1,
          }}
        />
        <Figcaption isUppercase={isUppercase(ctaText)}>
          {ctaText}
          <Carat />
        </Figcaption>
      </MaybeSpaLink>
    </Body>
  )
}
