import styled from 'styled-components'

import { ResponsiveImage as LMFResponsiveImage, fromMd } from '@syconium/little-miss-figgy'

export const Body = styled.footer`
  background: ${o => o.theme.color.deprecated.obsidian};
  color: white;
  padding: 56px 0 120px;
  top: 0;
  width: 100%;

  ${fromMd} {
    padding: 64px 0 52px;
  }
`

export const CaptureWrapper = styled.aside`
  position: relative;
  z-index: 1;
`

export const ResponsiveImage = styled(LMFResponsiveImage)`
  object-fit: fill;
  display: inline-block;
  height: 20px;
  min-width: 20px;
  width: auto;

  ${fromMd} {
    height: 12px;
  }
`

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
