import React, { ComponentType, FC, MouseEventHandler } from 'react'

import { AccentColorContainer } from '../../../containers/AccentColorContainer'
import { CtaButtonProps } from '../CtaButton/Template'

import { AddToBagButtonWrapper, ButtonComponentWrapper } from './styles'

type IProps = Omit<CtaButtonProps, 'accentColor' | 'aria-label'> & {
  ButtonComponent: ComponentType<CtaButtonProps>
  isReadyForSubmission?: boolean
}

export const AddToBagButton: FC<IProps> = ({
  ButtonComponent,
  children,
  isReadyForSubmission = true,
  onClick: providedOnClick,
  ...buttonPassthroughProps
}) => {
  const { accentColor } = AccentColorContainer.useContainer()

  const onClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (isReadyForSubmission) {
      /**
       * Note: if isReadyForSubmission is false it means the size and/or color
       * have not been selected. In this case running props.onClick(e)
       * triggers validation and prompts the user to select size/color.
       * Once size and color are selected, isReadyForSubmission will be true
       * and the animation will play next time they press the button.
       */
      if (providedOnClick) {
        providedOnClick(event)
      }
    }
  }

  return (
    <AddToBagButtonWrapper>
      <ButtonComponentWrapper>
        <ButtonComponent
          {...buttonPassthroughProps}
          onClick={onClick}
          accentColor={accentColor}
          aria-label='Add to Bag'
        >
          {children}
        </ButtonComponent>
      </ButtonComponentWrapper>
    </AddToBagButtonWrapper>
  )
}
