import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const CircleCheck: React.FC<IProps> = ({
  height = '24px',
  fill = '#282828',
  width = '24px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <svg
        {...{ height, width }}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle fill={fill} cx='12' cy='12' r='12' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.58503 11.7187L5.6457 10.658L9.96358 14.976L17.9697 6.96986L19.0304 8.03052L9.96356 17.0974L4.58503 11.7187Z'
          fill='white'
        />
      </svg>
    </BaseIcon>
  )
}
