import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_PRODUCT_REVIEWS = gql(`
  query getProductReviews(
    $shopifyProductId: String!
    $pageNum: Int!
    $numPerPage: Int!
    $star: Int
    $sort: String
    $sortDir: String
  ) {
    getReviews(
      productId: $shopifyProductId
      pageNum: $pageNum
      numPerPage: $numPerPage
      star: $star
      sort: $sort
      sortDir: $sortDir
    ) {
      averageScore
      totalReviews
      averageFit
      tag
      starDistribution {
        oneStarRatings
        twoStarRatings
        threeStarRatings
        fourStarRatings
        fiveStarRatings
      }
      pagination {
        pageNum
        numPages
        numPerPage
      }
      reviews {
        reviewer
        title
        content
        createdAt
        score
        customFields {
          key
          title
          value
        }
        imageUrls
      }
    }
  }
`)
