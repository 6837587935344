import { useRouter } from 'next/router'
import * as React from 'react'
import FocusLock from 'react-focus-lock'

import {
  MediaAsContents,
  Search as SearchIcon,
  SelectMark as SelectMarkIcon,
  useViewportBreakpoints,
} from '@syconium/little-miss-figgy'
import { useSearchOverlayContext } from '@syconium/magnolia/src/containers/SearchOverlayContext'
import { SearchAutocompleteProduct } from '@syconium/magnolia/src/types/graphql'

import { NoResultsText } from '../../SearchPage/styles'
import { SearchOverlayResults } from '../SearchOverlayResults'

import {
  A11bNumberOfResults,
  ClearButton,
  CloseButton,
  ErrorText,
  Form,
  IconWrapper,
  Input,
  InputBox,
  ResultsDropdown,
  ResultsWrapper,
  SearchButton,
  SearchWrapper,
} from './styles'

interface IProps {
  autoCompleteResults: SearchAutocompleteProduct[]
  handleFormChange(event: React.ChangeEvent<HTMLInputElement>): void
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void
  inputPlaceholder: string
  inputRef: React.RefObject<HTMLInputElement>
  isInputInvalid: boolean
  onClearFormClick: () => void
  onClickSearchOverlayProduct?: ({
    id,
    query,
  }: {
    id: string
    query: string
  }) => void | (() => void)
  popularSearchResults: string[]
  popularSearchTitle: string
  resetForm(): void
  resultsInFrontOfBackdrop: boolean
  searchButtonAriaLabelClear: string
  searchButtonAriaLabelSubmit: string
  searchTextError: string | undefined
  searchTextValue: string
  hasError?: boolean
  showNoResultsMessage?: boolean
}

export const Template = React.memo<IProps>(
  ({
    autoCompleteResults,
    handleFormChange,
    handleSubmit,
    hasError,
    inputPlaceholder,
    inputRef,
    isInputInvalid,
    onClearFormClick,
    onClickSearchOverlayProduct,
    popularSearchResults,
    popularSearchTitle,
    resetForm,
    resultsInFrontOfBackdrop,
    searchButtonAriaLabelClear,
    searchButtonAriaLabelSubmit,
    searchTextError,
    searchTextValue: searchText,
    showNoResultsMessage,
  }) => {
    const isSearching = searchText.length > 0
    const route = useRouter()
    const device = useViewportBreakpoints()
    const isSearchPage = route && route.pathname.includes('search')
    const isDropDownMenuClosed = popularSearchResults.length === 0
    const { shouldDisplaySearchOverlay } = useSearchOverlayContext()
    const isMobileView = device === 'small'

    return (
      <SearchWrapper isSearching={isSearching} isSearchPage={isSearchPage}>
        <FocusLock
          disabled={
            (isSearchPage && isDropDownMenuClosed) || (isDropDownMenuClosed && isMobileView)
          }
        >
          <SearchWrapper isSearching={isSearching} isSearchPage={isSearchPage}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit} role='search'>
              <InputBox isInvalid={isInputInvalid}>
                <Input
                  aria-label={inputPlaceholder}
                  name='searchText'
                  onChange={handleFormChange}
                  placeholder={inputPlaceholder}
                  ref={inputRef}
                  value={searchText}
                />
                <MediaAsContents lessThan='md'>
                  <SearchButton
                    aria-label={searchButtonAriaLabelSubmit}
                    onClick={() => resetForm()}
                    type='button'
                  >
                    <IconWrapper>
                      <SearchIcon />
                    </IconWrapper>
                  </SearchButton>
                  {isSearching && (
                    <ClearButton
                      aria-label={searchButtonAriaLabelClear}
                      onClick={() => resetForm()}
                      type='button'
                    >
                      <IconWrapper>
                        <SelectMarkIcon height='26px' width='26px' viewbox='-5 -5 20 20' />
                      </IconWrapper>
                    </ClearButton>
                  )}
                </MediaAsContents>

                <MediaAsContents greaterThanOrEqual='md'>
                  <SearchButton
                    aria-label={
                      isSearching ? searchButtonAriaLabelClear : searchButtonAriaLabelSubmit
                    }
                    onClick={() => resetForm()}
                    type='button'
                  >
                    <IconWrapper>
                      {isSearching ? (
                        <SelectMarkIcon height='26px' width='26px' viewbox='-5 -5 20 20' />
                      ) : (
                        <SearchIcon />
                      )}
                    </IconWrapper>
                  </SearchButton>
                  {shouldDisplaySearchOverlay && (
                    <CloseButton
                      aria-label={searchButtonAriaLabelClear}
                      onClick={() => resetForm()}
                      type='button'
                    >
                      <SelectMarkIcon height='26px' width='26px' viewbox='-5 -5 20 20' />
                    </CloseButton>
                  )}
                </MediaAsContents>
              </InputBox>
              {searchTextError ? (
                <ErrorText role='alert' aria-live='assertive' isInvalid={isInputInvalid}>
                  {searchTextError}
                </ErrorText>
              ) : null}
            </Form>
            {showNoResultsMessage && <NoResultsText>No results for '{searchText}'</NoResultsText>}
            <ResultsWrapper>
              <A11bNumberOfResults role='alert'>{`${autoCompleteResults.length} results`}</A11bNumberOfResults>
              <ResultsDropdown resultsInFrontOfBackdrop={resultsInFrontOfBackdrop}>
                <SearchOverlayResults
                  {...{
                    autoCompleteResults,
                    hasError,
                    onClearFormClick,
                    onClickSearchOverlayProduct,
                    popularSearchResults,
                    popularSearchTitle,
                    searchText,
                  }}
                />
              </ResultsDropdown>
            </ResultsWrapper>
          </SearchWrapper>
        </FocusLock>
      </SearchWrapper>
    )
  }
)
