import { StringifiableRecord } from 'query-string'
import { useCallback } from 'react'

import { SizeChartModalService } from '@syconium/magnolia/src/brunswick/containers/SizeChartModalService'
import { useSelectVariant } from '@syconium/magnolia/src/lib/hooks/useSelectVariant'

import { useSignalTracking } from '../../../../containers/SignalContainer'
import { useProductHref } from '../../../../lib/routing'
import { Fit } from '../../../constants'
import { useFixturesContext } from '../../../containers/fixtures'

import type { Props, ViewModel } from './types'

export function useViewModel({ closeModal, quickBuyState }: Props): ViewModel {
  const { openSizeChartModal } = SizeChartModalService.useContainer()
  const { trackProductInteraction } = useSignalTracking()
  const {
    quickBuy: { modal: quickBuyModalCopy },
  } = useFixturesContext()
  const {
    analyticsContext,
    availableProducts,
    currentSize,
    embroideryConfig,
    initialSelectedColor,
    modalState,
    productGroup,
    sizeChart,
    showColorSelector,
    swPromoEligible,
    variants,
  } = quickBuyState
  const isModalOpen = modalState === 'Open'

  const revealSizeChartModal = useCallback(() => {
    sizeChart && openSizeChartModal(sizeChart)
  }, [sizeChart, openSizeChartModal])

  const selectedVariant = {
    ...useSelectVariant({
      availableProducts,
      coloredAndFitVariants: variants,
      embroideryConfig,
      initialFeaturedProduct: productGroup.featuredProduct,
      initialSelectedColor,
      onCompleteAddToCart: closeModal,
      onCompleteViewProduct: closeModal,
      productGroup,
      shouldResetSelections: isModalOpen,
      swPromoEligible,
    }),
  }

  const query: StringifiableRecord = {
    color: selectedVariant.selectedColor?.name,
    fit:
      selectedVariant.availableFitsKeys && selectedVariant.availableFitsKeys.length > 0
        ? Fit.REGULAR
        : undefined,
  }

  const pdpLinkPath = useProductHref(selectedVariant.productHandle, query)

  const onProductView = () => {
    trackProductInteraction({
      handle: selectedVariant.productHandle,
      shopifyId: selectedVariant.productShopifyId,
      id: selectedVariant.productId,
    })
    closeModal()
  }

  return {
    ...selectedVariant,
    analyticsContext,
    category: productGroup.featuredProduct?.category ?? '',
    currentSize: selectedVariant.currentSize ?? currentSize,
    closeModal,
    isModalOpen,
    pdpLinkPath,
    modalTitle: quickBuyModalCopy.headline,
    openSizeChartModal: revealSizeChartModal,
    sizeChart,
    showColorSelector,
    onProductView: onProductView,
  }
}
