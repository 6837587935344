import { ShopRegion, SupportedLocaleIdentifier, supportedRegions } from '@syconium/weeping-figs'

import { formatPrettyCurrency } from './unorganized'

type IntlPrettyCurrencyProps = {
  cents: number | undefined
  region: ShopRegion
  locale: SupportedLocaleIdentifier
  truncateTrailingZeros?: boolean
  explicitFormat?: boolean
}

export const intlPrettyCurrency = ({
  cents = 0,
  region,
  locale,
  truncateTrailingZeros = false,
  explicitFormat = false,
}: IntlPrettyCurrencyProps): string => {
  const computedCurrency = region?.currency ?? supportedRegions.default.currency
  const computedLocale = locale ?? supportedRegions.default.defaultLocale

  return formatPrettyCurrency(cents, computedCurrency, computedLocale, {
    truncateTrailingZeros,
    explicitFormat,
  })
}
