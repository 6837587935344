import { Media } from '@syconium/little-miss-figgy'

import { NavTabBar } from '../NavTabBar'

import { NavGhostProxy, Right } from './styles'
import { useIconTabs } from './useIconTabs'

/**
 * The icon container for the NavHeader.
 *
 * Responsible for rendering the icon buttons within the header (i.e. cart, profile, language).
 *
 * @param props
 * @param [props.freezeTabId] - A unique ID for the tab bar, used to indicate which tab is active & should be frozen in NavTabBar.
 */
export function NavHeaderIcons({ freezeTabId }: { freezeTabId?: string }) {
  const { desktopIconTabs, mobileIconTabs } = useIconTabs()
  return (
    <NavGhostProxy>
      <Right>
        <Media lessThan='md'>
          <NavTabBar freezeTabId={freezeTabId} tabs={mobileIconTabs} />
        </Media>
        <Media greaterThanOrEqual='md'>
          <NavTabBar freezeTabId={freezeTabId} tabs={desktopIconTabs} />
        </Media>
      </Right>
    </NavGhostProxy>
  )
}
