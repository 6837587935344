import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FetchShopTheLook = gql(`
  query FetchShopTheLook($handle: String!) {
    product(handle: $handle) {
      shopTheLook {
        nodes {
          color
          colorInfo {
            availability
            name
            rawName
          }
          fit
          swPromoEligible
          waitlistable
          finalSale
          handle
          images {
            nodes {
              kind
              source
              color
            }
          }
          priceRange
          productGroup {
            id
            colorGroups {
              ...ColorGroup
            }
            colors {
              availability
              hexCodes
              name
              rawName
              swatchUrl
            }
            defaultVariant {
              priceDetails {
                discountPrice {
                  amount
                }
                price {
                  amount
                }
              }
            }
            description
            fits
            handle
            productsForAvailability: products {
              availableSizes
              colorInfo {
                rawName
              }
              rawFit
              swPromoEligible
            }
            rawFits
            sizes
            style
            sizeChart: sizeChartCG {
              columnNames
              columnValues
              imageUri
              name
              navLabel
              tables {
                cells
                measureImage
                measureTips {
                  kind
                  tip
                }
                sizeTips {
                  kind
                  tip
                }
                subtitle
                tabLabel
                title
              }
              tips {
                kind
                tip
              }
              title
              video
            }
          }
          variants {
            category
            color
            fit
            priceDetails {
              discountPrice {
                amount
              }
              price {
                amount
                currency
              }
            }
            rawColor
            shopifyId
            size
            sku
            soldOut
          }
        }
      }
    }
  }
`)
