import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { useSearchParam } from 'react-use'

import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'
import { CartContainer } from '../../brunswick/containers/cart'
import { CartServiceAddItemArgs } from '../../brunswick/interfaces/remote-data/cart'
import { FETCH_CART_VARIANTS } from '../../lib/graphql/queries'
import { FetchVariantsResponse } from '../../types/graphql'

export type UseCartRecoveryOpts = {
  /** @param {string} [queryKey] - The key of the cart recovery query parameter. This value of this parameter is expected to be a comma-separated list of SKU's that will be added to the cart on page load. */
  queryKey?: string
}

/**
 * Recover cart items specified in the URL & add to the local cart.
 */
export function useCartRecovery({ queryKey = 'cart' }: UseCartRecoveryOpts = {}) {
  const r = useSearchParam(queryKey)
  const { addItems, cart, updateInProgress } = CartContainer.useContainer()

  // The initial comma-separated string of SKU's from the cart recovery URL parameter
  // This will be set to null once all items in the query are recovered
  const [recoveryQuery, setRecoveryQuery] = useState<string | null>(r)

  // Determines if recovery of cart items is pending or not.
  // This will be set to false once the persisted cart is updated
  // and there is no more applicable SKU's to be recovered.
  const [isPending, setIsPending] = useState(Boolean(recoveryQuery))

  // Get applicable skus that are present in the recovery query
  const recoveredSkus = useMemo(() => {
    if (recoveryQuery && isPending === true && updateInProgress === false) {
      const s = (recoveryQuery as string).split(',')
      // Filter out skus that are already in the cart
      return s.filter(variant => {
        const alreadyExists = Object.values(cart.items).some(item => item.sku === variant)
        return !alreadyExists
      })
    }
    return []
  }, [recoveryQuery, cart.items, updateInProgress, isPending])

  // Fetch variants for pending skus
  const { error } = useQuery<FetchVariantsResponse>(FETCH_CART_VARIANTS, {
    variables: { skus: recoveredSkus },
    skip: recoveredSkus.length === 0,
    ssr: false,
    onCompleted: data => {
      // create local cart item objects from response, excluding embroidery
      const cartItems: CartServiceAddItemArgs[] = data.variants.nodes
        .filter(variant => variant.category.toLowerCase() !== 'embroidery')
        .map(variant => ({
          finalSale: variant.product.finalSale,
          item: variant,
          properties: {},
          quantity: 1,
          swPromoEligible: variant.product.swPromoEligible,
        }))

      // Add the pending cart items to the cart & reset the recovery query
      addItems(cartItems)
      setRecoveryQuery(null)
      setIsPending(false)
    },
    onError: error => {
      reportClientError({
        error,
        context: {
          scope: 'Cart Recovery Error',
        },
      })
      setIsPending(false)
    },
  })

  return { pending: isPending, error, cart }
}
