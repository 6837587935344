import * as React from 'react'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const SearchOverlayForm = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  {
    focusOnMount?: boolean
    initialSearchText?: string
    isMobileSearch?: boolean
    onAutoComplete?: (hasResults: boolean) => void
    onClearFormClick?: () => void
    onClickSearchOverlayProduct?: () => void
    onFormSubmit: (searchText: string) => void
    resultsInFrontOfBackdrop?: boolean
    shouldDisplaySearchOverlay: boolean
  }
>(
  (
    {
      focusOnMount = false,
      initialSearchText = '',
      isMobileSearch,
      onAutoComplete,
      onClearFormClick,
      onClickSearchOverlayProduct,
      onFormSubmit,
      resultsInFrontOfBackdrop = false,
      shouldDisplaySearchOverlay,
    },
    ref
  ) => {
    const viewModel = useViewModel({
      focusOnMount,
      initialSearchText,
      isMobileSearch,
      onAutoComplete,
      onClearFormClick,
      onClickSearchOverlayProduct,
      onFormSubmit,
      ref,
      shouldDisplaySearchOverlay,
    })

    return <Template resultsInFrontOfBackdrop={resultsInFrontOfBackdrop} {...viewModel} />
  }
)
