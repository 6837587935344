import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const SwatchCheckmark: React.FC<IProps> = ({
  height = '18px',
  fill = 'white',
  width = '18px',
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={`-5 -7 20 20`}>
      <path
        d='M0.375713 3.18538L1.22425 2.33687L3.84263 4.95531L8.77571 0.0222307L9.62424 0.870759L3.84262 6.65238L0.375713 3.18538Z'
        fill={fill}
      />
    </BaseIcon>
  )
}
