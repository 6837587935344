import Link from 'next/link'
import { FC } from 'react'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { PortalConfigContainer } from '@syconium/magnolia/src/brunswick/containers/PortalConfigContainer'

import { IProps } from './IProps'
import { Container, PortalLink, StyledColorAttribute } from './styles'

export const MultiColorsSection: FC<IProps> = ({
  colorGroups,
  hasSelectedSize,
  isCurrentColorCompletelySoldOut,
  isProductWaitlistable,
  onColorSelect,
  selectedColorKey,
  retainColorGroupLabel = false,
}) => {
  const {
    detailsSection: { colorTitle },
  } = useFixturesContext()

  const { portal } = PortalConfigContainer.useContainer()

  return (
    <Container data-testid='color-swatch-picker'>
      {colorGroups.map(
        cg =>
          cg.colors.length > 0 && (
            <StyledColorAttribute
              key={cg.label}
              title={colorGroups.length === 1 && !retainColorGroupLabel ? colorTitle : cg.label}
              selected={cg.colors.find(({ rawName }) => rawName === selectedColorKey)?.rawName}
              label={cg.label}
              onSelect={onColorSelect}
              colors={cg.colors}
              withTooltip={retainColorGroupLabel || colorGroups.length > 1}
              soldOut={
                isCurrentColorCompletelySoldOut && !hasSelectedSize && !isProductWaitlistable
              }
            >
              {portal && cg.label === `${portal.name} Uniform` && (
                <Link href={`/portals/${portal.collectionHandle}`} legacyBehavior passHref>
                  <PortalLink href={`/portals/${portal.collectionHandle}`}>
                    {portal.pdpCtaText ?? `Shop ${portal.name} Collection`}
                  </PortalLink>
                </Link>
              )}
            </StyledColorAttribute>
          )
      )}
    </Container>
  )
}
