export function extractScripts(rawHtml: string): {
  htmlWithoutScripts: string
  scriptTexts: string[]
} {
  let html = rawHtml
  const scriptTexts: string[] = []

  if (html) {
    // Regex + HTML https://stackoverflow.com/a/1732454/161182
    const tags = html.match(/<script[^>]*>[\s\S]*?<\/script>/g)
    if (tags) {
      tags.forEach(tag => {
        const text = tag.match(/<script[^>]*>([\s\S]*)<\/script>/)![1]
        if (text) scriptTexts.push(text)
        html = html.replace(tag, '')
      })
    }
  }

  return {
    htmlWithoutScripts: html,
    scriptTexts,
  }
}
