'use client'

import styled from 'styled-components'

import { IProps } from './types'

export const BaseIcon = styled.svg<IProps>(({ fill, rotateClockwise, transition }) => ({
  fill: fill ?? 'currentColor',
  transform: rotateClockwise && `rotateZ(${rotateClockwise})`,
  transition: transition && `transform ${transition}`,
}))
