import styled from 'styled-components'

import {
  DeprecatedText,
  ResponsiveImage as LMFResponsiveImage,
  fromMd,
} from '@syconium/little-miss-figgy'

import { NextModalStyles } from '../../../../components/modals/NextModal'
import { ModalCloseButton } from '../../ModalCloseButton'

export const modalStyles: NextModalStyles = {
  content: {
    borderRadius: 0,
    borderWidth: 0,
    bottom: 'initial',
    boxSizing: 'border-box',
    left: 'initial',
    overflow: 'initial',
    padding: 'initial',
    position: 'initial',
    right: 'initial',
    top: 'initial',
  },
  overlay: {
    backgroundColor: undefined,
  },
}

export const CloseButton = styled(ModalCloseButton)`
  ${fromMd} {
    padding: 26px;
  }
`

export const Header = styled.div<{
  backgroundColor?: string
  hasBottomBorder?: boolean
}>`
  background: ${o => o.backgroundColor || o.theme.color.deprecated.stoneLighter};
  border-bottom: ${o =>
    o.hasBottomBorder ? `1px solid ${o.theme.color.deprecated.stoneLighter}` : 'none'};
  padding: 20px;
  position: sticky;

  ${fromMd} {
    min-height: 64px;
    padding: 0;
  }
`

export const HeaderText = styled(DeprecatedText)`
  ${fromMd} {
    line-height: 68px;
  }
`

export const WantToKnowText = styled.p`
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.75px;
  padding: 40px 20px;
  text-align: center;

  ${fromMd} {
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: 0.5px;
    padding: 40px 0;
  }
`

export const Content = styled.div`
  overflow-y: auto;
`

export const TableWrapper = styled.div`
  flex: none;
  margin: 0 0 32px;
  overflow-y: auto;
  width: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
  }
`

export const Table = styled.table`
  color: ${o => o.theme.color.deprecated.gray.darkest};
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  width: 100%;

  ${fromMd} {
    border: 48px solid white;
    border-width: 0 48px;
  }
`

export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
`

export const Th = styled.th`
  background-color: ${o => o.theme.color.deprecated.stoneLighter};
  font-weight: bold;
  padding: 14px 12px;
  vertical-align: middle;
`

export const Td = styled.td`
  padding: 10px 12px;
  white-space: nowrap;
`

export const TipsSection = styled.section`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  color: ${o => o.theme.color.deprecated.gray.dark};
`

export const TipsSectionContent = styled.div`
  padding: 36px 20px 32px;

  ${fromMd} {
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 8px 40px 16px 48px;
    width: 600px;
  }
`

export const TipsSectionTextBlock = styled.div`
  ${fromMd} {
    width: 280px;
  }
`

export const HowToMeasureText = styled.h3`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.75px;
  padding: 0 0 12px;
`

export const TipItem = styled.li`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.7px;
  padding: 12px 0 12px 24px;
  position: relative;
`

export const TipCheckMark = styled.div`
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 16px;
`

export const ResponsiveImage = styled(LMFResponsiveImage)`
  margin: 24px auto 0;
  width: 100%;

  ${fromMd} {
    margin: 0 auto;
    height: 263px;
    width: auto;
  }
`
export const Body = styled.div`
  overflow: auto;

  ${fromMd} {
    max-height: 768px;
  }
`
