import Head from 'next/head'
import React, { useMemo, useTransition } from 'react'

import { useCollectionFilterQueryParamsContext } from '@syconium/magnolia/src/brunswick/containers/CollectionFilterQueryParamsContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { VisualFilterTile } from '@syconium/magnolia/src/types/graphql'

import { useCollectionFiltersContext } from '../../../containers/CollectionFiltersContext'
import { coreStylesVisibleTilesDesktop, coreStylesVisibleTilesMobile } from '../VisualFilters'

import {
  Body,
  FilterCtaText,
  FilterDescription,
  Image,
  ImageDisabled,
  ImageWrapper,
} from './styles'

interface Props extends VisualFilterTile {
  rollingFilters: string[]
  toggleRollingFilter: (filter: string) => void
  imagePriority: 'preload' | 'lazy' | 'default'
}

export const CoreStylesVisualFilterSectionTile: React.FC<Props> = ({
  ctaText,
  image,
  id,
  value,
  filter: filterFromField,
  filterSpec,
  toggleRollingFilter,
  rollingFilters,
  description,
  imagePriority = 'default',
}) => {
  const { updateFilterSelection } = useCollectionFilterQueryParamsContext()
  const { filters } = useCollectionFiltersContext()
  const { selectedFilters } = useCollectionFilterQueryParamsContext()
  const [isUpdateFiltersTransitionPending, startUpdateFiltersTransition] = useTransition()
  const isChecked = useMemo(() => {
    const includedInFilters = Object.values(selectedFilters).flat().includes(value)
    return isUpdateFiltersTransitionPending ? !includedInFilters : includedInFilters
  }, [isUpdateFiltersTransitionPending, selectedFilters, value])
  const filter = filterSpec?.name || filterFromField
  const getVisualFilterType = useMemo(
    () => filters?.find(f => f.displayName.toLocaleLowerCase() === filter),
    [filters, filter]
  )

  const isDisabled = useMemo(
    () => getVisualFilterType?.filterValues?.some(o => o.value === value && o.state === 'DISABLED'),
    [getVisualFilterType, value]
  )

  const toggleChecked = () => {
    if (isUpdateFiltersTransitionPending) return
    startUpdateFiltersTransition(() => {
      updateFilterSelection(filter, value, !isChecked)
      toggleRollingFilter(value)
    })
  }
  return (
    <Body>
      {!isDisabled ? (
        <label
          key={id}
          htmlFor={`checkbox-${id}`}
          {...trackEvent({
            customAttributes: {
              'data-heap-rolling-filters': rollingFilters.join(','),
              'data-heap-visual-filter-value': value,
              'data-heap-filter-type': 'visual',
            },
            category: 'page-tile-section-tile',
            action: 'click',
            label: value,
          })}
        >
          <input
            type='checkbox'
            id={`checkbox-${id}`}
            style={{ display: 'none' }}
            checked={isChecked}
            onChange={toggleChecked}
          />
          <ImageWrapper>
            <Image
              isChecked={isChecked}
              alt={ctaText}
              loading={imagePriority === 'lazy' ? 'lazy' : 'default'}
              src={image}
              aspectRatios={{
                sm: 20 / 26,
                md: 20 / 26,
              }}
              widths={{
                unit: 'vw',
                sm: 100 / coreStylesVisibleTilesMobile,
                md: 100 / coreStylesVisibleTilesDesktop,
              }}
              fetchpriority={imagePriority === 'preload' ? 'high' : 'auto'}
              renderPreloadLink={
                imagePriority === 'preload'
                  ? preloadLink => {
                      return <Head>{preloadLink}</Head>
                    }
                  : undefined
              }
            />
          </ImageWrapper>
        </label>
      ) : (
        <ImageDisabled
          alt={ctaText}
          src={image}
          loading={imagePriority === 'lazy' ? 'lazy' : 'default'}
          aspectRatios={{
            sm: 20 / 26,
            md: 20 / 26,
          }}
          widths={{
            unit: 'vw',
            sm: 100 / coreStylesVisibleTilesMobile,
            md: 100 / coreStylesVisibleTilesDesktop,
          }}
          fetchpriority={imagePriority === 'preload' ? 'high' : 'auto'}
          renderPreloadLink={
            imagePriority === 'preload'
              ? preloadLink => {
                  return <Head>{preloadLink}</Head>
                }
              : undefined
          }
        />
      )}

      <FilterCtaText
        isUppercase={isUppercase(ctaText)}
        isDisabled={isDisabled || false}
        isChecked={isChecked}
        onClick={() => {
          if (!isDisabled) toggleChecked()
        }}
      >
        {ctaText}
      </FilterCtaText>
      {description ? (
        <FilterDescription
          isUppercase={isUppercase(description)}
          isDisabled={isDisabled || false}
          isChecked={isChecked}
        >
          {description}
        </FilterDescription>
      ) : null}
    </Body>
  )
}
