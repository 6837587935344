import * as React from 'react'

export function isUppercase(text: string): boolean {
  // Not empty and has no lowercase letters.
  return /^[^a-z]+$/.test(text)
}

export const transformInitialsInput = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.value = ('' + event.currentTarget.value).toUpperCase()
}
