import { Nav } from '@syconium/weeping-figs'

import { SiteFixtures } from '../../../../../types/graphql'

import fallbackNavV1Json from './fallback-nav-v1.json'
import fallbackSiteFixturesJson from './fallback-site-fixtures.json'

export const fallbackNavV1 = fallbackNavV1Json as Nav

type SiteFixturesResponse = {
  data: {
    siteFixtures: SiteFixtures
  }
}

const fallbackSiteFixturesResponse = fallbackSiteFixturesJson as SiteFixturesResponse

export const fallbackSiteFixtures = fallbackSiteFixturesResponse.data.siteFixtures
