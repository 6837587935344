import { useMemo } from 'react'

import { AvailableProducts } from '@syconium/magnolia/src/lib/utils'

import { ProductColorGroupWithUnavailable } from '../../../types/figs'
import { ColorGroup } from '../../../types/graphql'

export function useColorGroupsWithUnavailable({
  availableProducts,
  colorGroups,
  colorName,
  currentFitKey,
  sizeName,
}: {
  availableProducts: AvailableProducts
  colorGroups: ReadonlyArray<ColorGroup>
  colorName: string | undefined
  currentFitKey: string
  sizeName?: string | null
}) {
  const colors = Object.keys(availableProducts)
  const colorsWithStock = colors.filter(color => {
    const availableSizes = availableProducts[color]?.[currentFitKey] ?? []
    return availableSizes.some(sizes => (sizes || []).length > 0)
  })

  const isCurrentColorCompletelySoldOut: boolean = Boolean(
    colorName && !colorsWithStock.includes(colorName)
  )

  const colorsGroupsWithUnavailable: ProductColorGroupWithUnavailable[] = useMemo(() => {
    if (sizeName) {
      /**
       * If the user HAS selected a size we want each color to show as sold-out
       * if the variant of that color and selected size is sold-out.
       */
      return colorGroups.map(o => ({
        ...o,
        colors: o.colors.map(c => {
          const availableSizes = (availableProducts[c.rawName] ?? {})[currentFitKey] || []
          return { ...c, unavailable: !availableSizes.includes(sizeName) }
        }),
      }))
    }
    /**
     * If the user has NOT selected a size we want each color to show as
     * sold-out if every variant of that color is sold out.
     */
    return colorGroups.map(o => ({
      ...o,
      colors: o.colors.map(c => ({ ...c, unavailable: !colorsWithStock.includes(c.rawName) })),
    }))
  }, [availableProducts, colorGroups, colorsWithStock, currentFitKey, sizeName])

  return {
    colorsGroupsWithUnavailable,
    isCurrentColorCompletelySoldOut,
  }
}
