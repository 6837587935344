import styled, { css } from 'styled-components'

import { fromMd, timingDefault } from '@syconium/little-miss-figgy'

export const searchOverlayMaxWidth: string = '993px'

const focusStyles = css`
  outline: none;
  border: 2px solid #007bff;
`

export const Form = styled.form`
  box-sizing: border-box;
  max-width: ${searchOverlayMaxWidth};
  padding: 0 10px 20px 10px;
  width: 100%;
  position: relative;

  ${fromMd} {
    padding: 0 40px;
    width: 80%;
    margin: auto;
  }
`

export const InputBox = styled.div<{
  isInvalid: boolean
}>`
  border-bottom: 1px solid black;
  border-bottom-color: ${o => o.isInvalid && o.theme.color.deprecated.red.error};
  position: relative;
  transition: border-color ${timingDefault} ease;

  ${fromMd} {
    border-bottom-width: 2px;
  }
`

export const Input = styled.input`
  border: none;
  box-sizing: border-box;
  font-family: ${o => o.theme.typography.deprecated.font.family.primary};
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  letter-spacing: 0.6px;
  line-height: 18px;
  outline: none;
  padding-top: 12px;
  padding-bottom: 0;
  padding-inline-end: 12px;
  padding-inline-start: 28px;
  width: 100%;

  ${fromMd} {
    font-size: 20px;
    padding: 0 32px;
  }

  ::placeholder {
    color: ${o => o.theme.color.deprecated.obsidianLighter};
  }
`

export const SearchWrapper = styled.div<{
  isSearching: boolean
  isSearchPage: boolean
}>`
  overflow: ${o => (o.isSearchPage ? 'hidden' : o.isSearching ? 'auto' : 'hidden')};
  width: 100%;

  ${fromMd} {
    overflow: visible;
  }
`

export const ErrorText = styled.p<{
  isInvalid: boolean
}>`
  color: ${o => o.theme.color.deprecated.red.error};
  font-size: 11px;
  font-weight: bold;
  height: 11px;
  margin: 8px 0 0 12px;
  opacity: ${o => (o.isInvalid ? 1 : 0)};
  transition: opacity ${timingDefault} ease;

  ${fromMd} {
    font-size: 13px;
    height: 13px;
    margin: 12px 0 0 32px;
  }
`

export const SearchButton = styled.button`
  height: 100%;
  inset-inline-start: 0;
  outline: none;
  position: absolute;
  width: 26px;

  &:focus {
    ${focusStyles}
  }

  ${fromMd} {
    inset-inline-start: auto;
    inset-inline-end: 0;
    &:hover {
      svg > g {
        stroke: ${o => o.theme.color.deprecated.gray.dark};
      }
    }
  }
`
export const ClearButton = styled(SearchButton)`
  left: auto;
  right: 0;
  &:focus {
    ${focusStyles}
  }
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: -10%;
  top: -100%;
  &:focus {
    ${focusStyles}
  }
  ${fromMd} {
    display: none;
  }
`

export const IconWrapper = styled.div`
  align-items: flex-end;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${fromMd} {
    align-items: center;
  }
`

export const ResultsWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const A11bNumberOfResults = styled.div`
  height: 0;
  opacity: 0;
  width: 0;
  padding-top: 8px;
`

export const ResultsDropdown = styled.div<{
  resultsInFrontOfBackdrop: boolean
}>`
  background-color: white;
  position: ${o => o.resultsInFrontOfBackdrop && 'absolute'};
  left: 0;
  right: 0;
  top: 0;
`
