import { animated } from 'react-spring'
import styled from 'styled-components'

import { ResponsiveImage, fromMd, timingSlower } from '@syconium/little-miss-figgy'
import { fadeIn } from '@syconium/magnolia/src/brunswick/styles/shared'

import { searchOverlayMaxWidth } from '../SearchOverlayForm/styles'

// TODO comment to explain 148px(changed to 176px to have a gap) and 248px
export const Container = styled(animated.div)`
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
  box-sizing: border-box;
  margin: auto;
  max-height: calc(100vh - 176px);
  max-width: ${searchOverlayMaxWidth};
  width: 100%;

  ${fromMd} {
    max-height: calc(100vh - 248px);
    overflow: auto;
  }
`

export const AutoCompleteResults = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 32px 16px;

  ${fromMd} {
    margin: 56px 48px 0;
  }
`

export const ProductTile = styled.li`
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  ${fromMd} {
    margin-bottom: 36px;
    width: 50%;
  }

  > a {
    display: flex;
  }
`

export const imageSize = 80

export const StyledResponsiveImage = styled(ResponsiveImage)`
  height: ${imageSize}px;
  min-width: ${imageSize}px;
  width: ${imageSize}px;
`

export const ProductDetails = styled.div`
  padding-inline-start: 20px;

  ${fromMd} {
    padding: 0 36px;
  }
`

export const ProductName = styled.p`
  color: ${o => o.theme.color.deprecated.gray.medium};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 1.5;
  padding-top: 2px;
`

export const Prices = styled.div`
  font-size: 12px;
  padding-top: 4px;
`

export const EffectivePrice = styled.span<{
  isDiscounted: boolean
}>`
  color: ${o => o.isDiscounted && o.theme.color.deprecated.red.error};
`

export const CompareAtPrice = styled.span`
  color: ${o => o.theme.color.deprecated.gray.medium};
  text-decoration: line-through;
`

export const PopularSearchResults = styled.div`
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
  border-top: 2px solid ${o => o.theme.color.deprecated.stoneLight};
  margin: 16px 32px 28px;
  padding: 28px 0 56px;

  ${fromMd} {
    border: none;
    margin: 16px 48px 0;
    text-align: center;
  }
`

export const PopularSearchTitle = styled.h3`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
  }
`

export const PopularSearchItem = styled(animated.li)`
  font-weight: 700;
  line-height: 1.33;
  margin-bottom: 12px;

  ${fromMd} {
    margin-bottom: 4px;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 80%;

  ${fromMd} {
    margin: auto;
    max-width: ${searchOverlayMaxWidth};
  }
`

export const ErrorMessage = styled.div`
  color: red;
  padding: 0 40px;
`
