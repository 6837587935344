import React, { FC } from 'react'

import { NavListGroupSection as IProps } from '@syconium/magnolia/src/types/graphql'

import { NavListSection } from '../../../nav/NavListSection'

import { Wrapper } from './styles'

export const ContentfulNavListGroupSection: FC<IProps> = ({ navSections }) => {
  return (
    <Wrapper groupSize={navSections.length}>
      {navSections.map(section => (
        <NavListSection key={section.id} {...section} />
      ))}
    </Wrapper>
  )
}
