import { MutableRefObject, useEffect, useRef } from 'react'

export function doesElementContain(source?: HTMLElement, target?: HTMLElement): boolean {
  if (!source || !target) return false
  return source.contains(target)
}

/**
 * React to click/touch events outside of a given element(s).
 *
 * @param {MutableRefObject<HTMLElement | null> | Array<MutableRefObject<HTMLElement | null>>} refs - A react ref or an array of refs containing the target elements that should not trigger the callback when clicked.
 * @param {(e: Event) => void} cb - The callback to call when a click event occurs outside of the provided element(s).
 */
export function useOutsideClick(
  refs: MutableRefObject<HTMLElement | null> | Array<MutableRefObject<HTMLElement | null>>,
  cb: (e: Event) => void
) {
  const callback = useRef(cb)

  // Store reference to provided callback
  useEffect(() => {
    callback.current = cb
  }, [cb])

  useEffect(() => {
    const handleClick = (e: Event) => {
      const isOutside = (() => {
        // If array, check if any of the refs contain the target
        if (Array.isArray(refs)) {
          return refs.reduce((acc, ref) => {
            if (!ref || acc === false) return acc
            return !doesElementContain(ref.current ?? undefined, e.target as HTMLElement)
          }, true)
        }

        // If not array, check if the ref contains the target
        return !doesElementContain(refs.current ?? undefined, e.target as HTMLElement)
      })()

      if (isOutside) callback.current(e)
    }

    // Attach event listeners for the click/touchstart events
    document.addEventListener('pointerdown', handleClick)

    return () => {
      // Destroy event listeners for the click/touchstart events
      document.removeEventListener('pointerdown', handleClick)
    }
  }, [refs])
}
