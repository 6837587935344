import styled, { css } from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'

/**
 * `sectionGapMobile` and `sectionGapDesktop` are vertical.
 * Each section "owns" the gap BELOW itself.
 */
export const sectionGapMobile = 40
export const sectionGapDesktop = 100
export const sectionSideGapDesktop = 120 // when not full-bleed

/**
 * We set `box-sixing: border-box` so that `sectionSideGapDesktop` is subtracted
 * from `pageLayoutMaxWidth`.
 */
export const BasePageSection = styled.section<{
  isFullBleedDesktop?: boolean | null
  hasVerticalMargin?: boolean
}>`
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: ${o => o.hasVerticalMargin && `${sectionGapMobile}px`};

  ${fromMd} {
    padding: 0 ${o => (o.isFullBleedDesktop ? 0 : sectionSideGapDesktop)}px
      ${o => (o.hasVerticalMargin ? `${sectionGapDesktop}px` : 0)};
  }
`

export const titleSpacing = css<{ isUppercase: boolean }>`
  letter-spacing: ${o => (o.isUppercase ? '0.15em' : '0.05em')};
`
