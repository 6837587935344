import React, { ComponentProps } from 'react'

import { ExpansionPanel, Minus, Plus } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { useTargetCriteria } from '@syconium/magnolia/src/lib/hooks/useTargetCriteria'
import {
  AlternateModelVariantAction,
  NavAccordion as INavAccordion,
  NavAccordionSection as INavAccordionSection,
  NavListItem as INavListItem,
  NavAccordionVariant,
} from '@syconium/magnolia/src/types/graphql'

import { useBrowserStoredState } from '../../../lib/hooks/useBrowserStoredState'
import { NavListItem } from '../NavListItem'
import { renderNavSection } from '../renderNavSection'

import {
  Body,
  CaratIconWrapper,
  Header,
  PlusIconWrapper,
  Section,
  SectionBody,
  StyledCaret,
} from './styles'

type OnChangeCallback = ComponentProps<typeof ExpansionPanel.Container>['onChange']

export const NavAccordion: React.FC<INavAccordion> = ({ id, sections, startOpenSet, variant }) => {
  const { checkCriteria } = useTargetCriteria()
  return (
    <Body role='list'>
      {sections.map((section, index) => {
        /*
         * TODO Currently we can only support hiding the NavAccordionSection, the query complexity for GetSiteFixtures exceeds the maximum limit when including
         * the alternateModelVariant.validContentfulModel, which is required to support replacing the NavAccordionSection as there are several recursively nested components
         * within each navigation section.  In the future we can accomplish this by using the RSC pattern to fetch data for the nested components individually or alternativley
         * we can use the contentfulId to fetch the contentful model directly.
         */
        if (section.alternateModelVariant) {
          if (section.alternateModelVariant.action === AlternateModelVariantAction.hideOriginal) {
            if (checkCriteria(section.alternateModelVariant.targetCriteria)) {
              return null
            }
          }
        }
        return renderSection({
          accordionId: id,
          section,
          startOpen: !!startOpenSet?.includes(index),
          variant,
        })
      })}
    </Body>
  )
}

function renderSection({
  accordionId,
  section,
  startOpen,
  variant,
}: {
  accordionId: string
  section: INavAccordionSection
  startOpen: boolean
  variant?: NavAccordionVariant
}): React.ReactElement | null {
  return (
    <NavAccordionSection {...{ accordionId, startOpen, variant }} key={section.id} {...section} />
  )
}

interface MySectionProps extends INavAccordionSection {
  accordionId: string
  startOpen: boolean
  variant?: NavAccordionVariant
}

const NavAccordionSection: React.FC<MySectionProps> = ({
  accordionId,
  body,
  header,
  id,
  startOpen,
  variant,
}) => {
  const isOpenDisabled = !body
  const isFooterVariant = variant === 'footer'

  /**
   * First render per session, `isOpen = startOpen`. Then we use session storage.
   * Use session instead of local storage to reset state between visits and tabs.
   * Qualify storageKey by accordion id to avoid shared state for sections reused
   * in multiple accordions or if hard-coded section ids are not unique.
   */
  const { state, setState } = useBrowserStoredState<{ isOpen: boolean }>({
    initialState: { isOpen: startOpen && !isOpenDisabled },
    storage: globalThis.sessionStorage,
    storageKey: `NavAccordion:${accordionId}:NavAccordionSection:${id}`,
    ssr: true,
  })
  const isOpen = state.isOpen && !isOpenDisabled
  const setIsOpen = (x: boolean) => setState({ isOpen: x })

  const onChange: OnChangeCallback | undefined = isOpenDisabled
    ? undefined
    : (_event, x) => setIsOpen(x)

  const headerItem: INavListItem = {
    ...header,
    href: isOpenDisabled ? header.href : null,
    isBold: true,
    isHeading: isFooterVariant,
  }

  const expandIcon = isFooterVariant ? expandPlusIcon : expandCaratIcon

  return (
    <Section {...{ isFooterVariant, isOpen, isOpenDisabled }} role='listitem'>
      {body ? (
        <ExpansionPanel.Container {...{ onChange }} disabled={isOpenDisabled} isExpanded={isOpen}>
          <ExpansionPanel.Summary
            expandIcon={isOpenDisabled ? undefined : expandIcon}
            tabIndex={isOpenDisabled ? -1 : 0}
            {...(isOpenDisabled
              ? {}
              : trackEvent({
                  category: 'navigation',
                  action: `${isOpen ? 'close' : 'open'} accordion section`,
                  label: header?.text ? header.text : undefined,
                }))}
          >
            <Header {...{ isOpen }}>
              <NavListItem {...headerItem} variant={isFooterVariant ? 'footer' : 'default'} />
            </Header>
          </ExpansionPanel.Summary>
          <ExpansionPanel.Details renderWhenCollapsed>
            <SectionBody aria-hidden={!isOpen}>{renderNavSection(body)}</SectionBody>
          </ExpansionPanel.Details>
        </ExpansionPanel.Container>
      ) : (
        <Header {...{ isOpen }}>
          <NavListItem
            {...headerItem}
            textRole='none'
            variant={isFooterVariant ? 'footer' : 'default'}
          />
        </Header>
      )}
    </Section>
  )
}

const expandCaratIcon = (isExpanded: boolean) => (
  <CaratIconWrapper>
    <StyledCaret {...{ isExpanded }} />
  </CaratIconWrapper>
)

const expandPlusIcon = (isExpanded: boolean) => (
  <PlusIconWrapper>
    {isExpanded ? <Minus stroke='currentcolor' /> : <Plus stroke='currentcolor' />}
  </PlusIconWrapper>
)
