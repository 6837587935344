import * as React from 'react'

import { Body, ExclamationIconWrapper, Text } from './styles'

export const Template: React.FC<{
  style?: React.CSSProperties
  text: string
}> = ({ style, text }) => {
  return (
    <Body style={style}>
      <ExclamationIconWrapper>
        <span aria-label='warning' role='img'>
          ⚠️
        </span>
      </ExclamationIconWrapper>

      <Text>{text}</Text>
    </Body>
  )
}
