import { QueryResult, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'

import { TokenAuthStorageSingleton } from '@syconium/iron-figs'
import { GraphClientsContainer } from '@syconium/magnolia/src/brunswick/containers/graph-clients'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { AccountServices as AccountServicesGQL } from '@syconium/magnolia/src/lib/graphql/mutations'
import { MY_PROFILE } from '@syconium/magnolia/src/lib/graphql/queries'
import { useGoogleTagManager } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'
import type { CurrentUser } from '@syconium/magnolia/src/types/figs'

import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'
import { ACTIVATE_CUSTOMER } from '../../components/pages/account/activate/queries'
import { useLoginRedirectingLazyQuery } from '../../lib/hooks'
import { useTrackLocalesWithHeap } from '../../lib/hooks/useTrackLocalesWithHeap'
import {
  getItemFromLS,
  getUserDataFromLS,
  removeItemFromLS,
  removeUserKeyFromLS,
  setItemInLS,
  setUserDataInLS,
} from '../../lib/utils'

import type { AccountServicesTypes, UseAuthentication } from './types'

const shopTokenLS: string = 'shop-token'

export const isCustomerLoggedIn = () => {
  const tokensSingleton = TokenAuthStorageSingleton.getInstance()
  const tokenStatus = tokensSingleton.getTokenStatus()
  return tokenStatus.status === 'active'
}

const useAuthentication = (): UseAuthentication => {
  const { pushAccountCreated } = useGoogleTagManager()
  const { figsAuthedClient } = GraphClientsContainer.useContainer()
  const router = useRouter()

  const [loginCustomerMutation] = useMutation<AccountServicesTypes.LoginCustomerResponse>(
    AccountServicesGQL.LOGIN_CUSTOMER,
    { client: figsAuthedClient }
  )
  const [createCustomerMutation] = useMutation<AccountServicesTypes.CreateCustomerResponse>(
    AccountServicesGQL.CREATE_CUSTOMER,
    {
      client: figsAuthedClient,
    }
  )
  const [activateCustomerMutation] = useMutation<AccountServicesTypes.ActivateCustomerResponse>(
    ACTIVATE_CUSTOMER,
    {
      client: figsAuthedClient,
    }
  )

  const [my] = useLoginRedirectingLazyQuery<AccountServicesTypes.MyResponse>(MY_PROFILE, {
    client: figsAuthedClient,
    fetchPolicy: 'network-only',
  })

  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(getUserDataFromLS())
  const [shopifyToken, setShopifyToken] = useState<string | null>(null)

  // Validate token on initialization for security, closing a loophole
  // - calling useState with function to skip jwt_decode on re-renders
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return isCustomerLoggedIn()
  })

  const { locale } = LocalizationContainer.useContainer()

  useTrackLocalesWithHeap(locale)

  const clearLogin = (): void => {
    TokenAuthStorageSingleton.getInstance().clearTokens()
    setIsLoggedIn(false)
    window?.heap?.clearEventProperties()
  }

  /**
   * validateUser - Check global authToken. if invalid, clear login state and redirect.
   */
  const validateUser = (): void => {
    const tokenStatus = TokenAuthStorageSingleton.getInstance().getTokenStatus()

    if (
      tokenStatus.status === 'absent' ||
      tokenStatus.status === 'present' ||
      tokenStatus.status === 'expired'
    ) {
      clearLogin()
      router.push(`/account/login`)
    }
  }

  const setTokensForUser = useCallback(
    ({
      authToken,
      refreshToken,
    }: {
      authToken: string | null | undefined
      refreshToken: string | null | undefined
    }): void => {
      // Add the user's token
      // - singleton stores token in local storage
      // - token is used in authorization header
      if (!authToken) return
      TokenAuthStorageSingleton.getInstance().setToken(authToken ?? '', true)
      TokenAuthStorageSingleton.getInstance().setRefreshToken(refreshToken ?? '')

      // For security, do not assume token is valid
      setIsLoggedIn(isCustomerLoggedIn())
    },
    []
  )

  /**
   * setAuthenticatedUser - set authToken and currentUser in state and LS
   *
   *  -- set authToken in TokenAuthStorageSingleton
   *     ↳ set in local storage key "@figs:auth-token"
   *     ↳ thence used in authorization header for secure requests
   *  -- set useAuthentication:isLoggedIn
   *  -- set currentUser in local storage key "@figs:user"
   *  -- set useAuthentication:currentUser
   *  -- set shopifyToken in local storage under @figs:shop-token
   */
  const setAuthenticatedUser = useCallback(
    ({
      authToken,
      currentUser,
      refreshToken,
      shopToken,
    }: {
      authToken: string | null
      currentUser: CurrentUser | null
      refreshToken: string | null
      shopToken: string | null
    }): void => {
      // Set the persisted user info for return messages, e.g. "Welcome back, {name}!"
      if (currentUser) {
        setUserDataInLS(currentUser)
      }

      setTokensForUser({ authToken, refreshToken })

      // Update state
      setCurrentUser(currentUser)

      setShopifyToken(shopToken)
    },
    [setTokensForUser]
  )

  useEffect(() => {
    if (shopifyToken) {
      setItemInLS(shopTokenLS, shopifyToken)
    }
  }, [shopifyToken])

  /**
   * logIn - Log in shop user.
   */
  const logIn = async (email: string, password: string): Promise<AccountServicesTypes.Login> => {
    // Ensure the customer-specific apollo cache is cleared before attempting login
    // See: https://www.apollographql.com/docs/react/networking/authentication#reset-store-on-logout
    await figsAuthedClient.clearStore()

    let loginError = null
    const mutationResult = await loginCustomerMutation({
      variables: { email, password },
      errorPolicy: 'all',
    })
    const {
      customerAccessToken: shopToken,
      refreshToken,
      token: authToken,
    } = mutationResult.data?.loginCustomer ?? {
      authToken: null,
      errors: null,
      refreshToken: null,
    }

    if (
      mutationResult?.errors?.length &&
      mutationResult.errors[0]?.message.includes('Unidentified')
    ) {
      loginError = mutationResult.errors[0]
    }

    let myResponse: QueryResult<AccountServicesTypes.MyResponse> | null = null
    if (!loginError) {
      setTokensForUser({ authToken, refreshToken })
      myResponse = await my()
    }

    const error = myResponse?.error ?? null
    const myData = myResponse?.data?.my
    const unprefixedShopifyId = myData?.shopifyId?.replace('gid://shopify/Customer/', '') ?? null

    // Ignore incorrect-credential errors (status 422)
    if (error && error.message) {
      reportClientError({
        error,
        context: {
          scope: 'magnolia/useAuthentication:login',
        },
      })
    }

    if (authToken && refreshToken && shopToken) {
      setAuthenticatedUser({
        authToken,
        refreshToken,
        shopToken: shopToken ?? null,
        currentUser: myData
          ? {
              id: myData.id,
              email,
              isPortalUser: Boolean(myData.portal),
              firstName: myData.profile.firstName,
              lastName: myData.profile.lastName,
              ordersCount: myData.profile.ordersCount,
              phone: myData.profile.phone,
              shopifyId: unprefixedShopifyId,
            }
          : null,
      })
    }

    if (window?.heap) {
      window.heap.clearEventProperties()
      window.heap.addEventProperties({ 'Logged In': true })
    }

    if (window?.friendbuyAPI && myData?.profile) {
      window.friendbuyAPI.push([
        'track',
        'customer',
        {
          id: myData.profile.id,
          email,
          firstName: myData.profile.firstName,
          lastName: myData.profile.lastName,
        },
      ])
    }

    return {
      authToken,
      errors: [loginError, error],
      firstName: myData?.profile.firstName ?? '',
      lastName: myData?.profile.lastName ?? '',
      shopifyToken: shopToken,
    } as AccountServicesTypes.Login
  }

  /**
   * createShopUser - Create shop user.
   */
  const createShopUser = async ({
    email,
    emailOptIn,
    firstName,
    lastName,
    password,
  }: {
    email: string
    emailOptIn: boolean
    firstName: string
    lastName: string
    password: string
  }): Promise<AccountServicesTypes.CreateCustomer> => {
    const mutationResult = await createCustomerMutation({
      variables: { input: { email, emailOptIn, firstName, lastName, password } },
      errorPolicy: 'all',
    })

    const { errors } = mutationResult
    const {
      token: authToken,
      refreshToken,
      customerAccessToken: shopToken,
    } = mutationResult?.data?.createCustomer ?? {
      customerAccessToken: null,
      token: null,
      errors: null,
    }
    setTokensForUser({ authToken, refreshToken })

    const { data: myResponse } = await my()
    const myData = myResponse?.my
    const unprefixedShopifyId = myData?.shopifyId?.replace('gid://shopify/Customer/', '') ?? null

    if (errors && errors[0]) {
      reportClientError({
        error: errors[0],
        context: {
          scope: 'magnolia/useAuthentication:create',
        },
      })
      // security: immediately nullify authToken on error
      return {
        authToken: null,
        email,
        errors: mutationResult?.errors,
        firstName,
        lastName,
        shopifyToken: null,
      }
    }

    if (authToken && refreshToken) {
      setAuthenticatedUser({
        authToken,
        refreshToken,
        shopToken: shopToken ?? null,
        currentUser: myData
          ? {
              id: myData.id,
              email: myData.email,
              firstName: myData.profile.firstName,
              lastName: myData.profile.lastName,
              ordersCount: myData.profile.ordersCount,
              phone: myData.profile.phone,
              isPortalUser: Boolean(myData.portal),
              shopifyId: unprefixedShopifyId,
            }
          : null,
      })
      pushAccountCreated(email)
    }

    return {
      authToken,
      email,
      errors,
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      shopifyToken: shopToken,
    }
  }

  const activateAccount: (
    activationUrl: string,
    password: string
  ) => Promise<AccountServicesTypes.ActivateCustomer> = async (
    activationUrl: string,
    password: string
  ) => {
    const { errors, data } = await activateCustomerMutation({
      variables: {
        activationUrl,
        password,
      },
    })

    const authToken: string | null = data?.activateCustomer?.token ?? null
    const refreshToken: string | null = data?.activateCustomer?.refreshToken ?? null
    const shopToken: string | null = data?.activateCustomer?.customerAccessToken ?? null
    setTokensForUser({ authToken, refreshToken })

    const { data: myResponse } = await my()

    const myData = myResponse?.my
    const unprefixedShopifyId = myData?.shopifyId?.replace('gid://shopify/Customer/', '') ?? null

    if (errors && errors[0]?.message) {
      reportClientError({
        error: errors[0],
        context: {
          scope: 'magnolia/useAuthentication:activateAccount',
        },
      })
    }

    if (authToken) {
      setAuthenticatedUser({
        authToken,
        refreshToken,
        shopToken,
        currentUser: myData
          ? {
              id: myData.id,
              email: myData.email,
              firstName: myData.profile.firstName,
              lastName: myData.profile.lastName,
              ordersCount: myData.profile.ordersCount,
              phone: myData.profile.phone,
              isPortalUser: Boolean(myData.portal),
              shopifyId: unprefixedShopifyId,
            }
          : null,
      })
    }

    return { errors }
  }

  /**
   * logOut - Log out shop user and redirect to home page.
   * - SIDE EFFECT: redirect to home page & clear figsAuthedClient cache. simplify security.
   */
  const logOut = async (): Promise<void> => {
    // Reset the apollo cache for the customer client when logging out
    // See: https://www.apollographql.com/docs/react/networking/authentication#reset-store-on-logout
    await figsAuthedClient.clearStore()

    TokenAuthStorageSingleton.getInstance().clearTokens()
    setIsLoggedIn(false)
    removeUserKeyFromLS()
    setCurrentUser(null)
    removeItemFromLS(shopTokenLS)
    setShopifyToken(null)
    window?.heap?.clearEventProperties()
    router.replace('/')
  }

  return {
    setAuthenticatedUser,
    createShopUser,
    activateAccount,
    currentUser,
    isKnownUser: Boolean(currentUser),
    isLoggedIn,
    logIn,
    logOut,
    shopifyToken: getItemFromLS(shopTokenLS) ?? null,
    validateUser,
  }
}

export const AuthenticationContainer = createContainer(useAuthentication)
