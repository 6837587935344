import { useRouter } from 'next/router'
import * as React from 'react'

import { intlPrettyCurrency } from '@syconium/magnolia/src/lib/utils'

import { useFixturesContext } from '../../containers/fixtures'
import { LocalizationContainer } from '../../containers/localization/LocalizationContainer'

import { IProps } from './IProps'
import { CrossedOutPrice, DiscountPrice, DiscountPriceWrapper, Price, ValuePrice } from './styles'

export const PriceDisplay: React.FC<IProps> = ({
  discountPrice,
  displayDiscountAsValue,
  discountPriceExplicitFmt,
  price,
  priceExplicitFmt,
  priceRange,
  pgDiscountPriceRange,
}) => {
  const { locale, region } = LocalizationContainer.useContainer()
  const { valueSignalLabel } = useFixturesContext().collections.productGroupTile
  const formattedPrice =
    priceExplicitFmt ?? intlPrettyCurrency({ cents: price, region: region, locale: locale })
  const router = useRouter()
  const isSearchPage = router?.pathname?.includes('search')

  if (
    pgDiscountPriceRange &&
    pgDiscountPriceRange.min !== pgDiscountPriceRange.max &&
    !isSearchPage
  ) {
    return (
      <>
        <DiscountPrice>
          {intlPrettyCurrency({
            cents: pgDiscountPriceRange.min,
            region: region,
            locale: locale,
          })}
          -
          {intlPrettyCurrency({
            cents: pgDiscountPriceRange.max,
            region: region,
            locale: locale,
            explicitFormat: true,
          })}
        </DiscountPrice>{' '}
        <CrossedOutPrice>{formattedPrice}</CrossedOutPrice>
      </>
    )
  } else if (priceRange) {
    return (
      <>
        <Price>
          {intlPrettyCurrency({ cents: priceRange.min, region: region, locale: locale })}
        </Price>
        -{' '}
        <Price>
          {intlPrettyCurrency({ cents: priceRange.max, region: region, locale: locale })}
        </Price>
      </>
    )
  }

  if (discountPrice) {
    return (
      <DiscountPriceWrapper displayDiscountAsValue={displayDiscountAsValue} price={price}>
        <DiscountPrice displayDiscountAsValue={displayDiscountAsValue}>
          {discountPriceExplicitFmt ??
            intlPrettyCurrency({ cents: discountPrice, region: region, locale: locale })}
        </DiscountPrice>{' '}
        {!!price &&
          (displayDiscountAsValue ? (
            <ValuePrice>
              ({formattedPrice} {valueSignalLabel})
            </ValuePrice>
          ) : (
            <CrossedOutPrice>{formattedPrice}</CrossedOutPrice>
          ))}
      </DiscountPriceWrapper>
    )
  }

  if (!price) return null

  return <Price>{formattedPrice}</Price>
}
