// Transition duration
export const timingFast = '0.125s'
export const timingFastMs = 125
export const timingDefault = '0.2s'
export const timingDefaultMs = 200
export const timingSlower = '0.4s'
export const timingSlowerMs = 400

// Transition easing function
export const easeInOutCubic = 'cubic-bezier(0.65, 0, 0.35, 1)'
