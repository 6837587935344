import { useCallback, useState } from 'react'

export type ModalState = 'Open' | 'Closed'

export interface ModalStateHookProps {
  closeModal: () => void
  openModal: () => void
  isOpen: boolean
}

export function useModalState(): ModalStateHookProps {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback((): void => {
    setIsOpen(true)
  }, [])

  const closeModal = useCallback((): void => {
    setIsOpen(false)
  }, [])

  return {
    closeModal,
    openModal,
    isOpen,
  }
}
