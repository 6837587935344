import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const PRODUCT_PAGE_QUERY = gql(`
  query ProductPageQuery(
    $handle: String!
    $collectionHandle: String
    $collectionScope: ProductGroupColorSelector
    $portalCollectionHandle: String
    $withPortalColors: Boolean!
  ) {
    productGroup(
      handle: $handle
      collectionHandle: $collectionHandle
    ) {
      id
      category
      genderCategory
      portalColorGroups: colorGroups(collectionHandle: $portalCollectionHandle, onlyScoped: true) @include(if: $withPortalColors) {
        ...ColorGroup
      }
      colorGroups(
        selector: $collectionScope
      ) {
        ...ColorGroup
      }
      description
      embroiderable
      embroideryImages {
        color
        embroideryOffsets
        fit
        index
        source
        kind
        type
      }
      iconBadge
      iconBadgePlacement
      priceRange {
        min
        max
      }
      productsForAvailability: products {
        ...ProductAvailabilityFragment
      }
      productGroupView(handle: $handle) {
        sections {
          __typename
          ...PageBannerSectionFragment
          ...PageHeroSectionFragment
          ...PageTileSectionFragment
        }
      }
      handle
      rtfFeatures
      title
      sizes
      sizeChart: sizeChartCG {
        columnNames
        columnValues
        imageUri
        name
        navLabel
        tables {
          cells
          measureImage
          measureTips {
            kind
            tip
          }
          sizeTips {
            kind
            tip
          }
          subtitle
          tabLabel
          title
        }
        tips {
          kind
          tip
        }
        title
        video
      }
      style
      fits
      rawFits
    }
  }
`)
