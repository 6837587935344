import { FC } from 'react'

import {
  modalAnimationDuration,
  navModalBodyOpenClassName,
  navModalPortalClassName,
} from '@syconium/magnolia/src/brunswick/styles/constants'
import { navModalStyles } from '@syconium/magnolia/src/brunswick/styles/shared'

import { NextModal } from '../../modals/NextModal'
import { NavModalContent } from '../NavModalContent'

import { HiddenCrawlableNavigationContent } from './styles'

export const NavModal: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  return (
    <>
      {/*
        Note: Since there is no way to make the elements of <NavModalContent> populate
        inside of <Modal> when `isOpen` === false, we need to populate these navigation elements
        manually to make navigation links from all tabs crawlable and receive link equity on them.
      */}
      {!isOpen && (
        <HiddenCrawlableNavigationContent>
          <NavModalContent closeModal={onClose} renderAllNavigationTabs />
        </HiddenCrawlableNavigationContent>
      )}

      <NextModal
        shouldReturnFocusAfterClose={true}
        isOpen={isOpen}
        bodyOpenClassName={navModalBodyOpenClassName}
        closeTimeoutMS={modalAnimationDuration}
        onRequestClose={onClose}
        portalClassName={navModalPortalClassName}
        style={navModalStyles}
      >
        <NavModalContent closeModal={onClose} />
      </NextModal>
    </>
  )
}
