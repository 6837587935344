import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

// legacy (Carica) service *deprecation intended*
export const ACTIVATE_ACCOUNT = gql(`
  mutation ActivateShopUser($activationUrl: String!, $password: String!) {
    activateShopUser(activationUrl: $activationUrl, password: $password) {
      authToken
      shopUser {
        email
        firstName
        id
        lastName
        ordersCount
        phone
        shopifyId
      }
      errors {
        path
        message
      }
    }
  }
`)

// account-services service
export const ACTIVATE_CUSTOMER = gql(`
  mutation ActivateCustomer($activationUrl: String!, $password: String!) {
    activateCustomer(activationUrl: $activationUrl, password: $password) {
      customerAccessToken
      refreshToken
      token
    }
  }
`)
