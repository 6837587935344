import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import { AuthenticationContainer } from '@syconium/magnolia/src/containers/AuthenticationContainer'
import { MY_PROFILE } from '@syconium/magnolia/src/lib/graphql/queries'
import { useLoginRedirectingLazyQuery } from '@syconium/magnolia/src/lib/hooks'

import { EmbroideryVariant } from '../../../types/graphql'
import { GraphClientsContainer } from '../graph-clients'
import { EmbroideryConfig } from '../QuickBuyContainer/types'

import type {
  PortalConfigResult,
  PortalConfigState,
  PortalConfigWithEmbroideryVariant,
  UsePortalConfigResult,
} from './types'

/* List of portal-specific embroidery options. Used to identify if a given set of embroidery options contain the expected portal logo options. */
const PORTAL_EMBROIDERY_OPTION_KINDS = ['LOGO_ONLY', 'LOGO_TEXT']

const usePortalConfig = (): UsePortalConfigResult => {
  const { figsAuthedClient } = GraphClientsContainer.useContainer()

  const defaultState = useMemo(() => ({ loading: false, stipend: null }), [])
  const [state, setState] = useState<PortalConfigState>(defaultState)

  const { isLoggedIn, currentUser } = AuthenticationContainer.useContainer()

  const [my] = useLoginRedirectingLazyQuery(MY_PROFILE, {
    client: figsAuthedClient,
  })
  const router = useRouter()

  const redirectWhenNotAvailable = useCallback(
    (result: PortalConfigResult) => {
      if (result.error?.message === 'Unauthorized') {
        router.replace('/account/login')
      } else if (!result.portal) {
        router.replace(`/`)
      }

      return result
    },
    [router]
  )

  const load = useCallback(async () => {
    try {
      setState({ loading: true, portal: undefined, error: undefined, stipend: null })
      const { data: myData, error: myError } = await my()

      const portal: PortalConfigWithEmbroideryVariant | undefined = myData?.my?.portal ?? undefined

      const stipend = myData?.my?.profile?.stipend ?? null

      setState({ loading: false, error: myError, portal, stipend })
      return { error: myError, portal }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setState({ loading: false, error, stipend: null })
      return { error }
    }
  }, [my])

  // Check if the current portal enforces mandatory text embroidery for a given product category
  const hasMandatoryTextEmbroidery = useCallback(
    (category?: string) =>
      Boolean(
        category && state.portal?.embroidery?.mandatoryTextEmbroideryCategories.includes(category)
      ),
    [state.portal?.embroidery?.mandatoryTextEmbroideryCategories]
  )

  // Check if the current portal enforces mandatory logo embroidery for a given product category
  const hasMandatoryLogoEmbroidery = useCallback(
    (category?: string) =>
      Boolean(
        category && state.portal?.embroidery?.mandatoryLogoEmbroideryCategories.includes(category)
      ),
    [state.portal?.embroidery?.mandatoryLogoEmbroideryCategories]
  )

  // Check if the current embroidery options contain the expected portal-specific `LOGO_ONLY` and `LOGO_TEXT` options
  const hasPortalLogoEmbroideryOptions = useCallback(
    <T extends { kind: string }>(embroideryOptions: readonly T[]) => {
      return embroideryOptions.some(e => PORTAL_EMBROIDERY_OPTION_KINDS.includes(e.kind))
    },
    []
  )

  // Reset portal state when login state changes
  if (isLoggedIn === false && state.portal) setState(defaultState)

  // Request portal data if current user is a portal user according to LS
  useEffect(() => {
    if (!state.portal && currentUser?.isPortalUser) load()
    // eslint-disable-next-line
  }, [state.portal, currentUser?.isPortalUser])

  // Create the portal-specific embroidery config object
  const createEmbroideryConfig = useCallback(
    ({
      embroideryVariant,
      productCategory,
    }: {
      embroideryVariant?: EmbroideryVariant
      productCategory: string
    }): EmbroideryConfig | undefined => {
      const embroiderySettings = state?.portal?.embroidery
      const enabledProductCategory =
        embroiderySettings?.eligibleProductCategories.includes(productCategory)

      // Return undefined if:
      // 1) No embroidery variant was provided
      // 2) No embroidery settings exist for the current portal
      // 3) The current product category is not specified as eligible for portal embroidery
      if (!embroideryVariant || !embroiderySettings || !enabledProductCategory) return undefined
      return {
        image: embroiderySettings.image,
        logoName: embroiderySettings.logoName,
        noText: embroiderySettings.noText ?? '',
        questionText: embroiderySettings.addText,
        yesText: embroiderySettings.yesText ?? '',
        variant: embroideryVariant,
        quickBuyText: state?.portal?.quickBuyText ?? '',
        mandatoryLogoEmbroidery: hasMandatoryLogoEmbroidery(productCategory),
      }
    },
    [hasMandatoryLogoEmbroidery, state?.portal?.embroidery, state?.portal?.quickBuyText]
  )

  return useMemo(
    () => ({
      ...state,
      portal: state.portal ?? undefined,
      load,
      redirectWhenNotAvailable,
      hasMandatoryTextEmbroidery,
      hasMandatoryLogoEmbroidery,
      hasPortalLogoEmbroideryOptions,
      createEmbroideryConfig,
    }),
    [
      load,
      redirectWhenNotAvailable,
      state,
      hasMandatoryLogoEmbroidery,
      hasMandatoryTextEmbroidery,
      hasPortalLogoEmbroideryOptions,
      createEmbroideryConfig,
    ]
  )
}

export const PortalConfigContainer = createContainer(usePortalConfig)
