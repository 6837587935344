import { createContext } from 'react'

import { createContextHook } from '../../../lib/utils/createContextHook'

interface IConfigContext {
  isPlain?: boolean
}

export const ConfigContext = createContext<IConfigContext | undefined>(undefined)

export const useConfigContext = createContextHook(ConfigContext, 'ConfigContext')
