export enum ProductTiers {
  APP = 'app',
}

export enum ProductCategories {
  KIT = 'Product Kit',
}

export enum Fit {
  REGULAR = 'regular',
  PETITE = 'petite',
  TALL = 'tall',
}

export const imageSize = 600
export const srcSetSizes = [100, 225, imageSize, 750]
export const debounceMs = 100

export const nullSizeChart = 'null-size-chart'
