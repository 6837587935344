import { useEffect } from 'react'

import { sessionStorageKeys } from '../../app/_config/Session.config'

export function useTrackLocalesWithHeap(locale: string | null) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const usedLocales: string[] = []
      const previouslyUsedLocalesString = window.sessionStorage.getItem(
        sessionStorageKeys.usedLocales
      )
      if (previouslyUsedLocalesString) {
        const previouslyUsedLocales = JSON.parse(previouslyUsedLocalesString)
        for (let locale of previouslyUsedLocales) {
          usedLocales.push(locale)
        }
      }
      if (typeof locale === 'string') {
        if (!usedLocales.includes(locale)) {
          usedLocales.push(locale)
        }
      }
      const usedLocalesString = JSON.stringify(usedLocales)

      window.sessionStorage.setItem(sessionStorageKeys.usedLocales, usedLocalesString)

      if (window?.heap) {
        window.heap.addEventProperties({ '@figs:used-locales': usedLocalesString })
      }
    }
  }, [locale])
}
