import styled from 'styled-components'

import { Help } from '@syconium/little-miss-figgy'
import { headerHeight } from '@syconium/magnolia/src/brunswick/constants'
import {
  modalAnimationDuration,
  navModalPortalClassName,
} from '@syconium/magnolia/src/brunswick/styles/constants'

import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'
import { Body as NavAccordion } from '../NavAccordion/styles'
import { Body as NavListSection } from '../NavListSection/styles'
import {
  Body as NavTileSection,
  ScrollWrap as NavTileSectionScrollWrap,
} from '../NavTileSection/styles'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: scroll;
`

export const Top = styled.div`
  flex: 0 0;
`

// position is a style attribute for cypress testing
export const Header = styled.header`
  height: ${headerHeight};
  text-align: center;
  top: 0;
  z-index: ${o => o.theme.zIndex.foregroundContent};
`

export const CloseButton = styled.button`
  align-items: center;
  background: transparent;
  display: flex;
  height: 100%;
  left: 0;
  padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;
  position: absolute;
  top: 0;
  z-index: 1;

  > svg {
    display: block;
  }

  .${navModalPortalClassName} .ReactModal__Content & {
    .p1,
    .p2 {
      stroke-width: 0.6;
      transform-origin: center;
      transition:
        stroke-width ${modalAnimationDuration}ms ease,
        transform ${modalAnimationDuration}ms ease;
    }

    .p1 {
      transform: rotate(45deg) translate(0, 3px) scale(1.4);
    }

    .p2 {
      transform: rotate(-45deg) translate(0, -3px) scale(1.4);
    }
  }

  .${navModalPortalClassName} .ReactModal__Content--after-open & {
    .p1,
    .p2 {
      stroke-width: 1;
      transform: none;
    }
  }

  .${navModalPortalClassName} .ReactModal__Content--before-close & {
    .p1,
    .p2 {
      stroke-width: 0.6;
    }

    .p1 {
      transform: rotate(45deg) translate(0, 3px) scale(1.4);
    }

    .p2 {
      transform: rotate(-45deg) translate(0, -3px) scale(1.4);
    }
  }
`

export const TabList = styled.div.attrs({ className: 'nav-modal-slides-in-from-left' })`
  background: white;
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stone};
  height: 100%;
  width: 100%;
`

export const Tab = styled.button<{
  isActive: boolean
  textColor?: string | null
}>`
  border-bottom: 5px solid
    ${o => (o.isActive ? o.textColor || o.theme.color.deprecated.obsidian : 'transparent')};
  border-top: 5px solid transparent;
  color: ${o =>
    o.textColor ||
    (o.isActive ? o.theme.color.deprecated.obsidian : o.theme.color.deprecated.obsidianLighter)};
  font-size: 16px;
  font-weight: bold;
  height: 100%;
  padding: 0 16px;
  user-select: none;

  &:hover {
    outline: none;
  }
`

export const TabPanel = styled.nav.attrs({ className: 'nav-modal-slides-in-from-left' })`
  background: white;

  > * {
    border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  }

  > :first-child,
  > ${NavAccordion} {
    border-top: none;
  }

  > ${NavListSection} {
    padding: 32px ${o => o.theme.dimensions.layout.sidePadding.md}px;
  }

  > ${NavTileSection} > ${NavTileSectionScrollWrap} {
    padding: 20px 12px;
  }
`

// Bottom is 2 equal halves. Could use Divider and justify: space-between.
export const Bottom = styled.div.attrs({ className: 'nav-modal-slides-in-from-left' })`
  background: white;
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  display: flex;
  flex-direction: column;
  flex: 0 0 50px;

  > section {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 50px;
    padding: 0 8px;
    width: 100%;
  }
`

export const HelpLink = styled(MaybeSpaLink)`
  font-size: 13px;
  font-weight: bold;
`

export const HelpIcon = styled(Help)`
  margin-inline-end: 10px;
  vertical-align: middle;
`

export const Middle = styled.div.attrs({ className: 'nav-modal-slides-in-from-left' })`
  background: white;
  flex: auto;
`
