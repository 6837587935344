import styled from 'styled-components'

import { timingSlower } from '@syconium/little-miss-figgy'

import { SectionLayout, contentHeight, padHeight } from '../styles'

export const ContainerLayout = styled.header`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: ${o => o.theme.zIndex.foregroundContent};

  .fresnel-container {
    display: contents;
  }

  ${SectionLayout} {
    flex-direction: row;

    &:last-child {
      flex-direction: row-reverse;
    }
  }

  ${({
    theme: {
      color: {
        deprecated: { white },
      },
      device: { fromMd },
      header,
      zIndex,
    },
  }) => `
  height: ${contentHeight};
  background-color: ${white.primary};
  padding: ${padHeight} 16px;

  transition: transform ${timingSlower} ease-in-out;

  transform: translate3d(0, 0 , 0);
  &[aria-hidden="true"] {
    transform: translate3d(${header.slideOutOffset}, 0 , 0);
  }

  ${fromMd} {
    align-items: center;
    padding: ${padHeight} 28px;
    transform: initial;
    z-index: ${zIndex.foregroundContent};
  }`}
`
