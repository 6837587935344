import React from 'react'

import { CheckMark } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { SizeChart, SizeChartTip } from '../../../../types/graphql'
import { useFixturesContext } from '../../../containers/fixtures'

import { SizeChartModalTable } from './SizeChartModalTable'
import {
  CloseButton,
  Content,
  Header,
  HeaderText,
  HowToMeasureText,
  ResponsiveImage,
  Table,
  TableWrapper,
  Td,
  Th,
  TipCheckMark,
  TipItem,
  TipsSection,
  TipsSectionContent,
  TipsSectionTextBlock,
  Tr,
  WantToKnowText,
} from './styles'

export const SizeChartModalContent: React.FC<{
  onClose?: () => void
  showHeader?: boolean
  sizeChart: SizeChart
}> = ({ onClose, showHeader = true, sizeChart }) => {
  const {
    sizesSection: {
      sizeChart: { headline, wantToKnowLine1, wantToKnowLine2, howToMeasure },
    },
  } = useFixturesContext()
  const { columnNames, columnValues, imageUri, tips, tables } = sizeChart

  return (
    <>
      {tables?.length ? (
        <SizeChartModalTable {...{ onClose, showHeader, sizeChart }} />
      ) : (
        <>
          {showHeader && (
            <Header>
              <HeaderText textType='h2' textTransform='uppercase' textAlign='center'>
                {headline}
              </HeaderText>
              <CloseButton
                onClick={onClose}
                {...trackEvent({ category: 'product details', action: 'close size chart modal' })}
              />
            </Header>
          )}
          <Content>
            <WantToKnowText>
              {wantToKnowLine1}
              <br />
              {wantToKnowLine2}
            </WantToKnowText>
            <TableWrapper>
              {columnNames && columnValues ? (
                <Table>
                  <thead>
                    <Tr>
                      {columnNames.map((column, index) => (
                        <Th key={`${column}-${index}`}>{column}</Th>
                      ))}
                    </Tr>
                  </thead>
                  <tbody>
                    {columnValues.map((values, index) => (
                      <Tr key={`${values[0]}-${index}`}>
                        {values.map((value, valueIndex) => (
                          <Td key={`${value}-${valueIndex}`}>{value}</Td>
                        ))}
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </TableWrapper>
            {tips && tips?.length > 0 ? (
              <TipsSection>
                <TipsSectionContent>
                  <TipsSectionTextBlock>
                    <HowToMeasureText>{howToMeasure}</HowToMeasureText>
                    <ul>
                      {tips.map((item: SizeChartTip, index) => (
                        <TipItem key={`${item.kind}-${index}`}>
                          <TipCheckMark>
                            <CheckMark />
                          </TipCheckMark>
                          <b>{item.kind}</b>. {item.tip}
                        </TipItem>
                      ))}
                    </ul>
                  </TipsSectionTextBlock>
                  {imageUri && (
                    <ResponsiveImage
                      alt={'size chart tip image'}
                      src={imageUri}
                      aspectRatios={{
                        sm: null,
                        md: null,
                      }}
                      widths={{ unit: 'vw', sm: 100, md: 40 }}
                      loading='default'
                    />
                  )}
                </TipsSectionContent>
              </TipsSection>
            ) : null}
          </Content>
        </>
      )}
    </>
  )
}
