import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Pencil: React.FC<IProps> = ({ height = '26px', width = '26px', stroke, ...rest }) => {
  return (
    <BaseIcon
      {...rest}
      height={height}
      width={width}
      stroke={stroke || 'currentColor'}
      viewBox='0 0 15 15'
      strokeWidth='0.1'
    >
      <path d='M0.801246 13.9828L5.08757 13.0594C5.20954 13.0245 5.33151 12.9722 5.41863 12.8851L13.5034 4.80034C14.1655 4.13822 14.1655 3.04051 13.5034 2.36097L11.639 0.496587C10.9769 -0.165529 9.8792 -0.165529 9.19966 0.496587L1.11488 8.58137C1.02776 8.66849 0.958063 8.79046 0.940639 8.91243L0.0171615 13.1988C-0.0351108 13.4253 0.0345856 13.6518 0.191402 13.8086C0.348219 13.9654 0.574733 14.0351 0.801246 13.9828ZM10.1406 1.43749C10.2974 1.28067 10.5413 1.28067 10.6981 1.43749L12.5625 3.30187C12.7193 3.45868 12.7193 3.70262 12.5625 3.85944L11.761 4.66095L9.32163 2.22157L10.1406 1.43749ZM8.39815 3.1799L10.8375 5.61927L5.4709 10.951L3.03153 8.51167L8.39815 3.1799ZM2.16033 9.50484L4.49515 11.8397L1.53306 12.4844L2.16033 9.50484Z' />
    </BaseIcon>
  )
}
