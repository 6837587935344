import { useCallback, useState } from 'react'

import type { Variant } from '../../../types/graphql'

export function useVariantValidator({ selectedVariant }: { selectedVariant?: Variant }) {
  const [invalid, setInvalid] = useState(false)

  const reset = useCallback(() => setInvalid(false), [setInvalid])

  const validate = useCallback(() => {
    const valid = !!selectedVariant
    setInvalid(!valid)
    return valid
  }, [setInvalid, selectedVariant])

  return { invalid, reset, validate }
}
