import { EmbroideryPlacementDetails, ProductAsset, ProductColor } from '../../../../types/graphql'

export function getFlatlayImage({
  images,
  placement,
  productColor,
}: {
  images: ReadonlyArray<ProductAsset> | undefined
  placement: EmbroideryPlacementDetails | null
  productColor: ProductColor | undefined
}): ProductAsset | undefined {
  const list = images?.filter(Boolean) ?? []
  return (
    list.find(o => o.kind === placement?.flatlayKind && o.color === productColor?.name) ??
    list.find(o => o.kind === placement?.flatlayKind && o.color === 'default') ??
    list.find(o => o.kind === 'flatlay-default' && o.color === productColor?.name) ??
    list.find(o => o.kind === 'flatlay-default' && o.color === 'default') ??
    list.find(o => o.kind === 'flatlay-default')
  )
}
