import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

/**
 * A simple wrapper around useState that updates the state when the input changes.
 * Allows local state to diverge until input changes.
 */
export function useInputObservingState<T>(input: T) {
  const [state, setState] = useState<T>(input)

  useUpdateEffect(() => {
    setState(input)
  }, [input])

  return { setState, state }
}
