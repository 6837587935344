import React from 'react'

import { NavTileSection as Props } from '@syconium/magnolia/src/types/graphql'

import { NavTile } from '../NavTile'

import { Body, ScrollWrap } from './styles'

export const NavTileSection: React.FC<Props> = ({ tiles }) => {
  return (
    <Body>
      <ScrollWrap>
        {tiles.map((tile, i) => (
          <NavTile key={[tile.id, i].join('-')} {...tile} />
        ))}
      </ScrollWrap>
    </Body>
  )
}
