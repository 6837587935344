import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import Cookies from 'universal-cookie'

import {
  CustomAttribute,
  ICartClient,
} from '@syconium/magnolia/src/brunswick/interfaces/remote-data/cart'
import { Cart, CartItem } from '@syconium/magnolia/src/types/figs'
import { SupportedLanguageGroupIdentifier, SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { CheckoutErrorCode } from '../../__generated__/graphql/shopify/graphql'
import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'
import { sessionStorageKeys } from '../../app/_config/Session.config'
import { isCustomerLoggedIn } from '../../containers/AuthenticationContainer'
import { Checkout, LanguageCode } from '../../types/shopify-storefront-api'
import { addQueryParams } from '../utils/url'

import {
  ASSOCIATE_CUSTOMER_TO_CHECKOUT,
  CREATE_CHECKOUT_WITH_LINE_ITEMS,
  FETCH_CHECKOUT,
} from './queries'
import {
  CheckoutCreate,
  CheckoutCreateVariables,
  CheckoutCustomerAssociateV2,
  CheckoutUserError,
  IterableKeys,
} from './types'
import { mapCartItemToCheckoutLineItemInput, shippingAddressForRegion } from './utils'

class Storage {
  private storageMedium: globalThis.Storage | undefined =
    typeof window === 'undefined' ? undefined : window.localStorage ?? window.sessionStorage

  public getItem(key: string): string | null {
    if (!this.storageMedium) return null
    return this.storageMedium.getItem(key)
  }

  public setItem(key: string, value: string): void {
    if (!this.storageMedium) return
    this.storageMedium.setItem(key, value)
  }

  public removeItem(key: string): void {
    if (!this.storageMedium) return
    this.storageMedium.removeItem(key)
  }
}

export class ShopifyStorefrontApiCartClient implements ICartClient {
  private static LOCAL_STORAGE_CHECKOUT_ID_KEY: string = '@figs:storefront-checkout-id'
  private apolloClient: ApolloClient<NormalizedCacheObject>
  private currency: string
  private storage = new Storage()

  constructor({
    apolloClient,
    currency = 'USD',
  }: {
    apolloClient: ApolloClient<NormalizedCacheObject>
    currency?: string
  }) {
    this.apolloClient = apolloClient
    this.currency = currency
  }

  public addItems(): Promise<CartItem[]> {
    return Promise.resolve([])
  }

  public removeItems(): Promise<CartItem[]> {
    return Promise.resolve([])
  }

  public setItemsQuantities(): Promise<CartItem[]> {
    return Promise.resolve([])
  }

  public fetchCart(): Promise<Cart | null> {
    return Promise.resolve(null)
  }

  private get legacyCheckoutKey(): string {
    return ShopifyStorefrontApiCartClient.LOCAL_STORAGE_CHECKOUT_ID_KEY
  }

  public get locallyStoredCheckoutId(): string | null {
    const checkoutKey = this.legacyCheckoutKey
    return atob(this.storage.getItem(checkoutKey) ?? '') || null
  }

  public get iterableAttributionValues(): { key: string; value: string }[] {
    const cookies = new Cookies()
    const iterableKeys = [
      IterableKeys.CAMPAIGN_ID,
      IterableKeys.MESSAGE_ID,
      IterableKeys.TEMPLATE_ID,
      IterableKeys.USER_ID,
      IterableKeys.SMS_CAMPAIGN_ID,
    ]
    const values: { key: string; value: string }[] = []

    iterableKeys.forEach(key => {
      const value = cookies.get(key) ?? ''

      // currently we map both email and sms campaigns to the same checkout attribute but sms takes precedence
      if (key === IterableKeys.SMS_CAMPAIGN_ID) key = IterableKeys.CAMPAIGN_ID

      values.push({ key, value })
    })

    return values
  }

  private async _createCheckoutWithCartItems(
    items: CartItem[],
    regionId: SupportedRegionIdentifier = 'US',
    languageGroup: SupportedLanguageGroupIdentifier = 'en',
    globalCustomAttributes: CustomAttribute[] = []
  ): Promise<CheckoutCreate> {
    const isLoggedIn = isCustomerLoggedIn()
    const iterableValues = this.iterableAttributionValues
    const language = languageGroup.toUpperCase() as LanguageCode

    const currentLocation =
      globalThis.document.location.protocol +
      '//' +
      globalThis.document.location.hostname +
      globalThis.document.location.pathname +
      globalThis.document.location.search
    const landingLocation = globalThis.sessionStorage?.getItem(sessionStorageKeys.landingPage)

    const lineItems = items
      .map(o => {
        return mapCartItemToCheckoutLineItemInput(o.variantId, o.quantity, {
          ...o.properties,
        })
      })
      .flat()

    const customAttributes: CustomAttribute[] = [
      {
        key: 'landing_site',
        value: landingLocation ?? currentLocation,
      },
      {
        key: 'is_customer_logged_in',
        value: String(isLoggedIn),
      },
    ]

    iterableValues.forEach(item => {
      if (item.value) customAttributes.push(item)
    })

    const checkout = await this.apolloClient.mutate<CheckoutCreate, CheckoutCreateVariables>({
      mutation: CREATE_CHECKOUT_WITH_LINE_ITEMS,
      variables: {
        allowPartialAddresses: true,
        lineItems,
        shippingAddress: regionId === 'US' ? undefined : shippingAddressForRegion(regionId),
        customAttributes: [...customAttributes, ...globalCustomAttributes],
        country: regionId,
        language: language,
      },
      context: {
        retryOnFailure: true,
      },
    })

    return checkout as unknown as CheckoutCreate
  }

  private filterInvalidCartItemsOnError(
    items: CartItem[],
    errors: CheckoutUserError[]
  ): CartItem[] {
    let validItems: CartItem[] = []
    const invalidIndices: number[] = []
    const errorCodesToHandle: CheckoutErrorCode[] = ['NOT_ENOUGH_IN_STOCK', 'PRODUCT_NOT_AVAILABLE']
    for (let e of errors) {
      // check for specific codes, as well as any that begin with `INVALID`
      if (errorCodesToHandle.includes(e.code) || e.code.startsWith('INVALID')) {
        invalidIndices.push(Number.parseInt(e.field[2]!))
      }
    }
    items.forEach((item: CartItem, index: number) => {
      if (!invalidIndices.includes(index)) validItems.push(item)
    })
    return validItems
  }

  /*
     In some cases a client can have items in their cart which have become unavailable in the shop
     between the time it was added and the time they attempt to checkout (purchase). In this event
     we have an array of INVALID errors returned to us from the Shopify API that indicates which item(s)
     is/are no longer available (i.e. "invalid") by its/their index-position(s) in the items array. We then filter
     those items out and re-attempt the checkout creation. (matt 5.25.21)
  */
  public async createCheckoutWithCartItems(
    items: CartItem[],
    regionId: SupportedRegionIdentifier | undefined,
    languageGroup: SupportedLanguageGroupIdentifier = 'en',
    storageKey = this.legacyCheckoutKey,
    globalCustomAttributes: CustomAttribute[] = []
  ): Promise<string> {
    let checkout = await this._createCheckoutWithCartItems(
      items,
      regionId,
      languageGroup,
      globalCustomAttributes
    )
    let validatedItems: CartItem[] = []
    if (checkout.data?.checkoutCreate.checkoutUserErrors.length) {
      reportClientError({
        error: new Error('[ERROR]: checkoutCreate failed'),
        context: {
          scope: 'checkout',
        },
      })

      validatedItems = this.filterInvalidCartItemsOnError(
        items,
        checkout.data.checkoutCreate.checkoutUserErrors
      )
    }

    if (validatedItems.length) {
      checkout = await this._createCheckoutWithCartItems(validatedItems, regionId, languageGroup)
    }

    if (
      !checkout.data ||
      !checkout.data.checkoutCreate?.checkout ||
      checkout.data.checkoutCreate.checkoutUserErrors.length
    ) {
      if (!validatedItems.length) {
        reportClientError({
          error: new Error('[ERROR]: checkout attempted with no valid items'),
          context: {
            scope: 'checkout',
          },
        })
      } else {
        throw new Error('Checkout failed!')
      }
    }

    const checkoutId = checkout?.data?.checkoutCreate?.checkout?.id
    if (checkoutId) {
      // Base64 encode the checkoutId for Global-E's convenience. In Shopify API versions
      // on and before 2020-10 this was done by Shopify.
      this.storage.setItem(storageKey, btoa(checkoutId))
    }

    const discount = globalThis.sessionStorage?.getItem(sessionStorageKeys.discount)

    return addQueryParams(checkout?.data?.checkoutCreate?.checkout?.webUrl, {
      ...(discount ? { discount } : {}),
    })
  }

  public async clearCheckoutIfCompleted(
    checkoutId = this.locallyStoredCheckoutId,
    storageKey = this.legacyCheckoutKey
  ): Promise<boolean> {
    if (!checkoutId) return false
    const checkout = await this.fetchCheckout(checkoutId)
    if (checkout?.completedAt) {
      this.storage.removeItem(storageKey)
      return true
    }
    return false
  }

  public async associateCustomer(shopifyToken: string | null): Promise<void> {
    const checkoutId = this.locallyStoredCheckoutId
    if (!checkoutId || !shopifyToken) return
    ;(await this.apolloClient.mutate<
      CheckoutCustomerAssociateV2,
      { checkoutId: string; customerAccessToken: string }
    >({
      mutation: ASSOCIATE_CUSTOMER_TO_CHECKOUT,
      variables: {
        checkoutId,
        customerAccessToken: shopifyToken,
      },
    })) as unknown as CheckoutCustomerAssociateV2

    return Promise.resolve()
  }

  private async fetchCheckout(checkoutId: string): Promise<Checkout | null> {
    const { data, error } = await this.apolloClient.query<{ node: Checkout }>({
      query: FETCH_CHECKOUT,
      variables: {
        id: checkoutId,
        currency: this.currency,
        presentmentCurrencyCode: this.currency,
      },
      fetchPolicy: 'network-only',
    })
    if (error) throw error
    return data?.node
  }
}
