import React from 'react'

import { useSearchOverlayContext } from '@syconium/magnolia/src/containers/SearchOverlayContext'
import { PromoBar as ContentfulPromoBar } from '@syconium/magnolia/src/types/graphql'

import { AccentColorContainer } from '../../../containers/AccentColorContainer'

import { slideDuration } from './styles'
import { ViewModel } from './types'

export const useViewModel: (contentfulPromoBar: ContentfulPromoBar) => ViewModel = function ({
  pages,
  backgroundColor,
  textColor,
}: ContentfulPromoBar): ViewModel {
  const { accentColor } = AccentColorContainer.useContainer()
  const { shouldDisplaySearchOverlay } = useSearchOverlayContext()
  const isFadedOut = shouldDisplaySearchOverlay

  const [transition, setTransition] = React.useState<boolean>(false)
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0)

  const autoTransition: boolean = pages.length > 1
  const lastPageIndex = pages.length - 1
  const nextPageIndex = currentPageIndex + 1 > lastPageIndex ? 0 : currentPageIndex + 1

  // Visually render the next and previous page (if it exists) only
  const displayPages = [pages[currentPageIndex]!, pages[nextPageIndex]!].filter(Boolean)

  const displayPagesLength = pages.length

  const handleNextPage = () => {
    setCurrentPageIndex(prev => (prev + 1) % displayPagesLength)
  }

  const handlePrevPage = () => {
    setCurrentPageIndex(prev => (prev - 1 + displayPagesLength) % displayPagesLength)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case 'ArrowUp':
        handlePrevPage()
        event.preventDefault()
        break
      case 'ArrowDown':
        handleNextPage()
        event.preventDefault()
        break
      default:
        break
    }
  }

  const onTransition = React.useCallback(() => {
    if (transition) return
    setTransition(true)
    setTimeout(() => {
      setCurrentPageIndex(nextPageIndex)
      setTransition(false)
    }, slideDuration)
  }, [transition, nextPageIndex])

  return {
    autoTransition,
    backgroundColor: accentColor ?? backgroundColor,
    textColor,
    displayPages,
    handleKeyDown,
    onTransition,
    transition,
    isFadedOut,
  }
}
