import { useMedia } from 'react-use'

import { lgStartMedia, mdMedia } from '../../constants'

export enum ViewportSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export function useViewportBreakpoints(): ViewportSizes {
  const isLarge = useMedia(lgStartMedia, false)
  const isMedium = useMedia(mdMedia, false)

  return (
    (isLarge && ViewportSizes.large) || (isMedium && ViewportSizes.medium) || ViewportSizes.small
  )
}
