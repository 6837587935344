import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Filter: React.FC<IProps> = ({ height = '20px', width = '16px' }) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 20 16'>
      <path d='M0 3H20' stroke='#282828' strokeWidth='1.43' />
      <path d='M0 13H20' stroke='#282828' strokeWidth='1.43' />
      <circle cx='5' cy='3' r='2.285' fill='white' stroke='#282828' strokeWidth='1.43' />
      <circle cx='15' cy='13' r='2.285' fill='white' stroke='#282828' strokeWidth='1.43' />
    </BaseIcon>
  )
}
