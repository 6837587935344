import styled from 'styled-components'

export const VisibilityTarget = styled.div`
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 1px;
`
