import React from 'react'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const NavModalContent: React.FC<{
  closeModal: () => void
  lang?: string
  langModalButton?: () => void
  renderAllNavigationTabs?: boolean
}> = ({ closeModal, lang, langModalButton, renderAllNavigationTabs = false }) => {
  const viewModel = useViewModel({ closeModal, renderAllNavigationTabs })
  return <Template {...viewModel} lang={lang} langModalButton={langModalButton} />
}
