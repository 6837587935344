import { useRouter } from 'next/router'

export function useUpdateQueryParam() {
  const updateQueryParams = useUpdateQueryParams()
  return function (key: string, value: string | undefined): void {
    return updateQueryParams({ [key]: value })
  }
}

export function useUpdateQueryParams() {
  const router = useRouter()

  return (updatedQueryParams: Record<string, string | undefined>) => {
    const query: Record<string, string | string[] | undefined> = { ...router.query }

    Object.entries(updatedQueryParams).forEach(([key, val]) => {
      if (typeof val === 'undefined') {
        delete query[key]
      } else {
        query[key] = val as string
      }
    })

    router.replace(
      {
        query,
      },
      undefined,
      { scroll: false, shallow: true }
    )
  }
}
