import React from 'react'
import { Interpolation } from 'styled-components'

import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { RawHtmlSection } from './page-view'

type ClickishEvent = React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>

export type TargetCriteriaTypes =
  | 'Initial Session'
  | 'Has Prior Session'
  | 'Logged In'
  | 'In Region'
export type TargetCriteriaLogic = 'Match Any' | 'Match All'

export type TargetCriteria = {
  name?: string
  targetLogic: TargetCriteriaLogic
  targetCriteria: TargetCriteriaTypes[]
  inRegion?: SupportedRegionIdentifier[]
}

export type ValidContentfulModel = NavListItem | NavTab

export enum AlternateModelVariantAction {
  replaceOriginal = 'Replace Original',
  hideOriginal = 'Hide Original',
}

export interface AlternateModelVariant {
  id: string
  name: string
  action: AlternateModelVariantAction
  targetCriteria: TargetCriteria
  validContentfulModel: ValidContentfulModel
}

export interface NavListItem
  extends Readonly<{
    asTag?: keyof JSX.IntrinsicElements
    badge: string | null
    badgeColor: string | null
    badgeTextColor: string | null
    href: string | null
    icon: string | null
    id: string
    isBold: boolean | null
    isHeading: boolean | null
    role?: string
    text: string
    textColor: string | null
    textRole?: string
    variant?: NavListSectionVariant
    htmlId?: string | null
    htmlClass?: string | null
    isBtn?: boolean | null
    alternateModelVariant?: AlternateModelVariant | null
    __typename?: string

    // client-side
    hasDivider?: boolean
    onClickish?: (event: ClickishEvent) => void
  }> {}

export interface NavListSection
  extends Readonly<{
    __typename: 'NavListSection'
    id: string
    items: NavListItem[]
    styles?: Interpolation<object>
    variant: NavListSectionVariant
  }> {}

export type NavListSectionVariant = 'default' | 'featured' | 'footer'

export interface NavListGroupSection
  extends Readonly<{
    __typename: 'NavListGroupSection'
    id: string
    navSections: NavListSection[]
  }> {}

export interface NavTile
  extends Readonly<{
    id: string
    ctaText: string
    href: string
    image: string
  }> {}

export interface NavTileSection
  extends Readonly<{
    __typename: 'NavTileSection'
    id: string
    tiles: NavTile[]
  }> {}

export interface NavAccordionSection
  extends Readonly<{
    id: string
    header: NavListItem
    body: NavSection | null
    alternateModelVariant?: AlternateModelVariant | null
  }> {}

export type NavAccordionVariant = 'default' | 'footer'

export interface NavAccordion
  extends Readonly<{
    __typename: 'NavAccordion'
    id: string
    sections: NavAccordionSection[]
    variant?: NavAccordionVariant

    // client-side
    startOpenSet?: number[]
  }> {}

export interface NavRegionLocaleSection
  extends Readonly<{
    __typename: 'NavRegionLocaleSection'
    id: string
  }> {}

export type NavSection =
  | NavListSection
  | NavListGroupSection
  | NavTileSection
  | NavAccordion
  | RawHtmlSection
  | NavRegionLocaleSection

export interface NavTab
  extends Readonly<{
    alternateModelVariant?: AlternateModelVariant | null
    href: string | null
    id: string
    sections: NavSection[]
    text: string
    textColor: string | null

    // client-side
    ariaLabel?: string
    onClickish?: (event: ClickishEvent) => void
    render?: () => React.ReactNode
    wrapperElement?: 'div' | 'button' | 'a'
    __typename?: string
  }> {}

export interface SiteNavigation
  extends Readonly<{
    // Nav 1.0
    navigationJSON?: string

    // Nav 2.0
    // - optional during migration
    desktopTabs?: NavTab[]
    mobileTabs?: NavTab[]
    helpLinkText?: string
    helpLinkHref?: string
  }> {}

export interface SiteFooter
  extends Readonly<{
    desktopSections?: NavSection[]
    mobileSections?: NavSection[]
    socialLinks?: NavListItem[]
    legalLinks?: NavListItem[]
    copyrightLine?: string
    accessibilityButtonText?: string
  }> {}

export interface PromoBar
  extends Readonly<{
    backgroundColor: string
    textColor: string
    pages: {
      id: string
      content: string
    }[]
  }> {}

export interface SizeChartTip {
  kind: string
  tip: string
}

export type TableCells = ReadonlyArray<ReadonlyArray<string>>

export interface SizeChartTable
  extends Readonly<{
    cells:
      | {
          centimeters: TableCells
          inches: TableCells
        }
      | TableCells
    measureImage: string | null
    measureTips: ReadonlyArray<SizeChartTip>
    sizeTips: ReadonlyArray<SizeChartTip>
    subtitle: string | null
    tabLabel: string | null
    title: string | null
  }> {}

export interface SizeChart
  extends Readonly<{
    columnNames?: ReadonlyArray<string> // deprecated
    columnValues?: TableCells // deprecated
    imageUri?: string // deprecated
    navLabel?: string | null
    name?: string
    tables?: ReadonlyArray<SizeChartTable>
    tips?: ReadonlyArray<SizeChartTip> // deprecated
    title?: string // deprecated
    video?: string | null
  }> {}

export enum TooltipContentTypes {
  ColorCategory = 'colorCategory',
}

export interface Tooltip
  extends Readonly<{
    id: string
    contentType: TooltipContentTypes
    enumValue: {
      name: string
    }
    title: string
    description: string
  }> {}

export interface ContentfulRegion {
  id: string
  name: string
  flagSrc: string
  shippingText: string
  taxAndDutyText: string
  welcomeShippingText: string
  welcomeTaxAndDutyText: string
  promoBarRegion: PromoBar | null
}

export interface RegionData
  extends Readonly<{
    regions: ContentfulRegion[]
    name: string
  }> {}

export interface ContentfulLocale
  extends Readonly<{
    language: string
    locale: string
  }> {}

export interface LocaleData
  extends Readonly<{
    locales: ContentfulLocale[]
    name: string
  }> {}

export interface SiteFixtures
  extends Readonly<{
    disableOneTrust: boolean | null
    disableOptimizely: boolean | null
    embroideryIcon?: string
    embroideryWarningMessage: string | null
    enablePDPAccentColor: boolean | null
    footer: SiteFooter
    handle: string
    localeData: LocaleData | null
    mensSizeCharts?: ReadonlyArray<SizeChart>
    miniCartMessage: string | null
    navigation: SiteNavigation
    nostoUpsell: boolean | null
    nostoUpsellId: string | null
    promoBar: PromoBar | null
    regionData: RegionData | null
    siteWidePromo: string | null
    enableSiteWidePromo: boolean | null
    tooltips: ReadonlyArray<Tooltip>
    upsellHandles: string[] | null
    womensSizeCharts?: ReadonlyArray<SizeChart>
    logoColorSelectionTooltip: string | null
    logoDigitizationDisclaimer: string | null
    logoDigitizationWarning: string | null
    logoLearnMorelink: string | null
  }> {}
