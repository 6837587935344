import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const VIDEO_SECTION_FRAGMENT = gql(`
  fragment VideoSectionFragment on VideoSection {
    buttonVariant
    id
    __typename
    imageDesktop
    imageMobile
    inlineVideoDesktop
    inlineVideoMobile
    inlineVimeoIdDesktop
    inlineVimeoIdMobile
    linkHref1
    linkHref2
    linkText1
    linkText2
    modalVideoId
    modalVideoPlatform
    subtitle
    textColor
    textContentMaxWidthDesktop
    textContentMaxWidthMobile
    title
    titleImageDesktop
    titleImageMobile
  }
`)
