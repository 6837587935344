import styled from 'styled-components'

import { fromMd, untilMd } from '@syconium/little-miss-figgy'

export const StyledImageWrapper = styled.div<{
  maxWidthMobile: number | null
  maxWidthDesktop: number | null
  marginBottom: number | null
}>`
  margin-bottom: ${o => o.marginBottom ?? 8}px;

  ${untilMd} {
    max-width: ${o => o.maxWidthMobile != null && `${o.maxWidthMobile}px`};
  }

  ${fromMd} {
    max-width: ${o => o.maxWidthDesktop != null && `${o.maxWidthDesktop}px`};
  }
`
