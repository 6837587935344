import styled from 'styled-components'

export const Container = styled.button`
  padding: 24px;
  position: absolute;
  inset-inline-end: 0;
  top: 0;

  svg {
    height: 16px;
    width: 16px;
  }

  g {
    stroke: ${o => o.theme.color.deprecated.obsidianLighter};
  }

  &:hover g {
    stroke: ${o => o.theme.color.deprecated.obsidian};
  }
`
