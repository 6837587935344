import { CSSProperties } from 'react'
import styled from 'styled-components'

import { fromMd, timingSlower } from '@syconium/little-miss-figgy'

import { PageWidthContainer } from '../../../lib/shared-styles'
import { zIndexNavHeader } from '../../nav/NavHeader/styles'

export const slideDuration = 400

export const Body = styled.aside<{
  backgroundColor: string
  isFadedOut: boolean
  textColor: string
  promoBarHeightDesktop: CSSProperties['height']
  promoBarHeightMobile: CSSProperties['height']
}>`
  background-color: ${o => o.backgroundColor};
  color: ${o => o.textColor};
  font-size: 10px;
  font-weight: 600;
  height: ${o => o.promoBarHeightMobile};
  line-height: 14px;
  opacity: ${o => (o.isFadedOut ? 0.2 : 1)};
  overflow: hidden;
  pointer-events: ${o => (o.isFadedOut ? 'none' : 'unset')};
  position: relative;
  transition: opacity ${timingSlower} ease;
  z-index: ${zIndexNavHeader + 1};

  ${fromMd} {
    font-size: 13px;
    height: ${o => o.promoBarHeightDesktop};
    line-height: 17px;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`

export const Page = styled.li<{
  promoBarHeightDesktopPixels: number
  promoBarHeightMobilePixels: number
}>`
  align-items: center;
  display: flex;
  height: ${o => o.promoBarHeightMobilePixels}px;
  justify-content: center;
  min-width: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;

  ${fromMd} {
    height: ${o => o.promoBarHeightDesktopPixels}px;
  }
`

export const Pages = styled.ul<{
  displayPagesLength: number
  promoBarHeightDesktopPixels: number
  promoBarHeightMobilePixels: number
  transition: boolean
}>`
  display: flex;
  height: ${o => o.promoBarHeightMobilePixels * o.displayPagesLength}px;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  ${Page} {
    transform: ${o =>
      o.transition ? `translateY(-${o.promoBarHeightMobilePixels}px)` : 'translateY(0)'};
    transition: transform ${o => (o.transition ? slideDuration : 0)}ms ease-in-out;
  }

  ${fromMd} {
    height: ${o => o.promoBarHeightDesktopPixels * o.displayPagesLength}px;
  }
`

export const Content = styled(PageWidthContainer)`
  display: flex;
  height: 100%;
  max-height: 100%;
`

export const CloseContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;

  ${fromMd} {
    right: 10px;
    top: 10px;
  }
`

export const CloseButton = styled.button`
  line-height: 0;
  padding: 5px;

  &:hover g {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`
