import * as React from 'react'
import { useTheme } from 'styled-components'

import { BaseIcon, IProps as IBaseIconProps } from '../BaseIcon'

export interface SelectMarkProps extends IBaseIconProps {
  viewbox?: string
  strokeWidth?: number
}

export const SelectMark: React.FC<SelectMarkProps> = ({
  height = '10px',
  stroke,
  viewbox = '0 0 10 10',
  width = '10px',
  strokeWidth = 1.5,
  ...rest
}) => {
  const { color } = useTheme()

  return (
    <BaseIcon {...{ height, width }} viewBox={viewbox} {...rest}>
      <g stroke={stroke || color.deprecated.black.primary} strokeWidth={strokeWidth}>
        <path className='p1' d='M8.695 1.095l-7.6 7.6' strokeWidth={strokeWidth} />
        <path className='p2' d='M8.695 8.695l-7.6-7.6' strokeWidth={strokeWidth} />
      </g>
    </BaseIcon>
  )
}
