import { QueryParamValue } from '../../types/utility'

export function addQueryParams(urlArg: string, params: Record<string, QueryParamValue>): string {
  if (!urlArg) return ''
  const [url, hash] = urlArg.split('#')
  const newQueryString = Object.entries(params)
    .map(([key, value]) => {
      if (value === true) return key
      if (value === false) return ''
      if (value === undefined) return ''
      return key + '=' + (Array.isArray(value) ? value.join() : value)
    })
    .filter(Boolean)
    .join('&')
  return url + (url?.includes('?') ? '&' : '?') + newQueryString + (hash ? '#' + hash : '')
}
