import styled from 'styled-components'

import { SelectMark } from '@syconium/little-miss-figgy'

import { Body as NavListItemBody } from '../NavListItem/styles'

export const Body = styled.div`
  position: relative;

  span {
    font-size: 16px;
    font-weight: 700;
  }

  ${NavListItemBody} {
    padding: 12px 0;
  }
`

export const TopLine = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  span {
    color: ${o => o.theme.color.deprecated.obsidianLighter};
    padding: 10px 0 24px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  inset-inline-end: 0;
  top: 10px;
`

export const CloseButtonIcon = styled(SelectMark)`
  height: 16px;
  width: 16px;
`

export const modalContentStyles = {
  background: 'white',
  borderRadius: 0,
  borderWidth: 0,
  bottom: 'auto',
  left: 0,
  maxHeight: '100%',
  overflow: 'hidden',
  padding: '24px 32px',
  right: 0,
  top: 0,
  transform: 'none',
  transition: 'none',
  zIndex: 1000,
}

export const modalOverlayStyles = {
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
}
