import { useLazyQuery } from '@apollo/client'
import { useMemo } from 'react'

import { SEARCH_AUTOCOMPLETE } from '../../lib/graphql/queries/SEARCH_AUTOCOMPLETE'
import { SearchAutocompleteProduct, SearchAutocompleteResults } from '../../types/graphql'

export function useSearchAutocomplete() {
  const [fetchSearchAutocompleteResults, { data, previousData, error, loading }] = useLazyQuery<{
    search: SearchAutocompleteResults
  }>(SEARCH_AUTOCOMPLETE, { notifyOnNetworkStatusChange: true })

  const products = (
    data?.search?.items?.nodes.map(node => node.product) ??
    previousData?.search?.items?.nodes.map(node => node.product) ??
    []
  ).map(product => {
    const url = `/${product.category == 'Product Kit' ? 'kits' : 'products'}/${product.handle}`
    return {
      ...product,
      url,
    } as SearchAutocompleteProduct
  })
  const suggestions = useMemo(() => {
    return (
      data?.search?.suggestions?.map(suggestion => suggestion.query) ??
      previousData?.search?.suggestions?.map(suggestion => suggestion.query) ??
      []
    )
  }, [data, previousData])
  const queryRan = data || error
  const noResult = !loading && products.length === 0 && queryRan

  return { fetchSearchAutocompleteResults, products, suggestions, error, noResult }
}
