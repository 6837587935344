import { FC } from 'react'

import { Media } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { CurrentUser } from '@syconium/magnolia/src/types/figs'

import { LoggedInTextWrapper, UserInitials, Wrapper } from './styles'

export const LoggedInUserAccountTab: FC<{ user: CurrentUser }> = ({ user }) => {
  const {
    magnolia: {
      account: { navigation: strings },
    },
  } = useFixturesContext()

  return (
    <Wrapper>
      <UserInitials>
        {user.firstName?.[0]}
        {user.lastName?.[0]}
      </UserInitials>
      <Media greaterThanOrEqual='md'>
        <LoggedInTextWrapper>
          {strings.menu.hi}
          {user.firstName}
        </LoggedInTextWrapper>
      </Media>
    </Wrapper>
  )
}
