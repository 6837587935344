import { css } from 'styled-components'

import { User } from '@syconium/little-miss-figgy'
import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { AuthenticationContainer } from '../../../containers/AuthenticationContainer'
import { useAccountMenuItems } from '../useAccountMenuItems'

import { LoggedInUserAccountTab } from './AccountTab'

export function useAccountTab(): NavTab {
  const { accountMenuItems } = useAccountMenuItems()
  const { currentUser, isLoggedIn } = AuthenticationContainer.useContainer()

  const accountTab: NavTab = {
    ariaLabel: 'Account',
    href: null,
    id: 'account',
    render: () =>
      isLoggedIn && currentUser ? <LoggedInUserAccountTab user={currentUser} /> : <User />,
    sections: [
      {
        __typename: 'NavListSection',
        id: 'account',
        items: accountMenuItems,
        variant: 'default',
        styles: css`
          position: absolute;
          inset-inline-end: 62px;
        `,
      },
    ],
    text: '',
    textColor: null,
    wrapperElement: 'button',
  }

  return accountTab
}
