'use client'

import {
  ApolloClient,
  ApolloLink,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client'
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { catalogCacheConfig } from '@syconium/weeping-figs'

import { QueryStatus } from '../../lib/hooks/types'
import { cookieKeys } from '../_config/Cookies.config'
import {
  GraphQlClients,
  getGraphqlClientLinks,
  graphqlClientsDefaultOptions,
} from '../_config/GraphqlClient.config'

import { useCookies } from './CookiesProvider.client'
import { useLocalization } from './LocalizationProvider.client'
import { usePreviewDirectives } from './PreviewDirectivesProvider.client'

export type UseGraphqlClientsProps =
  | {
      cookies: ReadonlyRequestCookies
    }
  | undefined

const useMakeFigsGraphqlClient = ({
  alias,
  authorized,
}: {
  alias: string
  authorized: boolean
}) => {
  const [cookies, _setCookie, _removeCookie] = useCookies([cookieKeys.authToken.key])
  const authToken: string | undefined = cookies[cookieKeys.authToken.key]
  const localization = useLocalization()
  const previewDirectives = usePreviewDirectives()
  const link = getGraphqlClientLinks({
    includeAuth: authorized,
    authToken,
    graphAlias: alias,
    localization,
    previewDirectives,
  })
  const makeFigsGraphqlClient = useCallback(() => {
    return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache(catalogCacheConfig),
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              // in a SSR environment, if you use multipart features like
              // @defer, you need to decide how to handle these.
              // This strips all interfaces with a `@defer` directive from your queries.
              new SSRMultipartLink({
                stripDefer: true,
              }),
              link,
            ])
          : link,
      defaultOptions: graphqlClientsDefaultOptions,
    })
  }, [link])
  return makeFigsGraphqlClient
}

const useGraphqlClientsImpl = () => {
  const makeFigsGraphqlClient = useMakeFigsGraphqlClient({
    alias: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === 'true' ? '' : 'shop',
    authorized: true,
  })
  return {
    authorizedClient: makeFigsGraphqlClient(),
  }
}

const GraphqlClientsContainer = createContainer(useGraphqlClientsImpl)
export const GraphqlClientsProvider = ({ children }: { children: React.ReactNode }) => {
  const makeFigsGraphqlClient = useMakeFigsGraphqlClient({
    alias: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === 'true' ? '' : 'catalog',
    authorized: false,
  })
  return (
    <ApolloNextAppProvider makeClient={makeFigsGraphqlClient}>
      <GraphqlClientsContainer.Provider>{children}</GraphqlClientsContainer.Provider>
    </ApolloNextAppProvider>
  )
}

export const useGraphqlClients = (): {
  cacheOptimizedClient: ApolloClient<object>
} & ReturnType<typeof GraphqlClientsContainer.useContainer> => {
  const mainClient = useApolloClient()
  const additionalClients = GraphqlClientsContainer.useContainer()
  return {
    cacheOptimizedClient: mainClient,
    ...additionalClients,
  }
}

export const useNextQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: Omit<QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'client'> & {
    client?: GraphQlClients
  }
) => {
  const additionalClients = GraphqlClientsContainer.useContainer()
  const queryResult = useQuery(query, {
    ...queryOptions,
    client:
      queryOptions?.client === 'auth-supported' ? additionalClients.authorizedClient : undefined,
  })
  const status = useMemo<QueryStatus>(() => {
    if (!queryResult.called) return 'idle'
    if (queryResult.loading) return 'pending'
    if (queryResult.error) return 'rejected'
    return 'resolved'
  }, [queryResult.called, queryResult.error, queryResult.loading])
  return {
    ...queryResult,
    status,
  }
}

export const useNextMutation = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: Omit<MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'client'> & {
    client?: GraphQlClients
  }
) => {
  const additionalClients = GraphqlClientsContainer.useContainer()
  const [mutation, mutationResult] = useMutation(query, {
    ...queryOptions,
    client:
      queryOptions?.client === 'auth-supported' ? additionalClients.authorizedClient : undefined,
  })
  const status = useMemo<QueryStatus>(() => {
    if (!mutationResult.called) return 'idle'
    if (mutationResult.loading) return 'pending'
    if (mutationResult.error) return 'rejected'
    return 'resolved'
  }, [mutationResult.called, mutationResult.error, mutationResult.loading])
  const output = useMemo(() => {
    return [
      mutation,
      {
        ...mutationResult,
        status,
      },
    ] as const
  }, [mutation, mutationResult, status])
  return output
}
