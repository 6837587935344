import { GetTileProductGroupForCollectionQueryVariables } from '../../../../../../../__generated__/graphql/catalog/graphql'

import type {
  EditorialCellPlacement,
  EditorialRow,
  Product,
} from '../../../../../../../types/graphql'

type TileProductCollectionScope = {
  collectionHandle: string
  collectionColorScope: GetTileProductGroupForCollectionQueryVariables['collectionColorScope']
}

export type TileProduct = Product & {
  __typename?: 'Product'
  collectionScope: TileProductCollectionScope | undefined
}

export type TitleTile = { __typename: 'TitleTile'; id: string; title: string; subtitle?: string }

export type TileItem = EditorialCellPlacement | EditorialRow | TileProduct | TitleTile

export enum EditorialCellDescriptionSize {
  'Small (Text Only, Two Up, or Wide)' = 'Small (Text Only, Two Up, or Wide)',
  'Medium (Text Only)' = 'Medium (Text Only)',
  'Medium (Single Tile)' = 'Medium (Single Tile)',
  'Large (Text Only or Two Up)' = 'Large (Text Only or Two Up)',
}

export enum EditorialCellTitle {
  'Large (Text Only)' = 'Large (Text Only)',
  'Medium (Two Up or Wide)' = 'Medium (Two Up or Wide)',
}

export enum EditorialCellDescriptionPosition {
  'Below Image' = 'BelowImage',
  'Above image' = 'AboveImage',
  'Bottom' = 'Bottom',
  'Text only (40px padding top and bottom)' = 'TextOnly',
}
