import styled from 'styled-components'

export const Container = styled.span`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 1.5;
  padding-top: 2px;
`

export const MatchingText = styled.b`
  color: black;
  font-weight: bold;
`
