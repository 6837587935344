import uniqBy from 'lodash/uniqBy';
import { fromPromise, createHttpLink, ApolloLink, ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { TokenAuthStorageSingleton } from '@syconium/iron-figs';
import { onError } from '@apollo/client/link/error';
import { stripIgnoredCharacters } from 'graphql/utilities/stripIgnoredCharacters';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloLink as ApolloLink$1, Observable } from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { print } from 'graphql/language/printer';

const catalogCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        collectionGridSection: {
          keyArgs: ["id"],
          read: (existing, options) => {
            if (existing)
              return existing;
            const collectionGridFieldCacheKey = `CollectionGridSection:${options.args?.id ?? ""}`;
            const cachedCollectionGridField = options.cache.extract()[collectionGridFieldCacheKey];
            if (cachedCollectionGridField)
              return { __ref: collectionGridFieldCacheKey };
            return void 0;
          }
        }
      }
    },
    CollectionView: {
      fields: {
        seoContent: {
          merge: true
        }
      }
    },
    Product: {
      fields: {
        colorInfo: {
          merge: true
        },
        defaultVariant: {
          merge(existing, incoming, { mergeObjects }) {
            const isMatch = existing && existing.shopifyId && incoming.shopifyId && existing.shopifyId === incoming.shopifyId;
            if (isMatch) {
              return mergeObjects(existing, incoming);
            } else {
              return incoming;
            }
          }
        },
        fitModel: {
          merge: true
        },
        review: {
          merge: true
        }
      },
      keyFields: (product) => {
        const { __typename, id } = product;
        const identifier = (() => {
          if (id)
            return id;
          return Math.random().toString();
        })();
        return `${__typename}:${identifier}`;
      }
    },
    SearchContext: {
      fields: {
        items: {
          keyArgs: false,
          merge(existing, incoming) {
            const existingNodes = existing?.nodes ?? [];
            const allItemNodes = uniqBy(
              [...existingNodes, ...incoming.nodes],
              (node) => node.product.__ref
            );
            return {
              ...incoming,
              nodes: allItemNodes
            };
          }
        }
      }
    },
    CollectionGridSection: {
      // TODO: We should look into this more. This is here just because without it,
      // hovering a tile on the PLP causes the PLP paging to break. But it shouldn't
      // require globally altering CollectionGridSection. I would think there is just some
      // specific nested fields within it that need some merge guidance.
      merge: true
    },
    Collection: {
      fields: {
        items: {
          keyArgs: ["selectedFilters", "grouping", "first"],
          merge(existing, incoming, opts) {
            const existingNodes = existing?.nodes ?? [];
            if (opts.args && !opts.args.after && existingNodes.length > 0)
              return incoming;
            const incomingNodes = incoming?.nodes ?? [];
            const allItemNodes = uniqBy([...existingNodes, ...incomingNodes], (node) => node.__ref);
            return {
              ...incoming,
              nodes: allItemNodes
            };
          }
        }
      }
    },
    ProductGroup: {
      fields: {
        sizeChart: {
          merge: true
        },
        sizeChartCG: {
          merge: true
        },
        priceRange: {
          merge: true
        },
        defaultVariant: {
          merge(existing, incoming, { mergeObjects }) {
            const isMatch = existing && existing.shopifyId && incoming.shopifyId && existing.shopifyId === incoming.shopifyId;
            if (isMatch) {
              return mergeObjects(existing, incoming);
            } else {
              return incoming;
            }
          }
        },
        variants: {
          merge(existing, incoming) {
            const existingNodes = existing?.nodes ?? [];
            const incomingNodes = incoming?.nodes ?? [];
            const allItemNodes = uniqBy([...existingNodes, ...incomingNodes], (node) => node.__ref);
            return {
              ...incoming,
              nodes: allItemNodes
            };
          }
        }
      },
      keyFields: (productGroup) => {
        const { __typename, collectionHandle, id } = productGroup;
        const identifier = (() => {
          if (collectionHandle && id)
            return `${collectionHandle}:${id}`;
          if (id)
            return id;
          return Math.random().toString();
        })();
        return `${__typename}:${identifier}`;
      }
    }
  }
};

const defaultCacheConfig = {};

const mergeFactory = (itemsField) => {
  return (existing, incoming) => {
    if (!existing)
      return incoming;
    const existingItems = existing[itemsField] || [];
    const incomingItems = incoming[itemsField] || [];
    return {
      ...incoming,
      [itemsField]: [...existingItems, ...incomingItems]
    };
  };
};

const shopCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        shopOrders: {
          keyArgs: ["id"],
          merge: mergeFactory("shopOrders")
        }
      }
    }
  }
};

function figsAuthLink() {
  return setContext((_, { headers }) => {
    const token = TokenAuthStorageSingleton.getInstance().getToken();
    if (!token) {
      return { headers };
    }
    return {
      headers: {
        ...headers,
        authorization: token
      }
    };
  });
}

const figsAuthFailoverLink = (handleError) => {
  return onError((response) => {
    const { operation, forward } = response;
    if (handleError && response.graphQLErrors?.[0]?.extensions?.code === "UNAUTHENTICATED") {
      return fromPromise(handleError().catch((_) => {
      })).flatMap(() => {
        const tokenSingleton = TokenAuthStorageSingleton.getInstance();
        const accessToken = tokenSingleton.getToken();
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `${accessToken}`
          }
        });
        return forward(operation);
      });
    }
    return void 0;
  });
};

const figsFetch = (input, init) => {
  if (init?.method === "GET") {
    deleteHeader(init.headers, "content-type");
  }
  if (isRequest(input)) {
    return fetch(input, init);
  }
  const url = urlWithRelativeSupportAndQueryStripping(input);
  return fetch(url.toString(), init);
};
function isRequest(info) {
  return !!info.url;
}
function deleteHeader(headers, key) {
  if (!headers)
    return;
  if (isHeaders(headers))
    headers.delete(key);
  else if (!Array.isArray(headers))
    delete headers[key];
}
function isHeaders(headers) {
  return !Array.isArray(headers) && !!headers.delete;
}
function urlWithRelativeSupportAndQueryStripping(info) {
  let url;
  if (typeof info === "string") {
    const requestUriIsRelative = info.startsWith("/");
    if (requestUriIsRelative) {
      url = new URL(info, globalThis.location?.origin);
    } else {
      url = new URL(info);
    }
  } else {
    url = new URL(info);
  }
  const query = url.searchParams.get("query");
  if (query) {
    url.searchParams.set("query", stripIgnoredCharacters(query));
  }
  return url.toString();
}

function figsHttpLink(baseUrl, graph) {
  const baseUrlOrUri = baseUrl === "/" ? `/${graph}/graphql` : new URL(`${graph}/graphql`, baseUrl).toString();
  return createHttpLink({
    useGETForQueries: true,
    fetch: figsFetch,
    fetchOptions: {
      mode: "cors"
    },
    uri: baseUrlOrUri
  });
}

var __defProp$1 = Object.defineProperty;
var __defNormalProp$1 = (obj, key, value) => key in obj ? __defProp$1(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField$1 = (obj, key, value) => {
  __defNormalProp$1(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const defaultBuildServiceFilterHeaderArgs = {
  includeUnavailable: false,
  isPreview: false,
  launchTags: void 0
};
const _ServiceFilterPairs = class _ServiceFilterPairs {
  constructor() {
  }
  static buildServiceFilterHeader({
    includeUnavailable = defaultBuildServiceFilterHeaderArgs.includeUnavailable,
    isPreview = defaultBuildServiceFilterHeaderArgs.isPreview,
    launchTags = defaultBuildServiceFilterHeaderArgs.launchTags,
    customerTier = defaultBuildServiceFilterHeaderArgs.customerTier
  } = defaultBuildServiceFilterHeaderArgs) {
    const headerPairs = [];
    if (includeUnavailable || this.unavailableProducts) {
      headerPairs.push(_ServiceFilterPairs.IncludeUnavailableProductDataPair);
    }
    if (isPreview || this.previewContent) {
      headerPairs.push(_ServiceFilterPairs.PreviewContentPair);
    }
    if (launchTags?.length || this.launchTags?.length) {
      const tags = this.launchTags?.length ? this.launchTags : launchTags;
      headerPairs.push(`launch-tags=${tags}`);
    }
    if (customerTier || this.customerTier) {
      headerPairs.push(`customer-tier=${customerTier ?? this.customerTier}`);
    }
    if (headerPairs.length === 0)
      return;
    return headerPairs.join("; ");
  }
  static includePreviewContent() {
    this.previewContent = true;
  }
  static includeUnavailableProducts() {
    this.unavailableProducts = true;
  }
  static includeLaunchTags(tags) {
    this.launchTags = tags;
  }
  static includeCustomerTier(customerTier) {
    this.customerTier = customerTier;
  }
};
__publicField$1(_ServiceFilterPairs, "PreviewContentPair", "content=preview");
__publicField$1(_ServiceFilterPairs, "IncludeUnavailableProductDataPair", "product-data=include-unavailable");
__publicField$1(_ServiceFilterPairs, "unavailableProducts", false);
__publicField$1(_ServiceFilterPairs, "previewContent", false);
__publicField$1(_ServiceFilterPairs, "launchTags");
__publicField$1(_ServiceFilterPairs, "customerTier");
let ServiceFilterPairs = _ServiceFilterPairs;

function figsServiceSelectionLink(serviceFilterPairsContext) {
  return setContext((_request, ctx) => {
    const headers = ctx.headers || {};
    const serviceFilterHeader = ServiceFilterPairs.buildServiceFilterHeader({
      includeUnavailable: ctx.includeUnavailable ?? serviceFilterPairsContext?.includeUnavailable,
      isPreview: ctx.isPreview ?? serviceFilterPairsContext?.isPreview,
      launchTags: ctx.launchTags ?? serviceFilterPairsContext?.launchTags,
      customerTier: ctx.customerTier ?? serviceFilterPairsContext?.customerTier
    });
    if (serviceFilterHeader) {
      headers["x-figs-service-filter"] = serviceFilterHeader;
    }
    return { ...ctx, headers };
  });
}

const figsDevEnvs = ["test", "local", "live"];
const figsDeploymentEnvs = ["sandbox", "staging", "prod"];
const figsEnvs = [...figsDevEnvs, ...figsDeploymentEnvs];
function isFigsEnv(maybeFigsEnv) {
  return typeof maybeFigsEnv === "string" && figsEnvs.includes(maybeFigsEnv);
}
const processEnv = process.env.FIGS_ENV || process.env.NEXT_PUBLIC_FIGS_ENV;
const figsEnv = isFigsEnv(processEnv) ? processEnv : "local";
const brunswickEnv = figsEnv === "prod" ? "" : `-${figsEnv}`;

const shopAddressabilityConfig = {
  defaultDomainPrefix: process.env.NEXT_PUBLIC_DEFAULT_INGRESS_DOMAIN_PREFIX || "www",
  implicitEnvList: ["prod"],
  selfContainedEnvList: ["local", "live"],
  ingressBaseDomain: process.env.NEXT_PUBLIC_INGRESS_BASE_DOMAIN || "wearfigs.com"
};
function shopCookieDomain() {
  if (shopAddressabilityConfig.selfContainedEnvList.includes(figsEnv))
    return void 0;
  const implicitEnv = shopAddressabilityConfig.implicitEnvList.includes(figsEnv);
  let subdomain = implicitEnv ? "" : `${figsEnv}.`;
  return `.${subdomain.toLocaleLowerCase()}${shopAddressabilityConfig.ingressBaseDomain}`;
}
const cookieDefaultMaxAge = 26e5;
const cookieDefaultOptions = {
  path: "/",
  maxAge: cookieDefaultMaxAge,
  httpOnly: false,
  domain: shopCookieDomain()
};
function requestedCookieContext(cookies) {
  if (!cookies) {
    return {};
  }
  return {
    region: cookies.get(regionKey),
    locale: cookies.get(localeKey),
    currency: cookies.get(currencyKey)
  };
}

function serializeContextToHeaderTarget(ctx, target) {
  const serialized = serializeContext(ctx);
  for (const [key, value] of Object.entries(serialized)) {
    target.header(key, value);
  }
}
function requestedHeaderContext(headers) {
  if (!headers) {
    return {};
  }
  return {
    region: oneOf(headers[regionKey] || headers[ingressDetectedCountryKey]),
    locale: oneOf(headers[localeKey]),
    currency: oneOf(headers[currencyKey]),
    name: oneOf(headers[nameKey])
  };
}
function oneOf(headerValue) {
  if (Array.isArray(headerValue)) {
    return headerValue[0];
  }
  return headerValue;
}

const supportedRegionIdentifiers = [
  "AE",
  "AT",
  "AU",
  "BE",
  "CA",
  "CR",
  "CO",
  "DE",
  "DO",
  "ES",
  "FI",
  "FR",
  "GB",
  "KW",
  "IE",
  "IL",
  "IT",
  "LU",
  "MX",
  "NL",
  "NZ",
  "PA",
  "PH",
  "PL",
  "PT",
  "SA",
  "SG",
  "US",
  "TR",
  "BR",
  "CH",
  "SE",
  "NO",
  "DK"
];
const supportedLanguageGroupIdentifiers = ["de", "en", "es", "fr"];
const supportedLocaleIdentifiers = [
  "ar-KW",
  "de-DE",
  "en-AE",
  "en-AU",
  "en-BE",
  "en-CA",
  "en-CR",
  "en-DO",
  "en-EU",
  "en-GB",
  "en-IE",
  "en-NZ",
  "en-PA",
  "en-PH",
  "en-SA",
  "en-SG",
  "en-US",
  "es-MX",
  "es-US",
  "fr-CA",
  "fr-FR",
  "he-IL",
  "en-CO",
  "es-ES",
  "en-TR",
  "en-BR",
  "en-CH",
  "en-NO",
  "es-CR",
  "es-DO",
  "es-CO",
  "es-PA"
];
const defaultLocale = "en-US";
const flagSrcMap = {
  US: "https://cdn.shopify.com/s/files/1/0139/8942/files/flag-us-icon.png?v=1607119686",
  CA: "https://cdn.shopify.com/s/files/1/0139/8942/files/flag-ca-icon.png?v=1607119998",
  AU: "https://cdn.shopify.com/s/files/1/0139/8942/files/flag-au-icon.png?v=1607120288",
  default: "https://cdn.shopify.com/s/files/1/0139/8942/files/flag-globe-icon.png?v=1607119990"
};
const unitedStates = {
  id: "US",
  isEnabled: true,
  name: "United States",
  currency: "USD",
  defaultLocale: "en-US",
  languages: {
    en: {
      locale: "en-US",
      isEnabled: true
    },
    es: {
      locale: "es-US",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.US
};
const brazil = {
  id: "BR",
  isEnabled: true,
  name: "Brazil",
  currency: "BRL",
  defaultLocale: "en-BR",
  languages: { en: { locale: "en-BR", isEnabled: true } },
  flagSrc: flagSrcMap.default
};
const canada = {
  id: "CA",
  isEnabled: true,
  name: "Canada",
  currency: "CAD",
  defaultLocale: "en-CA",
  languages: {
    en: {
      locale: "en-CA",
      isEnabled: true
    },
    fr: {
      locale: "fr-CA",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.CA
};
const denmark = {
  id: "DK",
  isEnabled: true,
  name: "Denmark",
  currency: "DKK",
  defaultLocale: "en-EU",
  languages: { en: { locale: "en-EU", isEnabled: true } },
  flagSrc: flagSrcMap.default
};
const austria = {
  id: "AT",
  isEnabled: true,
  name: "Austria",
  currency: "EUR",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const australia = {
  id: "AU",
  isEnabled: true,
  name: "Australia",
  currency: "AUD",
  defaultLocale: "en-AU",
  languages: {
    en: {
      locale: "en-AU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.AU
};
const unitedKingdom = {
  id: "GB",
  isEnabled: true,
  name: "United Kingdom",
  currency: "GBP",
  defaultLocale: "en-GB",
  languages: {
    en: {
      locale: "en-GB",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const belgium = {
  id: "BE",
  isEnabled: true,
  name: "Belgium",
  currency: "EUR",
  defaultLocale: "en-BE",
  languages: {
    en: {
      locale: "en-BE",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const colombia = {
  id: "CO",
  isEnabled: true,
  name: "Colombia",
  currency: "COP",
  defaultLocale: "es-MX",
  languages: {
    en: {
      locale: "en-CO",
      isEnabled: true
    },
    es: {
      locale: "es-MX",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const costaRica = {
  id: "CR",
  isEnabled: true,
  name: "Costa Rica",
  currency: "CRC",
  defaultLocale: "es-MX",
  languages: {
    en: {
      locale: "en-CR",
      isEnabled: true
    },
    es: {
      locale: "es-MX",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const dominicanRepublic = {
  id: "DO",
  isEnabled: true,
  name: "Dominican Republic",
  currency: "DOP",
  defaultLocale: "es-MX",
  languages: {
    en: {
      locale: "en-DO",
      isEnabled: true
    },
    es: {
      locale: "es-MX",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const finland = {
  id: "FI",
  isEnabled: true,
  name: "Finland",
  currency: "EUR",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const france = {
  id: "FR",
  isEnabled: true,
  name: "France",
  currency: "EUR",
  defaultLocale: "fr-FR",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    },
    fr: {
      locale: "fr-FR",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const germany = {
  id: "DE",
  isEnabled: true,
  name: "Germany",
  currency: "EUR",
  defaultLocale: "de-DE",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    },
    de: {
      locale: "de-DE",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const ireland = {
  id: "IE",
  isEnabled: true,
  name: "Ireland (Republic of)",
  currency: "EUR",
  defaultLocale: "en-IE",
  languages: {
    en: {
      locale: "en-IE",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const italy = {
  id: "IT",
  isEnabled: true,
  name: "Italy",
  currency: "EUR",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const israel = {
  id: "IL",
  isEnabled: true,
  name: "Israel",
  currency: "ILS",
  defaultLocale: "he-IL",
  languages: {
    en: {
      locale: "he-IL",
      // Currently he-IL is filled with English in Contentful
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const kuwait = {
  id: "KW",
  isEnabled: true,
  name: "Kuwait",
  currency: "USD",
  defaultLocale: "ar-KW",
  languages: {
    en: {
      locale: "ar-KW",
      // Currently ar-KW is filled with English in Contentful
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const luxembourg = {
  id: "LU",
  isEnabled: true,
  name: "Luxembourg",
  currency: "EUR",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const mexico = {
  id: "MX",
  isEnabled: true,
  name: "Mexico",
  currency: "MXN",
  defaultLocale: "es-MX",
  languages: {
    es: {
      locale: "es-MX",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const norway = {
  id: "NO",
  isEnabled: true,
  name: "Norway",
  currency: "NOK",
  defaultLocale: "en-NO",
  languages: { en: { locale: "en-NO", isEnabled: true } },
  flagSrc: flagSrcMap.default
};
const netherlands = {
  id: "NL",
  isEnabled: true,
  name: "Netherlands",
  currency: "EUR",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const newZealand = {
  id: "NZ",
  isEnabled: true,
  name: "New Zealand",
  currency: "NZD",
  defaultLocale: "en-NZ",
  languages: {
    en: {
      locale: "en-NZ",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const panama = {
  id: "PA",
  isEnabled: true,
  name: "Panama",
  currency: "USD",
  defaultLocale: "es-MX",
  languages: {
    en: {
      locale: "en-PA",
      isEnabled: true
    },
    es: {
      locale: "es-MX",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const philippines = {
  id: "PH",
  isEnabled: true,
  name: "Philippines",
  currency: "PHP",
  defaultLocale: "en-PH",
  languages: {
    en: {
      locale: "en-PH",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const poland = {
  id: "PL",
  isEnabled: true,
  name: "Poland",
  currency: "PLN",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const portugal = {
  id: "PT",
  isEnabled: true,
  name: "Portugal",
  currency: "EUR",
  defaultLocale: "en-EU",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const saudiArabia = {
  id: "SA",
  isEnabled: true,
  name: "Saudi Arabia",
  currency: "SAR",
  defaultLocale: "en-SA",
  languages: {
    en: {
      locale: "en-SA",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const singapore = {
  id: "SG",
  isEnabled: true,
  name: "Singapore",
  currency: "SGD",
  defaultLocale: "en-SG",
  languages: {
    en: {
      locale: "en-SG",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const spain = {
  id: "ES",
  isEnabled: true,
  name: "Spain",
  currency: "EUR",
  defaultLocale: "es-ES",
  languages: {
    en: {
      locale: "en-EU",
      isEnabled: true
    },
    es: {
      locale: "es-ES",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const Sweden = {
  id: "SE",
  isEnabled: true,
  name: "Sweden",
  currency: "SEK",
  defaultLocale: "en-EU",
  languages: { en: { locale: "en-EU", isEnabled: true } },
  flagSrc: flagSrcMap.default
};
const switzerland = {
  id: "CH",
  isEnabled: true,
  name: "Switzerland",
  currency: "CHF",
  defaultLocale: "en-CH",
  languages: { en: { locale: "en-CH", isEnabled: true } },
  flagSrc: flagSrcMap.default
};
const Turkey = {
  id: "TR",
  isEnabled: false,
  name: "Turkey",
  currency: "USD",
  defaultLocale: "en-TR",
  languages: { en: { locale: "en-TR", isEnabled: true } },
  flagSrc: flagSrcMap.default
};
const uae = {
  id: "AE",
  isEnabled: true,
  name: "United Arab Emirates",
  currency: "AED",
  defaultLocale: "en-AE",
  languages: {
    en: {
      locale: "en-AE",
      isEnabled: true
    }
  },
  flagSrc: flagSrcMap.default
};
const supportedRegions = {
  default: unitedStates,
  AE: uae,
  AT: austria,
  AU: australia,
  BE: belgium,
  BR: brazil,
  DK: denmark,
  CA: canada,
  CO: colombia,
  CH: switzerland,
  CR: costaRica,
  DE: germany,
  DO: dominicanRepublic,
  ES: spain,
  FI: finland,
  FR: france,
  GB: unitedKingdom,
  IE: ireland,
  IL: israel,
  IT: italy,
  KW: kuwait,
  LU: luxembourg,
  MX: mexico,
  NO: norway,
  NL: netherlands,
  NZ: newZealand,
  PA: panama,
  PH: philippines,
  PL: poland,
  PT: portugal,
  SA: saudiArabia,
  TR: Turkey,
  SE: Sweden,
  SG: singapore,
  US: unitedStates
};

function isValidLocale(locale) {
  const supportedLocaleIdentifiersAsStrings = supportedLocaleIdentifiers;
  return supportedLocaleIdentifiersAsStrings.indexOf(locale) > -1;
}
function isValidCountryCode(countryCode) {
  const supportedRegionIdentifiersAsStrings = supportedRegionIdentifiers;
  return supportedRegionIdentifiersAsStrings.indexOf(countryCode) > -1;
}
function isValidLanguageGroup(language) {
  const supportedLanguageGroupIdentifiersAsStrings = supportedLanguageGroupIdentifiers;
  return supportedLanguageGroupIdentifiersAsStrings.indexOf(language) > -1;
}
function filterRegion(region, options) {
  if (options?.countryCode && region.id !== options.countryCode) {
    return false;
  }
  if (options?.isEnabled !== void 0 && region.isEnabled !== options.isEnabled) {
    return false;
  }
  return true;
}
function retrieveRegion(options) {
  const matches = retrieveRegions(options);
  const firstMatch = matches.length === 1 ? matches[0] : null;
  if (!firstMatch && options?.defaultFallback !== true) {
    return null;
  }
  return firstMatch ? firstMatch : supportedRegions.default;
}
function retrieveRegions(options) {
  const matches = Object.entries(supportedRegions).filter(([key, region]) => {
    if (key === "default") {
      return false;
    }
    return filterRegion(region, options);
  }).map(([_key, region]) => {
    return region;
  });
  return matches;
}
function retrieveLocale(options) {
  let result = null;
  let region = null;
  if (typeof options?.region === "string") {
    region = retrieveRegion({
      countryCode: options.region,
      defaultFallback: false
    });
  } else if (options?.region) {
    region = options.region;
  }
  const fallbackResult = region ? region.defaultLocale : defaultLocale;
  const validResults = region ? retrieveLocalesForRegion(region, {
    language: options?.language
  }) : supportedLocaleIdentifiers;
  if (options?.language && region && isValidLanguageGroup(options?.language)) {
    const regionalLanguage = region.languages[options.language] ?? null;
    result = regionalLanguage ? regionalLanguage.locale : result;
  }
  if (options?.locale) {
    if (result && result !== options.locale) {
      result = null;
    } else if (isValidLocale(options.locale)) {
      result = validResults.includes(options.locale) ? options.locale : result;
    }
  }
  if (!result && options?.defaultFallback !== true) {
    return null;
  }
  return result ?? fallbackResult;
}
function retrieveLanguage(options) {
  const match = Object.entries(options.region.languages).find(
    ([_languageIdentifier, languageMetadata]) => {
      return options.isEnabled && !languageMetadata.isEnabled ? false : options.locale === languageMetadata.locale;
    }
  )?.[0];
  const defaultLanguage = Object.entries(options.region.languages)[0][0];
  if (!match && options.defaultFallback !== true) {
    return null;
  }
  return match ?? defaultLanguage;
}
function retrieveLocalesForRegion(region, options) {
  const results = Object.entries(region.languages).reduce(
    (results2, [key, language]) => {
      if (options?.isEnabled !== void 0 && language.isEnabled !== options.isEnabled) {
        return results2;
      }
      if (options?.language && key !== options.language) {
        return results2;
      }
      results2.push(language.locale);
      return results2;
    },
    []
  );
  return results;
}
function retrieveLanguageGroupsForRegion(region, options) {
  const results = Object.entries(region.languages).reduce(
    (results2, [key, language]) => {
      if (options?.isEnabled !== void 0 && language.isEnabled !== options.isEnabled) {
        return results2;
      }
      if (options?.locale !== void 0 && language.locale !== options.locale) {
        return results2;
      }
      results2.push(key);
      return results2;
    },
    []
  );
  return results;
}
function retrieveEnabledLocales(region) {
  const results = Object.entries(region.languages).reduce(
    (results2, [_, language]) => {
      if (language.isEnabled) {
        results2.push(language.locale);
      }
      return results2;
    },
    []
  );
  return results;
}
function retrieveLanguageRegionPairs() {
  const lr = [];
  Object.entries(supportedRegions).forEach(([key, region]) => {
    if (key !== "default") {
      Object.entries(region.languages).forEach(([langKey, value]) => {
        if (value.isEnabled) {
          lr.push(`${langKey}-${region.id}`);
        }
      });
    }
  });
  return lr;
}

function requestedPathParamContext(urlPath) {
  const firstPathParam = urlPath?.split("/")[1];
  const languageCountryPair = firstPathParam;
  if (languageCountryPair) {
    const language = languageCountryPair.split("-")[0].toLocaleLowerCase();
    const country = languageCountryPair.split("-").reverse()[0].toUpperCase();
    const supportedRegion = retrieveRegion({ countryCode: country, defaultFallback: false });
    if (supportedRegion) {
      const supportedLocale = retrieveLocale({
        language,
        region: supportedRegion,
        defaultFallback: false
      });
      if (supportedLocale) {
        return {
          region: supportedRegion.id,
          locale: supportedLocale,
          currency: supportedRegion.currency
        };
      }
    }
  }
  return {};
}

const prefix = "x-figs-shop";
const ingressDetectedCountryKey = "cloudfront-viewer-country";
const regionKey = `${prefix}-region`;
const localeKey = `${prefix}-locale`;
const currencyKey = `${prefix}-currency`;
const nameKey = `${prefix}-name`;
const legacyLocaleKey = "userLocale";
const detectedCountryEchoKey = `${prefix}-detected-country`;
function serializeContext(ctx) {
  return {
    [regionKey]: ctx.region,
    [localeKey]: ctx.locale,
    [currencyKey]: ctx.currency,
    ...ctx.name && {
      [nameKey]: ctx.name
    }
  };
}
function buildContextForWebsitePageView(pageViewContext) {
  const shopName = process.env.NEXT_PUBLIC_SHOPIFY_SHOP_NAME;
  const pathParams = requestedPathParamContext(pageViewContext.urlPath);
  const cookies = requestedCookieContext(pageViewContext.cookies);
  const headers = requestedHeaderContext(pageViewContext.headers);
  const requestedRegionId = pathParams.region ?? cookies.region ?? headers.region ?? "";
  const requestedLocale = pathParams.locale ?? cookies.locale ?? headers.locale;
  const region = isValidCountryCode(requestedRegionId) ? supportedRegions[requestedRegionId] : supportedRegions.default;
  const locale = retrieveLocale({
    region,
    locale: requestedLocale,
    defaultFallback: true
  });
  const language = retrieveLanguage({
    region,
    locale,
    defaultFallback: true
  });
  return {
    region: region.id,
    locale,
    language,
    currency: region.currency,
    name: shopName
  };
}
function buildContextForApiRequestHandler(apiRequestContext) {
  const headers = requestedHeaderContext(apiRequestContext.headers);
  const requiestedRegionId = headers.region ?? supportedRegions.default.id;
  const region = isValidCountryCode(requiestedRegionId) ? supportedRegions[requiestedRegionId] : supportedRegions.default;
  const requestedLocale = headers.locale ?? region.defaultLocale;
  const locale = retrieveLocale({
    region,
    locale: requestedLocale,
    defaultFallback: true
  });
  const language = retrieveLanguage({
    region,
    locale,
    defaultFallback: true
  });
  const currency = headers.currency ?? region.currency;
  return {
    region: region.id,
    locale,
    language,
    currency,
    name: headers.name
  };
}

const languageNamesByLocale = {
  "en-AE": "English",
  "en-AU": "English",
  "en-BE": "English",
  "en-CA": "English",
  "fr-CA": "French",
  "de-DE": "German",
  "en-EU": "English",
  "fr-FR": "French",
  "en-GB": "English",
  "ar-KW": "English",
  "en-IE": "English",
  "he-IL": "English",
  "en-NZ": "English",
  "en-US": "English",
  "en-PH": "English",
  "en-SA": "English",
  "en-SG": "English",
  "es-MX": "Spanish",
  "es-US": "Spanish",
  "es-ES": "Spanish",
  "en-CO": "English",
  "en-BR": "English",
  "en-TR": "English",
  "en-CR": "English",
  "en-NO": "English",
  "en-CH": "English",
  "en-PA": "English",
  "en-DO": "English",
  "es-CR": "Spanish",
  "es-DO": "Spanish",
  "es-CO": "Spanish",
  "es-PA": "Spanish"
};

function figsShopLocalizationContextLink(shopLocalizationContext) {
  return setContext((_request, ctx) => {
    const headers = ctx.headers || {};
    serializeContextToHeaderTarget(shopLocalizationContext, {
      header: (field, value) => {
        headers[field] = value;
      }
    });
    return { ...ctx, headers };
  });
}

function retryLink({
  initialDelay = 600,
  retries = 4
} = {}) {
  return new RetryLink({
    delay: {
      initial: initialDelay
    },
    attempts: {
      // One initial request plus four retries:
      max: 1 + retries,
      retryIf: (_error, operation) => !!operation.getContext().retryOnFailure
    }
  });
}

const shopifyAuthHeader = "X-Shopify-Storefront-Access-Token";
function shopifyStorefrontAuthLink() {
  return setContext((_request, ctx) => {
    const headers = ctx.headers || {};
    const storefrontApiKey = process.env.NEXT_PUBLIC_STOREFRONT_API_ACCESS_TOKEN;
    return {
      ...ctx,
      headers: {
        ...headers,
        ...storefrontApiKey ? { [shopifyAuthHeader]: storefrontApiKey } : {}
      }
    };
  });
}

function shopifyStorefrontGaLink() {
  return new ApolloLink((operation, forward) => {
    if (!globalThis["dataLayer"])
      globalThis["dataLayer"] = [];
    const { operationName, variables } = operation;
    globalThis["dataLayer"].push({
      event: "graphql",
      graphql: { operationName, variables }
    });
    return forward(operation);
  });
}

function shopifyStorefrontHttpLink(uri) {
  return createHttpLink({
    uri,
    fetch,
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function shopifyStorefrontThrottleErrorLink() {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((o) => {
      o.errors?.forEach((error) => {
        if (error.message === "Throttled")
          throw error;
      });
      return o;
    });
  });
}

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const DEFAULT_TIMEOUT = 15e3;
class TimeoutError extends Error {
  constructor(message, timeout, statusCode = 408) {
    super(message);
    __publicField(this, "timeout");
    __publicField(this, "statusCode");
    this.timeout = timeout;
    this.statusCode = statusCode;
  }
}
class TimeoutLink extends ApolloLink$1 {
  constructor(timeout, statusCode) {
    super();
    __publicField(this, "timeout");
    __publicField(this, "statusCode");
    this.timeout = timeout || DEFAULT_TIMEOUT;
    this.statusCode = statusCode;
  }
  request(operation, forward) {
    let controller;
    const requestTimeout = operation.getContext().timeout || this.timeout;
    if (typeof AbortController !== "undefined") {
      const context = operation.getContext();
      let fetchOptions = context.fetchOptions || {};
      controller = fetchOptions.controller || new AbortController();
      fetchOptions = { ...fetchOptions, controller, signal: controller.signal };
      operation.setContext({ fetchOptions });
    }
    const chainObservable = forward(operation);
    const operationType = operation.query.definitions.find(
      (def) => def.kind === "OperationDefinition"
    ).operation;
    if (requestTimeout <= 0 || operationType === "subscription") {
      return chainObservable;
    }
    const localObservable = new Observable((observer) => {
      let timer;
      const subscription = chainObservable.subscribe(
        (result) => {
          clearTimeout(timer);
          observer.next(result);
          observer.complete();
        },
        (error) => {
          clearTimeout(timer);
          observer.error(error);
          observer.complete();
        }
      );
      timer = setTimeout(() => {
        if (controller) {
          controller.abort();
          const context = operation.getContext();
          let fetchOptions = context.fetchOptions || {};
          if (fetchOptions.controller === controller && fetchOptions.signal === controller.signal) {
            fetchOptions = { ...fetchOptions, controller: null, signal: null };
            operation.setContext({ fetchOptions });
          }
        }
        observer.error(new TimeoutError("Timeout exceeded", requestTimeout, this.statusCode));
        subscription.unsubscribe();
      }, requestTimeout);
      let ctxRef = operation.getContext().timeoutRef;
      if (ctxRef) {
        ctxRef({
          unsubscribe: () => {
            clearTimeout(timer);
            subscription.unsubscribe();
          }
        });
      }
      return () => {
        clearTimeout(timer);
        subscription.unsubscribe();
      };
    });
    return localObservable;
  }
}
const defaultArgs = {
  timeout: 15e3
};
function timeoutLink({ timeout = defaultArgs.timeout } = defaultArgs) {
  return new TimeoutLink(timeout);
}

const initClient = ({
  host,
  inMemoryCacheConfig,
  requestTimeout,
  useGETForQueries = true,
  headers = void 0
}) => {
  const httpLink = createHttpLink({
    uri: new URL("graphql", host).toString(),
    useGETForQueries,
    fetch: figsFetch,
    fetchOptions: {
      mode: "cors"
    },
    headers
  });
  const authLink = setContext((_, { headers: headers2 }) => {
    const token = TokenAuthStorageSingleton.getInstance().getToken();
    if (!token) {
      return { headers: headers2 };
    }
    return {
      headers: {
        ...headers2,
        authorization: token
      }
    };
  });
  const timeout = timeoutLink({ timeout: requestTimeout });
  const retry = retryLink();
  const activeLinks = [authLink, retry, timeout, httpLink];
  const client = new ApolloClient({
    link: ApolloLink.from(activeLinks),
    cache: new InMemoryCache(inMemoryCacheConfig),
    ssrMode: typeof window === "undefined" || typeof document === "undefined"
  });
  return client;
};

function consoleTrace(scope, message, ...args) {
  console.debug(`[${scope}]`, message, ...args);
}
const traceEnabled = process.env.NEXT_PUBLIC_CLIENT_TRACE_ENABLED === "true";
const trace = traceEnabled ? consoleTrace : () => void 0;

function cacheKeyScope(ctx) {
  return `${ctx.region}:${ctx.currency}:${ctx.locale}${ctx.name}`;
}
function cacheKeyFor(ctx, profile) {
  return `${cacheKeyScope(ctx)}:${profile}`;
}
function selectClients(cache, ctx) {
  const scopePrefix = cacheKeyScope(ctx);
  const clients = [];
  cache.forEach((c, k) => {
    if (k.startsWith(scopePrefix))
      clients.push([k, c]);
  });
  return clients;
}
function cachedClientOr(cache, profile, ctx, create) {
  const key = cacheKeyFor(ctx, profile);
  const hit = cache.get(key);
  trace("client cache hit for", key, !!hit);
  if (hit)
    return hit;
  const client = create();
  cache.set(key, client);
  return client;
}

const apqEnabled = process.env.NEXT_PUBLIC_APOLLO_APQ_ENABLED === "true";
function figsLinkGenerator({
  baseUrl,
  graph,
  onError
}) {
  return (shopLocalizationContext, serviceFilterPairsContext) => {
    const linksFirstPart = [
      figsAuthLink(),
      figsServiceSelectionLink(serviceFilterPairsContext),
      figsShopLocalizationContextLink(shopLocalizationContext)
    ];
    const linksSecondPart = [retryLink(), timeoutLink(), configuredHttpLink(baseUrl, graph)];
    const baseLinks = [...linksFirstPart, ...linksSecondPart];
    const errorHanlderLink = ApolloLink.from(baseLinks);
    const errorHandlerClient = new ApolloClient({
      link: errorHanlderLink,
      cache: new InMemoryCache()
    });
    const finalLinksArray = onError ? [
      ...linksFirstPart,
      figsAuthFailoverLink(() => onError(errorHandlerClient)),
      ...linksSecondPart
    ] : baseLinks;
    const link = ApolloLink.from(finalLinksArray);
    return link;
  };
}
function generateHash(query) {
  const definitions = query.definitions.filter((d) => d.kind === "OperationDefinition");
  const allQuery = definitions.every(
    (d) => d.kind === "OperationDefinition" && d.operation === "query"
  );
  if (allQuery) {
    return sha256(stripIgnoredCharacters(print(query)));
  }
  return sha256(print(query));
}
function configuredHttpLink(baseUrl, graph) {
  const httpLink = figsHttpLink(baseUrl, graph);
  if (apqEnabled) {
    return createPersistedQueryLink({
      useGETForHashedQueries: true,
      generateHash
    }).concat(httpLink);
  }
  return httpLink;
}

function shopifyStorefrontLinkGenerator(storefrontUri) {
  return () => {
    return ApolloLink.from([
      shopifyStorefrontAuthLink(),
      retryLink(),
      timeoutLink(),
      shopifyStorefrontGaLink(),
      shopifyStorefrontThrottleErrorLink(),
      shopifyStorefrontHttpLink(storefrontUri)
    ]);
  };
}

var ApolloClientProfile = /* @__PURE__ */ ((ApolloClientProfile2) => {
  ApolloClientProfile2[ApolloClientProfile2["FigsPublicSupergraph"] = 0] = "FigsPublicSupergraph";
  ApolloClientProfile2[ApolloClientProfile2["FigsAuthedSupergraph"] = 1] = "FigsAuthedSupergraph";
  ApolloClientProfile2[ApolloClientProfile2["ShopifyStorefront"] = 2] = "ShopifyStorefront";
  return ApolloClientProfile2;
})(ApolloClientProfile || {});
function initializeClientFactory(figsApiBaseUrl, shopifyApiUrl, accountOnError) {
  const linksFor = (graph, onError) => figsLinkGenerator({
    baseUrl: figsApiBaseUrl,
    graph: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === "true" ? "" : graph,
    onError: onError ?? void 0
  });
  const linkGenerators = {
    [0 /* FigsPublicSupergraph */]: linksFor("catalog", accountOnError),
    [1 /* FigsAuthedSupergraph */]: linksFor("shop", accountOnError),
    [2 /* ShopifyStorefront */]: shopifyStorefrontLinkGenerator(shopifyApiUrl)
  };
  const cacheConfigs = {
    [0 /* FigsPublicSupergraph */]: catalogCacheConfig,
    [1 /* FigsAuthedSupergraph */]: shopCacheConfig,
    [2 /* ShopifyStorefront */]: defaultCacheConfig
  };
  const config = {
    cacheConfigs,
    figsBaseUrl: figsApiBaseUrl,
    isServer,
    linkGenerators
  };
  return {
    createContext: (shopLocalizationContext, serviceFilterPairsContext) => createContext(config, shopLocalizationContext, serviceFilterPairsContext)
  };
}
function createContext(config, shopLocalizationContext, serviceFilterPairsContext, cache = /* @__PURE__ */ new Map()) {
  const ctx = shopLocalizationContext;
  return {
    for: (profile) => {
      return cachedClientOr(
        cache,
        profile,
        ctx,
        () => createClient(config, profile, ctx, serviceFilterPairsContext)
      );
    },
    // All clients that have been acquired via this factory.  Must have been acquired to be
    // in this list, this implementation is lazy.
    clients: () => selectClients(cache, ctx),
    // Proactively dispose of the apollo client caches and stop the client for
    // resource reclamation and invalid access prevention.
    release: async () => {
      for (const [_, client] of selectClients(cache, ctx)) {
        client.stop();
        await client.clearStore();
      }
    }
  };
}
function createClient(config, profile, shopLocalizationContext, serviceFilterPairsContext) {
  trace("createClient", profile.toString(), config);
  const cacheConfig = config.cacheConfigs[profile];
  const cache = new InMemoryCache(cacheConfig);
  const linkGenerator = config.linkGenerators[profile];
  const link = linkGenerator(shopLocalizationContext, serviceFilterPairsContext);
  return new ApolloClient({
    cache,
    defaultOptions: {
      // no-cache does not even write to cache
      // network-only does not read from cache but will populate
      // cache-first is the default
      query: {
        errorPolicy: "all",
        fetchPolicy: "cache-first"
      }
    },
    link,
    // profile-specific
    ssrMode: config.isServer
  });
}

const TEAM_COLLECTION_PAGINATION = `
  pageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;

const CART_ITEMS = gql`
  fragment cartItems on CartItemInterface {
    cartItems {
      id
      owner {
        name
        email
      }
      discountAmount
      discountPercentage
      subtotal
      quantity
      isReturnable
    }
  }
`;
const COLLECTION_PAGINATION = gql`
  fragment pageInfo on CollectionConnection {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
const SIZE_CHART = gql`
  fragment sizeChart on ProductGroup {
    sizeChart {
      id
      title
      imageUri
      columnNames
      columnValues
      tips {
        kind
        tip
      }
    }
  }
`;
const COLLECTION_PRODUCT_GROUPS = gql`
  fragment productGroups on CollectionInterface {
    productGroups {
      id
      style
      category
      genderCategory
      fits
      colors
      sizes
      embroiderable
      title
      description
      whyWeLove
      rtfFeatures
      rawMaterials
      icons {
        id
        label
        title
        image
      }
      ...sizeChart
      careInstructions
      defaultVariant {
        name
        color
        image {
          source
        }
        product {
          badges
        }
      }
    }
  }
  ${SIZE_CHART}
`;
const COLLECTION_ITEMS = gql`
  fragment items on CollectionInterface {
    items {
      nodes {
        id
        variantId
        name
        size
        color
        price
        currencyType
        images {
          id
        }
      }
    }
  }
`;

const CREATE_TEAM_MUTATION = gql`
  mutation createTeam(
    $name: String!
    $figsRepEmail: String
    $teamOwners: [TeamOwnerInput!]
    $teamMembers: [TeamMemberInput!]
    $organization: OrganizationsInput!
  ) {
    createTeam(
      name: $name
      figsRepEmail: $figsRepEmail
      teamOwners: $teamOwners
      teamMembers: $teamMembers
      organization: $organization
    ) {
      team {
        id
        name
        figsRep {
          firstName
          lastName
        }
        figsRepEmail
        organization {
          id
          name
          size
          type
          parent {
            id
            name
            size
            type
          }
        }
        teamOwners {
          id
          firstName
          lastName
          email
          jobCategory
          emailOptIn
        }
      }
      teamCollection {
        id
      }
      errors {
        path
        message
      }
    }
  }
`;
const MODIFY_TEAM_MUTATION = gql`
  mutation modifyTeam($id: ID!, $name: String, $category: String, $figsRepEmail: String) {
    modifyTeam(teamId: $id, name: $name, category: $category, figsRepEmail: $figsRepEmail) {
      team {
        id
        name
        category
        figsRepEmail
      }
      errors {
        path
        message
        status
      }
    }
  }
`;
const CREATE_TEAM_OWNER = gql`
  mutation createTeamOwners($input: [UserInput!], $teamId: ID!) {
    createTeamOwners(input: $input, teamId: $teamId) {
      teamOwners {
        name
        jobCategory
      }
      errors {
        path
        message
      }
    }
  }
`;

const MODIFY_TEAM_COLLECTION_MUTATION = gql`
  mutation teamCollectionModifyItems(
    $id: ID!
    $name: String
    $add: CollectionUpdateInput
    $remove: CollectionUpdateInput
  ) {
    teamCollectionModifyItems(id: $id, name: $name, add: $add, remove: $remove) {
      teamCollection {
        id
        name
        size
        items {
          nodes {
            name
            category
            size
            color
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;
const PURCHASE_SAMPLE_KIT_MUTATION = gql`
  mutation purchaseSampleKit($teamId: ID!, $returnToUrl: String) {
    purchaseSampleKit(teamId: $teamId, returnToUrl: $returnToUrl) {
      invoiceUrl
      teamCart {
        id
        name
        status
        closesAt
        total
        team {
          id
          name
        }
        owner {
          id
          name
        }
        cartItems {
          collectionItem {
            id
            name
          }
          quantity
        }
        shippingAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        billingAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

const LOGIN_ADMIN_MUTATION = gql`
  mutation loginAdmin($email: String!, $token: String!) {
    loginAdmin(email: $email, token: $token) {
      adminUser {
        id
        email
        name
        firstName
        lastName
      }
      authToken
      oktaToken
      errors {
        path
        message
        status
      }
    }
  }
`;
const LOGIN_TEAM_OWNER = gql`
  mutation loginTeamOwner($email: String!, $password: String!) {
    loginTeamOwner(email: $email, password: $password) {
      teamOwner {
        email
        firstName
        lastName
        id
      }
      authToken
      errors {
        path
        message
        status
      }
    }
  }
`;

const CREATE_FIGS_REPS_MUTATION = gql`
  mutation createFigsReps($input: [UserInput!]!) {
    createFigsReps(input: $input) {
      figsReps {
        id
        email
        type
      }
      errors {
        path
        message
      }
    }
  }
`;

const COLLECTIONS_PRODUCT_GROUPS_QUERY = gql`
  query getCollections($name: String, $id: ID, $pg_filter: ProductGroupFilter) {
    collections(name: $name, id: $id, productGroupFilter: $pg_filter) {
      nodes {
        id
        name
        categories
        styles
        ...productGroups
      }
      ...pageInfo
    }
  }
  ${COLLECTION_PRODUCT_GROUPS}
  ${COLLECTION_PAGINATION}
`;

const JOB_CATEGORIES_QUERY = gql`
  query jobCategories {
    jobCategories {
      id
      name
    }
  }
`;
const ORGANIZATION_CATEGORIES_QUERY = gql`
  query organizationCategories {
    organizationCategories {
      id
      name
    }
  }
`;
const ORGANIZATION_SIZES_QUERY = gql`
  query organizationSizes {
    organizationSizes {
      id
      name
    }
  }
`;
const TEAM_CATEGORIES_QUERY = gql`
  query teamCategories {
    teamCategories {
      id
      name
    }
  }
`;

const TEAM_CARTS_DASHBOARD_QUERY = gql`
  query teamCarts(
    $cart_id: ID
    $owner: TeamOwnerFilter
    $team_id: ID
    $status: String
    $not_status: String
  ) {
    teamCarts(
      cartId: $cart_id
      owner: $owner
      teamId: $team_id
      status: $status
      notStatus: $not_status
    ) {
      nodes {
        id
        name
        total
        numItems
      }
    }
  }
`;
const TEAM_CARTS_QUERY = gql`
  query teamCarts(
    $cart_id: ID
    $owner: TeamOwnerFilter
    $team_id: ID
    $status: String
    $not_status: String
  ) {
    teamCarts(
      cartId: $cart_id
      owner: $owner
      teamId: $team_id
      status: $status
      notStatus: $not_status
    ) {
      nodes {
        id
        name
        total
        numItems
        ...cartItems
      }
    }
  }
  ${CART_ITEMS}
`;

const TEAM_OWNERS_QUERY = gql`
  query teamOwners {
    teamOwners {
      nodes {
        id
        firstName
        lastName
        jobCategory
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
const TEAM_OWNER_ROLES = gql`
  query jobCategories {
    jobCategories {
      id
      name
    }
  }
`;

const TEAM_COLLECTION_PRODUCT_GROUPS_QUERY = gql`
  query getTeamCollections(
    $name: String
    $team_id: ID
    $id: ID
    $owner: TeamOwnerFilter
    $pg_filter: ProductGroupFilter
  ) {
    teamCollections(
      name: $name
      teamId: $team_id
      id: $id
      owner: $owner
      productGroupFilter: $pg_filter
    ) {
      nodes {
        id
        name
        categories
        styles
        ...productGroups
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${COLLECTION_PRODUCT_GROUPS}
`;
const TEAM_COLLECTIONS_QUERY = gql`
  query teamCollections(
    $name: String
    $team_id: ID
    $id: ID
    $owner: TeamOwnerFilter
    $pg_filter: ProductGroupFilter
  ) {
    teamCollections(
      name: $name
      teamId: $team_id
      id: $id
      owner: $owner
      productGroupFilter: $pg_filter
    ) {
      nodes {
        id
        name
        size
        teamName
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
const TEAM_COLLECTION_DETAILS_QUERY = gql`
  query getTeamCollections(
    $name: String
    $team_id: ID
    $id: ID
    $owner: TeamOwnerFilter
    $pg_filter: ProductGroupFilter
  ) {
    teamCollections(
      name: $name
      teamId: $team_id
      id: $id
      owner: $owner
      productGroupFilter: $pg_filter
    ) {
      nodes {
        id
        name
        categories
        styles
        ...productGroups
        ...items
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${COLLECTION_PRODUCT_GROUPS}
  ${COLLECTION_ITEMS}
`;

const TEAMS_QUERY = gql`
  query teams($team_id: ID) {
    teams(teamId: $team_id) {
      nodes {
        id
        name
        figsRepEmail
        category
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
const TEAM_ORG_NAMES_QUERY = gql`
  query orgNames {
    organizationNames
  }
`;

const FIGS_REPS_QUERY = gql`
  query getFigsReps($id: ID, $first: String, $last: String) {
    figsReps(id: $id, firstName: $first, lastName: $last) {
      nodes {
        id
        name
        email
      }
    }
  }
`;
const FIGS_REP_DETAILS_QUERY = gql`
  query getFigsRepsDetails($id: ID, $first: String, $last: String) {
    figsReps(id: $id, firstName: $first, lastName: $last) {
      nodes {
        id
        firstName
        lastName
        email
        teams {
          id
          name
          category
        }
      }
    }
  }
`;

const isBrowser = typeof window !== "undefined" && typeof document !== "undefined";
const isServer = !isBrowser;

export { ApolloClientProfile, CART_ITEMS, COLLECTIONS_PRODUCT_GROUPS_QUERY, COLLECTION_ITEMS, COLLECTION_PAGINATION, COLLECTION_PRODUCT_GROUPS, CREATE_FIGS_REPS_MUTATION, CREATE_TEAM_MUTATION, CREATE_TEAM_OWNER, FIGS_REPS_QUERY, FIGS_REP_DETAILS_QUERY, JOB_CATEGORIES_QUERY, LOGIN_ADMIN_MUTATION, LOGIN_TEAM_OWNER, MODIFY_TEAM_COLLECTION_MUTATION, MODIFY_TEAM_MUTATION, ORGANIZATION_CATEGORIES_QUERY, ORGANIZATION_SIZES_QUERY, PURCHASE_SAMPLE_KIT_MUTATION, SIZE_CHART, ServiceFilterPairs, TEAMS_QUERY, TEAM_CARTS_DASHBOARD_QUERY, TEAM_CARTS_QUERY, TEAM_CATEGORIES_QUERY, TEAM_COLLECTIONS_QUERY, TEAM_COLLECTION_DETAILS_QUERY, TEAM_COLLECTION_PAGINATION, TEAM_COLLECTION_PRODUCT_GROUPS_QUERY, TEAM_ORG_NAMES_QUERY, TEAM_OWNERS_QUERY, TEAM_OWNER_ROLES, trace as apolloClientFactoryTrace, brunswickEnv, buildContextForApiRequestHandler, buildContextForWebsitePageView, catalogCacheConfig, cookieDefaultOptions, currencyKey, defaultCacheConfig, defaultLocale, detectedCountryEchoKey, figsEnv, ingressDetectedCountryKey, initClient, initializeClientFactory, isBrowser, isServer, isValidCountryCode, isValidLanguageGroup, isValidLocale, languageNamesByLocale, legacyLocaleKey, localeKey, nameKey, regionKey, retrieveEnabledLocales, retrieveLanguageGroupsForRegion, retrieveLanguageRegionPairs, retrieveLocale, retrieveLocalesForRegion, retrieveRegion, retrieveRegions, serializeContext, serializeContextToHeaderTarget, shopCacheConfig, supportedLocaleIdentifiers, supportedRegions };
