import { isServer } from '@syconium/weeping-figs'

import { QueryParamValue } from '../../types/utility'

/**
 * Not intended for boolean false param value, since we always return a string.
 * A false param value should return null, but that would ruin the convenience
 * of this convenience function.
 */
export function singleQueryParamValue<T = undefined>(
  queryParamValue: QueryParamValue,
  defaultValue: T
): T | string {
  if (typeof queryParamValue === 'boolean') return ''
  return (Array.isArray(queryParamValue) ? queryParamValue[0] : queryParamValue) ?? defaultValue
}

export function ssrOnly<T>(f: () => T): () => T | undefined {
  return isServer ? f : () => undefined
}
