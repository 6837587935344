import {
  CartItemProperties,
  ISetItemQuantityProps,
} from '@syconium/magnolia/src/brunswick/interfaces/remote-data/cart'
import {
  ICartClient,
  IFetch,
  IFetchResponse,
} from '@syconium/magnolia/src/brunswick/interfaces/remote-data/cart'

import { reportClientError } from '../../../../app/_components/chrome/scripts/DataDogRumScript'
import { extractNumericShopifyId } from '../../../../lib/shopify'
import { Cart, CartItem } from '../../../../types/figs'
import { ShopifyCart, ShopifyCartItem } from '../../../../types/shopify'
import { ShopifyCartItemSerializer } from '../ShopifyCartItemSerializer'
import { ShopifyCartSerializer } from '../ShopifyCartSerializer'

import { IProps } from './IProps'

export class ShopifyCartClient implements ICartClient {
  private fetch: IFetch
  private cartItemSerializer = new ShopifyCartItemSerializer()
  private cartSerializer = new ShopifyCartSerializer()

  constructor({ fetch }: IProps) {
    this.fetch = fetch
  }

  public async fetchCart(): Promise<Cart | null> {
    try {
      const result = await this.tryToFetchCart()
      return result
    } catch (error) {
      reportClientError({
        error,
        context: {
          scope: 'ShopifyCartClient',
          label: 'fetchCart',
        },
      })
      return null
    }
  }

  public addItem(
    variantId: string,
    quantity: number = 1,
    properties?: CartItemProperties
  ): Promise<CartItem[]> {
    return this.addItems([{ variantId, quantity, properties }])
  }

  public async addItems(
    items: Array<{
      variantId: string
      quantity?: number
      properties?: CartItemProperties
    }>
  ): Promise<CartItem[]> {
    const requestItems = items.map(o => ({
      id: extractNumericShopifyId(o.variantId),
      quantity: o.quantity ?? 1,
      properties: o.properties,
    }))
    const response = await this.postRequest<{ items: ShopifyCartItem[] }>('/cart/add.js', {
      items: requestItems,
    })
    if (!response) return []
    return response.items.map(this.cartItemSerializer.deserialize)
  }

  public async setItemsQuantities(props: ISetItemQuantityProps[]): Promise<CartItem[]> {
    const requestPayload = {
      updates: props.reduce((accum, curr) => ({ ...accum, [curr.key]: curr.newQuantity }), {}),
    }
    const shopifyCart = await this.postRequest<ShopifyCart>('/cart/update.js', requestPayload)
    if (!shopifyCart) return []
    return shopifyCart.items.map(this.cartItemSerializer.deserialize)
  }

  public removeItems(keys: string[]): Promise<CartItem[]> {
    return this.setItemsQuantities(keys.map(key => ({ key, newQuantity: 0 })))
  }

  public createCheckoutWithCartItems(): string {
    return '/checkout'
  }

  public clearCheckoutIfCompleted(): boolean {
    return false
  }

  public associateCustomer(): Promise<void> {
    return Promise.resolve()
  }

  public get locallyStoredCheckoutId(): string | null {
    return null
  }

  private async postRequest<Data, Body extends {} = {}>(
    path: string,
    body?: Body
  ): Promise<Data | null> {
    try {
      const result = await this.tryPostRequest<Data, Body>(path, body)
      return result
    } catch (error) {
      reportClientError({
        error,
        context: {
          scope: 'ShopifyCartClient',
          label: 'postRequest',
        },
      })
      return null
    }
  }

  private async tryToFetchCart(): Promise<Cart> {
    const response = await this.fetch('/cart.js')
    this.throwErrorOnFetchFailure(response)
    const data: ShopifyCart = await response.json()
    const cart: Cart = this.cartSerializer.deserialize(data)
    return cart
  }

  private async tryPostRequest<Data, Body extends {}>(path: string, body?: Body): Promise<Data> {
    const response = await this.fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    this.throwErrorOnFetchFailure(response)
    const data: Data = await response.json()
    return data
  }

  private throwErrorOnFetchFailure(response: IFetchResponse): void {
    if (!response.ok) {
      const error = new Error(
        `Fetch failed with status code ${response.status}. ${response.statusText}`
      )
      reportClientError({
        error,
        context: {
          scope: 'ShopifyCartClient',
          label: 'throwErrorOnFetchFailure',
        },
      })
      throw error
    }
  }
}
