import { CSSProperties, useState } from 'react'
import { createContainer } from 'unstated-next'

const useAccentColorContainer = (): {
  accentColor?: CSSProperties['color']
  setAccentColor: (x: CSSProperties['color'] | undefined) => void
} => {
  const [accentColor, setAccentColor] = useState<CSSProperties['color'] | undefined>()

  return {
    accentColor,
    setAccentColor,
  }
}

export const AccentColorContainer = createContainer(useAccentColorContainer)
