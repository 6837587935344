import Link from 'next/link'
import * as React from 'react'

import { ResponsiveImage, Spacer, Trash } from '@syconium/little-miss-figgy'
import { BadgesSection } from '@syconium/magnolia/src/components/pages/products/ProductPage/ProductHighlights/BadgesSection'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { GtmVariant } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'

import { CartItem } from '../../../../../types/figs'
import { useFixturesContext } from '../../../../containers/fixtures'
import { CartItemProperties as ICartItemProperties } from '../../../../interfaces/remote-data/cart'
import { CartItemQuantity } from '../../CartItemQuantity'
import { CartItemProperties } from '../CartItemProperties'
import { PriceAndDeleteButton } from '../PriceAndDeleteButton'
import { DeleteIconButton, DeleteIconColumn } from '../PriceAndDeleteButton/styles'

import {
  Body,
  Bottom,
  Details,
  DetailsColumn,
  DetailsRow,
  FitDescription,
  ImagePlaceholder,
  OptionsText,
  SiteWidePromoWrap,
  Title,
  Top,
} from './styles'

export interface Props {
  cartCurrency: string
  cartIsUpdating: boolean
  cartItem: CartItem
  decrementQuantity(): void
  eventCategory: 'minicart' | 'cart'
  hasEmbroidery?: boolean
  incrementQuantity(): void
  isEmbroiderable?: boolean
  propertiesToDisplay: ICartItemProperties
  pushProductClick(data: { variant: GtmVariant; list?: string; position?: number }): void
  removeItem(): void
  setQuantity(newQuantity: number): void
  toggleMiniCartSlideOut?: () => void
  uniqueId: string
}

export const Template: React.FC<Props> = ({
  cartCurrency,
  cartIsUpdating,
  cartItem: {
    color,
    description,
    effectivePrice,
    externalParentId,
    finalSale,
    fit,
    fullPrice,
    image,
    productGroupHandle,
    productId,
    productType,
    quantity,
    size,
    sku,
    swPromoEligible,
    title,
    variantId,
    isPortalColor,
  },
  decrementQuantity,
  eventCategory,
  incrementQuantity,
  propertiesToDisplay,
  pushProductClick,
  removeItem,
  setQuantity,
  toggleMiniCartSlideOut = () => ({}),
  uniqueId,
}) => {
  const { cart: miniCartCopy, siteWidePromo } = useFixturesContext()

  const linkQueryParams: string = color ? `?color=${color}` : ''
  const linkSuffix: string | undefined =
    productGroupHandle && `${productGroupHandle}${linkQueryParams}`

  const { [miniCartCopy.finalSale]: finalSaleProp, ...otherPropertiesToDisplay } =
    propertiesToDisplay

  const isFinalSaleVisible = finalSale || finalSaleProp

  // Get the promotional text to display in the details section.
  // Returns "undefined" if no promotional text should be rendered.
  const promoText = React.useMemo(() => {
    if (isPortalColor) return undefined
    // If the product is site-wide promo eligible, use the site-wide promo text
    if (swPromoEligible && siteWidePromo) return siteWidePromo
    return undefined
  }, [isPortalColor, swPromoEligible, siteWidePromo])

  return (
    <Body data-testid='cart-item' data-cart-item-id={productId}>
      <Top>
        <Link
          href={linkSuffix ? `/products/${linkSuffix}` : '/'}
          onClick={() => {
            pushProductClick({
              variant: {
                category: productType,
                currencyType: cartCurrency,
                externalParentId: externalParentId || '',
                discountPrice: effectivePrice,
                handle: productGroupHandle,
                price: fullPrice,
                shopifyId: variantId,
                sku,
              },
              list: `${eventCategory}-cart`,
            })
            toggleMiniCartSlideOut()
          }}
          {...trackEvent({
            category: 'minicart 2.0',
            action: 'click item thumbnail',
            label: `/products/${linkSuffix}`,
          })}
        >
          {image?.url ? (
            <ResponsiveImage
              alt={title}
              aspectRatios={{
                sm: 1,
                md: 1,
              }}
              src={image.url}
              loading='lazy'
              widths={{
                unit: 'px',
                sm: 100,
                md: 100,
              }}
            />
          ) : (
            <ImagePlaceholder />
          )}
        </Link>

        <Details id={`Details-${uniqueId}`}>
          <DetailsRow>
            <Title asTag='h3' data-minicart-item={title}>
              {title} {description}
            </Title>

            <DeleteIconColumn>
              <DeleteIconButton
                {...trackEvent({
                  category: eventCategory,
                  action: 'remove item from cart',
                  label: title,
                })}
                onClick={removeItem}
                aria-label={`Remove item: ${title}`}
              >
                <Trash stroke='currentColor' />
              </DeleteIconButton>
            </DeleteIconColumn>
          </DetailsRow>
          <Spacer height={4} />
          <DetailsRow hasOffsetBelow>
            <OptionsText>
              {size}
              {productType !== 'Gift Card' && (
                <>
                  {fit && <FitDescription>• {fit}</FitDescription>}
                  &nbsp;• {color}
                </>
              )}
            </OptionsText>
          </DetailsRow>
          {isFinalSaleVisible && <BadgesSection badges={[miniCartCopy.finalSale]} />}

          {!!promoText && <SiteWidePromoWrap>{promoText}</SiteWidePromoWrap>}

          <DetailsRow>
            <CartItemQuantity
              cartIsUpdating={cartIsUpdating}
              decrementQuantity={decrementQuantity}
              eventCategory={eventCategory}
              incrementQuantity={incrementQuantity}
              setQuantity={setQuantity}
              quantity={quantity}
            />
            <DetailsColumn>
              <PriceAndDeleteButton
                currency={cartCurrency}
                effectivePrice={effectivePrice}
                fullPrice={fullPrice}
              />
            </DetailsColumn>
          </DetailsRow>
        </Details>
      </Top>

      <Bottom>
        <CartItemProperties properties={otherPropertiesToDisplay} />
      </Bottom>
    </Body>
  )
}
