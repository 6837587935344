import { Column, Grid, Media, Row } from '@syconium/little-miss-figgy'
import * as FooterSubComponents from '@syconium/magnolia/src/brunswick/components/Footer/SubComponents'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { RefsContainer } from '@syconium/magnolia/src/brunswick/containers/refs'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { NavSection } from '@syconium/magnolia/src/types/graphql'

import { useEmailCaptureForm } from '../../../containers/EmailCaptureFormContainer'
import { NavAccordion } from '../NavAccordion'
import { NavListSection } from '../NavListSection'

import { Body, CaptureWrapper, ResponsiveImage, SectionsWrapper } from './styles'

export const NavFooter = () => {
  const { footerRef } = RefsContainer.useContainer()
  const {
    footer,
    globals: {
      socialMediaHandles: { hashtag },
    },
  } = useFixturesContext()
  const { hideEmailCaptureForm } = useEmailCaptureForm()
  const desktopSections = footer?.desktopSections || []
  const mobileSections = footer?.mobileSections || []
  const socialLinks = (footer?.socialLinks || []).map(link => ({
    text: link.text || '',
    href: link.href || '',
    icon: (
      <ResponsiveImage
        src={link.icon || ''}
        alt={link.text}
        aspectRatios={{ sm: null, md: null }}
        widths={{ unit: 'px', sm: 20, md: 20 }}
        loading='lazy'
        transparentBackground={true}
      />
    ),
  }))

  const desktopSectionElements = (footer?.desktopSections || []).map((section, index) => {
    return (
      <Column key={`${section.id}-${index}`} desktop={12 / desktopSections.length}>
        {renderFooterSection(section, index)}
      </Column>
    )
  })

  const mobileSectionElements = mobileSections.map((section, index) =>
    renderFooterSection(section, index)
  )

  const legalLinks = (footer?.legalLinks || []).map(link => ({
    href: link.href || '',
    title: link.text || '',
  }))

  const promo = (
    <FooterSubComponents.FooterPromo hashtag={hashtag} socialLinks={socialLinks}>
      Awesome Humans
    </FooterSubComponents.FooterPromo>
  )

  return (
    <>
      {!hideEmailCaptureForm && (
        <CaptureWrapper aria-label='email capture form'>
          <div id='lightbox-inline-form-e2b8cfc0-ee68-498b-9240-53d553d0ac0f'></div>
        </CaptureWrapper>
      )}

      <Body
        {...trackEvent({ category: 'footer', action: 'click footer' })}
        data-testid='site-footer'
        ref={footerRef}
        role='contentinfo'
      >
        <Media greaterThanOrEqual='md'>
          <Grid>
            <Row>
              <Column desktop={6}>{promo}</Column>
              <Column desktop={6}>
                <SectionsWrapper>{desktopSectionElements}</SectionsWrapper>
              </Column>
            </Row>
          </Grid>
        </Media>
        <Media lessThan='md'>
          {promo}
          {mobileSectionElements}
        </Media>

        <FooterSubComponents.FooterFooter
          asTag='div'
          legalLinks={legalLinks}
          copyright={footer?.copyrightLine || ''}
        />
      </Body>
    </>
  )
}

function renderFooterSection(props: NavSection, index: number): JSX.Element | null {
  const key: string = `${props.id}-${index}`
  switch (props.__typename) {
    case 'NavAccordion':
      return <NavAccordion {...props} key={key} />
    case 'NavListSection':
      return <NavListSection {...props} key={key} />
    default:
      return null
  }
}
