import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const MobileQuickBuy: React.FC<IProps> = ({ height = '32px', width = '32px' }) => {
  return (
    <BaseIcon
      {...{ height, width }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      fill='white'
    >
      <rect width='32' height='32' rx='16' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8327 16.625L10.166 16.625L10.166 15.375L21.8327 15.375L21.8327 16.625Z'
        fill='#282828'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.375 21.8332L15.375 10.1665L16.625 10.1665L16.625 21.8332L15.375 21.8332Z'
        fill='#282828'
      />
    </BaseIcon>
  )
}
