import { FC } from 'react'
import styled from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

import { LogoDigitizationSetting } from '../../../../../../brunswick/containers/embroidery/types'

import { DetailLine, Visual } from './styles'
import { Template } from './Template'

export interface Props {
  backgroundColor?: string
  imgSrc?: string
  logoName?: string
  logoPlacement?: string
  logoColor: LogoDigitizationSetting
  logoNote?: string
  hasLogoSetup?: boolean
}

export const DTCLogoSneakPeek: FC<Props> = ({
  backgroundColor,
  imgSrc,
  logoName,
  logoPlacement,
  logoColor,
  logoNote,
  hasLogoSetup,
}) => {
  const {
    embroidery: {
      modal: { forms: fixtures },
    },
  } = useFixturesContext()
  if (!imgSrc) return null
  return (
    <Template.Container className='logo-sneak-peek-container'>
      <Template.Header backgroundColor={backgroundColor}>
        <ImageWrapper>
          <ResponsiveImage
            alt={logoName || 'CustomLogo'}
            aspectRatios={{
              sm: null,
              md: null,
            }}
            src={imgSrc}
            loading={'default'}
            widths={{ unit: 'px', sm: 100, md: 100 }}
          />
        </ImageWrapper>
      </Template.Header>
      <Template.Body>
        {hasLogoSetup && (
          <StyledDetailLine
            general
          >{`${fixtures.dtcLogoEmbroideryForm.digitization} ${fixtures.dtcLogoEmbroideryForm.requested}`}</StyledDetailLine>
        )}
        <DetailLine general>
          {fixtures.logoOption.logoLabel}: {logoName}
        </DetailLine>

        <DetailLine>
          {fixtures.logoOption.logoPlacementLabel}: {logoPlacement}
        </DetailLine>

        <DetailLine>
          {fixtures.logoOption.logoColorLabel}:{' '}
          {logoColor && fixtures.dtcLogoEmbroideryForm.logoStrings[logoColor]?.title}
        </DetailLine>

        {logoNote && (
          <DetailLine>
            {fixtures.logoOption.logoNoteLabel}: {logoNote}
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}

const ImageWrapper = styled(Visual)`
  padding: ${o => o.theme.spacing(3.5)} ${o => o.theme.spacing(6)};
`

const StyledDetailLine = styled(DetailLine)`
  text-transform: capitalize;
`
