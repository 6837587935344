import { css } from 'styled-components'

import {
  modalAnimationDuration,
  navModalBodyOpenClassName,
  navModalPortalClassName,
} from './constants'

export const navModalStyles = css`
  .${navModalPortalClassName} {
    .ReactModal {
      &__Overlay {
        align-items: center;
        display: flex;
        justify-content: center;
        z-index: ${o => o.theme.zIndex.modals};
      }

      &__Content {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;

        .nav-modal-slides-in-from-left {
          transform: translate3d(-100vw, 0, 0);
          transition: transform ${modalAnimationDuration}ms ease;
          will-change: transform;
        }

        &--after-open .nav-modal-slides-in-from-left {
          transform: translate3d(0, 0, 0);
        }

        &--before-close .nav-modal-slides-in-from-left {
          transform: translate3d(-100vw, 0, 0);
        }

        [data-testid='nav-modal-content-body'] {
          background-color: transparent;
          transition: background-color ${modalAnimationDuration}ms ease;
        }

        &--after-open [data-testid='nav-modal-content-body'] {
          background-color: white;
        }

        &--before-close [data-testid='nav-modal-content-body'] {
          background-color: transparent;
        }
      }
    }
  }

  .${navModalBodyOpenClassName} {
    overflow: hidden;
  }
`
