import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const Body = styled.div<{
  inKit?: boolean
}>`
  padding-bottom: 20px;
`

export const Inner = styled.div<{
  inKit?: boolean
}>`
  margin-left: ${o => (o.inKit ? '16.5px' : 0)};

  ${fromMd} {
    margin-left: ${o => (o.inKit ? '24px' : 0)};
  }
`

export const Top = styled.div<{
  inKit?: boolean
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`

export const Label = styled.span`
  ${TypeStyle.css.bodyDefault};
  font-weight: bold;
  color: ${o => o.theme.color.deprecated.obsidian};
  flex-grow: 1;
`
