import { FC, useRef } from 'react'

import { TileLayout, TileLayoutProps } from '../TileLayout'
import { tileGapDefault } from '../TileLayout/styles'

import { Body } from './styles'

export const CenteredTileLayout: FC<TileLayoutProps<Object>> = ({
  sameHeightGridTiles = false,
  hasTileGaps = true,
  tileGapDesktop = hasTileGaps ? tileGapDefault : 0,
  tileGapMobile = hasTileGaps ? tileGapDefault : 0,
  ...props
}) => {
  const {
    fullBleed = true,
    layoutVariantDesktop = 'slider',
    layoutVariantMobile = 'slider',
    tiles = [],
    hasHorizontalMargin = true,
    hasVerticalMargin = true,
  } = props

  const bodyRef = useRef<HTMLDivElement>(null)

  return (
    <Body ref={bodyRef} hasVerticalMargin={hasVerticalMargin}>
      <TileLayout
        {...props}
        fullBleed={fullBleed}
        layoutVariantDesktop={layoutVariantDesktop}
        layoutVariantMobile={layoutVariantMobile}
        tileGapDesktop={tileGapDesktop}
        tileGapMobile={tileGapMobile}
        tiles={tiles}
        hasHorizontalMargin={hasHorizontalMargin}
        hasVerticalMargin={hasVerticalMargin}
        hasTileGaps={hasTileGaps}
        sameHeightGridTiles={sameHeightGridTiles}
      />
    </Body>
  )
}
