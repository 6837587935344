import styled, { css } from 'styled-components'

import { fromMd, timingDefault, timingSlower } from '@syconium/little-miss-figgy'
import { AccordionStyles } from '@syconium/little-miss-figgy'

import { headerHeight } from '../../constants'
import { Container as RoutesContainer } from '../Routes/styles'

import * as SubComponents from './SubComponents'

export const Container = styled(SubComponents.Container)`
  position: relative;

  & + ${RoutesContainer} {
    transition: transform ${timingSlower} ease-in-out;
    will-change: transform;
  }

  &[aria-hidden='true'] {
    z-index: ${o => o.theme.zIndex.alerts};
  }

  &[aria-hidden='true'] + ${RoutesContainer} {
    transform: translate3d(${o => o.theme.header.slideOutOffset}, 0, 0);
  }

  ${fromMd} {
    position: sticky;
    top: 0;

    & + ${RoutesContainer} {
      transition: initial;
      will-change: initial;
    }

    &[aria-hidden='true'] + ${RoutesContainer} {
      transform: initial;
      z-index: auto;
    }
  }
`

export const Menu = styled(SubComponents.Menu)`
  &:first-of-type {
    margin-top: 12px;
  }

  & ${AccordionStyles.HeaderContainer} {
    padding: 0 36px;
  }

  & ${AccordionStyles.BodyContainer} {
    padding: 0;
  }

  ${fromMd} {
    &:first-of-type {
      margin-top: initial;
    }
  }
`

export const MenuLink = styled.span`
  color: ${o => o.theme.color.deprecated.black.secondary};
  position: relative;

  ${fromMd} {
    color: black;

    &:hover {
      font-weight: bold;
    }

    &::after {
      border-bottom: 1px solid black;
      content: '';
      left: 0;
      position: absolute;
      top: 18px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform ${timingDefault} ease-in;
      width: 100%;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
`

export const MenuTitleLink = styled(MenuLink)`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 3.33em;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.3px;
    line-height: 2em;
    text-transform: initial;
  }
`

export const MenuItemLink = styled(MenuLink)<{
  emphasize?: boolean
}>`
  font-size: 13px;
  font-weight: ${o => o.emphasize && 'bold'};
  letter-spacing: 0.5px;
  line-height: 30px;

  ${fromMd} {
    font-size: 11px;
    line-height: 2.272727em;
    letter-spacing: 0.8px;
  }
`

export const fadableSegment = css<{ isFadedOut?: boolean }>`
  opacity: ${o => o.isFadedOut && '0.2'};
  pointer-events: ${o => o.isFadedOut && 'none'};
  transition: opacity ${timingSlower} ease;
`

export const GhostHeader = styled.div`
  height: ${headerHeight};
  width: 100vw;
`
