export function prettySizeName(shorthand: string): string {
  const nameMappings: Record<string, string> = {
    'XXS': '2X Small',
    'XS': 'X Small',
    'S': 'Small',
    'M': 'Medium',
    'L': 'Large',
    'XL': 'X Large',
    '2XL': '2X Large',
  }
  return nameMappings[shorthand.toUpperCase()] || shorthand
}
