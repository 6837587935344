import styled from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'

export const Container = styled.div`
  position: relative;
  z-index: ${o => o.theme.zIndex.foregroundContent + 400};

  ${fromMd} {
    position: initial;
    z-index: inherit;
  }
`
