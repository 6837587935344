import React from 'react'

import { NavSection } from '@syconium/magnolia/src/types/graphql'

import { renderNavSection } from '../renderNavSection'

import { Body } from './styles'

export const NavTray: React.FC<{
  onKeyDown?: React.KeyboardEventHandler<HTMLElement> | undefined
  sections: NavSection[]
}> = ({ onKeyDown, sections }) => {
  return <Body {...{ onKeyDown }}>{sections.map((x, i) => renderNavSection(x, i))}</Body>
}
