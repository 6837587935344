import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const RightArrow: React.FC<IProps> = ({
  height = '14px',
  rotateClockwise,
  stroke,
  width = '15px',
}) => (
  <BaseIcon
    rotateClockwise={rotateClockwise}
    width={width}
    height={height}
    viewBox='0 0 15 14'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke={stroke || '#231F20'} strokeWidth='1.5' fill='none' fillRule='evenodd'>
      <path d='M0 7.047h13.972M7.922 1.071l6.05 5.958-6.05 5.956' />
    </g>
  </BaseIcon>
)
