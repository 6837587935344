export const xsEndPixels: number = 359
export const smStartPixels: number = 360
export const smEndPixels: number = 811
export const mdStartPixels: number = 812
export const mdEndPixels: number = 1099
export const lgStartPixels: number = 1100
export const lgEndPixels: number = 1599
export const xlStartPixels: number = 1600

export const xsEndMedia = `(max-width: ${xsEndPixels}px)`
export const atXs = `@media screen and ${xsEndMedia}`

export const smPriorMedia = `(max-width: ${smStartPixels - 1}px)`
export const smStartMedia = `(min-width: ${smStartPixels}px)`
export const smEndMedia = `(max-width: ${smEndPixels}px)`
export const smMedia = `${smStartMedia} and ${smEndMedia}`
export const untilSm = `@media screen and ${smPriorMedia}`
export const atSm = `@media screen and ${smMedia}`
export const fromSm = `@media screen and ${smStartMedia}`

export const mdPriorMedia = `(max-width: ${mdStartPixels - 1}px)`
export const mdStartMedia = `(min-width: ${mdStartPixels}px)`
export const mdEndMedia = `(max-width: ${mdEndPixels}px)`
export const mdMedia = `${mdStartMedia} and ${mdEndMedia}`
export const untilMd = `@media screen and ${mdPriorMedia}`
export const atMd = `@media screen and ${mdMedia}`
export const fromMd = `@media screen and ${mdStartMedia}`

export const lgPriorMedia = `(max-width: ${lgStartPixels - 1}px)`
export const lgStartMedia = `(min-width: ${lgStartPixels}px)`
export const lgEndMedia = `(max-width: ${lgEndPixels}px)`
export const lgMedia = `${lgStartMedia} and ${lgEndMedia}`
export const untilLg = `@media screen and ${lgPriorMedia}`
export const atLg = `@media screen and ${lgMedia}`
export const fromLg = `@media screen and ${lgStartMedia}`

export const xlPriorMedia = `(max-width: ${xlStartPixels - 1}px)`
export const xlStartMedia = `(min-width: ${xlStartPixels}px)`
export const untilXl = `@media screen and ${xlPriorMedia}`
export const fromXl = `@media screen and ${xlStartMedia}`

export const finePointer = `(pointer: fine)`
export const withFinePointer = `@media screen and ${finePointer}`
export const hoverSupportMedia = `(hover: hover) and (pointer: fine)`
export const withHoverSupport = `@media screen and ${hoverSupportMedia}`
export const withoutHoverSupport = `@media not screen and ${hoverSupportMedia}`
