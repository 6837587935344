import { HTMLAttributes } from 'react'

import { AttributeSection, FitSelector } from '@syconium/little-miss-figgy'

export type FitAttributeProps = {
  title: string
  accentColor?: string
  fits: string[] | Readonly<string[]>
  fitNames?: ReadonlyMap<string, string>
  onSelect: (fit: string) => void
  selected?: string
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'>

/**
 * An individual fit attribute selector.
 */
export function FitAttribute({
  title,
  accentColor,
  fits,
  fitNames,
  onSelect,
  selected,
  ...rest
}: FitAttributeProps) {
  return (
    <AttributeSection title={title} selection={selected} {...rest}>
      <FitSelector
        accentColor={accentColor}
        fits={fits}
        fitNames={fitNames}
        onFitSelect={onSelect}
        selected={selected}
      />
    </AttributeSection>
  )
}
