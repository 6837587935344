/**
 * Heap analytics event and snapshot definitions
 * https://docs.google.com/spreadsheets/d/1GYpAGkC33Xx0xVkL-D2FfgutW5zxKtTAFmjEXeG0osw
 */

import { GtmVariant } from '../../lib/hooks/useGoogleTagManager'
import { CartItem } from '../../types/figs'

import { IEvent, ITrackEvent } from './IProps'

export function trackEvent({
  action = '',
  category,
  customAttributes = {},
  label,
  trackSwipe,
  value,
  pageSectionIndex,
  pageSectionName,
  correspondingAsset,
}: IEvent): ITrackEvent {
  if (correspondingAsset && correspondingAsset.startsWith('//')) {
    correspondingAsset = correspondingAsset.replace(/^\/\//, '')
  }
  const base = {
    ...customAttributes,
    'data-heap-category': category,
    'data-heap-action': action,
    'data-heap-label': label,
    'data-heap-value': value,
    'data-heap-track-swipe': trackSwipe,
    'data-heap-section-index': pageSectionIndex,
    'data-heap-section-name': pageSectionName,
    'data-heap-corresponding-asset': correspondingAsset,
  }

  return base
}

export const isHeapPresent = () => {
  if (typeof globalThis.heap !== 'undefined') {
    return true
  }
  return false
}

export const heapTrack = (eventName: string, props?: Record<string, string>) => {
  if (isHeapPresent()) {
    globalThis.heap.track(eventName, props)
  }
}

export function mapCartItemToGtmVariant(
  item: CartItem,
  currencyType: string | undefined
): GtmVariant {
  return {
    category: item.productType,
    currencyType: currencyType ?? 'USD',
    externalParentId: item.externalParentId || '',
    discountPrice: item.effectivePrice,
    handle: item.productGroupHandle,
    price: item.fullPrice,
    shopifyId: item.variantId,
    sku: item.sku,
  }
}
