import { formatPrettyTitlePG } from '@syconium/magnolia/src/lib/utils'

import { CartItem, PersistedCartItem } from '../../../types/figs'
import { Variant } from '../../../types/graphql'

export function augmentPersistedCartItemsWithFigsData(
  persistedCartItems: Record<string, PersistedCartItem>,
  variantsBySku: Record<string, Variant>
): CartItem[] {
  return Object.values(persistedCartItems).map((item: PersistedCartItem): CartItem => {
    const variant: Variant | undefined = variantsBySku[item.sku]
    if (!variant) return item as CartItem

    const discountPct = parseFloat(item?.properties['_kitDiscountPct'] ?? '')
    const validDiscountPct = !isNaN(discountPct) && discountPct > 0 && discountPct <= 100
    const discountMultiplier = validDiscountPct ? (100 - discountPct) / 100 : 1
    const price = variant.priceDetails.discountPrice?.amount ?? variant.priceDetails.price.amount
    const effectivePrice = Math.round(price * discountMultiplier)

    return {
      ...item,
      product: variant.product,
      productGroup: variant.productGroup,
      externalParentId: variant.externalParentId,
      priceDetails: variant.priceDetails,
      effectivePrice,
      fit: variant.fit && variant.fit.toLowerCase() !== 'regular' ? variant.fit : '',
      fullPrice: variant.priceDetails.price.amount,
      title: formatPrettyTitlePG(variant.productGroup),
      description: variant.productGroup.description ?? '',
      finalSale: variant.product.finalSale,
      handle: variant.product.handle,
      color: variant.color,
      productGroupHandle: variant.productGroup.handle,
      productId: variant.externalParentId,
      productType: variant.category,
      size: variant.size ?? undefined,
      image: {
        alt: variant.style,
        url: variant.image?.source,
      },
    }
  })
}
