import { createContainer } from 'unstated-next'

import { ShopifyCartClientSingleton } from '@syconium/magnolia/src/lib/remote-data/cart/ShopifyCartClient'

import { ICartClient } from '../../interfaces/remote-data/cart'

function useCartClient(cartClient: ICartClient = ShopifyCartClientSingleton.getInstance()) {
  return cartClient
}

export const CartClientContainer = createContainer(useCartClient)
