import styled from 'styled-components'

import {
  Button as LMFButton,
  ResponsiveImage as LMFResponsiveImage,
  TypeStyle,
} from '@syconium/little-miss-figgy'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100px;
`

export const ResponsiveImage = styled(LMFResponsiveImage)`
  object-fit: contain;
  font-family: 'object-fit: contain;';
  height: 100%;
  width: 100%;
`

export const Question = styled.div`
  ${TypeStyle.css.bodyDefault}
  font-weight: bold;
  margin: 15px 0 10px;
`

export const YesButton = styled(LMFButton)``

export const NoButton = styled(LMFButton)`
  border: none;
  margin-top: 5px;
`
