export * from './collection-view'
export * from './gift-package'
export * from './page-view'
export * from './product'
export * from './sections'
export * from './shared'
export * from './site-fixtures'
export * from './server-fixtures'
export * from './kits'
export * from './page-tab-section'
export * from './search'
export * from './accordion-view'

export interface Portal {
  collectionUrl: string | null
  groupEmail: string | null
  groupTag: string | null
  name: string | null
  redirectUrl: string | null
}

type Price = {
  shortFmt: string
  explicitFmt: string
}

type Prices = {
  prices: Price[]
}

export type FormatPrices = {
  formatPrices: Prices[]
}
