import styled from 'styled-components'

export const Container = styled.ul`
  margin-top: 4px;
`

export const Property = styled.li`
  color: ${o => o.theme.color.deprecated.gray.dark};
  font-size: 13px;
  font-style: italic;
  line-height: 26px;
`
