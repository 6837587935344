import { ImageLoader } from './imageLoader'

interface GetUrlParams {
  width?: number
  height?: number
  crop?: 'top' | 'center' | 'bottom' | 'left' | 'right'
  scale?: number
}

export const getUrlWithFilters = (src: string, { width, height, crop, scale }: GetUrlParams) => {
  const normalizedWidth = width ? Math.ceil(width) : ''
  const normalizedHeight = height ? Math.ceil(height) : ''

  const sizeFilter =
    normalizedWidth || normalizedHeight ? `_${normalizedWidth}x${normalizedHeight}` : ''

  if (!sizeFilter) return src

  const cropFilter = crop ? `_crop_${crop}` : ''
  const scaleFilter = scale && scale > 1 ? `@${Math.ceil(scale)}x` : ''

  const url = new URL(src)

  /**
   * This will split the url before the file extension and insert the filters
   * right before that dot. For example https://domain.com/products/image.jpg?v=1
   * will become https://domain.com/products/image_400x400_crop_center@2x.jpg?v=1
   */
  url.pathname = url.pathname.replace(
    /^(.+)\.(.*)$/,
    `$1${sizeFilter}${cropFilter}${scaleFilter}.$2`
  )

  return url.toString()
}

export const createShopifyImageLoader = ({
  devicePixelRatio,
}: {
  devicePixelRatio: number
}): ImageLoader => {
  return ({ src, width, height }) => {
    /**
     * Scale supports integers only, but the devicePixelRatio is decimal
     * on some devices (e.g. 1.25). We could round up the devicePixelRatio
     * to the nearest integer, but that would cause larger assets. It's a
     * trade-off between cache hit ratio vs higher image/page size.
     * I will go with lower cache hit ratio and properly sized images for now.
     */
    return getUrlWithFilters(src, {
      width: width ? width * devicePixelRatio : undefined,
      height: height ? height * devicePixelRatio : undefined,
      scale: 1,
    })
  }
}
