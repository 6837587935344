import styled, { Interpolation } from 'styled-components'

import { navDropdownShadow } from '@syconium/little-miss-figgy/dist/utility-classes'

import { Section as NavAccordionSection } from '../NavAccordion/styles'
import { Body as NavListSection } from '../NavListSection/styles'
import { Body as NavTile, navTileAspectRatio } from '../NavTile/styles'
import { ScrollWrap as NavTileSection } from '../NavTileSection/styles'

const width = '360px'
const padding = '24px'

export const Body = styled.div<{ styles?: Interpolation<object> }>`
  background: white;
  ${navDropdownShadow}
  max-width: ${width};
  min-width: 140px;
  width: fit-content;

  ${({ styles }) => styles}

  > ${NavListSection} {
    padding: ${padding};
  }

  > * > ${NavTileSection} {
    padding: ${padding};

    ${NavTile} {
      display: block;
      width: calc(${width} - 2 * ${padding});

      img {
        height: calc((${width} - 2 * ${padding}) / ${navTileAspectRatio});
      }
    }
  }

  ${NavAccordionSection}:first-child {
    border-top: none;
  }
`
