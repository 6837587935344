import { Observable, timer } from 'rxjs'
import { filter, map, share, take, takeUntil } from 'rxjs/operators'

interface Options {
  /** Poll frequency in milliseconds */
  pollFrequency: number
  /** Poll timeout in milliseconds */
  pollTimeout: number
}

const defaultOptions: Options = {
  pollFrequency: 50,
  pollTimeout: 10000,
}

/**
 * Returns an observable wrapping a globally defined variable which may be set
 * asynchronously, for example by GTM.
 */
export function asyncGlobalVariable<T>(
  accessGlobalVariable: () => T,
  {
    pollFrequency = defaultOptions.pollFrequency,
    pollTimeout = defaultOptions.pollTimeout,
  }: Partial<Options> = defaultOptions
): Observable<T> {
  return timer(0, pollFrequency).pipe(
    takeUntil(timer(pollTimeout)),
    filter(() => accessGlobalVariable() !== undefined),
    take(1), // first() after takeUntil() would throw https://stackoverflow.com/a/45865225/161182
    map(() => accessGlobalVariable()),
    share()
  )
}
