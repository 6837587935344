// Design: 320x355 mobile (0.9014), 383x425 desktop (0.9012)
export const defaultAspectRatio = 0.9014

// Grid aspect ratio is default, other variants override.
export const carouselAspectRatio = 0.7472 // design: 328.75 x 440
export const sliderAspectRatio = 0.7435 // design: 204 x 273

export const tileAspectRatioByLayoutType = {
  carousel: carouselAspectRatio,
  slider: sliderAspectRatio,
  grid: defaultAspectRatio,
}
