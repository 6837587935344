import pickBy from 'lodash/pickBy'

import { LocalizationContainer } from '../../brunswick/containers/localization/LocalizationContainer'
import { PersistedCartItem } from '../../types/figs'
import { SearchAutocompleteProduct } from '../../types/graphql'

const unavailableBaseSkus = ['QRGCARD', 'GIFTCARD']
const unavailableIntlPages = [
  'gift-card-collection',
  'unisex-figs-physical-gift-card-gift-card',
  'figs-gift-card',
  'figs-physical-gift-card',
]
const unavailableProductSkus = [
  'QRGCARD100NA:N/S',
  'QRGCARD50NA:N/S',
  'QRGCARD25NA:N/S',
  'QRGCARD10NA:N/S',
  'GIFTCARD250',
  'GIFTCARD100',
  'GIFTCARD75',
  'GIFTCARD50',
  'GIFTCARD25',
]

export function useInternationalAvailability() {
  const { isUSRegion } = LocalizationContainer.useContainer()

  const isUnavailablePage = (handle: string) => {
    if (isUSRegion) return false
    return unavailableIntlPages.includes(handle)
  }

  const filterUnavailableBaseSkus = (products: SearchAutocompleteProduct[]) => {
    if (isUSRegion) return products
    return products.filter(
      product => !unavailableBaseSkus.some(sku => product.defaultVariant.sku.startsWith(sku))
    )
  }

  const filterUnavailableCartItems = (cartItems: Record<string, PersistedCartItem>) => {
    if (isUSRegion) return cartItems
    return pickBy(
      cartItems,
      (item: PersistedCartItem) => !unavailableProductSkus.includes(item.sku)
    )
  }

  return {
    isUnavailablePage,
    filterUnavailableBaseSkus,
    filterUnavailableCartItems,
  }
}
