import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { emptyNavListItem } from '@syconium/magnolia/src/brunswick/__fixtures__'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { NavListItem } from '@syconium/magnolia/src/types/graphql'

import { AuthenticationContainer } from '../../containers/AuthenticationContainer'

/**
 * Logged Out:
 * - Log In
 * - Create Account
 *
 * Logged In:
 * - Profile
 * - Orders
 * - Addresses
 * - Preferences
 * - Log Out
 */
export const useAccountMenuItems = () => {
  const {
    magnolia: { account: strings },
  } = useFixturesContext()
  const { isLoggedIn, logOut: logOutFunction } = AuthenticationContainer.useContainer()
  const { isUSRegion } = LocalizationContainer.useContainer()
  const router = useRouter()

  const accountMenuItems: NavListItem[] = useMemo(() => {
    const createAccount = item({
      href: '/account/register',
      text: strings.createAccount,
    })
    const logIn = item({
      href: '/account/login',
      text: strings.logIn,
    })
    const logOut = item({
      hasDivider: true,
      onClickish: logOutFunction,
      text: strings.logOut,
    })
    const profile = item({
      onClickish: () => router.push('/account'),
      text: strings.navigation.profile,
    })
    const orders = item({
      onClickish: () => router.push('/account/orders'),
      text: strings.navigation.orders,
    })
    const addresses = item({
      onClickish: () => router.push('/account/addresses'),
      text: strings.navigation.addresses,
    })

    return isLoggedIn
      ? [profile, orders, ...(isUSRegion ? [addresses] : []), logOut]
      : [logIn, createAccount]
  }, [
    isLoggedIn,
    isUSRegion,
    logOutFunction,
    router,
    strings.createAccount,
    strings.logIn,
    strings.logOut,
    strings.navigation.addresses,
    strings.navigation.orders,
    strings.navigation.profile,
  ])

  return {
    accountMenuItems,
  }
}

function item(input: Partial<NavListItem>): NavListItem {
  return {
    ...emptyNavListItem,
    id: input.text || '',
    ...input,
  }
}
