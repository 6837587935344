import { useEffect, useState } from 'react'

import { hoverSupportMedia } from '../../constants'
import { isBrowser } from '../utils'

/**
 * Detect if the current device supports hover with a fine pointer.
 * Returns `null` until the media query check is resolved on the client.
 */
export const useSupportsHover = (): boolean | null => {
  const [supportsHover, setSupportsHover] = useState<boolean | null>(() => {
    if (isBrowser && window && window.matchMedia) {
      return window.matchMedia(hoverSupportMedia).matches
    }
    return null
  })

  useEffect(() => {
    // Handle dynamic changes to the supported input devices
    const handleMqlChange = (event: MediaQueryListEvent) => {
      setSupportsHover(event.matches)
    }

    const mql = window.matchMedia(hoverSupportMedia)
    mql.addEventListener('change', handleMqlChange)
    return () => mql.removeEventListener('change', handleMqlChange)
  }, [])

  return supportsHover
}
