import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Parcel: React.FC<IProps> = ({
  height = '24px',
  fill = '#282828',
  width = '24px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7385 6.28271L12.2385 2.78271L3.73853 6.28271L12.2385 9.78271L15.036 8.63081L11.5806 7.21044L14.4253 5.96389L18.0019 7.40954L20.7385 6.28271ZM15.5771 9.41022V14.7177L18.1715 13.5851V8.34194L21.2808 7.06165V17.5616L12.7808 21.0616V10.5616L15.5771 9.41022ZM11.8604 10.5616L3.36041 7.06165V17.5616L11.8604 21.0616V10.5616Z'
      />
    </BaseIcon>
  )
}
