import { css, keyframes } from 'styled-components'

import { stickyAddToBagPortalClassName } from './constants'

const fadeUpAndIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 48px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const stickyAddToBagStyles = css`
  .${stickyAddToBagPortalClassName} {
    .ReactModal {
      &__Content {
        animation: ${fadeUpAndIn} 0.4s ease;
        opacity: 0;
        transform: translate3d(0, 48px, 0);

        &--after-open {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        &--before-close {
          opacity: 0;
          transform: translate3d(0, 48px, 0);
        }
      }
    }
  }
`
