import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Carat: React.FC<IProps> = ({
  className = '',
  height = '10px',
  rotateClockwise,
  stroke,
  width = '10px',
  transition,
}) => {
  return (
    <BaseIcon
      className={className}
      height={height}
      width={width}
      rotateClockwise={rotateClockwise}
      transition={transition}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 10 10'
    >
      <path
        stroke={stroke || 'currentColor'}
        strokeWidth='1.5'
        d='M3 9l4.571-4L3 1'
        fill='none'
        strokeLinecap='square'
      />
    </BaseIcon>
  )
}
