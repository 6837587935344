import styled, { css } from 'styled-components'

import { NavListSectionVariant } from '@syconium/magnolia/src/types/graphql'

import { Body as NavListItem, Text as NavListItemText } from '../NavListItem/styles'

export const Body = styled.div``

// Negative margins allow containers to use simple padding rules and ignore variant
export const MarginFixer = styled.ul<{
  variant: NavListSectionVariant
}>`
  ${o =>
    o.variant !== 'footer' &&
    css`
      margin: ${o.variant === 'featured' ? '-9px 0' : '-6px 0'};

      ${NavListItem} {
        padding: ${o.variant === 'featured' ? '9px 0' : '6px 0'};
      }
    `}

  ${NavListItemText} {
    font-weight: ${o => o.variant === 'featured' && 'bold'};
  }
`

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid #e6e6e6;
`
export const MarginFixerList = styled.li<{
  isHeading: boolean | null
}>`
  &:first-of-type {
    margin-top: 0;
  }

  ${o =>
    o.isHeading &&
    css`
      margin-top: 24px;
    `}
`
