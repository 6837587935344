import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Diamond: React.FC<IProps> = ({
  height = '24px',
  fill = '#FFFFFF',
  width = '24px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ fill, height, width }} {...rest}>
      <g clip-path='url(#clip0_26034_5963)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.96653 4.27283C6.10742 4.13028 6.29952 4.05005 6.49995 4.05005H18.5C18.7004 4.05005 18.8925 4.13028 19.0334 4.27283L23.8334 9.12933C23.9739 9.27146 24.0518 9.46377 24.0499 9.6636C24.048 9.86342 23.9665 10.0542 23.8234 10.1937L12.7978 20.9372C12.5021 21.2253 12.0294 21.2203 11.7398 20.9262L1.16543 10.1827C0.877698 9.89032 0.878187 9.42106 1.16653 9.12933L5.96653 4.27283ZM6.81318 5.55005L2.75338 9.65764L12.2854 19.3421L22.2355 9.64653L18.1867 5.55005H6.81318Z'
          fill={fill}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.15002 9.60034C2.15002 9.18613 2.48581 8.85034 2.90002 8.85034L22.1 8.85034C22.5142 8.85034 22.85 9.18613 22.85 9.60034C22.85 10.0146 22.5142 10.3503 22.1 10.3503L2.90002 10.3503C2.48581 10.3503 2.15002 10.0146 2.15002 9.60034Z'
          fill={fill}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.23801 4.09724C6.62613 3.95257 7.05805 4.14993 7.20273 4.53805L12.3364 18.3106L17.8028 4.52358C17.9554 4.13853 18.3913 3.95014 18.7764 4.10281C19.1614 4.25548 19.3498 4.69138 19.1972 5.07644L13.012 20.6764C12.8976 20.9649 12.6174 21.1532 12.307 21.15C11.9967 21.1468 11.7204 20.9528 11.612 20.662L5.79719 5.06196C5.65252 4.67383 5.84988 4.24191 6.23801 4.09724Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_26034_5963'>
          <rect width='24' height='24' fill={fill} transform='translate(0.5)' />
        </clipPath>
      </defs>
    </BaseIcon>
  )
}
