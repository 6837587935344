import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_TILE_PRODUCT_GROUP_FOR_COLLECTION = gql(`
  query GetTileProductGroupForCollection(
    $productGroupHandle: String!
    $color: String
    $fit: String
    $collectionHandle: String!
    $collectionColorScope: CollectionColorScope!
    $withEmbroideryOptions: Boolean = false
    $withEmbroideryScope: LogoScopeEnum = DTC
  ) {
    collection: optimizedCollection(handle: $collectionHandle, colorScope: $collectionColorScope) {
      id
      productGroup(handle: $productGroupHandle) {
        ...TileProductGroup
        colorGroups {
          ...ColorGroup
        }
        colors {
          ...Color
        }
        featuredProduct(color: $color, fit: $fit) {
          ...FeaturedProduct
          embroidery(logoScope: $withEmbroideryScope) @include(if: $withEmbroideryOptions) {
            ...ProductEmbroidery
          }
        }
      }
    }
  }
`)
