import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

const useEmailCaptureFormImpl = () => {
  const { asPath } = useRouter()

  // Manage visibility of the email capture form
  const [forceHideEmailCaptureForm, setForceHideEmailCaptureForm] = useState(false)
  const hideEmailCaptureForm = useMemo(() => {
    return asPath?.includes('/account') || forceHideEmailCaptureForm
  }, [asPath, forceHideEmailCaptureForm])

  return {
    hideEmailCaptureForm,
    setHideEmailCaptureForm: setForceHideEmailCaptureForm,
  }
}

const EmailCaptureFormContainer = createContainer(useEmailCaptureFormImpl)
export const EmailCaptureFormProvider = EmailCaptureFormContainer.Provider
export const useEmailCaptureForm = EmailCaptureFormContainer.useContainer
