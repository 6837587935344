import { ReactElement } from 'react'
import styled from 'styled-components'

import { Size, Spacer } from '@syconium/little-miss-figgy'
import * as AttributeSectionStyles from '@syconium/little-miss-figgy/dist/components/AttributeSection/styles'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import type {
  AnalyticsContext,
  ProductColorGroupWithUnavailable,
} from '@syconium/magnolia/src/types/figs'
import type { ProductColor, SizeChart } from '@syconium/magnolia/src/types/graphql'

import { prettySizeName } from '../../../lib/utils/tech-debt'
import { useFixturesContext } from '../../containers/fixtures'

import {
  ColorSection,
  ColorSelector,
  FitSection,
  FitSelector,
  SizeChartButton,
  SizeSection,
  SizeSectionTopText,
  SizeSelector,
  SoldOutMessage,
} from './styles'

const FitSelection = styled(AttributeSectionStyles.Selection)`
  text-transform: capitalize;
`

export const VariantAttributeSelectors = ({
  analyticsContext,
  availableFitsKeys,
  colorGroups,
  currentFitKey,
  currentSize,
  fitNames,
  isActiveProductWaitlistable,
  isCurrentColorCompletelySoldOut,
  openSizeChartModal,
  productGroupTitle,
  selectedColor,
  setSelectedColor,
  setSelectedFitKey,
  setSelectedSize,
  showColorSelector = true,
  sizeChart,
  sizes,
  sizeSectionTitle,
  userHasAttemptedInvalidAddToBag,
}: {
  analyticsContext: AnalyticsContext
  availableFitsKeys: readonly string[]
  colorGroups: ProductColorGroupWithUnavailable[]
  currentFitKey: string
  currentSize: Size | undefined
  fitNames?: ReadonlyMap<string, string>
  isActiveProductWaitlistable: boolean
  isCurrentColorCompletelySoldOut: boolean
  openSizeChartModal(): void
  productGroupTitle: string
  selectedColor: ProductColor | undefined
  setSelectedColor(color: ProductColor): void
  setSelectedFitKey(fit: string): void
  setSelectedSize(size: Size): void
  showColorSelector?: boolean
  sizeChart?: SizeChart
  sizes: readonly Size[]
  sizeSectionTitle: string
  userHasAttemptedInvalidAddToBag: boolean
}): ReactElement => {
  const { selectAndAddVariant: strings } = useFixturesContext()
  /*
     const { useExtendedFixedWidthSizeSelectorItems } = useStackedColorsAndSizes(category) // <-- original

     HACK: we originally relied on the category to determine if the size buttons should be fixed-width,
     but because that string is translated for i18n we can't rely on it any longer (until we refactor).

     For now, we're going to simply check if the sizes contain a `/` which indicates sizes that we typically use fixed-width values.
  */
  const useLargeWidthSizeSelectorItems = Boolean(sizes[0]?.shorthand.includes('/'))

  const selectedFitName = fitNames?.get(currentFitKey) ?? currentFitKey

  return (
    <>
      <ColorSection showColorSelector={showColorSelector}>
        <ColorSelector
          colorGroups={colorGroups}
          isCurrentColorCompletelySoldOut={isCurrentColorCompletelySoldOut}
          hasSelectedSize={!!currentSize}
          isProductWaitlistable={isActiveProductWaitlistable}
          onColorSelect={setSelectedColor}
          selectedColorKey={selectedColor?.rawName}
          {...trackEvent({
            category: `${analyticsContext.category}`,
            action: 'select color',
            label: 'modal color',
          })}
        />
      </ColorSection>

      <SizeSection>
        <SizeSectionTopText>
          <div>
            <AttributeSectionStyles.Title isInvalid={userHasAttemptedInvalidAddToBag}>
              <AttributeSectionStyles.Label>{sizeSectionTitle}</AttributeSectionStyles.Label>
              <AttributeSectionStyles.Selection>
                {currentSize?.shorthand ? prettySizeName(currentSize.shorthand) : null}
                {currentSize?.soldOut && !isActiveProductWaitlistable ? (
                  <SoldOutMessage>&nbsp;{strings.soldOutMessage}</SoldOutMessage>
                ) : null}
              </AttributeSectionStyles.Selection>
            </AttributeSectionStyles.Title>
          </div>
          {sizeChart && (
            <SizeChartButton
              as='button'
              onClick={openSizeChartModal}
              {...trackEvent({
                category: `${analyticsContext.category} size chart`,
                action: 'click',
                label: 'modal size chart',
              })}
            >
              {strings.sizeSection.sizeChartCta}
            </SizeChartButton>
          )}
        </SizeSectionTopText>

        <SizeSelector
          sizes={sizes}
          context={productGroupTitle}
          minWidthStyle={useLargeWidthSizeSelectorItems ? 'lg' : 'md'}
          onSelectSize={setSelectedSize}
          selected={currentSize}
          disableMobileHorizontalSwipe
          {...trackEvent({
            category: `${analyticsContext.category}`,
            action: 'select size',
            label: 'modal size',
          })}
        />
      </SizeSection>

      {availableFitsKeys.length > 1 ? (
        <FitSection>
          <div>
            <AttributeSectionStyles.Title>
              <AttributeSectionStyles.Label>{strings.fitSectionTitle}</AttributeSectionStyles.Label>
              <FitSelection>{selectedFitName}</FitSelection>
            </AttributeSectionStyles.Title>
          </div>
          <FitSelector
            context={productGroupTitle}
            fits={availableFitsKeys}
            fitNames={fitNames}
            onFitSelect={setSelectedFitKey}
            selected={currentFitKey}
            {...trackEvent({
              category: `${analyticsContext.category}`,
              action: 'select fit',
              label: 'modal fit',
            })}
          />
        </FitSection>
      ) : (
        <Spacer height={8} />
      )}
    </>
  )
}
