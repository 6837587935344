import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Calendar: React.FC<IProps> = ({
  height = '24px',
  fill = '#282828',
  width = '24px',
  ...rest
}) => {
  return (
    <BaseIcon {...{ height, width }} {...rest}>
      <svg
        {...{ width, height }}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill={fill}
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.75 2H14.25V0.0458986L15.75 0.0458985V2H17C18.6569 2 20 3.34315 20 5V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V5C0 3.34315 1.34315 2 3 2H4.25V0H5.75V2ZM15.75 3.5H14.25H3C2.17157 3.5 1.5 4.17157 1.5 5V7.25H18.5V5C18.5 4.17157 17.8284 3.5 17 3.5H15.75ZM18.5 8.75H1.5V17C1.5 17.8284 2.17157 18.5 3 18.5H17C17.8284 18.5 18.5 17.8284 18.5 17V8.75Z'
        />
      </svg>
    </BaseIcon>
  )
}
