import { useFixturesContext } from '../../../../containers/fixtures'
import { LocalizationContainer } from '../../../../containers/localization/LocalizationContainer'

export function useViewModel() {
  const { locale } = LocalizationContainer.useContainer()

  const {
    collections: {
      productGroupTile: { valueSignalLabel },
    },
  } = useFixturesContext()

  return { locale, valueSignalLabel }
}
