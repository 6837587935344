import { ApolloError } from '@apollo/client'

class FlatApolloError extends Error {
  public _err: { message: string | undefined; status: number | undefined } | undefined

  constructor(err: string) {
    super(err)
    this._err = undefined

    try {
      this._err = JSON.parse(err)
    } catch (error) {
      console.error('Error parsing FlattenApolloError constructor arg')
    }

    Object.setPrototypeOf(this, new.target.prototype)
    this.name = FlatApolloError.name
  }

  public get status(): number | undefined {
    return this._err?.status
  }
}

export const flattenApolloError = (
  error: ApolloError | null | undefined
): FlatApolloError | null => {
  const networkError = error?.networkError
  const graphQLError = error?.graphQLErrors?.length ? error?.graphQLErrors[0] : null

  if (networkError || graphQLError) {
    return new FlatApolloError(
      JSON.stringify({
        message: networkError?.message ?? graphQLError?.message,
        // `statusCode` does exist
        // @ts-ignore
        status: networkError?.statusCode ?? graphQLError?.extensions?.response?.status,
      })
    )
  } else {
    return null
  }
}
