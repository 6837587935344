import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_PRODUCT_GROUP_DETAILS = gql(`
  query FetchProductGroupDetails($handle: String) {
    productGroups(handle: $handle) {
      nodes {
        fits
        iconBadge
        iconBadgePlacement
        products {
          color
          collectionBadgeColor
          collectionBadgeText
          finalSale
          iconBadge
          iconBadgePlacement
          waitlistable
        }
        sizeChart: sizeChartCG {
          columnNames
          columnValues
          imageUri
          name
          navLabel
          tables {
            cells
            measureImage
            measureTips {
              kind
              tip
            }
            sizeTips {
              kind
              tip
            }
            subtitle
            tabLabel
            title
          }
          tips {
            kind
            tip
          }
          title
          video
        }
        variants {
          category
          color
          currencyType
          discountPrice
          fit
          handle
          price
          priceDetails {
            ...PriceDetail
          }
          priceInfo
          shopifyId
          size
          sku
          soldOut
        }
      }
    }
  }
`)
