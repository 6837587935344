import { Media } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

import { NavTabBar } from '../NavTabBar'

import { NavLogo } from './NavLogo'
import { Center, LogoMedia } from './styles'
import { useIconTabs } from './useIconTabs'

/**
 * The center container for the NavHeader.
 *
 * On mobile viewports, renders the brand logo
 * On desktop viewports, renders the desktop menu tabs
 *
 * @param props
 * @param [props.isFadedOut] - Whether or not the contents in the container should be opaque.
 */
export function NavHeaderCenter({ isFadedOut = false }: { isFadedOut?: boolean }) {
  const { navigation } = useFixturesContext()
  const { desktopTabs } = navigation || {}
  const { desktopIconTabs } = useIconTabs()

  return (
    <Center isFadedOut={isFadedOut} desktopTabCount={desktopIconTabs.length}>
      <LogoMedia lessThan='md'>
        <NavLogo />
      </LogoMedia>
      <Media greaterThanOrEqual='md'>
        {desktopTabs && <NavTabBar tabs={desktopTabs} wrapItems />}
      </Media>
    </Center>
  )
}
