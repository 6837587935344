/**
 * NOTE: This entire file should be considered deprecated.
 *
 * We should not need to create a utility that understands all of our exposed routes,
 * it is not feasible to maintain this overtime and shouldn't be necessary.
 */

import { isBrowser } from '@syconium/magnolia/src/lib/utils'

// Creating a Regex object is expensive. Hoist them up here to create them just once rather than on every function call.
const paths = ['account', 'cart', 'collections', 'gift', 'kits', 'pages', 'portals', 'products']
const pathsRegex = new RegExp(`^/(${paths.join('|')})/`)
const staticRoutes = ['account/login', 'account/register', 'cart', 'gift', 'gift/digital', 'search']
const staticRoutesRegex = new RegExp(`^/(${staticRoutes.join('|')})($|\\?|#)`)
const basicPageRouteRegex = /^\/pages\/(.*?)($|\?|#)/
const extendedPageRouteRegex = /^\/pages\/(plain|wrap)\//
const reviewsRouteRegex = /^\/products\/(.*?)\/reviews($|\?|#)/
const dynamicRoutes = [
  'account/orders',
  'collections',
  'pages/plain',
  'pages/wrap',
  'portals',
  'products',
  'kits',
]
const dynamicRoutesRegex = new RegExp(`^/(${dynamicRoutes.join('|')})/(.*?)($|\\?|#)`)

// Treat absolute hrefs that match the current site as relative hrefs when deciding if it is an spa href
const absoluteToRelativeHref = (href: string) => {
  let sanitizedHref = href

  if (
    isBrowser() &&
    sanitizedHref.startsWith(window.location.protocol + '//' + window.location.host)
  ) {
    const currentSite = window.location.protocol + '//' + window.location.host
    sanitizedHref = sanitizedHref.startsWith(currentSite)
      ? sanitizedHref.replace(currentSite, '')
      : sanitizedHref
  }

  return sanitizedHref
}

export function isSpaHref(providedHref: string): boolean {
  const sanitizedHref = absoluteToRelativeHref(providedHref)

  // Greenhouse.io (careers) widget does not support SPA transitions
  if (sanitizedHref.includes('/pages/careers')) return false

  return pathsRegex.test(sanitizedHref)
}

/*
  Supported routes:

  • /account/login
  • /account/orders/[id]
  • /account/register
  • /cart
  • /collections/[handle]
  • /gift
  • /gift/digital
  • /pages/[handle]
  • /pages/plain/[handle]
  • /pages/wrap/[handle]
  • /products/[handle]
  • /products/[handle]/reviews

  When RegEx is not enough and we do parse URLs, see Next's server-side handling of relative links:
  https://github.com/vercel/next.js/blob/5d79a8c/packages/next/next-server/lib/router/router.ts#L160
*/
export function getLinkProps(providedHref: string): { href: string; as: string } {
  const sanitizedHref = absoluteToRelativeHref(providedHref)

  // Static routes
  let match = sanitizedHref.match(staticRoutesRegex)
  if (match) return { href: `/${match[1]}`, as: `${match.input}` }

  // pages/[handle] (separate from pages/{plain,wrap} due to ambiguity)
  match = sanitizedHref.match(basicPageRouteRegex)
  if (match) {
    const pagesException = sanitizedHref.match(extendedPageRouteRegex)
    const pageHash = match[2] == '#'
    let pageHref = `/pages/${match[1]}`

    if (!pagesException) {
      if (pageHash) pageHref = `${match.input}`
      return { href: pageHref, as: `${match.input}` }
    }
  }

  // products/[handle]/reviews
  match = sanitizedHref.match(reviewsRouteRegex)
  if (match) return { href: '/products/[handle]/reviews', as: `${match.input}` }

  // Routes ending with one dynamic segment
  match = sanitizedHref.match(dynamicRoutesRegex)
  if (match) return { href: `${match.input}`, as: `${match.input}` }

  return { href: '', as: '' }
}
