import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_UPSELL_PRODUCTS = gql(`
  query FetchUpsellProducts($handles: [String!]) {
    products(handles: $handles) {
      nodes {
        color
        colorInfo {
          rawName
        }
        finalSale
        rawFit
        handle
        images(first: 1) {
          nodes {
            source
          }
        }
        productGroup {
          category
          description
          defaultVariant {
            priceDetails {
              discountPrice {
                amount
              }
              price {
                amount
                currency
              }
            }
            size
            shopifyId
            sku
          }
          handle
          id
          sizes
          style
          title
        }
        swPromoEligible
        variants {
          shopifyId
          size
          sku
          soldOut
          priceDetails {
            ...PriceDetail
          }
        }
      }
    }
  }
`)
