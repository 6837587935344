/* eslint-disable rulesdir/require-routing-service */
import { KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { ITrackEvent } from '../../analytics'

import { useMaybeSpaLink } from './useMaybeSpaLink'

type MaybeSpaLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  Partial<ITrackEvent> & { href: string }
type MaybeSpaLinkComponent = React.ComponentType<MaybeSpaLinkProps>

type PathProps = {
  target?: string
}

const stringifiedJSONChars = new RegExp(/[{}\"]/g)

// Some of our vendors (i.e. OneTrust) pass `javascript:{some function}` as an href. React will (eventually)
// block insecure JavaScript unless it's set with `dangerouslysetinnerhtml`.
const whitelistedInlineJavaScript: string[] = [
  'javascript:Optanon.ToggleInfoDisplay()',
  'javascript:Optanon.ToggleInfoDisplay();',
]

export const MaybeSpaLink: MaybeSpaLinkComponent = ({
  href,
  children,
  onClick,
  onKeyDown,
  ...rest
}) => {
  const { isSpaLink, isLinkInIframe, spaLinkHandler, spaLinkHref } = useMaybeSpaLink(href)

  const pathProps: PathProps = {}
  if (isLinkInIframe) pathProps.target = '_top'

  const combinedClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isSpaLink) event.preventDefault()
    onClick?.(event)
    if (isSpaLink) spaLinkHandler()
  }

  const combinedKeyHandler = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      if (isSpaLink) event.preventDefault()
      onKeyDown?.(event)
      if (isSpaLink) spaLinkHandler()
    }
  }

  const isKnownScriptInjection = (href: string): boolean =>
    /^javascript:/.test(href) && whitelistedInlineJavaScript.includes(href)

  const [safeToRenderRawHtml, setSafeToRenderRawHtml] = useState<boolean>(
    isKnownScriptInjection(href ?? '')
  )

  useEffect(() => setSafeToRenderRawHtml(isKnownScriptInjection(href ?? '')), [href])

  // @ts-expect-error
  delete rest.isActive
  // @ts-expect-error
  delete rest.isFadedOut
  // @ts-expect-error
  delete rest.textColor
  // @ts-expect-error
  delete rest.hideUnderline

  /*
     Takes an object that contains raw html attributes and converts it into a
     string which can be directly placed onto an HTML element; e.g.
     { "data-heap-event":"click", "data-heap-id": 122 }
     -->
     data-heap-event="click" data-heap-id="123"
  */
  const objectToHTMLAttributeString = (pathProps: PathProps): string =>
    JSON.stringify(pathProps)
      .split(',')
      .map(s => {
        const parts = s.split(':')
        const key = parts[0]?.replaceAll(stringifiedJSONChars, '')
        const value = parts[1]?.replaceAll(stringifiedJSONChars, '')
        return key && value ? `${key}="${value}"` : ''
      })
      .join(' ')

  if (isSpaLink) {
    return (
      <a // eslint-disable-line jsx-a11y/no-static-element-interactions
        data-testid='spa-href'
        style={{ cursor: 'pointer' }}
        {...rest}
        href={spaLinkHref}
        onClick={combinedClickHandler}
        onKeyDown={combinedKeyHandler}
      >
        {children}
      </a>
    )
  } else if (safeToRenderRawHtml) {
    /*
      renderToStaticMarkup does not automatically inject the global theme provider, so we need to wrap the children in a theme provider if we intend to use theme styles 
    */
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<a href=${href} ${objectToHTMLAttributeString({
            ...pathProps,
            ...rest,
          })}>${renderToStaticMarkup(children as ReactElement)}</a>`,
        }}
      />
    )
  } else {
    return (
      <a {...pathProps} {...rest} href={href} onClick={onClick} onKeyDown={onKeyDown}>
        {children}
      </a>
    )
  }
}
