import * as React from 'react'

import { IconSneakPeek } from '@syconium/magnolia/src/components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/IconSneakPeek'
import { LogoSneakPeek } from '@syconium/magnolia/src/components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/LogoSneakPeek'
import { SneakPeeksWrapper } from '@syconium/magnolia/src/components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/styles'
import { TextSneakPeek } from '@syconium/magnolia/src/components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/TextSneakPeek'

import { DTCLogoSneakPeek } from '../../../../../components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/DTCLogoSneakpeek'
import { LogoDigitizationSetting } from '../../../../containers/embroidery/types'
import { CartItemProperties } from '../../../../interfaces/remote-data/cart'
import { PriceAndDeleteButton } from '../PriceAndDeleteButton'

import { Body, Inner, Label, Top } from './styles'

interface Props {
  cartItemProperties: CartItemProperties
  currency: string
  label: string
  effectivePrice: number
  eventCategory: 'minicart' | 'cart'
  fullPrice: number
  removeItem(): void
  title: string
  inKit?: boolean
}

export const Template: React.FC<Props> = ({
  cartItemProperties,
  currency,
  effectivePrice,
  eventCategory,
  fullPrice,
  label,
  removeItem,
  title,
  inKit,
}) => {
  const hasLogo = cartItemProperties._logo_image_source
  const hasIcon = cartItemProperties._icon_image_source && cartItemProperties.icon_placement
  const hasDtcLogo = cartItemProperties.logo_preview

  return (
    <Body inKit={inKit}>
      <Inner inKit={inKit}>
        <Top inKit={inKit}>
          <Label>{label}</Label>

          <PriceAndDeleteButton
            currency={currency}
            effectivePrice={effectivePrice}
            eventCategory={eventCategory}
            fullPrice={fullPrice}
            removeItem={removeItem}
            title={title}
          />
        </Top>
        <SneakPeeksWrapper>
          <TextSneakPeek
            backgroundColor={cartItemProperties._scrub_color}
            textColor={cartItemProperties.text_color}
            textFirstLine={cartItemProperties.text_first_line}
            textFont={cartItemProperties.text_font}
            textPlacement={cartItemProperties.text_placement}
            textSecondLine={cartItemProperties.text_second_line}
          />
          {hasLogo && (
            <LogoSneakPeek
              backgroundColor={cartItemProperties._scrub_color}
              imgSrc={cartItemProperties._logo_image_source}
              logoName={cartItemProperties.logo_name}
              logoPlacement={cartItemProperties.logo_placement}
            />
          )}
          {hasDtcLogo && (
            <DTCLogoSneakPeek
              backgroundColor={cartItemProperties._scrub_color}
              imgSrc={cartItemProperties.logo_preview}
              logoName={cartItemProperties.logo_name}
              logoPlacement={cartItemProperties.logo_placement}
              logoColor={cartItemProperties.logo_color_option as LogoDigitizationSetting}
              logoNote={cartItemProperties.logo_note}
            />
          )}
          {hasIcon && (
            <IconSneakPeek
              backgroundColor={cartItemProperties._scrub_color}
              iconImageSource={cartItemProperties._icon_image_source}
              iconInitials={cartItemProperties.icon_initials}
              iconInitialsColor={cartItemProperties.icon_initials_color}
              iconDesignName={cartItemProperties.icon_design}
              iconPlacement={cartItemProperties.icon_placement}
            />
          )}
        </SneakPeeksWrapper>
      </Inner>
    </Body>
  )
}
