import { PageTab, PageTabSection, PageTabTileSection, RawHtmlSection } from '../../types/graphql'
import {
  numberOfTilesDesktop,
  numberOfTilesMobile,
} from '../components/pages/Collection/sections/CollectionGridSection/DesktopFilterBar/VisualFiltersSection/VisualFilters'

const thumbnail = 'https://cdn.shopify.com/s/files/1/0139/8942/files/backtoschool_square_video.jpg'
const tile = { id: 'X', ctaText: 'HATS', href: '/collections/foo', image: thumbnail }

const tabSection: PageTabTileSection = {
  __typename: 'PageTabTileSection',
  id: 'A',
  name: 'section-name',
  layoutVariantDesktop: 'carousel',
  layoutVariantMobile: 'slider',
  tiles: [
    {
      ...tile,
      id: 'A',
      ctaText: 'CANCER',
      name: 'section-name',
      visibleTilesDesktop: numberOfTilesDesktop,
      visibleTilesMobile: numberOfTilesMobile,
    },
    {
      ...tile,
      id: 'B',
      ctaText: 'VIRGO',
      name: 'section-name',
      visibleTilesDesktop: numberOfTilesDesktop,
      visibleTilesMobile: numberOfTilesMobile,
    },
    {
      ...tile,
      id: 'C',
      ctaText: 'PEDIATRICS',
      name: 'section-name',
      visibleTilesDesktop: numberOfTilesDesktop,
      visibleTilesMobile: numberOfTilesMobile,
    },
    {
      ...tile,
      id: 'D',
      ctaText: 'ORAL CARE',
      name: 'section-name',
      visibleTilesDesktop: numberOfTilesDesktop,
      visibleTilesMobile: numberOfTilesMobile,
    },
    {
      ...tile,
      id: 'E',
      ctaText: 'THIS IS TOO LONG PROBABLY',
      name: 'section-name',
      visibleTilesDesktop: numberOfTilesDesktop,
      visibleTilesMobile: numberOfTilesMobile,
    },
    {
      ...tile,
      id: 'F',
      ctaText: 'Cased data',
      name: 'section-name',
      visibleTilesDesktop: numberOfTilesDesktop,
      visibleTilesMobile: numberOfTilesMobile,
    },
  ],
  tileVariantDesktop: 'circle',
  tileVariantMobile: 'square',
}
const tabSection2: PageTabTileSection = { ...tabSection, tiles: [...tabSection.tiles].reverse() }

const html = '<p>Example RawHtmlSection content.</p>'
const tabSection3: RawHtmlSection = { __typename: 'RawHtmlSection', id: 'B', html }

const tab: PageTab = {
  id: 'A',
  tabSections: [tabSection],
  text: 'Women',
  textColor: null,
}
const tab2: PageTab = {
  id: 'B',
  tabSections: [tabSection2],
  text: 'Men',
  textColor: null,
}
const tab3: PageTab = {
  id: 'C',
  tabSections: [tabSection3],
  text: '...',
  textColor: 'red',
}

export const pageTabSection: PageTabSection = {
  __typename: 'PageTabSection',
  hideOnDesktop: false,
  hideOnMobile: false,
  name: 'section-name',
  id: 'A',
  tabs: [tab, tab2, tab3],
  title: 'Shop Categories',
}
