import { catalogCacheConfig, initClient } from '@syconium/weeping-figs'

const baseUrl: string = process.env.NEXT_PUBLIC_BRUNSWICK_API_BASE_URL || ''

export const figsPublicClient = initClient({
  host: `${baseUrl}/catalog/`,
  inMemoryCacheConfig: catalogCacheConfig,
})

export const figsAuthedClient = initClient({
  host: `${baseUrl}/shop/`,
})
