import { useCallback, useMemo, useState } from 'react'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { ContentfulRegion } from '@syconium/magnolia/src/types/graphql'
import { ContentfulLocale } from '@syconium/magnolia/src/types/graphql'
import { RegionData } from '@syconium/magnolia/src/types/graphql'
import {
  ShopRegion,
  SupportedLocaleIdentifier,
  retrieveEnabledLocales,
  supportedRegions,
} from '@syconium/weeping-figs'

export type RegionLocalePickerProps = {
  globalContentfulRegionData: ContentfulRegion | undefined
  enabledLocales: SupportedLocaleIdentifier[]
  enabledRegionNames: string[]
  getLanguageName: (option: string) => string
  selectedContentfulRegionData: ContentfulRegion | undefined
  handleCountryChange: (countryRegionName: string) => void
  handleSubmit: () => void
  selectedLocale: SupportedLocaleIdentifier
  selectedRegion: ShopRegion
  setSelectedLocale: (locale: SupportedLocaleIdentifier) => void
}

export function getRegionData(
  region: ShopRegion,
  regionData: RegionData | null
): ContentfulRegion | undefined {
  let contentfulRegion = regionData?.regions.find((r: ContentfulRegion) => r.id === region.id)
  if (contentfulRegion && !contentfulRegion.flagSrc) {
    contentfulRegion.flagSrc = region.flagSrc
  }

  return contentfulRegion
}

export function useRegionLocalePicker(): RegionLocalePickerProps {
  const { localeData, regionData } = useFixturesContext()
  const { languageName, locale, region, regions, changeLocale } =
    LocalizationContainer.useContainer()
  const [selectedRegion, setSelectedRegion] = useState<ShopRegion>(region)
  const [selectedLocale, setSelectedLocale] = useState<SupportedLocaleIdentifier>(locale)

  const enabledRegions = regions.filter(supportedRegion => {
    return supportedRegion.isEnabled
  })
  const enabledRegionNames = enabledRegions.map(enabledRegion => {
    return enabledRegion.name
  })

  const enabledLocales = retrieveEnabledLocales(selectedRegion)

  const globalContentfulRegionData = useMemo(() => {
    return getRegionData(region, regionData)
  }, [regionData, region])

  const selectedContentfulRegionData = useMemo(() => {
    return getRegionData(selectedRegion, regionData)
  }, [regionData, selectedRegion])

  const getLanguageName = useCallback(
    (option: string) => {
      const contentfulLanguageName = localeData?.locales.find(
        (l: ContentfulLocale) => l.locale === option
      )?.language
      return contentfulLanguageName ?? languageName
    },
    [languageName, localeData]
  )

  const handleSubmit = useCallback(() => {
    changeLocale(selectedRegion ?? supportedRegions.default, selectedLocale)
  }, [changeLocale, selectedLocale, selectedRegion])

  const handleCountryChange = useCallback(
    (countryRegionName: string) => {
      const newRegion = enabledRegions.find(r => {
        return r.name === countryRegionName
      })

      if (newRegion) {
        setSelectedRegion(newRegion)
        newRegion.id === region.id
          ? setSelectedLocale(locale)
          : setSelectedLocale(newRegion.defaultLocale)
      }
    },
    [enabledRegions, locale, region.id]
  )

  return {
    globalContentfulRegionData,
    enabledLocales,
    enabledRegionNames,
    getLanguageName,
    handleCountryChange,
    handleSubmit,
    selectedContentfulRegionData,
    selectedLocale,
    selectedRegion,
    setSelectedLocale,
  }
}
