import React, { ComponentProps, ElementType, ReactElement, ReactNode, forwardRef } from 'react'

import {
  LoadingSpinner,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '@syconium/little-miss-figgy'

import { Button, LoadingSpinnerWrapper } from './styles'

export type CtaButtonProps<C extends ElementType = 'button'> = PolymorphicComponentProps<
  C,
  ComponentProps<typeof Button>
>

// @TODO: Workaround for polymorphism with `forwardRef` - remove when we are on React 19
export type CtaButtonComponent = <C extends ElementType = 'button'>(
  props: CtaButtonProps<C>
) => ReactElement | ReactNode

export const Template: CtaButtonComponent = forwardRef(function CtaButtonTemplate<
  C extends ElementType = 'button',
>({ children, isProcessing, ...rest }: PolymorphicComponentProps<C, {}>, ref: PolymorphicRef<C>) {
  return (
    <Button ref={ref} isProcessing={isProcessing} size='large' {...rest}>
      {isProcessing && (
        <LoadingSpinnerWrapper>
          {/* TODO: Translate this */}
          <LoadingSpinner label='loading' />
        </LoadingSpinnerWrapper>
      )}
      {children}
    </Button>
  )
})
