import Link from 'next/link'
import { ReactElement } from 'react'

import { ProgressiveAsset } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { AddToBagButton } from '../../AddToBagButton'
import { CtaButton } from '../../CtaButton'
import { PriceDisplay } from '../../PriceDisplay'
import { EmbroiderySelector } from '../../quick-buy/EmbroiderySelector'
import { TrayModal } from '../../TrayModal'
import { VariantAttributeSelectors } from '../../VariantAttributeSelectors/VariantAttributeSelectors'

import {
  AddToBagButtonSection,
  Body,
  ColorNameWrap,
  ProductImageWrapper,
  ProductPreview,
  ProductSummary,
  Style,
  VariantAttributeSelectorsWrapper,
} from './styles'

import type { ViewModel } from './types'

export const Template = ({
  addToCartWithEmbroidery,
  analyticsContext,
  availableFitsKeys,
  category,
  closeModal,
  colorGroups,
  ctaButton,
  currency,
  currentFitKey,
  currentSize,
  discountPrice,
  embroideryConfig,
  fitNames,
  imageSource,
  isActiveProductWaitlistable,
  isCurrentColorCompletelySoldOut,
  isEmbroideryVisible,
  isModalOpen,
  pdpLinkPath,
  modalTitle,
  openSizeChartModal,
  price,
  priceRange,
  productGroupTitle,
  selectedColor,
  setSelectedColor,
  setSelectedFitKey,
  setSelectedSize,
  showColorSelector = true,
  sizeChart,
  sizes,
  sizeSectionTitle,
  userHasAttemptedInvalidAddToBag,
  onProductView,
}: ViewModel): ReactElement => {
  return (
    <TrayModal
      isModalOpen={isModalOpen}
      onRequestClose={closeModal}
      overflow='visible'
      title={modalTitle}
    >
      <Body>
        {isEmbroideryVisible && embroideryConfig ? (
          <EmbroiderySelector
            fixtures={embroideryConfig}
            image={embroideryConfig.image}
            mandatoryLogoEmbroidery={embroideryConfig.mandatoryLogoEmbroidery}
            onSelect={addToCartWithEmbroidery}
            {...trackEvent({
              action: 'click',
              category: `${analyticsContext.category} embroidery selector`,
              label: 'modal embroidery',
            })}
          />
        ) : (
          <>
            <ProductPreview>
              <ProductImageWrapper>
                <Link href={pdpLinkPath} legacyBehavior={undefined} onClick={onProductView}>
                  <ProgressiveAsset.Image
                    {...trackEvent({
                      action: 'click',
                      category: analyticsContext.category,
                      label: 'Collection 2.0 (Quick Buy) - Click Product Image',
                    })}
                    key={imageSource}
                    src={imageSource}
                  />
                </Link>
              </ProductImageWrapper>
              <ProductSummary>
                <Link href={pdpLinkPath} legacyBehavior={undefined} onClick={onProductView}>
                  <Style
                    {...trackEvent({
                      action: 'click',
                      category: analyticsContext.category,
                      label: 'Collection 2.0 (Quick Buy) - Click Product Name',
                    })}
                  >
                    {productGroupTitle}
                  </Style>
                </Link>
                <ColorNameWrap>{!showColorSelector ? selectedColor?.name : ''}</ColorNameWrap>
                <PriceDisplay {...{ currency, discountPrice, price, priceRange }} />
              </ProductSummary>
            </ProductPreview>
            <VariantAttributeSelectorsWrapper>
              <VariantAttributeSelectors
                {...{
                  analyticsContext,
                  availableFitsKeys,
                  category,
                  colorGroups,
                  currentFitKey,
                  currentSize,
                  fitNames,
                  isActiveProductWaitlistable,
                  isCurrentColorCompletelySoldOut,
                  openSizeChartModal,
                  productGroupTitle,
                  selectedColor,
                  setSelectedColor,
                  setSelectedFitKey,
                  setSelectedSize,
                  showColorSelector,
                  sizeChart,
                  sizes,
                  sizeSectionTitle,
                  userHasAttemptedInvalidAddToBag,
                }}
              />
            </VariantAttributeSelectorsWrapper>

            <AddToBagButtonSection>
              <AddToBagButton
                allCaps
                block
                ButtonComponent={CtaButton}
                disabled={ctaButton.disabled}
                onClick={ctaButton.onClick}
                variant={ctaButton.variant}
                {...trackEvent({
                  action: 'click',
                  category: `${analyticsContext.category} quick buy`,
                  label: ctaButton.text,
                })}
              >
                {ctaButton.text}
              </AddToBagButton>
            </AddToBagButtonSection>
          </>
        )}
      </Body>
    </TrayModal>
  )
}
