import * as React from 'react'

import { IProps } from './IProps'
import { Container, Property } from './styles'

export const CartItemProperties: React.FC<IProps> = ({ properties }) => {
  if (Object.keys(properties).length === 0) return null

  return (
    <Container>
      {Object.keys(properties).map(key => (
        <Property key={key}>
          {key}: {properties[key]}
        </Property>
      ))}
    </Container>
  )
}
