'use client'

import { useMemo } from 'react'
import {
  Cookies,
  CookiesProvider as ReactCookiesProvider,
  useCookies as useReactCookies,
} from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'

export type CookiesProviderProps = {
  cookiesHeader: string
  children?: React.ReactNode
}

export const CookiesProvider = ({ children, cookiesHeader }: CookiesProviderProps) => {
  const reactCookies = useMemo(() => {
    return new Cookies(cookiesHeader)
  }, [cookiesHeader])
  return <ReactCookiesProvider cookies={reactCookies}>{children}</ReactCookiesProvider>
}

export const useCookies = <CL extends string>(
  cookieList: CL[]
): [
  { [C in CL]: string | undefined },
  (name: CL, value: string, options?: CookieSetOptions) => void,
  (name: CL, options?: CookieSetOptions) => void,
] => {
  const result = useReactCookies<CL, { [C in CL]: string | undefined }>(cookieList)

  // Stringify all the cookies to be safe since we are using strings in our types.
  const cookies = result[0]
  Object.keys(cookies).forEach(key => {
    const castedKey = key as keyof typeof cookies
    const value = cookies[castedKey]
    const stringValue = value === null || value === undefined ? undefined : `${value}`
    cookies[castedKey] = stringValue
  })

  return result
}
