import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const CheckMark: React.FC<IProps> = ({ height = '11px', width = '14px' }) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 14 11'>
      <path d='M10.912-.034l2.175 2.067-7.91 8.326L.773 4.111l2.453-1.729L5.522 5.64z' />
    </BaseIcon>
  )
}
