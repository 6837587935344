import React from 'react'

import { NavListSection, NavSection } from '@syconium/magnolia/src/types/graphql'

import { renderNavSection } from '../renderNavSection'

import { Body } from './styles'

export const NavDropdown: React.FC<{
  onKeyDown?: React.KeyboardEventHandler<HTMLElement> | undefined
  section: NavSection
}> = ({ section, onKeyDown }) => {
  return (
    <Body {...{ onKeyDown }} styles={(section as NavListSection)?.styles}>
      {renderNavSection(section)}
    </Body>
  )
}
