import { useMemo } from 'react'

import { Size } from '@syconium/little-miss-figgy'
import { Fit } from '@syconium/magnolia/src/brunswick/constants'
import {
  AvailableProducts,
  areFitsEquivalent,
  deriveFitNamesMap,
  deriveSizes,
} from '@syconium/magnolia/src/lib/utils'

import { Product, ProductGroup, Variant } from '../../types/graphql'

export function useProductGroupDetails({
  availableProducts,
  coloredAndFitVariants,
  currentColorKey,
  defaultVariantFitKey,
  productGroup,
  productGroupFitsKeys,
  selectedFitKey,
  selectedSizeName,
  tileProductGroup,
}: {
  availableProducts: AvailableProducts
  coloredAndFitVariants: Variant[]
  currentColorKey: string | undefined
  defaultVariantFitKey: string | undefined
  productGroup: ProductGroup | undefined | null
  productGroupFitsKeys: string[] | undefined
  selectedFitKey?: string
  selectedSizeName?: string
  tileProductGroup?: ProductGroup | undefined | null
}) {
  const variantFits = Object.keys(availableProducts[currentColorKey ?? ''] ?? {})
  const fitKeys = (productGroupFitsKeys || []).filter(fitKey =>
    variantFits.some(variantFit => areFitsEquivalent(variantFit, fitKey))
  )

  const availableProductFitKeys = currentColorKey
    ? Object.keys(availableProducts[currentColorKey] ?? {})
    : []
  const availableFitsKeys =
    productGroupFitsKeys?.filter(fit => availableProductFitKeys.includes(fit)) ?? []

  const productGroupForFits = productGroup?.rawFits ? productGroup : tileProductGroup
  const fitNames = useMemo(() => {
    return productGroupForFits ? deriveFitNamesMap(productGroupForFits) : undefined
  }, [productGroupForFits])

  const currentFitKey: string =
    (selectedFitKey && fitKeys.includes(selectedFitKey) && selectedFitKey) ||
    (defaultVariantFitKey && fitKeys.includes(defaultVariantFitKey) && defaultVariantFitKey) ||
    fitKeys[0] ||
    Fit.REGULAR

  const coloredVariant: Variant | undefined = coloredAndFitVariants[0]

  const activeProduct: Product | undefined = productGroup?.products
    ? productGroup?.products.find(product => product.handle === coloredVariant?.handle) ||
      productGroup?.products[0]
    : undefined

  const productDescription: string | undefined = productGroup?.description

  const sizes = deriveSizes(
    coloredAndFitVariants,
    productGroup?.sizes ?? tileProductGroup?.sizes ?? []
  )

  const currentSize: Size | undefined =
    sizes.length === 1 ? sizes[0] : sizes.find(size => size.shorthand === selectedSizeName)

  const selectedVariant: Variant | undefined = coloredAndFitVariants.find(
    v => v.size === currentSize?.shorthand
  )

  return {
    activeProduct,
    availableFitsKeys,
    coloredVariant,
    currentFitKey,
    currentSize,
    fitNames,
    productDescription,
    selectedVariant,
    sizes,
    sizeChart: productGroup?.sizeChart,
  }
}
