import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const CheckboxCheckmark: React.FC<IProps> = ({
  height = '18px',
  fill = 'white',
  width = '18px',
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={`-3 -5 18 18`}>
      <path d='M10.7495 1.25048L4.64233 7.35767L1.17383 3.88908' stroke={fill} strokeWidth='2' />
    </BaseIcon>
  )
}
