import { RawHtmlSection } from '@syconium/magnolia/src/brunswick/components/page-sections/RawHtmlSection'
import { NavSection } from '@syconium/magnolia/src/types/graphql'

import { ContentfulNavListGroupSection } from '../contentful/nav/ContentfulNavListGroupSection'

import { NavAccordion } from './NavAccordion'
import { NavListSection } from './NavListSection'
import { NavRegionLocaleSection } from './NavRegionLocaleSection'
import { NavTileSection } from './NavTileSection'

export function renderNavSection(section: NavSection, index: number = 0): JSX.Element | null {
  const key: string = `${section.id}-${index}`
  switch (section.__typename) {
    case 'NavAccordion':
      return <NavAccordion key={key} {...section} />
    case 'NavListSection':
      return <NavListSection key={key} {...section} />
    case 'NavListGroupSection':
      return <ContentfulNavListGroupSection key={key} {...section} />
    case 'NavTileSection':
      return <NavTileSection key={key} {...section} />
    case 'RawHtmlSection':
      return <RawHtmlSection key={key} {...section} />
    case 'NavRegionLocaleSection':
      return <NavRegionLocaleSection key={key} {...section} />
    default:
      return null
  }
}
