import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const WrapperButton = styled.button`
  background: none;
  border: none;
  display: flex;
`

export const Label = styled.label`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  font-size: 14px;
  flex-grow: 1;
  height: 30px;
  min-width: initial;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  > input[type='radio']:checked + span {
    border-bottom: 3px solid black;
    color: ${o => o.theme.color.deprecated.obsidian};
    padding-bottom: 1px;
  }

  ${fromMd} {
    flex-grow: initial;
    min-width: 115px;
  }
`
export const Nav = styled(TypeStyle.HeadlineSmall)`
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stone};
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
  padding-bottom: 3px;

  ${fromMd} {
    justify-content: flex-start;
  }
`

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  display: none;

  &:checked + span {
    font-weight: bold;
  }
`

export const Span = styled.span`
  display: block;
  height: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;
`
