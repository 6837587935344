import * as React from 'react'

import { Details, Body as StyledBody, Visual } from './styles'

const Container: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <StyledBody className={className} {...rest}>
      {children}
    </StyledBody>
  )
}

const Header: React.FC<{ backgroundColor?: string; children?: React.ReactNode }> = ({
  children,
  backgroundColor,
}) => {
  return <Visual backgroundColor={backgroundColor}>{children}</Visual>
}

const Body: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Details> {children}</Details>
}

export const Template = {
  Container,
  Header,
  Body,
}
