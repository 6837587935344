import { useCallback } from 'react'

import { Container, ImageWrapper, NoButton, Question, ResponsiveImage, YesButton } from './styles'
import { Props } from './types'

export const EmbroiderySelector: React.FC<Props> = ({
  className,
  fixtures,
  image: src,
  onSelect,
  mandatoryLogoEmbroidery = false,
}) => {
  const onNo = useCallback(() => onSelect(false), [onSelect])
  const onYes = useCallback(() => onSelect(true), [onSelect])

  return (
    <Container {...{ className }}>
      <ImageWrapper>
        <ResponsiveImage
          alt={fixtures.logoName}
          src={src}
          aspectRatios={{
            sm: null,
            md: null,
          }}
          loading='default'
          widths={{ unit: 'px', sm: 560, md: 560 }}
        />
      </ImageWrapper>
      <Question>{fixtures.questionText}</Question>
      <YesButton variant='filled' onClick={onYes}>
        {fixtures.yesText}
      </YesButton>
      {!mandatoryLogoEmbroidery && (
        <NoButton variant='black-on-transparent' onClick={onNo}>
          {fixtures.noText}
        </NoButton>
      )}
    </Container>
  )
}
