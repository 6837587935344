import styled from 'styled-components'

export const Body = styled.div`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 13px;
`

export const ExclamationIconWrapper = styled.span`
  font-size: 20px;
  margin-inline-end: 13px;
`

export const Text = styled.span`
  color: #707070;
  font-size: 11px;
  letter-spacing: 0.05em;
  line-height: 150%;
`
