import { EmbroideryConfig } from '../containers/QuickBuyContainer/types'

import { embroidery } from './portal'
import { embroideryVariant } from './unorganized'

export const embroideryConfig: EmbroideryConfig = {
  image: embroidery.image,
  logoName: embroidery.logoName,
  mandatoryLogoEmbroidery: false,
  noText: embroidery.noText ?? '',
  questionText: embroidery.addText,
  variant: embroideryVariant,
  yesText: embroidery.yesText ?? '',
  quickBuyText: 'Quick Buy Text',
}
