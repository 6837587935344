'use client'

import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

import { TokenAuthStorageSingleton } from '@syconium/iron-figs'

import { gql } from '../../__generated__/graphql/catalog'
import { QueryStatus } from '../../lib/hooks/types'
import {
  removeItemFromLS,
  removeUserKeyFromLS,
  setItemInLS,
  setUserDataInLS,
} from '../../lib/utils/local-storage'
import { User } from '../_config/User.config'

import { useGraphqlClients, useNextMutation } from './GraphqlClientsProvider.client'

export type UseUserProps = User | undefined

export type UseUserOutput = {
  user: UseUserProps
  logout: () => void
  login: (credentials: { email: string; username: string }) => void
  loginStatus: QueryStatus
}

const useUserImpl = (user: UseUserProps) => {
  const { authorizedClient } = useGraphqlClients()
  const [loginStatus, setLoginStatus] = useState<QueryStatus>('idle')
  const [loginMutation] = useNextMutation(
    gql(`
      mutation LoginUser($email: String!, $password: String!) {
        loginCustomer(email: $email, password: $password) {
          customerAccessToken
          refreshToken
          token
        }
      }
  `)
  )

  const logout = useCallback(() => {
    TokenAuthStorageSingleton.getInstance().clearTokens()

    // TODO: Use a heap hook to wrap the integration with it instead of direct window object access to different functions.
    if (typeof window?.heap?.clearEventProperties === 'function') {
      window.heap.clearEventProperties()
    }

    // Deprecated line, can delete once pages dir is gone. We shouldn't be putting things in local storage
    removeUserKeyFromLS()
    // Deprecated line, can delete once pages dir is gone. We shouldn't be putting things in local storage
    removeItemFromLS('shop-token')

    window.location.replace('/nostore')
  }, [])

  const login = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      if (loginStatus !== 'pending' && email && password) {
        setLoginStatus('pending')

        loginMutation({
          variables: { email, password },
        })
          .then(loginMutationResult => {
            if ((loginMutationResult.errors?.length ?? 0) > 0 || !loginMutationResult.data) {
              setLoginStatus('rejected')
            } else {
              const newAuthToken = loginMutationResult.data.loginCustomer.token
              const newRefreshToken = loginMutationResult.data.loginCustomer.refreshToken
              const nowShopToken = loginMutationResult.data.loginCustomer.customerAccessToken
              if (!newAuthToken || !newRefreshToken) {
                setLoginStatus('rejected')
              } else {
                const tokenSingleton = TokenAuthStorageSingleton.getInstance()
                tokenSingleton.setToken(newAuthToken, true)
                tokenSingleton.setRefreshToken(newRefreshToken)

                // Deprecated follow up call to `my` query to set local storage things for pages directory
                authorizedClient
                  .query({
                    query: gql(`query GetUserAfterSuccessfulLogin {
                    my {
                      id
                      email
                      shopifyId
                      profile {
                        firstName
                        lastName
                        phone
                        ordersCount
                      }
                      portal {
                        name
                      }
                    }
                  }`),
                  })
                  .then(myResponse => {
                    if (!myResponse.data || myResponse.error) {
                      setLoginStatus('rejected')
                      tokenSingleton.clearTokens()
                    } else {
                      // Deprecated line, can delete once pages dir is gone. We shouldn't be putting things in local storage
                      setItemInLS('shop-token', nowShopToken)
                      // Deprecated line, can delete once pages dir is gone. We shouldn't be putting things in local storage
                      setUserDataInLS({
                        id: myResponse.data.my.id,
                        email: myResponse.data.my.email,
                        isPortalUser: Boolean(myResponse.data.my.portal),
                        firstName: myResponse.data.my.profile.firstName,
                        lastName: myResponse.data.my.profile.lastName,
                        ordersCount: myResponse.data.my.profile.ordersCount,
                        phone: myResponse.data.my.profile.phone,
                        shopifyId: myResponse.data.my.shopifyId,
                      })

                      window.location.replace('/nostore')
                    }
                  })
                  .catch(_error => {
                    setLoginStatus('rejected')
                    tokenSingleton.clearTokens()
                  })
              }
            }
          })
          .catch(_error => {
            setLoginStatus('rejected')
          })
      }
    },
    [authorizedClient, loginMutation, loginStatus]
  )

  return {
    user,
    logout,
    login,
    loginStatus,
  }
}

const UserContainer = createContainer(useUserImpl)
export const UserProvider = UserContainer.Provider
export const useUser = UserContainer.useContainer
