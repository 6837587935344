import { css } from 'styled-components'

import { imageZoomPortalClassName, modalAnimationDuration } from './constants'

export const imageZoomStyles = css`
  .${imageZoomPortalClassName} {
    .ReactModal {
      &__Overlay {
        opacity: 0;
        transition: opacity ${modalAnimationDuration}ms ease-in-out;

        &--after-open {
          opacity: 1;
        }

        &--before-close {
          opacity: 0;
        }
      }
    }
  }
`
