import { useRef } from 'react'
import { BehaviorSubject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { createContainer } from 'unstated-next'

import { preventBodyScrollClassName } from '../../styles/constants'

function useMiniCartSlideOut(initialIsRevealed: boolean = false) {
  const isRevealed$ = useRef(new BehaviorSubject(initialIsRevealed)).current

  const toggleIsRevealed = useRef(() => {
    isRevealed$.next(!isRevealed$.value)
  }).current

  return {
    isRevealed$: isRevealed$.pipe(
      tap(isRevealed => {
        if (isRevealed) document.body.classList.add(preventBodyScrollClassName)
        else document.body.classList.remove(preventBodyScrollClassName)
      })
    ),
    toggleIsRevealed,
  }
}

export const MiniCartSlideOutContainer = createContainer(useMiniCartSlideOut)
