import Script from 'next/script'
import { FC } from 'react'

export const OneTrustScript: FC = () => {
  return (
    <>
      <Script
        id='onetrust-block-script'
        strategy='beforeInteractive' // Scripts that alter the UI are before interactive
        src='https://cdn.cookielaw.org/consent/5f6fbb5a-ed5e-4087-8c32-2c813aa00c2a/OtAutoBlock.js'
      ></Script>
      <Script
        id='onetrust-stub-script'
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        strategy='beforeInteractive' // Scripts that alter the UI are before interactive
        data-domain-script='5f6fbb5a-ed5e-4087-8c32-2c813aa00c2a'
      ></Script>
    </>
  )
}
