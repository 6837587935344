/**
 * Be careful not to use z-index to fix complex stacking issues with interleaved content between components.
 * What you probably want is a new stacking context:
 * - https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 * We only need to get fancy with our z-index scheme in the top level, shared stacking context within the root
 * html element, where modals do need to coexist with content, foreground content, alerts, etc.
 */
export const zIndexMin = -(Math.pow(2, 31) - 1)
export const zIndexBackground = -99
export const zIndexForeground = 100
export const zIndexModal = 1000
export const zIndexAlert = 10000
