import { PropsWithChildren, ReactElement, createContext } from 'react'

import { createContextHook } from '@syconium/magnolia/src/lib/utils'

import { ICollectionFilterQueryParamsContext } from './types'
import { useCollectionFilterQueryParams } from './useCollectionFilterQueryParams'

export const CollectionFilterQueryParamsContext = createContext<
  ICollectionFilterQueryParamsContext | undefined
>(undefined)

export const useCollectionFilterQueryParamsContext = createContextHook(
  CollectionFilterQueryParamsContext,
  'CollectionFilterQueryParamsContext'
)

export const CollectionFilterQueryParamsContextProvider = ({
  children,
}: PropsWithChildren<{}>): ReactElement => {
  return (
    <CollectionFilterQueryParamsContext.Provider value={useCollectionFilterQueryParams()}>
      {children}
    </CollectionFilterQueryParamsContext.Provider>
  )
}
