import { FC, useState } from 'react'

import { Media } from '@syconium/little-miss-figgy'
import { CenteredTileLayout } from '@syconium/magnolia/src/brunswick/components/CenteredTileLayout'

import {
  VisualFiltersSection as Props,
  VisualFilterTile,
} from '../../../../../../../../types/graphql'

import { CoreStylesVisualFilterSectionTile } from './CoreStylesVisualFilterSectionTile'
import {
  CoreStylesVariantWrapper,
  EntryCard,
  EntryCardDescription,
  EntryCardHeader,
  Wrapper,
} from './styles'
import { VisualFilterSectionTile } from './VisualFilterTile'

export const tileGapDesktop = 40
export const tileGapMobile = 24
export const minTileWidth = 110
export const maxTileWidth = 190
export const numberOfTilesDesktop = 9
export const numberOfTilesMobile = 5
export const coreStylesVisibleTilesDesktop = 5
export const coreStylesVisibleTilesMobile = 2.5

const calcMinWordLength = (s: string) => {
  return Math.min(...s.split(' ').map(w => w.length))
}

interface EntryCardTile {
  header: string | null
  description: string | null
  color: string
  isEntryCard: boolean
  id: string
}

export const VisualFilters: FC<Props> = ({
  layoutVariantDesktop,
  tiles,
  layoutVariantMobile,
  hideOnDesktop,
  hideOnMobile,
  isCoreStylesVariant,
  entryCardDescription,
  entryCardHeader,
  id,
}) => {
  const entryCardTile: EntryCardTile = {
    header: entryCardHeader,
    description: entryCardDescription,
    color: 'black',
    id: id,
    isEntryCard: true,
  }
  const [rollingFilters, setRollingFilters] = useState<string[]>([])
  const imagePriority = (index: number) => {
    const entryCardExists = entryCardDescription && entryCardHeader ? true : false
    const imageIndex = entryCardExists ? index - 1 : index
    return imageIndex === 0 ? 'preload' : imageIndex >= 2 ? 'lazy' : 'default'
  }
  var body = (
    <Wrapper>
      <CenteredTileLayout
        hasVerticalMargin={false}
        layoutVariantDesktop={layoutVariantDesktop}
        layoutVariantMobile={layoutVariantMobile}
        tileGapDesktop={tileGapDesktop}
        tileGapMobile={tileGapMobile}
        minTileWidth={minTileWidth}
        maxTileWidth={maxTileWidth}
        tiles={tiles.filter(tile => tile.description === null)}
        liftedButtons
        keyFromTile={(o: VisualFilterTile, index: number) => `${index}--${o.id}`}
        renderTile={(o: VisualFilterTile) => {
          return (
            <VisualFilterSectionTile
              {...o}
              toggleRollingFilter={(filter: string) => {
                if (rollingFilters.includes(filter)) {
                  setRollingFilters(prev => prev.filter(v => v !== filter))
                } else {
                  setRollingFilters(prev => [...prev, filter])
                }
              }}
              rollingFilters={rollingFilters}
              ctaText={o.ctaText}
              image={o.image}
              value={o.value}
              filter={o.filter}
              filterSpec={o.filterSpec}
              visibleTilesDesktop={numberOfTilesDesktop}
              visibleTilesMobile={numberOfTilesMobile}
            />
          )
        }}
        visibleTilesDesktop={numberOfTilesDesktop}
        visibleTilesMobile={numberOfTilesMobile}
      />
    </Wrapper>
  )
  const entryCardAndTiles =
    entryCardDescription && entryCardHeader ? [entryCardTile, ...tiles] : tiles

  var variant = (
    <CoreStylesVariantWrapper
      layoutVariantDesktop={
        entryCardAndTiles.length <= coreStylesVisibleTilesDesktop ? 'grid' : layoutVariantDesktop
      }
    >
      <CenteredTileLayout
        hasVerticalMargin={false}
        visibleTilesDesktop={coreStylesVisibleTilesDesktop}
        visibleTilesMobile={coreStylesVisibleTilesMobile}
        layoutVariantDesktop={
          entryCardAndTiles.length <= coreStylesVisibleTilesDesktop ? 'grid' : layoutVariantDesktop
        }
        layoutVariantMobile={layoutVariantMobile}
        tileGapDesktop={24}
        tileGapMobile={9}
        tiles={entryCardAndTiles}
        sameHeightGridTiles={true}
        keyFromTile={(o: VisualFilterTile | EntryCardTile, index: number) => `${index}--${o.id}`}
        renderTile={(o: VisualFilterTile | EntryCardTile, _options, index: number) => {
          if ('isEntryCard' in o) {
            if (!o.header) return null
            return (
              <EntryCard>
                <EntryCardHeader oneWordPerLine={true} minWordLength={calcMinWordLength(o.header)}>
                  {o.header}
                </EntryCardHeader>
                <EntryCardDescription>{o.description}</EntryCardDescription>
              </EntryCard>
            )
          }
          return (
            <CoreStylesVisualFilterSectionTile
              {...o}
              toggleRollingFilter={(filter: string) => {
                if (rollingFilters.includes(filter)) {
                  setRollingFilters(prev => prev.filter(v => v !== filter))
                } else {
                  setRollingFilters(prev => [...prev, filter])
                }
              }}
              rollingFilters={rollingFilters}
              ctaText={o.ctaText}
              image={o.image}
              value={o.value}
              filter={o.filter}
              filterSpec={o.filterSpec}
              imagePriority={imagePriority(index)}
            />
          )
        }}
        hideSectionSideGaps={true}
      />
    </CoreStylesVariantWrapper>
  )

  if (hideOnDesktop && hideOnMobile) return null
  if (hideOnDesktop) return <Media lessThan='md'>{body}</Media>
  if (hideOnMobile) return <Media greaterThanOrEqual='md'>{body}</Media>
  return isCoreStylesVariant ? variant : body
}
