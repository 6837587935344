export const COLLECTION_ITEM_PAGE_SIZE = 24
export const COLLECTION_ITEM_MAX_ROW_SIZE = 4
export const FILTERS_QUERY_PARAM = 'filters'

export enum KnownFilterKeys {
  Color = 'color',
  Length = 'length',
  ProductType = 'product type',
  Size = 'size',
}

export enum LegacyFilterQueryParam {
  Category = 'category',
  Color = 'color',
  Fit = 'fit',
}
