import { useRouter } from 'next/router'

import { Bag, TypeStyle } from '@syconium/little-miss-figgy'
import { CartContainer } from '@syconium/magnolia/src/brunswick/containers/cart'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { MiniCartSlideOutContainer } from '@syconium/magnolia/src/brunswick/containers/mini-cart-slideout'
import { useGoogleTagManager } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'
import { NavTab } from '@syconium/magnolia/src/types/graphql'

export function useCartTab(): NavTab {
  const {
    magnolia: { navigation: strings },
  } = useFixturesContext()
  const { cart } = CartContainer.useContainer()
  const { toggleIsRevealed } = MiniCartSlideOutContainer.useContainer()
  const { pushOpenMiniCart } = useGoogleTagManager()
  const { pathname } = useRouter()

  const isCartPage: boolean = pathname === '/cart'

  const cartTab: NavTab = {
    ariaLabel: strings.cartButtonAriaLabel,
    href: null,
    id: 'cart',
    onClickish: () => {
      if (isCartPage) return
      toggleIsRevealed()
      pushOpenMiniCart(cart)
    },
    render: () => (
      <>
        <Bag />
        <TypeStyle.BodyMicro>{cart?.itemCount ?? 0}</TypeStyle.BodyMicro>
      </>
    ),
    sections: [],
    text: '',
    textColor: null,
    wrapperElement: 'button',
  }

  return cartTab
}
