import { useMemo } from 'react'

import { useFixturesContext } from '../../brunswick/containers/fixtures'
import { TooltipContentTypes } from '../../types/graphql'

/**
 * Get the tooltip fixture object for a given label & content type.
 *
 * @param enumValue - The target "enumValue" to use when searching for the tooltip fixture.
 * @param contentType - A value from the `TooltipContentTypes` enum that specifies which tooltip content types to look for.
 *
 * @returns The tooltip fixture object, or `undefined` if no tooltip was found.
 */
export function useTooltipFixture(enumValue: string, contentType: TooltipContentTypes) {
  const { tooltips } = useFixturesContext()

  const tooltip = useMemo(() => {
    return tooltips?.find(t => t.contentType === contentType && t.enumValue.name === enumValue)
  }, [tooltips, enumValue, contentType])

  return tooltip
}
