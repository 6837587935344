import { useEffect, useRef } from 'react'

/**
 * Observe changes in the intersection of a target element with the viewport.
 *
 * @param {IntersectionObserverCallback} cb - The callback function to be called when the target element's intersection state changes.
 * @param {IntersectionObserverInit} [opts] - The options to pass to the IntersectionObserver.
 */
export function useIntersection(cb: IntersectionObserverCallback, opts?: IntersectionObserverInit) {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) return
    const io = new IntersectionObserver(cb, opts)
    io.observe(ref.current)
    return () => io.disconnect()
  }, [cb, opts])

  return ref
}
