import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import { usePagesDirectoryExperiment } from '../../../containers/ExperimentationContainer'

import { Body } from './styles'

export const Interstitial: FC<{ initEnabled?: boolean }> = ({ initEnabled }) => {
  const router = useRouter()
  const [enabled, setEnabled] = useState(initEnabled)

  // Note: this is trial code to test run experiments in production with LaunchDarkly it can be cleaned up at anytime
  usePagesDirectoryExperiment({
    key: 'global-passive-experiment-session',
    defaultVariant: 'unassigned',
    forceSync: true,
  })
  usePagesDirectoryExperiment({
    key: 'global-passive-experiment-user',
    defaultVariant: 'unassigned',
    forceSync: false,
  })

  useEffect(() => {
    const onRouteChangeStart = () => setEnabled(true)
    const onRouteChangeComplete = () => setEnabled(false)
    const onRouteChangeError = () => setEnabled(false)

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    router.events.on('routeChangeError', onRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
      router.events.off('routeChangeError', onRouteChangeError)
    }
  }, [router, setEnabled])

  return enabled ? <Body /> : null
}
