import * as React from 'react'

import { LoadingSpinner, Minus, Plus } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { useFixturesContext } from '../../../containers/fixtures'

import { usePropReactiveInputQuantity } from './hooks'
import { IProps } from './IProps'
import { Button, Container, Input, SpinnerWrapper } from './styles'

export const CartItemQuantity: React.FC<IProps> = ({
  cartIsUpdating,
  decrementQuantity,
  eventCategory,
  incrementQuantity,
  setQuantity,
  quantity,
}) => {
  const originalQuantity: string = quantity.toString()
  const [itemIsUpdating, setItemIsUpdating] = React.useState<boolean>(false)
  const {
    cart: { item: cartItemStrings },
    loadMoreButton: { loading: loadingLabel },
  } = useFixturesContext()

  const safeQuantityUpdate = React.useCallback(
    (updateQuantity: () => void) => (_e: React.SyntheticEvent) => {
      setItemIsUpdating(true)
      updateQuantity()
    },
    []
  )

  React.useEffect(() => {
    if (itemIsUpdating && !cartIsUpdating) setItemIsUpdating(false)
  }, [itemIsUpdating, cartIsUpdating])

  const { inputQuantity, setInputQuantity } = usePropReactiveInputQuantity(originalQuantity)

  function setQuantityIfValid(): void {
    if (inputQuantity === originalQuantity) return
    if (inputQuantity === undefined || !inputQuantity.trim())
      return setInputQuantity(originalQuantity)
    const newQuantity = parseInt(inputQuantity, 10)
    if (isNaN(newQuantity)) return setInputQuantity(originalQuantity)
    setQuantity(newQuantity)
  }

  return (
    <Container>
      <Button
        aria-label={cartItemStrings.decrementQuantity}
        disabled={cartIsUpdating}
        onClick={safeQuantityUpdate(decrementQuantity)}
        {...trackEvent({
          category: eventCategory,
          action: 'decrement cart item',
          value: parseInt(originalQuantity, 10),
        })}
      >
        <Minus stroke='currentColor' />
      </Button>
      {itemIsUpdating ? (
        <SpinnerWrapper>
          <LoadingSpinner label={loadingLabel} />
        </SpinnerWrapper>
      ) : (
        <Input
          onChange={event => setInputQuantity(event.target.value)}
          onBlur={setQuantityIfValid}
          type='number'
          value={inputQuantity}
        />
      )}
      <Button
        aria-label={cartItemStrings.incrementQuantity}
        disabled={cartIsUpdating}
        onClick={safeQuantityUpdate(incrementQuantity)}
        {...trackEvent({
          category: eventCategory,
          action: 'increment cart item',
          value: parseInt(originalQuantity, 10),
        })}
      >
        <Plus stroke='currentColor' />
      </Button>
    </Container>
  )
}
