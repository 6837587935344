import { IDeserializable } from '@syconium/magnolia/src/brunswick/interfaces/ISerializer'

import { Cart } from '../../../../types/figs'
import { ShopifyCart } from '../../../../types/shopify'
import { ShopifyCartItemSerializer } from '../ShopifyCartItemSerializer'

export class ShopifyCartSerializer implements IDeserializable<Cart, ShopifyCart> {
  private cartItemSerializer = new ShopifyCartItemSerializer()

  public deserialize(shopifyCart: ShopifyCart): Cart {
    return {
      currencyCode: shopifyCart.currency,
      itemCount: shopifyCart.item_count,
      items: shopifyCart.items.reduce(
        (itemsByKey, item) => ({
          ...itemsByKey,
          [item.key]: this.cartItemSerializer.deserialize(item),
        }),
        {}
      ),
      totalPrice: shopifyCart.total_price,
      id: crypto.randomUUID(),
    }
  }
}
