import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useState } from 'react'
import { createContainer } from 'unstated-next'

import {
  ApolloClientProfile,
  IInitClient,
  LocalizedApolloClientProfileFactory,
  apolloClientFactoryTrace,
  isServer,
} from '@syconium/weeping-figs'

import { initiallyStatelessClientMap } from './initiallyStatelessClientMap'

export type Client = ApolloClient<NormalizedCacheObject>

type GraphClients = {
  figsPublicClient: Client | IInitClient
  figsAuthedClient: Client | IInitClient
  shopifyStorefront: Client | IInitClient
}

export type InitialState = {
  enableCacheRehydration: boolean
  caches?: { [key: string]: NormalizedCacheObject }
  for: LocalizedApolloClientProfileFactory
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trace = (message: string, ...rest: any[]) => {
  apolloClientFactoryTrace('useGraphClients', message, ...rest)
}

// for a client in the BROWSER context only, restore caches
function hydrate(client: Client, cache?: NormalizedCacheObject) {
  if (isServer || !cache) return client

  trace('hydrating cache for', client, cache)
  client.cache.restore(cache)
  return client
}

function instanceOf(state: InitialState, profile: ApolloClientProfile) {
  const { caches, enableCacheRehydration, for: factory } = state
  const contentKey = caches ? Object.keys(caches).find(k => k.endsWith(`:${profile}`)) : undefined
  const cache = !!contentKey ? caches?.[contentKey] : undefined

  const client = factory(profile)

  return enableCacheRehydration ? hydrate(client, cache) : client
}

// If no initialState, as in the case of theme hosted Brunswick, return
// the same clients Brunswick has been using to avoid accidental pollution
function inferClientsFromState(state?: InitialState): GraphClients {
  if (!state) {
    trace('no initial state provided, fallback to defaults')
    return initiallyStatelessClientMap
  }

  return {
    figsPublicClient: instanceOf(state, ApolloClientProfile.FigsPublicSupergraph),
    figsAuthedClient: instanceOf(state, ApolloClientProfile.FigsAuthedSupergraph),
    shopifyStorefront: instanceOf(state, ApolloClientProfile.ShopifyStorefront),
  }
}

function useGraphClients(initialState?: InitialState) {
  const [clients] = useState(() => inferClientsFromState(initialState)) // fn for perf
  return clients
}

export const GraphClientsContainer = createContainer(useGraphClients)
