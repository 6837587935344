import * as React from 'react'

import { IProps } from './IProps'
import { BadgeText, Container } from './styles'

export const Badge: React.FC<IProps> = ({ text, isPlaceholder, isFinalSale }) => {
  return (
    <Container {...{ isPlaceholder, isFinalSale }}>
      <BadgeText textTransform='uppercase' textType='tag'>
        {isPlaceholder ? 'spacer' : text}
      </BadgeText>
    </Container>
  )
}
